import * as React from 'react';
import { FunctionComponent } from 'react';

import { Container, Flag, Legend, BrazilFlag, ColombiaFlag } from './styles'

interface CountryFlagProps {
    origin: string
}
 
const CountryFlag: FunctionComponent<CountryFlagProps> = (props) => {
    
    let flagUrl = BrazilFlag; // url da bandeira do brasil

    if (props.origin === 'Colombia'){
        flagUrl = ColombiaFlag // url da bandeira da colombia
    }

    return ( 
        <Container>
            <Flag src={flagUrl}/>
            <Legend>{props.origin}</Legend>
        </Container>
     );
}
 
export default CountryFlag;