import React, { useState, ChangeEvent, useEffect, FormEvent } from "react";
import Main from "../../components/templates/Main";
import { FiltersCoffeesContainer, CoffeesTable, SearchBarContainer,SearchBar, CreateShippingButton} from "./styles";
import FilterCoffees  from "./components/FilterCoffees";
import TableLegends  from "./components/TableLegends";
import CoffeesLineMain  from "./components/CoffeesLineMain";
import CoffeesLineApproval  from "./components/CoffeesLineApproval";
import CoffeesLineLabels  from "./components/CoffeesLineLabels";
import SelectTable  from "./components/SelectTable";
import useSelectedCoffees from "./hooks/useSelectedCoffees";
import useModal from "../../hooks/useModal";
import FarmlyModal from "../../components/templates/FarmlyModal";
import ICoffee from './models/CoffeesLineModel'
import {createNewShipping} from './hooks/useCreateNewShipping'
const ManageCoffee: React.FC = () => {

const coffeesData = useSelectedCoffees()
const [searchCoffees, setSearchCoffees] = useState(useSelectedCoffees().coffees);
const [shippingModal, setShippingModal] = useState(false) 

const modal = useModal()
const modalMessage = 'Dados Atualizados!'
let updatedCoffees = false;
useEffect(()=>{
  if(coffeesData.coffees.length>0){
    if(!updatedCoffees){
      setSearchCoffees(coffeesData.coffees) 
      updatedCoffees = true;
    }
  }
},[coffeesData.coffees])

const renderCoffeesMain = () =>

  searchCoffees.map((coffee:ICoffee) => {
    return(
      <CoffeesLineMain key={coffee.id} coffee={coffee} modal={modal.toggleModal} />
    )
})

const renderCoffeesApproval = () =>
  
  searchCoffees.map((coffee:ICoffee) => {
    return(
      <CoffeesLineApproval key={coffee.id} coffee={coffee} modal={modal.toggleModal}/>
    )
})

const renderCoffeesLabels = () =>
  
  searchCoffees.map((coffee:ICoffee) => {
    return(
      <CoffeesLineLabels key={coffee.id} coffee={coffee} />
    )
})

const handleSearch = (event: React.ChangeEvent<HTMLInputElement>)=>{
  const searchParams = event.target.value;
  let searchResult = coffeesData.coffees.filter((e: any)=>{
    return (e.producer.toLowerCase().includes(searchParams.toLowerCase())
    || e.farmCode.toLowerCase().includes(searchParams.toLowerCase()))
  });
  setSearchCoffees(searchResult);
}

  const postNewShipping = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const form = document.getElementById('newShippingForm') as unknown as HTMLFormElement
    const formData = new FormData(form)
    const shipping_date = formData.get('shipping_date')?.toString() as unknown as string
    const origin = formData.get('origin')?.toString() as unknown as string
    const export_link = formData.get('export_link')?.toString() as unknown as string
    alert('OiOI')
    Promise.resolve(createNewShipping(shipping_date, origin, export_link))
  } 
           
  return (
  <Main>
      {modal.showModal && <FarmlyModal id="page-modal" closeModal={modal.toggleModal}>{modalMessage}</FarmlyModal>}
      {shippingModal && <FarmlyModal closeModal={shippingModal}>
      <form method="post" id="newShippingForm" onSubmit={(e) => postNewShipping(e)}>
      <label>Data de Embarque</label>
      <input type="text" name="shipping_date" placeholder="31/12" />
      <label>País de Origem</label>
      <select name="origin">
        <option selected>Selecione...</option>
        <option value="BR">Brasil</option>
        <option value="CO">Colômbia</option>
      </select>
      <label>Link de Export</label>
      <input type="text" name="export_link" placeholder="FarmlyClub" />
      <input type="submit" value="Criar Embarque"/>
      </form>
    </FarmlyModal> }
      <SelectTable selectBoard={coffeesData.selectBoard} setSelectBoard={coffeesData.setSelectBoard}/>
      <SearchBarContainer>
        <SearchBar onChange={handleSearch} placeholder="Buscar café"/> 
      </SearchBarContainer>
      {/* <SearchBarContainer>
      <CreateShippingButton type="button" value={"Criar Embarque"} onClick={() => setShippingModal(true)} />
      </SearchBarContainer> */}
      <FiltersCoffeesContainer>
        <FilterCoffees
          queryParams={coffeesData.queryParams}
          setQueryParams={coffeesData.setQueryParams}
        />
      </FiltersCoffeesContainer>
      <CoffeesTable>
        <TableLegends selectBoard={coffeesData.selectBoard}/>
        {coffeesData.coffees && 
          coffeesData.selectBoard == 'main' ? renderCoffeesMain()
          : 
          coffeesData.selectBoard == 'approval' ? 
            renderCoffeesApproval()
            :  
            renderCoffeesLabels()
        }
      </CoffeesTable>
    
  </Main>
  );
};

export default ManageCoffee;
