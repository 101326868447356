import React from 'react'

const ErrorPage = props => {
    const { setError } = props
    return (
        <div className="error-container">
            <div className="error-subtitle">
                <p style={{ fontSize: '42px' }}>
                    404 :(
                </p>
            </div>
        </div>

    )
}
export default ErrorPage