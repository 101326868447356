import React, { Fragment, useState, useEffect } from "react";
import SliderCupping from "react-slick";
import { server } from '../../../common'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import CuppingIndv from '../CuppingIndv.jsx'
import CuppingExclusiveIndv from './CuppingExclusiveIndv.jsx'
import FarmlyModal from '../../templates/FarmlyModal'
import FarmlyInput from '../../templates/FarmlyInput'
import Title from '../../templates/Title'
import '../Cupping.css'
import FloatingButton from '../../templates/FloatingButton'
import MainCupping from '../../templates/MainCupping'
import NavCupping from '../../templates/NavCupping'
import CuppingIndvDesktop from "../CuppingIndvDesktop";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faReply, faShare, faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons'
import { RotateRight } from "@material-ui/icons";


const roletaCompleta = require('../../../assets/roleta-completa.svg')

const CuppingExclusive = (props) => {

    const coffeesCupping = [326, 327]
    const [sensoryNote, setSensoryNote] = useState()
    const [sensoryList, setSensoryList] = useState()
    const [currentCoffee, setCurrentCoffee] = useState(0)
    const [notesModal, setNotesModal] = useState(false)
    const [cuppingDone, setCuppingDone] = useState(false)
    const [checkCuppings, setCheckCuppings] = useState(false)
    const [fillAllSpaces, setFillAllSpaces] = useState(false)
    const [goToResults, setGoToResults] = useState({status: false, coffeeId: null})
    const [auxSensoryList, setAuxSensoryList] = useState(false)
    const [coffeeData1, setCoffeeData1] = useState({})
    const [coffeeData2, setCoffeeData2] = useState({})
    const [coffeeData3, setCoffeeData3] = useState({})
    const [coffeeData4, setCoffeeData4] = useState({})
    const [coffeeData5, setCoffeeData5] = useState({})
    const [coffeeData6, setCoffeeData6] = useState({})
    const [coffeeData7, setCoffeeData7] = useState({})
    const [coffeeData8, setCoffeeData8] = useState({})

    const history = useHistory()

    useEffect(() => {
        let aux = []
        let aux2 = 0
        coffeesCupping.forEach((id) => {
            aux2++
            axios.get(`${server}/getAllCuppings/${id}`)
                .then(res => {
                    if (res.data.length && res.data[0].cupping_done != true) aux.push(res.data[0])
                    if (aux2 == coffeesCupping.length) {
                        if (aux.length == 0) {
                            setCuppingDone(true)
                        }
                        else setCuppingDone(false)
                    }
                })
        })
    }, [checkCuppings])

    useEffect(() => {
        coffeesCupping.forEach((id) => {
            axios.get(`${server}/getAllCuppings/${id}`)
                .then(res => {
                    if (!res.data.length) {
                        let newCupping = {
                            coffee_id: id,
                            user_id: 2000,
                            aroma: '6',
                            flavor: '6',
                            completion: '6',
                            acidity: '6',
                            body: '6',
                            balance: '6',
                            defects: '0',
                            big_defect: '0',
                            overall: '0',
                            score: 76
                        }
                        axios.post(`${server}/createCupping`, newCupping)
                    }
                })
                axios.get(`${server}/coffee/${id}`)
                    .then(coffee => {
                        if (id === 326) {
                            setCoffeeData1( coffee.data )
                        } else if (id === 327) {
                            setCoffeeData2( coffee.data )
                        }
                        else if (id === 336) {
                            setCoffeeData4( coffee.data )
                        } else if (id === 337) {
                            setCoffeeData5( coffee.data )
                        } else if (id === 338) {
                            setCoffeeData6( coffee.data )
                        } else if (id === 339) {
                            setCoffeeData7( coffee.data )
                        } else if (id === 340) {
                            setCoffeeData8( coffee.data )
                        }
                    })
        })
        
    }, [])

    const renderCuppingIndvs = () =>
        coffeesCupping.map((coffee, index) => {
            return <CuppingIndv coffee={coffee} sensoryList={sensoryList} cuppingDone={cuppingDone} checkCuppings={checkCuppings} setCheckCuppings={setCheckCuppings} updateAll={updateAll} setFillAllSpaces={setFillAllSpaces} goToResults={goToResults} />
        })

    const renderCuppingIndvsDesktop1 = () =>
        coffeesCupping.map((coffee, index) => {
            return <CuppingIndvDesktop coffee={coffee} sensoryList={sensoryList} cuppingDone={cuppingDone} checkCuppings={checkCuppings} setCheckCuppings={setCheckCuppings} updateAll={updateAll} setFillAllSpaces={setFillAllSpaces} goToResults={goToResults} auxSensoryList={auxSensoryList} notesModal={notesModal}/>
        })

    // const renderCuppingIndvsDesktop2 = () =>
    //     coffeesCupping.map((coffee, index) => {
    //         return <CuppingIndvDesktop coffee={coffee} sensoryList={sensoryList} cuppingDone={cuppingDone} checkCuppings={checkCuppings} setCheckCuppings={setCheckCuppings} updateAll={updateAll} setFillAllSpaces={setFillAllSpaces} goToResults={goToResults}/>
    //     })

    const setNotesModalFunction = () => {
        axios.get(`${server}/coffee/${coffeesCupping[currentCoffee]}`)
            .then(res => {
                if (res.data.sensoryNotes !== null && res.data.sensoryNotes !== "") setSensoryList(res.data.sensoryNotes.split(','))
                else setSensoryList([])
                setNotesModal(true)
            })
    }

    const addToSensoryList = async () => {
        let aux = [...sensoryList]
        aux.push(sensoryNote)
        await setSensoryList(aux)
    }

    const removeFromSensoryList = async (note) =>{
        let aux =[]
        await sensoryList.map(item=>{
            if(item != note){
                aux.push(item)
            }
        })
        await setSensoryList(aux)
    }

    const addSensoryNote = () => {
        axios.get(`${server}/coffee/${coffeesCupping[currentCoffee]}`)
            .then(async res => {
                const reqBody = {
                    ...res.data,
                    sensoryNotes: sensoryList.join(',')
                }
                try {
                    await axios.patch(`${server}/coffees`, reqBody)
                    setSensoryNote([null])
                    setNotesModal(false)
                } catch (err) {
                    console.log(err)
                }
            })
        setAuxSensoryList(!auxSensoryList)
    }

    const updateAll = () => {
        let aux = 0
        coffeesCupping.forEach((coffee) => {
            aux++
            axios.get(`${server}/coffee/${coffee}`)
                .then(res => {
                    const reqBody = {
                        ...res.data
                    }
                    axios.get(`${server}/getMainCupping/${coffee}`)
                        .then(res => {
                            reqBody.aroma = res.data.aroma = null ? 0 : res.data.aroma
                            reqBody.acidity = res.data.acidity = null ? 0 : res.data.acidity
                            reqBody.aftertaste = res.data.completion = null ? 0 : res.data.completion
                            reqBody.body = res.data.body = null ? 0 : res.data.body
                            reqBody.balance = res.data.balance = null ? 0 : res.data.balance
                            reqBody.flavour = res.data.flavor = null ? 0 : res.data.flavor
                            reqBody.sampleApprove = "approved"
                            try {
                                axios.patch(`${server}/coffees`, reqBody)
                                if (aux == coffeesCupping.length) {
                                    history.push('/')
                                }
                            } catch (err) {
                                console.log(err)
                            }
                        })

                })
        })
    }

    const getFarmcode = (i) => {
        switch (i) {
            case 0:
                return `Coffee: ${coffeeData1.farmCode}`
            case 1:
                return `Coffee: ${coffeeData2.farmCode}`
            case 2:
                return `Coffee: ${coffeeData3.farmCode}`
            case 3:
                return `Coffee: ${coffeeData4.farmCode}`
            case 4:
                return `Coffee: ${coffeeData5.farmCode}`
            case 5:
                return `Coffee: ${coffeeData6.farmCode}`
            case 6:
                return `Coffee: ${coffeeData7.farmCode}`
            case 7:
                return `Coffee: ${coffeeData8.farmCode}`
            default:
                break;
        }

    }


    const renderSensoryCard = () =>
        sensoryList.map((note) => {
            let random = Math.floor(Math.random() * 3)
            return (
                <Fragment>
                  <div className={(note.length < 7) ? 'sensory-red' : (((note.length < 10 && note.length > 6) ? 'sensory-lime' : 'sensory-orange'))} style={{display: 'flex', flexDirection: 'row'}}>
                      {note}
                      <div onClick={()=>removeFromSensoryList(note)} style={{marginLeft: '8px', marginRight: '3px'}}>x</div>
                  </div>
                &nbsp;
                    <br />
                </Fragment>
            )
        })

    // const scrollDot = (current) => {
    //     console.log("CURRENT", current)
    //     div.scrollBy(0, 1000)
    // }

    const info = {
        dots: false,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
    };
    const settingsCupping = {
        dots: true,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        focusOnSelect: true,
        pauseOnHover: true,
        // beforeChange: (current, next) => this.setState({ activeSlide: next }),
        afterChange: current => {
            // scrollDot(current);
            setCurrentCoffee(current)
        },
        customPaging: function (i) {
            return (
                <a className="each-dot">
                    {i == 0 ? `Coffee: ${coffeeData1.farmCode}` : `Coffee: ${coffeeData2.farmCode}`}
                </a>
            );
        },

    };

    const settingsCuppingDesktop = {
        dots: true,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        focusOnSelect: true,
        pauseOnHover: true,
        arrows: true,
        dotsClass: 'slick-dots-desktop',
        // beforeChange: (current, next) => this.setState({ activeSlide: next }),
        afterChange: current => {
            // scrollDot(current);
            setCurrentCoffee(current)
        },
        customPaging: function (i) {
            return (
                <a className="each-dot-desktop" style={{ margin: '2px' }}>
                    {i == 0 ? `Coffee:${coffeeData1.farmCode}` : `Coffee:${coffeeData2.farmCode}`}
                </a>
            );
        },

    };

    var current_rotation = 0;

    const rotateRightWheel = () =>{
        current_rotation += 30;
        console.log(current_rotation);
	    document.getElementById("rotate-wheel").style.transform = 'rotate(' + current_rotation + 'deg)';
    }

    const rotateLeftWheel = () =>{
        current_rotation -= 30;
        console.log(current_rotation);
	    document.getElementById("rotate-wheel").style.transform = 'rotate(' + current_rotation + 'deg)';
    }
    return (
        <>
            {fillAllSpaces && <FarmlyModal closeModal={() => setFillAllSpaces(false)}>
                <label style={{ fontSize: '15px' }}><b>You have not filled all the fields!</b></label>
                <br />
                <label>Would you like to continue anyway or would you like to come back to review the cupping?</label>
                <br />
                <br />
                <div className="buttons-display-cupping-desktop">
                    <button className="go-back-button" onClick={() => setFillAllSpaces(false)}>Go back</button>
                    <button className="go-foward-button" onClick={() => setGoToResults({status: true, coffeeId: coffeesCupping[currentCoffee] })}>Continue</button>
                </div>
            </FarmlyModal>
            }
            {notesModal && 
                <div className="black-background-modal-cupping">
                    <button className="notes-button" onClick={()=>{
                        addSensoryNote()
                        setNotesModal(false)}}>
                            <FontAwesomeIcon icon={faArrowLeft}/>
                    </button>
                    <FontAwesomeIcon icon={window.screen.width>500 ? faShare : faArrowUp}  className="rotate-right-wheel-button" onClick={()=>rotateRightWheel()}/>
                    <img src={roletaCompleta} id="rotate-wheel" className="roleta-completa-sensory-notes-modal"></img>
                    <FontAwesomeIcon icon={window.screen.width>500 ? faReply : faArrowDown} className="rotate-left-wheel-button" onClick = {()=>rotateLeftWheel()}/>
                    <div className="add-sensory-notes-div">
                        <div className="sensory-note-input-container">
                            <input className="input-add-sensory-notes" onChange={(event)=>setSensoryNote(event.target.value)} placeholder="sensory note you would like to add"></input>
                            <button className="notes-button-add" onClick={()=>addToSensoryList()}>+</button>
                        </div>
                        <div className='sensory-cards-main'>
                            {renderSensoryCard()}
                        </div>
                    </div>
                </div>
                }

            {window.screen.width > 768 ?
                <MainCupping noBanner style={{overflowY: 'hidden'}}>
                    <br />
                    <br />
                    <br />
                    <div style={{ display: 'flex', flexDirection: 'row', overflowY: 'hidden' }}>
                        <label style={{ fontSize: 'xxx-large', fontWeight: '600', paddingLeft: '100px' }}>Cupping</label>
                        <FloatingButton setNotesModalFunction={setNotesModalFunction} />
                    </div>
                    <div className="cupping-desktop-ultra-container" >
                        <div className="cupping-desktop-container">
                            <div className="gambiarra">
                                <SliderCupping {...settingsCuppingDesktop}>
                                    {renderCuppingIndvsDesktop1()}
                                </SliderCupping>
                            </div>
                        </div>
                    </div>
                </MainCupping>
                :
                <div className="indv-container" >
                    <FloatingButton setNotesModalFunction={setNotesModalFunction} />
                    <SliderCupping {...settingsCupping}>
                        <CuppingExclusiveIndv coffee={coffeeData1} sensoryList={sensoryList} cuppingDone={cuppingDone} checkCuppings={checkCuppings} setCheckCuppings={setCheckCuppings} updateAll={updateAll} setFillAllSpaces={setFillAllSpaces} goToResults={goToResults} id={326}/>
                        <CuppingExclusiveIndv coffee={coffeeData2} sensoryList={sensoryList} cuppingDone={cuppingDone} checkCuppings={checkCuppings} setCheckCuppings={setCheckCuppings} updateAll={updateAll} setFillAllSpaces={setFillAllSpaces} goToResults={goToResults} id={327}/>
                    </SliderCupping>
                </div>
            }
        </>
    );
}

export default CuppingExclusive