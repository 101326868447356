import React, { useEffect, useState, Fragment } from 'react'
import './Carousel.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight, faTimes } from '@fortawesome/free-solid-svg-icons'
import { storage } from '../../firebase'
import axios from 'axios'
import { server } from '../../common'


const CarouselSingle = props => {

    const images = props.images.length ? props.images : props.imagesFarm
    let nextLeft = images.length - 2
    let nextRight = 4 % images.length
    const currOffset = [-1, -1, -1, -1, -1]

    useEffect(() => {
        const carouselImages = document.querySelectorAll('.carousel-img-single')
        const offset = images.length ? carouselImages[1].offsetLeft - carouselImages[0].offsetLeft :  []
        carouselImages.forEach(el => {
            el.style.transition = "transform 0s ease-in-out"
            el.style.transform = `translateX(-${offset}px)`
        })
    }, [])

    const moveRight = () => {
        const carouselImages = document.querySelectorAll('.carousel-img-single')
        const offset = carouselImages[1].offsetLeft - carouselImages[0].offsetLeft
        carouselImages.forEach((el, ind) => {
            if (currOffset[ind] <= -ind - 1) el.src = images[nextRight]
            const next = currOffset[ind] <= -ind - 1 ? -ind + 3 : currOffset[ind] - 1
            el.style.transition = currOffset[ind] <= -ind - 1 ? "none" : "transform 0.4s ease-in-out"
            currOffset[ind] = next;
            el.style.transform = `translateX(${next * offset}px)`
        })
        nextRight = (nextRight + 1) % images.length
        nextLeft = (nextLeft + 1) % images.length
    }


    const moveLeft = () => {
        const carouselImages = document.querySelectorAll('.carousel-img-single')
        const offset = carouselImages[1].offsetLeft - carouselImages[0].offsetLeft
        carouselImages.forEach((el, ind) => {
            if (currOffset[ind] >= -ind + 3) el.src = images[nextLeft]
            const next = currOffset[ind] >= -ind + 3 ? -ind - 1 : currOffset[ind] + 1
            el.style.transition = currOffset[ind] >= -ind + 3 ? "none" : "transform 0.4s ease-in-out"
            currOffset[ind] = next;
            el.style.transform = `translateX(${next * offset}px)`
        })
        nextRight = nextRight === 0 ? images.length - 1 : nextRight - 1
        nextLeft = nextLeft === 0 ? images.length - 1 : nextLeft - 1

    }   
     const removeFile = async () => { 
        let aux = images.length < 2 ? nextRight : nextRight + (images.length - 4)
        let index = aux
        if (aux < 0) {
            index = images.length + aux
        } 
        if (aux > images.length - 1) {
            index = aux - images.length
        }
        const formData = {...props.coffeeData}
        await storage.refFromURL(images[index]) 
            .getMetadata()
            .then(md => { 
                const aux2 = formData.imageUrl.split(',').filter((img) => img != md.name)
                formData.imageUrl = aux2.join(',')
                props.sendDataToRemoveImage(formData)
            })
        await storage.refFromURL(images[index])
        .delete()
    }

    const makeCardImg = async () => { 
        let aux = images.length < 2 ? nextRight : nextRight + (images.length - 4)
        let index = aux
        if (aux < 0) {
            index = images.length + aux
        } 
        if (aux > images.length - 1) {
            index = aux - images.length
        }
        await storage.refFromURL(images[index]) 
            .getMetadata()
            .then(md => { 
                axios.patch(`${server}/updateCardImg`, {
                    coffeeId: props.coffeeData.id,
                    cardImg: md.name
                })
                props.setModalMessage("Foto de capa atualizada!")
                props.toggleModal()
            })
    }
    

    return (
        <div className="carousel-single">
            {props.isOwner && props.images.length?             
            <div id="carousel-cape-button" className="carousel-button coffee-icon"
                onClick={makeCardImg} >
                Definir capa
            </div>
            : <Fragment/>
            }
            <div id="carousel-left-button-single" className="carousel-button coffee-icon"
                onClick={moveLeft} >
                <FontAwesomeIcon className="carousel-arrow-icon" icon={faArrowLeft} />
            </div>
            <div className="carousel-container-single">
                <div className="carousel-slide">
                    <img className="carousel-img-single"
                        id="car0" src={images[images.length - 1]} alt="" />
                    <img className="carousel-img-single"
                        id="car1" src={images[0]} alt="" />
                    <img className="carousel-img-single"
                        id="car2" src={images[1 % images.length]} alt="" />
                    <img className="carousel-img-single"
                        id="car3" src={images[2 % images.length]} alt="" />
                    <img className="carousel-img-single"
                        id="car4" src={images[3 % images.length]} alt="" />
                </div>
            </div>
            {props.isOwner && props.images.length?             
            <div id="carousel-delete-button" className="carousel-button coffee-icon"
                onClick={removeFile} >
                <FontAwesomeIcon className="carousel-arrow-icon" icon={faTimes} />
            </div>
            : <Fragment/>
            }
            <div id="carousel-right-button-single" className="carousel-button coffee-icon"
                onClick={moveRight} >
                <FontAwesomeIcon className="carousel-arrow-icon" icon={faArrowRight} />
            </div>
        </div>
    )
}

export default CarouselSingle