import React, { Fragment } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { faAngleUp } from '@fortawesome/free-solid-svg-icons'

import { language } from '../../../common'
import UserSendSampleForm from './UserSendSampleForm'

import '../../../styles/profile/components/UserSendSample.css'

const UserSendSample = props => {

    const {submitSample, showUserSendSampleContent, setShowUserSendSampleContent, user} = props;

    // const [showContent, setShowContent] = useState(false)
    const renderIframes = () => {
        return (
            <div style={{display: "flex", flexDirection:"column", alignItems: "center", width: "100%", height: "100%"}}>
			<span>Samples Producer</span>
            {user.id == 0 ? <iframe src={`https://farmly-offerlist.bubbleapps.io/version-test/samples_producer_l?fid=${user.id}&debug_mode=true`} className="user-iframe" ></iframe> :<></>}
			<span>Samples Roaster</span>
			{user.id == 0 ? <iframe src={`https://farmly-offerlist.bubbleapps.io/version-test/samples_roaster?fid=${user.id}&debug_mode=true`} className="user-iframe"></iframe> :<></>}
            </div>
        )
    }
    return (
        <Fragment>
            <div className="new-profile user-accordion-button">
                {/* {renderIframes()} */}
                <div className="user-accordion-action">
                    <div className={showUserSendSampleContent ? "user-accordion user-accordion-opened new-farmly-button " : "user-accordion user-accordion-closed new-farmly-button " }
                    onClick={() => { setShowUserSendSampleContent(!showUserSendSampleContent) }}>
                        {{pt:'Cadastrar Cafés',es:'Registrar cafés',en:'Register Coffees'}[language]}
                        {showUserSendSampleContent?<FontAwesomeIcon icon={faAngleUp} className="user-accordion-icon-up" />:<FontAwesomeIcon icon={faAngleDown} className="user-accordion-icon-down" />}
                    </div> 
                </div>
            </div>
            {
                showUserSendSampleContent ?
                    <div className="new-profile user-send-sample-section">
                        <div className="new-profile user-send-sample-text">
                            <strong> {{pt:'Antes de enviar suas amostras veja se elas atendem os seguintes requisitos:',es:'Antes de enviar sus muestras, asegúrese de que cumplen los siguientes requisitos:',en:'Before sending your samples, make sure they meet the following requirements:'}[language]}</strong>
                            <ul>
                                <li>{{pt:'Umidade até 11.5%',es:'Humedad hasta el 11,5%.',en:'Humidity up to 11.5%.'}[language]}</li>
                                <li>{{pt:'Peneira 16 ou maior',es:'Pantalla 16 o superior',en:'Sieve 16 or larger'}[language]}</li>
                                <li>{{pt:'Expectativa de pontuação: 83 ou mais.',es:'Puntuación esperada: 83 o más.',en:'Score expectation: 83 or higher.'}[language]}</li>
                                <li>{{pt:'Tipo: 2 (Catada)',es:'Tipo: 2 (Catada)',en:'Type: 2 (Crawl)'}[language]}</li>
                                <li>{{pt:'Quantidade: 2kg de amostra',es:'Cantidad: 2 kg de muestra',en:'Quantity: 2kg sample'}[language]}</li>
                            </ul>
                            <strong>
                            {{pt:'A amostra deve ser fiel a saca que será entregue.',es:'La muestra debe ser fiel a la bolsa que se entregará.',en:'The sample must be true to the bag that will be delivered.'}[language]}
                            {/* {{pt:'Ao enviar o café, por favor escreva na caixa,',es:'Al enviar el café, escriba en la casilla, '}[language]}<span> {{pt:"Seu Nome - FARMly - Seu Telefone",es:'"Su nombre - FARMly - Su teléfono"'}[language]}</span> {{pt:'para podermos identificar.',es:'para que podamos identificar.'}[language]} <br /> <br />
                            {{pt:'Por exemplo:',es:'Por ejemplo:'}[language]} <span> "Carlos - FARMly - (99)99999-9999" </span><br /> <br /> */}
                            </strong>
                        </div>
                        <UserSendSampleForm submitSample={submitSample} user={user}/>
                    </div>
                : null
            }
        </Fragment>
    )
}

export default UserSendSample