import React, { useState, useEffect, useContext } from "react";
import database from "../../../../../lib/backend";
import { acceptedCookies, language } from '../../../../../common';
import { useHistory } from "react-router-dom";
import UserContext from "../../../../../context/user/UserContext.js";
import CartContext from "../../../../../context/cart/CartContext.js";
import Countries from "../../../../../assets/countries.json";
import { FormControlLabel, Switch } from "@material-ui/core";
import { CoffeeCard } from "../../../../../components/CoffeeCard";

const useSampleCarts = () => {
    const [arraySamples, setArraySamples] = useState([]);
    const [step, setStep] = useState(0);
    const [modalMessage, setModalMessage] = useState("");
    const [authFailure, setAuthFailure] = useState(false);
    const [emailFailure, setEmailFailure] = useState(false);
    const [requestPopupFollowing, setRequestPopupFollowing] = useState(false);
    const [requestPopup, setRequestPopup] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [serverFailure, setServerFailure] = useState(false);
    const countryOptions = Countries;
    const url = document.URL;
    const urlArray = url.split('/')
    const listLink = urlArray[4]
    const history = useHistory();
    const [roasterData, setRoasterData] = useState({
        country: "",
        email: "",
        name: "",
        password: "",
        confirmPassword: "",
        postalCode: "",
        address: "",
        roastery: "",
        vat: "",
        phoneNumber: "",
        sensoryNotes: "",
        coffeeScores: "0,0,0,0,0,0",
    });
    const [userData, setUserData] = useState({
        name: "",
        email: "",
        password: "",
        confirmPassword: "",
        postalCode: "",
        address: "",
        country: "",
    });
    const userRoasterInfoRequest = {
        name: "",
        email: "",
        address: "",
        country: "",
        postalCode: "",
    };
    const cartContext = useContext(CartContext);
    const userContext = useContext(UserContext);


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleRoastOption = (changedIndex) => {
        const arraySamplesChange = [...arraySamples];
        if (arraySamplesChange[changedIndex].roastOption === true) {
            arraySamplesChange[changedIndex].roastOption = false;
        }
        else {
            arraySamplesChange[changedIndex].roastOption = true;
        }
        setArraySamples(arraySamplesChange);
    }

    const returnRoastOption = (changedIndex) => {
        const arraySamplesChange = [...arraySamples];
        if (arraySamplesChange[changedIndex].roastOption === true) {
            return true;
        }
        else {
            return false;
        }
    }

    const renderSamples = () =>
        arraySamples.map((item, indx) => {
            return (
                <div>
                    <CoffeeCard
                        key={indx}
                        coffee={item}
                        favoriteEvent={() => { console.log("favorite clicked") }}
                        commentEvent={() => { console.log("comment clicked") }}
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={returnRoastOption(indx)}
                                onChange={() => handleRoastOption(indx)}
                                name="roast_option"
                                inputProps={{ 'aria-label': 'roast option' }}
                            />
                        }
                        label={
                            returnRoastOption(indx) === true
                                ?
                                "Roasted (100g)"
                                :
                                "Not roasted (100g)"
                        }
                    />
                    <br />
                </div>
            );
        });

    useEffect(() => {
        database
            .get(`/getSampleCartAndFullCoffeesWithLink/${listLink}`)
            .then((res) => {
                res.data.forEach((element) => {
                    element.sampleWeight = 100;
                    element.roastOption = false;
                });
                setArraySamples(res.data);
            })
            .catch((err) => { });
    }, []);

    useEffect(() => {
        database
            .get(`/getRoasterDataBySampleCartLink/${listLink}`)
            .then((res) => {
                const roasterInfo = res.data[0];
                setRoasterData({
                    ...roasterData,
                    address: roasterInfo.address,
                    country: roasterInfo.country,
                    name: roasterInfo.name,
                    phoneNumber: roasterInfo.phoneNumber,
                    postalCode: roasterInfo.postalCode,
                    roastery: roasterInfo.roastery,
                    vat: roasterInfo.vat
                })
            })
            .catch((err) => { });
    }, []);

    const signUpForm = async () => {
        let user = { ...roasterData };
        delete user.confirmPassword;
        delete user.sensoryNotes
        delete user.coffeeScores
            
        console.log(user)
        try {
            const payload = await database.post("/signup/0", {
                ...user,
            });
            setUserData({ ...user, id: payload.data.id });
            userContext.userLogin({
                isProd: payload.data.isProd,
                jwt: payload.data.jwt,
                id: payload.data.id,
                main_id: payload.data.main_id,
            });
            const cookie = acceptedCookies() ? document.cookie : "";
            if (cookie.includes("cartList") && !cartContext.cookiesCartIsEmpty) {
                cartContext.setCartToServer(payload.data.jwt);
            }
            if (acceptedCookies()) {
                document.cookie = `jwt=${payload.data.jwt}; max-age=${payload.data.exp}; path=/`;
                document.cookie = `isProd=${payload.data.isProd}; max-age=${payload.data.exp}; path=/`;
                document.cookie = `userId=${payload.data.id}; max-age=${payload.data.exp}; path=/`;
            }
            if (payload.data.id != null || payload.data.id != undefined) {
                var n = new Date();
                userRoasterInfoRequest.email = roasterData.email;
                userRoasterInfoRequest.name = roasterData.name;
                userRoasterInfoRequest.address = roasterData.address;
                userRoasterInfoRequest.country = roasterData.country;
                userRoasterInfoRequest.postalCode = roasterData.postalCode;
                userRoasterInfoRequest.phoneNumber = roasterData.phoneNumber;
                arraySamples.map((item, indx) => {
                    database.post("/request", {
                        roaster: {
                            ...userRoasterInfoRequest,
                            sampleWeight: item.sampleWeight,
                            id: payload.data.id,
                        },
                        coffee: item.coffeeId,
                        date: n.getFullYear() + "-" + n.getMonth() + "-" + n.getDate(),
                        roast_option: item.roastOption,
                    });
                });

                setModalMessage(
                    <div>
                        {
                            {
                                pt: "Amostras solicitadas com sucesso!",
                                es: "¡Muestras solicitadas con éxito!",
                                en: "Samples successfully requested!",
                            }[language]
                        }
                        <div className="change-password-buttons" onClick={(e) => { history.push("/profile") }}>
                            {
                                {
                                    pt: "Ir para o perfil",
                                    en: "Go to profile",
                                    es: "Ir al perfil",
                                }[language]
                            }
                        </div>
                    </div>
                );
                toggleModal();
            }
        } catch (err) {
            setModalMessage(
                {
                    pt: "Opa! Tivemos problemas em processar o pedido. Avise-nos e tente outra vez mais tarde!",
                    es: "Hemos tenido problemas para procesar su solicitud. Háganoslo saber y vuelva a intentarlo más tarde.",
                    en: "Oops, we had problems processing your request. Let us know and try again later!",
                }[language]
            );
            toggleModal();
            console.log(err);
        }
    };

    const submitRequestFromLogin = (id = null) => {
        const userId = id !== null ? id : userContext.user.id ? userContext.user.id : userData.id;
        database.get(`/roaster/${userId}`).then((res) => {
            var n = new Date();
            userRoasterInfoRequest.email = res.data.email;
            userRoasterInfoRequest.name = res.data.name;
            userRoasterInfoRequest.address = res.data.address;
            userRoasterInfoRequest.country = res.data.country;
            userRoasterInfoRequest.postalCode = res.data.postalCode;
            arraySamples.map((item, indx) => {
                database.post("/request", {
                    roaster: {
                        ...userRoasterInfoRequest,
                        sampleWeight: item.sampleWeight,
                        id: userId,
                    },
                    coffee: item.coffeeId,
                    date: n.getFullYear() + "-" + n.getMonth() + "-" + n.getDate(),
                    roast_option: item.roastOption,
                });
            });
            setModalMessage(
                <div>
                    {
                        {
                            pt: "Amostras solicitadas com sucesso !",
                            es: "¡Muestras solicitadas con éxito !",
                            en: "Samples successfully requested !",
                        }[language]
                    }
                    <div className="change-password-buttons" onClick={(e) => { history.push("/profile") }}>
                        {
                            {
                                pt: "Ir para o perfil",
                                en: "Go to profile",
                                es: "Ir al perfil",
                            }[language]
                        }
                    </div>
                </div>
            );
            toggleModal();
        })
            .catch(err => {
                setModalMessage(
                    {
                        pt: "Opa! Tivemos problemas em processar o pedido. Avise-nos e tente outra vez mais tarde!",
                        es: "Hemos tenido problemas para procesar su solicitud. Háganoslo saber y vuelva a intentarlo más tarde.",
                        en: "Oops, we had problems processing your request. Let us know and try again later!",
                    }[language]
                );
                toggleModal();
                console.log(err);
            });
    };

    const toggleModal = () => {
        setShowModal(!showModal);
    };

    const login = () => {
        return new Promise((resolve, reject) => {
            database
                .post("/signin", {
                    email: userData.email,
                    password: userData.password,
                })
                .then((payload) => {
                    const cookie = acceptedCookies() ? document.cookie : "";
                    userContext.userLogin({
                        isProd: payload.data.isProd,
                        jwt: payload.data.jwt,
                        id: payload.data.id,
                        main_id: payload.data.main_id
                    });
                    if (cookie.includes("cartList") && !cartContext.cookiesCartIsEmpty) {
                        cartContext.setCartToServer(payload.data.jwt);
                    } else if (!payload.data.isProd) {
                        cartContext.getCartFromServer(payload.data.id);
                    }
                    if (acceptedCookies()) {
                        document.cookie = `jwt=${payload.data.jwt}; max-age=${payload.data.exp}; path=/`;
                        document.cookie = `isProd=${payload.data.isProd}; max-age=${payload.data.exp}; path=/`;
                        document.cookie = `userId=${payload.data.id}; max-age=${payload.data.exp}; path=/`;
                    }
                    if (!payload.data.isProd) {
                        database.get(`/roaster/${payload.data.id}`).then((roaster) => {
                            setUserData({
                                id: roaster.data.id,
                                name: roaster.data.name,
                                email: roaster.data.email,
                                postalCode: roaster.data.postalCode,
                                address: roaster.data.address,
                                country: roaster.data.country,
                            });
                            setRequestPopup(false);
                            setRequestPopupFollowing(true);
                            resolve(roaster.data)
                        });
                    }
                })
                .catch((err) => {
                    const status = err.response
                        ? err.response.data.status
                        : "server-failure";
                    if (status === "email-not-found") {
                        setEmailFailure(true);
                    } else if (status === "auth-failed" || status === "incomplete") {
                        setAuthFailure(true);
                    } else if (status === "server-failure") {
                        setServerFailure(true);
                    }
                    reject(err);
                });
        });
    };
    return {
        arraySamples, setArraySamples, step, setStep, modalMessage, setModalMessage, authFailure,
        setAuthFailure, emailFailure, setEmailFailure, requestPopupFollowing, setRequestPopupFollowing,
        requestPopup, setRequestPopup, showModal, setShowModal, serverFailure, setServerFailure,
        countryOptions, listLink, history, roasterData, setRoasterData, userData, setUserData,
        userRoasterInfoRequest, cartContext, userContext, handleRoastOption, renderSamples, signUpForm,
        submitRequestFromLogin, toggleModal, login
    }
}

export default useSampleCarts;