import React, { useState, useEffect, useContext, Fragment } from 'react'
import axios from 'axios'
import { server, isPt, farmlyTexts, isCo, isBr } from '../../common'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar,faCommentAlt, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import FarmlyModal from '../templates/FarmlyModal.jsx'
import UserContext from '../../context/user/UserContext.js'
import { farmlyColors } from '../../common.js'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { storage } from '../../firebase'
import './CoffeeCard.css'
import {
    toEuro,
    totalCoffeePrice,
    logisticsFee,
    importDuties,
    farmlyFee,
    producersPriceKg
} from '../templates/pricing.js'
import FarmlyInput from '../templates/FarmlyInput'

const footerArt = require('../../assets/footer-art.png')

const coffeePicture3 = require('../../assets/coffee_farm.jpg')

// recebe uma variavel noHover pelos props se for pra desativar o hover
// a variavel width é passada por parâmetro e por default eu acho q pega
// o comprimento real da imagem
const CoffeeCard = props => {

    const { loadFlag, coffeeId, coffee } = props
    const [commentSample, setcommentSample] = useState(false)
    const [successUnfavorite, setmodalUnfavorite] = useState(false)
    const [successFavorite, setmodalFavorite] = useState(false)
    const [hover, setHover] = useState(false)
    const [requestPopup, setRequestPopup] = useState(false)
    const userContext = useContext(UserContext)
    const [isFavorite, setIsFavorite] = useState()
    const [coffeeImageUrl, setCoffeeImageUrl] = useState()
    const [cardImg, setCardImg] = useState()
    const [farmImg, setFarmImg] = useState()
    const [totalKgPrice, setTotalKgPrice] = useState("0.00")
    const [paymentData, setPaymentData] = useState({
        farmly_fee: 0,
        logistics_fee: 0,
        import_duties: 0,
        import_duties_adjustment: 0,
        euro_value: 0
    })
    const newDate = new Date
    const day = ("0" + newDate.getDate().toString()).slice(-2)
    const month = ("0" + (newDate.getMonth() + 1).toString()).slice(-2)
    const year = newDate.getFullYear().toString()
    const dateString = [month, day, year].join('-')
    const [showReview, setShowReview] = useState(false)
    const [showReviewDone, setShowReviewDone] = useState(false)
    const [modalMessage, setModalMessage] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [reviewInfo, setReviewInfo] = useState({
        coffeeId: coffeeId,
        roasterId: userContext.user ? userContext.user.id : null,
        date: dateString,
        score: null,
        comment: null,
        isAdmin: userContext.isAdmin ? true : false
    })
    const [reviewDone, setReviewDone] = useState({})
    const [arrayReviews, setArrayReviews] = useState([])
    const [hasReview, setHasReview] = useState(false)

    const toggleFavorite = () => {
        setIsFavorite(!isFavorite)
        !isFavorite && axios.post(`${server}/favorites`, {
            coffee_id: props.coffeeId,
            roaster_id: userContext.user.id
        }) && setmodalFavorite(true)
        !!isFavorite && axios.delete(`${server}/favorites`, {
            data: {
                coffee_id: props.coffeeId,
                roaster_id: userContext.user.id
            }
        })&& setmodalUnfavorite(true)
    }

    const createReview = () => {
        axios.post(`${server}/createReview`, reviewInfo)
        setShowReview(false)
        setModalMessage("Review created, to see it click in the coffee.  Wait a few seconds...")
        toggleModal()
        setHasReview(true)
        setTimeout(function () {
            refreshPage()
        }, 1500);
    }

    const deleteReview = () => {
        axios.delete(`${server}/deleteReview/${coffeeId}/${userContext.user.id}`)
        setShowReviewDone(false)
        setModalMessage("Review deleted. Wait a few seconds...")
        toggleModal()
        setHasReview(false)
        setTimeout(function () {
            refreshPage()
        }, 1500);
    }

    const toggleModal = () => {
        setShowModal(!showModal)
    }

    function refreshPage() {
        window.location.reload();
    }
    
    useEffect(() => {
        if (userContext.user) {
            axios.get(`${server}/searchReview/${coffeeId}/${userContext.user.id}`)
                .then(review => {
                    setReviewDone(review.data)
                    if (review.data) {
                        setHasReview(true)
                        // contRemainReviews()
                    }

                })
        }
    }, [hasReview])

    // const triggerRequest = () => {
    //     setRequestPopup(!requestPopup)
    // }

    // const setModalCommentSample = () => {
    //     setcommentSample(true)
    // }

    useEffect(() => {
        axios.get(`${server}/paymentData`)
            .then(res => setPaymentData(res.data))
    }, [])

    useEffect(() => {
        if (props.coffee && paymentData) {
            const sackPrice = (!isCo()||!isPt()||!userContext.isProd()) ? totalCoffeePrice({ ...props.coffee, quantity: '1' }, paymentData) : parseFloat(props.coffee.sackPrice);
            if(coffee.origin == 'Brazil') {
                if(isBr()) setTotalKgPrice(isNaN(sackPrice) ? "0.00" : (coffee.sackPrice/60).toFixed(2))
                else setTotalKgPrice(isNaN(sackPrice) ? "0.00" : toEuro((sackPrice / 60), paymentData, coffee).toFixed(2))
            }
            else {
                if(isBr() || isCo()) setTotalKgPrice(isNaN(sackPrice) ? "0.00" : (coffee.sackPrice/70).toFixed(2))
                else setTotalKgPrice(isNaN(sackPrice) ? "0.00" : (sackPrice / 70).toFixed(2))
            }
          
        }
    }, [paymentData, props.coffee])


    useEffect(() => {
        if (userContext.user != null && !userContext.user.isProd) {
            axios.get(`${server}/favorites/${userContext.user.id}/${props.coffeeId}`)
                .then(res => res.data.length && setIsFavorite(true))
                .catch(setIsFavorite(false))
        }
        else {
            setIsFavorite(false)
        }
    }, [])

    useEffect(() => {
        if (props.imageUrl) {
            storage.ref('producers').child(props.imageUrl.split(',')[0]).getDownloadURL()
                .then(imgUrl => setCoffeeImageUrl(imgUrl))
                .catch(() => { })
        }
    }, [])

    useEffect(() => {
        if (props.cardImg) {
            storage.ref('producers').child(props.cardImg).getDownloadURL()
                .then(img => setCardImg(img))
                .catch(() => { })
        }
    }, [])


    useEffect(() => {
        axios.get(`${server}/producer/${props.producerId}`)
            .then(user => {
                if (user.data.farmImg) {
                    storage.ref('producers').child(user.data.farmImg.length > 1 ? user.data.farmImg.split(',')[0] : user.data.farmImg).getDownloadURL()
                        .then(imgUrl => setFarmImg(imgUrl))
                        .catch(() => { })
                }
            })
            .catch(setIsFavorite(false))
    }, [])

    return (

        <>
        <div className={props.className || 'coffee-card'} id={loadFlag ? 'load-coffees' : ''}
            style={{ backgroundColor: farmlyColors.desertSand, width: props.width }}
            onMouseEnter={() => props.noHover ? setHover(false) : setHover(true)} onMouseLeave={() => setHover(false)}>
            <img id='coffee-card-footer' style={{
                bottom: hover ? '55%' : '0%'
            }} src={footerArt} alt='' />
            <img id='coffee-card-image' style={{
                opacity: hover ? '0' : '1',
                width: '100%'
            }} src={cardImg || coffeeImageUrl || farmImg || coffeePicture3} alt='' />
            {hasReview ?
                <FontAwesomeIcon className="comment-icon-card commented" icon={faCommentAlt}
                style={{ width: '30px', height: '30px', zIndex: '2' }}
                onClick={() => setShowReviewDone(true)}  />
                :
                <FontAwesomeIcon className="comment-icon-card" icon={faCommentAlt}
                style={{ width: '30px', height: '30px', zIndex: '2' }}
                onClick={() => setShowReview(true)}  />
            }
            {((userContext.user && !userContext.user.isProd) || !userContext.user) && <FontAwesomeIcon className="favorite-icon-card" icon={faStar}
                style={isFavorite ? { color: '#EA1', width: '30px', height: '30px', zIndex: '2' } : { width: '30px', height: '30px', zIndex: '2' }}
                onClick={userContext.user ? toggleFavorite : props.triggerRequest}/>}
            <div className={props.coffeePage ? "farmCode-info" : props.coffeeSample ? "farmCode-roaster-sample" : "farmCode-carousel"}>Code: {props.farmCode}</div>
            <div id="underlay" style={{
                height: hover ? '55%' : '0%'
            }} />
            <Link to={`/coffee/${props.coffeeId}`} className="coffee-card-info-container" style={{
                height: hover ? '65%' : '32%'
            }}>

                <div id="coffee-card-info-labels" style={{ flexDirection: 'row', width: '100%', justifyContent: 'flex-start' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>

                        <label className="coffee-card-label">{isPt() ? 'Fazenda' : (isCo() ? 'Granja' : 'Farm')}:</label>
                        <label className="coffee-card-info" style={hover ? { padding: '5px' } : {}}>{props.farmName}</label>
                        {hover && <label className="coffee-card-label">{isPt() ? 'Variedade' : (isCo() ? 'Variedad' :'Variety')}:</label>}
                        {hover && <label className="coffee-card-info" style={hover ? { padding: '5px' } : {}}>{props.variety}</label>}
                        <label className="coffee-card-label">{isPt() ? 'Pontuação' : (isCo() ? 'Puntuación' : 'Score')}:</label>
                        <label className="coffee-card-score" style={hover ? { padding: '5px' } : {}}>{props.score}</label>
                    </div>
                    <div style={!hover ? { display: 'flex', flexDirection: 'column', width: '50%', justifyContent: 'flex-end', textAlign: 'right' } : { display: 'flex', flexDirection: 'column', width: '50%' }}>
                        {hover && <label className="coffee-card-label">{isPt() ? 'Notas Sensoriais' : (isCo() ? 'Notas sensoriales' : 'Sensory Notes')}:</label>}
                        {hover && <label className="coffee-card-info" style={hover ? { padding: '5px' } : {}}>{isPt() ? props.sensoryNotes : props.sensoryNotesEn}</label>}
                        {!coffee.origin == 'Brazil' ? 
                            <label className="coffee-card-price" style={hover ? { padding: '5px' } : {}}>{`€${totalKgPrice}/Kg`}</label>
                            :
                            <label className="coffee-card-price" style={hover ? { padding: '5px' } : {}}>{(isPt() && coffee.origin == "Brazil") ? `R$${totalKgPrice}/Kg` : `€${totalKgPrice}/Kg`}</label>}
                    </div>
                </div>
                {<FontAwesomeIcon id='coffee-card-icon' icon={faArrowRight} />}
            </Link>
            {successFavorite && <FarmlyModal closeModal={() => { setmodalFavorite(false)}}>
            <label>
                {isPt() ? 'Café adicionado aos favoritos com sucesso !' : isCo() ?  '¡Café añadido con éxito a los favoritos!' :'Coffee successfully added to favorites !'}
            </label>
            </FarmlyModal> }
            {successUnfavorite && <FarmlyModal closeModal={() => setmodalUnfavorite(false)}>
            <label>
                {isPt() ? 'Café retirado dos favoritos com sucesso !' : isCo() ?  '¡Café eliminado con éxito de los favoritos!' :'Coffee successfully removed from favorites!'}
            </label>
            </FarmlyModal> }
            {showReview && <FarmlyModal closeModal={() => setShowReview(false)}>
                    <label id="request-title">
                        Review this coffee!
                        </label>
                    <Fragment>
                        <div className="review-content">
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <label id="request-text">How was your experience with this coffee?</label>
                                <div class="rating">
                                    <input type="radio" id="star5" name="rating" onClick={() => setReviewInfo({ ...reviewInfo, score: 5 })} />
                                    <label class="full" for="star5" ></label>
                                    <input type="radio" id="star4half" name="rating" onClick={() => setReviewInfo({ ...reviewInfo, score: 4.5 })} />
                                    <label class="half" for="star4half" ></label>
                                    <input type="radio" id="star4" name="rating" onClick={() => setReviewInfo({ ...reviewInfo, score: 4 })} />
                                    <label class="full" for="star4" ></label>
                                    <input type="radio" id="star3half" name="rating" onClick={() => setReviewInfo({ ...reviewInfo, score: 3.5 })} />
                                    <label class="half" for="star3half" ></label>
                                    <input type="radio" id="star3" name="rating" onClick={() => setReviewInfo({ ...reviewInfo, score: 3 })} />
                                    <label class="full" for="star3" ></label>
                                    <input type="radio" id="star2half" name="rating" onClick={() => setReviewInfo({ ...reviewInfo, score: 2.5 })} />
                                    <label class="half" for="star2half" ></label>
                                    <input type="radio" id="star2" name="rating" onClick={() => setReviewInfo({ ...reviewInfo, score: 2 })} />
                                    <label class="full" for="star2" ></label>
                                    <input type="radio" id="star1half" name="rating" onClick={() => setReviewInfo({ ...reviewInfo, score: 1.5 })} />
                                    <label class="half" for="star1half" ></label>
                                    <input type="radio" id="star1" name="rating" onClick={() => setReviewInfo({ ...reviewInfo, score: 1 })} />
                                    <label class="full" for="star1"></label>
                                    <input type="radio" id="starhalf" name="rating" onClick={() => setReviewInfo({ ...reviewInfo, score: 0.5 })} />
                                    <label class="half" for="starhalf" ></label>
                                </div>
                            </div>
                            <br />
                            <div style={{ textAlign: 'start' }}>
                                <label id="request-text">Tell us more about it! </label>
                                <label id="request-text"> Make some comments below:</label>
                                <div className="producer-profile-row" style={{ width: '100%' }}>
                                    <FarmlyInput textArea
                                        value={reviewInfo.comment} setValue={value => setReviewInfo({ ...reviewInfo, comment: value })}
                                        styleClass="coffee-profile-review-input about-paragraph" style={{ height: '10vw' }} elementId="name" />
                                </div>
                            </div>
                        </div>
                        <div style={{ textAlign: '-webkit-right' }}>
                            <div className="new-review-button" onClick={() => {
                                createReview()
                                setShowReview(false)
                            }}>Send Review</div>
                        </div>
                    </Fragment>
            </FarmlyModal>}
            {showReviewDone && <FarmlyModal closeModal={() => setShowReviewDone(false)}>
                    <label id="request-title">
                        This was your review
                    </label>
                    <Fragment>
                        <div className="review-content">
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <label id="request-text">The score you gave for your experience with this coffee was:</label>
                                <div class="rating-delete">
                                    <input type="radio" id="star5" name="rating-delete" />
                                    <label class="full" for="star5" style={reviewDone.score == 5 ? { color: '#FFD700' } : { color: '#ddd' }}></label>
                                    <input type="radio" id="star4half" name="rating-delete" />
                                    <label class="half" for="star4half" style={reviewDone.score >= 4.5 ? { color: '#FFD700' } : { color: '#ddd' }} ></label>
                                    <input type="radio" id="star4" name="rating-delete" />
                                    <label class="full" for="star4" style={reviewDone.score >= 4 ? { color: '#FFD700' } : { color: '#ddd' }}></label>
                                    <input type="radio" id="star3half" name="rating-delete" />
                                    <label class="half" for="star3half" style={reviewDone.score >= 3.5 ? { color: '#FFD700' } : { color: '#ddd' }}></label>
                                    <input type="radio" id="star3" name="rating-delete" />
                                    <label class="full" for="star3" style={reviewDone.score >= 3 ? { color: '#FFD700' } : { color: '#ddd' }}></label>
                                    <input type="radio" id="star2half" name="rating-delete" />
                                    <label class="half" for="star2half" style={reviewDone.score >= 2.5 ? { color: '#FFD700' } : { color: '#ddd' }}></label>
                                    <input type="radio" id="star2" name="rating-delete" />
                                    <label class="full" for="star2" style={reviewDone.score >= 2 ? { color: '#FFD700' } : { color: '#ddd' }}></label>
                                    <input type="radio" id="star1half" name="rating-delete" />
                                    <label class="half" for="star1half" style={reviewDone.score >= 1.5 ? { color: '#FFD700' } : { color: '#ddd' }}></label>
                                    <input type="radio" id="star1" name="rating-delete" />
                                    <label class="full" for="star1" style={reviewDone.score >= 1 ? { color: '#FFD700' } : { color: '#ddd' }}></label>
                                    <input type="radio" id="starhalf" name="rating-delete" />
                                    <label class="half" for="starhalf" style={reviewDone.score >= 0.5 ? { color: '#FFD700' } : { color: '#ddd' }}></label>
                                </div>
                            </div>
                            <br />
                            <div style={{ textAlign: 'start' }}>
                                <label id="request-text">Comments: </label>
                                <div className="producer-profile-row" style={{ width: '100%' }}>
                                    <label styleClass="coffee-profile-review-input about-paragraph" style={{ height: '10vw' }} elementId="name">{reviewDone.comment}</label>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="new-review-button" style={{ backgroundColor: 'var(--farmly-fuzzy)' }}
                                onClick={() => {
                                    deleteReview()
                                }}>
                                Delete Review</div>
                        </div>
                    </Fragment>
            </FarmlyModal>}
            {showModal && <FarmlyModal id="page-modal" closeModal={toggleModal}>{modalMessage}</FarmlyModal>}
        </div >
        </>
    )


}

export default CoffeeCard