import React, { Fragment, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Main from './Main.jsx'
import './LandingPageCupping.css'
import './ThanksForSigning.css'
import Thanks from './ThanksForSigning.jsx'
import { isPt, server, isBr, acceptedCookies } from '../../common'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import ReactGA from 'react-ga'

const separatorSeaSalt = require('../../assets/topinho.png')
const BottomSeaSalt = require('../../assets/bottomunderlay.png')
const separatorDiaphonous = require('../../assets/topinho2.png')
const separatorDiaphonousBottom = require('../../assets/bottomcut.png')
const separatorBlueStone = require('../../assets/topinho3.png')
const CoffeeDecoration = require('../../assets/coffee_decoration_homepage.jpg')
const CoffeeCupping = require('../../assets/Coffeecolheita.jpg.jpeg')// Imagem que usaremos
const firstWave = require('../../assets/wave-first.jpeg')



const LandingPageCupping = props => {

    const history = useHistory()
    const [userData, setUserData] = useState({})
    const [userRegistered, setUserRegistered] = useState(false)
    const [pleaseType, setPleaseType] = useState(false)

    const register = () => {
        if(userData.email != null){
            setUserRegistered(true)
            axios.post(`${server}/newRegister`, { email: userData.email })   
        }
        else setPleaseType(true)
        
    }

    return (
        <Fragment>
            {!userRegistered ?
            <Main noBanner>
                <div className="homepage-overview">
                    <div id="home-about" className="landing-begining" >
                        <div className="home-title" style={{ color: 'var(--farmly-fuzzy)' }}>
                            <span className="landing-title">Virtual Cupping </span>
                        </div>
                        <div className="home-section-container" style={{ paddingBottom: '2em' }} >
                            <div className="homepage-text-container half-size">
                                <h2 className="bold" style={{ fontWeight: '600' }}>21/11 - 6pm </h2>
                                <h2 className="thin">
                                Meet the producer,
                                <br />
                                try their coffee and
                                <br />
                                buy it directly from the origin.
                                <br />
                                Do all that from home.
                            </h2>
                                <input className="landing-input" placeholder="Type your email here" value={userData.email} onChange={e => setUserData({ ...userData, email: e.target.value })}></input>
                                <br />
                                <button className="register-button" onClick={register}>Register for the cupping</button>
                                {pleaseType && <label className="login-fail-label">Please type an email first!</label>}
                                <h4 className="thin" style={{ lineHeight: '27px' }}>
                                    Click on the button above to register!
                                 </h4>
                            </div>
                        </div>
                        <h2 className="see-more"> See more </h2>
                        <div className="see-more-icon">
                            <FontAwesomeIcon icon={faAngleDown} style={{ height: '90px', width: '70px' }}></FontAwesomeIcon>
                        </div>
                        {/* <img className="wavy-separator" src={firstWave} alt="" /> */}
                    </div>

                    <div id="home-about" className="home-layer" >
                        <div className="home-title" style={{ color: 'var(--farmly-fuzzy)' }}>
                            <span className="bold">How does it work?</span>
                        </div>
                        <div className="home-section-container"  >
                            <div>
                                <img src={CoffeeCupping} className="coffee-colheita" alt="coffee beans" />
                            </div>
                            <div className="homepage-text-container half-size">
                                <br />
                            A lot of roasters, around this time of the year, would be visiting the origin to meet the producer that will supply them for the following months.
                            <br />
                                <br />
                            We decided to mimic this on an online event, so you will be able to order samples and try it along with the producer.
                            <br />
                                <br />
                            This way you will be able to buy directly from the origin without need to travel to the origin.
                            <br />
                                <br />
                            If you decide to buy any of the coffees FARMly will take care of all the export, import, and logistics so no need to worry about that, even if you are going to buy one bag of coffee.
                               {window.screen.width > 800 &&
                                    <Fragment>
                                        <br />
                                        <br />
                                        <br />
                                    </Fragment>
                                }
                            </div>
                        </div>
                        <div className="home-section-container" style={{ paddingBottom: '2em' }} >
                            <img src={CoffeeDecoration} className="coffee-colheita" alt="coffee beans" />
                            <div className="homepage-text-container half-size" style={{ alignItems: 'center' }}>
                                {window.screen.width > 800 &&
                                    <Fragment>
                                        <br />
                                        <br />
                                    </Fragment>
                                }
                                <input className="landing-input" placeholder="Type your email here" value={userData.email} onChange={e => setUserData({ ...userData, email: e.target.value })}></input>
                                <br />
                                <button className="register-button" onClick={register}>Register for the cupping</button>
                                {pleaseType && <label className="login-fail-label">Please type an email first!</label>}
                                <h4 className="obs-register" style={{ lineHeight: '27px' }}>
                                    Click on the button above to register!
                                 </h4>
                            </div>
                        </div>
                    </div>

                    {/* {!userContext.user && <img className="wavy-separator" src={separatorBlueStone} alt="" />} */}

                    <div id="home-signup-prompt" className="home-how-much">
                        <div className="home-title" style={{ color: 'black', margin: '0px' }}>
                            How much is it?
                    </div>
                        <br />
                        <div className="money-text">
                            We will ship you 5 samples of coffee for free, FARMly gets a small fee when you purchase the coffee from the producer, which is the payment for handling all of the logistics, export and import even for small quantities. So if in the end you don't buy coffee from the producer, no worries there is nothing to pay ; )
                        <br />
                            <br />
                        FARMly is shipping the samples for free to promote the connection between the producer and the roaster and to support small producers that need a little help to continue the great work that they are doing at the origin.
                    </div>
                        <div className="home-title" style={{ color: 'black', margin: '0px', fontSize: '25px' }}>
                            If you have any questions feel free to ask!
                    </div>
                        <div className="help-contacts">
                            <a target="_blank" rel="noopener noreferrer" href={`https://api.whatsapp.com/send?phone=+5527999802545`} style={{ display: 'flex', flexDirection: 'row', padding: '1vw' }}>
                                <FontAwesomeIcon className="whatsapp-icon-cart" icon={faWhatsapp} style={{ color: 'white' }} />
                                <label style={{ color: 'white' }}> +55 27 99980 2545</label>
                            </a>

                            <div style={{ display: 'flex', flexDirection: 'row', padding: '1vw' }}>
                                <FontAwesomeIcon className='footer-contacts-icon' icon={faEnvelope} style={{ color: 'white' }} />
                                <label style={{ color: 'white' }}>lfaria@farmlyclub.com</label>
                            </div>
                        </div>
                    </div>

                </div>
            </Main >
            :
            <Thanks email={userData.email} />
            }
        </Fragment>
    )
}
export default LandingPageCupping