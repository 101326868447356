import React, { useState, useEffect, useContext, Fragment } from 'react'
import Avatar from '@material-ui/core/Avatar';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import { farmlyColors, server, isBr } from '../../common.js'
import axios from 'axios'
import { storage } from '../../firebase'
import './SelectSamples.css'
import CartContext from '../../context/cart/CartContext'
import SampleContext from '../../context/sample/SampleContext'
import UserContext from '../../context/user/UserContext'
import Divider from '@material-ui/core/Divider'

import {
    toEuro,
    totalCoffeePrice,
    logisticsFee,
    importDuties,
    farmlyFee,
    producersPriceKg
} from '../templates/pricing.js'

const coffeeImage = require('../../assets/produtor3.jpg')
const coffeePicture3 = require('../../assets/coffee_farm.jpg')

const SelectSamples = props => {
    const {coffee, requestedSamples, userData, auxSamples, setAuxSamples} = props
    const [cardImg, setCardImg] = useState()
    const [farmImg, setFarmImg] = useState()
    const [coffeeImageUrl, setCoffeeImageUrl] = useState()
    const [isSelected, setIsSelected] = useState(false)
    const sampleContext = useContext(SampleContext)
    const userContext = useContext(UserContext)
    const [error, setError] = useState(false)

    

    useEffect(() => {
        if (coffee.imageUrl) {
            storage.ref('producers').child(coffee.imageUrl.split(',')[0]).getDownloadURL()
                .then(imgUrl => setCoffeeImageUrl(imgUrl))
                .catch(() => { })
        }
    }, [])

    useEffect(() => {
        if (coffee.cardImg) {
            storage.ref('producers').child(coffee.cardImg).getDownloadURL()
                .then(img => setCardImg(img))
                .catch(() => { })
        }
    }, [])

    useEffect(() => {
        axios.get(`${server}/producer/${coffee.producerId}`)
            .then(user => {
                if (user.data.farmImg) {
                    storage.ref('producers').child(user.data.farmImg.length > 1 ? user.data.farmImg.split(',')[0] : user.data.farmImg).getDownloadURL()
                        .then(imgUrl => setFarmImg(imgUrl))
                        .catch(() => { })
                }
            })
            .catch()
    }, [])

    useEffect(() => {
        requestedSamples.map(sample=>{
            if(sample.id == coffee.id){
                setIsSelected(true)
            }
        })
    }, [])

    const requestSubmit = async () => {
        await axios.post(`${server}/request`, {
            roaster: userData,
            coffee: coffee.id
        })
            .then(res => {
                const { answer } = res.data
                if (answer === 'success') {
                    console.log("sucesso");
                    // setTimeout(function () {
                        setAuxSamples(!auxSamples)
                    // }, 1500);
                }
            })
            .catch(err => {
                console.log('server error', err)
                setError(true)
            })
        
    }

    const removeSample = () =>{
        console.log(parseInt(userContext.user.id), coffee.id)
        axios.delete(`${server}/deleteRequest/${userContext.user.id}/${coffee.id}`)
        .then(res => {
            setAuxSamples(!auxSamples)
            console.log("rolou");
            console.log(res.data);
            })
            .catch(err => {
                console.log('server error', err)
                setError(true)
            })
    }

    return (
        <>
            {window.screen.width>768 ?
            <div>
                <div className="sample-card-live-container">
                    <div className="sample-card-live-img-container">
                        <img src={cardImg || coffeeImageUrl || farmImg || coffeePicture3} className="card-sample-live-image"></img>
                        <div className="sample-card-live-code">
                            {coffee.farmCode}
                        </div>
                    </div>
                    <div className="sample-card-info-container">
                        <p className="sample-card-live-producer-name">{coffee.producer}</p>
                        <div><b>Process:</b> {coffee.process}</div>
                        <div><b>Score:</b> {coffee.score}pts</div>
                    </div>
                    <div className="sample-card-live-button-container">
                        {isSelected ?
                            <button className="sample-card-live-selected-button" onClick={()=> {
                                setIsSelected(!isSelected)
                                removeSample()}}>Selected</button>
                            :
                            <button className="sample-card-live-select-button" onClick={()=> {
                                setIsSelected(!isSelected)
                                requestSubmit()}}>Select</button>
                        }
                    </div>

                </div>
            <br/>
            <Divider variant="middle" style={{ height: '2px', backgroundColor: 'rgb(102, 102, 102)', marginLeft: '0px', opacity: '0.2' }} />

            </div>
            :
            window.screen.width>425?
            <div>
                <div className="sample-card-live-container">
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                            <div className="sample-card-live-img-container">
                            <img src={cardImg || coffeeImageUrl || farmImg || coffeePicture3} className="card-sample-live-image"></img>
                            <div className="sample-card-live-code">
                                {coffee.farmCode}
                            </div>
                        </div>
                        <div style={{width: '30%'}}>
                            <p className="sample-card-live-producer-name">{coffee.producer}</p>
                        </div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                            <div className="sample-card-info-container">
                            <div><b>Process:</b> {coffee.process}</div>
                            <div><b>Score:</b> {coffee.score}pts</div>
                        </div>
                        <div className="sample-card-live-button-container">
                            {isSelected ?
                                <button className="sample-card-live-selected-button" onClick={()=> {
                                    setIsSelected(!isSelected)
                                    removeSample()}}>Selected</button>
                                :
                                <button className="sample-card-live-select-button" onClick={()=> {
                                    setIsSelected(!isSelected)
                                    requestSubmit()}}>Select</button>
                            }
                        </div>
                    </div>

                </div>
                <br/>
                <Divider variant="middle" style={{ height: '2px', backgroundColor: 'rgb(102, 102, 102)', marginLeft: '0px', opacity: '0.2' }} />
                
            </div>
            :
            <div>
                <div className="sample-card-live-container">
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                            <div className="sample-card-info-container">
                            <div><b>Code:</b> {coffee.farmCode}</div>
                            <div><b>Score:</b> {coffee.score}pts</div>
                        </div>
                        <div className="sample-card-live-button-container">
                            {isSelected ?
                                <button className="sample-card-live-selected-button" onClick={()=> {
                                    setIsSelected(!isSelected)
                                    removeSample()}}>Selected</button>
                                :
                                <button className="sample-card-live-select-button" onClick={()=> {
                                    setIsSelected(!isSelected)
                                    requestSubmit()}}>Select</button>
                            }
                        </div>
                    </div>

                </div>
                <br/>
                <Divider variant="middle" style={{ height: '2px', backgroundColor: 'rgb(102, 102, 102)', marginLeft: '0px', opacity: '0.2' }} />

            </div>
            }
        </>
    )
}
export default SelectSamples
