import styled from 'styled-components';

export const SampleCartActionButton = styled.div`
    width: 100%;
    background-color: #3f51b5;
    color: #fff;
    cursor: pointer;
    padding: 6px;
`

