import {
    ADD_TO_CART,
    REMOVE_FROM_CART,
    LOAD_CART,
    CLEAR_CART,
    PRODUCER_LOGIN
} from '../types.js'

export default ( state, action ) => {
    switch(action.type) {
        case ADD_TO_CART:
            return {
                ...state,
                cartList: action.payload
            }
        case REMOVE_FROM_CART:
            return {
                ...state,
                cartList: action.payload
            }
        case LOAD_CART:
            return {
                ...state,
                cartList: action.payload
            }
        case CLEAR_CART:
            return {
                ...state,
                cartList: []
            }
        case PRODUCER_LOGIN:
            return {
                ...state,
                cartList: null
            }
        default:
            return state
    }
}