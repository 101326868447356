import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { farmlyColors } from "../../common.js";
import { Link } from "react-router-dom";
import { storage } from "../../firebase";
import { jsPDF } from "jspdf";
import "./ControlPanel.css";
import { useEffect } from "react";
const footerArt = require("../../assets/footer-art.png");
const avatarPlaceholder = require("../../assets/avatar.png");

const AdminUserCard = (props) => {
  const { user, width, height, isProd } = props;
  const [prodImageUrl, setProdImageUrl] = useState("");

  useEffect(() => {
    getImage();
  }, [user]);

  const getImage = () => {
    user.profileImg &&
      storage
        .ref("producers")
        .child(`${user.profileImg}`)
        .getDownloadURL()
        .then((url) => {
          setProdImageUrl(url);
        })
        .catch((e) => {});
  };

  const createPDF = () => {
    const doc = new jsPDF();
    var line = 160; // Line height to start text at
    var lineHeight = 5;
    var leftMargin = 20;
    var wrapWidth = 180;
    var longString = `${user.description} `;

    var splitText = doc.splitTextToSize(longString, wrapWidth);
    for (var i = 0, length = splitText.length; i < length; i++) {
      // loop thru each line and increase
      doc.text(splitText[i], leftMargin, line);
      line = lineHeight + line;
    }
    doc.setLineWidth(10, 10);
    doc.addFont("Roboto", "Roboto", "normal");
    doc.setFont("Roboto");
    doc.setFontSize(40);
    doc.text("History of producers", 35, 40);
    doc.setFontSize(15);
    doc.text(`${user.name}:`, 40, 55);
    doc.setFontSize(12);
    doc.addImage(`${user.profileImg}`|| avatarPlaceholder, "JPEG"||"PNG",  55, 60, 100, 80);
    doc.save("lala.pdf");
  };

  return (
    <>
     {props.isProd ? (
    <div>
      <Link
        to={{
          pathname: `${isProd ? "producer" : "roaster"}/profile/${user.id}`,
        }}
        className="user-card"
        style={{
          backgroundColor: "var(--farmly-offWhite)",
          width: width,
          height: height,
          overflow: "hidden",
          cursor: "pointer",
        }}
      >
        <img
          id="user-card-image"
          style={{
            objectFit: "cover",
            width: "33%",
            marginTop: "21px",
          }}
          src={prodImageUrl || avatarPlaceholder}
          alt=""
        />

        <span className="user-card-name">
          {user.name} {isProd && " ~ " + user.farmName}
        </span>
        <div
          id="coffee-card-info-container"
          id="user-card-info-container"
          style={{ width: "65%", height: "80%" }}
        >
          <div id="user-card-info-labels" className="admin-users-infobox">
            <label className="user-card-label">Email:</label>
            <label className="user-card-info">{user.email}</label>
            <label className="user-card-label">Telefone:</label>
            <label className="user-card-info">{user.phoneNumber}</label>
            <label className="user-card-label">Região:</label>
            <label className="user-card-info">
              {user.region || user.country}
            </label>
            <label className="user-card-label">Região:</label>
            <label className="user-card-info">
              {user.region || user.country}
            </label>
            <label className="user-card-label">convidado por:</label>
            <label className="user-card-info">
              {user.invitedBy || "não possui convite"}
            </label>
            <label className="user-card-label">descrição:</label>
            <label className="user-card-info">{user.description}</label>
          </div>
        </div>
      </Link>
      <button className="button-generatorPDF" onClick={createPDF}>createPDF</button>
    </div>
   
    ) : (
      <div>
      <Link
        to={{
          pathname: `${isProd ? "producer" : "roaster"}/profile/${user.id}`,
        }}
        className="user-card"
        style={{
          backgroundColor: "var(--farmly-offWhite)",
          width: width,
          height: height,
          overflow: "hidden",
          cursor: "pointer",
        }}
      >
        <img
          id="user-card-image"
          style={{
            objectFit: "cover",
            width: "33%",
            marginTop: "21px",
          }}
          src={prodImageUrl || avatarPlaceholder}
          alt=""
        />

        <span className="user-card-name">
          {user.name} {isProd && " ~ " + user.farmName}
        </span>
        <div
          id="coffee-card-info-container"
          id="user-card-info-container"
          style={{ width: "65%", height: "80%" }}
        >
          <div id="user-card-info-labels" className="admin-users-infobox">
            <label className="user-card-label">Email:</label>
            <label className="user-card-info">{user.email}</label>
            <label className="user-card-label">Telefone:</label>
            <label className="user-card-info">{user.phoneNumber}</label>
            <label className="user-card-label">Região:</label>
            <label className="user-card-info">
              {user.region || user.country}
            </label>
            <label className="user-card-label">Região:</label>
            <label className="user-card-info">
              {user.region || user.country}
            </label>
            <label className="user-card-label">convidado por:</label>
            <label className="user-card-info">
              {user.invitedBy || "não possui convite"}
            </label>
            <label className="user-card-label">descrição:</label>
            <label className="user-card-info">{user.description}</label>
          </div>
        </div>
      </Link>
     
    </div>
    )}
 </>

  );
  
};

export default AdminUserCard;
