import React, { useState, useEffect, useContext, Fragment } from 'react'
import Avatar from '@material-ui/core/Avatar';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import { farmlyColors, server, isBr } from '../../../common.js'
import axios from 'axios'
import { storage } from '../../../firebase'
import './CuppingLiveSmallCard.css'
import CartContext from '../../../context/cart/CartContext'
import {
    toEuro,
    totalCoffeePrice,
    logisticsFee,
    importDuties,
    farmlyFee,
    producersPriceKg
} from '../../templates/pricing.js'
import UserContext from '../../../context/user/UserContext.js'


const coffeeImage = require('../../../assets/produtor3.jpg')
const coffeePicture3 = require('../../../assets/coffee_farm.jpg')

const CuppingLiveSmallCard = props => {
    const {coffee, toggleModal, setModalMessage, userData, setAuxSamples, auxSamples } = props
    const [expanded, setExpanded] = useState(false);
    const [sackQuantity, setSackQuantity] = useState(1)
    const cartContext = useContext(CartContext)
    const [isInCart, setIsInCart] = useState(false)
    const userContext = useContext(UserContext)
    const [paymentData, setPaymentData] = useState({
        farmly_fee: 0,
        logistics_fee: 0,
        import_duties: 0,
        import_duties_adjustment: 0,
        euro_value: 0
    })
    const [totalKgPrice, setTotalKgPrice] = useState("0.00")
    const priceKgEuro = toEuro(totalKgPrice, paymentData, coffee).toFixed(2)
    const [cardImg, setCardImg] = useState()
    const [farmImg, setFarmImg] = useState()
    const [coffeeImageUrl, setCoffeeImageUrl] = useState()
    const [error, setError] = useState(false)


    useEffect(() => {
        if(cartContext && cartContext.cartList) {
            cartContext.cartList.map(item => {
                if (item.id == coffee.id) {
                    setIsInCart(true)
                }
            })
    }
    }, [])

    useEffect(() => {
        axios.get(`${server}/paymentData`)
            .then(res => setPaymentData(res.data))
    }, [])

    useEffect(() => {
        if (coffee && paymentData) {
            const price = totalCoffeePrice({ ...coffee, quantity: sackQuantity }, paymentData)
            const kgPrice = totalCoffeePrice({ ...coffee, quantity: '1' }, paymentData)
            setTotalKgPrice(isNaN(kgPrice) ? "0.00" : (kgPrice / 60).toFixed(2))
        }
    }, [coffee, paymentData, sackQuantity])

    const decreaseQuantity = () =>{
        if(sackQuantity>0){
            setSackQuantity(sackQuantity-1)
        }
    }
    const increaseQuantity = () =>{
        setSackQuantity(sackQuantity+1)
    }

    const handleExpandClick = () => {
        setExpanded(!expanded);
      };

      const renderNotes = () =>
      coffee.sensory_notes_en.split(',').map(note=>{
         return(
             <div className={(note.length < 7) ? 'sensory-red' : (((note.length < 10 && note.length > 6) ? 'sensory-lime' : 'sensory-orange'))}>{note}</div>
         )
     })
        const addToCart = () => {
            cartContext.addToCart(parseInt(coffee.id), sackQuantity)
            setIsInCart(true)
            setModalMessage("Coffee Added!")
            toggleModal()
        }
    
        const updateCoffeeOnCart = () => {
            cartContext.cartList.map(item => {
                if (item.id == coffee.id) {
                    axios.patch(`${server}/coffeeSackRemain/${coffee}`, {
                        sackQuantity: sackQuantity - item.quantity,
                        sackRemain: coffee.sackRemain
                    })
                }
            })
            cartContext.updateCoffeeOnCart(parseInt(coffee.id), sackQuantity)
            setModalMessage("Cart updated!")
            toggleModal()
        }
        useEffect(() => {
            if (coffee.imageUrl) {
                storage.ref('producers').child(coffee.imageUrl.split(',')[0]).getDownloadURL()
                    .then(imgUrl => setCoffeeImageUrl(imgUrl))
                    .catch(() => { })
            }
        }, [])
    
        useEffect(() => {
            if (coffee.cardImg) {
                storage.ref('producers').child(coffee.cardImg).getDownloadURL()
                    .then(img => setCardImg(img))
                    .catch(() => { })
            }
        }, [])
    
        useEffect(() => {
            axios.get(`${server}/producer/${coffee.producerId}`)
                .then(user => {
                    if (user.data.farmImg) {
                        storage.ref('producers').child(user.data.farmImg.length > 1 ? user.data.farmImg.split(',')[0] : user.data.farmImg).getDownloadURL()
                            .then(imgUrl => setFarmImg(imgUrl))
                            .catch(() => { })
                    }
                })
                .catch()
        }, [])

        const removeSample = () =>{
            console.log(parseInt(userContext.user.id), coffee.id)
            axios.delete(`${server}/deleteRequest/${userContext.user.id}/${coffee.id}`)
            .then(res => {
                setAuxSamples(!auxSamples)
                console.log("rolou");
                console.log(res.data);
                })
                .catch(err => {
                    console.log('server error', err)
                    setError(true)
                })
        }
    return (
        <div style={{width: '100%'}}>
            <div className="live-small-card-container">
                <div className="second-container-live-card">
                    <div className="main-container-live-small-card">
                        <div className="third-container-live-small-card">
                            <div className="image-and-code-container-small-card">
                                <img src={cardImg || coffeeImageUrl || farmImg || coffeePicture3} className="card-small-live-image"></img>
                                <div className="card-small-live-code">
                                    {coffee.farmCode}
                                </div>
                                <br/>
                                <div className="medium-card-remove-sample" onClick={()=>removeSample()}>
                                    <u>Remove sample</u>
                                </div>
                            </div>
                            <div className="important-info-container-live-small-card">
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                <p className="small-card-live-producer-name">{coffee.producer}</p>
                                <IconButton
                                    onClick={handleExpandClick}
                                    aria-expanded={expanded}
                                    aria-label="show more"
                                    >
                                    <ExpandMoreIcon />
                                </IconButton>
                                </div>
                                <div className="small-card-info-title"><b>{isBr() ?'Processo:':'Process:'}</b> {coffee.process}</div>
                                <div style={{display: 'flex', flexDirection: 'row', marginTop: '25px'}}>
                                    <div className="small-card-info-title" style={{marginRight: '20px'}}><b>{true ? `€${totalKgPrice}`:`€${priceKgEuro}`}</b></div> {/*isBr()*/}
                                    <div className="small-card-info-title">{coffee.score}pts</div>
                                </div>
                            </div>
                        </div>
                        <Collapse in={expanded} timeout="auto" unmountOnExit>
                            <div className="third-container-live-medium-card">
                                <div className="card-medium-live-more-info-container">
                                <div><b>{isBr() ?'Variedade:':'Variety:'}</b></div>
                                <div>{coffee.variety}</div>
                                <br/>
                                <div><b>{isBr() ?'Região:':'Region:'}</b></div>
                                <div>{coffee.region}</div>
                                </div>
                                <div className="card-small-live-sensory-container">
                                    {coffee.sensory_notes_en != null &&
                                        renderNotes()
                                    }
                                </div>
                            </div>
                            {isBr() ?
                                <Fragment></Fragment>
                            :
                            <div>
                                <div className="card-medium-live-add-button-container">
                                {isInCart ? 
                                    <button className="card-small-live-add-button" onClick={updateCoffeeOnCart}>Update Cart</button>
                                :
                                    <button className="card-small-live-add-button" onClick={addToCart}>Add to Card</button>
                                }
                                </div>
                                <div className="card-medium-live-quantity-container">
                                    <button className="card-live-button-quantity" onClick={()=> decreaseQuantity()}>-</button>
                                    <input className="card-live-input-quantity" value={sackQuantity}></input>
                                    <button className="card-live-button-quantity" onClick={()=> increaseQuantity()}>+</button>
                                </div>
                            </div>
                        }
                        </Collapse>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CuppingLiveSmallCard
