import backend from '../../../../lib/backend'
import { useState, useEffect } from 'react';
import { server } from 'common';

const useSelectedCoffees = () => {


const [queryParams, setQueryParams] = useState({
    shipment: 12,
    taster: 0,
    section: 'tasted'
})

const [coffees, setCoffees] = useState([])
const [selectBoard, setSelectBoard] = useState('main')

useEffect(() => {
    getCoffees()
}, [queryParams, selectBoard])  

const getCoffees = () => {
    backend.get(`${server}/getCoffeesBySectionAndShipment/${queryParams.shipment}/${queryParams.taster}/${queryParams.section}`)
    .then(coffees => {
        setCoffees(coffees.data)
    })
    .catch(err => console.log(err))
}

return { coffees, queryParams, setQueryParams, selectBoard, setSelectBoard }

}

export default useSelectedCoffees