import React, { useContext, useState, useEffect, Fragment } from 'react'
import UserContext from '../../context/user/UserContext.js'

import Main from '../templates/Main'
import Title from '../templates/Title'
import SideMenu from '../templates/SideMenu'

import ViewCuppingSample from './ViewCuppingSample.jsx'
import SelectCupping from './SelectCupping.jsx'

import FarmlyModal from '../templates/FarmlyModal'
import FarmlyInput from '../templates/FarmlyInput'
import axios from 'axios'
import { server } from '../../common'
import Alert from '@material-ui/lab/Alert';
import { useHistory } from 'react-router-dom'
import Snackbar from '@material-ui/core/Snackbar';

const ControlCupping = props => {

    const userContext = useContext(UserContext)
    const history = useHistory()
    const options = [
        'Receber Amostras',
        'Selecionar Cafés'
    ]
    const [selectedOption, setSelectedOption] = useState(options[0])
    const [newSample, setNewSample] = useState(false)
    const [newCoffee, setNewCoffee] = useState({
        id: null,
        process: null,
    })
    const [coffees, setCoffees] = useState([])
    const [noCupping, setNoCupping] = useState(false)
    const [addToRecebidos, setAddToRecebidos] = useState(false)
    const [addToCupping, setAddToCupping] = useState(false)
    const [addToCuppingAndRecebidos, setAddToCuppingAndRecebidos] = useState(false)
    const [outOfCupping, setOutOfCupping] = useState(false)
    const [outOfRecebidos, setOutOfRecebidos] = useState(false)

    useEffect(() => {
        getCoffeesInit()
    }, [coffees])

    const getCoffeesInit = async () => {
        axios.get(`${server}/coffees`)
            .then(res => {
                setCoffees(res.data)
            })
    }

    const updateSample = async () => {
        const reqBody = {
            ...newCoffee
        }
        var n = new Date()
        const sent = n.getFullYear() + '-' + n.getMonth() + '-' + n.getDay()
        const received = n.getFullYear() + '-' + n.getMonth() + '-' + n.getDay()
        axios.patch(`${server}/coffees`, {
            id: reqBody.id,
            variety: reqBody.process,

        })
        axios.post(`${server}/sample`, {
            id: reqBody.id,
            sampleSentDate: sent,
            sampleReceivedDate: received,
        })
            .catch(e => console.log(e))
        setNewSample(false)
    }

    const AdminView = (selectedOption) => {
        switch (selectedOption) {
            default:
                return "Erro"
            case options[0]:
                return <ViewCuppingSample newSample={newSample} setNewSample={setNewSample} newCoffee={newCoffee} setNewCoffee={setNewCoffee}
                    setAddToCupping={setAddToCupping} setAddToRecebidos={setAddToRecebidos} setOutOfCupping={setOutOfCupping} setOutOfRecebidos={setOutOfRecebidos} 
                    setAddToCuppingAndRecebidos={setAddToCuppingAndRecebidos}/>
            case options[1]:
                return <SelectCupping />
        }
    }

    const renderCupping = (coffees) =>
        coffees.map((coffee) => {
            return (
                <div className="cupping-coffee" >
                    <div > Code: {coffee.farmCode}</div>
                    <button className="cancel-cupping-button" onClick={() => cancelCupping(coffee)}>x</button>
                </div >)
        })

    const cancelCupping = (coffee) => {
        axios.post(`${server}/sample`, {
            id: coffee.id,
            sampleSentDate: coffee.sampleSentDate,
            sampleReceivedDate: coffee.sampleReceivedDate,
            sampleTastedDate: "not-tasted",
            sampleApprove: coffee.sampleApprove
        })
            .catch(e => console.log(e))
    }

    const cuppingWarmUp = () => {
        let cuppingCoffees = coffees.filter((coffee) => coffee.sampleSentDate !== 'not-sent' && coffee.sampleReceivedDate !== 'not-received' && coffee.sampleTastedDate !== 'not-tasted' && coffee.sampleApprove !== 'approved')
        if (cuppingCoffees.length) {
            history.push({
                pathname: '/cupping',
                state: { cuppingCoffees: cuppingCoffees }
            })
            history.go()
        } else {
            setNoCupping(true)
        }
    }

    const handleAddToRecebidos = () => {
        setAddToRecebidos(false)
    }

    const handleAddToCupping = () => {
        setAddToCupping(false)
    }

    const handleAddToCuppingAndRecebidos = () => {
        setAddToCuppingAndRecebidos(false)
    }


    const handleOutOfCupping = () => {
        setOutOfCupping(false)
    }

    const handleOutOfRecebidos = () => {
        setOutOfRecebidos(false)
    }

    return (
        userContext.isAdmin ?
            <Main>
                <div style={{ width: '100%' }}>
                    {newSample &&
                        <FarmlyModal closeModal={() => setNewSample(false)}>
                            <div className="confirm-info-container">
                                <label className="pricing-info-title">Complete as informações sobre a amostra</label>
                                <br />
                                <label>Essa amostra foi associada ao "Produtor indefinido" com id: 193</label>
                                <br />
                                <label>Essa amostra será movida para a área de "Recebidos".</label>
                                <div className="pricing-info-item">
                                    <label className="pricing-info-label">Nome do produtor:</label>
                                    <FarmlyInput value={newCoffee.process} setValue={value => setNewCoffee({ ...newCoffee, process: value })}
                                        className="pricing-info-value" elementId="name" placeholder="Nome..." />
                                </div>
                                <br />
                                <label>O nome do produtor dessa amostra ficará na área "Lote" do cartão do café.</label>
                                <br />
                                <div className="checkout-buttons">
                                    <div className="cart-checkout-button" onClick={() => updateSample()}>Adicionar</div>
                                </div>
                            </div>
                        </FarmlyModal>}
                    {noCupping &&
                        <FarmlyModal closeModal={() => setNoCupping(false)}>
                            <div className="confirm-info-container" style={{ alignItems: 'center' }}>
                                <label className="pricing-info-title">Adicione cafés para o cupping!</label>
                                <br />
                                <label>Você não adicionou nenhum café para realizar o cupping!</label>
                                <br />
                                <label>Os cafés na área "Recebidos" podem ser adicionados para realização de cupping.</label>
                            </div>
                        </FarmlyModal>}
                    {addToRecebidos &&
                        <Snackbar open={addToRecebidos} autoHideDuration={6000} onClose={handleAddToRecebidos}
                        anchorOrigin={{vertical:'bottom', horizontal:'left'}} key={'bottom' + 'left'}>
                            <Alert icon={false} severity="warning">Esse café foi adicionado a área "Recebidos".</Alert>
                        </Snackbar>
                    }
                    {addToCupping &&
                        <Snackbar open={addToCupping} autoHideDuration={6000} onClose={handleAddToCupping}
                        anchorOrigin={{vertical:'bottom', horizontal:'left'}} key={'bottom' + 'left'}>
                            <Alert icon={false} severity="warning">Esse café foi adicionado ao cupping.</Alert>
                        </Snackbar>
                    }
                    {outOfCupping &&
                        <Snackbar open={outOfCupping} autoHideDuration={6000} onClose={handleOutOfCupping}
                        anchorOrigin={{vertical:'bottom', horizontal:'left'}} key={'bottom' + 'left'}>
                            <Alert icon={false} severity="warning">Esse café não será provado no cupping. Ele permanece na área "Recebidos".</Alert>
                        </Snackbar>
                    }
                    {outOfRecebidos &&
                        <Snackbar open={outOfRecebidos} autoHideDuration={6000} onClose={handleOutOfRecebidos}
                        anchorOrigin={{vertical:'bottom', horizontal:'left'}} key={'bottom' + 'left'}>
                            <Alert icon={false} severity="warning">Esse café retornou a área "Pendentes".</Alert>
                        </Snackbar>
                    }
                    {addToCuppingAndRecebidos &&
                        <Snackbar open={addToCuppingAndRecebidos} autoHideDuration={6000} onClose={handleAddToCuppingAndRecebidos}
                        anchorOrigin={{vertical:'bottom', horizontal:'left'}} key={'bottom' + 'left'}>
                            <Alert icon={false} severity="warning">Esse café foi adicionado ao cupping e a seção "Recebidos".</Alert>
                        </Snackbar>
                    }
                    <Title title="Painel de Controle" />
                    <div className="cupping-container">
                        <label className="side-menu-cupping">Cupping</label>
                        {window.screen.width > 850 && <br />}
                        <div className="coffee-selected-cupping">
                            {renderCupping(coffees.filter((el) => el.sampleReceivedDate !== 'not-received' && el.sampleTastedDate !== 'not-tasted' && el.sampleApprove !== 'approved')
                            )}
                        </div>
                        <br />
                        <button className="start-cupping-button" onClick={() => cuppingWarmUp()}>Start Cupping</button>
                    </div>
                    {AdminView(selectedOption)}
                </div>
            </Main >
            : <div>403 Unauthorized{console.log(userContext)}</div>
    )
}
export default ControlCupping