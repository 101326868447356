import React, { useState, useEffect } from 'react'

import FarmlyInput from '../templates/FarmlyInput.jsx'
import axios from 'axios'
import { server } from '../../common.js'

const Payments = props => {
    
    const [paymentData, setPaymentData] = useState({
        euro_value: 0,
        farmly_fee: 0,
        logistics_fee: 0,
        import_duties: 0,
        import_duties_adjustment: 0
    })

    useEffect(() => {
        refreshPaymentData()
    }, [])

    const refreshPaymentData = () => {
        axios.get(`${server}/paymentData`)
            .then(res => {
                setPaymentData(res.data)
            })
            .catch(err => {
                console.log(err)
                alert("Erro de servidor")
            })
    }

    const inputItem = name => {

        return (
        <FarmlyInput value={paymentData[name]}
            setValue={val => {
                const updatedData = {...paymentData}
                updatedData[name] = val
                setPaymentData(updatedData)
            }} elementId={`admin-payments-${name}`}/>
        )
    }

    return (
     
            <div className="control-panel-container">
            <label className='control-panel-payments-label' 
            htmlFor={`admin-payments-euro_value`}>Valor do Euro</label>
            {inputItem('euro_value')}
            <br />
            <label className='control-panel-payments-label' 
            htmlFor={`admin-payments-farmly_fee`}>Tarifa da FARMly (%)</label>
            {inputItem('farmly_fee')}
            <br />
            <label className='control-panel-payments-label' 
            htmlFor={`admin-payments-logistics_fee`}>Custos de Logística (Valor fixo em R$)</label>
            {inputItem('logistics_fee')}
            <br />
            <label className='control-panel-payments-label' 
            htmlFor={`admin-payments-import_duties`}>Custos de importação (Sobre o custo da saca)</label>
            {inputItem('import_duties')}
            <br />
            <label className='control-panel-payments-label' 
            htmlFor={`admin-payments-import_duties_adjustment`}>Ajuste do valor da moeda para os custos de importação</label>
            {inputItem('import_duties_adjustment')}
            <div className="control-panel-payments-buttons">
                <div className="control-panel-button-yes"
                    onClick={() => {
                        axios.patch(`${server}/paymentData`, paymentData)
                            .then(_ => {
                                alert("Dados atualizados!")
                            })
                            .catch(err => {
                                alert("Erro de servidor!")
                                console.log(err)
                            })
                    }}>
                    Atualizar
                </div>
                <div className="control-panel-button-no"
                    onClick={refreshPaymentData}>
                    Cancelar
                </div>
            </div>
        </div>
    )
}

export default Payments