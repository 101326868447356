import styled from "styled-components";

export const ShipmentLineContainer = styled.tr`
    height: 5vw;
`

export const ShipmentInfo = styled.td`
    width: 10vw;
    text-align: center;
`

export const ShipmentInfoButton = styled.button`
    height: 2vw;
    width: 5.5vw;
    border-radius: 15px;
    cursor: pointer;
    background: #C85B56;
    border: none;
    color: white;
    :hover {
        background: #C85B06;
    } 

`

export const ShipmentInfoCheckbox = styled.input`
    height: 1.5vw;
    width: 1.5vw;
    border-radius: 5px;
`
