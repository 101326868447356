import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSeedling, faHeart, faHandsHelping, faCheckCircle, faCoffee, faCaretRight, faChevronDown, faCircle, faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { faWhatsapp, faGoogle, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons'

import ReactGA from 'react-ga'

import './NoCoffeesLandingPage2.css'
import Main from '../templates/Main.jsx'
import FarmlyModal from '../templates/FarmlyModal'
import CoffeeCarousel from '../templates/CoffeeCarousel.jsx'
import CoffeesEmptySearch from '../coffees/CoffeesEmptySearch.jsx'
import NewCarousel from '../templates/NewCarousel.jsx'
import axios from 'axios'
import { isPt, server, isBr, isCo, acceptedCookies, language } from '../../common'
import UserContext from '../../context/user/UserContext.js'
import CartContext from '../../context/cart/CartContext.js'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import { GoogleLogin } from 'react-google-login'
import { Fragment } from 'react'

const greenWave = require('../../assets/landing-page-producer-green-wave.svg')
const ProducerPatricia = require('../../assets/Patricia_foto.jpg')
const Maxuel = require('../../assets/maxuel.png')
const Rogerio = require('../../assets/rogerio.jpeg')
const separatorProcess = require('../../assets/dark-green-wave.svg')
const processNumber1 = require('../../assets/processNumber1.svg')
const processIcon1 = require('../../assets/processIcon1.svg')
const processNumber2 = require('../../assets/processNumber2.svg')
const processIcon2 = require('../../assets/processIcon2.svg')
const processNumber3 = require('../../assets/processNumber3.svg')
const processIcon3 = require('../../assets/processIcon3.svg')
const processNumber4 = require('../../assets/processNumber4.svg')
const processIcon4 = require('../../assets/processIconNew.svg')
const processNumber5 = require('../../assets/processNumber5.svg')
const processIcon5 = require('../../assets/processIcon4.svg')
const firstArrowDesktop = require('../../assets/first-arrow-desktop.svg')
const secondArrowDesktop = require('../../assets/second-arrow-desktop.svg')
const lastArrowDesktop = require('../../assets/last-arrow-desktop.svg')
const firstDecorGroup = require('../../assets/decor-group.svg')
const secondDecorGroup = require('../../assets/first-decor-group.svg')
const thirdDecorGroup = require('../../assets/third-decor-group.svg')
const fourthDecorGroup = require('../../assets/fourth-decor-group.svg')
const firstArrowMobile = require('../../assets/first-arrow-mobile.svg')
const secondArrowMobile = require('../../assets/second-arrow-mobile.svg')
const lastArrowMobile = require('../../assets/last-arrow-mobile.svg')
const zeroArrowMobile = require('../../assets/zero-arrow-mobile.svg')
const smallDecorMobile = require('../../assets/small-decor-mobile.svg')
const otherSmallDecorMobile = require('../../assets/other-small-decor-mobile.svg')
const lastDecorEsqMobile = require('../../assets/last-decor-esq.svg')
const coffeeDecor = require('../../assets/decor-no-coffees.svg')
const groupDecor = require('../../assets/group-decor-no-coffees.png')
const wppButton = require('../../assets/wpp-button.svg')



const NoCoffeesLandingPage2 = props => {
 
    const userContext = useContext(UserContext)
    const cartContext = useContext(CartContext)
    const [signupModal, setSignupModal] = useState(false)
    const [userData, setUserData] = useState({})
    const [formData, setFormData] = useState({})
    const [quantity, setQuantity] = useState(0)
    const [pleaseType, setPleaseType] = useState(false)
    const [pleaseTypePhone, setPleaseTypePhone] = useState(false)
    const [google, setGoogle] = useState(false)
    const [showForm, setShowForm] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [modalMessage, setModalMessage] = useState('')
    const [emailModal, setEmailModal] = useState(false)
    const [inviteLink, setInviteLink] = useState('')
    const [coffeeScores, setCoffeeScores] = useState({
        sub80: '',
        s80_82: '',
        s82_84: '',
        s84_86: '',
        s86_88: '',
        over88: ''
    })

    useEffect(() => {
        ReactGA.event({
            category: 'NoCoffeesLandingPage',
            action: 'Visited the page NoCoffeesLandingPage'
        })
    }, [])
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xl'));

    const handleClickOpen = () => {
        setSignupModal(true);
    }
    const handleClose = () => {
        setSignupModal(false);
    }
    const submitSignup = async () => {
        if (userData.name == null || userData.email == null || userData.password == null || userData.confirmPassword == null || userData.phoneNumber == null || google) {
            setPleaseType(true)
        }
        else {
            let user = { ...userData }
            console.log(user);
            user.coffeeScores = Object.values(coffeeScores).reduce((prev, curr) => prev + curr)
            user.notfication_new = true
            delete user.confirmPassword
            user.invitedBy = null
            try {
                const payload = await axios.post(`${server}/signup/0`, {
                    ...user
                })

                setInviteLink(payload.data.inviteCode)

                userContext.userLogin({
                    isProd: payload.data.isProd,
                    jwt: payload.data.jwt,
                    id: payload.data.id
                })
                const cookie = acceptedCookies() ?
                    document.cookie : ""
                if (cookie.includes('cartList') && !cartContext.cookiesCartIsEmpty) {
                    cartContext.setCartToServer(payload.data.jwt)
                }

                if (acceptedCookies()) {
                    document.cookie = `jwt=${payload.data.jwt}; max-age=${payload.data.exp}; path=/`
                    document.cookie = `isProd=${payload.data.isProd}; max-age=${payload.data.exp}; path=/`
                    document.cookie = `userId=${payload.data.id}; max-age=${payload.data.exp}; path=/`
                }

                await axios.post(`${server}/createNotification`, {
                    userId: payload.data.id,
                    isProd: payload.data.isProd,
                    title: payload.data.isProd ?
                        `Bem-vindo(a) à FARMly!`
                        :
                        `Welcome to FARMly!`,
                    message: payload.data.isProd ?
                        `Obrigado por se cadastrar na nossa plataforma! Para começar a vender o seu café é preciso nos enviar uma amostra e aguardar a aprovação! Para enviar amostra basta entrar no seu Perfil, ir na aba "Enviar Amostra" e seguir as instruções para o envio!`
                        :
                        `Thank you for signing up in our website! You can start looking up for coffees clicking on "Coffees"!`
                })
                setSignupModal(false)

            } catch (err) {
                console.log(err)
            }
        }

    }
    const submitSignupGoogle = async () => {
        let user = { ...userData }
        console.log(user);
        user.coffeeScores = Object.values(coffeeScores).reduce((prev, curr) => prev + curr)
        user.notfication_new = true
        delete user.confirmPassword
        user.invitedBy = null
        try {
            const payload = await axios.post(`${server}/signup/0`, {
                ...user
            })

            setInviteLink(payload.data.inviteCode)

            userContext.userLogin({
                isProd: payload.data.isProd,
                jwt: payload.data.jwt,
                id: payload.data.id
            })
            const cookie = acceptedCookies() ?
                document.cookie : ""
            if (cookie.includes('cartList') && !cartContext.cookiesCartIsEmpty) {
                cartContext.setCartToServer(payload.data.jwt)
            }

            if (acceptedCookies()) {
                document.cookie = `jwt=${payload.data.jwt}; max-age=${payload.data.exp}; path=/`
                document.cookie = `isProd=${payload.data.isProd}; max-age=${payload.data.exp}; path=/`
                document.cookie = `userId=${payload.data.id}; max-age=${payload.data.exp}; path=/`
            }

            await axios.post(`${server}/createNotification`, {
                userId: payload.data.id,
                isProd: payload.data.isProd,
                title: payload.data.isProd ?
                    `Bem-vindo(a) à FARMly!`
                    :
                    `Welcome to FARMly!`,
                message: payload.data.isProd ?
                    `Obrigado por se cadastrar na nossa plataforma! Para começar a vender o seu café é preciso nos enviar uma amostra e aguardar a aprovação! Para enviar amostra basta entrar no seu Perfil, ir na aba "Enviar Amostra" e seguir as instruções para o envio!`
                    :
                    `Thank you for signing up in our website! You can start looking up for coffees clicking on "Coffees"!`
            })
            setSignupModal(false)

        } catch (err) {
            console.log(err)
        }
    }

    const responseGoogle = async (response) => {
        setUserData({ ...userData, email: response.profileObj.email, name: response.profileObj.name, password: response.profileObj.googleId, confirmPassword: response.profileObj.googleId })
        if (userData.phoneNumber == null) setPleaseTypePhone(true)
        else {
            let user = { ...userData, email: response.profileObj.email, name: response.profileObj.name, password: response.profileObj.googleId, confirmPassword: response.profileObj.googleId }
            user.coffeeScores = Object.values(coffeeScores).reduce((prev, curr) => prev + curr)
            user.notfication_new = true
            delete user.confirmPassword
            user.invitedBy = null
            try {
                const payload = await axios.post(`${server}/signup/0`, {
                    ...user
                })

                setInviteLink(payload.data.inviteCode)

                userContext.userLogin({
                    isProd: payload.data.isProd,
                    jwt: payload.data.jwt,
                    id: payload.data.id
                })
                const cookie = acceptedCookies() ?
                    document.cookie : ""
                if (cookie.includes('cartList') && !cartContext.cookiesCartIsEmpty) {
                    cartContext.setCartToServer(payload.data.jwt)
                }

                if (acceptedCookies()) {
                    document.cookie = `jwt=${payload.data.jwt}; max-age=${payload.data.exp}; path=/`
                    document.cookie = `isProd=${payload.data.isProd}; max-age=${payload.data.exp}; path=/`
                    document.cookie = `userId=${payload.data.id}; max-age=${payload.data.exp}; path=/`
                }

                await axios.post(`${server}/createNotification`, {
                    userId: payload.data.id,
                    isProd: payload.data.isProd,
                    title: payload.data.isProd ?
                        `Bem-vindo(a) à FARMly!`
                        :
                        `Welcome to FARMly!`,
                    message: payload.data.isProd ?
                        `Obrigado por se cadastrar na nossa plataforma! Para começar a vender o seu café é preciso nos enviar uma amostra e aguardar a aprovação! Para enviar amostra basta entrar no seu Perfil, ir na aba "Enviar Amostra" e seguir as instruções para o envio!`
                        :
                        `Thank you for signing up in our website! You can start looking up for coffees clicking on "Coffees"!`
                })
                setSignupModal(false)

            } catch (err) {
                console.log(err)
            }
        }
    }

    const goToWork = () => {
        document.getElementById("icon-more-down-landing-page-producer").scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
    }

    const goToProcess = () => {
        document.getElementById("process-home").scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
    }

    const toggleModal = () => {
        setShowModal(!showModal)
    }

    const sendEmailtoLucas = () => {
        const allFilled = (formData != {} && 'name' in formData && 'email' in formData )
        if (!allFilled) {
            const fillMsg = 'Please fill out all fields!'
            setModalMessage(fillMsg)
            setShowModal(true)
        } else {
            axios.post(`${server}/noCoffeeRoaster`, {
                name: formData.name,
                email: formData.email,
                phone: formData.phone,
                cafes: formData.coffee,
                quantity: quantity,
                from: '/noCoffes'
            })
                .then(res => {
                })
            setShowForm(false)
            setEmailModal(true)
        }
    }

    const sendFormGA = () => {
        ReactGA.event({
            category: 'NoCoffeesLandingPage',
            action: `User clicked on sending "finding your perfect coffee form"`
        })
    }


    const func = () => {

        return (
            <>
                {signupModal &&
                    <Fragment>
                        <div className="fundo-preto-sign-up-modal"></div>
                        <div className="linha-verde-sign-up-modal"></div>
                        <div className="sign-up-modal-and-text">
                            <div className="sign-up-modal-container">
                                {pleaseType && <> <br /> <label className="login-fail-label" style={{ alignSelf: 'center' }} >Please fill all the fields!</label> </>}
                                {pleaseTypePhone && <> <br /> <label className="login-fail-label" style={{ alignSelf: 'center' }} >Please, fill all the fields!</label> </>}
                                <div>
                                    <div className="sign-up-modal-close" style={{ cursor: 'pointer' }} onClick={() => setSignupModal(false)}>X</div>
                                    <br />
                                    <input className="sign-up-modal-input" value={userData.name} onChange={e => setUserData({ ...userData, name: e.target.value })} placeholder='Name'></input>
                                    <br />
                                    <input className="sign-up-modal-input" value={userData.email} onChange={e => setUserData({ ...userData, email: e.target.value })} placeholder='Email'></input>
                                    <br />
                                    <input className="sign-up-modal-input" value={userData.password} type='password' onChange={e => setUserData({ ...userData, password: e.target.value })} placeholder='Password'></input>
                                    <br />
                                    <input className="sign-up-modal-input" value={userData.confirmPassword} type='password' onChange={e => setUserData({ ...userData, confirmPassword: e.target.value })} placeholder='Confirm password'></input>
                                    <br />
                                    <input className="sign-up-modal-input" value={userData.phoneNumber} onChange={e => setUserData({ ...userData, phoneNumber: e.target.value })} placeholder='Phone'></input>
                                    <br />
                                    <div style={{ textAlign: 'center' }}>
                                        <button className="sign-up-modal-button" onClick={() => { submitSignup() }}>Sign Up!</button>
                                        <div className="sign-up-or-modal">Or</div>
                                        <GoogleLogin
                                            clientId="503177513332-b2g42k0864opttrsn7v6v8e2o3imvse5.apps.googleusercontent.com"
                                            buttonText='Cadastrar com Google'
                                            onSuccess={responseGoogle}
                                            onFailure={responseGoogle}
                                            cookiePolicy={'single_host_origin'}
                                            className="google-button-landing-page"
                                        />
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                }
                {showForm &&
                    <>
                        <div className="fundo-preto-sign-up-modal"></div>
                        <div className="form-fixed-no-coffees-landing-page">
                            <div className="green-container-form-no-coffees-landing-page green-container-fixed">
                                <div style={{ textAlign: 'right', cursor: 'pointer' }} onClick={() => setShowForm(false)}>X</div>
                                Looking for help finding the perfect coffee? Fill out the form and receive contact from our team!
                            </div>
                            <input className="input-fixed-form-no-coffees-landing-page" value={formData.name} onChange={e => setFormData({ ...formData, name: e.target.value })} placeholder='Name' ></input>
                            <input className="input-fixed-form-no-coffees-landing-page" value={formData.email} onChange={e => setFormData({ ...formData, email: e.target.value })} placeholder='Email' ></input>
                            <input className="input-fixed-form-no-coffees-landing-page" value={formData.phone} onChange={e => setFormData({ ...formData, phone: e.target.value })} placeholder='Phone' ></input>
                            {/* <textarea className="input-fixed-form-no-coffees-landing-page text-area-no-coffees" rows="5" value={formData.coffee} onChange={e => setFormData({ ...formData, coffee: e.target.value })} placeholder='Exp: I would like a red catuai single-origin with notes of chocolate, caramel and a soft body. Five bags of 60kg each.' ></textarea> */}

                            <div className="many-bags-no-coffees-landing-page" id="many-bags-no-coffees-2">Be contacted by the commercial team</div>
                            <input className="card-live-input-quantity card-input-no-coffees-landing-page" value={quantity} onChange={e => setQuantity(e.target.value)}></input>
                            {/* <div className="bag-subtitle-no-coffee-landing-page">Bags (60kg each) </div> */}
                            <div className="find-producer-fixed-no-coffees-landing-page" onClick={() => { sendEmailtoLucas(); sendFormGA(); }}> <span>Find your perfect coffee</span></div>
                        </div>
                    </>
                }
                {showModal && 
                    <FarmlyModal id="page-modal" closeModal={toggleModal}>{modalMessage}</FarmlyModal>}

                {emailModal &&
                    <>
                        <div className="fundo-preto-sign-up-modal"></div>
                        <div className="email-form-fixed-no-coffees-landing-page">
                            <div>We will be in touch soon to connect you with the best producers!</div>
                            <br />
                            <div className="go-back-no-coffees" onClick={() => setEmailModal(false)}>Go back</div>
                        </div>
                    </>}
                <Main noBanner>
                    <div className="homepage-overview">

                        <div className="landing-page-layer" >
                            <div className="introduction-landing-page-no-coffees ">
                                <div>
                                    <div className=" no-coffees-title text-landing-page-producer">
                                        Looking for new coffees in 2021?
                                </div>
                                    <br />
                                    <div className="sub-conjunto-landing-page-producer">
                                        <div className="sub-title-no-coffees-landing-page" >We have what you are looking for!</div>
                                    </div>
                                    <div>
                                        <br />
                                        <div className="no-coffees-text sub-sub-text-landing-page-producer" >It is worth remembering that the harvests in Brazil this year will be scarcer, with up to a 40% drop.
                                        </div>
                                        <br />
                                        <div className=" no-coffees-text sub-sub-text-landing-page-producer" >So it is important to have a plan on how to proceed and make sure you get the perfect coffee at the right time.
                                        </div>
                                        {window.screen.width < 800 && <div className="find-producer-no-coffees-landing-page" onClick={() => setShowForm(true)} >Find your perfect coffee</div>}
                                    </div>
                                </div>
                                {window.screen.width > 800 &&
                                    <div className="form-side-no-coffees-landing-page">
                                        <div className="form-no-coffees-landing-page" id="form-no-coffees-2">
                                            <div className="green-container-form-no-coffees-landing-page">
                                                Looking for help finding the perfect coffee? Fill out the form and receive contact from our team!
                                            </div>
                                            <input className="input-form-no-coffees-landing-page form-no-coffees-2" value={formData.name} onChange={e => setFormData({ ...formData, name: e.target.value })} placeholder='Name'  ></input>
                                            <input className="input-form-no-coffees-landing-page form-no-coffees-2" value={formData.email} onChange={e => setFormData({ ...formData, email: e.target.value })} placeholder='Email'  ></input>
                                            <input className="input-form-no-coffees-landing-page form-no-coffees-2" value={formData.phone} onChange={e => setFormData({ ...formData, phone: e.target.value })} placeholder='Phone'  ></input>
                                            {/* <textarea className="input-form-no-coffees-landing-page text-area-no-coffees" rows="5" value={formData.coffee} onChange={e => setFormData({ ...formData, coffee: e.target.value })} placeholder='Exp: I would like a red catuai single-origin with notes of chocolate, caramel and a soft body. Five bags of 60kg each.'  ></textarea> */}
                                            <div className="many-bags-no-coffees-landing-page" id="many-bags-no-coffees-2">Be contacted by the commercial team</div>
                                            <input className="card-live-input-quantity card-input-no-coffees-landing-page" value={quantity} onChange={e => setQuantity(e.target.value)}></input>
                                            {/* <div className="bag-subtitle-no-coffee-landing-page">Bags (60kg each) </div> */}
                                            <div className="find-producer-no-coffees-landing-page" onClick={() => { sendEmailtoLucas() }}>Find your perfect coffee</div>
                                        </div>
                                    </div>
                                }

                            </div>
                            <FontAwesomeIcon icon={faChevronDown} className="icon-more-down-landing-page-producer" id="icon-more-down-landing-page-producer" onClick={() => goToWork()} />
                        </div>

                        <img className="wavy-separator green-wave-no-coffees-page" src={greenWave} alt="" />
                        <div className="pay-no-coffees-landing-page">
                            <div className="pay-title-landing-page" style={{ color: 'white' }}>How much will you pay for it?</div>
                            <br />
                            <br />
                            <br />
                            <img src={groupDecor} className="group-decor-no-coffees-landing-page"></img>
                            <div className="pay-text-container-landing-page-producer">
                                <div className="pay-text-landing-page-producer" style={{ color: 'white' }}>We at farmly will help you buy your coffees directly from producers. <br />
                                    We'll send you some samples completely for free (no shipping as well) so you can pick your most desired coffee. We profit a small percentage
                                    over the cost of export, import, and distribution. Basically, we take care of everything for you
                                    so you can have great coffees while paying less and respecting your producer's work.
                                </div>
                                <br />
                                <div className="pay-text-landing-page-producer" style={{ color: 'white' }} id="pay-text">
                                    Too good to be true? <br /> Understand our <span onClick={() => goToProcess()}>process</span>
                                </div>
                            </div>
                            <div className="find-producer-no-coffees-landing-page" id="help-us-find" onClick={() => setShowForm(true) && sendFormGA()}>Help us find your perfect coffee</div>
                            <div className="pay-text-container-landing-page-producer">
                                <div className="pay-title-landing-page" id="pay-title" style={{ color: 'white' }}>
                                    What is Farmly?
                            </div>
                                <br />
                                <div className="pay-text-landing-page-producer" style={{ color: 'white' }}>
                                    Our goal is to help small coffee producers to export, connecting them directly with roasters
                                    who we believe will value producers' work and have a good impact on their lives and family
                                    also working with an environmentally friendly and sustainable method of production.
                                </div>
                                <div className="pay-text-landing-page-producer" style={{ color: 'white' }} id="pay-text-example">
                                    Too good to be true? Get to know our <span onClick={() => goToProcess()}>process</span>!
                            </div>
                            </div>
                            <img src={firstDecorGroup} className="decor-no-coffees-landing-page"></img>
                        </div>
                        <img className="wavy-separator separatorMediumGreenUp2" style={{ marginTop: '-2.5em !important' }} src={separatorProcess} alt="" />

                        <div id="process-home" className="home-layer process-no-coffees">
                            <div className="how-we-do-it-title" id="how-we-do-it-title">
                                <span>How does Farmly work</span>
                                <br />
                                <p className='how-we-do-it-subtitle'>
                                    Understand how we bring producers and roasters together to create a better business experience for every one.
                            </p>
                            </div>
                            {window.screen.width < 500 &&
                                <img src={zeroArrowMobile} className="zero-arrow-mobile"></img>
                            }
                            {window.screen.width < 500 &&
                                <img src={smallDecorMobile} className="small-decor-mobile"></img>
                            }
                            <div style={{ dispay: 'flex', flexDirection: 'row' }}>
                                <div style={{ width: '60%' }}>
                                </div>
                                <div className="process1-home" >
                                    <img src={processNumber1} className="processNumber1-no-coffees"></img>
                                    <img src={processIcon1} className="processIcon1-no-coffees"></img>
                                    <span className='process-title-home' id="process-home-title">
                                        Tell us what you are looking for
                                </span>
                                    <span className='process-text-home-no-coffees'>
                                        By informing us what you seek in your coffees, we will work on connecting you with a producer that best suits you
                                </span>
                                </div>
                                <img src={secondDecorGroup} className="first-decor-process-home"></img>
                            </div>
                            <div>
                                {window.screen.width > 500 ?
                                    <img src={firstArrowDesktop} className="first-arrow-desktop"></img>
                                    :
                                    <img src={firstArrowMobile} className="first-arrow-mobile" ></img>
                                }
                            </div>
                            <div style={{ dispay: 'flex', flexDirection: 'row' }}>
                                <div className="process2-home">
                                    <img src={processNumber2} className="processNumber2-no-coffees"></img>
                                    <img src={processIcon2} className='image-process2-home-no-coffees'></img>
                                    <span className='process-title-home' id="process-home-title">
                                        Shipment of samples</span>
                                    <span className='process-text-home-no-coffees'>
                                        We will send you some samples that best suits your tastes completely out of charge
                                </span>
                                </div>
                            </div>
                            <div>
                                {window.screen.width > 500 ?
                                    <img src={secondArrowDesktop} className="second-arrow-desktop"></img>
                                    :
                                    <img src={secondArrowMobile} className="second-arrow-desktop"></img>
                                }
                                {window.screen.width < 500 &&
                                    <img src={otherSmallDecorMobile} className="other-small-decor-mobile"></img>
                                }
                            </div>
                            <div style={{ dispay: 'flex', flexDirection: 'row' }}>
                                <img src={thirdDecorGroup} className="third-decor-process-home" ></img>
                                <div style={{ width: '60%' }}></div>
                                <div className="process3-home">
                                    <img src={processNumber3} className="processNumber3-no-coffees"></img>
                                    <img src={processIcon3} className='image-process3-home-no-coffees'></img>
                                    <span className='process-title-home' id="process-home-title">
                                        Choosing your coffees
                                </span>
                                    <span className='process-text-home-no-coffees'>
                                        We confirm the samples purchased and arrange the shipping of the bags
                                </span>
                                    <img src={groupDecor} className="group-decor"></img>

                                </div>
                            </div>
                            <div>
                                {window.screen.width > 500 ?
                                    <img src={lastArrowDesktop} className='last-arrow-desktop'></img>
                                    :
                                    <img src={lastArrowMobile} className='last-arrow-mobile'></img>
                                }
                            </div>
                            <div style={{ dispay: 'flex', flexDirection: 'row' }}>
                                <div className="process4-home">
                                    <img src={processNumber4} className="processNumber4-no-coffees" ></img>

                                    <img src={processIcon4} className='image-process4-landing-no-coffees'></img>
                                    <span className='process-title-home' id="process-home-title">
                                        Logistics
                                </span>
                                    <span className='process-text-home-no-coffees'>
                                        We work on everything from export to import and all the way to distribution so you can receive your perfect coffees
                                </span>
                                </div>
                                <img src={fourthDecorGroup} className="fourth-decor-process-home"></img>
                                <div style={{ width: '60%' }}></div>
                            </div>
                            <div>
                                {window.screen.width > 500 ?
                                    <img src={secondArrowDesktop} className="second-arrow-desktop"></img>
                                    :
                                    <img src={secondArrowMobile} className="second-arrow-desktop"></img>
                                }
                                {window.screen.width < 500 &&
                                    <img src={otherSmallDecorMobile} className="other-small-decor-mobile"></img>
                                }
                            </div>
                            <div style={{ dispay: 'flex', flexDirection: 'row' }}>
                                <div className="process3-home">
                                    <img src={processNumber5} className="processNumber5-no-coffees" ></img>
                                    <img src={processIcon5} className='image-process5-home-no-coffees'></img>
                                    <span className='process-title-home' id="process-home-title"  >
                                        Confirmation of receivement
                                </span>
                                    <span className='process-text-home-no-coffees'>
                                        Upon confirmation of receipt, payment is made to Farmly, who will then transfer the amount to the producer.
                                </span>
                                </div>
                                <div style={{ width: '60%' }}></div>
                            </div>
                            <img src={coffeeDecor} id="coffee-decor-1"></img>
                            <div className="find-producer-no-coffees-landing-page" id="register-now" onClick={() => setShowForm(true)}>
                                Register now!
                        </div>
                            <img src={coffeeDecor} id="coffee-decor-2"></img>
                        </div>
                        <img className="wavy-separator dark-green-wave" src={separatorProcess} alt="" />
                        <div className="not-sure" id="not-sure">
                            Not sure yet? <br /> Check out some statements from our partners.
                    </div>
                        <div>
                            <div className="img-and-producer-container-landing-page">
                                <img className="producer-img-landing-page-producer" src={Rogerio} alt="" />
                                <div className="producer-info-landing-page-producer">
                                    <div className="producer-name-landing-page-producer">Rogério</div>
                                    <div className="producer-region-landing-page-producer">Producer from Minas Gerais</div>
                                    <div className="producer-text-landing-page-producer">One year ago, my friend introduced me to Farmly and said that I should get in touch with them because my coffee had great potential. In 2020, I sent a sample to the company and they sold my coffee to a roaster in Europe. Since then, Farmly has been adding a lot of value for me and the producers in my region and has been selling our coffee in Europe very often!</div>
                                </div>
                            </div>
                            <div className="img-and-producer-container-landing-page-left">
                                <div className="producer-info-landing-page-producer-left">
                                    <div className="producer-name-landing-page-producer">Maxuel Carlos Lopes</div>
                                    <div className="producer-region-landing-page-producer">Producer from Matas de minas</div>
                                    <div className="producer-text-landing-page-producer">Being recognized and valued as a coffee producer is very rewarding. It is a great pleasure to make coffee and know that someone out there will taste it. I am very grateful for Farmly to have come into my life and the sales opportunities it has brought. Farmly is a part of my life and that drives us to produce a better quality coffee.</div>
                                </div>
                                <img className="producer-img-landing-page-producer-right" src={Maxuel} alt="" />
                            </div>
                            <div className="img-and-producer-container-landing-page">
                                <img className="producer-img-landing-page-producer" src={ProducerPatricia} alt="" />
                                <div className="producer-info-landing-page-producer">
                                    <div className="producer-name-landing-page-producer">Patrícia Guerra Moreira de Mendonça</div>
                                    <div className="producer-region-landing-page-producer">Producer from São Paulo</div>
                                    <div className="producer-text-landing-page-producer">
                                        Selling coffee through Farmly allowed me to reach a market that I couldn't do alone. It was a great joy to send coffee to buyers in Europe. The process was easy and well done as I received all the necessary help, from the indication of the appropriate packaging to the emission of the invoice. And in the end, there was payment in the agreed way. The whole process is very transparent and Farmly's team is always ready to help and answer questions. I am very satisfied with the partnership developed.</div>
                                </div>
                            </div>
                        </div>
                        <div className="improving-no-coffees-2">
                            <div className="impact-no-coffees-2">
                                <div className="signUp-title-home">
                                    Impact
                                </div>
                                <div className="improving-text-no-coffees-2 signUp-text-home">
                                    When you buy from us, you are improving the conditions of life for a producer's family!
                                </div>
                            </div>
                            <img src="/static/media/coffee_decoration_homepage.532e6133.jpg" class="signUp-decor-home" alt="decoration"></img>
                        </div>
                        <div className="not-sure">
                            Still have questions?
                        </div>
                        {/* <img className="wavy-separator red-wave-landing-page-producer" src={redWave} alt="" /> */}
                        <div className="wpp-button-container">
                            <a target="_blank" href={"https://api.whatsapp.com/send?phone=+553791036677"}>
                                <button className="wpp-button final-button-landing-page-producer">
                                    <FontAwesomeIcon icon={faWhatsapp} className="wpp-button-icon" />
                                (37) 9103-6677
                            </button>
                            </a>
                            <div className="not-sure"> or </div>
                            <Link to={'/help/#header'}>
                                <button className="google-button final-button-landing-page-producer">
                                    <FontAwesomeIcon icon={faGoogle} className="wpp-button-icon" />
                                Send us an e-mail
                            </button>
                            </Link>
                        </div>

                    </div>
                </Main>
            </>
        )
    }

    return (
        func()
    )
}

export default NoCoffeesLandingPage2