import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { server } from '../../common.js'
import './ControlPanel.css'
import AdminCoffeeCard from './AdminCoffeeCard.jsx'
import SearchBarOld from './SearchBar/SearchBarOld.jsx'


const SelectCoffee = (props) => {

    const [query, setQuery] = useState('')
    const [coffees, setCoffees] = useState([])
    const [page, setPage] = useState(0)

    useEffect(() => {
        getCoffeesInit(0)
    }, [])

    const getCoffeesInit = async (atPage = 0, display) => {
        /*const queryString = `${server}/coffees/${atPage}` + '?' + (query && `search=${query}&`)
        const newCoffees = atPage === 0 ? [] : [...coffees]
        // precisa fazer isso pra pegar 10 de cada
        await axios.get(queryString  + `display=true`)
            .then(res => {
                newCoffees.push(...res.data)
            }).then(_ => setPage(atPage + 1))
                .then(() => axios.get(queryString  + `display=false`)
                                .then(res => {
                                    newCoffees.push(...res.data)
                })).catch(_ => {
                //implementar um tratamento para isso!
            })
            setCoffees(newCoffees)*/
        axios.get(`${server}/coffees`)
            .then(res => {
                setCoffees(res.data)
            })
    }

    // quando tiver paginação vai usar isso
    const getCoffees = async (atPage = 0, display) => {
        const queryString = `${server}/coffees/${atPage}` + '?' + (query && `search=${query}&`) + `display=${display}`
        const newCoffees = [...coffees]
        await axios.get(queryString)
            .then(res => {
                newCoffees.push(...res.data)
                setCoffees(newCoffees)
            }).then(_ => setPage(atPage + 1))
            .catch(_ => { })
    }

    const searchCoffees = () => {
        setCoffees([])
        getCoffeesInit(0)
    }


    const updateCoffeesVisible = (cof, setCof, coffee) => {

        setCof(cof.map((el) => el.id === coffee.id ? ({ ...el, display: true }) : el))

        axios.post(`${server}/selectCoffee`, {
            id: coffee.id,
            display: true
            // old_display: false
        })
            .catch(e => console.log(e))
    }

    const updateCoffeesOldVisible = (cof, setCof, coffee) => {

        setCof(cof.map((el) => el.id === coffee.id ? ({ ...el, display: false }) : el))

        axios.post(`${server}/selectCoffee`, {
            id: coffee.id,
            display: false,
            old_display: true
        })
            .catch(e => console.log(e))
    }

    const updateCoffeesNotVisible = (cof, setCof, coffee) => {

        setCof(cof.map((el) => el.id === coffee.id ? ({ ...el, display: false, old_display: false }) : el))

        axios.post(`${server}/selectCoffee`, {
            id: coffee.id,
            display: false
            // old_display: false
        })
            .catch(e => console.log(e))
    }




    const showCollapsible = (name) => {

        var content = document.getElementById(`control-panel-${name}`)

        if (content.style.display === "flex") {
            content.style.display = "none";
        } else {
            content.style.display = "flex";
        }
    }

    const renderCards = (coffees, display) =>
        coffees.map((coffee) => {
            return (coffee.display === display) && <AdminCoffeeCard key={coffee.id} coffee={coffee} setCoffees={setCoffees} coffees={coffees}
                farmName={coffee.farm} score={coffee.score} display={coffee.display} region={coffee.region} oldDisplay={coffee.old_display}
                image={coffee.imageUrl} variety={coffee.variety} process={coffee.process} farmHeight={coffee.height} selectPage={true}
                producer={coffee.name} harvestDate={coffee.harvestDate} checkParam={coffee.display} sampleSentDate={coffee.sampleSentDate} sampleReceivedDate={coffee.sampleReceivedDate} sampleTastedDate={coffee.sampleTastedDate}
                coffeeId={coffee.id} sensoryNotes={coffee.sensoryNotes} sack_price={coffee.sack_price} width='40vh' height='45vh'
                loadFlag={coffee.loadFlag || false} updateVisible={updateCoffeesVisible} updateOldVisible={updateCoffeesOldVisible} updateNotVisible={updateCoffeesNotVisible} />
        })


    return (<div className="control-panel-container">
        <SearchBarOld query={query} setQuery={setQuery} submitQuery={searchCoffees} />
        <h2 className="section-title collapsible" id="sect1" onClick={(e) => showCollapsible(e.target.id)}>Visíveis<span className="drop-icon" id="sect1">{document.getElementById(`control-panel-sect1`) && document.getElementById(`control-panel-sect1`).style.display === "flex" ? '-' : '+'}</span></h2>
        <div id="control-panel-sect1" className="control-panel-coffees collapsed">
            {renderCards(coffees, true)}
        </div>
        {/* <h2 className="section-title collapsible" id="sect3" onClick={(e) => showCollapsible(e.target.id)}>Antigos Visíveis<span className="drop-icon" id="sect3">{document.getElementById(`control-panel-sect3`) && document.getElementById(`control-panel-sect3`).style.display === "flex" ? '-' : '+'}</span></h2>
        <div id="control-panel-sect3" className="control-panel-coffees collapsed">
            {renderCards(coffees, false, true)}
        </div> */}
        <h2 className="section-title collapsible" id="sect2" onClick={(e) => showCollapsible(e.target.id)}>Não Visíveis<span className="drop-icon" id="sect2">{document.getElementById(`control-panel-sect2`) && document.getElementById(`control-panel-sect2`).style.display === "flex" ? '-' : '+'}</span></h2>
        <div id="control-panel-sect2" className="control-panel-coffees collapsed">
            {renderCards(coffees, false)}
        </div>
    </div>)
}

export default SelectCoffee