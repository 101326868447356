import React, { FunctionComponent } from 'react';

import CardDescription from './components/CardDescription';

import { BodyContainer, Title } from './styles';
interface CardBodyProps {
    id          : number,
    origin      : string,
    name        : string,
    process     : string,
    prices      : [number,number],
    farmCode    : string,
}
 
const CardBody: FunctionComponent<CardBodyProps> = (props) => {

    return ( 
        <BodyContainer to={`/coffee/${props.id}`}>
            <Title>{props.name}</Title>
            <CardDescription process={props.process} prices={props.prices} farmCode={props.farmCode} origin={props.origin} />
        </BodyContainer>
    );
}
 
export default CardBody;
