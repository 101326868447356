import axios from 'axios'

import { server, acceptedCookies } from '../../common'

const getUserData = async () => {
    const cookies = acceptedCookies() ?
        document.cookie.split(';').filter(item => item.includes('jwt'))[0].split('jwt=')[1]
        : ""

    const user = await axios.post(`${server}/users`, {
        jwt: cookies
    })
    return user
}

export { getUserData }