import React, { useEffect, useContext, useState } from 'react'
import './LandingPage.css'

import UserContext from '../../context/user/UserContext'
import Main from '../templates/Main.jsx'
import ReactGA from 'react-ga'

import axios from 'axios'
import { server, landingPages, acceptedCookies } from '../../common'

const LandingPage = props => {

    const userContext = useContext(UserContext)
    const [landingPageLink, setLandingPageLink] = useState("https://blog.farmlyclub.com/lp-03-p/")

    useEffect(() => {
        ReactGA.event({
            category: 'Landing Page',
            action: `Accessed Lading Page`
        })
    })

    // Teste A/B
    /*useEffect(() => {
        if (!userContext.user || !userContext.user.isProd) {
            const cookie = acceptedCookies() ?
                document.cookie : ""
            if (!cookie.includes('landingPage=')) {
                axios.post(`${server}/landingPage`, {})
                    .then(res => {
                        const { current } = res.data
                        if(acceptedCookies())
                            document.cookie = `landingPage=${current};max-age=${60 * 60 * 24 * 2};path=/`
                        setLandingPageLink(landingPages[current])
                        ReactGA.event({
                            category: 'Landing Page',
                            action: `Accessed page ${current}`
                        })
                    })
                    .catch(err => console.log(err))
            } else {
                const current =
                    cookie
                        .split(';')
                        .filter(str => str.includes('landingPage='))[0]
                        .split('=')[1]
                ReactGA.event({
                    category: 'Landing Page',
                    action: `Accessed page ${current}`
                })
                setLandingPageLink(landingPages[current])
            }
        }
    }, [])*/

    return (
        <Main noFooter noBanner smallImage>
            <div style={{ marginTop: '60px' }}>
                {landingPageLink !== '' &&
                    <iframe src={landingPageLink} className="lp-iframe" />}
            </div>
        </Main>
    )

}

export default LandingPage