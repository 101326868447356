import React, { FunctionComponent } from 'react'
import FarmlyModal from 'components/templates/FarmlyModal';

interface ModalDisplayProps {
    showModal: boolean,
    toggleModal: () => void,
    modalMessage: string,
}

const ModalDisplay: FunctionComponent<ModalDisplayProps> = (props) => {
    return (
        <>
            {props.showModal && (
                <FarmlyModal closeModal={props.toggleModal}>
                    {props.modalMessage}
                </FarmlyModal>
            )}
        </>
    );
}
export default ModalDisplay;