import styled from "styled-components";

export const coffeePicture3 = require('assets/coffee_farm.jpg');

export const Container = styled.div`
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    font-family: 'Poppings','Montserrat',sans-serif;
    display: block;
    position: relative;
    height: 275px;
    width: 275px;
    border-radius: 15px; /* 15px rounded corners */
    background-color: #1b625e;
    overflow: hidden;
`