import React from 'react'
import { useHistory } from "react-router-dom";

import '../../../styles/profile/components/UserCoffees.css'
import UserCoffeesSlider from './UserCoffeesSlider'
import { isPt, isCo, language} from '../../../common'

import { ButtonBase } from '@material-ui/core'

const UserCoffees = props => {

    const history = useHistory();

    const {
        coffees,
        user,
        setShowUserSendSampleContent,
        windowSize,
        fetchError
    } = props

     const renderIframes = () => {
        return (
            <div style={{display: "flex", flexDirection:"column", alignItems: "center", width: "100%", height: "1000px"}}>
			{/* {user.isProd && coffees.length > 0 ? <span>Samples Producer</span> : <></>} */}
            {user.isProd && coffees.length > 0 ? <iframe src={`https://farmly-offerlist.bubbleapps.io/samples_producer_l?fid=${user.id}&debug_mode=true`} className="user-iframe" style={{width: '100%', height: '47%', border: 0}}></iframe> :<></>}
			{/* {!user.isProd && coffees.length > 0 ? <span>Samples Roaster</span> : <></> } */}
			{!user.isProd && coffees.length > 0 ? <iframe src={`https://farmly-offerlist.bubbleapps.io/samples_roaster?fid=${user.id}&debug_mode=true`} className="user-iframe" style={{width: '100%', height: '47%', border: 0}}></iframe> :<></>}
            </div>
        )
    }
 
    return (
        <>
            <div className="user-coffees-wave-separator" >
                {
                    user.isProd === true ?
                        <div className="new-profile-user-coffees-title">
                            {isPt() ? "Seus Cafés" : (isCo() ? "Tus cafés" : "Your coffees")}
                        </div>
                        :
                        <div className="new-profile-user-coffees-title">
                            {isPt() ? "Amostras que você pediu" : (isCo() ? "Muestras que ordenaste" : "Samples you've requested")}
                        </div>
                
                }
                {/* {
                    coffees.length>0
                    ?
                    <UserCoffeesSlider user={user} coffees={coffees} windowSize={windowSize} />
                    :
                    fetchError
                    ?
                    {pt:'Erro de servidor! Tente novamente mais tarde.',es:'Error del servidor. Por favor, inténtelo más tarde.',en:'Server error! Please try again later.'}
                    :
                    <ButtonBase className="new-farmly-button user-coffees-empty-button f-button-red f-button-tw" 
                    onClick={() => {if(!user.isProd) history.push("/offerlist"); else setShowUserSendSampleContent(true)}}>
                        {user.isProd === true 
                        ?
                        <a className="nostyle" href="#user-send-sample-form">{{pt:"Enviar Amostra",es:"Enviar Muestras",en:"Send Samples"}[language]}</a>
                        :
                        {pt:"Requisitar amostras",es:"Solicitar Muestras",en:"Request Samples"}[language]}
                    </ButtonBase>
                } */}

            </div>
            {renderIframes()}
          
        </>
    )
}

export default UserCoffees