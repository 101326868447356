import React from 'react'
import './PrivacyPolicy.css'

import Main from '../templates/Main.jsx'
import Title from '../templates/Title.jsx'
import { isPt, farmlyTexts } from '../../common.js'
import { privacyTexts } from './policyTexts.js'

const PrivacyPolicy = props => {

    return (
        <Main>
            <div className="privacy-policy">
                <Title title={isPt() ? farmlyTexts.privacyTitle_pt : farmlyTexts.privacyTitle_en} />
                <div className="policy-farmly-info">
                    FARMly 
                    <br />
                    VAT: NL 860748820B01
                    <br />
                    E-mail: lfaria@farmlyclub.com
                    <br />
                    {isPt() ? "Telefone" : "Phone number"}:  +55 27 99980-2545; + 31 (0)10 799 7300
                    <br />
                    {isPt() ? "Na Europa" : "In Europe"} (FARMly B.V.): ROTTERDAM, City WNA, Weena Zuid 130, Ground floor & 4th floor,3012 NC Rotterdam, Rotterdam, 3012
                    <br />
                    {isPt() ? "No Brasil" : "In Brazil"} (FARMly do Brasil Importação e Exportação LTDA – CNPJ 34.335.309/0001-54):Rua Dr. Eurico de Aguiar, 888, sala 602 - Santa Lúcia , Vitória-ES, Brasil, CEP: 29056-200
                </div>
                <div className="policy-text">
                    {isPt() ? privacyTexts.intro_pt : privacyTexts.intro_en}
                </div>
                <label className="policy-title">
                    {isPt() ? "1. Aplicação" : "1. Application"}
                </label>
                <div className="policy-text">
                    {isPt() ? privacyTexts.application_pt : privacyTexts.application_en}
                </div>
                <label className="policy-title">
                    {isPt() ? "2. Controle dos Dados" : "2. Data Controller"}
                </label>
                <div className="policy-text">
                    {isPt() ? privacyTexts.dataControl_pt : privacyTexts.dataControl_en}
                </div>
                <label className="policy-title">
                    {isPt() ? "3. Informações que Coletamos" : "3. The Information We Collect"}
                </label>
                <div className="policy-text">
                    {isPt() ? privacyTexts.collectInfo_pt : privacyTexts.collectInfo_en}
                </div>
                <div className="policy-subsection">
                    <label className="policy-subtitle">3.1</label>
                    <div className="policy-text">
                    {isPt() ? privacyTexts.collectInfo1_pt : privacyTexts.collectInfo1_en}
                    </div>
                </div>
                <div className="policy-subsection">
                    <label className="policy-subtitle">3.2</label>
                    <div className="policy-text">
                    {isPt() ? privacyTexts.collectInfo2_pt : privacyTexts.collectInfo2_en}
                    </div>
                </div>
                <div className="policy-subsection">
                    <label className="policy-subtitle">3.3</label>
                    <div className="policy-text">
                    {isPt() ? privacyTexts.collectInfo3_pt : privacyTexts.collectInfo3_en}
                        
                    </div>
                </div>
                <div className="policy-subsection">
                    <label className="policy-subtitle">3.4</label>
                    <div className="policy-text">
                    {isPt() ? privacyTexts.collectInfo4_pt : privacyTexts.collectInfo4_en}
                        
                    </div>
                </div>
                <div className="policy-subsection">
                    <label className="policy-subtitle">3.5</label>
                    <div className="policy-text">
                    {isPt() ? privacyTexts.collectInfo5_pt : privacyTexts.collectInfo5_en}
                        
                    </div>
                </div>
                <div className="policy-subsection">
                    <label className="policy-subtitle">3.6</label>
                    <div className="policy-text">
                    {isPt() ? privacyTexts.collectInfo6_pt : privacyTexts.collectInfo6_en}
                        
                    </div>
                </div>
                <div className="policy-subsubsection">
                    <label className="policy-subsubtitle">3.6.1</label>
                    <div className="policy-text">
                    {isPt() ? privacyTexts.collectInfo6_1_pt : privacyTexts.collectInfo6_1_en}
                        
                    </div>
                </div>
                <label className="policy-title">
                    {isPt() ? "4. Propósito e Utilização das Informações" : "4. Information Usage And Purposes"}
                </label>
                <div className="policy-subsection">
                    <label className="policy-subtitle">4.1</label>
                    <div className="policy-text">
                        {isPt() ? privacyTexts.infoPurpose1_pt : privacyTexts.infoPurpose1_en}
                    </div>
                </div>
                <div className="policy-subsection">
                    <label className="policy-subtitle">4.2</label>
                    <div className="policy-text">
                        {isPt() ? privacyTexts.infoPurpose2_pt : privacyTexts.infoPurpose2_en}
                    </div>
                </div>
                <div className="policy-subsection">
                    <label className="policy-subtitle">4.3</label>
                    <div className="policy-text">
                        {isPt() ? privacyTexts.infoPurpose3_pt : privacyTexts.infoPurpose3_en}
                    </div>
                </div>
                <div className="policy-subsection">
                    <label className="policy-subtitle">4.4</label>
                    <div className="policy-text">
                        {isPt() ? privacyTexts.infoPurpose4_pt : privacyTexts.infoPurpose4_en}
                    </div>
                </div>
                <div className="policy-subsection">
                    <label className="policy-subtitle">4.5</label>
                    <div className="policy-text">
                        {isPt() ? privacyTexts.infoPurpose5_pt : privacyTexts.infoPurpose5_en}
                    </div>
                </div>
                <div className="policy-subsection">
                    <label className="policy-subtitle">4.6</label>
                    <div className="policy-text">
                        {isPt() ? privacyTexts.infoPurpose6_pt : privacyTexts.infoPurpose6_en}
                    </div>
                </div>
                <div className="policy-subsection">
                    <label className="policy-subtitle">4.7</label>
                    <div className="policy-text">
                        {isPt() ? privacyTexts.infoPurpose7_pt : privacyTexts.infoPurpose7_en}
                    </div>
                </div>
                <label className="policy-title">
                    {isPt() ? "5. Cookies e Tecnologias de Terceiros" : "5. Cookies And Third-Party Technology"}
                </label>
                <div className="policy-subsection">
                    <label className="policy-subtitle">5.1</label>
                    <div className="policy-text">
                        {isPt() ? privacyTexts.cookies1_pt : privacyTexts.cookies1_en}
                    </div>
                </div>
                <div className="policy-subsection">
                    <label className="policy-subtitle">5.2</label>
                    <div className="policy-text">
                        {isPt() ? privacyTexts.cookies2_pt : privacyTexts.cookies2_en}
                    </div>
                </div>
                <label className="policy-title">
                    {isPt() ? "6. Compartilhamento e Divulgação de Informações" : "6. Information Sharing And Disclosure"}
                </label>
                <div className="policy-subsection">
                    <label className="policy-subtitle">6.1</label>
                    <div className="policy-text">
                        {isPt() ? privacyTexts.infoSharing1_pt : privacyTexts.infoSharing1_en}
                    </div>
                </div>
                <div className="policy-subsubsection">
                    <label className="policy-subsubtitle">6.1.1</label>
                    <div className="policy-text">
                        {isPt() ? privacyTexts.infoSharing1_1_pt : privacyTexts.infoSharing1_1_en}
                    </div>
                </div>
                <div className="policy-subsubsection">
                    <label className="policy-subsubtitle">6.1.2</label>
                    <div className="policy-text">
                        {isPt() ? privacyTexts.infoSharing1_2_pt : privacyTexts.infoSharing1_2_en}
                    </div>
                </div>
                <div className="policy-subsubsection">
                    <label className="policy-subsubtitle">6.1.3</label>
                    <div className="policy-text">
                        {isPt() ? privacyTexts.infoSharing1_3_pt : privacyTexts.infoSharing1_3_en}
                    </div>
                </div>
                <div className="policy-subsection">
                    <label className="policy-subtitle">6.2</label>
                    <div className="policy-text">
                        {isPt() ? privacyTexts.infoSharing2_pt : privacyTexts.infoSharing2_en}
                    </div>
                </div>
                <div className="policy-subsection">
                    <label className="policy-subtitle">6.3</label>
                    <div className="policy-text">
                        {isPt() ? privacyTexts.infoSharing3_pt : privacyTexts.infoSharing3_en}
                    </div>
                </div>
                <label className="policy-title">
                    {isPt() ? "7. Gerenciamento de Retenção e Exclusão de Informações" : "7. Information Retention And Deletion Management"}
                </label>
                <div className="policy-subsection">
                    <label className="policy-subtitle">7.1</label>
                    <div className="policy-text">
                        {isPt() ? privacyTexts.infoRetention1_pt : privacyTexts.infoRetention1_en}
                    </div>
                </div>
                <div className="policy-subsection">
                    <label className="policy-subtitle">7.2</label>
                    <div className="policy-text">
                        {isPt() ? privacyTexts.infoRetention2_pt : privacyTexts.infoRetention2_en}
                    </div>
                </div>
                <div className="policy-subsection">
                    <label className="policy-subtitle">7.3</label>
                    <div className="policy-text">
                        {isPt() ? privacyTexts.infoRetention3_pt : privacyTexts.infoRetention3_en}
                    </div>
                </div>
                <div className="policy-subsection">
                    <label className="policy-subtitle">7.4</label>
                    <div className="policy-text">
                        {isPt() ? privacyTexts.infoRetention4_pt : privacyTexts.infoRetention4_en}
                    </div>
                </div>
                <div className="policy-subsection">
                    <label className="policy-subtitle">7.5</label>
                    <div className="policy-text">
                        {isPt() ? privacyTexts.infoRetention5_pt : privacyTexts.infoRetention5_en}
                    </div>
                </div>
                <label className="policy-title">
                    {"8. Updates To Policy"}
                </label>
                <div className="policy-subsection">
                    <label className="policy-subtitle">8.1</label>
                    <div className="policy-text">
                        {isPt() ? privacyTexts.updates1_pt : privacyTexts.updates1_en}
                    </div>
                </div>
            </div>
        </Main>
    )
}

export default PrivacyPolicy