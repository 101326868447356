import React from 'react';
import { useState } from 'react';
import ButtonBase from '@material-ui/core/ButtonBase';

import FarmlyInput from "../templates/FarmlyInput";

import "../EditInformations/EditInformations.css"
import { language } from '../../common';


const EditEmail = (props) => {

    const [data, setData] = useState({ email: "" });

    const { submitNewEmail } = props;

    return (
        <div className="new-profile profile-information-edit-modal">
            <h3>{{ pt: 'Edição de Informações', es: 'Edición de información', en: "Information edition" }[language]}</h3>
            <div className="new-profile profile-information-edit-form" style={{ height: 'auto' }}>
                <h4>{{ pt: 'Alteração de e-mail', es: 'Cambio de e-mail', en: "Change email" }[language]}</h4>
                <div className="new-profile profile-information-edit-grid">
                    <div className="new-profile profile-information-edit-field">
                        <label htmlFor="profile-new-email" className="user-send-sample-input-label">{{ pt: 'Novo E-mail', es: 'Nuevo E-mail', en: "New email" }[language]}</label>
                        <FarmlyInput type='email' styleClass="new-profile user-send-sample-input" elementId="profile-new-email" placeholder={{ pt: 'Novo E-mail', es: 'Nuevo E-mail', en: "New email" }[language]}
                            value={data.email} setValue={value => setData({ email: value })}
                        />
                    </div>
                </div>
            </div>
            <div className='profile-information-edit-actions'>
                <ButtonBase className="new-farmly-button f-button-orange" onClick={() => submitNewEmail(data)}>{{ pt: 'Confirmar edições', es: 'Confirmar ediciones', en: "Confirm edits" }[language]}</ButtonBase>
            </div>
        </div>
    );
}

export default EditEmail;