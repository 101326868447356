import React, { useState, useEffect, useContext } from 'react'
import Main from '../templates/Main.jsx'
import { Link, useHistory } from 'react-router-dom'
import ReactGA from 'react-ga'
import "./ThanksForYourRegistration.css"
import OptionsTable from '../templates/OptionsTable.jsx'
import { server, isPt, farmlyTexts, acceptedCookies } from '../../common.js'
import axios from 'axios'
import FarmlyInput from '../templates/FarmlyInput.jsx'
import FarmlyModal from '../templates/FarmlyModal.jsx'
import RequestSample from '../coffeeProfile/RequestSample'
import CartContext from '../../context/cart/CartContext.js'
import UserContext from '../../context/user/UserContext'
import SampleContext from '../../context/sample/SampleContext.js'


const ThanksForYourRegistration = props => {
    const CoffeeDecoration = require('../../assets/coffee_decoration_homepage.jpg')
    const [coffees, setCoffees] = useState()
    const history = useHistory()
    const [userData, setUserData] = useState(props.location.state ? props.location.state.user : {})
    const [pleaseType, setPleaseType] = useState(false)
    const [freeSamples, setFreeSamples] = useState(false)
    const [thanksAgain, setThanksAgain] = useState(false)
    const [moreInfo, setMoreInfo] = useState(false)
    const [requestPopup, setRequestPopup] = useState(false)
    const [requestPopupSignUp, setRequestPopupSignUp] = useState(false)
    const [requestPopupFollowing, setRequestPopupFollowing] = useState(false)
    const [emailFailure, setEmailFailure] = useState(false)
    const [authFailure, setAuthFailure] = useState(false)
    const [serverFailure, setServerFailure] = useState(false)
    const [passwordDontMatch, setPasswordDontMatch] = useState(false)
    const userContext = useContext(UserContext)
    const cartContext = useContext(CartContext)
    const sampleContext = useContext(SampleContext)
    const [coffeeAmt, setCoffeeAmt] = useState({
        sub80: '0',
        s80_82: '0',
        s82_84: '0',
        s84_86: '0',
        s86_88: '0',
        over88: '0'
    })
    const [sensoryNotes, setSensoryNotes] = useState({
        strawberry: '',
        chocolate: '',
        caramel: '',
        lime: '',
        banana: '',
        orange: ''
    })
    const analyticsInfo = () => {
            ReactGA.event({
                category: 'User clicked "go to profile"',
                action: 'User sign up and clicked "go to profile" at "thank you" page'
            })
    }

    useEffect(() => {
        getCoffees()

    }, [])

    useEffect(() => {
        if(props.location.state) {
            sampleContext.addEmail(props.location.state.user.email)
        }

    }, [])

    useEffect(() => {
        if (requestPopup || requestPopupFollowing) {
            let aux = []
            sampleContext.offerList.forEach(sample => {
                aux.push(`${sample.id}`)
                if(aux.length == sampleContext.offerList.length) {
                    axios.patch(`${server}/updateCoffeesRegistrationData`, {
                        email: sampleContext.email,
                        coffees: aux.join(',')
                    })
                    .then(_ => {
                        history.push({
                            pathname: '/registeredRequisitions',
                            state: { 
                                user: userData,
                                email: sampleContext.email,
                                offerList: sampleContext.offerList 
                            }
                        })
                        history.go()
                    })
                }
            })
        }

    }, [requestPopup, requestPopupFollowing])

    const getCoffees = () => {
        const queryString = `${server}/coffees`
        axios.get(queryString)
            .then(res => {
                setCoffees(res.data.filter(item => item.display == true))
            })
            .catch(_ => {
            })
    }

    const renderList = coffees => {
        return <OptionsTable coffees={coffees} requestPopup={requestPopup} setRequestPopup={setRequestPopup} requestPopupFollowing={requestPopupFollowing} setRequestPopupFollowing={setRequestPopupFollowing} triggerRequest={triggerRequest} />
    }

    const registerAddress = () => {
        if(userData.address == null || userData.country == null || userData.postal_code == null || userData.phone_number == null){
            setPleaseType(true)
        }
        else {
            axios.patch(`${server}/updateRegistrationData`, { 
                email: userData.email,
                address: userData.address,
                country: userData.country,
                postal_code: userData.postal_code,
                phone_number: userData.phone_number 
            }) 
            setThanksAgain(true)
        }
    }

    const signUp = async () => {
        let user = { ...userData, postalCode: "", address: "" }
        user.notfication_new = true
        user.coffeeScores = (
            `sub80:${coffeeAmt.sub80},` +
            `s80_82:${coffeeAmt.s80_82},` +
            `s82_84:${coffeeAmt.s82_84},` +
            `s84_86:${coffeeAmt.s84_86},` +
            `s86_88:${coffeeAmt.s86_88},` +
            `over88:${coffeeAmt.over88},`
        )
        // user.sensoryNotes = Object.values(sensoryNotes).reduce((prev, curr) => prev + curr)
        ReactGA.event({
            category: 'New roaster registered',
            action: 'New Roaster Signed Up'
        })
        if (user.confirmPassword === user.password) {
            delete user.confirmPassword
            delete user.invitedBy
            try {
                const payload = await axios.post(`${server}/signup/0`, {
                    ...user
                })


                userContext.userLogin({
                    isProd: payload.data.isProd,
                    jwt: payload.data.jwt,
                    id: payload.data.id
                })
                const cookie = acceptedCookies() ?
                    document.cookie : ""
                if (cookie.includes('cartList') && !cartContext.cookiesCartIsEmpty) {
                    cartContext.setCartToServer(payload.data.jwt)
                }

                if (acceptedCookies()) {
                    document.cookie = `jwt=${payload.data.jwt}; max-age=${payload.data.exp}; path=/`
                    document.cookie = `isProd=${payload.data.isProd}; max-age=${payload.data.exp}; path=/`
                    document.cookie = `userId=${payload.data.id}; max-age=${payload.data.exp}; path=/`
                }
                setRequestPopupFollowing(true)
                setRequestPopupSignUp(false)
                setPasswordDontMatch(false)
            } catch (err) {
                console.log(err)
            }
        }
        else setPasswordDontMatch(true)

    }

    const login = () => {
        axios.post(`${server}/signin`, {
            email: userData.email,
            password: userData.password
        })
            .then(payload => {
                const cookie = acceptedCookies() ?
                    document.cookie : ""
                userContext.userLogin({
                    isProd: payload.data.isProd,
                    jwt: payload.data.jwt,
                    id: payload.data.id
                })
                if (cookie.includes('cartList') && !cartContext.cookiesCartIsEmpty) {
                    cartContext.setCartToServer(payload.data.jwt)
                } else if (!payload.data.isProd) {
                    cartContext.getCartFromServer(payload.data.id)
                }

                ReactGA.event({
                    category: 'Login',
                    action: 'User Logged In'
                })

                if (acceptedCookies()) {
                    document.cookie = `jwt=${payload.data.jwt}; max-age=${payload.data.exp}; path=/`
                    document.cookie = `isProd=${payload.data.isProd}; max-age=${payload.data.exp}; path=/`
                }
                if (acceptedCookies()) document.cookie = `userId=${payload.data.id}; max-age=${payload.data.exp}; path=/`
                if (!payload.data.isProd) {
                    axios.get(`${server}/roaster/${payload.data.id}`)
                        .then(roaster => {
                            setUserData(roaster.data)
                            setRequestPopup(false)
                            setRequestPopupFollowing(true)
                        })
                }

            })
            .catch(err => {
                const status = err.response ? err.response.data.status : 'server-failure'
                if (status === 'email-not-found') {
                    setEmailFailure(true)
                } else if (status === 'auth-failed' || status === 'incomplete') {
                    setAuthFailure(true)
                } else if (status === 'server-failure') {
                    setServerFailure(true)
                }
            })
    }

    const triggerRequest = () => {
        setRequestPopupFollowing(!requestPopupFollowing)
    }


    return (
        <>
        {false &&
            <FarmlyModal closeModal={() => { setRequestPopup(false) }} className="modal-sample">
                <div className="modal-landing-page">
                    <label id="request-title">
                        To proceed with your request, log into our plataform!
                    </label>
                    <br />
                    <br />
                    <div className="producer-profile-row" style={{ width: '100%', justifyContent: 'center' }}>
                        <div className="producer-profile-item">
                            <label htmlFor="" className="producer-profile-label" style={{ textAlign: 'left' }}>Email: </label>
                            <FarmlyInput value={userData.email} setValue={value => setUserData({ ...userData, email: value })}
                                styleClass="input-farmly" elementId="email" placeholder="email..." />
                        </div>
                    </div>
                    {emailFailure && <label className="login-fail-label"> Email not found.</label>}
                    {serverFailure && <label className="login-fail-label">Server error. Please, try again in a few moments, or send us a message describing the problem.</label>}
                    <div className="producer-profile-row" style={{ width: '100%', justifyContent: 'center' }}>
                        <div className="producer-profile-item">
                            <label htmlFor="" className="producer-profile-label" style={{ textAlign: 'left' }}>Password: </label>
                            <FarmlyInput value={userData.password} type='password' setValue={value => setUserData({ ...userData, password: value })}
                                styleClass="input-farmly" elementId="password" placeholder="password..." />
                        </div>
                    </div>
                    {authFailure && <label className="login-fail-label"> Incorrect email or password.</label>}
                    <div className="sign-up-button" style={{ marginLeft: '0px' }}>
                        <button className="register-button-samples" style={{ marginRight: '0px' }} onClick={() => login()}> Login</button>
                    </div>
                    <br />
                    <br />
                    <label id='new-account-label' style={{ marginRight: '5px' }}>
                        New here?
                        </label>
                    <Link id='new-account-link' onClick={() => { setRequestPopupSignUp(true); setRequestPopup(false) }}>
                        Sign up!
                        </Link>
                </div>
            </FarmlyModal>}
        {requestPopupSignUp &&
            <FarmlyModal closeModal={() => { setRequestPopupSignUp(false) }} className="modal-sample">
                <div className="modal-landing-page">
                    <label id="request-title">
                        Fill all the fields below and sign up to proceed!
                    </label>
                    <div className="producer-profile-row" style={{ width: '100%' }}>
                        <div className="producer-profile-item">
                            <label htmlFor="" className="producer-profile-label" style={{ textAlign: 'left' }}>Email: </label>
                            <FarmlyInput value={userData.email} setValue={value => setUserData({ ...userData, email: value })}
                                styleClass="input-farmly-signin" elementId="email" placeholder="email..." />
                        </div>
                        <div className="producer-profile-item" style={{ marginLeft: '2vw' }}>
                            <label htmlFor="" className="producer-profile-label" style={{ textAlign: 'left' }}>Name: </label>
                            <FarmlyInput value={userData.name} setValue={value => setUserData({ ...userData, name: value })}
                                styleClass="input-farmly-signin" elementId="name" placeholder="name..." />
                        </div>
                    </div>
                    <div className="producer-profile-row" style={{ width: '100%' }}>
                        <div className="producer-profile-item">
                            <label htmlFor="" className="producer-profile-label" style={{ textAlign: 'left' }}>Password: </label>
                            <FarmlyInput value={userData.password} type='password' setValue={value => setUserData({ ...userData, password: value })}
                                styleClass="input-farmly-signin" elementId="password" placeholder="password..." />
                        </div>
                        <div className="producer-profile-item" style={{ marginLeft: '2vw' }}>
                            <label htmlFor="" className="producer-profile-label" style={{ textAlign: 'left' }}>Confirm password: </label>
                            <FarmlyInput value={userData.confirmPassword} type='password' setValue={value => setUserData({ ...userData, confirmPassword: value })}
                                styleClass="input-farmly-signin" elementId="confirmPassword" placeholder="confirm..." />
                        </div>
                    </div>
                    {passwordDontMatch && <label className="login-fail-label"> The password's fields have to match.</label>}
                    <div className="producer-profile-row" style={{ width: '100%' }}>
                        <div className="producer-profile-item">
                            <label htmlFor="" className="producer-profile-label" style={{ textAlign: 'left' }}>Country: </label>
                            <FarmlyInput value={userData.country} setValue={value => setUserData({ ...userData, country: value })}
                                styleClass="input-farmly-signin" elementId="country" placeholder="country..." />
                        </div>
                        <div className="producer-profile-item" style={{ marginLeft: '2vw' }}>
                            <label htmlFor="" className="producer-profile-label" style={{ textAlign: 'left' }}>Phone Number: </label>
                            <FarmlyInput value={userData.phoneNumber} setValue={value => setUserData({ ...userData, phoneNumber: value })}
                                styleClass="input-farmly-signin" elementId="phoneNumber" placeholder="phone..." />
                        </div>
                    </div>
                    <div className="sign-up-button">
                        <button className="register-button-samples" onClick={() => signUp()}> Sign Up</button>
                    </div>
                </div>
            </FarmlyModal>}
        {requestPopupFollowing && <RequestSample triggerRequest={triggerRequest} />}
        <Main>
            <div>
                <label className="thanks-title-label" style={{justifyContent: 'center', display: 'flex'}}>
                    Thanks for Registering!
                </label> 
                
                <div className="thanks-second-container">
                    <div className="thanks-second-container-sub">
                    {!freeSamples ?
                        <>
                            <label className="thanks-second-info">We will send you up to 7 free samples, 3 will be on the cupping event and we will send you 4 more so you can try some great coffees from small producers so in the future you can start to</label>
                            <label className="thanks-second-info" style={{textDecoration: 'underline', fontWeight: '600'}} onClick={() => {setMoreInfo(!moreInfo)}}> buy directly from them!</label>
                            <br/>
                            {moreInfo &&  <label className="thanks-second-info">FARMly is an impact business that helps small producers to be able to sell directly to you, we take care of logistics, import and export. You don't have to worry about that ! We want you to trully connect with your coffee producer! Specialty coffee for us is about connection, positive impact and history.</label>}
                        </>
                            :
                        <>
                        {!thanksAgain?
                        <>
                            <label className="thanks-second-info">Complete the fields below so we can send you the free samples!</label>
                            <br/>
                            <br/>
                            <div className="thanks-inputs-container">
                                <div className="thanks-inputs-container-sub">
                                    <div>
                                        <label>Address:</label>
                                        <input className="thanks-input" value={userData.address} onChange={e => setUserData({...userData, address: e.target.value})}/>                                      
                                    </div>
                                    <div>
                                        <label>Postal Code:</label>
                                        <input className="thanks-input" value={userData.postal_code} onChange={e => setUserData({...userData, postal_code: e.target.value})}/>
                                    </div>
                                </div>
                                <br/>
                                <div className="thanks-inputs-container-sub">
                                    <div>
                                        <label>Country:</label>
                                        <input className="thanks-input" value={userData.country} onChange={e => setUserData({...userData, country: e.target.value})}/>                                      
                                    </div>
                                    <div>
                                        <label>Phone:</label>
                                        <input className="thanks-input" value={userData.phone_number} onChange={e => setUserData({...userData, phone_number: e.target.value})}/>
                                    </div>
                                </div>
                                <br/>
                                <div className="thanks-button">
                                    <button className="thanks-button-yes" onClick={registerAddress}> Confirm</button>
                                </div>
                                {pleaseType && <label className="login-fail-label" style={{alignSelf: 'center'}} >Please complete all fields to continue!</label>}
                            </div>
                        </>
                        :
                        <>
                            <label className="thanks-second-info">Thanks once again!</label>
                            <br/>
                            <br/>
                            <label className="thanks-second-info">We just sent you an email with all your order information!</label>
                            <br/>
                            <label className="thanks-second-info">If you have any doubts, contact us at cormercial@farmlyclub.com</label>
                        </>
                        
                        }
                        </>
                        } 
                    </div>
                    <img src={CoffeeDecoration} className="decor-img" alt="coffee beans" />
                </div>
                <div className="thanks-third-container">
                    {coffees && coffees.length && renderList(coffees)}
                </div>
            </div>
        </Main>
        </>
    )
}

export default ThanksForYourRegistration

