import React from 'react'
import './RoasterForm.css'
import { isPt, farmlyTexts } from '../../../common'
import { useEffect } from 'react'
import Countries from '../../../assets/countries.json'
import Select from 'react-select';
import ReactGA from 'react-ga'

const RoasterForm = props => {

    const { prev, next, roasterData, setRoasterData, countryOptions, setModalMessage, toggleModal } = props

    const allFilled = (
        roasterData.country !== '' &&
        roasterData.postalCode !== '' &&
        roasterData.address !== '' &&
        roasterData.phoneNumber !== ''
    )

    const countryNotFilled = (
        roasterData.country !== ''
    )

    const postalCodeNotFilled = (
        roasterData.postalCode !== ''
    )

    const addressNotFilled = (
        roasterData.address !== ''
    )

    const phoneNumberNotFilled = (
        roasterData.phoneNumber !== ''
    )

    const checkNumber = (text, object) => {
        if (text === '' || !isNaN(text.charAt(text.length - 1))) setRoasterData(object)
    }
    const renderOpts = () => {
        const country = []
        Countries.map((opt) => {
            country.push({
                value: opt,
                label: opt
            })
        })
        return country
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div id="roaster-form">
            <div className="signup-title-row">
                <div className="coffee-icon" />
                <div className="signup-compound-title" style={{ display: 'flex', flexDirection: 'column' }}>
                    <label className="signup-title">{isPt() ? farmlyTexts.signupAlmostThere_pt : farmlyTexts.signupAlmostThere_en}</label>
                    <label className="signup-subtitle">{isPt() ? farmlyTexts.signupRequiredFields_pt : farmlyTexts.signupRequiredFields_en}</label>
                </div>
            </div>
            <div className="roaster-form-compound-row">
                <div className="roaster-form-compound-field roaster-form-compound-first">
                    <label className="signup-field-label">{isPt() ? farmlyTexts.signupCountry_pt : farmlyTexts.signupCountry_en}{' *'}</label>
                    {/* <input type="text" name='region-list' className="signup-field-input roaster-form-large" value={roasterData.country} list='country-list'
                        onChange={(e) => { setRoasterData({ ...roasterData, country: e.target.value }) }}
                        placeholder={isPt() ? farmlyTexts.signupCountry_pt : farmlyTexts.signupCountry_en} /> */}
                    <Select
                        className="roaster-form-large"
                        id="select-country"
                        placeholder="Country..."
                        onChange={(e) => { setRoasterData({ ...roasterData, country: e.value }) }}
                        options={renderOpts()}
                    >
                    </Select>
                </div>
            </div>
            <div className="roaster-form-compound-row">
                <div className="roaster-form-compound-field roaster-form-compound-first">
                    <label className="signup-field-label">{isPt() ? farmlyTexts.signupPhone_pt : farmlyTexts.signupPhone_en}{' *'}</label>
                    <input type="text" className="signup-field-input roaster-form-large" value={roasterData.phoneNumber}
                        onChange={e => checkNumber(e.target.value, { ...roasterData, phoneNumber: e.target.value })}
                        placeholder={isPt() ? farmlyTexts.signupPhone_pt : farmlyTexts.signupPhone_en} />
                </div>
            </div>
            <div className="signup-compound-buttons" style={{ justifyContent: 'space-evenly' }}>
                <div onClick={prev} className="signup-back-button" style={{ width: '25%' }}>
                    {isPt() ? farmlyTexts.signupGoBack_pt : farmlyTexts.signupGoBack_en}
                </div>
                <div onClick={() => {
                    if (!countryNotFilled || !phoneNumberNotFilled) {
                        if (!countryNotFilled && phoneNumberNotFilled) {
                            setModalMessage(isPt() ? 'O campo "País" não foi preenchido' : 'The field "Country" is missing');
                            toggleModal()
                        } else if (countryNotFilled && !phoneNumberNotFilled) {
                            setModalMessage(isPt() ? 'O campo "Telefone" não foi preenchido' : 'The field "Phone" is missing');
                            toggleModal()

                        } else {
                            setModalMessage(isPt() ? farmlyTexts.signupPleaseFill_pt : farmlyTexts.signupPleaseFill_en);
                            toggleModal()
                        }
                    }
                    else next()
                }} className="signup-next-button" style={{ width: '25%' }}>

                    {isPt() ? farmlyTexts.signupSend_pt : farmlyTexts.signupSend_en}
                </div>
            </div>
        </div>
    )
}

export default RoasterForm