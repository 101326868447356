
const privacyTexts = {
    intro_pt: "Esta política descreve as informações que coletamos, nossos objetivos de uso e compartilhamento e seus direitos em relação a essas informações. Ao começar a usar a plataforma FARMly, você aceita a Política de Privacidade e as condições e casos de processamento de dados nela descritos. Se você não concorda com esta Política total ou parcialmente, você deve abster-se de usar a plataforma FARMly.",
    intro_en: "This policy describes the information we collect, our purposes for using and sharing it, and your rights regarding this information. By starting to use FARMly B.V. platform, you accept the Privacy and the conditions and data processing cases described therein. If you do not agree with this Policy completely or in part, you should refrain from using FARMly B.V. platform.",

    application_pt: "Esta Política se aplica a todos os usuários da plataforma FARMly, que devem concordar com os Termos e Condições, Política de Privacidade e Política de Cookies.",
    application_en: "This Policy applies to all users of FARMly B.V. platform, who must to agree with the terms and conditions, privacy and cookie policy.",

    dataControl_pt: "Quanto aos serviços prestados pela FARMly, os dados serão processados e armazenados na empresa com sede no Brasil, no endereço acima.",
    dataControl_en: "Regarding the services provided by FARMly B.V., the data will be processed and stored in the company established in Brazil, in the address upwrite.",

    collectInfo_pt: "A FARMly coleta os seguintes tipos de informações:",
    collectInfo_en: "FARMly B.V. collects the following types of information:",
    collectInfo1_pt: "Informações enviadas por usuários FARMly ao usar nossa plataforma e entrar em contato com nosso serviço de atendimento ao cliente.",
    collectInfo1_en: "Information submitted by FARMly B.V. users when using our platform and contacting our customer support service.",
    collectInfo2_pt: "Informações que são criadas automaticamente durante o uso de serviços e aplicativos FARMly, incluindo as informações do dispositivo do usuário (dados de localização do dispositivo, configurações de aplicativos do dispositivo, endereço IP e cookies), informações de transação, informações estatísticas e comportamentais.",
    collectInfo2_en: "Information which is automatically created during the usage of FARMly B.V. services and applications, including the information from the user’s device (device location data, device applications settings, IP address and cookies), transaction information, statistical and behavioral information.",
    collectInfo3_pt: "Informações sobre as chamadas e mensagens feitas através da plataforma FARMly, incluindo a data e hora da chamada / mensagem de texto e o conteúdo das mensagens de texto, se tal função for utilizada. Esta função permite que os usuários, bem como os parceiros de entrega e destinatários da FARMly liguem e enviem mensagens de texto entre si, sem revelar seus números de telefone. A FARMly também pode usar essas informações para garantir funções de atendimento ao cliente e para análises.",
    collectInfo3_en: "Information about calls and messages made through the FARMly platform, including the date and time of the call/text message and the content of the text messages if such function is used. This function allows users as well as FARMly B.V. delivery partners and delivery recipients to call and send text messages to each other without disclosing their phone numbers. FARMly B.V. may also use this information to ensure customer support service functions and for analytics.",
    collectInfo4_pt: "Informações do usuário das seguintes fontes (incluindo, mas não se limitando a): Feedback de outros usuários (como avaliações, reclamações e elogios); Parceiros de negócios da FARMly, por meio dos quais você cria ou acessa sua conta e serviços, bem como parceiros de negócios da FARMly que obtiveram seu consentimento para o compartilhamento de informações com terceiros, para fins não conflitantes com os propósitos declarados nesta Política; Provedores de serviços de seguros, financeiros e de marketing; Parceiros de negócios de café; Fontes públicas.",
    collectInfo4_en: "User information from the following sources (including, but not limited to): feedback from other users (such as ratings, complaints and compliments); FARMly B.V. business partners through which you create or access your account and services, as well as FARMly B.V. business partners who got your consent for information sharing with third parties, for purposes not conflicting the purposes stated in this Policy; insurance, financial and marketing service providers; partner among coffee business; public sources.",
    collectInfo5_pt: "Também coletamos: (a) Informações automáticas, que recebemos e armazenamos automaticamente; determinados tipos de informações quando você usa nossa plataforma são coletados, como informações sobre seu uso, incluindo sua interação com conteúdo e serviços disponíveis por meio da plataforma; (b) informações de cookies e outros identificadores exclusivos quando seu navegador ou dispositivo acessa a plataforma, e; (c) informações de outras fontes: podemos receber informações sobre você de outras fontes.",
    collectInfo5_en: "We also collect: (a) automatic Information, which we automatically receive and store; certain types of information when you use our platform are collected, such as information about your use, including your interaction with content and services available through the platform; (b) cookies information and other unique identifiers when your web browser or device accesses the platform, and; (c) information from other sources: we might receive information about you from other sources.",
    collectInfo6_pt: "Assim, coletamos as Informações que você nos fornece, em consequência, recebemos e armazenamos todas as informações que você fornecer em relação à plataforma FARMly. Você pode optar por não fornecer certas informações, mas talvez não consiga tirar proveito de algum de nossos serviços.",
    collectInfo6_en: "So, we collect the Information You Give Us, in consequence we receive and store any information you provide in relation to FARMly platform. You can choose not to provide certain information but then you might not be able to take advantage of many of our services.",
    collectInfo6_1_pt: "Todas as Informações coletadas serão processadas e armazenadas pela FARMly do Brasil, conforme estabelecido no item 2.",
    collectInfo6_1_en: "Every collected Information will be processed and stored by FARMly Brazil, as established in item 2.",

    infoPurpose1_pt: "Usamos seus dados para cumprir nossas obrigações conforme declarado em nossos contratos e acordos com você, e para fornecer nossos serviços e suas funções a você. As funções dos serviços FARMly incluem (mas não estão limitadas a): Fornecimento de informações de parceiros FARMly para nossos usuários; viabilizar transporte, serviços de entrega, entre outros; processamento de pagamentos e facilitação de serviços de usuários FARMly em seu nome; oferecer, obter, fornecer ou facilitar soluções de seguro ou financiamento em conexão com a plataforma FARMly; compartilhar informações com outras pessoas (por exemplo, quando você envia um elogio sobre um parceiro FARMly, recomenda a plataforma, entre outros); oferecer recursos para personalizar sua conta dentro da liberdade de personalização da plataforma; realizar operações internas necessárias para fornecer nossos serviços, incluindo para solucionar bugs de software e problemas operacionais, para realizar análises de dados, testes e pesquisas, e para monitorar e analisar tendências de uso e atividade; coleta de informações dos dispositivos de parceiros e clientes por questões de segurança; validação de informações sobre parceiros e clientes FARMly; suporte ao entrar em contato com o serviço de suporte ao cliente (direcionando suas perguntas ao funcionário de suporte ao cliente apropriado; investigando e abordando suas preocupações, monitorando e melhorando a qualidade do suporte ao cliente).",
    infoPurpose1_en: "We use your data to fulfil our obligations as stated in our contracts and agreements with you, and to provide our services and their functions to you. The functions of FARMly B.V. services include (but are not limited to): providing information from FARMly B.V. partners for our users; enabling transportation, delivery services, etc.; payment processing and facilitation for the services of FARMly B.V. users on their behalf; offering, obtaining, providing or facilitating insurance or financing solutions in connection with FARMly B.V. platform; sharing information with other people, (such as when you submit a compliment about a FARMly B.V. partner, Recommend the platform, etc; enabling features to personalize your account within the platform freedom to personalize; performing internal operations necessary to provide our services, including to troubleshoot software bugs and operational problems, to conduct data analysis, testing, and research, and to monitor and analyze usage and activity trends; collecting information from the devices of partner and customers to identify unsafe signal; validation of information about FARMly B.V. partner and customers; support when you contact customer support service (directing your questions to the appropriate customer support employee; investigating and addressing your concerns; monitoring and quality improvement of customer support).",
    infoPurpose2_pt: "Para testes, pesquisas, análises e desenvolvimento de produtos, usamos dados anônimos, estatísticos e agregados.",
    infoPurpose2_en: "For testing, research, analysis and product development we use anonymized, statistical and aggregated data.",
    infoPurpose3_pt: "Algumas funções da plataforma FARMly descritas acima exigem que compartilhemos parcialmente os dados (incluindo transmissão internacional) com outros usuários, parceiros, terceiros, FARMly e suas afiliadas.",
    infoPurpose3_en: "Some functions of FARMly platform described above require that we partially share the data (including cross-border transmission) with other users, partners, third parties, FARMly B.V. and its affiliates.",
    infoPurpose4_pt: "Ao utilizar as funções da plataforma, você dá o seu consentimento à FARMly para o processamento dos seus dados para os fins acima especificados através de quaisquer métodos necessários, incluindo a transmissão transfronteiriça de dados, por meio de ferramentas de automação ou sem tais ferramentas.",
    infoPurpose4_en: "By using the functions of platform, you give your consent to FARMly B.V. to the processing of your data for the purposes specified above through any methods necessary, including cross-border data transmission, by means of automation tools or without such tools.",
    infoPurpose5_pt: "A FARMly pode usar os dados coletados para enviar-lhe informações e mensagens publicitárias.",
    infoPurpose5_en: "FARMly B.V. may use the collected data to send you information and advertisement messages.",
    infoPurpose6_pt: "Nosso objetivo também pode ser solicitar seu consentimento para processar suas informações pessoais para uma finalidade específica que lhe comunicaremos. Quando você consente com o nosso processamento de suas informações pessoais para um propósito específico, você pode retirar seu consentimento a qualquer momento, e a FARMly irá interromper o processamento de seus dados para esse propósito.",
    infoPurpose6_en: "Our purpose can be also asking for your consent to process your personal information for a specific purpose that we communicate to you. When you consent to our processing your personal information for a specified purpose, you may withdraw your consent at any time, and we will stop processing of your data for that purpose.",
    infoPurpose7_pt: "Outra finalidade é apenas cumprir as obrigações legais. Em certos casos, temos a obrigação legal de coletar e processar suas informações pessoais.",
    infoPurpose7_en: "Another purpose is just complying with legal obligations. In certain cases, we have a legal obligation to collect and process your personal information.",

    cookies1_pt: "Cookies são arquivos de software que páginas da web ou aplicativos armazenam em navegadores ou nos dispositivos dos usuários enquanto eles navegam nas várias páginas do portal ou aplicativos e que são usados para armazenar informações sobre as visitas dos usuários. Portanto, cookie é um pequeno arquivo de texto que um portal deixa no sistema de computador do usuário (computador, tablet ou smartphone) quando ele o visita. A FARMly usa cookies e tecnologias semelhantes para fins como (a) autenticação de usuários únicos; (b) lembrar as preferências e configurações do usuário; (c) determinar a popularidade do conteúdo; (d) entrega e medição da eficácia das campanhas publicitárias; (e) analisar o tráfego e as tendências do site e, de maneira geral, compreender os comportamentos online e os interesses das pessoas que interagem com nossos serviços.",
    cookies1_en: "Cookies are software files that web pages or applications install in browsers or on users’ devices while they are browsing the various pages of the portal or applications and which are used to store information about users’ visits. Therefore, cookie is a small text file that a web portal leaves on the user's computer system (computer, tablet computer or smartphone) when he visits it. FARMly B.V. uses cookies and similar technologies for purposes such as (a) authenticating unique users; (b) remembering user preferences and settings; (c) determining the popularity of content; (d) delivering and measuring the effectiveness of advertising campaigns; (e) analyzing site traffic and trends, and generally understanding the online behaviors and interests of people who interact with our services.",
    cookies2_pt: "Também podemos permitir que outros forneçam medição de audiência e serviços analíticos para nós, para veicular anúncios em nosso nome na Internet e para rastrear e relatar o desempenho desses anúncios. Essas entidades podem usar cookies, web beacons, SDKs e outras tecnologias para identificar o seu dispositivo quando você visita nosso site e usa nossos serviços, bem como quando visita outros sites e serviços online.",
    cookies2_en: "We may also allow others to provide audience measurement and analytics services for us, to serve advertisements on our behalf across the Internet, and to track and report on the performance of those advertisements. These entities may use cookies, web beacons, SDKs, and other technologies to identify your device when you visit our site and use our services, as well as when you visit other online sites and services.",

    infoSharing1_pt: "Para garantir as funções de nossos serviços e aplicativos, outros usuários da plataforma podem ter acesso a algumas das informações coletadas pela FARMly.",
    infoSharing1_en: "To ensure the functions of our services and applications, other users of platform may be granted access to some of the information collected by FARMly B.V. and processed and stored by FARMly Brazil:",
    infoSharing1_1_pt: "Se você for um usuário da plataforma FARMly, podemos compartilhar seu primeiro nome e outras informações pessoais com o parceiro para garantir a conformidade com os termos e as condições.",
    infoSharing1_1_en: "If you are an FARMly platform user, we may share your first name and others personal information with the partner in order to warrant the tarms and conditions compliant.",
    infoSharing1_2_pt: "Se você for um parceiro, podemos compartilhar informações com seu comprador, incluindo seu nome e foto; tipo de café, pontuação, sua localização e o preço original.",
    infoSharing1_2_en: "If you are a partner, we may share information with your buyer, including your name and photo; coffee type, scores, your location; the original price.",
    infoSharing1_3_pt: "Se você preencher um perfil como parceiro ou cliente, também podemos compartilhar quaisquer informações associadas a esse perfil, incluindo informações que você enviar e comentários que empresas anteriores enviaram sobre você, com usuários da plataforma FARMly, parceiros e terceiros, para habilitar as funções dos nossos serviços e plataforma e/ou em nome de parceiros e clientes.",
    infoSharing1_3_en: "If you complete a profile as an partner or customer, we may also share any information associated with that profile, including information that you submit and feedback that past business have submitted about you, with FARMly platform users, partners and third parties for enabling the functions of our services and platform and/or on behalf of partners and customers.",
    infoSharing2_pt: "Suas mensagens enviadas para nós por meio de nossa rede tornam-se disponíveis publicamente.",
    infoSharing2_en: "Your messages sent to us of our network become publicly available.",
    infoSharing3_pt: "O compartilhamento de informações do usuário com terceiros também pode ser exigido pela legislação aplicável.",
    infoSharing3_en: "Sharing user information with third parties may also be required by the applicable law.",

    infoRetention1_pt: "A FARMly retém suas informações enquanto sua conta permanece ativa. Mediante seu pedido para remover suas informações ou conta, a FARMly apaga ou torna suas informações anônimas. Se o serviço ou plataforma FARMly não incluir a seção de remoção de informações ou o formulário de contato, você pode enviar a solicitação de exclusão de suas informações e conta para nosso serviço de atendimento ao cliente. FARMly pode reter informações após a exclusão da conta se for de acordo com a lei.",
    infoRetention1_en: "FARMly B.V. retains your information while your account remains active. Upon your request to remove your information or account, FARMly B.V. deletes or anonymizes your information. If the FARMly B.V. service or platform does not include the information removal section or contact form, you can send the request to delete your information and account to our customer support service. FARMly B.V. may retain information after account deletion if it is according by law.",
    infoRetention2_pt: "Você pode acessar suas informações através de sua conta. Você pode gerenciar as informações que enviou em sua conta, como seu nome, número de telefone e endereço de e-mail, bem como configurações de privacidade, conforme desejar.",
    infoRetention2_en: "You can access your information through your account. You can manage the information you submitted in your account, such as your name, phone number and e-mail address, as well as privacy settings, at your will.",
    infoRetention3_pt: "A plataforma pode solicitar ou notificar o usuário (dependendo do sistema operacional) sobre as permissões para acessar certos tipos de dados no dispositivo do usuário. Seu uso da plataforma significa seu consentimento para conceder as permissões.",
    infoRetention3_en: "The platform may ask or notify the user (depending on the operating system) about permissions to access certain types of data on the user’s device. Your usage of the platform means your consent to give the permissions.",
    infoRetention4_pt: "Você pode optar por não receber e-mails e outras mensagens da FARMly seguindo as instruções nessas mensagens. Observe que, se você optar por sair, ainda poderemos enviar mensagens não promocionais, como recibos de seus pedidos, preferências ou informações sobre sua conta.",
    infoRetention4_en: "You may opt out of receiving e-mails and other messages from FARMly B.V. by following the instructions in these messages. Please note that if you opt out, we may still send you nonpromotional messages, such as receipts for your orders, preferences or information about your account.",
    infoRetention5_pt: "A FARMly aplica medidas organizacionais, legais e técnicas para proteger suas informações privadas.",
    infoRetention5_en: "FARMly B.V. applies organizational, legal and technical measures to protect your private information.",

    updates1_pt: "Esta política pode ser atualizada ocasionalmente. Nós o encorajamos a revisar periodicamente esta Política para obter as informações mais recentes sobre nossas práticas de privacidade. Também disponibilizaremos versões anteriores de nossas Políticas de Privacidade para análise. Se você usar a plataforma e os serviços FARMly  depois que uma nova versão da Política de Privacidade entrar em operação, significa que você concorda com a Política atualizada.",
    updates1_en: "This Policy may be occasionally updated. We encourage you to periodically review this Policy for the latest information on our privacy practices. We will also make prior versions of our Privacy Policies available for review. If you use FARMly B.V. platform and services after a new version of the Privacy Policy comes into operation, it means you consent to the updated Policy.",
}

const cookiesTexts = {
    section1_pt: "Nosso site usa cookies. Cookies são arquivos de software que as páginas da Web ou aplicativos instalam nos navegadores ou nos dispositivos dos usuários enquanto eles navegam nas várias páginas do portal ou aplicativos e que são usados para armazenar informações sobre as visitas dos usuários. Portanto, cookie é um pequeno arquivo de texto que um portal da web deixa no dispositivo do usuário (computador, tablet ou smartphone) quando ele o visita. Dados pessoais podem ser armazenados em cookies.",
    section1_en: "Our website uses cookies. Cookies are software files that web pages or applications install in browsers or on users’ devices while they are browsing the various pages of the portal or applications and which are used to store information about users’ visits. Therefore, cookie is a small text file that a web portal leaves on the user's computer system (computer, tablet computer or smartphone) when he visits it. Personal data can be stored in cookies.",
    section2_pt: "O uso de cookies facilita a navegação, para adaptar as informações e serviços oferecidos aos interesses dos usuários ou para personalizar, adaptar e melhorar a experiência de navegação dos usuários sempre que eles acessam a página da web. Da mesma forma, esta página usa cookies para (a) Garantir que as páginas estejam funcionando corretamente; (b) Armazenar as preferências do usuário, assim como o idioma selecionado e o tamanho da fonte; (c) Coletar os detalhes da experiência de navegação do usuário e (d) Coletar informações estatísticas anônimas, como as páginas visitadas pelo usuário e por quanto tempo permanecem em cada página.",
    section2_en: "The use of cookies makes browsing easier, in order to the adaptation of the information and services offered to users’ interests, or to customize, adapt and improve users’ browsing experience every time they visit the web page. Likewise, this page uses cookies to (a) Ensure that pages are functioning correctly; (b) Store the user’s preferences, as well as the selected language and the font size; (c) Discover the details of the user’s browsing experience and (d) Gather anonymous statistical information, such as for example, the pages that the user visits and how long they remain on each page.",
    section3_l1_pt: "A FARMly pode usar diferentes tipos de cookies, que podem ser verificados e gerenciados livremente pelo usuário ao acessar a Web. Os cookies são descritos a seguir como (i) cookies próprios, que são aqueles enviados ao dispositivo do Usuário de um computador ou domínio gerenciado pela FARMly e a partir dos quais o serviço solicitado pelo Usuário é fornecido. E (ii) cookies de terceiros são aqueles enviados ao dispositivo do Usuário de um computador ou domínio não gerenciado pela FARMly e do qual o serviço solicitado pelo Usuário é fornecido, mas para outra entidade que processa os dados coletados pelo cookie.",
    section3_l1_en: "FARMly B.V. can use different types of cookies, which may be freely checked and managed by the user when accessing the Web. Cookies are described hereinafter as (i) own cookies are those sent to the Users terminal from a computer or domain managed by FARMly and from which the service requested by the User is provided. And (ii) third party cookies are those sent to the User’s terminal from a computer or domain that is not managed by FARMly and from which the service requested by the User is provided but for another entity that processes the data gathered by the cookie.",
    section3_l2_pt: "Nas configurações do seu navegador, você pode impedir a rastreabilidade através de cookies ou proibir o armazenamento de cookies de terceiros. Os cookies, dependendo de quanto tempo o usuário permanece na página, podem ser classificados como (i) cookies de sessão ou (ii) cookies permanentes. O primeiro tipo de cookies expira quando o usuário fecha o navegador. O segundo tipo de cookies expira quando o objetivo é concluído (por exemplo, identificar o usuário na página da web) ou quando eles são desativados manualmente.",
    section3_l2_en: "In your browser settings, you can prevent traceability through cookies or prohibit the storage of third-party cookies. Cookies, depending on how long the user remains on the page, may be classified as (i) session or (ii) permanent cookies. The first type of cookies expires when the user closes the browser. The second type of cookies expires when their objective has been completed (for example, identifying the user on the web page) or when they are turned off manually.",
    section3_l3_pt: "Dependendo da sua finalidade, os cookies podem ser classificados como estritamente necessários, que são essenciais para que a página da Web funcione corretamente. Eles geralmente são gerados quando os usuários acessam a página da Web ou efetuam login, sendo usados ​​para identificar usuários na página da Web, e seus propósitos são: (i) Manter o usuário identificado para que, ao deixar a página da Web e retornar mais tarde, ele possa ainda ser identificado, facilitando a navegação sem a necessidade de fazer um novo login e (ii) Garantir que os usuários tenham autorização de acesso a determinadas áreas ou serviços na página da web. Esses cookies são necessários para a operação técnica do site e para o controle de nossos objetivos corporativos comerciais. Algumas funções do nosso site não podem ser oferecidas sem o uso desses cookies. Utilizamos cookies tecnicamente necessários, com base em interesses legítimos (Art. 6, n. 1, “f”, GDPR e Art. 7, inciso IX, LGPD). Nosso interesse legítimo é garantir o funcionamento de nosso site e sua usabilidade ideal. Nossos cookies também podem ser classificados como cookies de desempenho, que são usados para melhorar a experiência de navegação e otimizar a página da web, como por exemplo, para armazenar as configurações do serviço e as compras feitas em um Carrinho de Compras.",
    section3_l3_en: "Depending on their purpose, cookies can be classified as strictly necessary (recording), which is essential for the web page to work correctly. They are typically generated when users access the web page or log in, being used to identify users on the web page, and their purposes are (i) keeping the user identified so that, when they leave the web page and return later, they can still be identified, making browsing easier without the need to do a new log-in and (ii) ensure that users have access authorization to certain areas or services on the web page. These cookies are necessary for the technical operation of the site and for the control of our commercial corporate goals. Some functions of our website cannot be offered without the use of these cookies. We use technically necessary cookies based on legitimate interests (Art. 6, n. 1, “f”, GDPR). Our legitimate interest is to ensure the functioning of our websites and their optimal usability. Our cookies can be classified as performance, which is used to improve the browsing experience and optimize the web page, such as for example, to store the settings of the service and the purchases made in a Shopping Cart.",
    section3_l4_pt: "Além disso, pode ser classificado como cookies de publicidade, que determinam o recebimento de informações sobre publicidade exibidas aos usuários da página da web. Existem dois tipos, (a) anônimo, que apenas coletam informações sobre a publicidade exibida na página da web, sem identificar explicitamente os usuários; ou (b) personalizados, que coletam informações pessoais sobre os usuários da página da web de terceiros, para personalizar a publicidade.",
    section3_l4_en: "Also, can be classified as advertising, which determine the receipt of information about advertising shown to users of the web page. There are two types, (a) Anonymous, that only collect information about the advertising displayed on the web page, without explicitly identifying users; or (b) customized, they collect personal information about the users of the web page for third parties, to customize the advertising.",
    section3_l5_pt: "Além disso, podem ser classificados como cookies de localização geográfica, que são usados para descobrir o país ou a região em que os usuários acessam uma página da web, a fim de oferecer conteúdo específico adequado às suas localizações.",
    section3_l5_en: "In addition, the classification can be as Geo-location, these cookies are used to discover the country or region where users access a web page, in order to offer specific content suited to their locations.",
    section3_l6_pt: "Finalmente, também podem ser classificados como cookies analíticos, que coletam informações sobre a experiência de navegação dos usuários na página da web, geralmente de forma anônima, embora às vezes também permitam que um usuário seja identificado, única e inequivocamente a fim de obter informações sobre os interesses do usuário nos serviços fornecidos pela página da web.",
    section3_l6_en: "In the end, can be classified as analytical, which collect information about the browsing experience of users on the web page, usually anonymously, although they sometimes also allow a user to be identified, solely and unequivocally in order to obtain information about the user’s interests in the services provided by the web page. ",
    section3_l7_pt: 'Em nosso site, usamos o Google Analytics, um serviço de análise da web fornecido pelo Google Ireland Limited, com sede em Gordon House, Barrow Street, Dublin 4, Irlanda ("Google"). O Google Analytics armazena cookies no seu dispositivo final, o que possibilita avaliar o uso do nosso site. Para esse fim, o Google coleta dados, por exemplo, para identificar claramente seu navegador, informações sobre quando e com que frequência você acessa nosso site, há quanto tempo está no nosso site e como interagiu com o nosso site. Encontre mais informações visitando o endereço: https://marketingplatform.google.com/about/analytics/terms/de/. Usamos o Google Analytics se você deu seu consentimento. Obtemos seu consentimento quando você visita nosso site através do banner de cookies. (Procedimento "Opt In").',
    section3_l7_en: 'On our website we use Google Analytics, a web analytics service provided by Google Ireland Limited, based in Gordon House, Barrow Street, Dublin 4, Ireland ("Google"). Google Analytics sets cookies on your end device, which make it possible to evaluate your use of our website. For this purpose, Google collects data, for example, to clearly identify your browser, information about when and how often you have visited our website, how long you have been on our website and how you interacted with our website. Find more information on this visiting https://marketingplatform.google.com/about/analytics/terms/de/. We use Google Analytics if you have given your consent. We obtain your consent when you visit our website via the cookie banner. ("Opt In" procedure).',
    section3_l8_pt: "Queremos dar a você a oportunidade de tomar uma decisão informada a favor ou contra o uso de cookies, que não são necessários para as funções técnicas do site. Observe que, se você rejeitar cookies de publicidade, receberá uma publicidade menos adaptada aos seus interesses. No entanto, o uso do site permanece na íntegra.",
    section3_l8_en: "We want to give you the opportunity to make an informed decision for or against the use of cookies, which are not necessary for the technical functions of the website. Please note that if you reject advertising cookies, you will receive advertising that is less tailored and tailored to your interests. However, the use of the website remains in full.",
    section4_l1_pt: 'Todos os navegadores permitem que o usuário aceite, rejeite ou exclua cookies, selecionando as configurações apropriadas no navegador. O usuário pode configurar os cookies no menu "Opções" ou "Preferências" do navegador. No entanto, é importante enfatizar que desativar os cookies pode impedir que alguns serviços da Web funcionem corretamente, afetando, total ou parcialmente, a navegação na página da Web.',
    section4_l1_en: "All browsers allow the user to accept, reject or delete cookies, namely by selecting the appropriate settings on their browser. The user can configure cookies on the “Options” or “Preferences” menu of their browser. However, it is important to stress that the deactivation of cookies may prevent some web services from functioning properly, affecting, wholly or partially, browsing on the web page.",
    section4_l2_pt: "Outras ferramentas de terceiros, disponíveis on-line, permitem que os usuários detectem os cookies em todas as páginas da web visitadas e realizem sua desativação.",
    section4_l2_en: "Other third-party tools, available online, allow users to detect the cookies on all web pages that they visit and carry out their deactivation.",
    section4_l3_pt: "Você tem controle total sobre o uso de cookies e pode excluir cookies no seu navegador, desativar completamente o armazenamento de cookies ou aceitar seletivamente determinados cookies. Por favor, use as funções de ajuda do seu navegador para descobrir como você pode alterar essas configurações. Isso pode limitar a funcionalidade do nosso site.",
    section4_l3_en: "You have full control over the use of cookies and can delete cookies in your browser, completely deactivate the storage of cookies or selectively accept certain cookies. Please use the help functions of your browser to find out how you can change these settings. This may limit the functionality of our website.",
    section5_pt: "A FARMly não se responsabiliza pelo conteúdo e veracidade das políticas de privacidade dos componentes de terceiros incluídos nesta página da web. Como garantia adicional, a gravação de cookies nesta página da web pode estar sujeita à aceitação do usuário durante sua visita à página da web e à instalação ou atualização do navegador usado. Esta aceitação pode ser revogada a qualquer momento na política de conteúdo e privacidade ou pelos termos e condições.",
    section5_en: "FARMly shall not be liable for the content and truthfulness of privacy policies of third-party components included in this web page. As an additional guarantee, the recording of cookies on this web page may be subject to the user’s acceptance of cookies during their visit to the web page and to the installation or updating of the browser used. This acceptance may be revoked at any time in the content and privacy policy or by terms and conditions.",
    section6_pt: "A FARMly pode modificar esta política de cookies de acordo com os requisitos legais ou regulamentares, ou adaptar esta política às novas instruções promulgadas por lei. Quando houver alterações significativas nesta política de cookies, os usuários da página serão informados.",
    section6_en: "FARMly may modify this cookies policy in accordance with the legal or regulatory requirements or adapt this policy to new instructions enacted by law. When there are significant changes to this cookies policy, the users of the web page will be informed.",
}

export { privacyTexts, cookiesTexts }