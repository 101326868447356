import React, { useState, useEffect, useContext, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { useHistory, Link } from "react-router-dom";
import Slider from "react-slick";

import Dropzone from "react-dropzone";

import "./About.css";

import Title from "../templates/Title.jsx";
import axios from "axios";
import { isPt, isBr, isCo, farmlyTexts, server, acceptedCookies } from "../../common";
import UserContext from "../../context/user/UserContext.js";
import CartContext from "../../context/cart/CartContext.js";
import FarmlyInput from "../templates/FarmlyInput.jsx";
import FarmlyInputCoffee from "../templates/FarmlyInputCoffee.jsx";
import FarmlyModal from "../templates/FarmlyModal.jsx";
import CarouselSingle from "../templates/CarouselSingle.jsx";
import PricingInfo from "../templates/PricingInfo.jsx";
import FarmlyLoading from "../templates/FarmlyLoading.jsx";
import PaymentData from "./PaymentData.jsx";
import RatingCard from "../templates/Rating";
import ReactGA from 'react-ga'
import { GoogleLogin } from 'react-google-login'
import Cookies from 'js-cookie'
import RequestSample from '../coffeeProfile/RequestSample'
import {
	toEuro,
	totalCoffeePrice,
	logisticsFee,
	importDuties,
	farmlyFee,
	producersPriceKg,
	simpleIRPJ_CSLL,
    simpleEuroProdKg,
    simpleFarmlyFee,
    simpleLogisticsFee,
    simpleImportDuties

} from "../templates/pricing.js";
import { set } from "react-ga";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert, AlertTitle } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import Countries from "../../assets/countries.json";
import Select from "react-select";

const useStyles = makeStyles((theme) => ({
	root: {
		color: "rgb(27, 98, 94)",
		backgroundColor: "rgb(221, 243, 240)",
		maxWidth: "290px",
		position: "fixed",
		top: "75%",
		right: "1%",
		zIndex: "2",
	},
}));

const typeIcon = require("../../assets/Icons/coffee-cup.svg");
const varietyIcon = require("../../assets/Icons/coffee-beans.svg");
const processIcon = require("../../assets/Icons/sapling-with-leaves.svg");
const harvestIcon = require("../../assets/Icons/calendar.svg");
const remainIcon = require("../../assets/Icons/sack.svg");
const heightIcon = require("../../assets/Icons/hills.svg");
const priceIcon = require("../../assets/Icons/money.svg");
const scoreIcon = require("../../assets/Icons/star.svg");
const decorImage = require("../../assets/coffee_decoration_homepage.jpg");

const infoItems = [
	{
		icon: typeIcon,
		name: isPt()
			? farmlyTexts.iconRegion_pt
			: isCo()
			? farmlyTexts.iconRegion_es
			: farmlyTexts.iconRegion_en,
		infoClass: 0
	},
	{
		icon: varietyIcon,
		name: isPt()
			? farmlyTexts.iconVariety_pt
			: isCo()
			? farmlyTexts.iconVariety_es
			: farmlyTexts.iconVariety_en,
			infoClass: 1

	},
	{
		icon: processIcon,
		name: isPt()
			? farmlyTexts.iconProcess_pt
			: isCo()
			? farmlyTexts.iconProcess_es
			: farmlyTexts.iconProcess_en,
			infoClass: 2

	},
	{
		icon: harvestIcon,
		name: isPt()
			? farmlyTexts.iconHarvest_pt
			: isCo()
			? farmlyTexts.iconHarvest_es
			: farmlyTexts.iconHarvest_en,
			infoClass: 3

	},
	{
		icon: remainIcon,
		name: isPt()
			? farmlyTexts.iconRemain_pt
			: isCo()
			? farmlyTexts.iconRemain_es
			: farmlyTexts.iconRemain_en,
		type: "numeric",
		infoClass: 4
	},
	{
		icon: heightIcon,
		name: isPt()
			? farmlyTexts.iconHeight_pt
			: isCo()
			? farmlyTexts.iconHeight_es
			: farmlyTexts.iconHeight_en,
		type: "measure",
		measureUnit: "m",
		infoClass: 5
	},
	{
		icon: scoreIcon,
		name: isPt()
			? farmlyTexts.iconScore_pt
			: isCo()
			? farmlyTexts.iconScore_es
			: farmlyTexts.iconScore_en,
		type: "numeric",
		infoClass: 6
	},
	{
		icon: varietyIcon,
		name: isPt()
			? farmlyTexts.iconSieve_pt
			: isCo()
			? farmlyTexts.iconSieve_es
			: farmlyTexts.iconSieve_en,
		type: "numeric",
		infoClass: 7
	},
];

const InfoItem = (props) => {
	const {
		icon,
		name,
		info,
		editable,
		setInfo,
		type,
		measureUnit,
		setShowUpdateButtons,
		
	} = props;

	return (
		<div className="coffee-item-container">
			<img className="coffee-info-icon" src={icon} alt={name} />
			<div
				className="coffee-item-texts"
				style={editable ? { flexDirection: "column" } : {}}
			>
				<label className="coffee-info-name">{name + ":  "}</label>
				{editable ? (
					<FarmlyInputCoffee
						styleClass="coffee-info-input"
						value={info || ""}
						setValue={setInfo}
						type={type ? type : ""}
						placeholder={name}
						measureUnit={measureUnit ? measureUnit : ""}
						elementId={name}
						setShowUpdateButtons={setShowUpdateButtons}
					/>
				) : (
					<Fragment>
						{name === "Remaining bags:  " || "Sacas restantes:  "  || "Bolsas restantes:  "? (
							<Fragment>
								<Fragment>
									{info === 0 ? (
										<label className="coffee-info-label">
											{isPt()
												? "Sem estoque"
												: isCo()
												? "No disponible"
												: "Out of stock"}
										</label>
									) : (
										<label id="sackRemainLabel" className="coffee-info-label">{info}</label>
									)}
								</Fragment>
							</Fragment>
						) : (
							<label className="coffee-info-label">{info}</label>
						)}
					</Fragment>
					
				)}
			</div>
		
		</div>
		
	);
};

const About = (props) => {
	const {
		sackQuantity,
		increaseQuantity,
		decreaseQuantity,
		setSackQuantity,
		producerData,
		farmImageUrl,
		coffeeImageUrl,
		isOwner,
		coffeeData,
		setCoffeeData,
		handleSave,
		reset,
		sendDataToRemoveImage,
		hasEdited,
		addToCart,
		removeFromCart,
		isInCart,
		requestedAlready,
		setRequestedAlready,
		coffeeId,
		updateCoffeeOnCart,
		fileUpload,
		onDropRejected,
		setCoffeeDataOrigin,
	} = props;
	const [quantityText, setQuantityText] = useState(sackQuantity.toString());
	const [loading, setLoading] = useState(true);
	const [shippmentInfo, setShippmentInfo] = useState({})
	let userContext = useContext(UserContext);
	
	const cartContext = useContext(CartContext);

	const state = (null)
	const [userData, setUserData] = useState({
        email: null
    })
	const [requestPopup, setRequestPopup] = useState(false)
	const [requestPopupSignUp, setRequestPopupSignUp] = useState(false)
	const [requestPopupFollowing, setRequestPopupFollowing] = useState(false)
	const [isRequestSamplePopupAtDisplay, setIsRequestSamplePopupAtDisplay] = useState(false)
    const [samplesArray, setSamplesArray] = useState([])
    const [noSamplePopup, setNoSamplePopup] = useState(false)
    const [emailFailure, setEmailFailure] = useState(false)
    const [authFailure, setAuthFailure] = useState(false)
    const [serverFailure, setServerFailure] = useState(false)
    const [passwordDontMatch, setPasswordDontMatch] = useState(false)
    const [failureSignInGoogle, setModalSignin] = useState(false)
    const [signupGoogle, setSignupGoogle] = useState(false)
    const [pleaseTypePassword, setPleaseTypePassword] = useState(false)
    const [coffeeAmt, setCoffeeAmt] = useState({
        sub80: '0',
        s80_82: '0',
        s82_84: '0',
        s84_86: '0',
        s86_88: '0',
        over88: '0'
    })

	const [isFavorite, setIsFavorite] = useState();
	const [confirmDelete, setConfirmDelete] = useState(false);
	const [enDescription, setEnDescription] = useState(false);
	const [displayPaymentData, setDisplayPaymentData] = useState(false);
	const [paymentData, setPaymentData] = useState({
		farmly_fee: 0,
		logistics_fee: 0,
		import_duties: 0,
		import_duties_adjustment: 0,
		euro_value: 0,
	});
	const newDate = new Date();
	const day = ("0" + newDate.getDate().toString()).slice(-2);
	const month = ("0" + (newDate.getMonth() + 1).toString()).slice(-2);
	const year = newDate.getFullYear().toString();
	const dateString = [month, day, year].join("-");
	const [modalMessage, setModalMessage] = useState("");
	const [showModal, setShowModal] = useState(false);
	const [successUnfavorite, setmodalUnfavorite] = useState(false);
	const [successFavorite, setmodalFavorite] = useState(false);
	const [showPricingInfo, setShowPricingInfo] = useState(false);
	const [showReview, setShowReview] = useState(false);
	const [showReviewDone, setShowReviewDone] = useState(false);
	const [showUpdateButtons, setShowUpdateButtons] = useState(false);
	const [
		showUpdateButtonsDescription,
		setShowUpdateButtonsDescription,
	] = useState(false);
	const [showTimerModal, setShowTimerModal] = useState(false);
	const [reviewInfo, setReviewInfo] = useState({
		coffeeId: coffeeId,
		roasterId: userContext.user ? userContext.user.id : null,
		date: dateString,
		score: null,
		comment: null,
		email:null,
		roaster: null
	});

	const [reviewDone, setReviewDone] = useState({});
	const [toggleRequestSampleModal, setToggleRequestSampleModal] = useState(false);

	const [arrayReviews, setArrayReviews] = useState([]);
	const [hasReview, setHasReview] = useState(false);
	const history = useHistory();
	const [tIRPJ_CSLL, setTIRPJ_CSLL] = useState(0.00);
	const [tImportDuties, setTImportDuties] = useState(0.00);
	const [tLogistics_Fee, settLogistics_Fee] = useState(0.00);
	const [tFarmly_fee, settFarmly_fee] = useState(0.00);
	const [coffeePrice, setCoffeePrice] = useState("0.00");
	const [tEuroKgPrice, setTEuroKgPrice] = useState(0.00);
	const [coffeePriceKg, setCoffeePriceKg] = useState("0.00");
	const [totalKgPrice, setTotalKgPrice] = useState("0.00");
	const [euroProdKg, setEuroProdKg] = useState(0.00);
	const [adminCart, setAdminCart] = useState(false);
	const [collectReferenceModal, setCollectRefenceModal] = useState(false);
	const [roasterReference, setRoasterReference] = useState({});
	const [addedToCart, setAddedToCart] = useState(false);
	const [open, setOpen] = useState(false);

	useEffect(() => {
		if (coffeeData && paymentData) {
			const price = totalCoffeePrice(
				{ ...coffeeData, quantity: sackQuantity },
				paymentData
			);
			
			setCoffeePrice(isNaN(price) ? "0.00" : (price / 60).toFixed(2));
			const kgPrice = totalCoffeePrice(
				{ ...coffeeData, quantity: "1" },
				paymentData
			);
			setTotalKgPrice(isNaN(kgPrice) ? "0.00" : (coffeeData.origin == "Brazil" ? (kgPrice / 60).toFixed(2) : (kgPrice / 70).toFixed(2)))
			
			// document.addEventListener('DOMContentLoaded', () => {
			// 	document.querySelector("#sackRemainLabel").innerHTML = coffeeData.origin === "Brasil" ? coffeeData.sackRemain  + "- (60 kg)" : coffeeData.sackRemain + "- (70 kg)" 
			// })
		
		}
	}, [coffeeData, paymentData, sackQuantity]);

	useEffect(() => {
		if(coffeeData){
			calculateCoffeeTaxes().catch(err => {
				throw err;
			})
		}
	}, [coffeeData])

	const priceKgEuro = coffeeData.origin == "Brazil" ? toEuro(totalKgPrice, paymentData, coffeeData).toFixed(2) : totalKgPrice

	const producers_price_kg = producersPriceKg({
		...coffeeData,
		quantity: sackQuantity,
	}).toFixed(2);
	const priceEuro = toEuro(producers_price_kg, paymentData, coffeeData).toFixed(2);
	const priceInt = coffeeData.origin == "Brazil" ? (coffeeData.sackPrice/60).toFixed(2).split(".")[0] : (coffeeData.sackPrice/70).toFixed(2).split(".")[0]
	const priceCent = coffeeData.origin == "Brazil" ? (coffeeData.sackPrice/60).toFixed(2).split(".")[1] : (coffeeData.sackPrice/70).toFixed(2).split(".")[1]
	const priceIntEuro = coffeeData.sackPrice ? priceKgEuro.split(".")[0] : 0;
	const priceCentEuro = coffeeData.sackPrice ? priceKgEuro.split(".")[1] : "00";

	const logistics_fee = logisticsFee(
		{ ...coffeeData, quantity: sackQuantity },
		paymentData
	);
	const import_duties = importDuties(
		{ ...coffeeData, quantity: sackQuantity },
		paymentData
	);
	const farmly_fee = farmlyFee(
		{ ...coffeeData, quantity: sackQuantity },
		paymentData
	)

	// se a resposta não for um array vazio, ele seta o favorito como true * gambiarra *
	useEffect(() => {
		if (userContext.user != null && !userContext.user.isProd) {
			axios
				.get(`${server}/favorites/${Cookies.get("reviewRoasterId")? Cookies.get("reviewRoasterId") : userContext.user.id}/${coffeeId}`)
				.then((res) =>( res.data.length && setIsFavorite(true)))
				.catch(setIsFavorite(false));
			axios
				.get(`${server}/requested/${Cookies.get("reviewRoasterId")? Cookies.get("reviewRoasterId") : userContext.user.id}/${coffeeId}`)
				.then((res) => res.data.length && setRequestedAlready(true) && Cookies.delete("reviewRoasterId"))
				.catch((err) => setRequestedAlready(false) && console.log(err));
		} else {
			setIsFavorite(false);
		}
		
		axios.get(`${server}/paymentData`).then((res) => setPaymentData(res.data));
	}, []);

	document.addEventListener('DOMContentLoaded', () => {
		if(window.location.search) {
			const queryParams = window.location.search.substring(1).split('=')
			if(queryParams[0] === "focus" && queryParams[1] === "true")
			document.getElementById('about-quantity').scrollIntoView()
		}
	})
	useEffect(() => {	
		setQuantityText(sackQuantity)
	}, [sackQuantity]);

	const toggleFavorite = () => {
		setIsFavorite(!isFavorite);
		!isFavorite &&
			axios.post(`${server}/favorites`, {
				coffee_id: props.coffeeId,
				roaster_id: userContext.user.id,
			}) &&
			setmodalFavorite(true);
		!!isFavorite &&
			axios.delete(`${server}/favorites`, {
				data: {
					coffee_id: props.coffeeId,
					roaster_id: userContext.user.id,
				},
			}) &&
			setmodalUnfavorite(true);
	};

	const onQuantityChange = (e) => {
		const text = e.target.value;
		if (!isNaN(text) && text != "") {
			const val = parseInt(text);
			if (val >= 0 && val <= coffeeData.sackRemain) {
				setSackQuantity(val);
				setQuantityText(val.toString());
			}
		} else if (text == "") setQuantityText("");
	};

	const onQuantityFocus = () => {
		setQuantityText("");
	};

	const onQuantityBlur = () => {
		if (quantityText == "") setQuantityText(sackQuantity.toString());
	};

	

	infoItems[0].prop = "region";
	infoItems[1].prop = "variety";
	infoItems[2].prop = "process";
	infoItems[3].prop = "harvestDate";
	infoItems[4].prop = "sackRemain";
	infoItems[5].prop = "height";
	infoItems[6].prop = "score";
	infoItems[7].prop = "sieve";

	if (isOwner) infoItems[4].name = "Quantidade de sacas";

	
	const signUp = async () => {
        let user = { ...userData, postalCode: "", address: "" }
        user.notfication_new = true
        user.coffeeScores = (
            `sub80:${coffeeAmt.sub80},` +
            `s80_82:${coffeeAmt.s80_82},` +
            `s82_84:${coffeeAmt.s82_84},` +
            `s84_86:${coffeeAmt.s84_86},` +
            `s86_88:${coffeeAmt.s86_88},` +
            `over88:${coffeeAmt.over88},`
        )
        // user.sensoryNotes = Object.values(sensoryNotes).reduce((prev, curr) => prev + curr)
        ReactGA.event({
            category: 'New roaster registered',
            action: 'New Roaster Signed Up'
        })
        if (user.confirmPassword === user.password) {
            delete user.confirmPassword
            delete user.invitedBy
            try {
                const payload = await axios.post(`${server}/signup/0`, {
                    ...user
                })
                userContext.userLogin({
                    isProd: payload.data.isProd,
                    jwt: payload.data.jwt,
                    id: payload.data.id
                })
                const cookie = acceptedCookies() ?
                    document.cookie : ""
                if (cookie.includes('cartList') && !cartContext.cookiesCartIsEmpty) {
                    cartContext.setCartToServer(payload.data.jwt)
                }

                if (acceptedCookies()) {
                    Cookies.set('jwt', payload.data.jwt, {expires: payload.data.exp, path: '/'})
                    Cookies.set('isProd', payload.data.isProd, {expires: payload.data.exp, path: '/'})
                    Cookies.set('userId', payload.data.id, {expires: payload.data.exp, path: '/'})
                    Cookies.set('main_id', payload.data.main_id, {expires: payload.data.exp, path: '/'})
                }
				setReviewInfo({...reviewInfo, roasterId : payload.data.id})
				setRequestPopupSignUp(false)
                setPasswordDontMatch(false)
				loginToReview?setShowReview(true):setRequestPopupFollowing(true);
            } catch (err) {
                console.log(err)
            }
        }
        else setPasswordDontMatch(true)

    }

	async function login() {
        axios.post(`${server}/signin`, {
            email: userData.email,
            password: userData.password
        })
            .then(payload => {
                const cookie = acceptedCookies() ?
                    document.cookie : ""
                userContext.userLogin({
                    isProd: payload.data.isProd,
                    jwt: payload.data.jwt,
                    id: payload.data.id
                })
				
                if (cookie.includes('cartList') && !cartContext.cookiesCartIsEmpty) {
                    cartContext.setCartToServer(payload.data.jwt)
                } else if (!payload.data.isProd) {
                    cartContext.getCartFromServer(payload.data.id)
                }

                ReactGA.event({
                    category: 'Login',
                    action: 'User Logged In'
                })
                if (acceptedCookies()) {
                    Cookies.set('jwt', payload.data.jwt, {expires: payload.data.exp, path: '/'})
                    Cookies.set('isProd', payload.data.isProd, {expires: payload.data.exp, path: '/'})
                    Cookies.set('userId', payload.data.id, {expires: payload.data.exp, path: '/'})
                    Cookies.set('main_id', payload.data.main_id, {expires: payload.data.exp, path: '/'})
                }
                if (!payload.data.isProd) {
                    axios.get(`${server}/roaster/${payload.data.id}`)
                        .then(roaster => {
                            setUserData(roaster.data)
							setReviewInfo({...reviewInfo, roasterId: payload.data.id })
							loginToReview?setShowReview(true):setRequestPopupFollowing(true);
                            setRequestPopup(false)
                        })
                }

            })
            .catch(err => {
                const status = err.response ? err.response.data.status : 'server-failure'
                if (status === 'email-not-found') {
                    setEmailFailure(true)
                } else if (status === 'auth-failed' || status === 'incomplete') {
                    setAuthFailure(true)
                } else if (status === 'server-failure') {
                    setServerFailure(true)
                }
            })
    }
	
	const triggerRequest = () => {
        setRequestPopupFollowing(!requestPopupFollowing)
		alert(!requestPopupFollowing)
	    }
	
    const googleLogin = (email, name, password) => {
        setEmailFailure(false)
        setAuthFailure(false)

        if (password === '') {
            setPleaseTypePassword(true)
            return
        }
        axios.post(`${server}/signin`, {
            email: email,
            password: password
        })
            .then(payload => {
                const cookie = acceptedCookies() ?
                    document.cookie : ""
                userContext.userLogin({
                    isProd: payload.data.isProd,
                    jwt: payload.data.jwt,
                    id: payload.data.id
                })
                if (cookie.includes('cartList') && !cartContext.cookiesCartIsEmpty) {
                    cartContext.setCartToServer(payload.data.jwt)
                } else if (!payload.data.isProd) {
                    cartContext.getCartFromServer(payload.data.id)
                }

                ReactGA.event({
                    category: 'Login',
                    action: 'User Logged In'
                })

                if (acceptedCookies()) {
                    Cookies.set('jwt', payload.data.jwt, {expires: payload.data.exp, path: '/'})
                    Cookies.set('isProd', payload.data.isProd, {expires: payload.data.exp, path: '/'})
                    Cookies.set('userId', payload.data.id, {expires: payload.data.exp, path: '/'})
                    Cookies.set('main_id', payload.data.main_id, {expires: payload.data.exp, path: '/'})
                }
				if (!payload.data.isProd) {
                    axios.get(`${server}/roaster/${payload.data.id}`)
                        .then(roaster => {
                            setUserData(roaster.data)
							loginToReview?setShowReview(true):setRequestPopupFollowing(true);
                            setRequestPopup(false)
                        })
                }else{
					history.push('/profile')
				}
            })
            .catch(err => {
                const status = err.response ? err.response.data.status : 'server-failure'

                if (status === 'email-not-found') {
                    setModalSignin(true)
                    if (setSignupGoogle) {
                        history.push({
                            pathname: '/signupGoogle',
                            state: {
                                userData: {
                                    email: email,
                                    name: name,
                                    password: password,
                                    confirmPassword: password

                                }
                            }
                        })
                        history.go()
                    }
                } else if (status === 'auth-failed' || status === 'incomplete') {
                    setAuthFailure(true)
                    setModalSignin(true)
                } else if (status === 'server-failure') {
                    setServerFailure(true)

                }
            })
    }


    const responseGoogle = (response) => {
        googleLogin(response.profileObj.email, response.profileObj.name, response.profileObj.googleId)
    }
	// useEffect(() => {
	// 	calculateCoffeeTaxes().catch(err => {throw err})
	// }, setShowPricingInfo)
	const renderInfoItems = () =>
		infoItems.map(
			(item) =>
				(isOwner ||
					(item.prop !== "score" &&
						coffeeData[item.prop] !== null &&
						coffeeData[item.prop] !== undefined &&
						coffeeData[item.prop] !== 0)) && (
					<InfoItem
						icon={item.icon}
						name={item.name}
						info={item.infoClass === 4 ? `${coffeeData[item.prop]} ${coffeeData.origin === "Brazil" ? "(60 kg)" : "(70 kg)"}` : item.infoClass === 5 ? coffeeData[item.prop] + "m" : coffeeData[item.prop]} 
						key={item.name}
						editable={isOwner}
						type={item.type}
						setInfo={(value) => {
							const updatedData = { ...coffeeData };
							updatedData[item.prop] = value;
							setCoffeeData(updatedData);
						}}
						measureUnit={item.measureUnit}
						setShowUpdateButtons={setShowUpdateButtons}
					/>
				)
				
		);

	
	const renderCoffeeItem = () => (
		<div className="coffee-price-container">
			<div className="coffee-price-texts">
				{/*<img className="coffee-info-icon coffee-price-icon" src={priceIcon} alt={'preço'} />*/}
				<label className="coffee-price-label">
					{isPt()
						? farmlyTexts.priceLabel_pt
						: isCo()
						? farmlyTexts.priceLabel_es
						: farmlyTexts.priceLabel_en}
				</label>
			</div>
			<FarmlyInput
				value={coffeeData.sackPrice || ""}
				styleClass="coffee-price-input"
				setValue={(value) => {
					setCoffeeData({ ...coffeeData, sackPrice: value });
				}}
				type="currency"
				placeholder="0.00"
				setShowUpdateButtons={setShowUpdateButtons}
			/>
			{userContext.isAdmin && (
				<div
					className="coffee-payment-data-button"
					onClick={() => setDisplayPaymentData(true)}
				>
					{isPt()
						? farmlyTexts.paymentMethod_pt
						: isCo()
						? farmlyTexts.paymentMethod_es
						: farmlyTexts.paymentMethod_en}
				</div>
			)}
		</div>
	);

	const deleteCoffee = () => {
		axios
			.delete(`${server}/coffee`, {
				data: {
					coffeeId: props.coffeeId,
					jwt: userContext.user.jwt,
				},
			})
			.then((res) => {
				history.push({ pathname: "/coffees" });
			})
			.catch((err) => alert("Algo deu errado!"));
	};

	const prodSendSample = () => {
		history.push({
			pathname: "/profile",
			state: {
				coffeeSection: true,
				coffeeId: props.coffeeId,
			},
		});
	};

	const [loginToReview,setLoginToReview] = useState(false); 
	
	function createReview() {
		if(userContext.user){
			const data = {...reviewInfo, roasterId: userContext.user.id}
			axios.post(`${server}/createReview`,data)
			setModalMessage("Review created. Wait a few seconds...");
			toggleModal();
			setHasReview(true);
			setShowReview(false);
			Cookies.set("reviewRoasterId", userContext.user.id)
			if(loginToReview){Cookies.set("reviewdRoasterId", userContext.user.id)}
			setTimeout(function () {
				refreshPage();
			}, 1500);
		}else{
			setLoginToReview(true)
			setRequestPopup(true)
			setShowReview(false);
		}
	};

function createReviewWithoutLogin(){
	const data = {...reviewInfo}
	axios.post(`${server}/review`,data).then(()=>{
		setModalMessage("Review created. Wait a few seconds...")
		toggleModal();
		setHasReview(true);
		setShowReview(false);
		setTimeout(function(){
			refreshPage();
		},1500)
	}).catch(()=>{
		setShowReview(false);
	})
}
	const deleteReview = () => {
		axios.delete(`${server}/deleteReview/${coffeeId}/${userContext.user.id}`);
		setShowReviewDone(false);
		setModalMessage("Review deleted. Wait a few seconds...");
		toggleModal();
		setHasReview(false);
		setTimeout(function () {
			refreshPage();
		}, 1500);
	};

	const toggleModal = () => {
		setShowModal(!showModal);
	};

	function refreshPage() {
		window.location.reload();
	}

	const cancelUpdate = () => {
		reset();
		setShowUpdateButtons(false);
	};

	const cancelUpdateDescription = () => {
		reset();
		setShowUpdateButtonsDescription(false);
	};

	const coffeeReservation = () => {
		addToCart();
		handleClickCart();
		setShowTimerModal(true);
		// axios.patch(`${server}/coffeeSackRemain/${coffeeId}`, {
		//     sackQuantity: sackQuantity,
		//     sackRemain: coffeeData.sackRemain
		// })
	};

	const addToAdminCart = () => {
		setCollectRefenceModal(false);
		if (!adminCart) {
			axios
				.post(`${server}/createCartItem`, {
					coffeeId: coffeeId,
					quantity: sackQuantity,
					reference: roasterReference.reference,
					country: roasterReference.country,
				})
				.then((_) => {
					setModalMessage(generateCartLink(roasterReference.reference));
					toggleModal();
				});
		}
	};

	const disablePaymentDataFields = (value, fieldName) => {
		if(value === 0 || value === ""){
			return
		}

		switch(fieldName){
			case "":
		}
	}

	const generateCartLink = (id) => {
		const link =`/exclusiveCart/${id}`
		return (
			<div>
			<span>Café adicionado ao carrinho Admin!</span><br></br>
			<a href={link}>Veja o café no carrinho</a>
			</div>
		)
	}

	const calculateCoffeeTaxes = async () => {
		if(coffeeData.sackPrice !== undefined) {
			const shipments = await (await axios.get(`${server}/getShippingData`)).data;
			const shipment = shipments.filter(c => c.shipping_id == coffeeData.shipment)
			if(shipment.length === 0){
				const baseFarmlyFee = coffeeData.farmlyFee !== null ? coffeeData.farmlyFee / 100 : 0.02 
				const sack = parseFloat(coffeeData.sackPrice)
				const producersPriceKg = coffeeData.origin === "Brazil" ? parseFloat((sack /60).toFixed(2)) : parseFloat((sack /70).toFixed(2));
				const prodEuro = coffeeData.origin === "Brazil" ? parseFloat((producersPriceKg / 5.09).toFixed(2)) : parseFloat((producersPriceKg / 2.06).toFixed(2))
				const irpj =  coffeeData.origin === "Brazil" ? (sack * 0.0308)/(5.09 * 60) : 0
				const importDuties = ((prodEuro * baseFarmlyFee) * 1.15) + irpj
				const logisticsFee = coffeeData.origin === "Brazil" ? 2.61 : 2.06
				const farmlyFee = ((parseFloat(prodEuro) + irpj) * baseFarmlyFee).toFixed(2)
				let euroPriceKg = 0
				if(coffeeData.is_applied_taxes) {
					const kgPrice = totalCoffeePrice(
						{ ...coffeeData, quantity: "1" },
						paymentData
					);
					const totalKgPrice = isNaN(kgPrice) ? 0.00 : (coffeeData.origin == "Brazil" ? (kgPrice / 60).toFixed(2) : (kgPrice / 70).toFixed(2))

					euroPriceKg = coffeeData.origin == "Brazil" ? toEuro(totalKgPrice, paymentData, coffeeData).toFixed(2) : totalKgPrice
				} else {
					euroPriceKg = coffeeData.origin === "Brazil" ? prodEuro + parseFloat(farmlyFee)+importDuties+logisticsFee: 
				prodEuro + parseFloat(farmlyFee) + importDuties + logisticsFee
				}
				setEuroProdKg(parseFloat(prodEuro))
				setTEuroKgPrice(parseFloat(euroPriceKg.toFixed(2)))
				setTImportDuties(parseFloat(importDuties.toFixed(2)))
				settLogistics_Fee(logisticsFee)
				settFarmly_fee(parseFloat(farmlyFee))
				return
			}	
			setShippmentInfo(shipment)
			console.log(shipment === [])
				const sack = parseFloat(coffeeData.sackPrice)
				const producersPriceKg = coffeeData.origin === "Brazil" ? parseFloat((sack /60).toFixed(2)) : parseFloat((sack /70).toFixed(2));
				let farmlyFee = 0, IRPJ_CSLL = 0, eurProdKg = 0, logisticFee = 0
				if(shipment[0].farmly_fee === null) {
					shipment[0].farmly_fee = 20  
				} 
				if(coffeeData.origin === "Brazil") {
					if(shipment[0].price_br === null) {
						shipment[0].price_br = 5.09
					}
					eurProdKg = simpleEuroProdKg(producersPriceKg, shipment[0].price_br)
					IRPJ_CSLL = simpleIRPJ_CSLL(sack, shipment[0].price_br)
				} else  {
					if(shipment[0].price_co === null) {
						shipment[0].price_co = 2
					}	
							
				}
				const import_duties = coffeeData.origin === "Brazil" ? simpleImportDuties(eurProdKg) + IRPJ_CSLL : simpleImportDuties(parseFloat(producersPriceKg) + IRPJ_CSLL) ;
				if(coffeeData.origin === "Brazil") {
					if(shipment[0].logistics_br === null) {
						logisticFee = simpleLogisticsFee(coffeeData.origin)
					} else {
						logisticFee = shipment[0].logistics_br
					}
				}  else {
					if(shipment[0].logistics_co === null) {
						logisticFee = simpleLogisticsFee(coffeeData.origin)
					} else {
						logisticFee = shipment[0].logistics_co
					}
				}
				const armazenamento = shipment[0].storage;
				const baseFarmlyFee = coffeeData.farmly_fee && coffeeData.farmly_fee !== shipment[0].farmly_fee ? coffeeData.farmly_fee : shipment[0].farmly_fee
				farmlyFee = coffeeData.origin === "Brazil" ? simpleFarmlyFee(eurProdKg, IRPJ_CSLL, baseFarmlyFee) : simpleFarmlyFee(producersPriceKg, IRPJ_CSLL, baseFarmlyFee)
				let euroPriceKg = 0
				if(coffeeData.is_applied_taxes) {
					const kgPrice = totalCoffeePrice({ ...coffeeData, quantity: "1" }, paymentData);
					const totalKgPrice = isNaN(kgPrice) ? 0.00 : (coffeeData.origin == "Brazil" ? kgPrice / 60  : kgPrice / 70)

					euroPriceKg = coffeeData.origin == "Brazil" ? parseFloat(toEuro(totalKgPrice, paymentData, coffeeData)) : parseFloat(totalKgPrice)
					console.log("olha o valor do euro" + euroPriceKg)
				} else {
					euroPriceKg = coffeeData.origin === "Brazil" ? eurProdKg + farmlyFee+import_duties+logisticFee+armazenamento : 
				producersPriceKg + farmlyFee + import_duties + logisticFee + armazenamento
				}
				// const euroPriceKg = coffeeData.origin === "Brazil" ? parseFloat(eurProdKg) +parseFloat(farmlyFee)+import_duties+logisticFee+armazenamento : 
				// producersPriceKg + parseFloat(farmlyFee) + import_duties + logisticFee + armazenamento
				console.log(producersPriceKg, farmlyFee, import_duties, logisticFee)
				if(coffeeData.origin === "Brazil") {
					setEuroProdKg(parseFloat(eurProdKg.toFixed(2)))
				} else {
					setEuroProdKg(parseFloat((sack /70).toFixed(2)))
				}
				setTIRPJ_CSLL(parseFloat(IRPJ_CSLL.toFixed(2)))
				setTImportDuties(parseFloat(import_duties.toFixed(2)))
				settLogistics_Fee(logisticFee)
				settFarmly_fee(parseFloat(farmlyFee.toFixed(2)))
				setTEuroKgPrice(euroPriceKg.toFixed(2))
			}
		
		
	}	

	const handleClickCart = () => {
		setOpen(true);
		setAddedToCart(true);
	};

	const handleCloseCart = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		setOpen(false);
		setAddedToCart(false);
	};

	const handleSignIn = () => {
		if(!userContext.user){
			setRequestPopup(true)
		}else{
			setRequestPopupFollowing(true)
		}
	}

	useEffect(() => {
		if (userContext.user) {
			axios
				.get(`${server}/searchReview/${coffeeId}/${userContext.user.id}`)
				.then(async (review) => {

					setReviewDone(review.data);
					if (review.data) {
						setHasReview(true);
					}
				}).catch((err)=>{console.log(err)})
		}
	}, [hasReview]);

	useEffect(() => {
		if (history.location.pathname !== "/coffee/new") {
			axios
				.get(`${server}/getReview/${coffeeId}`)
				.then(async (reviews) => {
					await setArrayReviews(reviews.data);
				})
				.catch((err) => {
					console.log(err);
					setArrayReviews([]);
				});
		}
			
	}, []);

	const renderReviews = () => {
	
		arrayReviews.map((item) => {
			return (
						
					<RatingCard
						score={item.score}
						comment={item.comment}
						roaster={item.roaster}
						data={item.date}
					/>
	
		
			);
		});
	};

	const renderOpts = () => {
		const country = [];
		Countries.map((opt) => {
			country.push({
				value: opt,
				label: opt,
			});
		});
		return country;
	};

	var settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 3,
		initialSlide: 0,
		autoplay: false,
		autoplaySpeed: 2500,
		adaptiveHeight: true,
		// variableWidth: true,
		pauseOnHover: true,
		responsive: [
			{
				breakpoint: 1030,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					infinite: false,
					dots: true,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					initialSlide: 2,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};

	setTimeout(function () {
		setLoading(false);
		//your code to be executed after 2.5 seconds
	}, 2500);

	const classes = useStyles();

	return (
		<>	
			{requestPopup &&
                <FarmlyModal closeModal={() => { setRequestPopup(false) }} className="modal-sample">
                    <div className="modal-landing-page">
                        <label id="request-title">
                            To proceed with your request, log in to our plataform!
                        </label>
                        <br />
                        <br />
                        <div className="producer-profile-row-center">
                            <div className="producer-profile-item">
                                <label htmlFor="" className="producer-profile-label" >Email: </label>
                                <FarmlyInput value={userData.email} setValue={value => setUserData({ ...userData, email: value })}
                                    styleClass="input-farmly new-offerlist-input" elementId="email" placeholder="email..." />
                            </div>
                        </div>
                        {emailFailure && <label className="login-fail-label"> Email not found.</label>}
                        {serverFailure && <label className="login-fail-label">Server error. Please, try again in a few moments, or send us a message describing the problem.</label>}
                        <div className="producer-profile-row-center">
                            <div className="producer-profile-item">
                                <label htmlFor="" className="producer-profile-label" >Password: </label>
                                <FarmlyInput value={userData.password} type='password' setValue={value => setUserData({ ...userData, password: value })}
                                    styleClass="input-farmly new-offerlist-input" elementId="password" placeholder="password..." />
                            </div>
                        </div>
                        {authFailure && <label className="login-fail-label"> Incorrect email or password.</label>}
                        <div className="new-offerlist-button-group">
                            <button className="login-button" onClick={() => login()}> Login</button>
                            <div className="signup-field-label">{isPt() ? 'Ou' : (isCo() ? 'O' : 'Or')}</div>
                            <div class="login-button-google" >
                                <GoogleLogin
                                    clientId="503177513332-b2g42k0864opttrsn7v6v8e2o3imvse5.apps.googleusercontent.com"
                                    buttonText={isPt() ? 'Entrar com Google' : (isCo() ? `Registro en Google` : "Log in with Google")}
                                    onSuccess={responseGoogle}
                                    onFailure={responseGoogle}
                                    cookiePolicy={'single_host_origin'}
                                />
                            </div>
                        </div>
                        <br />
                        <br />
                        <div>
                            <label id='new-account-label' >
                                New here?
                            </label>
                            <a className='sign-up-button-req'
                            onClick={() => {setRequestPopupSignUp(true); setRequestPopup(false) }}>
                                Sign up!
                            </a>
                        </div>
                    </div>
                </FarmlyModal>}
            {requestPopupSignUp &&
                <FarmlyModal closeModal={() => { setRequestPopupSignUp(false) }} className="modal-sample">
                    <div className="modal-landing-page">
                        <label id="request-title">
                            Fill all the fields below and sign up to proceed!
                        </label>
                        <div className="producer-profile-row-about-page" >
                            <div className="producer-profile-item">
                                <label htmlFor="" className="producer-profile-label" >Email: </label>
                                <FarmlyInput value={userData.email} setValue={value => setUserData({ ...userData, email: value })}
                                    styleClass="input-farmly-signin" elementId="email" placeholder="email..." />
                            </div>
							<div className="producer-profile-row-space"></div>
                            <div className="producer-profile-item">
                                <label htmlFor="" className="producer-profile-label" >Name: </label>
                                <FarmlyInput value={userData.name} setValue={value => setUserData({ ...userData, name: value })}
                                    styleClass="input-farmly-signin" elementId="name" placeholder="name..." />
                            </div>
                        </div>
                        <div className="producer-profile-row-about-page" >
                            <div className="producer-profile-item">
                                <label htmlFor="" className="producer-profile-label" >Password: </label>
                                <FarmlyInput value={userData.password} type='password' setValue={value => setUserData({ ...userData, password: value })}
                                    styleClass="input-farmly-signin" elementId="password" placeholder="password..." />
                            </div>
							<div className="producer-profile-row-space"></div>
                            <div className="producer-profile-item">
                                <label htmlFor="" className="producer-profile-label" >Confirm password: </label>
                                <FarmlyInput value={userData.confirmPassword} type='password' setValue={value => setUserData({ ...userData, confirmPassword: value })}
                                    styleClass="input-farmly-signin" elementId="confirmPassword" placeholder="confirm..." />
                            </div>
                        </div>
                        {passwordDontMatch && <label className="login-fail-label"> The password's fields have to match.</label>}
                        <div className="producer-profile-row-about-page" >
                            <div className="producer-profile-item">
                                <label htmlFor="" className="producer-profile-label" >Country: </label>
                                <FarmlyInput value={userData.country} setValue={value => setUserData({ ...userData, country: value })}
                                    styleClass="input-farmly-signin" elementId="country" placeholder="country..." />
                            </div>
							<div className="producer-profile-row-space"></div>
                            <div className="producer-profile-item">
                                <label htmlFor="" className="producer-profile-label">Phone Number: </label>
                                <FarmlyInput value={userData.phoneNumber} setValue={value => setUserData({ ...userData, phoneNumber: value })}
                                    styleClass="input-farmly-signin" elementId="phoneNumber" placeholder="phone..." />
                            </div>
                        </div>
                        <div className="sign-up-button">
                            <button className="register-button-samples" onClick={() => signUp()}> Sign Up</button>
                        </div>
                        <div className="signup-field-label">{isPt() ? 'Ou' : (isCo() ? 'O' : 'Or')}</div>
                        <div class="login-button-google-about">
                            <GoogleLogin
                                clientId="503177513332-b2g42k0864opttrsn7v6v8e2o3imvse5.apps.googleusercontent.com"
                                buttonText={isPt() ? 'Entrar com Google' : (isCo() ? `Registro en Google` : "Log in with Google")}
                                onSuccess={responseGoogle}
                                onFailure={responseGoogle}
                                cookiePolicy={'single_host_origin'}
                            />
                        </div>
                    </div>
                </FarmlyModal>}
            {failureSignInGoogle &&
                <FarmlyModal closeModal={() => setModalSignin(false)}> <div>
                    <label className="modal-label-email-error">
                        {isPt() ? 'Email não cadastrado. ' : (isCo() ? `Correo electrónico no registrado` : 'Email not registered')}
                    </label>
                    <button className="modalGoogle-button" onClick={() => setSignupGoogle(true)}>
                        {isPt() ? 'Cadastrar-se com esse email' : (isCo() ? `Inscríbase con este correo electrónico ` : 'Sign up with this email')}
                    </button>
                    <label className="signup-field-label">{isPt() ? 'Ou' : (isCo() ? 'O' : 'Or')}</label>
                    <button className="modalGoogle-button" onClick={() => setModalSignin(false)}>
                        {isPt() ? 'Tentar com outro email' : (isCo() ? `Inténtelo con otro correo electrónico ` : 'Try with another email')}
                    </button>

                </div>
                </FarmlyModal>}
			<div className="fill-window">
            	{requestPopupFollowing && !showReview && <RequestSample closeModal={() => setRequestPopupFollowing(false)} samplesArray={[coffeeId]} />}
            </div>
			{noSamplePopup &&
                <FarmlyModal closeModal={() => { setNoSamplePopup(false) }}>
                    <label>The shipping of the samples from this section of coffees has already ended.</label>
                    <br />
                </FarmlyModal>
            }

			{addedToCart && (
				<Snackbar
					open={open}
					autoHideDuration={40000}
					onClose={handleCloseCart}
				>
					<Alert
						elevation={6}
						variant="standard"
						onClose={handleCloseCart}
						severity="success"
					>
						<AlertTitle>This coffee was added to your cart, go up to the menu and access it.</AlertTitle>
						<br />
						It will be reserved to you for{" "}
						<b>
							{cartContext.timer.minutes == 0
								? "00"
								: cartContext.timer.minutes < 10
								? "0" + cartContext.timer.minutes
								: cartContext.timer.minutes}
							:
							{cartContext.timer.seconds == 0
								? "00"
								: cartContext.timer.seconds < 10
								? "0" + cartContext.timer.seconds
								: cartContext.timer.seconds}
						</b>
						<br />
						You can restart the timer by clicking in "Keep Buying" in your cart
						page! We will also let you know when you have 3 minutes left!
					</Alert>
				</Snackbar>
			)}
			{/* {addedToCart && <SnackbarContent
                className={classes.root}
                message={isBr() ? `Este café foi adicionado ao carrinho. \n 
                Esse produto estará reservado para você por ${cartContext.timer.minutes == 0 ? '00' : cartContext.timer.minutes < 10 ? '0' + cartContext.timer.minutes : cartContext.timer.minutes}:${cartContext.timer.seconds == 0 ? '00' : cartContext.timer.seconds < 10 ? '0' + cartContext.timer.seconds : cartContext.timer.seconds}\n
                Finalize suas compras ou continue comprando!`
                    :
                    `This coffee was added to your cart.\n
                It will be reserved to you for ${cartContext.timer.minutes == 0 ? '00' : cartContext.timer.minutes < 10 ? '0' + cartContext.timer.minutes : cartContext.timer.minutes}:${cartContext.timer.seconds == 0 ? '00' : cartContext.timer.seconds < 10 ? '0' + cartContext.timer.seconds : cartContext.timer.seconds}\n
                Check out your purchases or keep buying!`
                }
                action={action}
            />} */}

			{showModal && (
				<FarmlyModal id="page-modal" closeModal={toggleModal}>
					{modalMessage}
				</FarmlyModal>
			)}
			<div
				id="coffee-about"
				className={
					isPt()
						? farmlyTexts.menuAboutTitle_pt
						: isCo()
						? farmlyTexts.menuAboutTitle_es
						: farmlyTexts.menuAboutTitle_en
				}
			>
			
				{confirmDelete && isOwner && (
					<FarmlyModal closeModal={() => setConfirmDelete(false)}>
						<label className="delete-coffee-title">
							{isPt()
								? farmlyTexts.coffeeDelete_pt
								: isCo()
								? farmlyTexts.coffeeDelete_es
								: farmlyTexts.coffeeDelete_en}
						</label>
						<p>
							{isPt()
								? farmlyTexts.coffeeConfirmDelete_pt
								: isCo()
								? farmlyTexts.coffeeConfirmDelete_es
								: farmlyTexts.coffeeConfirmDelete_en}
						</p>
						<div className="confirm-delete-button" onClick={deleteCoffee}>
							{isPt()
								? farmlyTexts.coffeeDeleteButton_pt
								: isCo()
								? farmlyTexts.coffeeDeleteButton_es
								: farmlyTexts.coffeeDeleteButton_en}
						</div>
					</FarmlyModal>
				)}
				{showPricingInfo && (
					<FarmlyModal closeModal={() => setShowPricingInfo(false)}>
						{coffeeData.origin == "Brazil" ? (
							<PricingInfo
								brazil={coffeeData.origin == "Brazil" ? true : false}
								producerFee={
									isBr()
										? euroProdKg
										: euroProdKg
								}
								logistics_fee={
									isBr() ? tLogistics_Fee : tLogistics_Fee
								}
								import_duties={
									isBr() ? tImportDuties : tImportDuties
								}
								farmly_fee={
									isBr() ? tFarmly_fee : tFarmly_fee
								}
								// IRPJ_CSLL = {
								// 	isBr() ? tIRPJ_CSLL: tIRPJ_CSLL
								// }
								type = {
									1
								}
							/>
						) : (
							<PricingInfo
								brazil={coffeeData.origin == "Brazil" ? true : false}
								producerFee={euroProdKg}
								logistics_fee={tLogistics_Fee}
								import_duties={tImportDuties}
								farmly_fee={tFarmly_fee}
								// IRPJ_CSLL = {tIRPJ_CSLL}
								type= {1}
							/>
						)}
					</FarmlyModal>
				)}
				{collectReferenceModal && (
					<FarmlyModal closeModal={() => setCollectRefenceModal(false)}>
						<div className="confirm-info-container">
							<label className="pricing-info-title">
								Coloque aqui uma referência para o torrefador ou torrefadora e
								selecione o país
							</label>
							<div className="pricing-info-item">
								<label className="pricing-info-label">Referência:</label>
								<br />
								<FarmlyInput
									value={roasterReference.reference}
									setValue={(value) =>
										setRoasterReference({
											...roasterReference,
											reference: value,
										})
									}
									className="pricing-info-value"
									elementId="reference"
									placeholder="roaster..."
								/>
							</div>
							<div className="pricing-info-item">
								<label className="pricing-info-label">País:</label>
								<br />
								<div style={{ width: "320px" }}>
									<Select
										id="shipping-country"
										placeholder="Country..."
										onChange={(e) =>
											setRoasterReference({
												...roasterReference,
												country: e.value,
											})
										}
										options={renderOpts()}
									></Select>
								</div>
							</div>
							<br />
							<div className="checkout-buttons">
								<div
									className="cart-checkout-button"
									onClick={() => addToAdminCart()}
								>
									Concluir
								</div>
							</div>
						</div>
					</FarmlyModal>
				)}
				{showReview && (
					<FarmlyModal closeModal={() => setShowReview(false)}>
						<label id="request-title">Review this coffee!</label>
						{userContext.isAdmin && (
							<>
								<br />
								<br />
								<label id="request-title">
									{" "}
									
									<FarmlyInput
										value={reviewInfo.email}
										setValue={(value) =>
											setReviewInfo({ ...reviewInfo, email: value })
										}
										style={{ width: "10vw" }}
										elementId="email"
									/>
								</label>
							</>
						)}
						<Fragment>
							<div className="review-content-about">
								<div style={{ display: "flex"}} className="review-responsivity">
									<label id="request-text">
										How was your experience with this coffee?
									</label>
									<div class="rating">
										<input
											type="radio"
											id="star5"
											name="rating"
											defaultChecked={reviewInfo.score == 5}
											onClick={() => setReviewInfo({ ...reviewInfo, score: 5 })}
										/>
										<label class="full" for="star5"></label>
										<input
											type="radio"
											id="star4half"
											name="rating"
											defaultChecked={reviewInfo.score == 4.5}
											onClick={() =>
												setReviewInfo({ ...reviewInfo, score: 4.5 })
											}
										/>
										<label class="half" for="star4half"></label>
										<input
											type="radio"
											id="star4"
											name="rating"
											defaultChecked={reviewInfo.score == 4}
											onClick={() => setReviewInfo({ ...reviewInfo, score: 4 })}
										/>
										<label class="full" for="star4"></label>
										<input
											type="radio"
											id="star3half"
											name="rating"
											defaultChecked={reviewInfo.score == 3.5}
											onClick={() =>
												setReviewInfo({ ...reviewInfo, score: 3.5 })
											}
										/>
										<label class="half" for="star3half"></label>
										<input
											type="radio"
											id="star3"
											name="rating"
											defaultChecked={reviewInfo.score == 3}
											onClick={() => setReviewInfo({ ...reviewInfo, score: 3 })}
										/>
										<label class="full" for="star3"></label>
										<input
											type="radio"
											id="star2half"
											name="rating"
											defaultChecked={reviewInfo.score == 2.5}
											onClick={() =>
												setReviewInfo({ ...reviewInfo, score: 2.5 })
											}
										/>
										<label class="half" for="star2half"></label>
										<input
											type="radio"
											id="star2"
											name="rating"
											defaultChecked={reviewInfo.score == 2}
											onClick={() => setReviewInfo({ ...reviewInfo, score: 2 })}
										/>
										<label class="full" for="star2"></label>
										<input
											type="radio"
											id="star1half"
											name="rating"
											defaultChecked={reviewInfo.score == 1.5}
											onClick={() =>
												setReviewInfo({ ...reviewInfo, score: 1.5 })
											}
										/>
										<label class="half" for="star1half"></label>
										<input
											type="radio"
											id="star1"
											name="rating"
											defaultChecked={reviewInfo.score == 1}
											onClick={() => setReviewInfo({ ...reviewInfo, score: 1 })}
										/>
										<label class="full" for="star1"></label>
										<input
											type="radio"
											id="starhalf"
											name="rating"
											defaultChecked={reviewInfo.score == 0.5}
											onClick={() =>
												setReviewInfo({ ...reviewInfo, score: 0.5 })
											}
										/>
										<label class="half" for="starhalf"></label>
									</div>
								</div>
								<br />
								<div style={{ textAlign: "start" }}>
									<label id="request-text">Tell us more about it! </label>
									<label id="request-text"> Make some comments below:</label>
									<div>
										<FarmlyInput
											textArea
											value={reviewInfo.comment}
											setValue={(value) =>
												setReviewInfo({ ...reviewInfo, comment: value })
											}
											styleClass="coffee-profile-review-input about-paragraph"
											style={{ height: "10vw" }}
											elementId="name"
										/>
									</div>
									{!userContext.user &&
									<>	
										<label id="request-text">Do you want to send without logging in? Type your name</label>
										<div>
											<FarmlyInput
												value={reviewInfo.email}
												setValue={(value) =>
													setReviewInfo({ ...reviewInfo, roaster: value })
												}
												styleClass="coffee-profile-review-input-email about-paragraph"
												elementId="name"
											/>
										</div>
									</>
									}
								</div>
							</div>
							<div className="new-review-button-about">
								<div
									className="new-review-button"
									onClick={() => {
										userContext.user? createReview() : createReviewWithoutLogin();
									}}
								>
							 	Submit review
								</div> 
								{!userContext.user &&
								<div
									className="new-review-button"
									onClick={() => {
										createReview();
									}}
								>
								Login to submit review
								</div>}
							</div>
						
						</Fragment>
					</FarmlyModal>
				)}
		
				{showReviewDone && (
					<FarmlyModal closeModal={() => setShowReviewDone(false)}>
						<label id="request-title">This was your review</label>
						<Fragment>
							<div className="review-content">
								<div style={{ display: "flex", flexDirection: "row" }}>
									<label id="request-text">
										The score you gave for your experience with this coffee was:
									</label>
									<div class="rating-delete">
										<input type="radio" id="star5" name="rating-delete" />
										<label
											class="full"
											for="star5"
											style={
												reviewDone.score == 5
													? { color: "#FFD700" }
													: { color: "#ddd" }
											}
										></label>
										<input type="radio" id="star4half" name="rating-delete" />
										<label
											class="half"
											for="star4half"
											style={
												reviewDone.score >= 4.5
													? { color: "#FFD700" }
													: { color: "#ddd" }
											}
										></label>
										<input type="radio" id="star4" name="rating-delete" />
										<label
											class="full"
											for="star4"
											style={
												reviewDone.score >= 4
													? { color: "#FFD700" }
													: { color: "#ddd" }
											}
										></label>
										<input type="radio" id="star3half" name="rating-delete" />
										<label
											class="half"
											for="star3half"
											style={
												reviewDone.score >= 3.5
													? { color: "#FFD700" }
													: { color: "#ddd" }
											}
										></label>
										<input type="radio" id="star3" name="rating-delete" />
										<label
											class="full"
											for="star3"
											style={
												reviewDone.score >= 3
													? { color: "#FFD700" }
													: { color: "#ddd" }
											}
										></label>
										<input type="radio" id="star2half" name="rating-delete" />
										<label
											class="half"
											for="star2half"
											style={
												reviewDone.score >= 2.5
													? { color: "#FFD700" }
													: { color: "#ddd" }
											}
										></label>
										<input type="radio" id="star2" name="rating-delete" />
										<label
											class="full"
											for="star2"
											style={
												reviewDone.score >= 2
													? { color: "#FFD700" }
													: { color: "#ddd" }
											}
										></label>
										<input type="radio" id="star1half" name="rating-delete" />
										<label
											class="half"
											for="star1half"
											style={
												reviewDone.score >= 1.5
													? { color: "#FFD700" }
													: { color: "#ddd" }
											}
										></label>
										<input type="radio" id="star1" name="rating-delete" />
										<label
											class="full"
											for="star1"
											style={
												reviewDone.score >= 1
													? { color: "#FFD700" }
													: { color: "#ddd" }
											}
										></label>
										<input type="radio" id="starhalf" name="rating-delete" />
										<label
											class="half"
											for="starhalf"
											style={
												reviewDone.score >= 0.5
													? { color: "#FFD700" }
													: { color: "#ddd" }
											}
										></label>
									</div>
								</div>
								<br />
								<div style={{ textAlign: "start" }}>
									<label id="request-text">Comments: </label>
									<div
										className="producer-profile-row"
										style={{ width: "100%" }}
									>
										<label
											styleClass="coffee-profile-review-input about-paragraph"
											style={{ height: "10vw" }}
											elementId="name"
										>
											{reviewDone.comment}
										</label>
									</div>
								</div>
							</div>
							<div>
								<div
									className="new-review-button"
									style={{ backgroundColor: "var(--farmly-fuzzy)" }}
									onClick={() => {
										deleteReview();
									}}
								>
									Delete Review
								</div>
							</div>
						</Fragment>
					</FarmlyModal>
				)}

				<div className="about-title-container">
					<Title
						title={
							isPt()
								? farmlyTexts.menuAboutTitle_pt
								: isCo()
								? farmlyTexts.menuAboutTitle_es
								: farmlyTexts.menuAboutTitle_en
						}
						showIcon
						uppercase
						ml_20
					/>
					{((userContext.user && !userContext.user.isProd) ||
						!userContext.user) && (
						<FontAwesomeIcon
							className="favorite-icon"
							icon={faStar}
							style={
								isFavorite
									? { color: "#EE1", width: "30px", height: "30px" }
									: { width: "30px", height: "30px" }
							}
							onClick={userContext.user ? toggleFavorite : triggerRequest}
						/>
					)}
					{isOwner && props.coffeeId !== "new" && (
						<div
							className="about-delete-coffee-button"
							onClick={() => setConfirmDelete(true)}
						>
							{isPt()
								? farmlyTexts.coffeeDeleteButton_pt
								: isCo()
								? farmlyTexts.coffeeDeleteButton_es
								: farmlyTexts.coffeeDeleteButton_en}
						</div>
					)}
				</div>
				
				{producerData.farmImg || coffeeData.imageUrl ? (
					//O produtor tem imagem do seu café
					<div>
						<br />
						<label>{coffeeData.farmCode}</label>
						<div className="coffee-cover">
							<div className="coffee-description">
								{userContext.isAdmin && (
									<>
										<div className="description-language-selection">
											<label
												style={{
													cursor: "pointer",
													fontWeight: enDescription ? 400 : 600,
												}}
												onClick={() => setEnDescription(false)}
											>
												PT
											</label>
											<label
												style={{
													cursor: "pointer",
													fontWeight: enDescription ? 600 : 400,
												}}
												onClick={() => setEnDescription(true)}
											>
												EN
											</label>
										</div>
										{!coffeeData.description_checked && (
											<label>
												{isPt()
													? farmlyTexts.coffeeDescriptionChecked_pt
													: isCo()
													? farmlyTexts.coffeeDescriptionChecked_es
													: farmlyTexts.coffeeDescriptionChecked_en}
											</label>
										)}
									</>
								)}
								{isOwner ? (
									<FarmlyInputCoffee
										value={
											(userContext.isAdmin && enDescription
												? coffeeData.descriptionEn
												: coffeeData.description) || ""
										}
										textArea
										setShowUpdateButtons={setShowUpdateButtonsDescription}
										setValue={(value) => {
											let updateObj =
												userContext.isAdmin && enDescription
													? {
															...coffeeData,
															descriptionEn: value,
															description_checked: true,
													  }
													: { ...coffeeData, description: value };
											setCoffeeData(updateObj);
										}}
										styleClass="coffee-profile-paragraph-input about-paragraph"
										placeholder={
											isPt()
												? farmlyTexts.coffeeProfileParagraphAbout_pt
												: isCo()
												? farmlyTexts.coffeeProfileParagraphAbout_es
												: farmlyTexts.coffeeProfileParagraphAbout_en
										}
									/>
								) : (
									<p className="coffee-profile-paragraph">
										{isPt()
											? coffeeData.description
											: coffeeData.descriptionEn
											? coffeeData.descriptionEn
											: coffeeData.description}
									</p>
								)}
								
								{isOwner && showUpdateButtonsDescription && (
									<div className="edit-coffee-container">
										<div
											className="edit-coffee-button delete-coffee-button"
											onClick={cancelUpdateDescription}
										>
											{isPt()
												? farmlyTexts.cancelCoffeeButton_pt
												: isCo()
												? farmlyTexts.cancelCoffeeButton_es
												: farmlyTexts.cancelCoffeeButton_en}
										</div>
										<div className="edit-coffee-button" onClick={handleSave}>
											{isPt()
												? farmlyTexts.editCoffeeButton_pt
												: isCo()
												? farmlyTexts.editCoffeeButton_es
												: farmlyTexts.editCoffeeButton_en}
										</div>
									</div>
								)}
							</div>
							<div>
								{loading ? (
									<div className="loading-carousel">
										<div id="cover-image-coffee" style={{ display: "none" }} />
										<FarmlyLoading display={loading} />
									</div>
								) : (
									<CarouselSingle
										images={coffeeImageUrl}
										imagesFarm={farmImageUrl}
										coffeeData={coffeeData}
										sendDataToRemoveImage={sendDataToRemoveImage}
										isOwner={isOwner}
										setModalMessage={setModalMessage}
										toggleModal={toggleModal}
									></CarouselSingle>
								)}
								{isOwner && (
									<Dropzone
										onDrop={fileUpload}
										onDropRejected={onDropRejected}
										maxSize={2097152}
										accept="image/*"
										multiple
									>
										{({ getRootProps, getInputProps }) => (
											<section>
												<div
													className="dropzone"
													{...getRootProps()}
													style={{
														height: "10%",
														width: "92%",
														marginLeft: "2vw",
													}}
												>
													<input {...getInputProps()} accept="image/*" />
													<p>Adicionar mais Fotos</p>
												</div>
											</section>
										)}
									</Dropzone>
								)}
							</div>
						</div>
						<div className="coffee-info-title">
							<hr className="light-blue-separator" />
							{isOwner && (
								<Title
									title={
										isPt()
											? farmlyTexts.coffeeInfoContainer_pt
											: isCo()
											? farmlyTexts.coffeeInfoContainer_es
											: farmlyTexts.ccoffeeInfoContainer_en
									}
								/>
							)}
						</div>

						<div
							className={`coffee-info-container ${
								isOwner ? "editable-info-container" : ""
							}`}
						>
							{renderInfoItems()}
							{isOwner && renderCoffeeItem()}
						</div>
					</div>
				) : (
					//O produtor não colocou imagem do seu café

					<div style={{ display: "flex", flexDirection: "column" }}>
						<div className="coffee-cover-coffee">
							<div
								className="coffee-description"
								style={{
									width: "100%",
									paddingTop: "1vw",
									marginBottom: "2vw",
								}}
							>
								{userContext.isAdmin && (
									<>
										<div className="description-language-selection">
											<label
												style={{
													cursor: "pointer",
													fontWeight: enDescription ? 400 : 600,
												}}
												onClick={() => setEnDescription(false)}
											>
												PT
											</label>
											<label
												style={{
													cursor: "pointer",
													fontWeight: enDescription ? 600 : 400,
												}}
												onClick={() => setEnDescription(true)}
											>
												EN
											</label>
										</div>
										{!coffeeData.description_checked && (
											<label>
												{isPt()
													? farmlyTexts.coffeeDescriptionChecked_pt
													: isCo()
													? farmlyTexts.coffeeDescriptionChecked_es
													: farmlyTexts.coffeeDescriptionChecked_en}
											</label>
										)}
									</>
								)}
				
								{isOwner ? (
									<FarmlyInputCoffee
										value={
											(userContext.isAdmin && enDescription
												? coffeeData.descriptionEn
												: coffeeData.description) || ""
										}
										textArea
										setShowUpdateButtons={setShowUpdateButtonsDescription}
										setValue={(value) => {
											let updateObj =
												userContext.isAdmin && enDescription
													? {
															...coffeeData,
															descriptionEn: value,
															description_checked: true,
													  }
													: { ...coffeeData, description: value };
											setCoffeeData(updateObj);
										}}
										styleClass="coffee-profile-paragraph-input about-paragraph"
										placeholder={
											isPt()
												? farmlyTexts.coffeeProfileParagraphAbout_pt
												: isCo()
												? farmlyTexts.coffeeProfileParagraphAbout_es
												: farmlyTexts.coffeeProfileParagraphAbout_en
										}
									/>
								) : (
									<p className="coffee-profile-paragraph">
										{coffeeData.descriptionEn
											? coffeeData.descriptionEn
											: coffeeData.description}
									</p>
								)}
								{isOwner && showUpdateButtonsDescription && (
									<div className="edit-coffee-container">
										<div
											className="edit-coffee-button delete-coffee-button"
											onClick={cancelUpdateDescription}
										>
											{isPt()
												? farmlyTexts.cancelCoffeeButton_pt
												: isCo()
												? farmlyTexts.cancelCoffeeButton_es
												: farmlyTexts.cancelCoffeeButton_en}
										</div>
										<div className="edit-coffee-button" onClick={handleSave}>
											{isPt()
												? farmlyTexts.editCoffeeButton_pt
												: isCo()
												? farmlyTexts.editCoffeeButton_es
												: farmlyTexts.editCoffeeButton_en}
										</div>
									</div>
								)}
							</div>
							<div>
								{window.screen.width > 1000 ? (
									<div>
										{loading ? (
											<div className="loading-carousel">
												<FarmlyLoading display={loading} />
											</div>
										) : (
											<>
												{farmImageUrl.length > 0 ?
													<CarouselSingle
													images={coffeeImageUrl}
													imagesFarm={farmImageUrl}
													coffeeData={coffeeData}
													sendDataToRemoveImage={sendDataToRemoveImage}
													isOwner={isOwner}
													setModalMessage={setModalMessage}
													toggleModal={toggleModal}
												></CarouselSingle>
												:
													<div id="cover-image-coffee" />
												}
											</>
										)}
									</div>
								) : (
									<div id="cover-image-coffee" />
								)}
								{isOwner && (
									
									<Dropzone
										onDrop={fileUpload}
										onDropRejected={onDropRejected}
										maxSize={2097152}
										accept="image/*"
										multiple
									>
										{({ getRootProps, getInputProps }) => (
											<section>
												<div
													className="dropzone"
													style={{
														height: "50px",
														width: "100%",
														marginLeft: "15px",
														marginTop: 0,
														cursor: "pointer",
													}}
													{...getRootProps()}
												>
													<input {...getInputProps()} accept="image/*" />
													<p>Adicionar mais Fotos</p>
												</div>
											</section>
										)}
									</Dropzone>
								)}
							</div>
						</div>
						<hr className="light-blue-separator" />
						{isOwner && (
							<Title
								title={
									isPt()
										? farmlyTexts.coffeeInfoContainer_pt
										: isCo()
										? farmlyTexts.coffeeInfoContainer_es
										: farmlyTexts.ccoffeeInfoContainer_en
								}
							/>
						)}
						<div
							className={`coffee-info-container-coffee ${
								isOwner ? "editable-info-container" : ""
							}`}
						>
							{renderInfoItems()}
							{isOwner && renderCoffeeItem()}
						</div>
					</div>
				)}
				{/* {isOwner && props.coffeeId !== 'new' &&
                    <div className="coffee-about-row">
                        <div className={`coffee-profile-sample-status ${coffeeData.sampleSentDate !== 'not-sent' ? "coffee-profile-sample-sent" : ""}`}
                            onClick={prodSendSample} >
                            {coffeeData.sampleSentDate === 'not-sent' ?
                                "Enviar amostra" :
                                "Acompanhar envio da amostra"}
                        </div>
                    </div>
                } */}
				{!(
					userContext.user &&
					userContext.user.isProd &&
					!userContext.isAdmin
				) && (
					<div id="about-sample-container">
						<div id="about-sample-image-container">
							<img id="about-sample-image" src={varietyIcon} alt="" />
						</div>
						<div id="about-sample-score-container">
							{coffeeData.id == 166 ||
							coffeeData.id == 167 ||
							coffeeData.id == 168 ||
							coffeeData.farmCode == 319 ||
							coffeeData.farmCode == 320 ||
							coffeeData.farmCode == 321 ||
							coffeeData.farmCode == 322 ||
							coffeeData.farmCode == 323 ||
							coffeeData.farmCode == 324 ||
							coffeeData.farmCode == 450 ||
							coffeeData.farmCode == 451 ||
							coffeeData.farmCode == 452 ||
							coffeeData.farmCode == 453 ||
							coffeeData.farmCode == 454 ||
							coffeeData.farmCode == 455 ||
							coffeeData.farmCode == 456 ||
							coffeeData.farmCode == 500 ? (
								<Fragment></Fragment>
							) : (
								<Fragment>
									<label id="about-sample-score-label">
										{isPt()
											? farmlyTexts.coffeeScore_pt
											: isCo()
											? farmlyTexts.coffeeScore_es
											: farmlyTexts.coffeeScore_en}
									</label>
									<label id="about-sample-score">{coffeeData.score}</label>
								</Fragment>
							)}
						</div>
						<div id="about-sample-try-container">
							<label id="about-sample-try-label">
								{isPt()
									? farmlyTexts.coffeeTrySample_pt
									: farmlyTexts.coffeeTrySample_en}
							</label>
							<p id="about-sample-try-text">
								{isPt()
									? farmlyTexts.coffeeTrySampleText_pt
									: farmlyTexts.coffeeTrySampleText_en}{" "}
							</p>
							{!requestedAlready ? ( //!requestedAlready ?
							
								<div id="about-sample-try-button" onClick={()=>setIsRequestSamplePopupAtDisplay(true)}>
									{isPt()
										? farmlyTexts.coffeeSampleRequest_pt
										: farmlyTexts.coffeeSampleRequest_en}
								</div>
							) : (
								// <div id="about-sample-try-button-requested">{isPt() ? farmlyTexts.sampleRequested_pt : farmlyTexts.sampleRequested_en}</div>
								<div id="about-sample-try-button-requested">
									{isPt()
										? "Sem amostras restantes!"
										: "No more samples available!"}
								</div>
							)	}
							{isRequestSamplePopupAtDisplay && <RequestSample closeModal={() => setIsRequestSamplePopupAtDisplay(false)} samplesArray={[coffeeId]} />}
							{hasReview ? (
								<div
									id="about-sample-try-button"
									style={{ marginTop: "1vw" }}
									onClick={() => setShowReviewDone(true)}
								> 
									{isPt()
									? farmlyTexts.coffeeSeeReview_pt
									: farmlyTexts.coffeeSeeReview_en}
								</div>
							) : (
									<div
										id="about-sample-try-button"
										style={{ marginTop: "1vw" }}
										onClick={() => setShowReview(true)}
									>
										{isPt()
										? farmlyTexts.coffeeReview_pt
										: farmlyTexts.coffeeReview_en}
									</div>
							)}
						</div>
						<div className="vertical-divider" />
				
						<div id="about-request-container">
							<div id="about-price-container">
								{coffeeData.origin == "Brazil" ? (
									<>
										{isBr() ? (
											<label id="about-price">R$ {tEuroKgPrice}</label>
										) : (
											<label id="about-price">€ {tEuroKgPrice}</label>
										)}
									</>
								) : (
									<>
										{
											(isBr() || isCo()) ? (
												<label id="about-price">€ {tEuroKgPrice}</label> //R$
											) : (
												<label id="about-price">€ {tEuroKgPrice}</label>
											) /*priceIntEuro*/
										}
									</>
								)}
								<div
									className="coffee-price-info"
									onClick={() => setShowPricingInfo(true)}
								>
									i
								</div>
							</div>
							{coffeeData.origin == "Brazil" ? (
								<label style={{ fontWeight: 500 }}>
									{isPt() ? "Sacas (60kg cada)" : "Bags (60kg each)"}
								</label>
							) : (
								<label style={{ fontWeight: 500 }}>
									{isPt() ? "Sacas (70kg cada)" : "Bags (70kg each)"}
								</label>
							)}

							<div id="about-quantity-container">
								<div
									className="about-quantity-button"
									id="about-button-left"
									onClick={decreaseQuantity}
								>
									-
								</div>
								<input
									id="about-quantity"
									onChange={onQuantityChange}
									value={sackQuantity}
									onFocus={onQuantityFocus}
									onBlur={onQuantityBlur}
								/>
								<div
									className="about-quantity-button"
									id="about-button-right"
									onClick={increaseQuantity}
								>
									+
								</div>
								{isInCart || coffeeData.sackRemain == 0 ? (
									<div>
										{coffeeData.sackRemain == 0 ? ( //
											<div id="about-remove" className="about-cart-button">
												{isPt() ? "Esgotado" : isCo() ? "Agotado" : "Sold out"}
											</div>
										) : (
											// <Link to='/cart'>
											//     < div id="about-remove" className="about-cart-button">
											//         {isPt() ? farmlyTexts.coffeeGotoCart_pt : farmlyTexts.coffeeGotoCart_en}
											//     </div>
											// </Link>
											<div
												id="about-buy"
												className="about-cart-button"
												onClick={updateCoffeeOnCart}
											>
												{isPt()
													? "Atualizar Carrinho"
													: isCo()
													? "Carro de la actualización"
													: "Update Cart"}
											</div>
										)}
									</div>
								) : userContext.isAdmin ? ( //
									<div
										id="about-buy"
										className="about-cart-button"
										onClick={() => setCollectRefenceModal(true)}
									>
										{adminCart ? "Esgotado" : "Carrinho Adm"}
									</div> //
								) : (
									<div
										id="about-buy"
										className="about-cart-button"
										onClick={coffeeReservation}
									>
										{isPt()
											? farmlyTexts.sampleAddCart_pt
											: isCo()
											? farmlyTexts.sampleAddCart_es
											: farmlyTexts.sampleAddCart_en}
									</div>
								)}
							</div>
						</div>
					</div>
				)}
				<div className="about-title-container">
					<Title
						title="Reviews"
						showIcon
						uppercase
						ml_20
					/>
				</div>
				<div className="div-style-card-review">
			
				{arrayReviews.map((item) => {
			return (
			
					<RatingCard
						score={item.score}
						comment={item.comment}
						roaster={item.roaster}
						data={item.date}
					/>
				
			
			);
		})}
				</div>
				{isOwner && showUpdateButtons && (
					<div className="edit-coffee-container">
						<div
							className="edit-coffee-button delete-coffee-button"
							onClick={cancelUpdate}
						>
							{isPt()
								? farmlyTexts.cancelCoffeeButton_pt
								: isCo()
								? farmlyTexts.cancelCoffeeButton_es
								: farmlyTexts.cancelCoffeeButton_en}
						</div>
						<div className="edit-coffee-button" onClick={handleSave}>
							{isPt()
								? farmlyTexts.editCoffeeButton_pt
								: isCo()
								? farmlyTexts.editCoffeeButton_es
								: farmlyTexts.editCoffeeButton_en}
						</div>
					</div>
				)}
				{displayPaymentData && userContext.isAdmin && (
					<PaymentData
						coffeeId={coffeeId}
						close={() => setDisplayPaymentData(false)}
					/>
				)}
			</div>
			{successFavorite && (
				<FarmlyModal
					closeModal={() => {
						setmodalFavorite(false);
					}}
				>
					<label>
						{isPt()
							? "Café adicionado aos favoritos com sucesso !"
							: isCo()
							? "¡Café añadido con éxito a los favoritos!"
							: "Coffee successfully added to favorites !"}
					</label>
				</FarmlyModal>
			)}
			{successUnfavorite && (
				<FarmlyModal closeModal={() => setmodalUnfavorite(false)}>
					<label>
						{isPt()
							? "Café retirado dos favoritos com sucesso !"
							: isCo()
							? "¡Café eliminado con éxito de los favoritos!"
							: "Coffee successfully removed from favorites!"}
					</label>
				</FarmlyModal>
			)}
			{
				history.location.pathname !== "/coffee/new" ? (
					arrayReviews.length > 1 ? (
						<div>
							<div
								className="container about-container"
								style={{
									paddingRight: "0vw",
									marginLeft: "1vw",
									paddingLeft: "0vw",
								}}
							>
								<Slider id="slider-comentarios" {...settings}>
									{renderReviews()}
								</Slider>
							</div>
						</div>
					) : (
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "space-evenly",
							}}
						>
							{arrayReviews.map((item) => {
								return (
									<div id="slider-comentarios">
										{/* <RatingCard
											score={item.score}
											comment={item.comment}
											roaster={item.roaster}
											data={item.date}
										/> */}
										<br />
									</div>
								);
							})}
						</div>
					)
				) : (
					void 0
				) //do nothing
			}
			
		</>
	);
};

export default About;
