import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { server, acceptedCookies } from '../../common.js'
import UserContext from '../../context/user/UserContext.js'

import Main from '../templates/Main'
import Title from '../templates/Title'
import SideMenu from '../templates/SideMenu'

import ViewSample from './ViewSample.jsx'
import SelectCoffee from './SelectCoffee.jsx'
import RoasterPanel from './RoasterPanel.jsx'
import ProducerPanel from './ProducerPanel.jsx'
import MailList from './MailList.jsx'
import Payments from './Payments.jsx'
import Negociations from './Negociations.jsx'
import AllRequests from './AllRequests.jsx'
import SampleCart from '../../modules/SampleCart/SampleCartAdmin';

const ControlPanel = props => {

    const userContext = useContext(UserContext)
    const options = [
        'Carrinhos de Amostras',
        'Receber Amostras',
        'Selecionar Cafés',
        'Ver Torrefadores',
        'Ver Produtores',
        'Pagamentos',
        'Pedidos de Amostra'
    ]
    const [selectedOption, setSelectedOption] = useState(options[0])
    const [query, setQuery] = useState('')

    const [users, setUsers] = useState([])
    const [requests, setRequests] = useState()

    useEffect(() => {
        getUsersInit(0)
    }, [])

    const getUsersInit = async (atPage = 0) => {
        const queryString = `${server}/allroasters` + (query && `?search=${query}&`)
        const newUsers = []
        const cookies = acceptedCookies() ?
            document.cookie.split(';').filter(item => item.includes('jwt'))[0].split('jwt=')[1]
            : null

        await axios.post(queryString, { jwt: cookies })
            .then(res => {
                newUsers.push(...res.data)
            })
            .catch(_ => { })
        setUsers(newUsers)
        users.map(async (user) => {
            await axios.get(`${server}/allrequests`)
                .then(res => {
                    setRequests(res.data)
                })
        })
    }

    const AdminView = (selectedOption) => {
        switch (selectedOption) {
            default:
                return "Erro"
            case options[0]:
                return <SampleCart />
            case options[1]:
                return <ViewSample />
            case options[2]:
                return <SelectCoffee />
            case options[3]:
                return <RoasterPanel />
            case options[4]:
                return <ProducerPanel />
            case options[5]:
                return <Payments />
            case options[6]:
                return <AllRequests users={users} getUsersInit={getUsersInit} requests={requests} />
        }
    }

    return (
        userContext.isAdmin ?
            <Main >
                <Title title="Mestre dos Magos" />
                <SideMenu sideMenuOptions={options} selectedOption={selectedOption}
                    setSelectedOption={setSelectedOption} menuWidth='17vw' />
                {AdminView(selectedOption)}
            </Main>
            : <div>403 Unauthorized{console.log(userContext)}</div>
    )
}
export default ControlPanel;