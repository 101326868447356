import Cookie from 'js-cookie';
import React from 'react'

const OldHome = React.lazy(() => import('../../../../components/home/Home'))
const Home = React.lazy(() => import('../../../Home'))

const whichHome = () => {
  if(Cookie.get('home-ab') == 'home') return true
  else return false
}

const GetCookie = () => {
  if(Cookie.get('home-ab') === undefined) return false
  else return true
}

const decideHome = () => {
  return Math.random() < 0.5
}

export const HomeAB = GetCookie() ? (whichHome() ? Home : OldHome) : ((decideHome() ? Home : OldHome))
