import styled from "styled-components";

export const BrazilFlag = require("assets/brazil.png")
export const ColombiaFlag = require("assets/colombia.png")

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`
export const Flag = styled.img`
    width: 50px;
    height: 35px;
    border-radius: 5px;
`

export const Legend = styled.p`
    font-weight: 300;
    font-size: 12px;
    margin: 0;
    color: #fff;
`