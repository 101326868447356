import React from 'react'
import './CategoryPicker.css'
import ReactGA from 'react-ga'
import { isPt, farmlyTexts, isCo } from '../../common'
import { useEffect } from 'react'

const producerImage = require('../../assets/producerSelect.jpg')
const roasterImage = require('../../assets/roasterSelect.jpg')

const CategoryPicker = props => {

    const { next, prev, setIsProd, userData } = props
    useEffect(() => {
        window.scrollTo(0, 0)
        console.log(userData)
    }, [])

    //REPORTS ANALYTICS
    useEffect(() => {
        ReactGA.event({
            category: 'SignUp - Category picker',
            action: `Visited the Category Picker Page`
        })
    }, [])
    const clickProducerCategory = () =>{
        ReactGA.event({
            category: 'SignUp - Category picker',
            action: 'New user selected producer category'
        })
    }
    const clickRoasterCategory = () =>{
        ReactGA.event({
            category: 'SignUp - Category picker',
            action: 'New user selected roaster category'
        })
    }
    const clickGoBackButton = () =>{
        if(isPt()) {
            ReactGA.event({
                category: 'SignUp - Category picker',
                action: `New producer clicked Go Back button`
            })
        } else {
            ReactGA.event({
                category: 'SignUp - Category picker',
                action: `New roaster clicked Go Back button`
            })
        }
    }

    return (
        <div id="category-picker">
            <div className="signup-title-row">
                <div className="coffee-icon" />
                <label className="signup-title">{isPt() ? farmlyTexts.signupCategoryTitle_pt : (isCo() ? 'Usted es' : farmlyTexts.signupCategoryTitle_en)}</label>
            </div>
            <div id="picker-images" className="signup-row">
                <div className="picker-item" onClick={() => {
                    setIsProd(true)
                    next(true)
                    clickProducerCategory()
                }} >
                    <label className="category-name">{isPt() ? farmlyTexts.signupProducer_pt : (isCo() ? 'Productor' : farmlyTexts.signupProducer_en)}</label>
                    <div className="picker-image" id="producer" alt="producer" />
                </div>
                <label id='picker-or-label'>{isPt() ? farmlyTexts.signupOr_pt : (isCo() ? 'O' :farmlyTexts.signupOr_en)}</label>
                <div className="picker-item" onClick={() => {
                    setIsProd(false)
                    next(false)
                    clickRoasterCategory()
                }} >
                    <label className="category-name">{isPt() ? farmlyTexts.signupRoaster_pt : (isCo() ? 'Tostador' : farmlyTexts.signupRoaster_en)}</label>
                    <div className="picker-image" id="roaster" alt="roaster" />
                </div>
            </div>
            <div className="signup-row basic-bottom">
                <div className="picker-back-button" onClick={() => {
                    clickGoBackButton();
                    prev();                    
                }}>
                    {isPt() ? farmlyTexts.signupGoBack_pt : (isCo() ? 'Regresar' :farmlyTexts.signupGoBack_en)}
                </div>
            </div>
        </div>
    )
}

export default CategoryPicker