import axios from "axios";
import React from "react";
import { server } from '../../common'
import './CoffeeCuppingCard.css'
import { useHistory } from 'react-router-dom'
import ReactGA from 'react-ga'


const CuppingMasterCard = (props) => {

    const {cuppingData, coffeesVectorImg, coffeesVector} = props
    const selectColor = cuppingData.cupping_color
    const history = useHistory()
    
    const renderImages = () =>
    coffeesVectorImg.map((coffee, index) => {
        return (<img className="cupping-image-unique-cards" src={coffee.img} style={{width: `${100/coffeesVectorImg.length}%`, borderTopLeftRadius: index == 0 ? `20px` :`0px`, borderTopRightRadius: index == coffeesVectorImg.length-1 ? `20px`: `0px` }}></img>)
    })

    const renderImages3 = () => {
        return ( 
        <>
            <img className="cupping-image-unique-cards" src={coffeesVectorImg[0].img} style={{width: `${100/3}%`, borderTopLeftRadius: `20px`, borderTopRightRadius: `0px` }}></img>
            <img className="cupping-image-unique-cards" src={coffeesVectorImg[1].img} style={{width: `${100/3}%`, borderTopLeftRadius: `0px`, borderTopRightRadius: '0px'}}></img>
            <img className="cupping-image-unique-cards" src={coffeesVectorImg[2].img} style={{width: `${100/3}%`, borderTopLeftRadius: '0px', borderTopRightRadius:`20px`}}></img>
        </>)}

    const goToResults = () => {
        ReactGA.event({
            category: 'Cupping Select',
            action: 'User clicked ate cupping card to see the result'
        })
        history.push({
            pathname: '/cuppingResults',
            state: { cuppingCoffees: coffeesVector, cupping_id: cuppingData.cupping_id }
        })
        history.go()
    }

    return (
        <>
            <div className="cupping-img-and-color-cupping-card coffee-cupping-hover" onClick={() => goToResults()}>
                <div>
                    <div className="all-pictures-modal-cupping-select">
                        {coffeesVectorImg.length > 3 ? renderImages3() : renderImages()}
                    </div>
                    <div className="select-color-cupping-card-modal-cupping" style={selectColor == 1 ? {backgroundColor: '#b73952'} : selectColor == 2 ? {backgroundColor: '#f47a54'} : selectColor==3 ? {backgroundColor: '#bba726'} : selectColor==4 ? {backgroundColor: 'rgb(27, 98, 94)'} : selectColor == 5 ? {backgroundColor:'#6dd7f3'} : {backgroundColor:'rgb(27, 98, 94)'}}>
                        <div className="coffee-name-modal-cupping">{cuppingData.description}</div>
                        <div className="description-modal-cupping">
                            Coffees: {cuppingData.img.split(',').length > 4 ? `${cuppingData.img.split(',')[0]},${cuppingData.img.split(',')[1]},${cuppingData.img.split(',')[2]},${cuppingData.img.split(',')[3]}...` : cuppingData.img}
                        </div>
                        <div className="coffee-id-modal-cupping">
                            ID: {cuppingData.cupping_id}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default CuppingMasterCard
