import React, { Fragment, useContext, useEffect, useState } from 'react'
import "./CartScreenAdmin.css"

import Main from '../templates/Main.jsx'
import Cookies from 'js-cookie'
import UserContext from '../../context/user/UserContext'
import FarmlyInput from '../templates/FarmlyInput.jsx'
import FarmlyModal from '../templates/FarmlyModal.jsx'
import CoffeeItemAdmin from './CoffeeItemAdmin.jsx'
import { Link, useHistory } from 'react-router-dom'
import Countries from '../../assets/countries.json'
import Select from 'react-select';
import DataList from '../templates/Datalist.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

import axios from 'axios'
import { server, isPt, farmlyTexts, isCo } from '../../common.js'
import * as pricings from '../templates/pricing.js'

const CartScreenAdmin = props => {

    const userContext = useContext(UserContext)
    const history = useHistory()
    let coffeeTaxes = [{}]
    const [coffees, setCoffees] = useState([])
    const [coupon, setCoupon] = useState("")
    const [loginPrompt, setLoginPrompt] = useState(false)
    const [showInfo, setShowInfo] = useState(false)
    const [showInfoAddress, setShowInfoAddress] = useState(false)
    const [conclude, setConclude] = useState(false)
    const [roasterData, setRoasterData] = useState({})
    const [paymentData, setPaymentData] = useState({
        euro_value: 0,
        farmly_fee: 0,
        logistics_fee: 0,
        import_duties: 0,
        import_duties_adjustment: 0
    })
    const [coffeesTaxesPricing, setCoffeesTaxesPricing] = useState([])
    const [shippmentInfo, setShippmentInfo] = useState([])
    const [countriesInfo, setCountriesInfo] = useState([])
    const [deliveryOption, setDeliveryOption] = useState('deliver') // deliver || pick-up
    const [shippingCountry, setShippingCountry] = useState('')
    const [shippingFee, setShippingFee] = useState(0.0)
    const [countryUnlisted, setCountryUnlisted] = useState(false)
    const [cartItensList, setCartItensList] = useState({})
    const [delivery, setDelivery] = useState(true)
    const [error, setError] = useState(false)
    const [showCoffeeModalCouponName, setShowCoffeeModalCouponName] = useState(false)
    const [couponItems, setCouponItems] = useState([{
        item_id: 0,
        quantity: 0,
        euro_value: 0.00,
        item_value: 0.00,
        logistics_fee: 0.00,
        farmly_fee: 0.00,
        transport_fee: 0.00,
        sack_price: 0.00,
        name: ""
    }])
    const [query, setQuery] = useState()
    const [search, setSearch] = useState('')
    const [couponInfo, setCouponInfo] = useState({name: window.location.pathname.split('/')[2]})
    const [requestedCouponInfo, setRequestedCouponInfo] = useState({name: "", isSuccessfullyApplied: false})
    const getRoasterData = async () => {
        if (userContext.user) {
            if(userContext.user.id == 0) {
                await axios.get(`${server}/roaster/1`)
                    .then(res => {
                        setRoasterData(res.data)
                        setShowInfo(true)
                    })
                    .catch(err => console.log(err))
            } else {
                await axios.get(`${server}/roaster/${userContext.user.id}`)
                    .then(res => {
                        setRoasterData(res.data)
                        setShowInfo(true)
                    })
                    .catch(err => console.log(err))
            }
        } else {
            setLoginPrompt(true)
        }
    }

    const calculateCoffeeTaxes =  (coffees, shipments) => {
        if(shipments.length === 0){
            return []
        }
        let coffeeTaxes = [{
            id: 0,
            producerFee: 0.00,
            logistics_fee: 0.00,
            import_duties: 0.00,
            farmly_fee: 0.00,
            IRPJ_CSLL: 0.00
        }]
        coffees.forEach(coffee => {
            let shipment = shipments.filter(c => c.shipping_id == coffee.shipment)

			if(shipment === []){
				alert("ATENÇÃO, não há envio associado a este café")
				return
			}	
				let sack = parseFloat(coffee.sackPrice)
				let producersPriceKg = coffee.origin === "Brazil" ? parseFloat((sack /60).toFixed(2)) : parseFloat((sack /70).toFixed(2));
				let farmly_fee = 0, IRPJ_CSLL = 0, eurProdKg = 0, logistic_fee = 0
				if(shipment[0].farmly_fee === null) {
					shipment[0].farmly_fee = 20  
				} 
				if(coffee.origin === "Brazil") {
					if(shipment[0].price_br === null) {
						shipment[0].price_br = 5.09
					}
					eurProdKg =  pricings.simpleEuroProdKg(producersPriceKg, shipment[0].price_br)
					IRPJ_CSLL =  pricings.simpleIRPJ_CSLL(sack, shipment[0].price_br)
				} else  {
					if(shipment[0].price_co === null) {
						shipment[0].price_co = 2
					}	
							
				}
				let import_duties = coffee.origin === "Brazil" ?  pricings.simpleImportDuties(eurProdKg) :  pricings.simpleImportDuties(parseFloat(producersPriceKg)) ;
				if(coffee.origin === "Brazil") {
					if(shipment[0].logistics_br === null) {
						logistic_fee =  pricings.simpleLogisticsFee(coffee.origin)
					} else {
						logistic_fee = shipment[0].logistics_br
					}
				}  else {
					if(shipment[0].logistics_co === null) {
						logistic_fee =  pricings.simpleLogisticsFee(coffee.origin)
					} else {
						logistic_fee = shipment[0].logistics_co
					}
				}
				let armazenamento = shipment[0].storage;
				farmly_fee = coffee.origin === "Brazil" ? pricings.simpleFarmlyFee(eurProdKg, IRPJ_CSLL, shipment[0].farmly_fee) :  pricings.simpleFarmlyFee(producersPriceKg, IRPJ_CSLL, shipment[0].farmly_fee)  
				let euroPriceKg = coffee.origin === "Brazil" ? eurProdKg+IRPJ_CSLL+parseFloat(farmly_fee)+import_duties+logistic_fee+armazenamento : 
				producersPriceKg + parseFloat(farmly_fee) + import_duties + logistic_fee + armazenamento
				if(coffee.origin === "Colombia") {
					eurProdKg = parseFloat((sack /70).toFixed(2))
				} 
                if(coffeeTaxes[0].id === 0) {
                    coffeeTaxes[0].id = coffee.id
                    coffeeTaxes[0].logistics_fee = logistic_fee
                    coffeeTaxes[0].farmly_fee = parseFloat(farmly_fee)
                    coffeeTaxes[0].import_duties = import_duties
                    coffeeTaxes[0].producerFee =  parseFloat(euroPriceKg)
                    coffeeTaxes[0].IRPJ_CSLL = parseFloat(IRPJ_CSLL.toFixed(2))
                } else {
                    coffeeTaxes.push({
                        id: coffee.id,
                        logistics_fee: logistic_fee,
                        import_duties: import_duties,
                        farmly_fee: parseFloat(farmly_fee),
                        producerFee: parseFloat(euroPriceKg),
                        IRPJ_CSLL: parseFloat(IRPJ_CSLL.toFixed(2))
                    })    
                }   
            
				// setTIRPJ_CSLL(parseFloat(IRPJ_CSLL.toFixed(2)))
				// setTImportDuties(parseFloat(import_duties.toFixed(2)))
				// settLogistics_Fee(logisticFee)
				// settFarmly_fee(parseFloat(farmlyFee))
				// setTEuroKgPrice(parseFloat(euroPriceKg.toFixed(2)))
			
		
		
        })
        return coffeeTaxes
	}	

    // useEffect(() => {
    //     if(shippingCountry != null) {
    //         const sacks = totalSacks(coffees)
    //         const countryName = shippingCountry
    //         const countryLC = shippingCountry.toLowerCase()
    //         const countryFilter = countriesInfo.filter(country => {
    //             const nameLC = country.name.toLowerCase()
    //             return nameLC.includes(countryLC) || countryLC.includes(nameLC)
    //         })
    // if (countryFilter.length == 0) {
    //     setCountryUnlisted(true)
    //     setShippingFee(0)
    // } else {
    //     setCountryUnlisted(false)
    //     const country = countryFilter[0]
    //     const pallets = Math.ceil(totalSacks(coffees) / 15)
    //     if (pallets <= 5) {
    //         setShippingFee(country.palletPrices[pallets])
    //     } else {
    //         setShippingFee(country.palletPrices[5] + (country.palletPrices['extra'] * (pallets - 5)))
    //     }
    // }
    //     }

    // }, [shippingCountry])

  

    useEffect(() => {
        axios.get(`${server}/paymentData`)
            .then(data => {
                setPaymentData(data.data)
            })
        axios.get(`${server}/countries`)
            .then(async ({ data }) => {
                setCountriesInfo(data)
                const countryLC = shippingCountry.toLowerCase()
                const countryFilter = data.filter(country => {
                    const nameLC = country.name.toLowerCase()
                    return nameLC.includes(countryLC) || countryLC.includes(nameLC)
                })
                if (countryFilter.length == 0) {
                    // setCountryUnlisted(true)
                    if (window.location.pathname.split('/')[2] == `farmly11-10`) {
                        setShippingFee(182.51)
                        setShippingCountry('Czech Republic')
                    }
                    else {
                        setShippingFee(0)
                        setShippingCountry('FARMly')
                    }

                } else {
                    setCountryUnlisted(false)
                    if (window.location.pathname.split('/')[2] == `lot-zero`) setShippingFee(99.62)
                    else if (window.location.pathname.split('/')[2] == 'awaken0120') {
                        setShippingFee(139.46)
                    }
                    else if (window.location.pathname.split('/')[2] == 'MOTMOT0207') {
                        setShippingFee(0)
                    }
                    else {
                        const country = countryFilter[0]
                        const pallets = Math.ceil(totalSacks(coffees) / 10)
                        if (pallets <= 5) {
                            setShippingFee(country.palletPrices[pallets])
                        } else {
                            setShippingFee(country.palletPrices[5] + (country.palletPrices['extra'] * (pallets - 5)))
                        }
                    }
                }
            })
            if(coffees.length > 0 && shippmentInfo.length > 0) {
                const coffeeCalculatedTaxes = calculateCoffeeTaxes(coffees, shippmentInfo)
                setCoffeesTaxesPricing(coffeeCalculatedTaxes);
            }   


    }, [coffees, shippmentInfo])

    
    useEffect(() => {
        coffeeTaxes = coffeesTaxesPricing
        renderCoffes()
    }, [coffeesTaxesPricing])

    useEffect(() => {
        axios.get(`${server}/getAllCartItens/${window.location.pathname.split('/')[2]}`)
            .then(cartItens => {
                if (cartItens.data.length > 0) {
                    setCartItensList(cartItens)
                    setShippingCountry(cartItens.data[0].country)
                    const cartString = cartItens.data.reduce((prev, item) => `${item.coffeeId},${prev}`, "")
                    axios.get(`${server}/coffeesList/${cartString}`)
                        .then(async res => {
                            let coffees = res.data
                            cartItens.data.forEach(async item => {
                                coffees = coffees.map(coffee => {
                                    return coffee.id == item.coffeeId ?
                                        { ...coffee, quantity: parseInt(item.quantity) } : coffee
                                })
                            })
                                let shipments = []
                                 axios.get(`${server}/getShippingData`).then(res => {
                                    shipments = res.data;
                                    setShippmentInfo(shipments)
                                    // coffeeTaxes = calculateCoffeeTaxes(coffees, shipments)
                                    // setCoffeesTaxesPricing(coffeesTaxesPricing)
                                    // console.log("ss", coffeeTaxes)
                                }).catch((err) => {
                                    alert('An error occured')
                                    console.log(err)
                                })
                              
                            setCoffees(coffees)
                            
                        })
                } else setCoffees([])
            })
    }, [])



    const totalSacks = coffees => coffees
        .reduce((total, curCoffee) => total + curCoffee.quantity, 0)

    const totalProducersPrice = () => pricings.toEuro(pricings.totalProducersPrice(coffees, paymentData), paymentData, { euro_value: coffees[0].euro_value })
    const totalPrice = () => pricings.toEuro(pricings.totalPrice(coffees, paymentData), paymentData, { euro_value: coffees[0].euro_value }) + shippingFee
    const logisticsFee = coffee => coffee.origin == "Brazil" ? pricings.toEuro(pricings.logisticsFee(coffee, paymentData), paymentData, coffee) : pricings.logisticsFee(coffee, paymentData)
    const totalLogisticsFee = coffees => pricings.toEuro(pricings.totalLogisticsFee(coffees, paymentData), paymentData, { euro_value: coffees[0].euro_value })
    const importDuties = coffee => coffee.origin == "Brazil" ? pricings.toEuro(pricings.importDuties(coffee, paymentData), paymentData, coffee) : pricings.importDuties(coffee, paymentData)
    const totalImportDuties = coffees => pricings.toEuro(pricings.totalImportDuties(coffees, paymentData), paymentData, { euro_value: coffees[0].euro_value })
    const farmlyFee = coffee => coffee.origin == "Brazil" ? pricings.toEuro(pricings.farmlyFee(coffee, paymentData), paymentData, coffee) : pricings.farmlyFee(coffee, paymentData)
    const totalFarmlyFee = coffees => pricings.toEuro(pricings.totalFarmlyFee(coffees, paymentData), paymentData, { euro_value: coffees[0].euro_value })




    const setQuantity = (value, id) => {
        const updatedCoffees = coffees.map(el =>
            el.id === id ? { ...el, quantity: value } : el)
        setCoffees(updatedCoffees)
    }

    const setCouponItemData = (couponData) => {
        setCouponItems(couponData)
    }

    const renderCoffes = () => coffees.map(coffee => 
        {
            let irpj_csll = 0

            if(coffeesTaxesPricing.length > 0) {
                let currentCoffeePricing = coffeesTaxesPricing.filter(c => c.id === coffee.id);
                currentCoffeePricing = currentCoffeePricing[0]
                if(coffee.origin === "Brazil"){
                 irpj_csll = currentCoffeePricing.IRPJ_CSLL * coffee.quantity
                }
            }

     

    return(<CoffeeItemAdmin key={`cart-item-${coffee.id}`} // PARA VOLTAR O PRECO AO NORMAL: price={toEuro(coffee.sackPrice)} producerFee={toEuro(coffee.sackPrice) * coffee.quantity} 
        id={coffee.id} producer={coffee.producer} brazil={coffee.origin == "Brazil" ? true : false} coffee={coffee} couponItem={couponItems} setCouponItemData={setCouponItemData}
        price={coffee.origin == "Brazil" ? pricings.toEuro(coffee.sackPrice, paymentData, coffee) : parseFloat(coffee.sackPrice)} setQuantity={setQuantity} sacksLeft={coffee.sackRemain}
        quantity={coffee.quantity} producerFee={coffee.origin == "Brazil" ? pricings.toEuro(coffee.sackPrice, paymentData, coffee) * coffee.quantity : coffee.sackPrice * coffee.quantity}
        paymentData={{
            farmly_fee: farmlyFee(coffee),
            // farmly_fee: farmlyFee(coffee),
            // logistics_fee: farmlyFee(coffee)
            logistics_fee:  logisticsFee(coffee),
            import_duties: coffee.origin === "Brazil"  && coffeesTaxesPricing.length > 0 ? importDuties(coffee) + parseFloat(irpj_csll.toFixed(2)) : importDuties(coffee),
        }} /> )})
    

    const renderEmpty = () => <div className="empty-cart" >
        {isPt() ? farmlyTexts.cartEmpty_pt : farmlyTexts.cartEmpty_en}
    </div>

    const updateRoasterPayment = async () => {
        var aux = []
        cartItensList.data.map((item) => {
            aux.push(`${item.coffeeId}:${item.quantity}`)
        })
        if (delivery) await setRoasterData({ ...roasterData, delivery_address: roasterData.address, delivery_postal_code: roasterData.postalCode })

        await axios.post(`${server}/updatePaymentInfo/${roasterData.id}`, roasterData) //NA SEGUNDA VEZ, NAO TA MEXENDO NO ENDERECO DE ENTREGA!!!
            .then(res => {
                proceedToCheckout(aux)
            })
    }

    const applyDiscount = async (coupon) => {
        try {
            if(coupon) {
                const data = await (await axios.get(`${server}/getAdminCartWithDiscount/${coupon}`)).data
                let isValidCoupon = false
                let coffeeIndex = [], notFoundElements = []
                coffees.map((c, i) => {
                    isValidCoupon = data.findIndex(coupon => coupon.item_id === c.id) > -1
                    if(isValidCoupon) {
                        coffeeIndex.push(i)
                    } else {
                        notFoundElements.push(i)
                    }
                })
                
                if(coffeeIndex.length === 0){
                    alert(isPt() ? farmlyTexts.cartInvalidCoupon_pt : isCo() ?  farmlyTexts.cartInvalidCoupon_es : farmlyTexts.cartInvalidCoupon_en )
                } else {
                    setRequestedCouponInfo({name: coupon, isSuccessfullyApplied: true})
                    let producer = 0.00, logisticFee = 0.00, importDuties = 0.00, farmlyFee = 0.00, shippingDiscount = 0.00, 
                    shippingPrice = parseFloat(document.querySelector('#shippingFee').innerHTML.substring(1)), shippingText = document.querySelector('#shipping').innerHTML.split('€')
                    
                    coffeeIndex.map(c=> {
                        let currentCoffee = [coffees[c]]
                        let currentCoupon = data.filter(cc => cc.item_id === currentCoffee[0].id)
                        producer += pricings.toEuroWithDiscount(currentCoupon[0].sack_price, {euro_price: currentCoupon[0].euro_value})
                        logisticFee += pricings.totalLogisticsFeeWithDiscount(currentCoffee, {logistics: currentCoupon[0].logistics_fee, euro_price: currentCoupon[0].euro_value})
                        importDuties += pricings.totalImportDutiesWithDiscount(currentCoffee, {sack_price: currentCoupon[0].sack_price, euro_price: currentCoupon[0].euro_value})
                        farmlyFee +=  pricings.totalFarmlyFeeWithDiscount(currentCoffee, {sack_price: currentCoupon[0].sack_price, euro_price: currentCoupon[0].euro_value, farmly_fee: currentCoupon[0].farmly_fee })
                        shippingDiscount += shippingPrice * (currentCoupon[0].transport_fee / 100 )

                    })

                    if(notFoundElements.length > 0){
                        notFoundElements.map(element => {
                            let currentCoffee = [coffees[element]]
                            producer += pricings.toEuro(parseFloat(currentCoffee[0].sackPrice), paymentData, currentCoffee[0])
                            logisticFee += pricings.totalLogisticsFee(currentCoffee, paymentData)
                            importDuties += pricings.totalImportDuties(currentCoffee, paymentData)
                            farmlyFee +=  pricings.totalFarmlyFee(currentCoffee, paymentData)
                        })
                      
                    }
                    shippingPrice -= shippingDiscount
                    document.querySelector('#producerPrice').innerHTML = `€${producer.toFixed(2)}`;
                    document.querySelector('#logisticsFeePrice').innerHTML = `€${logisticFee.toFixed(2)}`;
                    document.querySelector('#importDutiesPrice').innerHTML = `€${importDuties.toFixed(2)}`;
                    document.querySelector('#farmlyFeePrice').innerHTML = `€${farmlyFee.toFixed(2)}`;
                    document.querySelector('#shippingFee').innerHTML = `€${shippingPrice.toFixed(2)}`;
                    document.querySelector('#shipping').innerHTML = `${shippingText[0]} €${shippingPrice.toFixed(2)}`;
                    document.querySelector('#totalsPrice').innerHTML = `€${parseFloat(producer + logisticFee + importDuties + farmlyFee + shippingPrice).toFixed(2)}`
                    
                    alert(isPt() ? farmlyTexts.cartValidCoupon_pt : isCo() ?  farmlyTexts.cartValidCoupon_es : farmlyTexts.cartValidCoupon_en )


                }
            }   
        } catch (error) {
            
        }
        
    }

    const proceedToCheckout = (coffeesList) => {

        const paymentOptions = {
            amount: totalPrice().toFixed(2),
            description: 'FARMly',
            roasterId: userContext.user.id,
            coffees: coffees,
            roasterData: roasterData,
            shippingCountry: shippingCountry,
            producersPrice: totalProducersPrice().toFixed(2),
            logisticsFee: totalLogisticsFee(coffees).toFixed(2),
            importDuties: totalImportDuties(coffees).toFixed(2),
            farmlyFee: totalFarmlyFee(coffees).toFixed(2),
            shippingFee: shippingFee.toFixed(2),
            server: server,
            ref: window.location.pathname.split('/')[2]
        }

        // if (coffees.reduce((acc, coffee) => acc && coffee.sackRemain > 0, true) && totalProducersPrice() > 0) {
        axios.post(`${server}/payments/`, paymentOptions)
            .then(res => {
                setShowInfo(false)
                history.push({
                    pathname: '/thanksForBuying',
                    state: { payment: paymentOptions }
                })
                history.go()
            })
    }
    // .then(data => {
    //     setPaymentData(data.data)




    // const renderCoffes = () => coffees.map(coffee => <CoffeeItemAdmin key={`cart-item-${coffee.id}`} // PARA VOLTAR O PRECO AO NORMAL: price={toEuro(coffee.sackPrice)} producerFee={toEuro(coffee.sackPrice) * coffee.quantity} 

    const createCoupon = async (couponItems) => {
        try {
            const isCouponItemCreated = couponItems[0].item_id > 0
            const couponName = couponInfo.name;
            let i = 0
            if(isCouponItemCreated && couponName){
            for(let data of couponItems){
                if(data.name === ""){
                    couponItems[i].name = couponName
                }
                i++
            }
            setCouponItems(couponItems)
            const reference = window.location.pathname.split('/')[2];
            const coupon = await axios.post(`${server}/createRegularCartItem/${reference}`, couponItems)
            if(coupon.status === 201){
                alert("Cupom criado com sucesso!")
            }    
        } else {
            if(!isCouponItemCreated){
                alert('É necessário a criação de pelo menos um item no cupom.')
                setShowCoffeeModalCouponName(false)
            }
            if(!couponName){
                document.querySelector('#name').focus = true;
                alert('Forneça o nome do cupom.')
            }
        }
        } catch (error) {
            alert('Ocorreu um erro')
            console.log(error)
        }
        
    }
    // useEffect(() => {
    //     const getCouponData = async () => {
    //         setError(false)

    //         try {

    //             const result = await axios.get(`${server}/coupon/?name=${query}`)
    //             setCoupon(result.data)
    //                 console.log( setCoupon(result.data))
    //         } catch (error) {
    //             setError(true)
    //         }

    //     }
    //     getCouponData()
    // }, [query]);







    const renderDeliveryOptions = option => (option === 'deliver' ? (
        <div className="shipping-fees">
            <div className="shipping-fee-field">
                <label htmlFor="shipping-country" className="shipping-fee-label">Country</label>
                {/* <input type="text" className="shipping-fee-input" id="shipping-country"
                    placeholder="Country" onChange={e => setShippingCountry(e.target.value)}
                    value={shippingCountry} list="country-list" /> */}
                <input
                    id="shipping-country"
                    placeholder="Country..."
                    value={shippingCountry}
                    style={{ fontSize: 'medium' }}
                // onChange={e => setShippingCountry(e.value)}
                // options={renderOpts()}
                >
                </input>
                <label className="shipping-fee-value">{countryUnlisted ?
                    <div>
                        <label>
                            We cannot calculate the shipping fees to your country automatically at the moment. Please get in touch so that we can help you figure it out!
                        </label>
                        <br />
                        <br />
                        <FontAwesomeIcon className="whatsapp-icon-cart" icon={faWhatsapp} />
                        <label> Click </label>
                        <a target="_blank" rel="noopener noreferrer" href={`https://api.whatsapp.com/send?phone=+5527999802545`}>
                            <label>here</label>
                        </a>
                        <label> to send us a whatsapp message!</label>
                        <br />
                        <br />
                        <FontAwesomeIcon className='footer-contacts-icon' icon={faEnvelope} />
                        <label> Click </label>
                        <a target="_blank" rel="noopener noreferrer" href={`https://api.whatsapp.com/send?phone=+5527999802545`}>
                            <label>here</label>
                        </a>
                        <label> to send us an email!</label>

                    </div>
                    : <div id="shipping">{isPt() ? 'Taxa de entrega: ' : 'Shipping fee:'}€{shippingFee}</div>

                }</label>
                {/* <div className="update-shipping" onClick={() => updateShippingFee(shippingCountry, totalSacks(coffees))}>
                        Update
                    </div> */}
            </div>
        </div>
    ) : (
        <div className="pick-up">
            <div className="pick-up-text">
                {isPt() ? 'Você pode retirar o seu café conosco no Vollers, nossos parceiros em Rotterdam. ' : 'You can pick up your coffee with us at Vollers, our partners in Rotterdam.'}

                <br />
                <br />
                {isPt() ? 'Se você escolher essa opção, não terá nenhum custo adicional na taxa de entrega.</br> Nosso endereço é: ' : 'If you choose this option, there are no aditional shipping fees. Their address is:'}

            </div>
            <div className="vollers-address">
                Vollers Holland B.V.
                <br />
                Bunschotenweg 141,
                <br />
                3089KB Rotterdam
            </div>
        </div>
    ))

    return (
        <>
            {loginPrompt && <FarmlyModal closeModal={() => setLoginPrompt(false)}>
                <label id="request-title">
                    Checkout
                </label>
                <div className="request-content">
                    <label id="request-text">{isPt() ? farmlyTexts.cartLogin_pt : farmlyTexts.cartLogin_en}</label>
                    <Link id='request-submit' style={{ textDecoration: "none" }} to={{ pathname: '/login', state: window.location.pathname }}>
                        Login
                    </Link>
                </div>
            </FarmlyModal>}

            {showCoffeeModalCouponName && <FarmlyModal closeModal={() => setShowCoffeeModalCouponName(false)}>
                <label id="request-title">
                    Forneça o nome do Cupom
                </label>
                    <FarmlyInput value={couponInfo.name} setValue={value => setCouponInfo({ ...couponInfo, name: value })}
                        className="pricing-info-value" elementId="name" type="text" placeholder="Nome do Cupom" />
                         <div className="coupon-confirm">
                            <div className="new-coupon-button" style={window.screen.width < 330 ? { marginLeft: '-120px' } : { display: 'auto' }} onClick={() => createCoupon(couponItems)}>Criar Cupom</div>
                        </div>
            </FarmlyModal>
            }


            {showInfo &&
                <FarmlyModal closeModal={() => setShowInfo(false)}>
                    <div className="confirm-info-container">
                        <label className="pricing-info-title">Confirm info to negociate the payment</label>
                        <div className="pricing-info-item">
                            <label className="pricing-info-label">Name:</label>
                            <FarmlyInput value={roasterData.payment_name} setValue={value => setRoasterData({ ...roasterData, payment_name: value })}
                                className="pricing-info-value" elementId="name" placeholder="name..." />
                        </div>
                        <div className="pricing-info-item">
                            <label className="pricing-info-label">Roastery:</label>
                            <FarmlyInput value={roasterData.roastery} setValue={value => setRoasterData({ ...roasterData, roastery: value })}
                                className="pricing-info-value" elementId="name" placeholder="roastery..." />
                        </div>
                        <div className="pricing-info-item">
                            <label className="pricing-info-label">VAT:</label>
                            <FarmlyInput value={roasterData.vat} setValue={value => setRoasterData({ ...roasterData, vat: value })}
                                className="pricing-info-value" elementId="vat" placeholder="VAT..." />
                        </div>
                        <div className="pricing-info-item">
                            <label className="pricing-info-label">Email:</label>
                            <FarmlyInput value={roasterData.email} setValue={value => setRoasterData({ ...roasterData, email: value })}
                                className="pricing-info-value" elementId="email" placeholder="email..." />
                        </div>
                        <br />
                        <div className="checkout-buttons">
                            <div className="cart-checkout-button" onClick={() => { setShowInfo(false); setShowInfoAddress(true) }}>Next</div>
                        </div>
                    </div>
                </FarmlyModal>}

            {showInfoAddress &&
                <FarmlyModal closeModal={() => setShowInfoAddress(false)}>
                    <div className="confirm-info-container">
                        <label className="pricing-info-title">Confirm billing information</label>
                        <div className="pricing-info-item">
                            <label className="pricing-info-label">Billing Address:</label>
                            <FarmlyInput value={roasterData.address} setValue={value => setRoasterData({ ...roasterData, address: value })}
                                className="pricing-info-value-adm" elementId="name" placeholder="address..." />
                        </div>
                        <div className="pricing-info-item">
                            <label className="pricing-info-label">Postal Code:</label>
                            <FarmlyInput value={roasterData.postalCode} setValue={value => setRoasterData({ ...roasterData, postalCode: value })}
                                className="pricing-info-value-adm" elementId="name" placeholder="postal code..." />
                        </div>
                        <br />
                        <div className="profile-checkbox-item"
                            onClick={() => { setDelivery(!delivery) }}>
                            <div className="profile-checkbox"
                                style={{ backgroundColor: delivery ? 'var(--farmly-blueStone)' : 'white' }} />
                            <label className="profile-checkbox-label-adm-cart">Deliver to billing address</label>
                        </div>
                        {!delivery && <>
                            <div className="pricing-info-item">
                                <label className="pricing-info-label">Delivery Address:</label>
                                <FarmlyInput value={roasterData.delivery_address} setValue={value => setRoasterData({ ...roasterData, delivery_address: value })}
                                    className="pricing-info-value-adm" elementId="vat" placeholder="address..." />
                            </div>
                            <div className="pricing-info-item">
                                <label className="pricing-info-label">Postal Code:</label>
                                <FarmlyInput value={roasterData.delivery_postal_code} setValue={value => setRoasterData({ ...roasterData, delivery_postal_code: value })}
                                    className="pricing-info-value-adm" elementId="email" placeholder="postal code..." />
                            </div>
                        </>}
                        <br />
                        <div className="checkout-buttons">
                            <div className="cart-checkout-button" style={window.screen.width < 330 ? { marginLeft: '-120px' } : { display: 'auto' }} onClick={() => updateRoasterPayment()}>Checkout</div>
                        </div>
                    </div>
                </FarmlyModal>}

            {conclude && <FarmlyModal closeModal={() => setConclude(false)}>
                <label id="request-title">
                    Thank you for your purchase!!!
                </label>
                <div className="request-content">
                    <label id="request-text">Check your email box! We will be in touch to negociate the payment method! Remember to check the Span box either!</label>

                </div>
            </FarmlyModal>}

            <div className="cart-screen">
                <div className="cart-items-table">
                    <div className="cart-top-row">
                        <label className="cart-column-title"></label>
                        <label className="cart-column-title">{isPt() ? farmlyTexts.cartProduct_pt : farmlyTexts.cartProduct_en}</label>
                        <label className="cart-column-title">{isPt() ? farmlyTexts.cartPrice_pt : farmlyTexts.cartPrice_en}</label>
                        <label className="cart-column-title">{isPt() ? "Sacas" : "Bags"}</label>
                        <label className="cart-column-title">{isPt() ? farmlyTexts.cartProducer_pt : farmlyTexts.cartProducer_en}</label>

                    </div>
                    {coffees.length > 0 && shippmentInfo.length > 0 && coffeeTaxes.length > 0 ?
                        renderCoffes() :
                        renderEmpty()
                    }
                    <div className="cart-bottom-row">

                        <FarmlyInput placeholder={isPt() ? farmlyTexts.cartCouponCode_pt : farmlyTexts.cartCouponCode_en}
                            styleClass="cart-coupon-input" value={query} setValue={value => setQuery(value)} />


                        <button type="button" className="cart-apply-coupon" onClick={() => applyDiscount(query)}>{isPt() ? farmlyTexts.cartApplyCoupon_pt : farmlyTexts.cartApplyCoupon_en}</button>
                       {userContext.user && userContext.user.id == 0 && <button type="button" className="cart-create-coupon" onClick={() => setShowCoffeeModalCouponName(true)}>{isPt() ? farmlyTexts.cartCreateCoupon_pt : farmlyTexts.cartCreateCoupon_en}</button>}
                        {/*Botão de update no carrinho, nao sei direito pra que serve, acho que ainda pra nada*/}
                        {/* <div className="cart-update-button" onClick={updateCart}>{isPt() ? farmlyTexts.cartUpdateCart_pt : farmlyTexts.cartUpdateCart_en}</div> */}

                        {error && <div> Algo deu errado ao tentar carregar o cupom </div>}

                    </div>
                </div>
                <div className="delivery-table">
                    <div className="delivery-table-top-row">
                        <label className="cart-column-title">{isPt() ? 'Entrega' : 'Shipping'}</label>
                    </div>
                    <div className="delivery-options">
                        <div className="delivery-option delivery-option-left"
                            onClick={() => setDeliveryOption('deliver')}
                            style={deliveryOption == 'deliver' ? { fontWeight: 600 } : {}}>
                            {isPt() ? 'Entrega' : 'Deliver'}
                        </div>
                        <div className="delivery-option delivery-option-right"
                            onClick={() => setDeliveryOption('pick-up')}
                            style={deliveryOption == 'pick-up' ? { fontWeight: 600 } : {}}>
                            {isPt() ? 'Retirada' : 'Pick up'}
                        </div>
                    </div>
                    <div className="delivery-details">
                        {renderDeliveryOptions(deliveryOption)}
                    </div>
                </div>
                <div className="cart-totals">
                    <div className="cart-totals-top-row">
                        <label className="cart-column-title">{isPt() ? farmlyTexts.cartTotals_pt : farmlyTexts.cartTotals_en}</label>
                    </div>
                    <div className="cart-totals-table">
                        <label className="cart-totals-label">{isPt() ? farmlyTexts.cartProducer_pt : farmlyTexts.cartProducer_en}</label>
                        <label className="cart-totals-value" id="producerPrice">
                            {coffees.length === 0 ?
                                "€0.00" :
                                `€${totalProducersPrice().toFixed(2)}`
                            }
                        </label>
                        <label className="cart-totals-label">{isPt() ? farmlyTexts.cartShipping_pt : "Logistics"}</label>
                        <label className="cart-totals-value" id="logisticsFeePrice">
                            {coffees.length === 0 ?
                                "€0.00" :
                                `€${totalLogisticsFee(coffees).toFixed(2)}`
                            }
                        </label>
                        <label className="cart-totals-label">{isPt() ? farmlyTexts.cartImport_pt : farmlyTexts.cartImport_en}</label>
                        <label className="cart-totals-value" id="importDutiesPrice">
                            {coffees.length === 0 ?
                                "€0.00" :
                                `€${totalImportDuties(coffees).toFixed(2)}`
                            }
                        </label>
                        <label className="cart-totals-label">{isPt() ? farmlyTexts.cartFarmlyFee_pt : farmlyTexts.cartFarmlyFee_en}</label>
                        <label className="cart-totals-value" id="farmlyFeePrice">
                            {coffees.length === 0 ?
                                "€0.00" :
                                `€${totalFarmlyFee(coffees).toFixed(2)}`
                            }
                        </label>
                        <label className="cart-totals-label">{isPt() ? "Entrega" : "Shipping"}</label>
                        <label className="cart-totals-value" id='shippingFee'>{countryUnlisted ?
                            "Not Included"
                            : `€${shippingFee}`
                        }</label>
                        {window.location.pathname.split('/')[2] == 'theminersprime' &&
                            <>
                                <label className="cart-totals-label">Prime Subscription</label>
                                <label className="cart-totals-value">€197.00</label>
                            </>
                        }
                        {(window.location.pathname.split('/')[2] == 'theminersprime' || window.location.pathname.split('/')[2] == 'farmly11-10') &&
                            <>
                                <label className="cart-totals-label">Discount</label>
                                {window.location.pathname.split('/')[2] == 'theminersprime' ?
                                    <label className="cart-totals-value">-€768.60</label>
                                    :
                                    <label className="cart-totals-value">-€329.40</label>
                                }
                            </>
                        }
                        <label className="cart-totals-label">{isPt() ? farmlyTexts.cartTotal_pt : farmlyTexts.cartTotal_en}</label>
                        {(window.location.pathname.split('/')[2] == 'theminersprime' || window.location.pathname.split('/')[2] == 'farmly11-10') ?

                            window.location.pathname.split('/')[2] == 'theminersprime' ?
                                <label className="cart-totals-value">
                                    {coffees.length === 0 ?
                                        "€0.00" :
                                        `€${((totalPrice().toFixed(2)) - 768.60 + 197.00).toFixed(2)}`
                                    }
                                </label>
                                :
                                <label className="cart-totals-value">
                                    {coffees.length === 0 ?
                                        "€0.00" :
                                        `€${(totalPrice().toFixed(2) - 329.40).toFixed(2)}`
                                    }
                                </label>
                            :
                            <label className="cart-totals-value" id="totalsPrice">
                                {coffees.length === 0 ?
                                    "€0.00" :
                                    `€${totalPrice().toFixed(2)}`
                                }
                            </label>
                        }
                        {
                            coupon.length > 0 && <label className="cart-totals-label"> Total with discount</label>

                        }
                        <label className="cart-totals-value">{

                            coupon &&
                            coupon.map(item => <label>€{(totalPrice() - ((item.value) * totalPrice())).toFixed(2)}</label>)} </label>

                    </div>
                    <button className={`cart-totals-checkout-button ${coffees.length === 0 ? "totals-checkout-disabled" : ""}`}
                        onClick={coffees.length > 0 ? getRoasterData : () => { }}>
                        {coffees.length === 0 ?
                            isPt() ? farmlyTexts.cartEmptyButton_pt : farmlyTexts.cartEmptyButton_en
                            : isPt() ? farmlyTexts.cartCheckout_pt : farmlyTexts.cartCheckout_en}
                    </button>
                    <label className="total-observation" style={{ fontSize: '1em', marginTop: '5px' }}>
                        * Exc VAT.
                    </label>

                </div>
            </div>
        </>
    )
}

const CartPageAdmin = () => {




    return (

        <Main>
            <CartScreenAdmin />

        </Main>
    )
}

export default CartPageAdmin