import React, { useState, useEffect } from 'react'
import Main from '../templates/Main.jsx'
import { Link } from 'react-router-dom'
import ReactGA from 'react-ga'
import { server, isPt, farmlyTexts } from '../../common.js'
import './ThanksForYourPurchase.css'

const ThanksForYourPurchase = props => {
    const payment = props.location.state ? props.location.state.payment : null
    const analyticsInfo = () => {
            ReactGA.event({
                category: 'User clicked "go to profile"',
                action: 'User sign up and clicked "go to profile" at "thank you" page'
            })
    }
    console.log(payment);

    function redirectToPDF(){
        let today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0');
        const yyyy = today.getFullYear();
        today = dd + '-' + mm + '-' + yyyy;
        window.open(`${payment.server}/downloadPDF/${payment.roasterId}/${payment.ref}/INV_601_${today}?lgstcsf=${payment.logisticsFee}&shppngf=${payment.shippingFee}&shppngcntr=${payment.shippingCountry}`) //${payment.id}
    }

    return (
        <Main>
            <div id="signup-thank-you">
                <label id="thank-you-label">
                    Thanks for your Purchase!
                </label>
                <label id="thank-you-sublabel" style={{textAlign: 'center'}}>Check your email box! We will send the order invoice and be in touch to negociate the payment method! Remember to check the span box either!</label>
                <br/>
                <label id="thank-you-sublabel" style={{textAlign: 'center'}}>Your order information:</label>
                <div className="cart-totals-table">
                        <label className="cart-totals-label">{isPt() ? farmlyTexts.cartProducer_pt : farmlyTexts.cartProducer_en}</label>
                        <label className="cart-totals-value">€{payment.producersPrice}</label>
                        <label className="cart-totals-label">{isPt() ? farmlyTexts.cartShipping_pt : "Logistics"}</label>
                        <label className="cart-totals-value">€{payment.logisticsFee}</label>
                        <label className="cart-totals-label">{isPt() ? farmlyTexts.cartImport_pt : farmlyTexts.cartImport_en}</label>
                        <label className="cart-totals-value">€{payment.importDuties}</label>
                        <label className="cart-totals-label">{isPt() ? farmlyTexts.cartFarmlyFee_pt : farmlyTexts.cartFarmlyFee_en}</label>
                        <label className="cart-totals-value">€{payment.farmlyFee}</label>
                        <label className="cart-totals-label">{isPt() ? "Entrega" : "Shipping"}</label>
                        <label className="cart-totals-value">€{payment.shippingFee}</label>
                        <label className="cart-totals-label" style={{height: '35px'}}>Total</label>
                        <label className="cart-totals-value">€{payment.amount}</label>
                    </div>
                <label id="thank-you-sublabel" style={{textAlign: 'center'}}>If you have any questions, please contact us at: icouto@farmlyclub.com!</label>
            </div>
        </Main>
    )
}

export default ThanksForYourPurchase

