import axios from 'axios'
import {server} from './common'

const addImg = (user, img, role) => {
    // console.log('add img function',user, img, role);
    if (Array.isArray(img)){
        // console.log('array')
        return (axios.post(`${server}/postImg`,{
            user_id:user.main_id,
            role:role,
            images:img
        }));
    }
    // console.log('single')
    return (axios.post(`${server}/postImg`,{
        image:{
            user_id:user.main_id,
            role:role,
            file_name:img.file_name,
            img_url:img.img_url
        }
    }));
}

const removeImg = (img_id) => {
    if (typeof(img_id)==='number'){
        return (axios.post(`${server}/deleteImg`,{
            img_id:img_id
        }));
    }
}

export {
    addImg, removeImg,
}