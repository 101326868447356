import React, { useState, useEffect } from 'react'
import './TellUsMore.css'

import { isPt, farmlyTexts } from '../../common'

const placeholderImage = require('../../assets/roaster.jpg')
const strawberryImage = require('../../assets/strawberry.jpg')
const chocolateImage = require('../../assets/chocolate.jpg')
const orangeImage = require('../../assets/orange.jpg')
const limeImage = require('../../assets/lime.jpg')
const bananaImage = require('../../assets/banana.jpg')
const caramelImage = require('../../assets/caramel.jpg')

const CoffeeScoreItem = props => {

    useEffect(() => {
        setFade(parseInt(coffeeAmt) > 0)
    }, [])

    const { coffeeAmt, setCoffeeAmt, scoreLabel, id } = props

    const [fade, setFade] = useState(false)

    const isNumeral = text => text === '' || !isNaN(text.charAt(text.length - 1))

    return (
        <div className="signup-score-item"
            style={{ opacity: fade ? 1 : '' }}>
            <div className="signup-score-img-container"
                onClick={() => document.getElementById(id).focus()}>
                <img className="signup-score-img"
                    src={placeholderImage} alt="" />
                <label className="signup-score-label">{scoreLabel}</label>
            </div>
            <input type="text" className="signup-field-input roaster-score-input"
                value={coffeeAmt} onChange={e => {
                    const { value } = e.target
                    isNumeral(value) &&
                    setCoffeeAmt(value === '' ? '' : parseInt(value).toString())
                }}
                onFocus={() => {
                    if (parseInt(coffeeAmt) === 0) setCoffeeAmt('')
                    setFade(true)
                }} onBlur={() => {
                    if (parseInt(coffeeAmt) === 0 || coffeeAmt === '') setCoffeeAmt('0')
                    setFade(parseInt(coffeeAmt) > 0)
                }} id={id} />
        </div>
    )
}

const TellUsMore = props => {

    const { prev, next, sensoryNotes, setSensoryNotes,
        coffeeAmt, setCoffeeAmt } = props

    const selectedScore = (
        parseInt(coffeeAmt.sub80) > 0 ||
        parseInt(coffeeAmt.s80_82) > 0 ||
        parseInt(coffeeAmt.s82_84) > 0 ||
        parseInt(coffeeAmt.s84_86) > 0 ||
        parseInt(coffeeAmt.s86_88) > 0 ||
        parseInt(coffeeAmt.over88) > 0
    )

    const renderCoffeeScores = () => (
        <div className="signup-scores-container">
            <div className="signup-row">
    <label className="signup-field-label tell-more-field-label">{isPt() ? farmlyTexts.signupSacksPerScore_pt : farmlyTexts.signupSacksPerScore_en}</label>
            </div>
            <div className="signup-row signup-coffee-scores">

                <CoffeeScoreItem coffeeAmt={coffeeAmt.sub80} setCoffeeAmt={val => setCoffeeAmt({ ...coffeeAmt, sub80: val })}
                    scoreLabel={'- 80'} id={'score0'} />

                <CoffeeScoreItem coffeeAmt={coffeeAmt.s80_82} setCoffeeAmt={val => setCoffeeAmt({ ...coffeeAmt, s80_82: val })}
                    scoreLabel={'80 ~ 82'} id={'score1'} />

                <CoffeeScoreItem coffeeAmt={coffeeAmt.s82_84} setCoffeeAmt={val => setCoffeeAmt({ ...coffeeAmt, s82_84: val })}
                    scoreLabel={'82 ~ 84'} id={'score2'} />

                <CoffeeScoreItem coffeeAmt={coffeeAmt.s84_86} setCoffeeAmt={val => setCoffeeAmt({ ...coffeeAmt, s84_86: val })}
                    scoreLabel={'84 ~ 86'} id={'score3'} />

                <CoffeeScoreItem coffeeAmt={coffeeAmt.s86_88} setCoffeeAmt={val => setCoffeeAmt({ ...coffeeAmt, s86_88: val })}
                    scoreLabel={'86 ~ 88'} id={'score4'} />

                <CoffeeScoreItem coffeeAmt={coffeeAmt.over88} setCoffeeAmt={val => setCoffeeAmt({ ...coffeeAmt, over88: val })}
                    scoreLabel={'88 +'} id={'score5'} />
            </div>
        </div>
    )

    const renderSensoryNotes = () => (
        <div className="signup-hidden-row"
            style={{ height: selectedScore ? '' : 0, marginTop: '6vh' }}>
            <div className="signup-row">
    <label className="signup-field-label tell-more-field-label">{isPt() ? farmlyTexts.signupSensoryNotes_pt : farmlyTexts.signupSensoryNotes_en}</label>
            </div>
            <div className="signup-row signup-sensory-notes">
                <div className="signup-sensory-item"
                    onClick={() => setSensoryNotes({ ...sensoryNotes, strawberry: sensoryNotes.strawberry ? '' : 'strawberry,' })}
                    style={{ opacity: sensoryNotes.strawberry ? 1 : '' }} >
                    <img className="signup-sensory-img" src={strawberryImage} alt="" />
                    <label className="signup-score-label">{isPt() ? farmlyTexts.signupStrawberry_pt : farmlyTexts.signupStrawberry_en}</label>
                </div>
                <div className="signup-sensory-item"
                    onClick={() => setSensoryNotes({ ...sensoryNotes, chocolate: sensoryNotes.chocolate ? '' : 'chocolate,' })}
                    style={{ opacity: sensoryNotes.chocolate ? 1 : '' }} >
                    <img className="signup-sensory-img" src={chocolateImage} alt="" />
                    <label className="signup-score-label">{isPt() ? farmlyTexts.signupChocolate_pt : farmlyTexts.signupChocolate_en}</label>
                </div>
                <div className="signup-sensory-item"
                    onClick={() => setSensoryNotes({ ...sensoryNotes, orange: sensoryNotes.orange ? '' : 'orange,' })}
                    style={{ opacity: sensoryNotes.orange ? 1 : '' }} >
                    <img className="signup-sensory-img" src={orangeImage} alt="" />
                    <label className="signup-score-label">{isPt() ? farmlyTexts.signupOrange_pt : farmlyTexts.signupOrange_en}</label>
                </div>
                <div className="signup-sensory-item"
                    onClick={() => setSensoryNotes({ ...sensoryNotes, lime: sensoryNotes.lime ? '' : 'lime,' })}
                    style={{ opacity: sensoryNotes.lime ? 1 : '' }} >
                    <img className="signup-sensory-img" src={limeImage} alt="" />
                    <label className="signup-score-label">{isPt() ? farmlyTexts.signupLime_pt : farmlyTexts.signupLime_en}</label>
                </div>
                <div className="signup-sensory-item"
                    onClick={() => setSensoryNotes({ ...sensoryNotes, banana: sensoryNotes.banana ? '' : 'banana,' })}
                    style={{ opacity: sensoryNotes.banana ? 1 : '' }} >
                    <img className="signup-sensory-img" src={bananaImage} alt="" />
                    <label className="signup-score-label">{isPt() ? farmlyTexts.signupBanana_pt : farmlyTexts.signupBanana_en}</label>
                </div>
                <div className="signup-sensory-item"
                    onClick={() => setSensoryNotes({ ...sensoryNotes, caramel: sensoryNotes.caramel ? '' : 'caramel,' })}
                    style={{ opacity: sensoryNotes.caramel ? 1 : '' }} >
                    <img className="signup-sensory-img" src={caramelImage} alt="" />
                    <label className="signup-score-label">{isPt() ? farmlyTexts.signupCaramel_pt : farmlyTexts.signupCaramel_en}</label>
                </div>
            </div>
        </div>
    )
    
    return (
        <div id="tell-us-more">
            <div className="signup-title-row">
                <div className="coffee-icon" style={{ alignSelf: 'flex-start', marginTop: '2px' }} />
                <div className="signup-compound-title" style={{ display: 'flex', flexDirection: 'column' }}>
                    <label className="signup-title">{isPt() ? farmlyTexts.signupTellUsMore_pt : farmlyTexts.signupTellUsMore_en}</label>
                    <label className="signup-subtitle">{isPt() ? farmlyTexts.signupLater_pt : farmlyTexts.signupLater_en}</label>
                </div>
            </div>

            {renderCoffeeScores()}

            {/*renderSensoryNotes()*/}

            <div className="signup-compound-buttons">
                <div onClick={prev} className="signup-back-button">
                    {isPt() ? farmlyTexts.signupGoBack_pt : farmlyTexts.signupGoBack_en}
                </div>
                <div onClick={next} className="signup-next-button">
                    {selectedScore ? 
                        (isPt() ? farmlyTexts.signupSend_pt : farmlyTexts.signupSend_en) : 
                        (isPt() ? farmlyTexts.signupSkip_pt : farmlyTexts.signupSkip_en)}
                </div>
            </div>
        </div>
    )
}

export default TellUsMore