import React, { useContext } from 'react'
import { useEffect } from 'react'
import './Nav.css'
import './NewNav.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingBasket } from '@fortawesome/free-solid-svg-icons'
import Cookies from 'js-cookie'
import { Link } from 'react-router-dom'
import { isPt, isCo, farmlyTexts, acceptedCookies, server, isBr } from '../../common'
import UserContext from '../../context/user/UserContext.js'
import CartContext from '../../context/cart/CartContext.js'
import {     useLocation, useHistory } from 'react-router-dom'
import { useState } from 'react'
import axios from 'axios'
const homeHeaderImage = require('../../assets/basetopcut.png')
const logoImage = require('../../assets/title-home-small.png')
const logoAlt = require('../../assets/title-home-small.png')

const NewNav = props => {
    
    const { mainTitle } = props
    const [allowedUsersToChat, setAllowedUsersToChat] = useState(
    [{email:"cafebernardes1@gmail.com",nome:"Antonio Kellysson Bernardes",id:"1669241318093x983390948458828300"},
    {email:"saviosantanap@gmail.com",nome:"Juarez Pereira Neto",id:"1669241297109x430458353808548200"},
    {email:"pieterkeijsers@hotmail.com",nome:"Pieter Keijsers",id:"1669241276817x788784002493723400"},
    {email:"franciscousa78@gmail.com",nome:"Francisco José Ferreira Toledo",id:"1669241246408x511221924357583740"},
    {email:"maxuelclopes@icloud.com",nome:"Maxuel",id:"1669241224325x959187248327039400"},
    {email:"michelccimini@hotmail.com",nome:"Michel Cupertino cimini",id:"1669241203708x911371509931526000"},
    {email:"cr3661065@gmail.com",nome:"Carlos Roberto de Souza",id:"1669241186660x832244318968098400"},
    {email:"robertoeluciene2016@gmail.com",nome:"Roberto Marinei Cardoso",id:"1669241167751x155562247513310720"},
    {email:"gisele293cristina293@gmail.com",nome:"gisele cristina da silva almeida",id:"1669241146214x129513695168244960"},
    {email:"suelisilvafaria@gmail.com",nome:"Sueli Silva Faria Oliveira",id:"1669241116249x498952506092759100"},
    {email:"julianevpsouza26@gmail.com",nome:"Marcos Ferreira de Souza",id:"1669241090704x164275227978977000"},
    {email:"luuizpauulofaria.souzza@gmail.com",nome:"Luiz Paulo de Souza faria",id:"1669241059471x374134377542423940"},
    {email:"fabianodiniz96@hotmail.com",nome:"Fabiano Diniz",id:"1669241031875x243668810348544700"},
    {email:"info@winme-roastery.com",nome:"Patrick Baer",id:"1669241010434x874517378542254600"},
    {email:"kafe@francin.cz",nome:"Frantisek",id:"1669240990873x793194582745848700"},
    {email:"info@oo-kaffee.de",nome:"Christian Obermeier",id:"1669240969858x789343117531887200"},
    {email:"dario@aliena.space",nome:"Dario Fociani",id:"1669240949457x365391221992058560"},
    {email:"roscasanovapedro@gmail.com",nome:"Pedro Ros Casanova",id:"1669240909610x346072166840103230"},
    {email:"jack@crosbycoffee.co.uk",nome:"Jack Foster",id:"1669240830207x717768097493701800"},
    {email:"coltrane@thecoltrane.com",nome:"Antonio Kellysson Bernardes",id:"1669239566513x640579635142307600"},    
    {email:"produtor@gmail.com",nome:"Maxuel",id:"1669239457748x363692488139987200"},
    {email:"raphael@raphael.com",nome:"Christian Obermeier",id:"1669234943768x486080930606063200"}])

    const userContext = useContext(UserContext)
    const cartContext = useContext(CartContext)
    const location = useLocation()
    const history = useHistory()

    const [linkIdToChat, setLinkIdToChat] = useState("")
    const [notificationList, setNotificationList] = useState()

    useEffect( () => {
        {
            userContext.user &&
                axios.get(`${server}/getAllNotifications/${userContext.user.id}/${userContext.user.isProd}`)
                    .then(async notifications => {
                        await setNotificationList(notifications.data.filter((novas) => novas.read == false))
                    })
                    .catch(err => console.log(err))
                    let data 
                if(userContext.user){
                    if(userContext.user.isProd) {
                        data = axios.get(`${server}/producer/${userContext.user.id}`).then((res) => {
                        const isUserAllowed = allowedUsersToChat.findIndex(c => c.email === res.data.email)
                        if(isUserAllowed > -1)
                        {
                            setLinkIdToChat(allowedUsersToChat[isUserAllowed].id)
                        }
                        }).catch((err) => {
                            console.error(err)
                        });
                    } else {    
                        data =  axios.get(`${server}/roaster/${userContext.user.id}`).then((res) => {
                            const isUserAllowed = allowedUsersToChat.findIndex(c => c.email === res.data.email)
                            if(isUserAllowed > -1)
                            {
                                setLinkIdToChat(allowedUsersToChat[isUserAllowed].id)
                            }                        
                        }).catch((err) => {
                            console.error(err)
                        });
                    }


                
                }
            
        }
    }, [])

    const logout = () => {

        if (acceptedCookies()) {
            Cookies.set('jwt', 0, {expires: 0, path: '/'})
            Cookies.set('isProd', 0, {expires: 0, path: '/'})
            Cookies.set('userId', 0, {expires: 0, path: '/'})
            Cookies.set('main_id', 0, {expires: 0, path: '/'})
            Cookies.set('cartList', 0, {expires: 0, path: '/'})
        }
        { (!userContext.user || (userContext.user && !userContext.user.isProd)) && cartContext.clearCart() }
        userContext.userLogout()
        window.location.href = '/'
    }

    const renderLogo = () => (
        <Link to="/">
            <div id="logo-container">
                {!props.smallImage &&
                    <img id='logo' src={logoImage} alt="logo" />
                }
                <img id='logo-alt' src={logoAlt} alt="logo" />
                {props.smallImage &&
                    <div className="small-image">
                        <img id='small-logo' src={logoAlt} alt="logo" />
                    </div>
                }
            </div>
        </Link>
    )

    const burgerClick = () => {
        const burger = document.querySelector("#burger")
        const nav = document.querySelector(".nav")
        const navItems = document.querySelectorAll('.nav-item')

        nav.classList.toggle('nav-active')
        burger.classList.toggle('burger-open')

        navItems.forEach((item, index) => {
            item.style.animation = item.style.animation ? '' : `navLinkFade 0.5s ease forwards ${index / 7 + 0.2}s`
        })
    }

    const renderNavBar = () => (
        <div className="nav-area">
            <nav className="nav">
                <Link className='nav-item' to='/'>
                    {isPt() ? farmlyTexts.navHome_pt : farmlyTexts.navHome_en}
                </Link>
                {(isPt() || isCo()) && false && <Link className='nav-item' to='/newcoffees'>
                    {(isPt() || isCo()) ? farmlyTexts.navCoffees_pt : farmlyTexts.navCoffees_en}
                </Link>}
                {/* {userContext.user && !userContext.user.isProd &&
                    <Link className='nav-item' to='/cuppingSelect'> 
                        Cupping
                    </Link>
                }  */}
                {(!isPt() && !isCo() || userContext.user && !userContext.user.isProd || userContext.user && userContext.user.id == 0) &&
                    <Link className='nav-item' to='/offerlist'> 
                        Offerlist
                    </Link>
                }  
                {(linkIdToChat !== undefined && linkIdToChat !== "" && linkIdToChat !== null) &&
                <Link className='nav-item' to={"/Chat/"+ linkIdToChat}>Chat</Link>
                } 
                {!userContext.user &&
                    <Link className='nav-item' to='/login'>
                        {isPt() ? farmlyTexts.navLogin_pt : farmlyTexts.navLogin_en}
                    </Link>}
                {!!userContext.user &&
                    <Link className='nav-item' to={{
                        pathname: '/profile',
                        navProps: {
                            from: userContext.user.isProd ? 'Perfil' : 'Profile'
                        }
                    }} >
                        {(isPt() || isCo()) ? farmlyTexts.navProfile_pt : farmlyTexts.navProfile_en}
                    </Link>}
                {!!userContext.user && userContext.user.isProd &&
                    <Link className='nav-item' to={{
                        pathname: '/profiletosample',
                        navProps: {
                            from: 'Enviar Amostra'
                        }
                    }} >
                        {isCo() ? 'Enviar Muestra' : (isPt() ? 'Enviar Amostra' : 'Send Sample')}
                    </Link>}
                    {isBr() &&
                    <Link className='nav-item' to='/CoffeeExchangeRate'>
                        {isPt() ? "Cotação do Café" : "Coffee Exchange Rate"}
                    </Link>}

                {/* {isPt() && <a className='nav-item' href='https://blog-pt.farmlyclub.com'>
                    {isPt() ? farmlyTexts.navBlog_pt : farmlyTexts.navBlog_en}
                </a>}
                {!isPt() && <a className='nav-item' href='https://blog.farmlyclub.com'>
                    {isPt() ? farmlyTexts.navBlog_pt : farmlyTexts.navBlog_en}
                </a>} */}
                <Link className='nav-item' to='/help'>
                    {isPt() ? farmlyTexts.navHelp_pt : (isCo() ? 'Ayuda' : farmlyTexts.navHelp_en)}
                </Link>

                {cartContext.cartList && !isBr() && (userContext.user ? !userContext.user.isProd : !userContext.user) &&
                    <Link className='nav-item' to='/cart' alt="link to shopping cart">
                        <div className="cart-container">
                            <FontAwesomeIcon className="cart-icon" icon={faShoppingBasket} />
                            {cartContext.cartList.length > 0 &&
                                <div className='cart-counter'>{cartContext.cartList.length}</div>
                            }
                        </div>
                    </Link>}
                {!!userContext.user && <label id="divider">|</label>}
                {!!userContext.user &&
                    <button className="logout nav-item" onClick={logout}>{isPt() ? farmlyTexts.navLogout_pt : farmlyTexts.navLogout_en}</button>}
            </nav>
            <div id="burger" onClick={burgerClick}>
                <div id="line1"></div>
                <div id="line2"></div>
                <div id="line3"></div>
            </div>
        </div >
    )

    const renderHeaderBanner = () => (
        <div style = {{marginBottom: '5vw'}} >
            <div className="img-background-new-nav"></div>
            <div className="separator-new-nav"></div>
            <div className="main-title-new-nav" style={mainTitle != '' ? {} :  { display: 'none'} } >{mainTitle}</div>
        </div>
    )

    const renderHomeBanner = () => (
        <div id="home-header-container">
            <img id="home-header" src={homeHeaderImage} alt="" />
        </div>
    )

    return (
        <>
            <div id="nav-container" style={props.noBanner ? { position: 'fixed', width: '100%' } : {}}>
                {renderLogo()}
                {renderNavBar()}
                {!props.noBanner && (props.isHome ? renderHomeBanner() : renderHeaderBanner())}
            </div>
        </>
    )
}

export default NewNav