import React, { useState, useEffect, useContext, Fragment, forwardRef, useRef, useImperativeHandle } from 'react'
import axios from 'axios'
import { server, isPt } from '../../../common'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faTimes } from '@fortawesome/free-solid-svg-icons'
import { useHistory, Link } from 'react-router-dom'
import UserContext from '../../../context/user/UserContext.js'
import Paper from '@material-ui/core/Paper'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import FarmlyInput from '../../templates/FarmlyInput'
import '../CuppingIndv.css'
import Grid from '@material-ui/core/Grid'
import Volume from '@material-ui/core/Slider'
import Slider from "react-slick"
import Divider from '@material-ui/core/Divider'
import RadarChart from '../../templates/RadarChartCupping'
import Select from 'react-select'
import FarmlyModal from '../../templates/FarmlyModal'
import { faYoutube } from '@fortawesome/free-solid-svg-icons'
import { OpenInNewOutlined } from '@material-ui/icons'

const CuppingExclusiveIndv = props => {

    const { coffee, sensoryList, cuppingDone, checkCuppings, setCheckCuppings, updateAll, setFillAllSpaces, goToResults, id, setGoToResults } = props
    const userContext = useContext(UserContext)
    const [showResults, setShowResults] = useState(false)
    const [init, setInit] = useState(true)
    const [coffeeData, setCoffeeData] = useState()
    const [cuppingData, setCuppingData] = useState({})
    const [score, setScore] = useState(0)
    const [roast1, setRoast1] = useState(false)
    const [roast2, setRoast2] = useState(false)
    const [roast3, setRoast3] = useState(false)
    const [roast4, setRoast4] = useState(false)
    const [roast5, setRoast5] = useState(false)

    const [defect0, setDefect0] = useState(false)
    const [defect1, setDefect1] = useState(false)
    const [defect2, setDefect2] = useState(false)
    const [defect3, setDefect3] = useState(false)
    const [defect4, setDefect4] = useState(false)
    const [defect5, setDefect5] = useState(false)

    const [bigDefect0, setBigDefect0] = useState(false)
    const [bigDefect1, setBigDefect1] = useState(false)
    const [bigDefect2, setBigDefect2] = useState(false)
    const [bigDefect3, setBigDefect3] = useState(false)
    const [bigDefect4, setBigDefect4] = useState(false)
    const [bigDefect5, setBigDefect5] = useState(false)

    const [sweet1, setSweet1] = useState(false)
    const [sweet2, setSweet2] = useState(false)
    const [sweet3, setSweet3] = useState(false)
    const [sweet4, setSweet4] = useState(false)
    const [sweet5, setSweet5] = useState(false)
    const [sweet, setSweet] = useState(0)

    const [clean1, setClean1] = useState(false)
    const [clean2, setClean2] = useState(false)
    const [clean3, setClean3] = useState(false)
    const [clean4, setClean4] = useState(false)
    const [clean5, setClean5] = useState(false)
    const [cleanCup, setCleanCup] = useState(0)

    const [equal1, setEqual1] = useState(false)
    const [equal2, setEqual2] = useState(false)
    const [equal3, setEqual3] = useState(false)
    const [equal4, setEqual4] = useState(false)
    const [equal5, setEqual5] = useState(false)
    const [equalty, setEqualty] = useState(0)

    const [valueAroma, setValueAroma] = useState(0)
    const [valueAroma2, setValueAroma2] = useState(0)
    const [valueAroma3, setValueAroma3] = useState(0)
    const [valueFlavor, setValueFlavor] = useState(0)
    const [valueCompletion, setValueCompletion] = useState(0)
    const [valueAcidity, setValueAcidity] = useState(0)
    const [valueAcidity2, setValueAcidity2] = useState(0)
    const [valueBody, setValueBody] = useState(0)
    const [valueBody2, setValueBody2] = useState(0)
    const [valueBalance, setValueBalance] = useState(0)
    const [valueOverall, setValueOverall] = useState(0)

    const [roastWarning, setRoastWarning] = useState(false)
    const [aromaWarning, setAromaWarning] = useState(false)
    const [flavorWarning, setFlavorWarning] = useState(false)
    const [completionWarning, setCompletionWarning] = useState(false)
    const [acidityWarning, setAcidityWarning] = useState(false)
    const [bodyWarning, setBodyWarning] = useState(false)
    const [balanceWarning, setBalanceWarning] = useState(false)
    const [overallWarning, setOverallWarning] = useState(false)
    const [scoreVar, setScoreVar] = useState(false)
    const [opinion, setOpinion] = useState(false)
    const opinionOptions = [
        "Not good",
        "Good for basic blend",
        "Standard single origin",
        "Standard espresso",
        "High end espresso",
        "Amazing Coffee",
        "Rare Coffee"]


    const history = useHistory()

    useEffect(() => {
        if (init) {
            initializeData()
        }
    }, [])

    useEffect(() => {
        if (goToResults.status) {
            if(goToResults.coffeeId == id) {
                handleResult(false)
                setFillAllSpaces(false)
            }
        }
    }, [goToResults])


    useEffect(() => {
        if (scoreVar) {
            setScore(parseInt(cuppingData.aroma) + parseInt(cuppingData.flavor) + parseInt(cuppingData.completion) + parseInt(cuppingData.acidity) + parseInt(cuppingData.body) + parseInt(cuppingData.balance) + cleanCup + sweet + equalty - parseInt(cuppingData.defects) - parseInt(cuppingData.big_defect) + parseInt(cuppingData.overall) + 30)
        } else {
            setScore(0)
        }
    }, [cuppingData, cleanCup, equalty, sweet, init, scoreVar])

    useEffect(() => {
        axios.get(`${server}/coffee/${id}`)
            .then(res => {
                if (res.data.sensoryNotes !== null && res.data.sensoryNotes !== "") setCoffeeData(res.data)
                else setCoffeeData({ ...res.data, sensoryNotes: "Sem notas" })
            })
    }, [showResults, sensoryList])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }, [showResults])

    const initializeData = () => {
        axios.get(`${server}/getMainCupping/${id}`)
            .then(res => {
                if (res.data.length == 0) {
                    setCuppingData({
                        coffee_id: id,
                        user_id: 2000,
                        aroma: '0',
                        flavor: '0',
                        completion: '0',
                        acidity: '0',
                        body: '0',
                        balance: '0',
                        defects: '0',
                        big_defect: '0',
                        overall: '0',
                        score: 0
                    })
                    setScore(0)
                }
                else {
                    setCuppingData({ ...res.data, cupping_done: false })
                    setScore(parseInt(res.data.aroma) + parseInt(res.data.flavor) + parseInt(res.data.completion) + parseInt(res.data.acidity) + parseInt(res.data.body) + parseInt(res.data.balance) + cleanCup + sweet + equalty - parseInt(res.data.defects) - parseInt(res.data.big_defect) + parseInt(res.data.overall))
                    setScoreVar(true)
                }
                setInit(false)
            })
    }

    const handleResult = async (force) => {
        if ((!roastWarning || !aromaWarning || !flavorWarning || !acidityWarning || !bodyWarning || !balanceWarning || !overallWarning || !completionWarning) && force ) {
            setFillAllSpaces(true)
        } else {
            axios.patch(`${server}/updateCupping`, {
                ...cuppingData,
                clean_cup: cleanCup,
                sweetness: sweet,
                uniformity: equalty,
                score: score,
                cupping_done: true
            }) .then(_ => {
                    axios.post(`${server}/createOpinion`, { ...opinion, coffee_id: id})
                        .then(_ => {
                            setCheckCuppings(!checkCuppings)
                            setShowResults(true)
                        })
            })
        }

    }

    const handleReturn = async () => {
        await axios.patch(`${server}/updateCuppingStatus`, {
            coffeeId: id,
            userId: 2000,
            status: false
        })
        setCheckCuppings(!checkCuppings)
        setShowResults(false)
    }

    const setRoastPoint = (which) => {
        switch (which) {
            case 1:
                setCuppingData({ ...cuppingData, roasting_point: 1 })
                setRoast1(!roast1)
                setRoast2(false)
                setRoast3(false)
                setRoast4(false)
                setRoast5(false)
                break;
            case 2:
                setCuppingData({ ...cuppingData, roasting_point: 2 })
                setRoast2(!roast2)
                setRoast1(false)
                setRoast3(false)
                setRoast4(false)
                setRoast5(false)
                break;
            case 3:
                setCuppingData({ ...cuppingData, roasting_point: 3 })
                setRoast3(!roast3)
                setRoast2(false)
                setRoast1(false)
                setRoast4(false)
                setRoast5(false)
                break;
            case 4:
                setCuppingData({ ...cuppingData, roasting_point: 4 })
                setRoast4(!roast4)
                setRoast2(false)
                setRoast3(false)
                setRoast1(false)
                setRoast5(false)
                break;
            case 5:
                setCuppingData({ ...cuppingData, roasting_point: 5 })
                setRoast5(!roast5)
                setRoast2(false)
                setRoast3(false)
                setRoast4(false)
                setRoast1(false)
                break;

            default:
                break;
        }
    }

    const setDefect = (which) => {
        switch (which) {
            case 0:
                setCuppingData({ ...cuppingData, defects: 0 })
                setDefect0(!defect0)
                setDefect2(false)
                setDefect1(false)
                setDefect3(false)
                setDefect4(false)
                setDefect5(false)
                break;
            case 2:
                setCuppingData({ ...cuppingData, defects: 2 })
                setDefect1(!defect1)
                setDefect2(false)
                setDefect0(false)
                setDefect3(false)
                setDefect4(false)
                setDefect5(false)
                break;
            case 4:
                setCuppingData({ ...cuppingData, defects: 4 })
                setDefect2(!defect2)
                setDefect0(false)
                setDefect1(false)
                setDefect3(false)
                setDefect4(false)
                setDefect5(false)
                break;
            case 6:
                setCuppingData({ ...cuppingData, defects: 6 })
                setDefect3(!defect3)
                setDefect0(false)
                setDefect2(false)
                setDefect1(false)
                setDefect4(false)
                setDefect5(false)
                break;
            case 8:
                setCuppingData({ ...cuppingData, defects: 8 })
                setDefect4(!defect4)
                setDefect0(false)
                setDefect2(false)
                setDefect3(false)
                setDefect1(false)
                setDefect5(false)
                break;
            case 10:
                setCuppingData({ ...cuppingData, defects: 10 })
                setDefect5(!defect5)
                setDefect0(false)
                setDefect2(false)
                setDefect3(false)
                setDefect4(false)
                setDefect1(false)
                break;

            default:
                break;
        }
    }


    const setBigDefect = (which) => {
        switch (which) {
            case 0:
                setCuppingData({ ...cuppingData, big_defect: 0 })
                setBigDefect0(!bigDefect0)
                setBigDefect2(false)
                setBigDefect1(false)
                setBigDefect3(false)
                setBigDefect4(false)
                setBigDefect5(false)
                break;
            case 4:
                setCuppingData({ ...cuppingData, big_defect: 4 })
                setBigDefect1(!bigDefect1)
                setBigDefect2(false)
                setBigDefect0(false)
                setBigDefect3(false)
                setBigDefect4(false)
                setBigDefect5(false)
                break;
            case 8:
                setCuppingData({ ...cuppingData, big_defect: 8 })
                setBigDefect2(!bigDefect2)
                setBigDefect0(false)
                setBigDefect1(false)
                setBigDefect3(false)
                setBigDefect4(false)
                setBigDefect5(false)
                break;
            case 12:
                setCuppingData({ ...cuppingData, big_defect: 12 })
                setBigDefect3(!bigDefect3)
                setBigDefect2(false)
                setBigDefect1(false)
                setBigDefect0(false)
                setBigDefect4(false)
                setBigDefect5(false)
                break;
            case 16:
                setCuppingData({ ...cuppingData, big_defect: 16 })
                setBigDefect4(!bigDefect4)
                setBigDefect2(false)
                setBigDefect1(false)
                setBigDefect3(false)
                setBigDefect0(false)
                setBigDefect5(false)
                break;
            case 20:
                setCuppingData({ ...cuppingData, big_defect: 20 })
                setBigDefect5(!bigDefect5)
                setBigDefect2(false)
                setBigDefect1(false)
                setBigDefect3(false)
                setBigDefect4(false)
                setBigDefect0(false)
                break;

            default:
                break;
        }
    }

    const handleChangeAroma = (event, newValue) => {
        setValueAroma(newValue)
        setCuppingData({ ...cuppingData, aroma: newValue })
        console.log(cuppingData, coffeeData)
        setScoreVar(true)
        setAromaWarning(true)
    }

    const handleChangeAroma2 = (event, newValue) => {
        setScoreVar(true)
        setValueAroma2(newValue)
    }

    const handleChangeAroma3 = (event, newValue) => {
        setScoreVar(true)
        setValueAroma3(newValue)
    }


    const handleChangeFlavor = (event, newValue) => {
        setValueFlavor(newValue)
        setCuppingData({ ...cuppingData, flavor: newValue })
        setScoreVar(true)
        setFlavorWarning(true)
    }

    const handleChangeCompletion = (event, newValue) => {
        setValueCompletion(newValue)
        setCuppingData({ ...cuppingData, completion: newValue })
        setScoreVar(true)
        setCompletionWarning(true)
    }

    const handleChangeAcidity = (event, newValue) => {
        setValueAcidity(newValue)
        setCuppingData({ ...cuppingData, acidity: newValue })
        setScoreVar(true)
        setAcidityWarning(true)
    }

    const handleChangeAcidity2 = (event, newValue) => {
        setScoreVar(true)
        setValueAcidity2(newValue)
    }

    const handleChangeBody = (event, newValue) => {
        setValueBody(newValue)
        setCuppingData({ ...cuppingData, body: newValue })
        setScoreVar(true)
        setBodyWarning(true)
    }

    const handleChangeBody2 = (event, newValue) => {
        setScoreVar(true)
        setValueBody2(newValue)
    }

    const handleChangeBalance = (event, newValue) => {
        setValueBalance(newValue)
        setCuppingData({ ...cuppingData, balance: newValue })
        setScoreVar(true)
        setBalanceWarning(true)
    }

    const handleChangeOverall = (event, newValue) => {
        setValueOverall(newValue)
        setCuppingData({ ...cuppingData, overall: newValue })
        setScoreVar(true)
        setOverallWarning(true)
    }

    const renderOpts = () => {
        const about = []
        opinionOptions.map((opt) => {
            about.push({
                value: opt,
                label: opt
            })
        })
        return about
    }

    // const updateCupping = () => {
    //         axios.patch(`${server}/updateCupping`, {
    //             ...cuppingData,
    //             clean_cup: cleanCup,
    //             sweetness: sweet,
    //             uniformity: equalty,
    //             score: score,
    //             cupping_done: true
    //         }) .then(_ => { 
    //                 axios.post(`${server}/createOpinion`, { ...opinion, coffee_id: id})
    //                     .then(_ => updateAll())
    //         })
            
    //     }

    const renderSensoryCard = () =>
        coffeeData.sensoryNotes.split(',').map((note) => {
            let random = Math.floor(Math.random() * 3)
            return (
                <Fragment>
                    <div className={(note.length < 7) ? 'sensory-red' : (((note.length < 10 && note.length > 6) ? 'sensory-lime' : 'sensory-orange'))}>{note}</div>
                    &nbsp;
                    <br />
                </Fragment>
            )
        })

    const useStyles = makeStyles((theme) => ({
        mainPaper: {
            height: 'auto',
            marginBottom: '-55px',
            borderRadius: '25px',
            width: '100%',
            backgroundColor: 'rgb(27, 98, 94)',
            marginTop: '-10vw',
            paddingTop: '20vw'
        },
        volume: {
            height: 300,
        },
        scorePaper: {
            marginTop: '-55px',
            height: 'auto',
            marginBottom: '5px',
            borderRadius: '25px',
            width: '100%',
            backgroundColor: 'rgb(27, 98, 94)',
            zIndex: '0',
            paddingBottom: '25px'
        },
        margin: {
            margin: theme.spacing(1),
        },
    }))
    const classes = useStyles()

    const marks = [
        {
            value: 6,
            label: '6',
        },
        {
            value: 7,
            label: '7',
        },
        {
            value: 8,
            label: '8',
        },
        {
            value: 9,
            label: '9',
        },
        {
            value: 10,
            label: '10',
        },
    ]

    const noMarks = [
        {
            value: 6,
            label: '-',
        },
        {
            value: 7,
            label: '-',
        },
        {
            value: 8,
            label: '-',
        },
        {
            value: 9,
            label: '-',
        },
        {
            value: 10,
            label: '-',
        },
    ]

    const bodyMarks = [
        {
            value: 6,
            label: '- Pouco denso',
        },
        {
            value: 7,
            label: '-',
        },
        {
            value: 8,
            label: '- Denso',
        },
        {
            value: 9,
            label: '-',
        },
        {
            value: 10,
            label: '- Muito denso',
        },
    ]

    const acidityMarks = [
        {
            value: 6,
            label: '- Baixa',
        },
        {
            value: 7,
            label: '-',
        },
        {
            value: 8,
            label: '- Média',
        },
        {
            value: 9,
            label: '-',
        },
        {
            value: 10,
            label: '- Alta',
        },
    ]

    const chartAttributes = [{
        Acidez: cuppingData.acidity,
        Aroma: cuppingData.aroma,
        Corpo: cuppingData.body,
        Equilibrio: cuppingData.balance,
        Finalização: cuppingData.completion,
        Sabor: cuppingData.flavor
    }]


    const settings = {
        className: "center",
        centerMode: true,
        infinite: false,
        centerPadding: "35px",
        slidesToShow: 3,
        swipeToSlide: true,
        centerColor: 'red',
        arrows: true,
        acessibility: true
    }

    return (
        <div>
            {!showResults ?
                <Fragment>
                    <Paper className={classes.mainPaper}>
                        <div className="producer-profile-item" style={{ padding: '15vw', height: 'auto' }}>
                            <label htmlFor="" className="producer-profile-label coffee-producer-title">{coffee.producer}'s coffee</label>
                            <br />
                            <label style={{ color: 'rgb(230, 179, 143)', paddingLeft: '6vw' }}>Code : {coffee.farmCode}</label>
                            <br />
                            <label className='coffee-comment'>{coffee.description}</label>
                        </div>

                        <div className='img-sup-dir'></div>
                        <div className='img-sup-esq'></div>
                    </Paper>
                    <Paper elevation={3} style={{ margin: '4vw', borderRadius: '25px' }}>
                        <div style={{ padding: '15px' }}>
                            <div className="producer-profile-item">
                                <label htmlFor="" className="producer-profile-label info-title" >Roasting point</label>
                            </div>
                            <div className="producer-profile-item coffee-roast" >
                                <div className={roast1 ? 'selected-roast' : ''} onClick={() => {
                                    setRoastPoint(1)
                                    setRoastWarning(true)
                                }}>
                                    <div className='cafe-fraco'></div>
                                </div>
                                <div className={roast2 ? 'selected-roast' : ''} onClick={() => {
                                    setRoastPoint(2)
                                    setRoastWarning(true)
                                }}>
                                    <div className='cafe-fraco2'></div>
                                </div>
                                <div className={roast3 ? 'selected-roast' : ''} onClick={() => {
                                    setRoastPoint(3)
                                    setRoastWarning(true)
                                }}>
                                    <div className='cafe-medio'></div>
                                </div>
                                <div className={roast4 ? 'selected-roast' : ''} onClick={() => {
                                    setRoastPoint(4)
                                    setRoastWarning(true)
                                }}>
                                    <div className='cafe-medio2'></div>
                                </div>
                                <div className={roast5 ? 'selected-roast' : ''} onClick={() => {
                                    setRoastPoint(5)
                                    setRoastWarning(true)
                                }}>
                                    <div className='cafe-forte'></div>
                                </div>
                            </div>
                        </div>
                    </Paper>
                    <Paper elevation={3} style={{ margin: '4vw', borderRadius: '25px' }}>
                        <div style={{ padding: '15px' }}>
                            <div className="producer-profile-item">
                                <label htmlFor="" className="producer-profile-label info-title" >Aroma</label>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <label>Dry</label>
                                    <br />
                                    <Grid container spacing={2} className='small-volume'>
                                        <Grid item xs>
                                            <Volume value={valueAroma3} style={{ color: "rgb(27, 98, 94)" }} onChange={handleChangeAroma3} marks={noMarks} min={6} max={10} valueLabelDisplay="off" orientation="vertical" aria-labelledby="continuous-slider" step={0.5} track="normal" />
                                        </Grid>
                                    </Grid>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <label>Crust</label>
                                    <br />
                                    <Grid container spacing={2} className='small-volume'>
                                        <Grid item xs>
                                            <Volume value={valueAroma2} style={{ color: "rgb(27, 98, 94)", borderRadius: '50%' }} onChange={handleChangeAroma2} marks={noMarks} min={6} max={10} valueLabelDisplay="off" orientation="vertical" aria-labelledby="continuous-slider" step={0.5} />
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                            <br />
                            <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                                <label>Overview</label>
                                <br />
                                <br />
                                <br />
                                <Grid container spacing={2} className="big-volume" >
                                    <Grid item xs>
                                        <Volume value={cuppingData.aroma ? cuppingData.aroma : valueAroma} style={{ color: "rgb(27, 98, 94)", borderRadius: '50%' }} onChange={handleChangeAroma} marks={marks} min={6} max={10} valueLabelDisplay="on" orientation="vertical" aria-labelledby="continuous-slider" step={0.5} />
                                    </Grid>
                                </Grid>
                            </div>
                            <br />
                            <div>
                                <label>Comment:</label>
                                <br />
                                <br />
                                <FarmlyInput textarea styleClass="farmly-input comments-cupping" value={cuppingData.aromaComment || ""} setValue={value => setCuppingData({ ...cuppingData, aromaComment: value })}></FarmlyInput>
                            </div>
                        </div>
                    </Paper>

                    <Paper elevation={3} style={{ margin: '4vw', borderRadius: '25px' }}>
                        <div style={{ padding: '15px' }}>
                            <div className="producer-profile-item">
                                <label htmlFor="" className="producer-profile-label info-title">Flavor</label>
                            </div>
                            <br />
                            <br />
                            <Grid container spacing={2} className="big-volume">
                                <Grid item xs>
                                    <Volume value={valueFlavor} style={{ color: "rgb(27, 98, 94)" }} onChange={handleChangeFlavor} marks={marks} min={6} max={10} valueLabelDisplay="on" orientation="vertical" aria-labelledby="continuous-slider" step={0.5} />
                                </Grid>
                            </Grid>
                            <div>
                                <label>Comment:</label>
                                <br />
                                <br />
                                <FarmlyInput textarea styleClass="farmly-input comments-cupping" value={cuppingData.flavorComment || ""} setValue={value => setCuppingData({ ...cuppingData, flavorComment: value })}></FarmlyInput>
                            </div>
                        </div>
                    </Paper>

                    <Paper elevation={3} style={{ margin: '4vw', borderRadius: '25px' }}>
                        <div style={{ padding: '15px' }}>
                            <div className="producer-profile-item">
                                <label htmlFor="" className="producer-profile-label info-title">Acidity</label>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <label>Intensity</label>
                                    <br />
                                    <Grid container spacing={2} className='small-volume'>
                                        <Grid item xs>
                                            <Volume value={valueAcidity2} style={{ color: "rgb(27, 98, 94)", borderRadius: '50%' }} onChange={handleChangeAcidity2} marks={acidityMarks} min={6} max={10} valueLabelDisplay="off" orientation="vertical" aria-labelledby="continuous-slider" step={0.5} />
                                        </Grid>
                                    </Grid>
                                </div>

                            </div>
                            <br />
                            <br />
                            <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                                <label>Overview</label>
                                <br />
                                <br />
                                <br />
                                <Grid container spacing={2} className="big-volume">
                                    <Grid item xs>
                                        <Volume value={valueAcidity} style={{ color: "rgb(27, 98, 94)", borderRadius: '50%' }} onChange={handleChangeAcidity} marks={marks} min={6} max={10} valueLabelDisplay="on" orientation="vertical" aria-labelledby="continuous-slider" step={0.5} />
                                    </Grid>
                                </Grid>
                            </div>
                            <div>
                                <label>Comment:</label>
                                <br />
                                <br />
                                <FarmlyInput textarea styleClass="farmly-input comments-cupping" value={cuppingData.acidityComment || ""} setValue={value => setCuppingData({ ...cuppingData, acidityComment: value })}></FarmlyInput>
                            </div>
                        </div>
                    </Paper>

                    <Paper elevation={3} style={{ margin: '4vw', borderRadius: '25px' }}>
                        <div style={{ padding: '15px' }}>
                            <div className="producer-profile-item">
                                <label htmlFor="" className="producer-profile-label info-title">After taste</label>
                            </div>
                            <br />
                            <br />
                            <Grid container spacing={2} className="big-volume">
                                <Grid item xs>
                                    <Volume value={valueCompletion} style={{ color: "rgb(27, 98, 94)" }} onChange={handleChangeCompletion} marks={marks} min={6} max={10} valueLabelDisplay="on" orientation="vertical" aria-labelledby="continuous-slider" step={0.5} />
                                </Grid>
                            </Grid>
                            <div>
                                <label>Comment:</label>
                                <br />
                                <br />
                                <FarmlyInput textarea styleClass="farmly-input comments-cupping" value={cuppingData.completionComment || ""} setValue={value => setCuppingData({ ...cuppingData, completionComment: value })}></FarmlyInput>
                            </div>
                        </div>
                    </Paper>

                    <Paper elevation={3} style={{ margin: '4vw', borderRadius: '25px' }}>
                        <div style={{ padding: '15px' }}>
                            <div className="producer-profile-item">
                                <label htmlFor="" className="producer-profile-label info-title">Body</label>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <label>Intensity</label>
                                    <br />
                                    <br />
                                    <br />
                                    <Grid container spacing={2} className='small-volume'>
                                        <Grid item xs>
                                            <Volume value={valueBody2} style={{ color: "rgb(27, 98, 94)", borderRadius: '50%' }} onChange={handleChangeBody2} marks={bodyMarks} min={6} max={10} valueLabelDisplay="off" orientation="vertical" aria-labelledby="continuous-slider" step={0.5} />
                                        </Grid>
                                    </Grid>
                                </div>

                            </div>
                            <br />
                            <br />
                            <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                                <label>Overview</label>
                                <br />
                                <br />
                                <br />
                                <Grid container spacing={2} className="big-volume">
                                    <Grid item xs>
                                        <Volume value={valueBody} style={{ color: "rgb(27, 98, 94)", borderRadius: '50%' }} onChange={handleChangeBody} marks={marks} min={6} max={10} valueLabelDisplay="on" orientation="vertical" aria-labelledby="continuous-slider" step={0.5} />
                                    </Grid>
                                </Grid>
                            </div>
                            <div>
                                <label>Comment:</label>
                                <br />
                                <br />
                                <FarmlyInput textarea styleClass="farmly-input comments-cupping" value={cuppingData.bodyComment || ""} setValue={value => setCuppingData({ ...cuppingData, bodyComment: value })}></FarmlyInput>
                            </div>
                        </div>
                    </Paper>

                    <Paper elevation={3} style={{ margin: '4vw', borderRadius: '25px' }}>
                        <div style={{ padding: '15px' }}>
                            <div className="producer-profile-item">
                                <label htmlFor="" className="producer-profile-label info-title" >Balance</label>
                            </div>
                            <br />
                            <br />
                            <Grid container spacing={2} className="big-volume">
                                <Grid item xs>
                                    <Volume value={valueBalance} style={{ color: "rgb(27, 98, 94)" }} onChange={handleChangeBalance} marks={marks} min={6} max={10} valueLabelDisplay="on" orientation="vertical" aria-labelledby="continuous-slider" step={0.5} />
                                </Grid>
                            </Grid>
                            <div>
                                <label>Comment:</label>
                                <br />
                                <br />
                                <FarmlyInput textarea styleClass="farmly-input comments-cupping" value={cuppingData.balanceComment || ""} setValue={value => setCuppingData({ ...cuppingData, balanceComment: value })}></FarmlyInput>
                            </div>
                        </div>
                    </Paper>
                    < Paper elevation={3} zIndex="tooltip" style={{ margin: '4vw', borderRadius: '25px', position: 'relative' }}>
                        <div style={{ padding: '15px', textAlign: 'center' }}>
                            <div className="producer-profile-item">
                                <label htmlFor="" className="producer-profile-label info-title">Clean Cup</label>
                            </div>
                            <div>
                                {clean1 && <FontAwesomeIcon icon={faTimes} className="select-cross" onClick={() => {
                                    clean1 ? setCleanCup(cleanCup + 2) : setCleanCup(cleanCup - 2)
                                    setClean1(!clean1)
                                }}></FontAwesomeIcon>}
                                <FontAwesomeIcon className="select-cup" icon={faCoffee} onClick={() => {
                                    clean1 ? setCleanCup(cleanCup + 2) : setCleanCup(cleanCup - 2)
                                    setClean1(!clean1)
                                }}></FontAwesomeIcon>
                                {clean2 && <FontAwesomeIcon icon={faTimes} className="select-cross" onClick={() => {
                                    clean2 ? setCleanCup(cleanCup + 2) : setCleanCup(cleanCup - 2)
                                    setClean2(!clean2)
                                }}></FontAwesomeIcon>}
                                <FontAwesomeIcon className="select-cup" icon={faCoffee} onClick={() => {
                                    clean2 ? setCleanCup(cleanCup + 2) : setCleanCup(cleanCup - 2)
                                    setClean2(!clean2)
                                }}></FontAwesomeIcon>
                            </div>
                            <div>
                                {clean3 && <FontAwesomeIcon icon={faTimes} className="select-cross" onClick={() => {
                                    clean3 ? setCleanCup(cleanCup + 2) : setCleanCup(cleanCup - 2)
                                    setClean3(!clean3)
                                }}></FontAwesomeIcon>}
                                <FontAwesomeIcon className="select-cup" icon={faCoffee} onClick={() => {
                                    clean3 ? setCleanCup(cleanCup + 2) : setCleanCup(cleanCup - 2)
                                    setClean3(!clean3)
                                }}></FontAwesomeIcon>
                                {clean4 && <FontAwesomeIcon icon={faTimes} className="select-cross" onClick={() => {
                                    clean4 ? setCleanCup(cleanCup + 2) : setCleanCup(cleanCup - 2)
                                    setClean4(!clean4)
                                }}></FontAwesomeIcon>}
                                <FontAwesomeIcon className="select-cup" icon={faCoffee} onClick={() => {
                                    clean4 ? setCleanCup(cleanCup + 2) : setCleanCup(cleanCup - 2)
                                    setClean4(!clean4)
                                }}></FontAwesomeIcon>
                                {clean5 && <FontAwesomeIcon icon={faTimes} className="select-cross" onClick={() => {
                                    clean5 ? setCleanCup(cleanCup + 2) : setCleanCup(cleanCup - 2)
                                    setClean5(!clean5)
                                }}></FontAwesomeIcon>}
                                <FontAwesomeIcon className="select-cup" icon={faCoffee} onClick={() => {
                                    clean5 ? setCleanCup(cleanCup + 2) : setCleanCup(cleanCup - 2)
                                    setClean5(!clean5)
                                }}></FontAwesomeIcon>
                            </div>
                            {/* <div className="comment-box">
                                <label>Comentário:</label>
                                <br />
                                <br />
                                <FarmlyInput textarea styleClass="farmly-input comments-cupping" value={cuppingData.cleanCupComment || ""} setValue={value => setCuppingData({ ...cuppingData, cleanCupComment: value })}></FarmlyInput>
                            </div> */}
                        </div>
                    </Paper>

                    {/* Doçura */}
                    <Paper elevation={3} style={{ margin: '4vw', borderRadius: '25px' }}>
                        <div style={{ padding: '15px', textAlign: 'center' }}>
                            <div className="producer-profile-item">
                                <label htmlFor="" className="producer-profile-label info-title">Sweetness</label>
                            </div>
                            <div>
                                {sweet1 && <FontAwesomeIcon icon={faTimes} className="select-cross" onClick={() => {
                                    sweet1 ? setSweet(sweet + 2) : setSweet(sweet - 2)
                                    setSweet1(!sweet1)
                                }}></FontAwesomeIcon>}
                                <FontAwesomeIcon className="select-cup" icon={faCoffee} onClick={() => {
                                    sweet1 ? setSweet(sweet + 2) : setSweet(sweet - 2)
                                    setSweet1(!sweet1)
                                }}></FontAwesomeIcon>
                                {sweet2 && <FontAwesomeIcon icon={faTimes} className="select-cross" onClick={() => {
                                    sweet2 ? setSweet(sweet + 2) : setSweet(sweet - 2)
                                    setSweet2(!sweet2)
                                }}></FontAwesomeIcon>}
                                <FontAwesomeIcon className="select-cup" icon={faCoffee} onClick={() => {
                                    sweet2 ? setSweet(sweet + 2) : setSweet(sweet - 2)
                                    setSweet2(!sweet2)
                                }}></FontAwesomeIcon>
                            </div>
                            <div>
                                {sweet3 && <FontAwesomeIcon icon={faTimes} className="select-cross" onClick={() => {
                                    sweet3 ? setSweet(sweet + 2) : setSweet(sweet - 2)
                                    setSweet3(!sweet3)
                                }}></FontAwesomeIcon>}
                                <FontAwesomeIcon className="select-cup" icon={faCoffee} onClick={() => {
                                    sweet3 ? setSweet(sweet + 2) : setSweet(sweet - 2)
                                    setSweet3(!sweet3)
                                }}></FontAwesomeIcon>
                                {sweet4 && <FontAwesomeIcon icon={faTimes} className="select-cross" onClick={() => {
                                    sweet4 ? setSweet(sweet + 2) : setSweet(sweet - 2)
                                    setSweet4(!sweet4)
                                }}></FontAwesomeIcon>}
                                <FontAwesomeIcon className="select-cup" icon={faCoffee} onClick={() => {
                                    sweet4 ? setSweet(sweet + 2) : setSweet(sweet - 2)
                                    setSweet4(!sweet4)
                                }}></FontAwesomeIcon>
                                {sweet5 && <FontAwesomeIcon icon={faTimes} className="select-cross" onClick={() => {
                                    sweet5 ? setSweet(sweet + 2) : setSweet(sweet - 2)
                                    setSweet5(!sweet5)
                                }}></FontAwesomeIcon>}
                                <FontAwesomeIcon className="select-cup" icon={faCoffee} onClick={() => {
                                    sweet5 ? setSweet(sweet + 2) : setSweet(sweet - 2)
                                    setSweet5(!sweet5)
                                }}></FontAwesomeIcon>
                            </div>
                            {/* <div className="comment-box">
                                <label>Comentário:</label>
                                <br />
                                <br />
                                <FarmlyInput textarea styleClass="farmly-input comments-cupping" value={cuppingData.sweetnessComment || ""} setValue={value => setCuppingData({ ...cuppingData, sweetnessComment: value })}></FarmlyInput>
                            </div> */}
                        </div>
                    </Paper>

                    {/* Uniformidade */}
                    <Paper elevation={3} style={{ margin: '4vw', borderRadius: '25px' }}>
                        <div style={{ padding: '15px', textAlign: 'center' }}>
                            <div className="producer-profile-item">
                                <label htmlFor="" className="producer-profile-label info-title">Uniformity</label>
                            </div>
                            <div>
                                {equal1 && <FontAwesomeIcon icon={faTimes} className="select-cross" onClick={() => {
                                    equal1 ? setEqualty(equalty + 2) : setEqualty(equalty - 2)
                                    setEqual1(!equal1)
                                }}></FontAwesomeIcon>}
                                <FontAwesomeIcon className="select-cup" icon={faCoffee} onClick={() => {
                                    equal1 ? setEqualty(equalty + 2) : setEqualty(equalty - 2)
                                    setEqual1(!equal1)
                                }}></FontAwesomeIcon>
                                {equal2 && <FontAwesomeIcon icon={faTimes} className="select-cross" onClick={() => {
                                    equal2 ? setEqualty(equalty + 2) : setEqualty(equalty - 2)
                                    setEqual2(!equal2)
                                }}></FontAwesomeIcon>}
                                <FontAwesomeIcon className="select-cup" icon={faCoffee} onClick={() => {
                                    equal2 ? setEqualty(equalty + 2) : setEqualty(equalty - 2)
                                    setEqual2(!equal2)
                                }}></FontAwesomeIcon>
                            </div>
                            <div>
                                {equal3 && <FontAwesomeIcon icon={faTimes} className="select-cross" onClick={() => {
                                    equal3 ? setEqualty(equalty + 2) : setEqualty(equalty - 2)
                                    setEqual3(!equal3)
                                }}></FontAwesomeIcon>}
                                <FontAwesomeIcon className="select-cup" icon={faCoffee} onClick={() => {
                                    equal3 ? setEqualty(equalty + 2) : setEqualty(equalty - 2)
                                    setEqual3(!equal3)
                                }}></FontAwesomeIcon>
                                {equal4 && <FontAwesomeIcon icon={faTimes} className="select-cross" onClick={() => {
                                    equal4 ? setEqualty(equalty + 2) : setEqualty(equalty - 2)
                                    setEqual4(!equal4)
                                }}></FontAwesomeIcon>}
                                <FontAwesomeIcon className="select-cup" icon={faCoffee} onClick={() => {
                                    equal4 ? setEqualty(equalty + 2) : setEqualty(equalty - 2)
                                    setEqual4(!equal4)
                                }}></FontAwesomeIcon>
                                {equal5 && <FontAwesomeIcon icon={faTimes} className="select-cross" onClick={() => {
                                    equal5 ? setEqualty(equalty + 2) : setEqualty(equalty - 2)
                                    setEqual5(!equal5)
                                }}></FontAwesomeIcon>}
                                <FontAwesomeIcon className="select-cup" icon={faCoffee} onClick={() => {
                                    equal5 ? setEqualty(equalty + 2) : setEqualty(equalty - 2)
                                    setEqual5(!equal5)
                                }}></FontAwesomeIcon>
                            </div>
                            {/* <div className="comment-box">
                                <label>Comentário:</label>
                                <br />
                                <br />
                                <FarmlyInput textarea styleClass="farmly-input comments-cupping" value={cuppingData.uniformityComment || ""} setValue={value => setCuppingData({ ...cuppingData, uniformityComment: value })}></FarmlyInput>
                            </div> */}
                        </div>
                    </Paper>

                    <Paper elevation={3} style={{ margin: '4vw', borderRadius: '25px' }}>
                        <div style={{ padding: '15px' }}>
                            <div className="producer-profile-item">
                                <label htmlFor="" className="producer-profile-label info-title">Overall</label>
                            </div>
                            <br />
                            <br />
                            <Grid container spacing={2} className="big-volume">
                                <Grid item xs>
                                    <Volume value={valueOverall} style={{ color: "rgb(27, 98, 94)" }} onChange={handleChangeOverall} marks={marks} min={6} max={10} valueLabelDisplay="on" orientation="vertical" aria-labelledby="continuous-slider" step={0.5} />
                                </Grid>
                            </Grid>
                            <div>
                                <label>Comment:</label>
                                <br />
                                <br />
                                <FarmlyInput textarea styleClass="farmly-input comments-cupping" value={cuppingData.overallComment || ""} setValue={value => setCuppingData({ ...cuppingData, overallComment: value })}></FarmlyInput>
                            </div>
                        </div>
                    </Paper>

                    {/* Defeitos */}
                    <Paper elevation={3} style={{ margin: '4vw', borderRadius: '25px', position: 'relative' }}>
                        <div style={{ padding: '15px', textAlign: 'center' }}>
                            <div className="producer-profile-item">
                                <label htmlFor="" className="producer-profile-label info-title">Defects</label>
                            </div>
                            <label className='defect-comment'><b>Taint</b> x2</label>
                            <Slider {...settings} style={{ margin: '8vw' }}>
                                <div className={defect0 ? 'selected-defect' : ''}>
                                    <h3 style={{ width: '45px' }} onClick={() => setDefect(0)}>0</h3>
                                </div>
                                <div className={defect1 ? 'selected-defect' : ''}>
                                    <h3 style={{ width: '45px' }} onClick={() => setDefect(2)}>1</h3>
                                </div>
                                <div className={defect2 ? 'selected-defect' : ''}>
                                    <h3 style={{ width: '45px' }} onClick={() => setDefect(4)}>2</h3>
                                </div>
                                <div className={defect3 ? 'selected-defect' : ''}>
                                    <h3 style={{ width: '45px' }} onClick={() => setDefect(6)}>3</h3>
                                </div>
                                <div className={defect4 ? 'selected-defect' : ''}>
                                    <h3 style={{ width: '45px' }} onClick={() => setDefect(8)}>4</h3>
                                </div>
                                <div className={defect5 ? 'selected-defect' : ''}>
                                    <h3 style={{ width: '45px' }} onClick={() => setDefect(10)}>5</h3>
                                </div>
                            </Slider>
                            <label className='defect-comment'><b>Fault</b> x4</label>
                            <Slider {...settings} style={{ margin: '8vw' }}>
                                <div className={bigDefect0 ? 'selected-defect' : ''}>
                                    <h3 style={{ width: '45px' }} onClick={() => setBigDefect(0)}>0</h3>
                                </div>
                                <div className={bigDefect1 ? 'selected-defect' : ''}>
                                    <h3 style={{ width: '45px' }} onClick={() => setBigDefect(4)}>1</h3>
                                </div>
                                <div className={bigDefect2 ? 'selected-defect' : ''}>
                                    <h3 style={{ width: '45px' }} onClick={() => setBigDefect(8)}>2</h3>
                                </div>
                                <div className={bigDefect3 ? 'selected-defect' : ''}>
                                    <h3 style={{ width: '45px' }} onClick={() => setBigDefect(12)}>3</h3>
                                </div>
                                <div className={bigDefect4 ? 'selected-defect' : ''}>
                                    <h3 style={{ width: '45px' }} onClick={() => setBigDefect(16)}>4</h3>
                                </div>
                                <div className={bigDefect5 ? 'selected-defect' : ''}>
                                    <h3 style={{ width: '45px' }} onClick={() => setBigDefect(20)}>5</h3>
                                </div>
                            </Slider>
                            {/* <div className="comment-box">
                                <label>Comentário:</label>
                                <br />
                                <br />
                                <FarmlyInput textarea styleClass="farmly-input comments-cupping" value={cuppingData.defectComment || ""} setValue={value => setCuppingData({ ...cuppingData, defectComment: value })}></FarmlyInput>
                            </div> */}
                        </div>
                    </Paper>
                    <Paper elevation={3} style={{ margin: '4vw', borderRadius: '25px', position: 'relative' }}>
                        <div style={{ padding: '15px', textAlign: 'center' }}>
                            <div className="producer-profile-item">
                                <label className="producer-profile-label info-title">How would you describe this coffee?</label>
                                <Select
                                    className="roaster-form-large"
                                    classNamePrefix="select"
                                    id="select-country"
                                    onChange={(e) => { setOpinion({ ...opinion, about: e.value }) }}
                                    options={renderOpts()}
                                >
                                </Select>
                            </div>
                            <div className="producer-profile-item">
                                <label className="producer-profile-label info-title">How much this coffee should be ? (€/kg)</label>
                                <div> 
                                    €<input className="min-value-input-exlusive-cupping" value={opinion.minValue} onChange={(e) => setOpinion({...opinion, minValue: e.target.value})}></input>
                                    to
                                    €<input className="min-value-input-exlusive-cupping" value={opinion.maxValue} onChange={(e) => setOpinion({...opinion, maxValue: e.target.value})}></input> 
                                </div>
                            </div>
                         
        
                            
                            <div className="comment-box">
                                <label>Comment:</label>
                                <br />
                                <br />
                                <FarmlyInput textarea styleClass="farmly-input comments-cupping" value={opinion.comment || ""} setValue={value => setOpinion({ ...opinion, comment: value })}></FarmlyInput>
                            </div>
                        </div>
                    </Paper>


                    <Paper elevation={3} className={classes.scorePaper}>
                        <div className="producer-profile-item" style={{ padding: '4vw', paddingTop: '18vw', textAlign: 'center' }}>
                            <div className='img-inf-dir1'></div>
                            <label htmlFor="" className="producer-profile-label" style={{ color: 'white', fontSize: '30px' }}>Score</label>
                            <label style={{ color: 'rgb(230, 179, 143)', fontSize: '90px', fontWeight: '500' }}> {scoreVar && !!parseFloat(score) ? parseFloat(score) : 0}</label>
                        </div>
                        <div style={{ textAlign: '-webkit-center' }}>
                            {/* <div>
                        <button className="score-paper-button-obs" onClick={createCupping} style={{ marginBottom: '8px' }}>
                            Observacoes
                        </button>
                    </div> */}
                            <div>
                                <button className="score-paper-button-score" onClick={() => handleResult(true)} style={{ marginBottom: '8px' }}>
                                    Save
                        </button>
                            </div>
                            <div className='img-inf-dir2'></div>
                            <div className='img-inf-esq'></div>
                        </div>
                    </Paper>
                </Fragment>
                :
                <Fragment>
                    <Paper className={classes.mainPaper}>
                        <div className="producer-profile-item" style={{ padding: '15vw', height: 'auto' }}>
                            <label htmlFor="" className="producer-profile-label" style={{ color: 'white', textAlign: 'center' }}>{coffee.producer}'s coffee</label>
                            <label style={{ color: 'rgb(230, 179, 143)', textAlign: 'center' }}>Code: {coffee.farmCode}</label>
                            <div style={{ textAlign: 'center' }}>
                                <label style={{ color: 'white', fontSize: '60px', fontWeight: 'bold' }}>{score}</label>
                                <label style={{ color: 'white', fontSize: '40px', fontWeight: 'bold' }}>pts</label>
                            </div>
                            <br />
                            <div className='sensory-cards'>
                                {renderSensoryCard()}
                            </div>
                            <br />
                            <br />
                            <label style={{ color: 'white', padding: '10px', textAlign: 'center' }}>{cuppingData.overallComment}</label>
                            <br />
                            <div>
                                <RadarChart attributes={chartAttributes} />
                            </div>

                        </div>
                        <div className='img-sup-dir'></div>
                    </Paper>
                    <Paper elevation={3} style={{ margin: '4vw', borderRadius: '25px' }}>
                        <div style={{ padding: '15px' }}>
                            <div className="producer-profile-item">
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', fontSize: '28px' }}>
                                    <label htmlFor="" className="producer-profile-label" style={{ color: 'rgb(102, 102, 102)' }}>Aroma: </label>
                                    <label htmlFor="" className="producer-profile-label" style={{ color: 'rgb(230, 179, 143)' }}> {cuppingData.aroma} </label>
                                </div>
                                <br />
                                <br />
                                <label htmlFor="" className="producer-profile-label" style={{ fontWeight: 'normal' }} >Comments:</label>
                                <label htmlFor="" className="producer-profile-label" style={{ fontWeight: 'lighter' }} > {cuppingData.aromaComment} </label>
                                <Divider variant="middle" style={{ height: '2px', backgroundColor: 'rgb(102, 102, 102)', marginLeft:'0px' }} />
                            </div>
                            <div className="producer-profile-item">
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', fontSize: '28px' }}>
                                    <label htmlFor="" className="producer-profile-label" style={{ color: 'rgb(102, 102, 102)' }}>Flavor: </label>
                                    <label htmlFor="" className="producer-profile-label" style={{ color: 'rgb(230, 179, 143)' }}> {cuppingData.flavor} </label>
                                </div>
                                <br />
                                <br />
                                <label htmlFor="" className="producer-profile-label" style={{ fontWeight: 'normal' }} >Comments:</label>
                                <label htmlFor="" className="producer-profile-label" style={{ fontWeight: 'lighter' }}  > {cuppingData.flavorComment}</label>
                                <Divider variant="middle" style={{ height: '2px', backgroundColor: 'rgb(102, 102, 102)' , marginLeft:'0px'}} />
                            </div>
                            <div className="producer-profile-item">
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', fontSize: '25px' }}>
                                    <label htmlFor="" className="producer-profile-label" style={{ color: 'rgb(102, 102, 102)' }}>After taste: </label>
                                    <label htmlFor="" className="producer-profile-label" style={{ color: 'rgb(230, 179, 143)' }}> {cuppingData.completion} </label>
                                </div>
                                <br />
                                <br />
                                <label htmlFor="" className="producer-profile-label" style={{ fontWeight: 'normal' }} >Comments:</label>
                                <label htmlFor="" className="producer-profile-label" style={{ fontWeight: 'lighter' }}  > {cuppingData.completionComment}</label>
                                <Divider variant="middle" style={{ height: '2px', backgroundColor: 'rgb(102, 102, 102)', marginLeft:'0px' }} />
                            </div>
                            <div className="producer-profile-item">
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', fontSize: '25px' }}>
                                    <label htmlFor="" className="producer-profile-label" style={{ color: 'rgb(102, 102, 102)' }}>Acidity: </label>
                                    <label htmlFor="" className="producer-profile-label" style={{ color: 'rgb(230, 179, 143)' }}> {cuppingData.acidity} </label>
                                </div>
                                <br />
                                <br />
                                <label htmlFor="" className="producer-profile-label" style={{ fontWeight: 'normal' }} >Comments:</label>
                                <label htmlFor="" className="producer-profile-label" style={{ fontWeight: 'lighter' }} > {cuppingData.acidityComment}</label>
                                <Divider variant="middle" style={{ height: '2px', backgroundColor: 'rgb(102, 102, 102)', marginLeft:'0px' }} />
                            </div>
                            <div className="producer-profile-item">
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', fontSize: '28px' }}>
                                    <label htmlFor="" className="producer-profile-label" style={{ color: 'rgb(102, 102, 102)' }}>Body:  </label>
                                    <label htmlFor="" className="producer-profile-label" style={{ color: 'rgb(230, 179, 143)' }}> {cuppingData.body} </label>
                                </div>
                                <br />
                                <br />
                                <label htmlFor="" className="producer-profile-label" style={{ fontWeight: 'normal' }} >Comments:</label>
                                <label htmlFor="" className="producer-profile-label" style={{ fontWeight: 'lighter' }}  > {cuppingData.bodyComment}</label>
                                <Divider variant="middle" style={{ height: '2px', backgroundColor: 'rgb(102, 102, 102)' , marginLeft:'0px'}} />
                            </div>
                            <div className="producer-profile-item">
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', fontSize: '28px' }}>
                                    <label htmlFor="" className="producer-profile-label" style={{ color: 'rgb(102, 102, 102)' }}>Balance: </label>
                                    <label htmlFor="" className="producer-profile-label" style={{ color: 'rgb(230, 179, 143)' }}>  {cuppingData.balance} </label>
                                </div>
                                <br />
                                <br />
                                <label htmlFor="" className="producer-profile-label" style={{ fontWeight: 'normal' }} >Comments:</label>
                                <label htmlFor="" className="producer-profile-label" style={{ fontWeight: 'lighter' }}  > {cuppingData.balanceComment}</label>
                                <Divider variant="middle" style={{ height: '2px', backgroundColor: 'rgb(102, 102, 102)', marginLeft:'0px' }} />
                            </div>
                            <div className="producer-profile-item">
                                <br />
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', fontSize: '20px' }}>
                                    <label htmlFor="" className="producer-profile-label" style={{ color: 'rgb(102, 102, 102)' }}>Clean Cup: </label>
                                    <label htmlFor="" className="producer-profile-label" style={{ color: 'rgb(230, 179, 143)' }}>  {(cleanCup / 2)*(-1)}/5 </label>
                                </div>
                                <br />
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', fontSize: '20px' }}>
                                    <label htmlFor="" className="producer-profile-label" style={{ color: 'rgb(102, 102, 102)' }}>Sweetness: </label>
                                    <label htmlFor="" className="producer-profile-label" style={{ color: 'rgb(230, 179, 143)' }}>  {(sweet / 2)*(-1)}/5 </label>
                                </div>
                                <br />
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', fontSize: '20px' }}>
                                    <label htmlFor="" className="producer-profile-label" style={{ color: 'rgb(102, 102, 102)' }}>Overall: </label>
                                    <label htmlFor="" className="producer-profile-label" style={{ color: 'rgb(230, 179, 143)' }}>  {(equalty / 2)*(-1)}/5 </label>
                                </div>
                                <br />
                                <Divider variant="middle" style={{ height: '2px', backgroundColor: 'rgb(102, 102, 102)' , marginLeft:'0px'}} />
                                <br />
                                <br />
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', fontSize: '20px' }}>
                                    <label htmlFor="" className="producer-profile-label" style={{ color: 'rgb(102, 102, 102)' }}>Taint defects: </label>
                                    <label htmlFor="" className="producer-profile-label" style={{ color: 'rgb(230, 179, 143)' }}>  {cuppingData.defects / 2}/5 </label>
                                </div>
                                <br />
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', fontSize: '20px' }}>
                                    <label htmlFor="" className="producer-profile-label" style={{ color: 'rgb(102, 102, 102)' }}>Fault defects: </label>
                                    <label htmlFor="" className="producer-profile-label" style={{ color: 'rgb(230, 179, 143)' }}>  {cuppingData.big_defect / 4}/5 </label>
                                </div>
                                <br />
                            </div>
                        </div>
                    </Paper>
                    <Paper elevation={3} className={classes.scorePaper}>
                        <br />
                        <br />
                        <div style={{ textAlign: '-webkit-center' }}>
                            <div>
                                <button className="score-paper-button-obs" onClick={() => handleReturn()} style={{ marginBottom: '8px' }}>
                                    Go back
                        </button>
                            </div>
                            <div>
                                <button className="score-paper-button-score" onClick={() => updateAll()} style={!cuppingDone ? { display: 'none' } : { marginBottom: '8px' }}>
                                    Finalize
                        </button>
                            </div>
                            <div className='img-inf-dir2'></div>
                            <div className='img-inf-esq'></div>
                        </div>
                    </Paper>
                </Fragment>
            }
        </div >
    )


}

export default CuppingExclusiveIndv