import React from "react";
import { CoffeeLineContainer, CoffeeInfo, CoffeeLink } from "./styles";
import CoffeesLineModel from '../../models/CoffeesLineModel'

interface CoffeesLineProps {
    coffee : CoffeesLineModel
}

export default function CoffeesLineLabels(props: CoffeesLineProps) {

    const { coffee } = props;

    return (
        <CoffeeLineContainer>
            <CoffeeInfo>
                {coffee.farmCode}
            </CoffeeInfo>
            <CoffeeInfo>
                {coffee.producer}
            </CoffeeInfo>
            <CoffeeInfo>
                {coffee.process}    
            </CoffeeInfo>
            <CoffeeInfo>
                {coffee.variety}  
            </CoffeeInfo>
            <CoffeeInfo>
                {coffee.height}  
            </CoffeeInfo>
            <CoffeeInfo>
                {coffee.region}
            </CoffeeInfo>
            <CoffeeInfo>
                {coffee.farmName}
            </CoffeeInfo>
            <CoffeeInfo>
                <CoffeeLink href={`https://farmlyclub.com/coffee/${coffee.id}`}> 
                    https://farmlyclub.com/coffee/{coffee.id}
                </CoffeeLink>    
            </CoffeeInfo>        
        </CoffeeLineContainer>
    );
    }
