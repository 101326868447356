import React, { useState, useEffect } from 'react'
import './RecoveryPage.css'

import Main from '../templates/Main'
import { server } from '../../common'
import axios from 'axios'
import { useHistory } from 'react-router'

const RecoveryPage = props => {

    const [valid, setValid] = useState(false)
    const [message, setMessage] = useState('One moment...')
    const [user, setUser] = useState({})
    const [password, setPassword] = useState('')
    let [passwordUpdate, setUpdate] = useState(false);
    const history = useHistory();

    useEffect(() => {
        axios.post(`${server}/verifyRecoveryToken`, {
            token: props.match.params.token
        })
            .then(res => {
                const { answer } = res.data

                if (answer === 'invalid') {
                    setMessage('Invalid link')
                }
                else if (answer === 'expired') {
                    setMessage('Expired link')
                } else if (answer === 'valid') {
                    setValid(true)
                    setUser({ ...res.data.user })
                }
            })
            .catch(err => {
                props.history.push('/')
            })
    }, [])

    const renderValidPage = () => (
        <div id="recovery-valid-container">
            <label id="recovery-page-title">Olá {`${user.name}`}!</label>
            <div id="recovery-password-container">
                <label htmlFor="recovery-password-input" id="recovery-password-label">
                    Digite sua nova senha
                </label>
                <input type="password" id="recovery-password-input"
                    value={password} onChange={e => setPassword(e.target.value)} />
            </div>
            <div id="recovery-password-submit" onClick={() => {
                if (!passwordUpdate){
                    axios.post(`${server}/updatePassword`, {
                        id: user.id,
                        token: props.match.params.token,
                        password
                    })
                    .then(res => {
                        document.querySelector('#recovery-password-container').style.display = 'none'
    
                        if(res.data.answer === 'success') {
                            document.querySelector('#recovery-page-title').innerHTML = 'Senha atualizada com sucesso!'
                            setUpdate(true);
                        }
                    })
                }
                else {
                    history.replace("/login");
                }
            }}>
                {passwordUpdate ? "Voltar ao site" : "Enviar"}
            </div>
        </div>
    )

    const renderInvalidPage = () => (
        <label>{message}</label>
    )

    return (
        <Main>
            {valid ? renderValidPage() : renderInvalidPage()}
        </Main>
    )
}

export default RecoveryPage