import React, { useState, useEffect } from 'react'
import axios from "axios";
import {server, language} from "../../../common";

// import { Tabs, Tab } from '@material-ui/core'

import '../../../styles/profile/components/UserFarmlyClubList.css'

const UserFarmlyClubList = props => {

    const {inviteCode} = props;

    const [partnersList,setPartnersList] = useState([]);

    useEffect(() =>{
        // console.log(props.inviteCode)
        axios.get(`${server}/getInvitedProducersList/`+inviteCode,
        ).then(partners=>{
            // console.log(partners.data)
            setPartnersList(partners.data)
        })
        .catch(err=>{
            console.log(err)
        })
    },[])


    return (
        <div className="new-profile user-farmly-club-list">
            <div className="user-farmly-club-list-title">
                {{
                    pt:"Lista de Parceiros",
                    es:"Lista de socios",
                    en:"Partners List",
                }[language]}
            </div>
                {partnersList.length > 0
                    ?
                    <ol className="new-profile user-farmly-club-tabs">
                        {partnersList.map(partner=><li>{partner.name}</li>)}
                    </ol>
                    :
                    <p>{{
                        pt:"Nenhum parceiro encontrado",
                        es:"No se han encontrado socios",
                        en:"No partner found",
                        
                    }[language]}</p>
                }
        </div>
    )

}

export default UserFarmlyClubList