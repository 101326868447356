import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt, faEnvelope, faPhoneAlt } from '@fortawesome/free-solid-svg-icons'

import './Footer.css'
import { isPt, farmlyTexts, isCo } from '../../common'
const footerArt = require('../../assets/footer-art.png')
const logo = require('../../assets/logo-white-text-green.png')

const mapsUrl = 'https://www.google.com/maps/place/Condom%C3%ADnio+do+Edif%C3%ADcio+Metropolitan+Office+-+R.+Dr.+Eurico+de+Aguiar,+888+-+Santa+Lucia,+Vit%C3%B3ria+-+ES,+29056-264/@-20.3045244,-40.3025723,17z/data=!3m1!4b1!4m5!3m4!1s0xb817c483cf84b5:0x2e9d20a482a8eb90!8m2!3d-20.3045244!4d-40.3003836'

const Footer = props => {

    const renderNotInSignup = () => (
        <div id="footer-links">
            <div className="footer-links-item">
                <div className="coffee-icon footer-coffee-icon" />
                <a href="/" className="footer-link">
                    {isPt() ? farmlyTexts.footerConcept_pt : farmlyTexts.footerConcept_en}
                </a>
            </div>
            <div className="footer-links-item">
                <div className="coffee-icon footer-coffee-icon" />
                <a href="/help" className="footer-link">
                    {isPt() ? farmlyTexts.footerContact_pt : farmlyTexts.footerContact_en}
                </a>
            </div>
        </div>
    )

    const renderOurOffice = () => (
        <div className="footer-contacts-item">
            <FontAwesomeIcon className='footer-contacts-icon' icon={faMapMarkerAlt} />
            <a className="footer-contacts-text" id="footer-address-link" href={mapsUrl}>Our office</a>
        </div>
    )

    return (
        <div id="footer-container">
            <img id='footer-img' src={footerArt} alt="" />
            <div id="footer-card">
                <img id='footer-logo' src={logo} alt="logo" />
                {renderNotInSignup()}
                <div id="footer-contacts">
                    {renderOurOffice()}
                    <div className="footer-contacts-item">
                        <FontAwesomeIcon className='footer-contacts-icon' icon={faEnvelope} />
                        <label className="footer-contacts-text">lfaria@farmlyclub.com</label>
                    </div>
                    <div className="footer-contacts-item">
                        <FontAwesomeIcon className='footer-contacts-icon' icon={faPhoneAlt} />
                        {isPt() ? 
                        <a target="_blank" rel="noopener noreferrer" href={`https://api.whatsapp.com/send?phone=+5535999432879`}>
                            <label className="footer-contacts-text">+55 35 99943-2879</label>
                        </a>
                        : isCo() ? 
                        <a target="_blank" rel="noopener noreferrer" href={`https://api.whatsapp.com/send?phone=+5531993173932`}>
                            <label className="footer-contacts-text">+55 31 99317-3932</label>
                        </a>
                        :
                        <a target="_blank" rel="noopener noreferrer" href={`https://api.whatsapp.com/send?phone=+5537991036677`}>
                            <label className="footer-contacts-text">+55 37 99103-6677</label>
                        </a>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer