import React, { useContext } from "react";

import { Button } from '@material-ui/core';
import { language, server } from "../../../common";
import "../../../styles/profile/components/UserTitle.css";
import "../../../styles/profile/components/UserDescription.css";
import UserContext from "../../../context/user/UserContext";
import { approveProducerDescription } from "../../../services/producer"
import axios from "axios";

const UserTitle = (props) => {

    const { isOwner, windowSize, user, editProfile } = props;

    const userContext = useContext(UserContext);

    const profileImg = user.imgs && user.imgs.filter(img => img.role === 'profile').length > 0 ? user.imgs && user.imgs.filter(img => img.role === 'profile')[0] : null

    const checkProducerDescription = () => {
        approveProducerDescription(user.id)
            .then(res => {
                document.location.reload(true);
            })
    }

    return (
        <div className={!profileImg && !isOwner ? "new-profile profile-title profile-title-center" : "new-profile profile-title"}>
            {windowSize && windowSize.w > 768 ? (
                <h2 className="new-profile profile-title-text">{user.name}</h2>
            ) : (
                <h4 className="new-profile profile-title-text">{user.name}</h4>
            )}
            {user.isProd === false ?
                <h5 className="new-profile profile-subtitle">{user.roastery}</h5>
                :
                <h5 className="new-profile profile-subtitle">{user.farmName}</h5>
            }
            {isOwner ?
                !user.description ? (
                    <p
                        className="new-profile profile-description profile-description-active"
                        onClick={isOwner && editProfile}
                    >
                        {user.isProd === false ?
                            {
                                pt: 'Clique aqui para escrever sua história para que os produtores possam te conhecer melhor.',
                                en: 'Click here to write your story so that the producers can get to know you better.',
                                es: 'Haga clic aquí para escribir su historia para que el los produtores pueden conocerte mejor.'
                            }[language]
                            :
                            {
                                pt: 'Clique aqui para escrever sua história para que os torrefadores possam te conhecer melhor.',
                                en: 'Click here to write your story so that the roasters can get to know you better.',
                                es: 'Haga clic aquí para escribir su historia para que el los tostadores pueden conocerte mejor.'
                            }[language]
                        }
                    </p>
                ) : (
                    user.isProd === false ?
                        <p className="new-profile profile-description">
                            {language === "en" ? user.description : user.descriptionPt}
                        </p>
                        :
                        <p className="new-profile profile-description">
                            {language === "en" ? user.descriptionEn : user.description}
                        </p>
                )
                :
                ""
            }
            {/* {
                (user.description !== null) && (
                    <div className="new-profile description-checked-box">
                        <Button className="new-profile description-checked"
                            onClick={() => checkProducerDescription()}
                            disabled={user.description_checked}>
                            Aprovar descrição
                        </Button>
                    </div>
                )
            }
            {
                (user.description !== null && user.description_checked) &&
                <div className="new-profile-description-checked-helper-text-box">
                    <span className="new-profile-description-checked-helper-text">
                        A descrição já foi validada.
                    </span>
                </div>
            } */}

        </div>
    );
};

export default UserTitle;
