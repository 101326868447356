import React, { useState, useEffect, useContext, Suspense } from 'react'
import Title from '../templates/Title.jsx'
import { Link, useHistory } from 'react-router-dom'
import { isPt, isCo, farmlyTexts, server, acceptedCookies } from '../../common'
import UserContext from '../../context/user/UserContext'
import { LoadingContext } from '../../context/loading/LoadingContext'
import SampleContext from '../../context/sample/SampleContext'
import CartContext from '../../context/cart/CartContext.js'
import axios from 'axios'
import './NewOfferList.css'
import FarmlyInput from '../templates/FarmlyInput.jsx'
import FarmlyModal from '../templates/FarmlyModal.jsx'
import { makeStyles } from '@material-ui/core/styles';
import ReactGA, { set } from 'react-ga'
import { GoogleLogin } from 'react-google-login'
import RequestSample from '../coffeeProfile/RequestSample'
import Select from 'react-select';
import Main from '../templates/Main.jsx'
import {
	toEuro,
	totalCoffeePrice,
	logisticsFee,
	importDuties,
	farmlyFee,
	producersPriceKg,
	simpleIRPJ_CSLL,
    simpleEuroProdKg,
    simpleFarmlyFee,
    simpleLogisticsFee,
    simpleImportDuties
} from '../templates/pricing.js'
import Cookies from 'js-cookie'
import backend from 'lib/backend'

const coFlag = require("../../assets/colombia.png");
const brFlag = require("../../assets/brazil.png");
const greenFriday = require("../../assets/png/greenfriday.png");

const NewOfferList = props => {

    const state = (props.location.state || null)
    const history = useHistory()

    const [coffees, setCoffees] = useState([])
    const userContext = useContext(UserContext)
    const loadingContext = useContext(LoadingContext);
    // const sampleContext = useContext(SampleContext)
    const cartContext = useContext(CartContext)
    const [userData, setUserData] = useState({
        email: props.location.state ? props.location.state.email : null
    })
    const [totalKgPrice, setTotalKgPrice] = useState("0.00")
    const [requestPopup, setRequestPopup] = useState(false)
    const [requestPopupSignUp, setRequestPopupSignUp] = useState(false)
    const [requestPopupFollowing, setRequestPopupFollowing] = useState(false)
    const [noSamplePopup, setNoSamplePopup] = useState(false)
    const [emailFailure, setEmailFailure] = useState(false)
    const [authFailure, setAuthFailure] = useState(false)
    const [serverFailure, setServerFailure] = useState(false)
    const [passwordDontMatch, setPasswordDontMatch] = useState(false)
    const [failureSignInGoogle, setModalSignin] = useState(false)
    const [samplesReload, setSamplesReload] = useState(false)
    const [signupGoogle, setSignupGoogle] = useState(false)
    const [pleaseTypePassword, setPleaseTypePassword] = useState(false)
    const [showModal, setShowModal] = useState(false);
    const [paymentData, setPaymentData] = useState({
        farmly_fee: 0,
        logistics_fee: 0,
        import_duties: 0,
        import_duties_adjustment: 0,
        euro_value: 0
    })
    const [coffeeAmt, setCoffeeAmt] = useState({
        sub80: '0',
        s80_82: '0',
        s82_84: '0',
        s84_86: '0',
        s86_88: '0',
        over88: '0'
    })
 
    const [shipmentInfo, setShipmentInfo] = useState({
        shipment: 0,
        day: "XX",
        month: "XX",
        year: "XXXX",
        weeksLeft: "X"
    })
    const [newerShipment, setNewerShipment] = useState({
        value: 0,
        departure_date: ""
    })
    const [olderShipment, setOlderShipment] = useState({
        value: 0,
        departure_date: ""
    })
    const [samplesArray, setSamplesArray] = useState([])
    const [shipmentSelected,setShipmentSelected] = useState(0);
    const greenFridayCoffees = []
    const toggleModal = () => {
        setShowModal(!showModal)
    }
 
    // Talevz nem precise mais
    useEffect(() => {
        coffees.map((coffee) => {
            if (coffee && paymentData) {
                const sackPrice = totalCoffeePrice({ ...coffee, quantity: '1' }, paymentData)
                setTotalKgPrice(isNaN(sackPrice) ? "0.00" : (sackPrice / 60).toFixed(2))
            }
        })
    }, [paymentData, coffees])


    const getCoffees = (shipment) => {
        if(shipmentInfo.shipment){
            const queryString = `${server}/coffeesByShipment/${shipmentInfo.shipment}`
            axios.get(queryString)
                .then(res => {
                    setCoffees(res.data.sort(compare).filter(c => c.producerId !== 1287))
                })
                .catch(_ => {
                })
        }
     
    }

    const [paymentShipmentData,setPaymentShipmentData] = useState([])
    const [defaultPaymentShipmentData,setDefaultPaymentShipmentData] = useState([])

    const getDisplayedShipments = async () => {
        loadingContext.setLoading(true);
        try {                    
            const res = await backend.get(`${server}/shipping`,{ params: { offerlist_display: true}})
            if(res.data.length > 0){
                setPaymentShipmentData(res.data)
            }
            const today = new Date()
            let day, month, year
            day = parseInt(res.data[1].departure_date.split('-')[2].substring(0, 2))
            month = parseInt(res.data[1].departure_date.split('-')[1])
            year = parseInt(res.data[1].departure_date.split('-')[0])
            const shipmentDate = new Date(year, month, day)
            day = parseInt(res.data[0].departure_date.split('-')[2].substring(0, 2))
            month = parseInt(res.data[0].departure_date.split('-')[1])
            year = parseInt(res.data[0].departure_date.split('-')[0])           
            const oldShipmentDate = new Date(year, month, day)
            const diffTime = Math.abs(shipmentDate - today)
            const diffWeeks = Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 7)) 
            const diffTimeOld = Math.abs(oldShipmentDate - today)
            const diffWeeksOld = Math.ceil(diffTimeOld / (1000 * 60 * 60 * 24 * 7))
            setShipmentInfo({...shipmentInfo,
                shipment: res.data[1].shipping_id,
                day: shipmentDate.getDate() < 10 ? "0" + shipmentDate.getDate() : shipmentDate.getDate(), 
                month: shipmentDate.getMonth() < 10 ? "0" + shipmentDate.getMonth() : shipmentDate.getMonth(),
                year: shipmentDate.getFullYear(),
                weeksLeft: diffWeeks
            })
            setNewerShipment({value: res.data[1].shipping_id, departure_date: shipmentDate, weeksLeft: diffWeeks})
            setOlderShipment({value: res.data[0].shipping_id, departure_date: oldShipmentDate, weeksLeft: diffWeeksOld})
            // getCoffees(res.data[1].shipping_id)   
            try{
                const defaultData = await backend.get(`${server}/shipping`,{ params: { shipping_id: 1}})
                setDefaultPaymentShipmentData(defaultData.data[0])
            } catch (error) {
                console.log(error);
            }                 
        } catch (error) {
            console.log(error);
            alert('Could not load shipments information!')
        } finally {
            loadingContext.setLoading(false);
        }
    }

    function compare(a, b) {
        const B = a.farmCode
        const A = b.farmCode

        let comparison = 0;
        if (A < B) {
            comparison = 1;
        } else if (A > B) {
            comparison = -1;
        }
        return comparison;
    }

    const signUp = async () => {
        let user = { ...userData, postalCode: "", address: "" }
        user.notfication_new = true
        user.coffeeScores = (
            `sub80:${coffeeAmt.sub80},` +
            `s80_82:${coffeeAmt.s80_82},` +
            `s82_84:${coffeeAmt.s82_84},` +
            `s84_86:${coffeeAmt.s84_86},` +
            `s86_88:${coffeeAmt.s86_88},` +
            `over88:${coffeeAmt.over88},`
        )
        // user.sensoryNotes = Object.values(sensoryNotes).reduce((prev, curr) => prev + curr)
        ReactGA.event({
            category: 'New roaster registered',
            action: 'New Roaster Signed Up'
        })
        if (user.confirmPassword === user.password) {
            delete user.confirmPassword
            delete user.invitedBy
            try {
                const payload = await axios.post(`${server}/signup/0`, {
                    ...user
                })


                userContext.userLogin({
                    isProd: payload.data.isProd,
                    jwt: payload.data.jwt,
                    id: payload.data.id
                })
                const cookie = acceptedCookies() ?
                    document.cookie : ""
                if (cookie.includes('cartList') && !cartContext.cookiesCartIsEmpty) {
                    cartContext.setCartToServer(payload.data.jwt)
                }

                if (acceptedCookies()) {
                    Cookies.set('jwt', payload.data.jwt, {expires: payload.data.exp, path: '/'})
                    Cookies.set('isProd', payload.data.isProd, {expires: payload.data.exp, path: '/'})
                    Cookies.set('userId', payload.data.id, {expires: payload.data.exp, path: '/'})
                    Cookies.set('main_id', payload.data.main_id, {expires: payload.data.exp, path: '/'})
                }

                setRequestPopupFollowing(true)
                setRequestPopupSignUp(false)
                setPasswordDontMatch(false)
            } catch (err) {
                console.log(err)
            }
        }
        else setPasswordDontMatch(true)

    }

    const login = () => {
        axios.post(`${server}/signin`, {
            email: userData.email,
            password: userData.password
        })
            .then(payload => {
                const cookie = acceptedCookies() ?
                    document.cookie : ""
                userContext.userLogin({
                    isProd: payload.data.isProd,
                    jwt: payload.data.jwt,
                    id: payload.data.id
                })
                if (cookie.includes('cartList') && !cartContext.cookiesCartIsEmpty) {
                    cartContext.setCartToServer(payload.data.jwt)
                } else if (!payload.data.isProd) {
                    cartContext.getCartFromServer(payload.data.id)
                }

                ReactGA.event({
                    category: 'Login',
                    action: 'User Logged In'
                })
                if (acceptedCookies()) {
                    Cookies.set('jwt', payload.data.jwt, {expires: payload.data.exp, path: '/'})
                    Cookies.set('isProd', payload.data.isProd, {expires: payload.data.exp, path: '/'})
                    Cookies.set('userId', payload.data.id, {expires: payload.data.exp, path: '/'})
                    Cookies.set('main_id', payload.data.main_id, {expires: payload.data.exp, path: '/'})
                }
                if (!payload.data.isProd) {
                    axios.get(`${server}/roaster/${payload.data.id}`)
                        .then(roaster => {
                            setUserData(roaster.data)
                            setRequestPopup(false)
                            setRequestPopupFollowing(true)
                        })
                } else {
                    setRequestPopup(false)
                    window.location.reload()
                }

            })
            .catch(err => {
                const status = err.response ? err.response.data.status : 'server-failure'
                if (status === 'email-not-found') {
                    setEmailFailure(true)
                } else if (status === 'auth-failed' || status === 'incomplete') {
                    setAuthFailure(true)
                } else if (status === 'server-failure') {
                    setServerFailure(true)
                }
            })
    }

    const googleLogin = (email, name, password) => {
        setEmailFailure(false)
        setAuthFailure(false)

        if (password === '') {
            setPleaseTypePassword(true)
            return
        }
        axios.post(`${server}/signin`, {
            email: email,
            password: password
        })
            .then(payload => {
                const cookie = acceptedCookies() ?
                    document.cookie : ""
                userContext.userLogin({
                    isProd: payload.data.isProd,
                    jwt: payload.data.jwt,
                    id: payload.data.id
                })
                if (cookie.includes('cartList') && !cartContext.cookiesCartIsEmpty) {
                    cartContext.setCartToServer(payload.data.jwt)
                } else if (!payload.data.isProd) {
                    cartContext.getCartFromServer(payload.data.id)
                }

                ReactGA.event({
                    category: 'Login',
                    action: 'User Logged In'
                })

                if (acceptedCookies()) {
                    Cookies.set('jwt', payload.data.jwt, {expires: payload.data.exp, path: '/'})
                    Cookies.set('isProd', payload.data.isProd, {expires: payload.data.exp, path: '/'})
                    Cookies.set('userId', payload.data.id, {expires: payload.data.exp, path: '/'})
                    Cookies.set('main_id', payload.data.main_id, {expires: payload.data.exp, path: '/'})
                }
                let next = null
                if (typeof state === "string") next = state
                history.push({
                    pathname: next || (payload.data.isProd ? '/profile' : '/coffees'),
                    state: { coffeeSection: true }
                })
                history.go()
            })
            .catch(err => {
                const status = err.response ? err.response.data.status : 'server-failure'

                if (status === 'email-not-found') {
                    setModalSignin(true)
                    if (setSignupGoogle) {
                        history.push({
                            pathname: '/signupGoogle',
                            state: {
                                userData: {
                                    email: email,
                                    name: name,
                                    password: password,
                                    confirmPassword: password

                                }
                            }
                        })
                        history.go()
                    }
                } else if (status === 'auth-failed' || status === 'incomplete') {
                    setAuthFailure(true)
                    setModalSignin(true)
                } else if (status === 'server-failure') {
                    setServerFailure(true)

                }
            })
    }

    const responseGoogle = (response) => {
        googleLogin(response.profileObj.email, response.profileObj.name, response.profileObj.googleId)
    }

    useEffect(() => {
        getDisplayedShipments()
    }, [])

    
    useEffect(() => {
        getCoffees()
    }, [shipmentInfo])

    const triggerRequest = () => {
        setRequestPopupFollowing(!requestPopupFollowing)
    }
    
    // Definir preço     
    const totalCoffeePriceBr = (coffee) =>{
        if(paymentShipmentData){
        const selectedShipmemt = shipmentSelected == 0 ? paymentShipmentData[1] : paymentShipmentData[0];
        
        if(selectedShipmemt.price_br==null){
            selectedShipmemt.price_br=5
        } 
        if(selectedShipmemt.farmly_fee==null) 
        {selectedShipmemt.farmly_fee=20
        } 
        if(selectedShipmemt.import_duties==null) 
        {selectedShipmemt.import_duties=1.15
        }  else if(!coffee.farmlyFee) {
            coffee.farmlyFee = selectedShipmemt.farmly_fee  
        } 
        if(selectedShipmemt.logistics_br==null){selectedShipmemt.logistics_br=defaultPaymentShipmentData.logistics_br}
        if(selectedShipmemt.storage==null){selectedShipmemt.storage=defaultPaymentShipmentData.storage}
        const sack = parseFloat(coffee.sackPrice)
        const farmlyFee = coffee.farmly_fee ? coffee.farmly_fee : selectedShipmemt.farmly_fee
        const producersPriceKg = parseFloat((sack/60).toFixed(2));
        const eurProdKg = simpleEuroProdKg(producersPriceKg, selectedShipmemt.price_br)
        const IRPJ_CSLL = simpleIRPJ_CSLL(sack, selectedShipmemt.price_br); // pensar nisso, aqui esta pafrao com 0.0308
        const farmly_fee = simpleFarmlyFee(eurProdKg, IRPJ_CSLL, farmlyFee) 
        const import_duties = simpleImportDuties(eurProdKg) // Também confirmar isso
        const logisticFee = selectedShipmemt.logistics_br;
        const armazenamento = selectedShipmemt.storage;
        const euroPriceKg = parseFloat(eurProdKg)+IRPJ_CSLL+parseFloat(farmly_fee)+import_duties+logisticFee+armazenamento;
        return (euroPriceKg.toFixed(2));
        }
    }

    const totalCoffeePriceCo = (coffee) =>{
        if(paymentShipmentData) {
            if(coffee.producer == "Elias Bayter"){            
                return ((totalCoffeePrice({ ...coffee, quantity: '1' }, paymentData)) / 70).toFixed(2);
            }else{
                const selectedShipmemt = shipmentSelected == 0 ? paymentShipmentData[1] : paymentShipmentData[0];
                if(selectedShipmemt.price_co==null){selectedShipmemt.price_co=defaultPaymentShipmentData.price_co}
                if(selectedShipmemt.farmly_fee==null) 
                {selectedShipmemt.farmly_fee=20
                } 
                if(selectedShipmemt.import_duties==null) 
                {selectedShipmemt.farmly_fee=1.15
                } 
    
                else if(coffee.farmlyFee != null || coffee.farmlyFee != 0) {
                selectedShipmemt.farmly_fee = coffee.farmlyFee 
                }             
                if(selectedShipmemt.import_duties==null){selectedShipmemt.import_duties=defaultPaymentShipmentData.import_duties}
                if(selectedShipmemt.logistics_co==null){selectedShipmemt.logistics_co=defaultPaymentShipmentData.logistics_co}
                if(selectedShipmemt.storage==null){selectedShipmemt.storage=defaultPaymentShipmentData.storage}
                const sack = parseFloat(coffee.sackPrice)
                const producersPriceKg = parseFloat((sack/70).toFixed(20));
                // const eurProdKg = (producersPriceKg/paymentShipmentDatas.price_co).toFixed(2);
                // const farmlyfeeConstant = parseFloat(paymentShipmentDatas.farmly_fee) / 100
                // const importDutiesConstant = parseFloat(paymentShipmentDatas.import_duties)
                const farmly_fee = simpleFarmlyFee(producersPriceKg, 0, paymentShipmentData.farmly_fee)
                const import_duties = simpleImportDuties(producersPriceKg); // Também confirmar isso
                const logisticFee = selectedShipmemt.logistics_co;
                const armazenamento = selectedShipmemt.storage;
                const euroPriceKg = producersPriceKg + parseFloat(farmly_fee) + import_duties + logisticFee + armazenamento
                return (euroPriceKg.toFixed(2));
            }
        }
    }

    const renderCoffeeSampleButtons = coffee => {
            if(userContext.user && !userContext.user.isProd){
                return (
                    !samplesArray.filter(item => item === coffee.id).length ?
                    <button className="new-offerlist-add-sample-button" onClick={() => samplesArray.length < 5 ? addToList(coffee.id) : setShowModal(!showModal)}>Add Sample</button>
                    :
                    <button className="new-offerlist-add-sample-button-added" onClick={() => removeFromList(coffee.id)}>Remove</button>
                )
            } else if(!userContext.user && !isPt() && !isCo()){
                return (
                    !samplesArray.filter(item => item === coffee.id).length ?
                    <button className="new-offerlist-add-sample-button" onClick={() => samplesArray.length < 5 ? addToList(coffee.id) : setShowModal(!showModal)}>Add Sample</button>
                    :
                    <button className="new-offerlist-add-sample-button-added" onClick={() => removeFromList(coffee.id)}>Remove</button>
                )
            }
        }
    

    const renderCoffeeCells = coffees =>
        coffees.map((coffee) => {
            return (
                <div className="new-offerlist-table-cell">
                    <div className={samplesArray.filter(item => item === coffee.id).length ? "new-offerlist-table-labels-added" : "new-offerlist-table-labels"}>
                        {samplesArray}
                        <label className="new-offerlist-table-label">
                            <div>{coffee.farmCode}</div>
                            {greenFridayCoffees.includes(coffee.farmCode) && <img className="offerlist-green-friday" src={greenFriday}/>}
                        </label>
                        <img className="offerlist-country-flag" src={coffee.origin == "Brazil" ? brFlag : coFlag}></img>
                        <label className="new-offerlist-table-label">{coffee.producer}</label>
                        <label className="new-offerlist-table-label">{coffee.variety}</label>
                        <label className="new-offerlist-table-label">{coffee.process}</label>
                        <label className="new-offerlist-table-label">{coffee.sackRemain}</label>
                        <label className="new-offerlist-table-label">€ {coffee.origin == "Brazil" ? totalCoffeePriceBr({ ...coffee, quantity: '1' }) : totalCoffeePriceCo({ ...coffee, quantity: '1' })}</label>
                        <label className="new-offerlist-table-label">{showSensoryNotes(coffee.sensoryNotes)}</label>
                        <div className="new-offerlist-table-cell-buttons">
                        <a href={`/coffee/${coffee.id}`} ><button className="new-offerlist-visit-coffee-button" onClick={() => { sendToCoffee(coffee.id) }}>Visit Coffee</button></a>                            
                        {renderCoffeeSampleButtons(coffee)}
                        </div>
                    </div>
                    
                    {coffee.id != coffees[coffees.length - 1].id && <div className="new-offerlist-white-line" />}
                </div>
            )
        })



  
    //connectFarmlyBubbleApiToFarmlyOfferList().then((dat) => dat)

    const showSensoryNotes = notes =>
        notes.split(',').map((note) => {
            return (
                <label className="new-offerlist-table-sensory-note">{note}, </label>
            )
        })

    const sendToCoffee = id => {
        history.push({
            pathname: `/coffee/${id}`
        })
    }

    const addToList = (id) => {
        let aux = samplesArray
        aux.push(id)
        setSamplesArray(aux)
        setSamplesReload(!samplesReload)
    }

    const removeFromList = (id) => {
        setSamplesArray(samplesArray.filter(item => item != id))
    }

    const decideWitchModal = () => {
        if (userContext.user) setRequestPopupFollowing(true)
        else setRequestPopup(true)
        // setNoSamplePopup(true)
    }

    const renderCoffeeCellsMobile = coffees =>
        coffees.map((coffee) => {
            return (
                <div className="new-offerlist-table-cell-mobile">
                    <div className={samplesArray.filter(item => item === coffee.id).length ? "new-offerlist-table-labels-added-mobile" : "new-offerlist-table-labels-mobile"}>
                        <label className="new-offerlist-table-label-mobile">
                            <div>{coffee.farmCode}</div>
                            {greenFridayCoffees.includes(coffee.farmCode) && <img className="offerlist-green-friday" src={greenFriday}/>}
                        </label>
                        <img className="offerlist-country-flag" src={coffee.origin == "Brazil" ? brFlag : coFlag}></img>
                        <label className="new-offerlist-table-label-mobile">{coffee.producer}</label>
                        <label className="new-offerlist-table-label-mobile">{coffee.variety}</label>
                        <label className="new-offerlist-table-label-mobile">{coffee.process}</label>
                        <label className="new-offerlist-table-label-mobile">{coffee.sackRemain} bags</label>
                        <label className="new-offerlist-table-label-mobile">€ {coffee.origin == "Brazil" ? totalCoffeePriceBr({ ...coffee, quantity: '1' }) : totalCoffeePriceCo({ ...coffee, quantity: '1' })}</label>
                        <label className="new-offerlist-table-label-mobile">{showSensoryNotes(coffee.sensoryNotes)}</label>
                        <div className="new-offerlist-table-cell-buttons">
                            <button className="new-offerlist-visit-coffee-button" onClick={() => { sendToCoffee(coffee.id) }}>Visit Coffee</button>
                            {!samplesArray.filter(item => item === coffee.id).length ?
                                <button className="new-offerlist-add-sample-button" onClick={() => samplesArray.length < 5 ? addToList(coffee.id) : setShowModal(!showModal)} style={shipmentInfo.shipment === olderShipment.value ? { display: 'none' } : {}}>Add Sample</button>
                                :
                                <button className="new-offerlist-add-sample-button-added" onClick={() => removeFromList(coffee.id)}>Remove</button>
                            }
                        </div>
                    </div>
                    {coffee.id !== coffees[coffees.length - 1].id && <div className="new-offerlist-white-line" />}
                </div>
            )
        })
    
    const changeShipment = (value) => {

        if(value === newerShipment.value){
            setShipmentSelected(0);
            setShipmentInfo({
                ...shipmentInfo, 
                shipment: value,
                day: newerShipment.departure_date.getDate() < 10 ? "0" + newerShipment.departure_date.getDate(): newerShipment.departure_date.getDate(),
                month: newerShipment.departure_date.getMonth(),
                year: newerShipment.departure_date.getFullYear(),
                weeksLeft: newerShipment.weeksLeft
            })
        } else {
            setShipmentSelected(1);
            setShipmentInfo({
                ...shipmentInfo, 
                shipment: value,
                day: olderShipment.departure_date.getDate() < 10 ? "0" + olderShipment.departure_date.getDate(): olderShipment.departure_date.getDate(),
                month: olderShipment.departure_date.getMonth(),
                year: olderShipment.departure_date.getFullYear(),
                weeksLeft: olderShipment.weeksLeft
            })
        }
    }

  
    
    return (
        
        <>
            {/* <iframe src='https://farmly-offerlist.bubbleapps.io/version-test/offer_colombia' style={{position:"fixed", top:"0", left:"0", bottom:"0", right:"0", width:"100%", height:"100%", border:"none", margin:"0", padding:"0", overflow:"hidden", zIndex:"999999"}} allowFullScreen="true"></iframe> */}
            {requestPopup &&
                <FarmlyModal closeModal={() => { setRequestPopup(false) }} className="modal-sample">
                    <div className="modal-landing-page">
                        <label id="request-title">
                            To proceed with your request, log in to our plataform!
                        </label>
                        <br />
                        <br />
                        <div className="producer-profile-row-center">
                            <div className="producer-profile-item">
                                <label htmlFor="" className="producer-profile-label" >Email: </label>
                                <FarmlyInput value={userData.email} setValue={value => setUserData({ ...userData, email: value })}
                                    styleClass="input-farmly new-offerlist-input" elementId="email" placeholder="email..." />
                            </div>
                        </div>
                        {emailFailure && <label className="login-fail-label"> Email not found.</label>}
                        {serverFailure && <label className="login-fail-label">Server error. Please, try again in a few moments, or send us a message describing the problem.</label>}
                        <div className="producer-profile-row-center">
                            <div className="producer-profile-item">
                                <label htmlFor="" className="producer-profile-label" >Password: </label>
                                <FarmlyInput value={userData.password} type='password' setValue={value => setUserData({ ...userData, password: value })}
                                    styleClass="input-farmly new-offerlist-input" elementId="password" placeholder="password..." />
                            </div>
                        </div>
                        {authFailure && <label className="login-fail-label"> Incorrect email or password.</label>}
                        <div className="new-offerlist-button-group">
                            <button className="login-button" onClick={() => login()}> Login</button>
                            <div className="signup-field-label">{isPt() ? 'Ou' : (isCo() ? 'O' : 'Or')}</div>
                            <div class="login-button-google" >
                                <GoogleLogin
                                    clientId="503177513332-b2g42k0864opttrsn7v6v8e2o3imvse5.apps.googleusercontent.com"
                                    buttonText={isPt() ? 'Entrar com Google' : (isCo() ? `Registro en Google` : "Log in with Google")}
                                    onSuccess={responseGoogle}
                                    onFailure={responseGoogle}
                                    cookiePolicy={'single_host_origin'}
                                />
                            </div>
                        </div>
                        <br />
                        <br />
                        <div>
                            <label id='new-account-label' >
                                New here?
                            </label>
                            <Link id='new-account-link' onClick={() => { setRequestPopupSignUp(true); setRequestPopup(false) }}>
                                Sign up!
                            </Link>
                        </div>
                    </div>
                </FarmlyModal>}
            {requestPopupSignUp &&
                <FarmlyModal closeModal={() => { setRequestPopupSignUp(false) }} className="modal-sample">
                    <div className="modal-landing-page">
                        <label id="request-title">
                            Fill all the fields below and sign up to proceed!
                        </label>
                        <div className="producer-profile-row" >
                            <div className="producer-profile-item">
                                <label htmlFor="" className="producer-profile-label" >Email: </label>
                                <FarmlyInput value={userData.email} setValue={value => setUserData({ ...userData, email: value })}
                                    styleClass="input-farmly-signin" elementId="email" placeholder="email..." />
                            </div>
                            <div className="producer-profile-item-with-margin">
                                <label htmlFor="" className="producer-profile-label" >Name: </label>
                                <FarmlyInput value={userData.name} setValue={value => setUserData({ ...userData, name: value })}
                                    styleClass="input-farmly-signin" elementId="name" placeholder="name..." />
                            </div>
                        </div>
                        <div className="producer-profile-row" >
                            <div className="producer-profile-item">
                                <label htmlFor="" className="producer-profile-label" >Password: </label>
                                <FarmlyInput value={userData.password} type='password' setValue={value => setUserData({ ...userData, password: value })}
                                    styleClass="input-farmly-signin" elementId="password" placeholder="password..." />
                            </div>
                            <div className="producer-profile-item-with-margin">
                                <label htmlFor="" className="producer-profile-label" >Confirm password: </label>
                                <FarmlyInput value={userData.confirmPassword} type='password' setValue={value => setUserData({ ...userData, confirmPassword: value })}
                                    styleClass="input-farmly-signin" elementId="confirmPassword" placeholder="confirm..." />
                            </div>
                        </div>
                        {passwordDontMatch && <label className="login-fail-label"> The password's fields have to match.</label>}
                        <div className="producer-profile-row" >
                            <div className="producer-profile-item">
                                <label htmlFor="" className="producer-profile-label" >Country: </label>
                                <FarmlyInput value={userData.country} setValue={value => setUserData({ ...userData, country: value })}
                                    styleClass="input-farmly-signin" elementId="country" placeholder="country..." />
                            </div>
                            <div className="producer-profile-item-with-margin">
                                <label htmlFor="" className="producer-profile-label">Phone Number: </label>
                                <FarmlyInput value={userData.phoneNumber} setValue={value => setUserData({ ...userData, phoneNumber: value })}
                                    styleClass="input-farmly-signin" elementId="phoneNumber" placeholder="phone..." />
                            </div>
                        </div>
                        <div className="sign-up-button">
                            <button className="register-button-samples" onClick={() => signUp()}> Sign Up</button>
                        </div>
                        <div className="signup-field-label">{isPt() ? 'Ou' : (isCo() ? 'O' : 'Or')}</div>
                        <div class="login-button-google">
                            <GoogleLogin
                                clientId="503177513332-b2g42k0864opttrsn7v6v8e2o3imvse5.apps.googleusercontent.com"
                                buttonText={isPt() ? 'Entrar com Google' : (isCo() ? `Registro en Google` : "Log in with Google")}
                                onSuccess={responseGoogle}
                                onFailure={responseGoogle}
                                cookiePolicy={'single_host_origin'}
                            />
                        </div>
                    </div>
                </FarmlyModal>}
            {failureSignInGoogle &&
                <FarmlyModal closeModal={() => setModalSignin(false)}> <div>
                    <label className="modal-label-email-error">
                        {isPt() ? 'Email não cadastrado. ' : (isCo() ? `Correo electrónico no registrado` : 'Email not registered')}
                    </label>
                    <button className="modalGoogle-button" onClick={() => setSignupGoogle(true)}>
                        {isPt() ? 'Cadastrar-se com esse email' : (isCo() ? `Inscríbase con este correo electrónico ` : 'Sign up with this email')}
                    </button>
                    <label className="signup-field-label">{isPt() ? 'Ou' : (isCo() ? 'O' : 'Or')}</label>
                    <button className="modalGoogle-button" onClick={() => setModalSignin(false)}>
                        {isPt() ? 'Tentar com outro email' : (isCo() ? `Inténtelo con otro correo electrónico ` : 'Try with another email')}
                    </button>

                </div>
                </FarmlyModal>}
            {requestPopupFollowing && <RequestSample closeModal={() => setRequestPopupFollowing(false)} samplesArray={samplesArray} />}
            {noSamplePopup &&
                <FarmlyModal closeModal={() => { setNoSamplePopup(false) }}>
                    <label>The shipping of the samples from this section of coffees has already ended.</label>
                    <br />
                </FarmlyModal>
            }
            <Main titleHeader="Offerlist">
                <div className="new-offerlist-container">
                    <div className="new-offerlist-title-container">
                        <p className="new-offerlist-title-main">Let us know what samples you would like to try!</p>
                        <p className="new-offerlist-title-sub"> You will receive up to 5 samples + <b className="new-offerlist-title-sub-b" >samples shipment for free.</b> </p>
                        <label className="new-offerlist-shipment-select-label">Search coffees by shipment date</label>
                        {loadingContext.loading === false && newerShipment.departure_date &&
                            <Select
                                className="new-offerlist-shipment-select"
                                classNamePrefix="select"
                                id="select-shipment"
                                placeholder={`${shipmentInfo.day}/${shipmentInfo.month}/${shipmentInfo.year}`}
                                onChange={(e) => { changeShipment(e.value) }}
                                options={[{ value: newerShipment.value, label: `${newerShipment.departure_date.getDate() < 10 ? "0" + newerShipment.departure_date.getDate():  newerShipment.departure_date.getDate() }/${newerShipment.departure_date.getMonth() < 10 ? "0" + newerShipment.departure_date.getMonth() : newerShipment.departure_date.getMonth() }/${newerShipment.departure_date.getFullYear()}` }, { value: olderShipment.value, label: `${olderShipment.departure_date.getDate() < 10 ? "0" + olderShipment.departure_date.getDate():   olderShipment.departure_date.getDate() }/${olderShipment.departure_date.getMonth() < 10 ? "0" + olderShipment.departure_date.getMonth() : olderShipment.departure_date.getMonth() }/${olderShipment.departure_date.getFullYear()}` }]}
                            >
                            </Select>
                        }
                     
                        <br />
                        <br />
                    </div>
                    {window.screen.width > 500 ?
                        <div className="new-offerlist-table-container">
                            <div className="new-offerlist-table-info">
                                <div className="new-offerlist-table-main-info">
                                    <p>The sample order for this shipment will end at</p>
                                    <p>{`${shipmentInfo.day}/${shipmentInfo.month}/${shipmentInfo.year}`}</p>
                                    <p className="new-offerlist-sub-info">( {`${shipmentInfo.weeksLeft} week${shipmentInfo.weeksLeft > 1 ? 's' : ''} before shipment of confirmed purchases`})</p>
                                </div>
                                <div className="new-offerlist-table-info-samples">
                                    {false && <div className="new-offerlist-table-info-samples-number">Added samples: {samplesArray.length}/5</div>}
                                    {(samplesArray.length > 0 && samplesArray.length < 6) && <button className="new-offerlist-table-info-samples-button" onClick={() => decideWitchModal()}> Request Samples</button>}
                                </div>
                            </div>
                            <div className="new-offerlist-table-cell">
                                <div className="new-offerlist-table-labels">
                                    <label className="new-offerlist-table-label-title">Code</label>
                                    <label className="new-offerlist-table-label-title">Country</label>
                                    <label className="new-offerlist-table-label-title">Producer</label>
                                    <label className="new-offerlist-table-label-title">Variety</label>
                                    <label className="new-offerlist-table-label-title">Process</label>
                                    <label className="new-offerlist-table-label-title">Bags</label>
                                    <label className="new-offerlist-table-label-title">Price/Kg</label>
                                    <label className="new-offerlist-table-label-title">Sensory Notes</label>
                                    <label className="new-offerlist-table-label-title" style={{ color: 'rgb(27, 98, 94)' }}>Information</label>
                                </div>
                                <div className="new-offerlist-white-line" />
                            </div>
                            {coffees && renderCoffeeCells(coffees)}
                        </div>
                        :
                        <div className="new-offerlist-table-container">
                            <div className="new-offerlist-table-info">
                                <div className="new-offerlist-table-main-info">
                                    <p>The sample order for this shipment will end at</p>
                                    <p>{`${shipmentInfo.day}/${shipmentInfo.month}/2022`}</p>
                                    <p className="new-offerlist-sub-info">( {`${shipmentInfo.weeksLeft} week${shipmentInfo.weeksLeft > 1 ? 's' : ''} before shipment of confirmed purchases`})</p>
                                </div>
                                <div className="new-offerlist-table-info-samples">
                                    <div className="new-offerlist-table-info-samples-number">Added samples: {samplesArray.length}/5</div>
                                    {(samplesArray.length > 0 && samplesArray.length < 6) && <button className="new-offerlist-table-info-samples-button" onClick={() => decideWitchModal()}> Request Samples</button>}
                                </div>
                            </div>
                            <div className="new-offerlist-table-cells-mobile">
                                {coffees && renderCoffeeCellsMobile(coffees)}
                            </div>
                        </div>
                    }
                    <br />
                    {showModal && <FarmlyModal id="page-modal" closeModal={toggleModal}>{isPt() ? "Limite de amostras para pedir atingido!" : "Limit of samples to order reached!"}</FarmlyModal>}
                </div>

            </Main>
        </>

    )
}
export default NewOfferList