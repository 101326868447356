import React, { Fragment, useState, useEffect } from "react";
import SliderCupping from "react-slick";
import { server } from '../../common'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import CuppingIndv from './CuppingIndv.jsx'
import FarmlyModal from '../templates/FarmlyModal'
import FarmlyInput from '../templates/FarmlyInput'
import Title from '../templates/Title'
import './Cupping.css'
import FloatingButton from '../templates/FloatingButton'
import MainCupping from '../templates/MainCupping'
import NavCupping from '../templates/NavCupping'
import CuppingIndvDesktop from "./CuppingIndvDesktop";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faReply, faShare, faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons'
import { RotateRight } from "@material-ui/icons";
import ReactGA from 'react-ga'


const roletaCompleta = require('../../assets/roleta-completa.svg')

const Cupping = (props) => {

    const coffeesCupping = props.location.state.cuppingCoffees
    const cupping_id = props.location.state.cupping_id
    const [sensoryNote, setSensoryNote] = useState()
    const [sensoryList, setSensoryList] = useState()
    const [currentCoffee, setCurrentCoffee] = useState(0)
    const [notesModal, setNotesModal] = useState(false)
    const [cuppingDone, setCuppingDone] = useState(false)
    const [checkCuppings, setCheckCuppings] = useState(false)
    const [fillAllSpaces, setFillAllSpaces] = useState(false)
    const [goToResults, setGoToResults] = useState({status: false, coffeeId: null})
    const [auxSensoryList, setAuxSensoryList] = useState(false)
    const [init, setInit] = useState(true)
    const history = useHistory()

    useEffect(() => {
        let aux = []
        let aux2 = 0
        coffeesCupping.forEach((coffee) => {
            aux2++
            axios.get(`${server}/getAllCuppings/${coffee.coffee_id}`)
                .then(res => {
                    if (res.data.length && res.data[0].cupping_done != true) aux.push(res.data[0])
                    if (aux2 == coffeesCupping.length) {
                        if (aux.length == 0) {
                            setCuppingDone(true)
                        }
                        else setCuppingDone(false)
                    }
                })
        })
    }, [checkCuppings])

    // useEffect(() => {
    //     coffeesCupping.forEach((coffee) => {
    //         axios.get(`${server}/getAllCuppings/${coffee.id}`)
    //             .then(res => {
    //                 if (!res.data.length) {
    //                     let newCupping = {
    //                         coffee_id: coffee.id,
    //                         user_id: 2000,
    //                         aroma: '6',
    //                         flavor: '6',
    //                         completion: '6',
    //                         acidity: '6',
    //                         body: '6',
    //                         balance: '6',
    //                         defects: '0',
    //                         big_defect: '0',
    //                         overall: '0',
    //                         score: 76
    //                     }
    //                     axios.post(`${server}/createCupping`, newCupping)
    //                 }
    //             })
    //     })
    // }, [])


    // useEffect(() => {
    //     if(init) {
    //         createCuppings()
    //     }
        
    // }, [])

    // const createCuppings = () => {
    //     coffeesCupping.forEach((coffee) => {
    //         let newCupping = {
    //             coffee_id: coffee.coffee_id,
    //             user_id: coffee.user_id,
    //             aroma: '6',
    //             flavor: '6',
    //             completion: '6',
    //             acidity: '6',
    //             body: '6',
    //             balance: '6',
    //             defects: '0',
    //             big_defect: '0',
    //             overall: '0',
    //             score: 76,
    //             isFarmly: false,
    //             cupping_id: cupping_id
    //         }
    //         setInit(false)
    //         axios.post(`${server}/createCupping`, newCupping)
    //     })
    // }

    const renderCuppingIndvs = () =>
        coffeesCupping.map((coffee, index) => {
            return <CuppingIndv coffee={coffee} sensoryList={sensoryList} cuppingDone={cuppingDone} checkCuppings={checkCuppings} setCheckCuppings={setCheckCuppings} updateAll={updateAll} setFillAllSpaces={setFillAllSpaces} goToResults={goToResults} auxSensoryList={auxSensoryList} notesModal={notesModal} cupping_id={cupping_id}/>
        })

    const renderCuppingIndvsDesktop1 = () =>
        coffeesCupping.map((coffee, index) => {
            return <CuppingIndvDesktop coffee={coffee} sensoryList={sensoryList} cuppingDone={cuppingDone} checkCuppings={checkCuppings} setCheckCuppings={setCheckCuppings} updateAll={updateAll} setFillAllSpaces={setFillAllSpaces} goToResults={goToResults} auxSensoryList={auxSensoryList} notesModal={notesModal} cupping_id={cupping_id}/>
        })

    const setNotesModalFunction = () => {
        axios.post(`${server}/getCuppingCoffee`, { user_id: coffeesCupping[currentCoffee].user_id, coffee_id: coffeesCupping[currentCoffee].coffee_id})
            .then(res => {
                if (res.data.sensoryNotes !== null && res.data.sensoryNotes !== "") setSensoryList(res.data.sensoryNotes.split(','))
                else setSensoryList([])
                setNotesModal(true)
            })
    }

    const addToSensoryList = async () => {
        let aux = [...sensoryList]
        aux.push(sensoryNote)
        await setSensoryList(aux)
        setSensoryNote("")
    }

    const addSensoryNote = () => {
        axios.post(`${server}/getCuppingCoffee`, { user_id: coffeesCupping[currentCoffee].user_id, coffee_id: coffeesCupping[currentCoffee].coffee_id})
            .then(async res => {
                const reqBody = {
                    ...res.data,
                    sensoryNotes: sensoryList.join(',')
                }
                try {
                    await axios.patch(`${server}/updateCuppingCoffee`, reqBody)
                    setSensoryNote([null])
                    setNotesModal(false)
                } catch (err) {
                    console.log(err)
                } finally {
                    setAuxSensoryList(!auxSensoryList)
                }
            })
        // setAuxSensoryList(!auxSensoryList)
    }

    const updateAll = () => {
        let aux = 0
        coffeesCupping.forEach((coffee) => {
            if(coffee.user_id !== 2000) {
                history.push('/cuppingSelect')
            }
            aux++
            axios.get(`${server}/coffee/${coffee.id}`)
                .then(res => {
                    const reqBody = {
                        ...res.data
                    }
                    axios.get(`${server}/getMainCupping/${coffee.id}`)
                        .then(res => {
                            reqBody.aroma = res.data.aroma = null ? 0 : res.data.aroma
                            reqBody.acidity = res.data.acidity = null ? 0 : res.data.acidity
                            reqBody.aftertaste = res.data.completion = null ? 0 : res.data.completion
                            reqBody.body = res.data.body = null ? 0 : res.data.body
                            reqBody.balance = res.data.balance = null ? 0 : res.data.balance
                            reqBody.flavour = res.data.flavor = null ? 0 : res.data.flavor
                            reqBody.sampleApprove = "approved"
                            try {
                                axios.patch(`${server}/coffees`, reqBody)
                                if (aux == coffeesCupping.length) {
                                    history.push('/cuppingSelect')
                                }
                            } catch (err) {
                                console.log(err)
                            }
                        })

                })
        })
    }

    const renderSensoryCard = () =>
        sensoryList.map((note) => {
            let random = Math.floor(Math.random() * 3)
            return (
                <Fragment>
                    <div className={(random == 0) ? 'sensory-red' : ((random == 1 ? 'sensory-lime' : 'sensory-orange'))}>{note}</div>
                &nbsp;
                    <br />
                </Fragment>
            )
        })

    const info = {
        dots: false,
        infinite: true,
        draggable: true,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
    };
    const settingsCupping = {
        arrows: false,
        dots: true,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        focusOnSelect: true,
        pauseOnHover: true,
        // beforeChange: (current, next) => this.setState({ activeSlide: next }),
        afterChange: current => {
            let dot_width = document.getElementsByClassName('slick-active')[document.getElementsByClassName('slick-active').length-1].offsetWidth;
            document.getElementsByClassName('slick-dots')[0].scroll({left: (current)*dot_width, top: 0, behavior: 'smooth'})
            setCurrentCoffee(current)
        },
        customPaging: function (i) {
            return (
                <a className="each-dot">
                    <div>Café:{coffeesCupping[i].coffee_id}</div>
                </a>
            );
        },

    };

    const settingsCuppingDesktop = {
        dots: true,
        infinite: false,
        draggable: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        focusOnSelect: true,
        pauseOnHover: true,
        arrows: true,
        dotsClass: 'slick-dots-desktop',
        // beforeChange: (current, next) => this.setState({ activeSlide: next }),
        afterChange: current => {
            // scrollDot(current);
            setCurrentCoffee(current)
        },
        customPaging: function (i) {
            return (
                <a className="each-dot-desktop" style={{ margin: '2px' }}>
                    Café:{coffeesCupping[i].coffee_id}
                </a>
            );
        },
    };

    var current_rotation = 0;

    const rotateRightWheel = () =>{
        current_rotation += 30;
        console.log(current_rotation);
	    document.getElementById("rotate-wheel").style.transform = 'rotate(' + current_rotation + 'deg)';
    }

    const rotateLeftWheel = () =>{
        current_rotation -= 30;
        console.log(current_rotation);
	    document.getElementById("rotate-wheel").style.transform = 'rotate(' + current_rotation + 'deg)';
    }

    //REPORTS ANALYTICS
    useEffect(() => {
        ReactGA.event({
            category: 'Cupping',
            action: `A user visited the Cupping Page`
        })
    }, [])


    return (
        <>
            {fillAllSpaces && <FarmlyModal closeModal={() => setFillAllSpaces(false)} style={{ maxHeight: 300, maxWidth: 400 }}>
                <div className='cupping-modal-wrap'>
                    <label><b>Você não preencheu todos o campos!</b></label>
                    <br />
                    <label>Alguns campos não foram preenchidos! Você gostaria de continuar mesmo assim ou gostaria de voltar para revisar o cupping?</label>
                    <br />
                    <br />
                    <div className="buttons-display-cupping-desktop">
                        <button className="go-back-button" onClick={() => setFillAllSpaces(false)}>Voltar</button>
                        <button className="go-foward-button" onClick={() => setGoToResults({status: true, coffeeId: coffeesCupping[currentCoffee].coffee_id })}>Gerar resultado</button>
                    </div>
                </div>
            </FarmlyModal>
            }
            {notesModal && 
                <div className="black-background-modal-cupping">
                    <button className="notes-button" onClick={()=>{
                        addSensoryNote()
                        setNotesModal(false)}}>
                            <FontAwesomeIcon icon={faArrowLeft}/>
                    </button>
                    <FontAwesomeIcon icon={window.screen.width>500 ? faShare : faArrowUp}  className="rotate-right-wheel-button" onClick={()=>rotateRightWheel()}/>
                    <img src={roletaCompleta} id="rotate-wheel" className="roleta-completa-sensory-notes-modal"></img>
                    <FontAwesomeIcon icon={window.screen.width>500 ? faReply : faArrowDown} className="rotate-left-wheel-button" onClick = {()=>rotateLeftWheel()}/>
                    <div className="add-sensory-notes-div">
                        <div className="sensory-note-input-container">
                            <input className="input-add-sensory-notes" value={sensoryNote} onChange={(event)=>setSensoryNote(event.target.value)} placeholder="sensory note you would like to add"></input>
                            <button className="notes-button-add" onClick={()=>addToSensoryList()}>+</button>
                        </div>
                        <div className='sensory-cards-main'>
                            {renderSensoryCard()}
                        </div>
                    </div>
                </div>
            }

            {window.screen.width > 768 ?
                <MainCupping noBanner style={{overflowY: 'hidden'}}>
                    <div className="cupping-holder">
                        <div style={{ display: 'flex', flexDirection: 'row', overflowY: 'hidden' }}>
                            <label style={{ fontSize: 'xxx-large', fontWeight: '600', paddingLeft: '100px' }}>Cupping</label>
                            <FloatingButton setNotesModalFunction={setNotesModalFunction} />
                        </div>
                        <SliderCupping {...settingsCuppingDesktop}>
                            {renderCuppingIndvsDesktop1()}
                        </SliderCupping>
                    </div>
                </MainCupping>
                :
                <MainCupping noBanner>
                    <div className="indv-container" >
                        <div className="cupping-holder">
                            <FloatingButton setNotesModalFunction={setNotesModalFunction} />
                            <SliderCupping {...settingsCupping}>
                                {renderCuppingIndvs()}
                            </SliderCupping>
                        </div>
                    </div>
                </MainCupping>
            }
        </>
    );
}

export default Cupping 