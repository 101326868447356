import React, { useState, useEffect } from 'react'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import ReactGA from 'react-ga'
import './ThankYou.css'
import { isPt, isCo } from '../../common'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ThankYou = props => {
    const { firstName, isProd, inviteLink, state } = props

    const [dest, setDest] = useState(state)
    // useEffect(() => {
    //     setDest(state)
    //     console.log(state);
    // }, [state])

    const welcomeTextProd_pt = 'Seja bem-vindo(a) a FARMly! Enviamos para você um email de confirmação.'
    const welcomeTextProd_co = '¡Bienvenido a FARMly! Te enviamos un email de confirmación.'
    const welcomeTextProd_en = 'You are signed up to FARMly. We just sent you a confirmation email.'

    const welcomeTextRoaster_pt = 'Você está cadastrado(a) na FARMly, bem vindo(a)!'
    const welcomeTextRoaster_en = 'You are signed up to FARMly, welcome!'


    const welcomeText = (
        isProd ?
            isPt() ?
                welcomeTextProd_pt :
                (isCo() ?
                    welcomeTextProd_co :
                    welcomeTextProd_en) :
            isPt() ?
                welcomeTextRoaster_pt :
                welcomeTextRoaster_en
    )

    const onCopyLinkClicked = () => {
        const hover = document.querySelector('.copied-hover-signup')
        hover.style.opacity = 1
        setTimeout(() => {
            hover.style.opacity = 0
        }, 2000)
        const a = document.getElementById("link-copy");
        a.focus()
        a.select()
        document.execCommand("copy");
    }

    const inviteString = 'https://farmlyclub.com/ReceivedLink?invite=' + inviteLink

    //REPORTS ANALYTICS
    useEffect(() => {
        ReactGA.event({
            category: 'SignUp - Thank You',
            action: `Visited the SignUp Thank You Page`
        })
    }, [])

    const clickGoToProfile = () => {
        ReactGA.event({
            category: 'SignUp - Thank You',
            action: 'User sign up and clicked "go to profile" at "thank you" page'
        })
    }

    const clickWhatsappButton = () => {
        ReactGA.event({
            category: 'SignUp - Thank You',
            action: `New producer clicked wpp button at farmlyclub invite link`
        })
    }
    const clickCopyInviteButton = () => {
        ReactGA.event({
            category: 'SignUp - Thank You',
            action: `New producer clicked to copy farmlyclub invite link`
        })
    }

    const setFinalDestination = () => {
        if (isProd) { //if it is a productor account, it can't return to cartScreen
            if (dest === '/cart') setDest('/profile')
        }
    }

    const renderInvite = () => {
        setFinalDestination()
        return (
            <>
                <label id="thank-you-label">{isCo() ? 
                    'Gana con Farmly'
                :
                    'Ganhe junto com a Farmly'
                }
                </label>
                <label id="new-thank-you-sublabel">{isCo() ? 
                    'Cuando un productor cierra una venta en el Club FARMly, FARMly se compromete a pagar (de nuestro bolsillo) el equivalente al 0,5% del valor de esa venta a la persona que remitió a ese productor. Envíe la invitación ahora por Whatsapp, ¡simplemente haga clic en el icono de abajo!'
                :
                    'Quando um produtor fecha uma venda no FARMly Club, a FARMly se compromete a pagar (do nosso próprio bolso) o equivalente a 0,5% do valor dessa venda para quem indicou esse produtor. Envie o convite agora por Whatsapp, basta clicar no ícone abaixo!'
                }
                </label>
                <div className="rowflex-new-thank-you">
                    <p className="invite-link" onClick={() => onCopyLinkClicked()}>
                        https://farmlyclub.com/ReceivedLink?invite={inviteLink}
                        <div className="invite-copy-signup" onClick={()=>{clickCopyInviteButton()}} >
                            <FontAwesomeIcon className="copy-icon-signup" icon={faCopy} />
                            <label className="copied-hover-signup">
                                Copiado
                            </label>
                        </div>
                    </p>

                    {isCo() ?
                        <a target="_blank" rel="noopener noreferrer" className="whatsapp-share-thank-you" href={`https://api.whatsapp.com/send?text=Utilice+el+link+de+invitacion+de+${firstName}+para+unirse+a+su+red+de+socios+productores+en+FARMly!+https://www.farmlyclub.com/ReceivedLink?invite=${inviteLink}`}
                        onClick={()=> clickWhatsappButton()}>
                            <FontAwesomeIcon className="whatsapp-icon-thank-you" icon={faWhatsapp} />
                            <span>Comparte tu link de Whatsapp</span> 
                        </a>
                    : <a target="_blank" rel="noopener noreferrer" className="whatsapp-share-thank-you" href={`https://api.whatsapp.com/send?text=Use+o+link+de+convite+de+${firstName}+para+entrar+para+sua+rede+de+produtores+parceiros+na+FARMly!+https://www.farmlyclub.com/ReceivedLink?invite=${inviteLink}`}
                        onClick={()=> clickWhatsappButton()}>
                            <FontAwesomeIcon className="whatsapp-icon-thank-you" icon={faWhatsapp} />
                            <span>Compartilhe seu link no Whatsapp</span> 
                        </a>}
                </div>
                <textarea id="link-copy" style={{ zIndex: -1 }} value={inviteString} readOnly />

            </>
        )
    }

    return (
        <div id="signup-thank-you">
            <label id="thank-you-label">
                {isPt() ? `Obrigado ${firstName}!` : (isCo() ? `Gracias ${firstName}!` : `Thank you ${firstName}!`)}
            </label>
            <label id="new-thank-you-sublabel">{welcomeText}</label>
            {isProd && renderInvite(firstName)}
            <Link id='back-to-home-link-thank-you' to={dest} replace onClick={()=> clickGoToProfile()}>
                {(dest == "/cart") ? 'Go to Cart' : ((dest == "/cuppingLive") ? 'Go to cupping' : (isPt() ? 'Visite seu perfil' : (isCo() ? 'Ir al perfil' : 'Go to Profile')))}
            </Link>
        </div>
    )
}

export default ThankYou

