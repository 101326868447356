import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { server } from '../../common'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faClipboardCheck, faCoffee, faRedo } from '@fortawesome/free-solid-svg-icons'
import { farmlyColors } from '../../common.js'
import { Link } from 'react-router-dom'
import './CuppingCards.css'

const footerArt = require('../../assets/footer-art.png')

const AdminCuppingCard = props => {

    const hover = true;

    const {
        image,
        coffeeId,
        coffee,
        farmName,
        variety,
        farmHeight,
        process,
        region,
        score,
        producer,
        sensoryNotes,
        sampleMessage,
        sampleSentDate,
        sampleReceivedDate,
        sampleTastedDate,
        harvestDate,
        sackPrice,
        display,
        width,
        height,
        coffees,
        setCoffees,
        updateFunction,
        updateDouble,
        checkParam,
        doubleCheckParam,
        toggleModal,
        sampleApproved,
        position,
        farmCode,
        description,
        forceCuppingUpdate
    } = props

    const [isChecked, setIsChecked] = useState(checkParam)
    const [isCheckedDouble, setIsCheckedDouble] = useState(doubleCheckParam)
    const [isApproved, setIsApproved] = useState(sampleApproved)
    const [cuppingResult, setCuppingResult] = useState()

    useEffect(() => {
        axios.get(`${server}/getMainCupping/${coffeeId}`)
            .then(res => {
                setCuppingResult({ ...res.data })
            })
        if(coffee.sampleTastedDate == 'not-tasted'){
            setIsChecked(false)
        }else{
            setIsChecked(true)
        }
    }, [])


    const toggleCheck = () => {
        //marca o café e muda ele de lugar
            if(coffee.sampleTastedDate == 'not-tasted'){
                setIsChecked(false)
            }else{
                setIsChecked(true)
            }
        console.log("help", isChecked);
        updateFunction(coffees, setCoffees, coffee)
    }

    const forceCupping = () =>{
        forceCuppingUpdate(coffees, setCoffees, coffee)
    }

    const showModal = () => {
        toggleModal(coffeeId)
    }

    const toggleCheckDouble = () => {
        setIsCheckedDouble(!isCheckedDouble)
        updateDouble(coffees, setCoffees, coffee)
    }

    const toggleApproved = () => {
        setIsApproved("approved")
        updateDouble(coffees, setCoffees, coffee)
    }

    const toggleNotApproved = () => {
        setIsApproved("not-approved")
        updateDouble(coffees, setCoffees, coffee)
    }

    const getStatus = () => {
        if (coffee.sampleReceivedDate == 'not-received') {
            return ('Pendente')
        } else if (coffee.sampleTastedDate == 'not-tasted') {
            return ('Recebido')
        } else if (coffee.sampleApprove == 'approved') {
            return ('Provado')
        } else return ('Provando')
    }

    return (
        <div className='coffee-card' style={{ backgroundColor: farmlyColors.desertSand, width: width, height: height, overflow: 'hidden' }}>
            <img id='coffee-card-footer' style={{
                bottom: hover ? '35%' : '0%'
            }} src={footerArt} alt='' />
            <img id='coffee-card-image' style={{
                opacity: hover ? '0' : '1',
                width: '100%'
            }} src={image} alt='' />
            {(getStatus() == 'Provado') &&
                <Link to={window.screen.width> 768 ? { pathname: '/cuppingIndvResultDesktop', state: { farmCode, sensoryNotes: sensoryNotes, description, producer, cupping: cuppingResult } }:{ pathname: '/cuppingIndvResult', state: { farmCode, sensoryNotes: sensoryNotes, description, producer, cupping: cuppingResult } }}
                    className="make-greeting" style={{ width: '30px', height: '30px' }}>
                    <FontAwesomeIcon icon={faClipboardCheck}
                        style={{ width: '30px', height: '30px' }}
                    />
                </Link>
            }
            <span className="coffee-card-label" style={{ position: 'absolute', margin: '10px' }}>Code: {farmCode}</span>
            {(checkParam != undefined) &&
            < div className="tooltip2">
                <FontAwesomeIcon className="check-icon-card" icon={position == 1 ? faCheck : position == 4 ? faRedo : faCoffee}
                    style={coffee.sampleTastedDate !== 'not-tasted' ? { color: '1A1', width: '30px', height: '30px' } : { width: '30px', height: '30px', color: '#d3d3d3' }}
                    onClick={position == 3 ? forceCupping : toggleCheck} />
                    <span className="tooltip2text">{position == 4 ? 'Refazer':'Cupping'}</span>
                </div>
                }
            {(doubleCheckParam != undefined) && < div className="tooltip"><FontAwesomeIcon className="check-icon-card-double-cupping" icon={faCheck}
                style={isCheckedDouble ? { color: '1A1', width: '30px', height: '30px' } : { width: '30px', height: '30px' }}
                onClick={position == 3 ? toggleCheck : toggleCheckDouble} />
                <span className="tooltiptext">{position == 3 ?'marcar recebido':'desmarcar recebido'}</span></div>}
            <div id="underlay" style={{
                height: hover ? '55%' : '0%', zIndex: 0
            }} />
            <Link to={{ pathname: `/coffee/${coffeeId}`, fromProfile: false }} id="coffee-card-info-container" style={{
                height: hover ? '75%' : '23%', zIndex: 1, backgroundColor: 'white'
            }} >
                {/* (sampleTastedDate && sampleTastedDate != "not-tasted") ? '#AEA' : (sampleReceivedDate && sampleReceivedDate != "not-received") ? '#EDA' : (sampleSentDate && sampleSentDate != "not-sent") ? '#EAA' : 'white' */}
                <div id="coffee-card-info-labels" className="admin-coffee-infobox">
                    <label className="coffee-card-label">Produtor:</label>
                    <label className="coffee-card-info admin-coffee-card-info">{producer}</label>
                    <label className="coffee-card-label">Fazenda:</label>
                    <label className="coffee-card-info admin-coffee-card-info">{farmName}</label>
                    <label className="coffee-card-label">Lote:</label>
                    <label className="coffee-card-info admin-coffee-card-info">{harvestDate}</label>
                    <label className="coffee-card-info admin-coffee-card-info">{variety} - {score}</label>
                    {display != undefined && <label className="coffee-card-label">Sensorial:</label>}
                    {display != undefined && <label className="coffee-card-info admin-coffee-card-info">{sensoryNotes}</label>}
                    {display != undefined && <label className="coffee-card-label">Preço:</label>}
                    {display != undefined && <label className="coffee-card-info admin-coffee-card-info">{sackPrice}</label>}
                    <div style={{ marginTop: 'auto' }}>
                        <label className="coffee-card-label">Status: </label>
                        <label className={`coffee-card-${getStatus()}`}>{getStatus()}</label>
                    </div>
                </div>
            </Link>
        </div>
    )

}

export default AdminCuppingCard