import React, { useState, useEffect, Fragment } from 'react'
import Main from './Main.jsx'
import ReactGA from 'react-ga'
import './ThanksForSigning.css'
import FarmlyModal from './FarmlyModal.jsx'
import { Link, useHistory } from 'react-router-dom'
import axios from 'axios'
import { server } from '../../common'


const ThanksForSigning = props => {
    const { email } = props
    const history = useHistory()
    const [resendPopUp, setResendPopUp] = useState(false)

    const analyticsInfo = () => {
            ReactGA.event({
                category: 'User clicked "go to profile',
                action: 'User sign up and clicked "go to profile" at "thank you" page'
            })
    }

    const reSend = () => {
        setResendPopUp(true)
        axios.post(`${server}/resend`, { email: email })
    }

    return (
        <Fragment>
            {resendPopUp && <FarmlyModal closeModal={() => { setResendPopUp(false) }} className="modal-sample">
                    <div className="modal-landing-page">
                        <label id="request-title">
                            Email resent!
                        </label>
                        <br />
                        <br />
                        <label id="request-content">
                            Still having trouble? Contact us at comercial@farmlyclub.com
                        </label>
                    </div>
                </FarmlyModal>}
            <Main>
                
                <div id="signup-thank-you">
                    <label id="thank-you-label">
                        Thanks for registering!
                        
                    </label>
                    <label id="thank-you-sublabel" style={{textAlign: 'center'}}>We just sent a confirmation email to: {email}
                    <br/>
                    <br/>
                    Please, check your email box and stay tuned!
                    Further information will be sent by email too!</label>
                    <br/>
                    <label id="thank-you-sublabel">Didn't receive any email?</label>
                    <br/>
                    <button className="resend-button" onClick={reSend}>Resend email</button>
                </div>
            </Main>
        </Fragment>
    )
}

export default ThanksForSigning

