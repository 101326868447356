import React from 'react'
import './CategoryPicker.css'
import ReactGA from 'react-ga'
import { isPt, farmlyTexts } from '../../../common'
import { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'

const producerImage = require('../../../assets/producerSelect.jpg')
const roasterImage = require('../../../assets/roasterSelect.jpg')

const CategoryPicker = props => {

    const { next, prev, setIsProd, userData } = props
    const history = useHistory()
    
    useEffect(() => {
        window.scrollTo(0, 0)
        console.log(userData)
    }, [])

    const goBack = () => {
        history.push({
            pathname: '/login'
        })
        history.go()
    }

    return (
        <div id="category-picker">
            <div className="signup-title-row">
                <div className="coffee-icon" />
                <label className="signup-title">{isPt() ? farmlyTexts.signupCategoryTitle_pt : farmlyTexts.signupCategoryTitle_en}</label>
            </div>
            <div id="picker-images" className="signup-row">
                <div className="picker-item" onClick={() => {
                    setIsProd(true)
                    next(true)
                    ReactGA.event({
                        category: 'Producer category selected',
                        action: 'New user selected producer category'
                    })
                }} >
                    <label className="category-name">{isPt() ? farmlyTexts.signupProducer_pt : farmlyTexts.signupProducer_en}</label>
                    <div className="picker-image" id="producer" alt="producer" />
                </div>
                <label id='picker-or-label'>{isPt() ? farmlyTexts.signupOr_pt : farmlyTexts.signupOr_en}</label>
                <div className="picker-item" onClick={() => {
                    setIsProd(false)
                    next(false)
                    ReactGA.event({
                        category: 'Roaster category selected',
                        action: 'New user selected roaster category'
                    })
                }} >
                    <label className="category-name">{isPt() ? farmlyTexts.signupRoaster_pt : farmlyTexts.signupRoaster_en}</label>
                    <div className="picker-image" id="roaster" alt="roaster" />
                </div>
            </div>
            <div className="signup-row basic-bottom">
                <div className="picker-back-button" onClick={goBack}>
                    {isPt() ? farmlyTexts.signupGoBack_pt : farmlyTexts.signupGoBack_en}
                </div>
            </div>
        </div>
    )
}

export default CategoryPicker