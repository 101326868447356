import React, { useEffect, useState } from "react";
import Main from '../templates/Main.jsx'
import "./ShipmentManage.css"
import axios from 'axios'
import { server } from '../../common.js'
import FarmlyInput from '../templates/FarmlyInput.jsx'

const ShipmentManage = props => {

    const [shipments, setShipments] = useState([]);
    const [shipmentsChanged, setShipmentsChanged] = useState([]);
    useEffect(()=>{
        axios.get(`${server}/getShippingData`)
            .then((res)=>{
                setShipments(res.data.sort((a, b)=>{return b.shipping_id - a.shipping_id}))
                setShipmentsChanged(res.data.sort((a, b)=>{return b.shipping_id - a.shipping_id}))
            })

    },[])

    const handleChange = (e)=>{
        const value = e.target.value;
        const data = shipmentsChanged.filter((shipment) =>{
            return shipment.shipping_id == e.target.name.split("/")[1]
        }) 

        if(e.target.name.split("/")[0] == "departure_date")   {data[0].departure_date = value;}
        // if(e.target.name.split("/")[0] == "origin")           {data[0].origin = value;}
        if(e.target.name.split("/")[0] == "farmly_fee")       {data[0].farmly_fee = value;}
        if(e.target.name.split("/")[0] == "import_duties")    {data[0].import_duties = value;}
        if(e.target.name.split("/")[0] == "storage")          {data[0].storage = value;}
        if(e.target.name.split("/")[0] == "logistics_br")     {data[0].logistics_br = value;}
        if(e.target.name.split("/")[0] == "logistics_co")     {data[0].logistics_co = value;}
        if(e.target.name.split("/")[0] == "price_br")         {data[0].price_br = value;}
        if(e.target.name.split("/")[0] == "price_co")         {data[0].price_co = value;}
        if(e.target.name.split("/")[0] == "offerlist_display"){data[0].offerlist_display = data[0].offerlist_display==true?false:true}
        console.log(data)
        const shipmentIndex = shipmentsChanged.length - data[0].shipping_id;
        const dataAux = shipmentsChanged
        dataAux[shipmentIndex] = data[0] // o zerin do daatAUx
        setShipmentsChanged(dataAux);
        console.log(shipmentsChanged)
    }
    
    const handleClick = (e, action) => {
        let data
        let isValidOfferlist = false
        if(action !== 1) {
            data = shipmentsChanged.filter((shipment) =>{
                return shipment.shipping_id == e.target.value
            })
        }
        if(action === 2) {
            isValidOfferlist = validateVisibleOfferlists()
            if(isValidOfferlist) {
                axios.put(`${server}/updateShippingData/${e.target.value}`, data[0])
                .then((res)=>{
                    if(res.status === 200) {
                        alert("Embarque alterado com sucesso! ");
                        window.location.reload()
                    }
                })
                .catch((err)=>{
                    alert('Acontenceu algum erro. ');
                    console.log(err)       
                })
            }
           
    }
        else if(action === 0) {
            const id = parseInt(e.target.value)
            axios.delete(`${server}/deleteshipping/${e.target.value}`)
            .then((res)=>{
                if(res.status === 200){
                    alert("Embarque deletado com sucesso! ");
                    const table = document.getElementsByTagName('table')[0]
                    let rowNumber = 1
                    document.querySelectorAll('input[type="checkbox"]').forEach((offerlist) => {
                        let currrentId = parseInt(offerlist.name.split('/')[1])
                        if(id === currrentId){
                            table.deleteRow(rowNumber)
                        } else {
                            rowNumber++;
                        }
                    })
                }
            
            })
            .catch((err)=>{
                alert('Acontenceu algum erro. ');
                console.log(err)        
            })
        }
        else {
            data = {
                departure_date: document.querySelector('[name="departure_date/0"]').value,
                // origin: document.querySelector('[name="origin/0"]').value,
                farmly_fee: parseFloat(document.querySelector('[name="farmly_fee/0"]').value),
                import_duties:parseFloat(document.querySelector('[name="import_duties/0"]').value),
                storage: parseFloat(document.querySelector('[name="storage/0"]').value),
                logistics_br: parseFloat(document.querySelector('[name="logistics_br/0"]').value),
                logistics_co: parseFloat(document.querySelector('[name="logistics_co/0"]').value),
                price_br: parseFloat(document.querySelector('[name="price_br/0"]').value),
                price_co: parseFloat(document.querySelector('[name="price_co/0"]').value),
                offerlist_display: false
            }
            // isValidOfferlist = validateVisibleOfferlists()
                    axios.post(`${server}/createnewshipping`, data).then((res) => {
                    if(res.status === 201) {
                        alert("Embarque criado com sucesso! ");
                        window.location.reload()
                    }
                }).catch((err) => {
                    alert('Acontenceu algum erro. ');
                    console.log(err)
                })
            
           
        }
    }
    const validateVisibleOfferlists = () => {
        let visibleOfferlistAmount = 0
        document.querySelectorAll('input[type="checkbox"]').forEach((offerlist) => {
            if(offerlist.checked === true){
                visibleOfferlistAmount++;
            }
        })
        if(visibleOfferlistAmount > 2){
            alert('Não é permitido mais de duas offerlist à mostra. Favor desmarcar uma offerlist.');
            return false;
        }
        return true;
    }
    const createNewShippingRow = () => {
        const table = document.getElementsByTagName('table')[0]
        const row = table.insertRow()
        const newTableCells = 9
        let cell, cellContent, select
        for(let i =0; i <= newTableCells; i++){
            cell = row.insertCell()
            cell.className = "td"
            switch(i){
                case 0:
                cellContent = document.createElement('input')
                cellContent.type = "date"
                cellContent.name = "departure_date/0"
                cellContent.className = "inputs"
                cell.appendChild(cellContent)
                break;
                case 1:
                cellContent = document.createElement('input')
                cellContent.type = "number"
                cellContent.name = "farmly_fee/0"
                cellContent.className = "inputs"
                cell.appendChild(cellContent)
                break;
                case 2:
                cellContent = document.createElement('input')
                cellContent.type = "number"
                cellContent.name = "import_duties/0"
                cellContent.className = "inputs"
                cell.appendChild(cellContent)
                break;
                case 3:
                cellContent = document.createElement('input')
                cellContent.type = "number"
                cellContent.name = "storage/0"
                cellContent.className = "inputs"
                cell.appendChild(cellContent)
                break;
                case 4:
                cellContent = document.createElement('input')
                cellContent.type = "number"
                cellContent.name = "logistics_br/0"
                cellContent.className = "inputs"
                cell.appendChild(cellContent)
                break;
                case 5:
                cellContent = document.createElement('input')
                cellContent.type = "number"
                cellContent.name = "logistics_co/0"
                cellContent.className = "inputs"
                cell.appendChild(cellContent)
                break;
                case 6:
                cellContent = document.createElement('input')
                cellContent.type = "number"
                cellContent.name = "price_br/0"
                cellContent.className = "inputs"
                cell.appendChild(cellContent)
                break;
                case 7:
                cellContent = document.createElement('input')
                cellContent.type = "number"
                cellContent.name = "price_co/0"
                cellContent.className = "inputs"
                cell.appendChild(cellContent)
                break;
                case 8:
                cellContent = document.createElement('input')
                cellContent.type = "checkbox"
                cellContent.disabled = true;
                cellContent.name = "offerlist_display/0"
                cell.appendChild(cellContent)
                break;     
                case 9:
                cellContent = document.createElement('button')
                cellContent.type = "button"
                cellContent.onclick = handleClick
                cellContent.innerHTML = "Salvar"
                cellContent.className = "insert-button"
                cell.appendChild(cellContent)
                break;                     
            }
        }
    }

    


    const renderShipments = ()=>{
        return shipments.map((shipments)=>{
            return(
                <tr>
                    <td className="td"><input  type="date" className="inputs"  name={"departure_date/"+ shipments.shipping_id} defaultValue={shipments.departure_date?.split("T")[0]} onChange={handleChange}></input></td>
                    {/* <td className="td">
                    <select name={"origin/"+shipments.shipping_id} onChange={handleChange}>
                        {shipments.origin === "BR" &&
                        <>
                        <option value="BR" selected>Brasil</option>
                        <option value="CO">Colômbia</option>
                        </>
                        }
                         {shipments.origin === "CO" &&
                        <>
                        <option value="BR">Brasil</option>
                        <option value="CO" selected>Colômbia</option>
                        </>
                        }
                        {shipments.origin === undefined || shipments.origin === null &&
                        <>
                        <option selected>Selecione</option>
                        <option value="BR">Brasil</option>
                        <option value="CO">Colômbia</option>
                        </>
                        }
                    </select>
                    </td> */}
                    <td className="td"><input  type="number" className="inputs" defaultValue={shipments.farmly_fee} name={"farmly_fee/"+shipments.shipping_id} value={shipmentsChanged.farmly_fee} onChange={handleChange}></input></td>
                    <td className="td"><input  type="number" className="inputs" defaultValue={shipments.import_duties} name={"import_duties/"+shipments.shipping_id} value={shipmentsChanged.import_duties} onChange={handleChange}></input></td>
                    <td className="td"><input  type="number" className="inputs" defaultValue={shipments.storage} name={"storage/"+shipments.shipping_id} value={shipmentsChanged.storage} onChange={handleChange}></input></td>
                    <td className="td"><input  type="number" className="inputs" defaultValue={shipments.logistics_br} name={"logistics_br/"+shipments.shipping_id} value={shipmentsChanged.logistics_br} onChange={handleChange}></input></td>
                    <td className="td"><input  type="number" className="inputs" defaultValue={shipments.logistics_co} name={"logistics_co/"+shipments.shipping_id} value={shipmentsChanged.logistics_co} onChange={handleChange}></input></td>
                    <td className="td"><input  type="number" className="inputs" defaultValue={shipments.price_br} name={"price_br/"+shipments.shipping_id} value={shipmentsChanged.price_br} onChange={handleChange}></input></td>
                    <td className="td"><input  type="number" className="inputs" defaultValue={shipments.price_co} name={"price_co/"+shipments.shipping_id} value={shipmentsChanged.price_co} onChange={handleChange}></input></td>
                    <td className="td"><input  type="checkbox" defaultChecked={shipments.offerlist_display} name={"offerlist_display/"+shipments.shipping_id} value={shipmentsChanged.offerlist_display} onChange={handleChange}></input></td>
                    <td className="td"><button value={shipments.shipping_id} onClick={(e) => handleClick(e, 2)} className="update-button">Salvar</button></td>
                    <td className="td"><button value={shipments.shipping_id} onClick={(e) => handleClick(e, 0)} className="delete-button">Deletar</button></td>
                </tr>
            )
        })
    }

    return(
        <Main titleHeader="Embarques">
            <button onClick={createNewShippingRow} className="insert-button">Novo Embarque</button>
            <table>
                <tr>
                    <th className="th">Embarque</th>
                    {/* <th className="th">Origem</th> */}
                    <th className="th">Farmly Fee</th>
                    <th className="th">Impostos</th>
                    <th className="th">Armazenamento</th>
                    <th className="th">Logística BR</th>
                    <th className="th">Logística CO</th>
                    <th className="th">Cotação BR</th>
                    <th className="th">Cotação CO</th>
                    <th className="th">Exibir</th>
                    <th className="th"></th>
                </tr>
                {renderShipments()}
            </table>
        </Main>
    )
}

export default ShipmentManage;