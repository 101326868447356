import React, { useReducer, useContext, useEffect, useState } from "react";
import UserContext from "./UserContext.js";
import UserReducer from "./UserReducer.js";
import CartContext from "../cart/CartContext";
import { USER_LOGGED_IN, USER_LOGGED_OUT, USER_IS_ADMIN } from "../types.js";
import axios from "axios";
import { acceptedCookies, server } from "../../common.js";

const UserState = (props) => {
	const cartContext = useContext(CartContext);

	const splits = acceptedCookies() ? document.cookie.split(";") : [];
	const isProd = splits.filter((item) => item.includes("isProd"))[0];
	const jwt = splits.filter((item) => item.includes("jwt"))[0];
	const id = splits.filter((item) => item.includes("userId"))[0];
	const main_id = splits.filter((item) => item.includes("main_id"))[0];

	const initialState = {
		user:
			isProd && jwt && id && main_id
				? {
						isProd: isProd.split("=")[1] === "true",
						jwt: jwt.split("=")[1],
						id: id.split("=")[1],
						main_id: main_id.split("=")[1],
				  }
				: null,
		isAdmin: (id && id.split("=")[1] == 0) || (main_id && main_id.split('=')[1] == 1108) ? true : false,
	};

	useEffect(() => {
		if (isProd && jwt && id && isProd.split("=")[1] === "true") {
			cartContext.producerLogin();
		}
	}, []);

	const [state, dispatch] = useReducer(UserReducer, initialState);

	const userLogin = (user) => {
		if (user.isProd) cartContext.producerLogin();
		return dispatch({ type: USER_LOGGED_IN, payload: user });
	};
	const userLogout = () => dispatch({ type: USER_LOGGED_OUT });

	return (
		<UserContext.Provider
			value={{
				user: state.user,
				isAdmin: state.isAdmin,
				userLogin,
				userLogout,
			}}
		>
			{props.children}
		</UserContext.Provider>
	);
};

export default UserState;
