import styled from 'styled-components';

export const SliderContainer = styled.div`
    width: "90vw";
    max-width: "90vw" 
`
export const BasicInfoSample = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3vh;
    flex-direction: column; 
`
export const SignUpRow = styled.div`
    margin-top: 5px;
`
export const GoogleLoginContainer = styled.div`
    margin-top: 5px;
    margin-bottom: 10px;
    width: 100%;
	border-radius: 25px !important;
`
export const EmailAlreadyRegistered = styled.p`
`
export const SignUpFieldLabel = styled.div`
    margin-top: 5px;
`
export const SignupFieldInput = styled.div`
    border-radius: 14px;
    border-color: gray !important;
    width: 350px;
    height: 40px;
    display: flex;
    justify-content: center;
    @media (max-width: 400px) {
        width: 285px;
    }
`
export const SignUpBasicButton = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    border-radius: 25px;
    margin-bottom: 8px;
    color: #ffffff !important;
    cursor: pointer;
    margin-top: 15px;
    height: 40px;
    align-items: center;
    background-color: #1B625E;
    @media (max-width: 400px) {
		font-size: 12px;
	}
    :hover {
        transform: translateY(4px);
    }
`
