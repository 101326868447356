import React, { useState, useEffect, useContext, Fragment } from 'react'
import axios from 'axios'
import { server, isPt } from '../../../common'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faTimes } from '@fortawesome/free-solid-svg-icons'
import { useHistory, Link } from 'react-router-dom'
import UserContext from '../../../context/user/UserContext.js'
import Paper from '@material-ui/core/Paper'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import FarmlyInput from '../../templates/FarmlyInput'
import './FarmlyCuppingIndv.css'
import Grid from '@material-ui/core/Grid'
import Volume from '@material-ui/core/Slider'
import RadarChart from '../../templates/RadarChartCupping'

const CuppingIndv = props => {

    const { coffee, sensoryList, cuppingDone, checkCuppings, setCheckCuppings, setFillAllSpaces, goToResults, taster, userId } = props
    const userContext = useContext(UserContext)
    const [showResults, setShowResults] = useState(false)
    const [coffeeData, setCoffeeData] = useState()
    const [cuppingData, setCuppingData] = useState({
        coffee_id: coffee.id,
        user_id: userId,
        aroma: coffee.aroma ? coffee.aroma : 0,
        flavor: coffee.flavour ? coffee.flavour : 0,
        completion: coffee.aftertaste ? coffee.aftertaste : 0,
        acidity: coffee.acidity ? coffee.acidity : 0,
        body: coffee.body ? coffee.body: 0,
        balance: coffee.balance ? coffee.balance : 0,
        overall: coffee.overall ? coffee.overall : 0,
        defects: '0',
        big_defect: '0',
        overall: '0',
        score: coffee.score,
        generalComment: coffee.general_comment,
        isFarmly: true

    })
    const [score, setScore] = useState(coffee.score)

    const [defect0, setDefect0] = useState(false)
    const [defect1, setDefect1] = useState(false)
    const [defect2, setDefect2] = useState(false)
    const [defect3, setDefect3] = useState(false)
    const [defect4, setDefect4] = useState(false)
    const [defect5, setDefect5] = useState(false)

    const [bigDefect0, setBigDefect0] = useState(false)
    const [bigDefect1, setBigDefect1] = useState(false)
    const [bigDefect2, setBigDefect2] = useState(false)
    const [bigDefect3, setBigDefect3] = useState(false)
    const [bigDefect4, setBigDefect4] = useState(false)
    const [bigDefect5, setBigDefect5] = useState(false)

    const [sweet1, setSweet1] = useState(false)
    const [sweet2, setSweet2] = useState(false)
    const [sweet3, setSweet3] = useState(false)
    const [sweet4, setSweet4] = useState(false)
    const [sweet5, setSweet5] = useState(false)
    const [sweet, setSweet] = useState(0)

    const [clean1, setClean1] = useState(false)
    const [clean2, setClean2] = useState(false)
    const [clean3, setClean3] = useState(false)
    const [clean4, setClean4] = useState(false)
    const [clean5, setClean5] = useState(false)
    const [cleanCup, setCleanCup] = useState(0)

    const [equal1, setEqual1] = useState(false)
    const [equal2, setEqual2] = useState(false)
    const [equal3, setEqual3] = useState(false)
    const [equal4, setEqual4] = useState(false)
    const [equal5, setEqual5] = useState(false)
    const [equalty, setEqualty] = useState(0)

    const [valueAroma2, setValueAroma2] = useState(0)
    const [valueAroma3, setValueAroma3] = useState(0)
    const [valueAcidity2, setValueAcidity2] = useState(0)
    const [valueBody2, setValueBody2] = useState(0)

    const [roastWarning, setRoastWarning] = useState(false)
    const [aromaWarning, setAromaWarning] = useState(false)
    const [flavorWarning, setFlavorWarning] = useState(false)
    const [completionWarning, setCompletionWarning] = useState(false)
    const [acidityWarning, setAcidityWarning] = useState(false)
    const [bodyWarning, setBodyWarning] = useState(false)
    const [balanceWarning, setBalanceWarning] = useState(false)
    const [overallWarning, setOverallWarning] = useState(false)
    const [updateNotes, setUpdateNotes] = useState(false)
    const [scoreVar, setScoreVar] = useState(false)

    useEffect(() => {
        if (goToResults.status) {
            if(goToResults.coffeeId == coffee.coffee_id) {
                handleResult(false)
                setFillAllSpaces(false)
            }
        }
    }, [goToResults])

    useEffect(() => {
        if (cuppingDone) {
            axios.patch(`${server}/updateCupping`, {
                ...cuppingData,
                clean_cup: cleanCup,
                sweetness: sweet,
                uniformity: equalty,
                score: score,
                cupping_done: true
            })
        }
    }, [showResults, cuppingDone])

    useEffect(() => {
            setScore(parseFloat(cuppingData.aroma) + parseFloat(cuppingData.flavor) + parseFloat(cuppingData.completion) + parseFloat(cuppingData.acidity) + parseFloat(cuppingData.body) + parseFloat(cuppingData.balance) + cleanCup + sweet + equalty - parseFloat(cuppingData.defects) - parseFloat(cuppingData.big_defect) + parseFloat(cuppingData.overall)+30)
    }, [cuppingData, cleanCup, equalty, sweet])

    useEffect(() => {
        axios.post(`${server}/getCuppingCoffee`, { user_id: coffee.user_id, coffee_id: coffee.coffee_id})
            .then(res => {
                if (res.data.sensoryNotes !== null && res.data.sensoryNotes !== "") setCoffeeData(res.data)
                else setCoffeeData({ ...res.data, sensoryNotes: "Sem notas" })
            })
    }, [showResults, updateNotes])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }, [showResults])

    // const initializeData = () => {
    //     setCuppingData({
    //         coffee_id: coffee.id,
    //         user_id: userId,
    //         aroma: coffee.aroma ? coffee.aroma : 0,
    //         flavor: coffee.flavour ? coffee.flavour : 0,
    //         completion: coffee.aftertaste ? coffee.aftertaste : 0,
    //         acidity: coffee.acidity ? coffee.acidity : 0,
    //         body: coffee.body ? coffee.body: 0,
    //         balance: coffee.balance ? coffee.balance : 0,
    //         overall: coffee.overall ? coffee.overall : 0,
    //         defects: '0',
    //         big_defect: '0',
    //         overall: '0',
    //         score: coffee.score,
    //         generalComment: coffee.general_comment,
    //         isFarmly: true
    //     })
    //     setScore(coffee.score)
    //     setInit(false)
    // }

    const handleResult = async (force) => {
        if ((!roastWarning || !aromaWarning || !flavorWarning || !acidityWarning || !bodyWarning || !balanceWarning || !overallWarning || !completionWarning) && force ) {
            setFillAllSpaces(true)
        } else {
            axios.patch(`${server}/coffees`, {
                id: coffee.id,
                aroma: cuppingData.aroma,
                acidity: cuppingData.acidity,
                balance: cuppingData.balance,
                flavour: cuppingData.flavor,
                aftertaste: cuppingData.completion,
                overall: cuppingData.overall,
                body: cuppingData.body,
                score: score,
                sampleSentDate: cuppingData.sampleSentDate,
                sampleReceivedDate: cuppingData.sampleReceivedDate,
                sampleTastedDate: cuppingData.sampleTastedDate, 
                producer: cuppingData.producer,
                process: cuppingData.process,
                variety: cuppingData.variety,
                region: cuppingData.region,
                height: cuppingData.height,
                sackRemain: cuppingData.storage,
                section: taster + 4,
                general_comment: cuppingData.generalComment
                // clean_cup: cleanCup,
                // sweetness: sweet,
                // uniformity: equalty,
                // cupping_done: true
            }) .then(_ => {  
                    setCheckCuppings(!checkCuppings)
                    setShowResults(true)
            })
        }

    }


    const setDefect = (which) => {
        switch (which*2) {
            case 0:
                setCuppingData({ ...cuppingData, defects: 0 })
                setDefect0(!defect0)
                setDefect2(false)
                setDefect1(false)
                setDefect3(false)
                setDefect4(false)
                setDefect5(false)
                break;
            case 2:
                setCuppingData({ ...cuppingData, defects: 2 })
                setDefect1(!defect1)
                setDefect2(false)
                setDefect0(false)
                setDefect3(false)
                setDefect4(false)
                setDefect5(false)
                break;
            case 4:
                setCuppingData({ ...cuppingData, defects: 4 })
                setDefect2(!defect2)
                setDefect0(false)
                setDefect1(false)
                setDefect3(false)
                setDefect4(false)
                setDefect5(false)
                break;
            case 6:
                setCuppingData({ ...cuppingData, defects: 6 })
                setDefect3(!defect3)
                setDefect0(false)
                setDefect2(false)
                setDefect1(false)
                setDefect4(false)
                setDefect5(false)
                break;
            case 8:
                setCuppingData({ ...cuppingData, defects: 8 })
                setDefect4(!defect4)
                setDefect0(false)
                setDefect2(false)
                setDefect3(false)
                setDefect1(false)
                setDefect5(false)
                break;
            case 10:
                setCuppingData({ ...cuppingData, defects: 10 })
                setDefect5(!defect5)
                setDefect0(false)
                setDefect2(false)
                setDefect3(false)
                setDefect4(false)
                setDefect1(false)
                break;

            default:
                break;
        }
    }

        const setBigDefect = (which) => {
        switch (which*4) {
            case 0:
                setCuppingData({ ...cuppingData, big_defect: 0 })
                setBigDefect0(!bigDefect0)
                setBigDefect2(false)
                setBigDefect1(false)
                setBigDefect3(false)
                setBigDefect4(false)
                setBigDefect5(false)
                break;
            case 4:
                setCuppingData({ ...cuppingData, big_defect: 4 })
                setBigDefect1(!bigDefect1)
                setBigDefect2(false)
                setBigDefect0(false)
                setBigDefect3(false)
                setBigDefect4(false)
                setBigDefect5(false)
                break;
            case 8:
                setCuppingData({ ...cuppingData, big_defect: 8 })
                setBigDefect2(!bigDefect2)
                setBigDefect0(false)
                setBigDefect1(false)
                setBigDefect3(false)
                setBigDefect4(false)
                setBigDefect5(false)
                break;
            case 12:
                setCuppingData({ ...cuppingData, big_defect: 12 })
                setBigDefect3(!bigDefect3)
                setBigDefect2(false)
                setBigDefect1(false)
                setBigDefect0(false)
                setBigDefect4(false)
                setBigDefect5(false)
                break;
            case 16:
                setCuppingData({ ...cuppingData, big_defect: 16 })
                setBigDefect4(!bigDefect4)
                setBigDefect2(false)
                setBigDefect1(false)
                setBigDefect3(false)
                setBigDefect0(false)
                setBigDefect5(false)
                break;
            case 20:
                setCuppingData({ ...cuppingData, big_defect: 20 })
                setBigDefect5(!bigDefect5)
                setBigDefect2(false)
                setBigDefect1(false)
                setBigDefect3(false)
                setBigDefect4(false)
                setBigDefect0(false)
                break;

            default:
                break;
        }
    }

    const handleChangeAroma = (event, newValue) => {
        setScoreVar(true)
        setCuppingData({...cuppingData, aroma: newValue})
    }

    const handleChangeAroma2 = (event, newValue) => {
        setScoreVar(true)
        setValueAroma2(newValue)
    }

    const handleChangeAroma3 = (event, newValue) => {
        console.log(coffee);
        setScoreVar(true)
        setValueAroma3(newValue)
    }

    const handleChangeFlavor = (event, newValue) => {
        setScoreVar(true)
        setCuppingData({...cuppingData, flavor: newValue})
    }

    const handleChangeAcidity = (event, newValue) => {
        setScoreVar(true)
        setCuppingData({...cuppingData, acidity: newValue})
    }

    const handleChangeAcidity2 = (event, newValue) => {
        setScoreVar(true)
        setValueAcidity2(newValue)
    }

    const handleChangeBalance = (event, newValue) => {
        setScoreVar(true)
        setCuppingData({...cuppingData, balance: newValue})
    }

    const handleChangeBody = (event, newValue) => {
        setScoreVar(true)
        setCuppingData({...cuppingData, body: newValue})
    }

    const handleChangeBody2 = (event, newValue) => {
        setScoreVar(true)
        setValueBody2(newValue)
    }

    const handleChangeCompletion = (event, newValue) => {
        setScoreVar(true)
        setCuppingData({...cuppingData, completion: newValue})
    }

    const handleChangeOverall = (event, newValue) => {
        setScoreVar(true)
        setCuppingData({...cuppingData, overall: newValue})
    }

    const removeNote = async (item) => {

        let oldList = coffee.sensoryNotes.split(',')
        if ((coffee.sensoryNotes.split(',').indexOf(item) >= 0)) {
            oldList.splice(coffee.sensoryNotes.split(',').indexOf(item), 1)
            console.log(oldList);
            const reqBody = {
                ...coffee,
                sensoryNotes: oldList.join(',')
            }
            try {
                await axios.patch(`${server}/updateCuppingCoffee`, reqBody)
                setUpdateNotes(!updateNotes)
            } catch (err) {
                console.log(err)
            }
        }
    }

    const renderSensoryCard = () =>
        coffee.sensoryNotes.split(',').map((note) => {
            let random = Math.floor(Math.random() * 3)
            return (
                <Fragment>
                    {note !== "Sem notas" ? <div className={(random == 0) ? 'sensory-red' : ((random == 1 ? 'sensory-lime' : 'sensory-orange'))} style={{display: 'flex', flexDirection: 'row', width: 'auto'}}>
                        {note}
                        <div onClick={()=>removeNote(note)} style={{marginLeft: '8px', marginRight: '3px'}}>x</div>
                        </div> : note}
                    &nbsp;
                    <br />
                </Fragment>
            )
        })


    const useStyles = makeStyles((theme) => ({
        mainPaper: {
            height: 'auto',
            marginBottom: '-55px',
            borderRadius: '25px',
            width: '100%',
            backgroundColor: 'rgb(27, 98, 94)',
            marginTop: '-10vw',
            paddingTop: '20vw'
        },
        volume: {
            height: 300,
        },
        scorePaper: {
            marginTop: '-55px',
            height: 'auto',
            marginBottom: '5px',
            borderRadius: '25px',
            width: '100%',
            backgroundColor: 'rgb(27, 98, 94)',
            zIndex: '0',
            paddingBottom: '25px'
        },
        margin: {
            margin: theme.spacing(1),
        },
    }))
    const classes = useStyles()

    const marks = [
        {
            value: 6,
            label: '6',
        },
        {
            value: 7,
            label: '7',
        },
        {
            value: 8,
            label: '8',
        },
        {
            value: 9,
            label: '9',
        },
        {
            value: 10,
            label: '10',
        },
    ]

    const noMarks = [
        {
            value: 6,
            label: '-',
        },
        {
            value: 7,
            label: '-',
        },
        {
            value: 8,
            label: '-',
        },
        {
            value: 9,
            label: '-',
        },
        {
            value: 10,
            label: '-',
        },
    ]

    const bodyMarks = [
        {
            value: 6,
            label: '- Pouco denso',
        },
        {
            value: 7,
            label: '-',
        },
        {
            value: 8,
            label: '- Denso',
        },
        {
            value: 9,
            label: '-',
        },
        {
            value: 10,
            label: '- Muito denso',
        },
    ]

    const acidityMarks = [
        {
            value: 6,
            label: '- Baixa',
        },
        {
            value: 7,
            label: '-',
        },
        {
            value: 8,
            label: '- Média',
        },
        {
            value: 9,
            label: '-',
        },
        {
            value: 10,
            label: 'Alta',
        },
    ]

    const chartAttributes = [{
        Acidez: cuppingData.acidity,
        Aroma: cuppingData.aroma,
        Corpo: cuppingData.body,
        Equilibrio: cuppingData.balance,
        Finalização: cuppingData.completion,
        Sabor: cuppingData.flavor
    }]


    const settings = {
        className: "center",
        centerMode: true,
        infinite: false,
        centerPadding: "35px",
        slidesToShow: 3,
        swipeToSlide: true,
        centerColor: 'red',
        arrows: true,
        acessibility: true
    }

    return (
        <div>
            <Paper className={classes.mainPaper}>
                <div className="producer-profile-item" style={{ padding: '15vw', height: 'auto' }}>
                    <label htmlFor="" className="producer-profile-label coffee-producer-title">{coffee.producer}</label>
                    <br />
                    <label style={{ color: 'rgb(230, 179, 143)', paddingLeft: '6vw' }}>Code : {coffee.farmCode}</label>
                </div>

                <div className='img-sup-dir'></div>
                <div className='img-sup-esq'></div>
            </Paper>
            <Paper elevation={3} style={{ margin: '4vw', borderRadius: '25px' }}>
                <div style={{ padding: '15px' }}>
                    <div className="producer-profile-item">
                        <label htmlFor="" className="producer-profile-label info-title" >Ponto da Torra</label>
                    </div>
                    <div className="producer-profile-item coffee-roast" >
                        <div className={cuppingData.roasting_point == '1' ? 'selected-roast' : ''} onClick={() => {
                            setCuppingData({ ...cuppingData, roasting_point: '1'})
                            setRoastWarning(true)
                        }}>
                            <div className='cafe-fraco'></div>
                        </div>
                        <div className={cuppingData.roasting_point == '2'  ? 'selected-roast' : ''} onClick={() => {
                            setCuppingData({ ...cuppingData, roasting_point: '2'})
                            setRoastWarning(true)
                        }}>
                            <div className='cafe-fraco2'></div>
                        </div>
                        <div className={cuppingData.roasting_point == '3' ? 'selected-roast' : ''} onClick={() => {
                            setCuppingData({ ...cuppingData, roasting_point: '3'})
                            setRoastWarning(true)
                        }}>
                            <div className='cafe-medio'></div>
                        </div>
                        <div className={cuppingData.roasting_point == '4' ? 'selected-roast' : ''} onClick={() => {
                            setCuppingData({ ...cuppingData, roasting_point: '4'})
                            setRoastWarning(true)
                        }}>
                            <div className='cafe-medio2'></div>
                        </div>
                        <div className={cuppingData.roasting_point == '5' ? 'selected-roast' : ''} onClick={() => {
                            setCuppingData({ ...cuppingData, roasting_point: '5'})
                            setRoastWarning(true)
                        }}>
                            <div className='cafe-forte'></div>
                        </div>
                    </div>
                </div>
            </Paper>
            <Paper elevation={3} style={{ margin: '4vw', borderRadius: '25px' }}>
                <div style={{ padding: '15px' }}>
                    <div className="producer-profile-item">
                        <label htmlFor="" className="producer-profile-label info-title" >Aroma</label>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <label>Seco</label>
                            <br />
                            <Grid container spacing={2} className='small-volume'>
                                <Grid item xs>
                                    <Volume value={valueAroma3} style={{ color: "rgb(27, 98, 94)" }} onChange={handleChangeAroma3} marks={noMarks} min={6} max={10} valueLabelDisplay="off" orientation="vertical" aria-labelledby="continuous-slider" step={0.5} track="normal" />
                                </Grid>
                            </Grid>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <label>Crosta</label>
                            <br />
                            <Grid container spacing={2} className='small-volume'>
                                <Grid item xs>
                                    <Volume value={valueAroma2} style={{ color: "rgb(27, 98, 94)", borderRadius: '50%' }} onChange={handleChangeAroma2} marks={noMarks} min={6} max={10} valueLabelDisplay="off" orientation="vertical" aria-labelledby="continuous-slider" step={0.5} />
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                    <br />
                    <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                        <label>Geral</label>
                        <br />
                        <br />
                        <br />
                        <Grid container spacing={2} className="big-volume" >
                            <Grid item xs>
                                <Volume value={cuppingData.aroma} style={{ color: "rgb(27, 98, 94)", borderRadius: '50%' }} onChange={handleChangeAroma} marks={marks} min={6} max={10} valueLabelDisplay="on" orientation="vertical" aria-labelledby="continuous-slider" step={0.25} />
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </Paper>

            <Paper elevation={3} style={{ margin: '4vw', borderRadius: '25px' }}>
                <div style={{ padding: '15px' }}>
                    <div className="producer-profile-item">
                        <label htmlFor="" className="producer-profile-label info-title">Sabor</label>
                    </div>
                    <br />
                    <br />
                    <Grid container spacing={2} className="big-volume">
                        <Grid item xs>
                            <Volume value={cuppingData.flavor} style={{ color: "rgb(27, 98, 94)" }} onChange={handleChangeFlavor} marks={marks} min={6} max={10} valueLabelDisplay="on" orientation="vertical" aria-labelledby="continuous-slider" step={0.25} />
                        </Grid>
                    </Grid>
                </div>
            </Paper>

            <Paper elevation={3} style={{ margin: '4vw', borderRadius: '25px' }}>
                <div style={{ padding: '15px' }}>
                    <div className="producer-profile-item">
                        <label htmlFor="" className="producer-profile-label info-title">Acidez</label>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <label>Intensidade</label>
                            <br />
                            <Grid container spacing={2} className='small-volume'>
                                <Grid item xs>
                                    <Volume value={valueAcidity2} style={{ color: "rgb(27, 98, 94)", borderRadius: '50%' }} onChange={handleChangeAcidity2} marks={acidityMarks} min={6} max={10} valueLabelDisplay="off" orientation="vertical" aria-labelledby="continuous-slider" step={0.5} />
                                </Grid>
                            </Grid>
                        </div>

                    </div>
                    <br />
                    <br />
                    <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                        <label>Geral</label>
                        <br />
                        <br />
                        <br />
                        <Grid container spacing={2} className="big-volume">
                            <Grid item xs>
                                <Volume value={cuppingData.acidity} style={{ color: "rgb(27, 98, 94)", borderRadius: '50%' }} onChange={handleChangeAcidity} marks={marks} min={6} max={10} valueLabelDisplay="on" orientation="vertical" aria-labelledby="continuous-slider" step={0.25} />
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </Paper>

            <Paper elevation={3} style={{ margin: '4vw', borderRadius: '25px' }}>
                <div style={{ padding: '15px' }}>
                    <div className="producer-profile-item">
                        <label htmlFor="" className="producer-profile-label info-title">Finalização</label>
                    </div>
                    <br />
                    <br />
                    <Grid container spacing={2} className="big-volume">
                        <Grid item xs>
                            <Volume value={cuppingData.completion} style={{ color: "rgb(27, 98, 94)" }} onChange={handleChangeCompletion} marks={marks} min={6} max={10} valueLabelDisplay="on" orientation="vertical" aria-labelledby="continuous-slider" step={0.25} />
                        </Grid>
                    </Grid>
                </div>
            </Paper>

            <Paper elevation={3} style={{ margin: '4vw', borderRadius: '25px' }}>
                <div style={{ padding: '15px' }}>
                    <div className="producer-profile-item">
                        <label htmlFor="" className="producer-profile-label info-title">Corpo</label>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <label>Intensidade</label>
                            <br />
                            <br />
                            <br />
                            <Grid container spacing={2} className='small-volume'>
                                <Grid item xs>
                                    <Volume value={valueBody2} style={{ color: "rgb(27, 98, 94)", borderRadius: '50%' }} onChange={handleChangeBody2} marks={bodyMarks} min={6} max={10} valueLabelDisplay="off" orientation="vertical" aria-labelledby="continuous-slider" step={0.5} />
                                </Grid>
                            </Grid>
                        </div>

                    </div>
                    <br />
                    <br />
                    <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                        <label>Geral</label>
                        <br />
                        <br />
                        <br />
                        <Grid container spacing={2} className="big-volume">
                            <Grid item xs>
                                <Volume value={cuppingData.body} style={{ color: "rgb(27, 98, 94)", borderRadius: '50%' }} onChange={handleChangeBody} marks={marks} min={6} max={10} valueLabelDisplay="on" orientation="vertical" aria-labelledby="continuous-slider" step={0.25} />
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </Paper>

            <Paper elevation={3} style={{ margin: '4vw', borderRadius: '25px' }}>
                <div style={{ padding: '15px' }}>
                    <div className="producer-profile-item">
                        <label htmlFor="" className="producer-profile-label info-title" >Equilíbrio</label>
                    </div>
                    <br />
                    <br />
                    <Grid container spacing={2} className="big-volume">
                        <Grid item xs>
                            <Volume value={cuppingData.balance} style={{ color: "rgb(27, 98, 94)" }} onChange={handleChangeBalance} marks={marks} min={6} max={10} valueLabelDisplay="on" orientation="vertical" aria-labelledby="continuous-slider" step={0.25} />
                        </Grid>
                    </Grid>
                </div>
            </Paper>

            <Paper elevation={3} style={{ margin: '4vw', borderRadius: '25px' }}>
                <div style={{ padding: '15px' }}>
                    <div className="producer-profile-item">
                        <label htmlFor="" className="producer-profile-label info-title" >Nota Final</label>
                    </div>
                    <br />
                    <br />
                    <Grid container spacing={2} className="big-volume">
                        <Grid item xs>
                            <Volume value={cuppingData.overall} style={{ color: "rgb(27, 98, 94)" }} onChange={handleChangeOverall} marks={marks} min={6} max={10} valueLabelDisplay="on" orientation="vertical" aria-labelledby="continuous-slider" step={0.25} />
                        </Grid>
                    </Grid>
                </div>
            </Paper>
            < Paper elevation={3} zIndex="tooltip" style={{ margin: '4vw', borderRadius: '25px', position: 'relative' }}>
                <div style={{ padding: '15px', textAlign: 'center' }}>
                    <div className="producer-profile-item">
                        <label htmlFor="" className="producer-profile-label info-title">Xícara Limpa</label>
                    </div>
                    <div>
                        {clean1 && <FontAwesomeIcon icon={faTimes} className="select-cross" onClick={() => {
                            clean1 ? setCleanCup(cleanCup + 2) : setCleanCup(cleanCup - 2)
                            setClean1(!clean1)
                        }}></FontAwesomeIcon>}
                        <FontAwesomeIcon className="select-cup" icon={faCoffee} onClick={() => {
                            clean1 ? setCleanCup(cleanCup + 2) : setCleanCup(cleanCup - 2)
                            setClean1(!clean1)
                        }}></FontAwesomeIcon>
                        {clean2 && <FontAwesomeIcon icon={faTimes} className="select-cross" onClick={() => {
                            clean2 ? setCleanCup(cleanCup + 2) : setCleanCup(cleanCup - 2)
                            setClean2(!clean2)
                        }}></FontAwesomeIcon>}
                        <FontAwesomeIcon className="select-cup" icon={faCoffee} onClick={() => {
                            clean2 ? setCleanCup(cleanCup + 2) : setCleanCup(cleanCup - 2)
                            setClean2(!clean2)
                        }}></FontAwesomeIcon>
                    </div>
                    <div>
                        {clean3 && <FontAwesomeIcon icon={faTimes} className="select-cross" onClick={() => {
                            clean3 ? setCleanCup(cleanCup + 2) : setCleanCup(cleanCup - 2)
                            setClean3(!clean3)
                        }}></FontAwesomeIcon>}
                        <FontAwesomeIcon className="select-cup" icon={faCoffee} onClick={() => {
                            clean3 ? setCleanCup(cleanCup + 2) : setCleanCup(cleanCup - 2)
                            setClean3(!clean3)
                        }}></FontAwesomeIcon>
                        {clean4 && <FontAwesomeIcon icon={faTimes} className="select-cross" onClick={() => {
                            clean4 ? setCleanCup(cleanCup + 2) : setCleanCup(cleanCup - 2)
                            setClean4(!clean4)
                        }}></FontAwesomeIcon>}
                        <FontAwesomeIcon className="select-cup" icon={faCoffee} onClick={() => {
                            clean4 ? setCleanCup(cleanCup + 2) : setCleanCup(cleanCup - 2)
                            setClean4(!clean4)
                        }}></FontAwesomeIcon>
                        {clean5 && <FontAwesomeIcon icon={faTimes} className="select-cross" onClick={() => {
                            clean5 ? setCleanCup(cleanCup + 2) : setCleanCup(cleanCup - 2)
                            setClean5(!clean5)
                        }}></FontAwesomeIcon>}
                        <FontAwesomeIcon className="select-cup" icon={faCoffee} onClick={() => {
                            clean5 ? setCleanCup(cleanCup + 2) : setCleanCup(cleanCup - 2)
                            setClean5(!clean5)
                        }}></FontAwesomeIcon>
                    </div>
                </div>
            </Paper>

            {/* Doçura */}
            <Paper elevation={3} style={{ margin: '4vw', borderRadius: '25px' }}>
                <div style={{ padding: '15px', textAlign: 'center' }}>
                    <div className="producer-profile-item">
                        <label htmlFor="" className="producer-profile-label info-title">Doçura</label>
                    </div>
                    <div>
                        {sweet1 && <FontAwesomeIcon icon={faTimes} className="select-cross" onClick={() => {
                            sweet1 ? setSweet(sweet + 2) : setSweet(sweet - 2)
                            setSweet1(!sweet1)
                        }}></FontAwesomeIcon>}
                        <FontAwesomeIcon className="select-cup" icon={faCoffee} onClick={() => {
                            sweet1 ? setSweet(sweet + 2) : setSweet(sweet - 2)
                            setSweet1(!sweet1)
                        }}></FontAwesomeIcon>
                        {sweet2 && <FontAwesomeIcon icon={faTimes} className="select-cross" onClick={() => {
                            sweet2 ? setSweet(sweet + 2) : setSweet(sweet - 2)
                            setSweet2(!sweet2)
                        }}></FontAwesomeIcon>}
                        <FontAwesomeIcon className="select-cup" icon={faCoffee} onClick={() => {
                            sweet2 ? setSweet(sweet + 2) : setSweet(sweet - 2)
                            setSweet2(!sweet2)
                        }}></FontAwesomeIcon>
                    </div>
                    <div>
                        {sweet3 && <FontAwesomeIcon icon={faTimes} className="select-cross" onClick={() => {
                            sweet3 ? setSweet(sweet + 2) : setSweet(sweet - 2)
                            setSweet3(!sweet3)
                        }}></FontAwesomeIcon>}
                        <FontAwesomeIcon className="select-cup" icon={faCoffee} onClick={() => {
                            sweet3 ? setSweet(sweet + 2) : setSweet(sweet - 2)
                            setSweet3(!sweet3)
                        }}></FontAwesomeIcon>
                        {sweet4 && <FontAwesomeIcon icon={faTimes} className="select-cross" onClick={() => {
                            sweet4 ? setSweet(sweet + 2) : setSweet(sweet - 2)
                            setSweet4(!sweet4)
                        }}></FontAwesomeIcon>}
                        <FontAwesomeIcon className="select-cup" icon={faCoffee} onClick={() => {
                            sweet4 ? setSweet(sweet + 2) : setSweet(sweet - 2)
                            setSweet4(!sweet4)
                        }}></FontAwesomeIcon>
                        {sweet5 && <FontAwesomeIcon icon={faTimes} className="select-cross" onClick={() => {
                            sweet5 ? setSweet(sweet + 2) : setSweet(sweet - 2)
                            setSweet5(!sweet5)
                        }}></FontAwesomeIcon>}
                        <FontAwesomeIcon className="select-cup" icon={faCoffee} onClick={() => {
                            sweet5 ? setSweet(sweet + 2) : setSweet(sweet - 2)
                            setSweet5(!sweet5)
                        }}></FontAwesomeIcon>
                    </div>
                </div>
            </Paper>

            {/* Uniformidade */}
            <Paper elevation={3} style={{ margin: '4vw', borderRadius: '25px' }}>
                <div style={{ padding: '15px', textAlign: 'center' }}>
                    <div className="producer-profile-item">
                        <label htmlFor="" className="producer-profile-label info-title">Uniformidade</label>
                    </div>
                    <div>
                        {equal1 && <FontAwesomeIcon icon={faTimes} className="select-cross" onClick={() => {
                            equal1 ? setEqualty(equalty + 2) : setEqualty(equalty - 2)
                            setEqual1(!equal1)
                        }}></FontAwesomeIcon>}
                        <FontAwesomeIcon className="select-cup" icon={faCoffee} onClick={() => {
                            equal1 ? setEqualty(equalty + 2) : setEqualty(equalty - 2)
                            setEqual1(!equal1)
                        }}></FontAwesomeIcon>
                        {equal2 && <FontAwesomeIcon icon={faTimes} className="select-cross" onClick={() => {
                            equal2 ? setEqualty(equalty + 2) : setEqualty(equalty - 2)
                            setEqual2(!equal2)
                        }}></FontAwesomeIcon>}
                        <FontAwesomeIcon className="select-cup" icon={faCoffee} onClick={() => {
                            equal2 ? setEqualty(equalty + 2) : setEqualty(equalty - 2)
                            setEqual2(!equal2)
                        }}></FontAwesomeIcon>
                    </div>
                    <div>
                        {equal3 && <FontAwesomeIcon icon={faTimes} className="select-cross" onClick={() => {
                            equal3 ? setEqualty(equalty + 2) : setEqualty(equalty - 2)
                            setEqual3(!equal3)
                        }}></FontAwesomeIcon>}
                        <FontAwesomeIcon className="select-cup" icon={faCoffee} onClick={() => {
                            equal3 ? setEqualty(equalty + 2) : setEqualty(equalty - 2)
                            setEqual3(!equal3)
                        }}></FontAwesomeIcon>
                        {equal4 && <FontAwesomeIcon icon={faTimes} className="select-cross" onClick={() => {
                            equal4 ? setEqualty(equalty + 2) : setEqualty(equalty - 2)
                            setEqual4(!equal4)
                        }}></FontAwesomeIcon>}
                        <FontAwesomeIcon className="select-cup" icon={faCoffee} onClick={() => {
                            equal4 ? setEqualty(equalty + 2) : setEqualty(equalty - 2)
                            setEqual4(!equal4)
                        }}></FontAwesomeIcon>
                        {equal5 && <FontAwesomeIcon icon={faTimes} className="select-cross" onClick={() => {
                            equal5 ? setEqualty(equalty + 2) : setEqualty(equalty - 2)
                            setEqual5(!equal5)
                        }}></FontAwesomeIcon>}
                        <FontAwesomeIcon className="select-cup" icon={faCoffee} onClick={() => {
                            equal5 ? setEqualty(equalty + 2) : setEqualty(equalty - 2)
                            setEqual5(!equal5)
                        }}></FontAwesomeIcon>
                    </div>
                </div>
            </Paper>

            {/* Defeitos */}
            <Paper elevation={3} style={{ margin: '4vw', borderRadius: '25px', position: 'relative' }}>
                    <div style={{ padding: '15px', textAlign: 'center' }}>
                        <div className="producer-profile-item">
                            <label htmlFor="" className="producer-profile-label info-title">Defeitos</label>
                        </div>
                        <label className='defect-comment'><b>Leve</b> x2</label>
                        <br />
                        <input type="number" className="farmly-input input-cupping-categories" min={0} max={5} step={1} value={cuppingData.defects/2} onChange={e => setDefect(e.target.value)} placeholder=""/> 
                        {(cuppingData.defects/2>5 || cuppingData.defects/2<0 )  && <label className='invalid-input-cupping' style={{ color: 'var(--farmly-fuzzy)' }}>Nota entre 0 e 5.</label>}
                        <br />
                        <label className='defect-comment'><b>Grave</b> x4</label>
                        <br />
                        <input type="number" className="farmly-input input-cupping-categories" min={0} max={5} step={1} value={cuppingData.big_defect/4} onChange={e => setBigDefect(e.target.value)} placeholder=""/> 
                        {(cuppingData.big_defect/4>5 || cuppingData.big_defect/4<0 )  && <label className='invalid-input-cupping' style={{ color: 'var(--farmly-fuzzy)' }}>Nota entre 0 e 5.</label>}
                        <div className="comment-box">
                            <label>Comentário:</label>
                            <br />
                            <br />
                            <FarmlyInput textarea styleClass="farmly-input comments-cupping" value={cuppingData.generalComment || ""} setValue={value => setCuppingData({ ...cuppingData, generalComment: value })}></FarmlyInput>
                        </div>
                    </div>
                </Paper>

            <Paper elevation={3} className={classes.scorePaper}>
                <div className="producer-profile-item" style={{ padding: '4vw', paddingTop: '18vw', textAlign: 'center' }}>
                    <div className='img-inf-dir1'></div>
                    <label htmlFor="" className="producer-profile-label" style={{ color: 'white', fontSize: '30px' }}>Pontuação</label>
                    <label style={{ color: 'rgb(230, 179, 143)', fontSize: '90px', fontWeight: '500' }}> {scoreVar && !!parseFloat(score) ? parseFloat(score) : 0}</label>
                </div>
                <br />
                <div>
                    <RadarChart attributes={chartAttributes} maxHeight={'64vw'}/>
                </div>
                <br />
                {coffee && 
                    <div className='sensory-cards-mobile'>
                            {renderSensoryCard()}
                    </div>
                }
                <br />
                <br />
                <div style={{ textAlign: '-webkit-center' }}>
                    <div>
                        <button className="score-paper-button-score" onClick={() => handleResult(true)} style={{ marginBottom: '8px' }}>
                            Salvar
                        </button>
                    </div>
                    <div className='img-inf-dir2'></div>
                    <div className='img-inf-esq'></div>
                </div>
            </Paper>
        </div >
    )


}

export default CuppingIndv