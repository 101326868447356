import React, { useState, Fragment, useEffect } from 'react'
import axios from 'axios'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faCheck, faIdCard, faCoffee, faHistory } from '@fortawesome/free-solid-svg-icons'
import { farmlyColors, server } from '../../common.js'
import { Link } from 'react-router-dom'
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import './Cards.css'

const footerArt = require('../../assets/footer-art.png')

const AdminCoffeeCard = props => {

    const hover = true;

    const {
        image,
        coffeeId,
        coffee,
        farmName,
        variety,
        farmHeight,
        process,
        region,
        score,
        producer,
        sensoryNotes,
        sampleMessage,
        sampleSentDate,
        sampleReceivedDate,
        sampleTastedDate,
        harvestDate,
        sack_price,
        display,
        width,
        height,
        coffees,
        setCoffees,
        updateVisible,
        updateOldVisible,
        updateNotVisible,
        updateDouble,
        checkParam,
        doubleCheckParam,
        toggleModal,
        sampleApprove,
        updateApproval,
        updateDisapproval
        // oldDisplay,
        // selectPage
    } = props

    const [isChecked, setIsChecked] = useState(checkParam)
    const [isCheckedDouble, setIsCheckedDouble] = useState(doubleCheckParam)
    const [isApproved, setIsApproved] = useState(sampleApprove)
    const [isOld, setIsOld] = useState(coffee.old_display)
    const [coffeeSection, setCoffeeSection] = useState(coffee.section)

    const toggleCheck = () => {
        //marca o café e muda ele de lugar
        if (isChecked) {
            updateNotVisible(coffees, setCoffees, coffee)
        } else {
            updateVisible(coffees, setCoffees, coffee)
        }
    }

    const toggleOld = () => {
        if (isOld) {
            updateNotVisible(coffees, setCoffees, coffee)
        } else {
            updateOldVisible(coffees, setCoffees, coffee)
        }
    }

    const showModal = () => {
        toggleModal(coffeeId)
    }

    const toggleCheckDouble = () => {
        setIsCheckedDouble(!isCheckedDouble)
        updateDouble(coffees, setCoffees, coffee)
    }

    const toggleApproved = () => {
        setIsApproved("approved")
        updateApproval(coffees, setCoffees, coffee)
    }

    const toggleNotApproved = () => {
        setIsApproved("not-approved")
        updateDisapproval(coffees, setCoffees, coffee)
    }

    const handleChange = (event) => {
        setCoffeeSection(event.target.value);
      };

    useEffect(() => {
        axios.patch(`${server}/setSection`,{
            id: coffeeId,
            section: coffeeSection
        })
    }, [coffeeSection])

    return (
        <div className='coffee-card' style={{ backgroundColor: farmlyColors.desertSand, width: width, height: height, overflow: 'hidden' }}>
            <img id='coffee-card-footer' style={{
                bottom: hover ? '35%' : '0%'
            }} src={footerArt} alt='' />
            <img id='coffee-card-image' style={{
                opacity: hover ? '0' : '1',
                width: '100%'
            }} src={image} alt='' />
            <Link to={{ pathname: 'greeting_card', params: { farmName, variety, farmHeight, process, region } }}
                className="make-greeting" style={{ width: '30px', height: '30px' }}>
                <FontAwesomeIcon icon={faIdCard}
                    style={{ width: '30px', height: '30px' }}
                />
            </Link>
            <FontAwesomeIcon className="check-icon-card-left" icon={faEnvelope}
                style={{ width: '30px', height: '30px' }} onClick={showModal}
            />
            <span className="coffee-id-card">café N° {coffeeId}
            <div style={{ marginTop: 'auto' }}>
                        <label className="coffee-card-label">Seção: </label>
                        <Select
                            labelId="demo-customized-select-label"
                            id="demo-customized-select"
                            value={coffeeSection}
                            onChange={handleChange}
                            >
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                        </Select>
                        {/* <label className='coffee-card-label'>{coffee.section}</label> */}
                    </div></span>
            {(checkParam != undefined) && <FontAwesomeIcon className="check-icon-card" icon={faCheck}
                style={isChecked ? { color: '1A1', width: '30px', height: '30px' } : { width: '30px', height: '30px' }}
                onClick={toggleCheck} />}
            {/* {selectPage && <FontAwesomeIcon className="check-icon-card-double" icon={faHistory}
                style={isOld ? { color: '1A1', width: '30px', height: '30px' } : { width: '30px', height: '30px' }}
                onClick={toggleOld} />} */}
            {(doubleCheckParam != undefined) && < div className="tooltip"><FontAwesomeIcon className="check-icon-card-double" icon={faCheck}
                style={isCheckedDouble ? { color: '1A1', width: '30px', height: '30px' } : { width: '30px', height: '30px' }}
                onClick={toggleCheckDouble} />
                <span className="tooltiptext">desmarcar recebido</span></div>}
            <div id="underlay" style={{
                height: hover ? '55%' : '0%', zIndex: 0
            }} />
            <Link to={{ pathname: `/coffee/${coffeeId}`, fromProfile: false }} id="coffee-card-info-container" style={{
                height: hover ? '75%' : '23%', zIndex: 1, backgroundColor: (sampleTastedDate && sampleTastedDate != "not-tasted") ? '#AEA' : (sampleReceivedDate && sampleReceivedDate != "not-received") ? '#EDA' : (sampleSentDate && sampleSentDate != "not-sent") ? '#EAA' : 'white'
            }} >
                <div id="coffee-card-info-labels" className="admin-coffee-infobox">
                    <label className="coffee-card-label">Produtor:</label>
                    <label className="coffee-card-info admin-coffee-card-info">{producer}</label>
                    <label className="coffee-card-label">Fazenda:</label>
                    <label className="coffee-card-info admin-coffee-card-info">{farmName}</label>
                    <label className="coffee-card-label">Lote:</label>
                    <label className="coffee-card-info admin-coffee-card-info">{harvestDate}</label>
                    <label className="coffee-card-info admin-coffee-card-info">{variety} - {score}</label>
                    {display != undefined && <label className="coffee-card-label">Sensorial:</label>}
                    {display != undefined && <label className="coffee-card-info admin-coffee-card-info">{sensoryNotes}</label>}
                    {display != undefined && <label className="coffee-card-label">Preço:</label>}
                    {display != undefined && <label className="coffee-card-info admin-coffee-card-info">{sack_price}</label>}
                </div>
            </Link>
        </div>
    )

}

export default AdminCoffeeCard