import React, { FunctionComponent } from "react";
import { isPt, farmlyTexts, isCo, server } from "../../../../../common";
import { useEffect } from "react";
import backend from "../../../../../lib/backend"
import { GoogleLogin } from "react-google-login";
import { Link } from "react-router-dom";
import { BasicInfoSample, EmailAlreadyRegistered, GoogleLoginContainer, SignUpBasicButton, SignupFieldInput, SignUpFieldLabel, SignUpRow } from "./style";

interface RoasterDataProps {
	country: string,
	email: string,
	name: string,
	password: string,
	confirmPassword: string,
	postalCode: string,
	address: string,
	roastery: string,
	vat: string,
	phoneNumber: string,
	sensoryNotes: string,
	coffeeScores: string
}
interface BasicInfoSampleCartProps {
	roasterData: RoasterDataProps,
	setStep: React.Dispatch<React.SetStateAction<number>>
	setRoasterData: React.Dispatch<React.SetStateAction<RoasterDataProps>>,
	toggleModal: () => void,
	setModalMessage: React.Dispatch<React.SetStateAction<string>>,
}

const BasicInfoSampleCart: FunctionComponent<BasicInfoSampleCartProps> = (props) => {
	const allFilled = props.roasterData.email !== "";

	const validate = (email: string) => {
		const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;

		return expression.test(String(email).toLowerCase());
	};

	const passwordMatches = props.roasterData.password === props.roasterData.confirmPassword;

	const responseGoogle = (response: any) => {
		props.setRoasterData({
			...props.roasterData,
			email: response.profileObj.email,
			name: response.profileObj.givenName,
			password: response.profileObj.googleId,
		});
		props.setStep(1);
	};

	return (
		<BasicInfoSample>
			<SignUpRow>
				<SignupFieldInput>
					<input
						type="email"
						style={{ borderRadius: "15px", width: "500px" }}
						value={props.roasterData.email}
						required
						onChange={(e) =>
							props.setRoasterData({ ...props.roasterData, email: e.target.value })
						}
						placeholder={
							isPt() ? farmlyTexts.signupEmail_pt : farmlyTexts.signupEmail_en
						}
					/>
				</SignupFieldInput>
			</SignUpRow>
			<SignUpRow>
				<SignUpBasicButton
					className="signup-basic-button"
					onClick={async () => {
						const nextButton: any = document.querySelector(".signup-basic-button");
						nextButton.style.pointerEvents = "none";
						nextButton.innerHTML = isPt()
							? "Aguarde..."
							: isCo()
								? "Espera..."
								: "Wait...";
						const res = await backend.post(`${server}/userExists`, {
							email: props.roasterData.email,
						});
						const userExists = res.data.exists;

						if (!allFilled) {
							const fillMsg = isPt()
								? farmlyTexts.signupPleaseFill_pt
								: isCo()
									? `¡Rellena todos los campos!`
									: farmlyTexts.signupPleaseFill_en;
							props.setModalMessage(fillMsg);
							props.toggleModal();
						} else if (userExists) {
							const userExistsMsg: any = isPt() ? (
								<>
									<EmailAlreadyRegistered>Email já cadastrado!</EmailAlreadyRegistered>
									<Link to="/login">Deseja fazer Login?</Link>
								</>
							) : isCo() ? (
								<>
									<EmailAlreadyRegistered>¡El email ya está registrado!</EmailAlreadyRegistered>
									<Link to="/login">¿Quieres iniciar sesión?</Link>
								</>
							) : (
								<>
									<EmailAlreadyRegistered>Email already registered!</EmailAlreadyRegistered>
									<Link to="/login">Do you want to log in?</Link>
								</>
							);
							props.setModalMessage(userExistsMsg);
							props.toggleModal();
						} else if (!validate(props.roasterData.email)) {
							const emailInvalidMsg = isPt()
								? "Selecione um email válido!"
								: isCo()
									? "Selecciona un email válido!"
									: "Select a valid email!";
							props.setModalMessage(emailInvalidMsg);
							props.toggleModal();
						} else if (!passwordMatches) {
							const passwordMsg = isPt()
								? "Senha e confirmação devem ser iguais!"
								: isCo()
									? "La contraseña y la confirmación deben ser las mismas!"
									: "Password and confirmation must match!";
							props.setModalMessage(passwordMsg);
							props.toggleModal();
						} else props.setStep(1);
						nextButton.style.pointerEvents = "auto";
						nextButton.innerHTML = isPt()
							? farmlyTexts.signupNext_pt
							: farmlyTexts.signupNext_en;
					}}
				>
					{isPt()
						? farmlyTexts.signupNext_pt
						: isCo()
							? "Siguiente"
							: farmlyTexts.signupNext_en}
				</SignUpBasicButton>
				<GoogleLoginContainer>
					<GoogleLogin
						clientId="503177513332-b2g42k0864opttrsn7v6v8e2o3imvse5.apps.googleusercontent.com"
						buttonText={
							isPt()
								? "Entrar com Google"
								: isCo()
									? `Registro en Google`
									: "Sign in with Google"
						}
						onSuccess={responseGoogle}
						onFailure={responseGoogle}
						cookiePolicy={"single_host_origin"}
					/>
				</GoogleLoginContainer>
			</SignUpRow>
		</BasicInfoSample>
	);
};

export default BasicInfoSampleCart;
