import React from 'react'
import InputMask from 'react-input-mask'
import './ProducerForm.css'
import { isPt, farmlyTexts, isCo } from '../../common'
import { useEffect, useState } from 'react'
import ReactGA from 'react-ga'

const ProducerForm = props => {

    const { prev, next, producerData, setProducerData, coffeeScores, setCoffeeScores, regionOptions, setModalMessage, toggleModal } = props

    const [fromGoogle, setFromGoogle] = useState(producerData.password ? false : true)
    const [passwordsDontMatch, setPasswordsDontMatch] = useState(false)

    const farmNameNotFilled = (
        producerData.farmName !== ''
    )

    const phoneNotFilled = (
        producerData.phoneNumber !== ''
    )

    const checkNumber = (text, object) => {
        if (text === '' || !isNaN(text.charAt(text.length - 1))) setProducerData(object)
    }
    const renderOpts = regionOptions =>
        regionOptions.map((opt) => {
            return <option key={opt.name} value={opt.name}>{opt.name}</option>
        })

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    //REPORTS ANALYTICS
    useEffect(() => {
        ReactGA.event({
            category: 'SignUp - Producer Form',
            action: `Visited the Producer Form Page`
        })
    }, [])
    const clickInputFarmName = () => {
        ReactGA.event({
            category: 'SignUp - Producer Form',
            action: `New producer clicked on farm name input`
        })
    }
    const clickInputPhone = () => {
        ReactGA.event({
            category: 'SignUp - Producer Form',
            action: `New producer clicked on phone input`
        })
    }
    const clickGoBackButton = () => {
        ReactGA.event({
            category: 'SignUp - Producer Form',
            action: `New producer clicked Go Back button`
        })
    }
    const clickNextButton = () => {
        ReactGA.event({
            category: 'SignUp - Producer Form',
            action: `New producer clicked next button, finished main info and subimitted`
        })
    }


    return (
        <div id="producer-form">
            <div className="signup-title-row">
                <div className="coffee-icon" />
                <div className="signup-compound-title" style={{ display: 'flex', flexDirection: 'column' }}>
                    <label className="signup-title">{isPt() ? farmlyTexts.signupAlmostThere_pt : (isCo() ? '¡Ya casi está!' : farmlyTexts.signupAlmostThere_en)}</label>
                    <label className="signup-subtitle">{isPt() ? farmlyTexts.signupRequiredFields_pt : (isCo() ? '¡Los campos marcados con * son obligatorios!' : farmlyTexts.signupRequiredFields_en)}</label>
                </div>
            </div>
            <div className="signup-row">
                <label className="signup-field-label">{isPt() ? farmlyTexts.signupFarmName_pt + ' *' : (isCo() ? 'Nombre de la granja *' : farmlyTexts.signupFarmName_en + ' *')}</label>
                <input type="text" className="signup-field-input" value={producerData.farmName}
                    onChange={e => setProducerData({ ...producerData, farmName: e.target.value })}
                    placeholder={isPt() ? farmlyTexts.signupFarmName_pt : (isCo() ? 'Nombre de la granja' : farmlyTexts.signupFarmName_en)}
                    onClick={() => { clickInputFarmName() }}
                />
            </div>
            <div className="signup-row">
                <div className="prod-form-compound-field prod-form-compound-first">
                    <label className="signup-field-label">{isPt() ? farmlyTexts.signupPhone_pt + ' *' : (isCo() ? 'Teléfono *' : farmlyTexts.signupPhone_en + ' *')}</label>

                    <InputMask className="signup-field-input prod-form-field-input" mask="(99) 99999-9999" maskChar=" "
                        value={producerData.phoneNumber}
                        style={{ width: '100%' }}
                        onChange={(e) => setProducerData({ ...producerData, phoneNumber: e.target.value })}
                        placeholder={isPt() ? farmlyTexts.signupPhone_pt : (isCo() ? 'Teléfono' : farmlyTexts.signupPhone_en)} id="prod-form-phone"
                        onClick={() => { clickInputPhone() }}
                    />
                </div>
                {/* {fromGoogle &&
                <>
                <div className="signup-row">
                    <label className="signup-field-label">{isPt() ? farmlyTexts.signupPasswordLabel_pt + ' *' : (isCo() ? 'Elija su contraseña *' : farmlyTexts.signupPasswordLabel_en + ' *')}</label>
                    <input type="password" className="signup-field-input" value={producerData.password}
                        onChange={e => setProducerData({ ...producerData, password: e.target.value })}
                        placeholder={isPt() ? farmlyTexts.loginPassword_pt : (isCo() ? `Contraseña` : farmlyTexts.loginPassword_en)} />
                </div>
                <div className="signup-row">
                <label className="signup-field-label">{isPt() ? farmlyTexts.signupConfirmPassword_pt + ' *' : (isCo() ? 'Confirma tu contraseña *' : farmlyTexts.signupConfirmPassword_en+ ' *')}</label>
                        <input type="password" className="signup-field-input" value={producerData.confirmPassword}
                            onChange={e => setProducerData({ ...producerData, confirmPassword: e.target.value })}
                            placeholder={isPt() ? farmlyTexts.signupConfirmPassword_pt : (isCo() ? 'Confirma tu contraseña *' : farmlyTexts.signupConfirmPassword_en)} />
                </div>
                </>
                } */}
                {/* <div className="prod-form-compound-field">
                    <label className="signup-field-label">{isPt() ? farmlyTexts.signupFarmSize_pt + ' (Ha)' : farmlyTexts.signupFarmSize_en + ' (Ha)'}</label>
                    <input type="text" className="signup-field-input prod-form-field-input" value={producerData.farmSize}
                        onChange={e => checkNumber(e.target.value, { ...producerData, farmSize: e.target.value})}
                        placeholder='Ha' id="prod-form-farm-size" />
                </div>
                <div className="prod-form-compound-field">
                    <label className="signup-field-label">{isPt() ? farmlyTexts.signupFarmHeight_pt + ' (m)' : farmlyTexts.signupFarmHeight_en + ' (m)'}</label>
                    <input type="text" className="signup-field-input prod-form-field-input" value={producerData.farmHeight}
                        onChange={e => checkNumber(e.target.value, { ...producerData, farmHeight: e.target.value})}
                        placeholder='m' id="prod-form-height" />
                </div> */}
            </div>
            {/* <div className="signup-row prod-form-compound-row">
                <div className="prod-form-compound-field prod-form-compound-first">
                    <label className="signup-field-label">{isPt() ? farmlyTexts.signupRegion_pt + ' *' : farmlyTexts.signupRegion_en + ' *'}</label>
                    <input type="text" name='region-list' className="signup-field-input prod-form-field-input" value={producerData.region}
                        list='region-list' onChange={(e) => { setProducerData({ ...producerData, region: e.target.value }) }}
                        placeholder={isPt() ? farmlyTexts.signupRegion_pt : farmlyTexts.signupRegion_en} id="prod-form-region" />
                    <datalist id='region-list'>
                        {renderOpts(regionOptions)}
                    </datalist>
                </div>
                <div className="prod-form-compound-field">
                    <label className="signup-field-label">{isPt() ? farmlyTexts.signupAverageYield_pt + ' (Sacas de 60kg)' : farmlyTexts.signupAverageYield_en + ' (60kg Sacks)'}</label>
                    <input type="text" className="signup-field-input prod-form-field-input" value={producerData.averageYield}
                        onChange={e => checkNumber(e.target.value, { ...producerData, averageYield: e.target.value })}
                        placeholder={isPt() ? 'Sacas' : 'Sacks'} id="prod-form-yield" />
                </div>
            </div>
            <div className="signup-row">
                <label className="signup-field-label">{isPt() ? farmlyTexts.signupVarieties_pt : farmlyTexts.signupVarieties_en}</label>
                <input type="text" className="signup-field-input" value={producerData.varieties}
                    onChange={e => setProducerData({ ...producerData, varieties: e.target.value })}
                    placeholder="Catuai, Catucaí..." />
            </div>
            <div className="signup-row">
                <label className="signup-field-label">{isPt() ? farmlyTexts.signupProdCoffeeScores_pt : farmlyTexts.signupProdCoffeeScores_en}</label>
            </div>
            <div className="signup-row signup-checkboxes">
                <div className="signup-checkbox-item"
                    onClick={() => setCoffeeScores({ ...coffeeScores, sub80: coffeeScores.sub80 ? '' : 'sub80,' })}>
                    <div className="signup-checkbox"
                        style={{ backgroundColor: coffeeScores.sub80 ? 'var(--farmly-blueStone)' : 'white' }} />
                    <label className="signup-checkbox-label">{isPt() ? farmlyTexts.signupBelow80_pt : farmlyTexts.signupBelow80_en}</label>
                </div>
                <div className="signup-checkbox-item"
                    onClick={() => setCoffeeScores({ ...coffeeScores, s80_82: coffeeScores.s80_82 ? '' : '80_82,' })}>
                    <div className="signup-checkbox"
                        style={{ backgroundColor: coffeeScores.s80_82 ? 'var(--farmly-blueStone)' : 'white' }} />
                    <label className="signup-checkbox-label">{isPt() ? farmlyTexts.signup8082_pt : farmlyTexts.signup8082_en}</label>
                </div>
                <div className="signup-checkbox-item"
                    onClick={() => setCoffeeScores({ ...coffeeScores, s82_84: coffeeScores.s82_84 ? '' : '82_84,' })}>
                    <div className="signup-checkbox"
                        style={{ backgroundColor: coffeeScores.s82_84 ? 'var(--farmly-blueStone)' : 'white' }} />
                    <label className="signup-checkbox-label">{isPt() ? farmlyTexts.signup8284_pt : farmlyTexts.signup8284_en}</label>
                </div>
                <div className="signup-checkbox-item"
                    onClick={() => setCoffeeScores({ ...coffeeScores, s84_86: coffeeScores.s84_86 ? '' : '84_86,' })}>
                    <div className="signup-checkbox"
                        style={{ backgroundColor: coffeeScores.s84_86 ? 'var(--farmly-blueStone)' : 'white' }} />
                    <label className="signup-checkbox-label">{isPt() ? farmlyTexts.signup8486_pt : farmlyTexts.signup8486_en}</label>
                </div>
                <div className="signup-checkbox-item"
                    onClick={() => setCoffeeScores({ ...coffeeScores, s86_88: coffeeScores.s86_88 ? '' : '86_88,' })}>
                    <div className="signup-checkbox"
                        style={{ backgroundColor: coffeeScores.s86_88 ? 'var(--farmly-blueStone)' : 'white' }} />
                    <label className="signup-checkbox-label">{isPt() ? farmlyTexts.signup8688_pt : farmlyTexts.signup8688_en}</label>
                </div>
                <div className="signup-checkbox-item"
                    onClick={() => setCoffeeScores({ ...coffeeScores, over88: coffeeScores.over88 ? '' : 'over88,' })}>
                    <div className="signup-checkbox"
                        style={{ backgroundColor: coffeeScores.over88 ? 'var(--farmly-blueStone)' : 'white' }} />
                    <label className="signup-checkbox-label">{isPt() ? farmlyTexts.signupOver88_pt : farmlyTexts.signupOver88_en}</label>
                </div>
            </div>
            <div className="signup-row prod-form-bottom-compound-row">
                <div className="prod-form-bottom-compound-field prod-form-bottom-compound-first">
                    <label className="signup-field-label">{isPt() ? farmlyTexts.signupHarvestDate_pt : farmlyTexts.signupHarvestDate_en}</label>
                    <InputMask value={producerData.harvestDate} className="signup-field-input"
                        onChange={(e) => setProducerData({ ...producerData, harvestDate: e.target.value })}
                        placeholder={isPt() ? farmlyTexts.signupProdDate_pt : farmlyTexts.signupProdDate_en} id="prod-form-harvest" />
                </div>
            </div> */}
            {passwordsDontMatch && <label className="login-fail-label">{isPt() ? 'Senha e confirmação devem ser iguais!' : (isCo() ? 'La contraseña y la confirmación deben ser las mismas!' : 'Password and confirmation must match!')}</label>}
            <div className="signup-compound-buttons">
                <div className="signup-back-button"
                    onClick={() => {
                        clickGoBackButton();
                        prev();
                    }}
                >
                    {isPt() ? farmlyTexts.signupGoBack_pt : (isCo() ? 'Regresar' : farmlyTexts.signupGoBack_en)}
                </div>
                <div onClick={async () => {
                    setPasswordsDontMatch(false)
                    const nextButton = document.querySelector('.signup-next-button')

                    if (!farmNameNotFilled || !phoneNotFilled) {
                        if (!phoneNotFilled && !farmNameNotFilled) {
                            const requiredMsg = isPt() ? 'Preencha todos os campos marcados com * antes de continuar' : (isCo() ? 'Rellene todos los campos marcados con * antes de continuar' : 'Fill out all fields with an * before continuing')
                            setModalMessage(requiredMsg)
                            toggleModal()
                        } else if (!farmNameNotFilled) {
                            const requiredMsg = isPt() ? 'O campo "Nome da Fazenda" é obrigatório e não foi preenchido' : (isCo() ? 'El campo "Nombre de la Granja" no ha sido rellenado.' : 'The field "Farm Name" is missing')
                            setModalMessage(requiredMsg)
                            toggleModal()
                        } else {
                            const requiredMsg = isPt() ? 'O campo "Telefone" é obrigatório e não foi preenchido' : (isCo() ? 'El campo "Teléfono" no está rellenado.' : 'The field "Phone" is missing')
                            setModalMessage(requiredMsg)
                            toggleModal()
                        }

                    }
                    else {
                        if (producerData.password !== producerData.confirmPassword) {
                            setPasswordsDontMatch(true)
                            return
                        }
                        nextButton.style.pointerEvents = 'auto'
                        next()
                        clickNextButton()                    
                    }
                }} className="signup-next-button">
                    {isPt() ? farmlyTexts.signupNext_pt : (isCo() ? 'Siguiente' : farmlyTexts.signupNext_en)}
                </div>
            </div>
        </div>
    )
}

export default ProducerForm