import React, { Fragment, FunctionComponent } from 'react';

import IntlCoffeeCard from 'components/IntlCoffeeCard';

import { Container } from './styles';

interface TestingProps {
    
}
 
const Testing: FunctionComponent<TestingProps> = () => {
    return ( 
        <Fragment>
            <h1>Testing components</h1>
            <Container>
                <IntlCoffeeCard/>
            </Container>
        </Fragment>
     );
}
 
export default Testing;
