import React from 'react'
import { isBr } from '../../common'
import './PricingInfo.css'

const PricingInfo = props => {

    const {
        producerFee,
        logistics_fee,
        import_duties,
        farmly_fee,
        brazil,
        IRPJ_CSLL, 
        type
    } = props

    console.log(producerFee, logistics_fee, import_duties, farmly_fee, IRPJ_CSLL)
    if(type === 1){
        return (
            // <div className="pricing-info-container" style={{minWidth: '400px'}}>
            //     <label className="pricing-info-title">{isBr() && brazil ? 'Detalhes do preço' : 'Pricing Details'}</label>
            //     <div className="pricing-info-item">
            //         <label className="pricing-info-label pricing-label-values"> {isBr() ? 'Produtor:' : 'Producer:'} </label>
            //         <label className="pricing-info-value">{isBr() && brazil ? "R$" : "€"}{brazil ? (producerFee/60).toFixed(2) : (producerFee/70).toFixed(2)}/kg</label>
            //     </div>
            <div className="pricing-info-container" style={{minWidth: '400px'}}>
                <label className="pricing-info-title">{isBr() && brazil ? 'Detalhes do preço' : 'Pricing Details'}</label>
                <div className="pricing-info-item">
                    <label className="pricing-info-label pricing-label-values"> {isBr() ? 'Produtor:' : 'Producer:'} </label>
                    <label className="pricing-info-value">{isBr() && brazil ? "R$" : "€"}{producerFee}/kg</label>
                </div>
                {/* <div className="pricing-info-item">
                    <label className="pricing-info-label pricing-label-values">{isBr() ? 'Logística' : 'Logistics*'}:</label>
                    <label className="pricing-info-value">{isBr() && brazil ? "R$" : "€"}{brazil ? (logistics_fee/60).toFixed(2) : (logistics_fee/70).toFixed(2)}/kg</label>
                </div> */}
                <div className="pricing-info-item">
                    <label className="pricing-info-label pricing-label-values">{isBr() ? 'Logística' : 'Logistics*'}:</label>
                    <label className="pricing-info-value">{isBr() && brazil ? "R$" : "€"}{logistics_fee}/kg</label>
                </div>
                {/* <div className="pricing-info-item">
                    <label className="pricing-info-label pricing-label-values">{isBr() ? 'Taxa de importação' : 'Import tax'}:</label>
                    <label className="pricing-info-value">{isBr() && brazil ? "R$" : "€"}{brazil ? parseFloat(import_duties).toFixed(2): parseFloat(import_duties).toFixed(2)}/kg</label>
                </div> */}
                <div className="pricing-info-item">
                    <label className="pricing-info-label pricing-label-values">{isBr() ? 'Taxa de importação' : 'Import tax'}:</label>
                    <label className="pricing-info-value">{import_duties}/kg</label>
                </div>
                {/* <div className="pricing-info-item">
                    <label className="pricing-info-label pricing-label-values">{isBr() ? 'Taxa da FARMly' : 'FARMly fee'}:</label>
                    <label className="pricing-info-value">{isBr() && brazil ? "R$" : "€"}{brazil ? (farmly_fee/60).toFixed(2) : (farmly_fee/70).toFixed(2)}/kg</label>
                </div> */}
                 <div className="pricing-info-item">
                    <label className="pricing-info-label pricing-label-values">{isBr() ? 'Taxa da FARMly' : 'FARMly fee'}:</label>
                    <label className="pricing-info-value">{isNaN(farmly_fee) ? parseFloat(farmly_fee): farmly_fee}/kg</label>
                </div>
                    {/* <div className="pricing-info-item">
                    <label className="pricing-info-label pricing-label-values">{'IRPJ'}</label>
                    <label className="pricing-info-value">{isBr() && brazil ? "R$" : "€"}{brazil ? IRPJ_CSLL: 0}/Kg</label>
                </div> */}
                <div className="pricing-info-item">
                    <label className="pricing-info-label pricing-label-values">Total{isBr() ? '' : '**'}:</label>
                    <label className="pricing-info-value">{isBr() && brazil ? "R$" : "€"}{parseFloat(producerFee + logistics_fee + farmly_fee + import_duties).toFixed(2)}/kg</label>
                    
                    {/* <label className="pricing-info-value">{isBr() && brazil ? "R$" : "€"}{brazil ? ((parseFloat(producerFee) + logistics_fee + farmly_fee + import_duties)/60).toFixed(2) : (((parseFloat(producerFee) + logistics_fee + farmly_fee + import_duties)/70)).toFixed(2)}/kg</label> */}
                    {/* <label className="pricing-info-value">{isBr() && brazil ? "R$" : "€"}{brazil ? ((parseFloat(producerFee) + parseFloat(logistics_fee) + parseFloat(farmly_fee) + parseFloat(import_duties))/60).toFixed(2) : (((parseInt(producerFee) + parseInt(logistics_fee) + parseInt(farmly_fee) + parseInt(import_duties))/70)).toFixed(2)}/kg</label> */}
                </div>
                
                {/* <label className="pricing-info-title">{reais ? 'Detalhes do preço' : 'Pricing Details'}</label>
                <div className="pricing-info-item">
                    <label className="pricing-info-label pricing-label-values"> {reais ? 'Produtor:' : 'Producer:'} </label>
                    <label className="pricing-info-value">{reais ? "R$" : "€"}{reais ? (producerFee/60).toFixed(2) : producerFee.toFixed(2)}</label>
                </div>
                <div className="pricing-info-item">
                    <label className="pricing-info-label pricing-label-values">{reais ? 'Logística' : 'Logistics*'}:</label>
                    <label className="pricing-info-value">{reais ? "R$" : "€"}{reais ? (logistics_fee/60).toFixed(2) : logistics_fee.toFixed(2)}</label>
                </div>
                <div className="pricing-info-item">
                    <label className="pricing-info-label pricing-label-values">{reais ? 'Taxa de importação' : 'Import tax'}:</label>
                    <label className="pricing-info-value">{reais ? "R$" : "€"}{reais ? (import_duties/60).toFixed(2) : import_duties.toFixed(2)}</label>
                </div>
                <div className="pricing-info-item">
                    <label className="pricing-info-label pricing-label-values">{reais ? 'Taxa da FARMly' : 'FARMly fee'}:</label>
                    <label className="pricing-info-value">{reais ? "R$" : "€"}{reais ? (farmly_fee/60).toFixed(2) : farmly_fee.toFixed(2)}</label>
                </div>
                <div className="pricing-info-item">
                    <label className="pricing-info-label pricing-label-values">Total{reais ? '' : '**'}:</label>
                    <label className="pricing-info-value">{reais ? "R$" : "€"}{reais ? ((producerFee + logistics_fee + farmly_fee + import_duties)/60).toFixed(2) : (producerFee + logistics_fee + farmly_fee + import_duties).toFixed(2)}</label>
                </div> */}
                {!isBr() &&  <div className="pricing-info-item" style={{ borderBottom: 'none', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <label className="pricing-info-label" style={{ fontSize: '1em' }}>
                        * Shipping fees depend on DHL and vary for each region.
                                </label>
                    <label className="pricing-info-label" style={{ fontSize: '1em', marginTop: '5px' }}>
                        ** Exc VAT.
                                </label>
                </div>}
            </div>
        )
    }
    else {
        return (
            <div className="pricing-info-container" style={{minWidth: '400px'}}>
                <label className="pricing-info-title">{isBr() && brazil ? 'Detalhes do preço' : 'Pricing Details'}</label>
                <div className="pricing-info-item">
                    <label className="pricing-info-label pricing-label-values"> {isBr() ? 'Produtor:' : 'Producer:'} </label>
                    <label className="pricing-info-value">{isBr() && brazil ? "R$" : "€"}{brazil ? (producerFee/60).toFixed(2) : (producerFee/70).toFixed(2)}/kg</label>
                </div>
                <div className="pricing-info-item">
                    <label className="pricing-info-label pricing-label-values">{isBr() ? 'Logística' : 'Logistics*'}:</label>
                    <label className="pricing-info-value">{isBr() && brazil ? "R$" : "€"}{brazil ? (logistics_fee/60).toFixed(2) : (logistics_fee/70).toFixed(2)}/kg</label>
                </div>
                <div className="pricing-info-item">
                    <label className="pricing-info-label pricing-label-values">{isBr() ? 'Taxa de importação' : 'Import tax'}:</label>
                    <label className="pricing-info-value">{isBr() && brazil ? "R$" : "€"}{brazil ? (import_duties/60).toFixed(2) : (import_duties/70).toFixed(2)}/kg</label>
                </div>
                <div className="pricing-info-item">
                    <label className="pricing-info-label pricing-label-values">{isBr() ? 'Taxa da FARMly' : 'FARMly fee'}:</label>
                    <label className="pricing-info-value">{isBr() && brazil ? "R$" : "€"}{brazil ? (farmly_fee/60).toFixed(2) : (farmly_fee/70).toFixed(2)}/kg</label>
                </div>
                    {/* <div className="pricing-info-item">
                    <label className="pricing-info-label pricing-label-values">{'IRPJ'}</label>
                    <label className="pricing-info-value">{isBr() && brazil ? "R$" : "€"}{brazil ? IRPJ_CSLL: 0}/Kg</label>
                </div> */}
                <div className="pricing-info-item">
                    <label className="pricing-info-label pricing-label-values">Total{isBr() ? '' : '**'}:</label>
                    {/* <label className="pricing-info-value">{isBr() && brazil ? "R$" : "€"}{parseFloat(producerFee + logistics_fee + farmly_fee + import_duties + IRPJ_CSLL).toFixed(2)}/kg</label> */}
                    <label className="pricing-info-value">{isBr() && brazil ? "R$" : "€"}{brazil ? ((parseFloat(producerFee) + logistics_fee + farmly_fee + import_duties)/60).toFixed(2) : (((parseFloat(producerFee) + logistics_fee + farmly_fee + import_duties)/70)).toFixed(2)}/kg</label>
                    {/* <label className="pricing-info-value">{isBr() && brazil ? "R$" : "€"}{brazil ? ((parseFloat(producerFee) + parseFloat(logistics_fee) + parseFloat(farmly_fee) + parseFloat(import_duties))/60).toFixed(2) : (((parseInt(producerFee) + parseInt(logistics_fee) + parseInt(farmly_fee) + parseInt(import_duties))/70)).toFixed(2)}/kg</label> */}
                </div>
                
                {/* <label className="pricing-info-title">{reais ? 'Detalhes do preço' : 'Pricing Details'}</label>
                <div className="pricing-info-item">
                    <label className="pricing-info-label pricing-label-values"> {reais ? 'Produtor:' : 'Producer:'} </label>
                    <label className="pricing-info-value">{reais ? "R$" : "€"}{reais ? (producerFee/60).toFixed(2) : producerFee.toFixed(2)}</label>
                </div>
                <div className="pricing-info-item">
                    <label className="pricing-info-label pricing-label-values">{reais ? 'Logística' : 'Logistics*'}:</label>
                    <label className="pricing-info-value">{reais ? "R$" : "€"}{reais ? (logistics_fee/60).toFixed(2) : logistics_fee.toFixed(2)}</label>
                </div>
                <div className="pricing-info-item">
                    <label className="pricing-info-label pricing-label-values">{reais ? 'Taxa de importação' : 'Import tax'}:</label>
                    <label className="pricing-info-value">{reais ? "R$" : "€"}{reais ? (import_duties/60).toFixed(2) : import_duties.toFixed(2)}</label>
                </div>
                <div className="pricing-info-item">
                    <label className="pricing-info-label pricing-label-values">{reais ? 'Taxa da FARMly' : 'FARMly fee'}:</label>
                    <label className="pricing-info-value">{reais ? "R$" : "€"}{reais ? (farmly_fee/60).toFixed(2) : farmly_fee.toFixed(2)}</label>
                </div>
                <div className="pricing-info-item">
                    <label className="pricing-info-label pricing-label-values">Total{reais ? '' : '**'}:</label>
                    <label className="pricing-info-value">{reais ? "R$" : "€"}{reais ? ((producerFee + logistics_fee + farmly_fee + import_duties)/60).toFixed(2) : (producerFee + logistics_fee + farmly_fee + import_duties).toFixed(2)}</label>
                </div> */}
                {!isBr() &&  <div className="pricing-info-item" style={{ borderBottom: 'none', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <label className="pricing-info-label" style={{ fontSize: '1em' }}>
                        * Shipping fees depend on DHL and vary for each region.
                                </label>
                    <label className="pricing-info-label" style={{ fontSize: '1em', marginTop: '5px' }}>
                        ** Exc VAT.
                                </label>
                </div>}
            </div>
        )
    
    }

    // return (
    //     // <div className="pricing-info-container" style={{minWidth: '400px'}}>
    //     //     <label className="pricing-info-title">{isBr() && brazil ? 'Detalhes do preço' : 'Pricing Details'}</label>
    //     //     <div className="pricing-info-item">
    //     //         <label className="pricing-info-label pricing-label-values"> {isBr() ? 'Produtor:' : 'Producer:'} </label>
    //     //         <label className="pricing-info-value">{isBr() && brazil ? "R$" : "€"}{brazil ? (producerFee/60).toFixed(2) : (producerFee/70).toFixed(2)}/kg</label>
    //     //     </div>
    //     <div className="pricing-info-container" style={{minWidth: '400px'}}>
    //         <label className="pricing-info-title">{isBr() && brazil ? 'Detalhes do preço' : 'Pricing Details'}</label>
    //         <div className="pricing-info-item">
    //             <label className="pricing-info-label pricing-label-values"> {isBr() ? 'Produtor:' : 'Producer:'} </label>
    //             <label className="pricing-info-value">{isBr() && brazil ? "R$" : "€"}{producerFee}/kg</label>
    //         </div>
    //         {/* <div className="pricing-info-item">
    //             <label className="pricing-info-label pricing-label-values">{isBr() ? 'Logística' : 'Logistics*'}:</label>
    //             <label className="pricing-info-value">{isBr() && brazil ? "R$" : "€"}{brazil ? (logistics_fee/60).toFixed(2) : (logistics_fee/70).toFixed(2)}/kg</label>
    //         </div> */}
    //         <div className="pricing-info-item">
    //             <label className="pricing-info-label pricing-label-values">{isBr() ? 'Logística' : 'Logistics*'}:</label>
    //             <label className="pricing-info-value">{isBr() && brazil ? "R$" : "€"}{logistics_fee}/kg</label>
    //         </div>
    //         {/* <div className="pricing-info-item">
    //             <label className="pricing-info-label pricing-label-values">{isBr() ? 'Taxa de importação' : 'Import tax'}:</label>
    //             <label className="pricing-info-value">{isBr() && brazil ? "R$" : "€"}{brazil ? parseFloat(import_duties).toFixed(2): parseFloat(import_duties).toFixed(2)}/kg</label>
    //         </div> */}
    //         <div className="pricing-info-item">
    //             <label className="pricing-info-label pricing-label-values">{isBr() ? 'Taxa de importação' : 'Import tax'}:</label>
    //             <label className="pricing-info-value">{import_duties}/kg</label>
    //         </div>
    //         {/* <div className="pricing-info-item">
    //             <label className="pricing-info-label pricing-label-values">{isBr() ? 'Taxa da FARMly' : 'FARMly fee'}:</label>
    //             <label className="pricing-info-value">{isBr() && brazil ? "R$" : "€"}{brazil ? (farmly_fee/60).toFixed(2) : (farmly_fee/70).toFixed(2)}/kg</label>
    //         </div> */}
    //          <div className="pricing-info-item">
    //             <label className="pricing-info-label pricing-label-values">{isBr() ? 'Taxa da FARMly' : 'FARMly fee'}:</label>
    //             <label className="pricing-info-value">{isNaN(farmly_fee) ? parseFloat(farmly_fee): farmly_fee}/kg</label>
    //         </div>
    //             <div className="pricing-info-item">
    //             <label className="pricing-info-label pricing-label-values">{'IRPJ'}</label>
    //             <label className="pricing-info-value">{isBr() && brazil ? "R$" : "€"}{brazil ? IRPJ_CSLL: 0}/Kg</label>
    //         </div>
    //         <div className="pricing-info-item">
    //             <label className="pricing-info-label pricing-label-values">Total{isBr() ? '' : '**'}:</label>
    //             <label className="pricing-info-value">{isBr() && brazil ? "R$" : "€"}{parseFloat(producerFee + logistics_fee + farmly_fee + import_duties + IRPJ_CSLL).toFixed(2)}/kg</label>

    //             {/* <label className="pricing-info-value">{isBr() && brazil ? "R$" : "€"}{brazil ? ((parseFloat(producerFee) + logistics_fee + farmly_fee + import_duties)/60).toFixed(2) : (((parseFloat(producerFee) + logistics_fee + farmly_fee + import_duties)/70)).toFixed(2)}/kg</label> */}
    //             {/* <label className="pricing-info-value">{isBr() && brazil ? "R$" : "€"}{brazil ? ((parseFloat(producerFee) + parseFloat(logistics_fee) + parseFloat(farmly_fee) + parseFloat(import_duties))/60).toFixed(2) : (((parseInt(producerFee) + parseInt(logistics_fee) + parseInt(farmly_fee) + parseInt(import_duties))/70)).toFixed(2)}/kg</label> */}
    //         </div>
            
    //         {/* <label className="pricing-info-title">{reais ? 'Detalhes do preço' : 'Pricing Details'}</label>
    //         <div className="pricing-info-item">
    //             <label className="pricing-info-label pricing-label-values"> {reais ? 'Produtor:' : 'Producer:'} </label>
    //             <label className="pricing-info-value">{reais ? "R$" : "€"}{reais ? (producerFee/60).toFixed(2) : producerFee.toFixed(2)}</label>
    //         </div>
    //         <div className="pricing-info-item">
    //             <label className="pricing-info-label pricing-label-values">{reais ? 'Logística' : 'Logistics*'}:</label>
    //             <label className="pricing-info-value">{reais ? "R$" : "€"}{reais ? (logistics_fee/60).toFixed(2) : logistics_fee.toFixed(2)}</label>
    //         </div>
    //         <div className="pricing-info-item">
    //             <label className="pricing-info-label pricing-label-values">{reais ? 'Taxa de importação' : 'Import tax'}:</label>
    //             <label className="pricing-info-value">{reais ? "R$" : "€"}{reais ? (import_duties/60).toFixed(2) : import_duties.toFixed(2)}</label>
    //         </div>
    //         <div className="pricing-info-item">
    //             <label className="pricing-info-label pricing-label-values">{reais ? 'Taxa da FARMly' : 'FARMly fee'}:</label>
    //             <label className="pricing-info-value">{reais ? "R$" : "€"}{reais ? (farmly_fee/60).toFixed(2) : farmly_fee.toFixed(2)}</label>
    //         </div>
    //         <div className="pricing-info-item">
    //             <label className="pricing-info-label pricing-label-values">Total{reais ? '' : '**'}:</label>
    //             <label className="pricing-info-value">{reais ? "R$" : "€"}{reais ? ((producerFee + logistics_fee + farmly_fee + import_duties)/60).toFixed(2) : (producerFee + logistics_fee + farmly_fee + import_duties).toFixed(2)}</label>
    //         </div> */}
    //         {!isBr() &&  <div className="pricing-info-item" style={{ borderBottom: 'none', flexDirection: 'column', alignItems: 'flex-start' }}>
    //             <label className="pricing-info-label" style={{ fontSize: '1em' }}>
    //                 * Shipping fees depend on DHL and vary for each region.
    //                         </label>
    //             <label className="pricing-info-label" style={{ fontSize: '1em', marginTop: '5px' }}>
    //                 ** Exc VAT.
    //                         </label>
    //         </div>}
    //     </div>
    // )
}

export default PricingInfo