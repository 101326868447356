import React from 'react'
import './SearchBarOld.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { isPt, isCo } from '../../../common'

const SearchBarOld = props => {

    const { query, setQuery, submitQuery } = props

    return (
        <div className="farmly-search-bar-container">
            <div className="farmly-search-submit" onClick={submitQuery} >
                <FontAwesomeIcon className="farmly-search-icon" icon={faSearch} />
            </div>
            <input type="text" placeholder={isPt() || isCo() ? "Buscar" : "Search"} className="farmly-search-bar" id={props.idName||"search-bar"}
                value={query} onChange={e => setQuery(e.target.value)} />
        </div>
    )
}

export default SearchBarOld