import * as React from 'react';
import { FunctionComponent } from "react"

import { Container, CardDividerSVG1, CardDividerSVG2, CardDividerSVG3, CardDividerSVG4 } from './styles'

interface CardDividerProps {
    
}
 
const CardDivider: FunctionComponent<CardDividerProps> = () => {
    return (
        <Container>
            <img className="fst-d" src={CardDividerSVG1} alt="bottom-margin-1" />
            <img className="scn-d" src={CardDividerSVG2} alt="bottom-margin-2" />
            <img className="thr-d" src={CardDividerSVG3} alt="bottom-margin-3" />
            <img className="frt-d" src={CardDividerSVG4} alt="bottom-margin-4" />
        </Container>
    );
}
 
export default CardDivider;