import React, { useState, Fragment, useContext } from 'react'
import { Map, Marker, TileLayer } from 'react-leaflet'
import { isPt, isCo, farmlyTexts, server, acceptedCookies } from '../../common'
import axios from 'axios'

import './Origin.css'

import Title from '../templates/Title.jsx'
import FarmlyInputCoffee from '../templates/FarmlyInputCoffee'
import UserContext from '../../context/user/UserContext.js'
import ProfileEditButtonsCoffee from './ProfileEditButtonsCoffee.jsx'
import Dropzone from 'react-dropzone'

const producerCropImage = require('../../assets/coffee_tree.jpeg')

const Origin = props => {

    const { producerData, coffeeData, setCoffeeData, setProducerData, handleSave, updateProducerData, hasEdited, reset,
        imageUrl, farmImageUrl, fileUpload, removeFile, selectedFileURL, onDropRejected, setSelectedFileURL,
        isOwner, regionSubtitle, info, producerDataOrigin, setProducerDataOrigin, cancelEdit } = props
    const [enDescription, setEnDescription] = useState(false)
    const [showUpdateButtons, setShowUpdateButtons] = useState(false)
    const userContext = useContext(UserContext)
    const handleMapClick = (event) => {
        setCoffeeData({ ...coffeeData, latitude: event.latlng.lat, longitude: event.latlng.lng })
    }

    return (
        <>
            <div id="coffee-origin" className={`${isPt() ? farmlyTexts.menuOriginTitle_pt : isCo() ? 'Fuente': farmlyTexts.menuOriginTitle_en} coffee-section-margin-top`}>
                <div className="coffee-producer-row">
                    <div className="coffee-producer-column">
                        <Title title={isPt() ? farmlyTexts.originTitle_pt : isCo() ? 'Fuente' : farmlyTexts.originTitle_en} showIcon uppercase ml_20 />
                        <div className="producer-intro-container">
                            {isOwner ?
                                <div className="producer-profile-row">
                                    <div className="producer-profile-item">
                                        <label htmlFor="" className="producer-profile-label">Foto de Perfil</label>
                                        <Dropzone onDrop={fileUpload} onDropRejected={onDropRejected} maxSize={2097152}
                                            accept="image/*">
                                            {({ getRootProps, getInputProps }) => (
                                                <section>
                                                    <div className="dropzone" {...getRootProps()}>
                                                        <input {...getInputProps()} accept="image/*" />
                                                        {selectedFileURL ? <img src={selectedFileURL} alt="foto de perfil" /> :
                                                            <p>Arraste ou selecione um arquivo</p>}
                                                    </div>
                                                </section>
                                            )}
                                        </Dropzone>
                                        {selectedFileURL && <p className="remove-image" onClick={removeFile}>remover imagem</p>}
                                    </div>
                                </div>
                                :
                                <img src={producerData.profileImg ? imageUrl : producerCropImage} alt="" id="producer-crop-image" className="coffee-bean-format" />
                            }
                            <div className="producer-name-title">
                                <Title title={producerData.name} subtitle={isPt() ? farmlyTexts.originSubtitle_pt: isCo() ? farmlyTexts.originSubtitle_es : farmlyTexts.originSubtitle_en} ml_20 />
                            </div>
                        </div>

                    </div>
                    {userContext.isAdmin && window.screen.width < 600 &&
                        <>
                            <div className="description-language-selection">
                                <label style={{ cursor: "pointer", fontWeight: enDescription ? 400 : 600 }}
                                    onClick={() => setEnDescription(false)}>
                                    PT
                            </label>
                                <label style={{ cursor: "pointer", fontWeight: enDescription ? 600 : 400 }}
                                    onClick={() => setEnDescription(true)}>
                                    EN
                            </label>
                            </div>
                            {!producerData.description_checked && <label>Versão em inglês não checada!</label>}
                        </>
                    }
                    {isOwner ?
                        <div className="hide-desktop">
                            <FarmlyInputCoffee textArea
                                value={(userContext.isAdmin && enDescription) ? producerData.descriptionEn : producerData.description}
                                setValue={value => {
                                    const updateObj = (userContext.isAdmin && enDescription) ?
                                        { ...producerData, descriptionEn: value, description_checked: true }
                                        : { ...producerData, description: value }
                                    setProducerData(updateObj)
                                }}
                                styleClass="producer-coffee-paragraph-input"
                                placeholder="Escreva algumas linhas sobre você" elementId="about" setShowUpdateButtons={setShowUpdateButtons} />
                            {
                                isOwner && showUpdateButtons &&
                                <ProfileEditButtonsCoffee updateUserData={updateProducerData} cancelEdit={cancelEdit} setShowUpdateButtons={setShowUpdateButtons} />
                            }
                        </div>

                        :
                        <p className="coffee-profile-paragraph hide-desktop">
                            {producerData.descriptionEn ? producerData.descriptionEn : producerData.description}
                        </p>
                    }
                    {isOwner ?

                        <Map center={producerData.longitude ? [producerData.latitude, producerData.longitude] : [-19.9023386, -44.1041386]} zoom={13} className="map-container-owner" onClick={(e) => isOwner && handleMapClick(e)}>
                            <TileLayer
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            />
                            <Marker position={producerData.longitude ? [producerData.latitude, producerData.longitude] : [-19.9023386, -44.1041386]} />
                        </Map>
                        :
                        <Map center={producerData.longitude ? [producerData.latitude, producerData.longitude] : [-19.9023386, -44.1041386]} zoom={13} className="map-container" onClick={(e) => isOwner && handleMapClick(e)}>
                            <TileLayer
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            />
                            <Marker position={producerData.longitude ? [producerData.latitude, producerData.longitude] : [-19.9023386, -44.1041386]} />
                        </Map>
                    }
                </div>
                <br />
                {userContext.isAdmin && window.screen.width > 700 &&
                    <>
                        <div className="description-language-selection">
                            <label style={{ cursor: "pointer", fontWeight: enDescription ? 400 : 600 }}
                                onClick={() => setEnDescription(false)}>
                                PT
                            </label>
                            <label style={{ cursor: "pointer", fontWeight: enDescription ? 600 : 400 }}
                                onClick={() => setEnDescription(true)}>
                                EN
                            </label>
                        </div>
                        {!producerData.description_checked && <label>Versão em inglês não checada!</label>}
                    </>
                }

                {isOwner ?
                    <div className="hide-mobile">
                        <FarmlyInputCoffee textArea
                            value={(userContext.isAdmin && enDescription) ? producerData.descriptionEn : producerData.description}
                            setValue={value => {
                                const updateObj = (userContext.isAdmin && enDescription) ?
                                    { ...producerData, descriptionEn: value, description_checked: true }
                                    : { ...producerData, description: value }
                                setProducerData(updateObj)
                            }}
                            styleClass="producer-coffee-paragraph-input"
                            placeholder="Escreva algumas linhas sobre você" elementId="about" setShowUpdateButtons={setShowUpdateButtons} />
                        {
                            isOwner && showUpdateButtons &&
                            <ProfileEditButtonsCoffee updateUserData={updateProducerData} cancelEdit={cancelEdit} setShowUpdateButtons={setShowUpdateButtons} />
                        }
                    </div>

                    :
                    <p className="coffee-profile-paragraph hide-mobile">
                        {isPt() ? producerData.description : (producerData.descriptionEn ? producerData.descriptionEn : producerData.description)}
                    </p>
                }
            </div>
        </>
    )
}

export default Origin
