import React, { FunctionComponent } from 'react'
import {
    TextField
} from '@mui/material';
import { ExpireDateLabelContainer } from './style';
import CartModel from 'modules/SampleCart/models/cartModel';
import { format } from 'date-fns'
interface InputLabelExpireDateProps {
    sampleCart: CartModel
    setUpdateDate: React.Dispatch<React.SetStateAction<boolean>>
}

const InputLabelExpireDate: FunctionComponent<InputLabelExpireDateProps> = (props) => {
    return (
        <ExpireDateLabelContainer>
            <TextField
                id="sample-cart-date"
                type="date"
                label="Expire Date"
                onChange={(e) => { props.sampleCart.id !== null && props.setUpdateDate(true) }}
                InputLabelProps={{
                    shrink: true,
                }}
                inputProps={{
                    min: format(new Date(), "yyyy-MM-dd")
                }}
            />
        </ExpireDateLabelContainer>
    );
}

export default InputLabelExpireDate;