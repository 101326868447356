import React, { FunctionComponent, useEffect, useState} from "react";
import {
	SignupBackButton, SignupCompoundButtons, SignupNextButton,
	SignUpCompoundRow, SignUpContainer, InputInformationData,
	SelectItems
} from "./style";
import { isPt, isCo, farmlyTexts } from "../../../../../common";
import { Select, MenuItem, InputLabel } from '@material-ui/core';
import Countries from "../../../../../assets/countries.json";
interface RoasterDataProps {
	country: string,
	email: string,
	name: string,
	password: string,
	confirmPassword: string,
	postalCode: string,
	address: string,
	roastery: string,
	vat: string,
	phoneNumber: string,
	sensoryNotes: string,
	coffeeScores: string
}
interface SampleCartSignUpProps {
	roasterData: RoasterDataProps,
	setStep: React.Dispatch<React.SetStateAction<number>>,
	signUpForm: () => void,
	setRoasterData: React.Dispatch<React.SetStateAction<RoasterDataProps>>,
	setModalMessage: React.Dispatch<React.SetStateAction<string>>,
	toggleModal: () => void,
}

const SampleCartSignUp: FunctionComponent<SampleCartSignUpProps> = (props) => {
	
	const [selectedCountry, setSelectedCountry] = useState(0)

	const allFilled =
		props.roasterData.country !== "" &&
		props.roasterData.postalCode !== "" &&
		props.roasterData.roastery !== "" &&
		props.roasterData.vat !== "" &&
		props.roasterData.password !== "" &&
		props.roasterData.address !== "" &&
		props.roasterData.name !== "" &&
		props.roasterData.phoneNumber !== "" &&
		props.roasterData.confirmPassword !== "";

	const countryNotFilled = props.roasterData.country !== "";

	const postalCodeNotFilled = props.roasterData.postalCode !== "";

	const addressNotFilled = props.roasterData.address !== "";

	const phoneNumberNotFilled = props.roasterData.phoneNumber !== "";

	const checkNumber = (text: any, object: any) => {
		if (text === "" || !isNaN(text.charAt(text.length - 1)))
			props.setRoasterData(object);
	};

	const countriesElectedForVatEori = [
		"United Kingdom (UK)",
	];

	const checkCountries = (country: string) => {
		if (
			countriesElectedForVatEori.find(
				(country) => country == props.roasterData.country
			) != undefined
		) {
			return (
				<InputInformationData>
					<input
						type="text"
						style={{ border: "none", outline: "none", width: "100%" }}
						disabled={!!props.roasterData.vat}
						placeholder={props.roasterData.vat === null ? "VAT/EORI" : props.roasterData.vat}
						onChange={(e) => {
							props.setRoasterData({ ...props.roasterData, vat: e.target.value });
						}}
					/>
				</InputInformationData>
			);
		}
	};

	const allCountry: Array<any> = [];
	const renderCountries = (): any => {
		Countries.map((item, idx) => {
			allCountry.push({
				label: item,
				value: idx
			})
		})
		return (
			allCountry.map((item, index) => {
				return (
					<MenuItem value={index}>
						{item.label}
					</MenuItem>
				)
			})
		)
	}

	const findCountryNameByEvent = (indexCountry: number) => {
		const foundLabel = allCountry.find(element => element.value == indexCountry)
		return foundLabel.label
	}

	const findCountryIndexByEvent = (country : string) => {
		const foundLabel = allCountry.find(element => element.label == country)
		return foundLabel.value
	}


	return (
		<SignUpContainer>
			<SignUpCompoundRow>
				<InputInformationData>
					<input
						type="password"
						value={props.roasterData.password}
						style={{ border: "none", outline: "none", width: "100%" }}
						onChange={(e) =>
							props.setRoasterData({ ...props.roasterData, password: e.target.value })
						}
						placeholder={
							isPt()
								? farmlyTexts.loginPassword_pt
								: isCo()
									? `Contraseña`
									: farmlyTexts.loginPassword_en
						}
					/>
				</InputInformationData>
				<InputInformationData>
					<input
						type="password"
						value={props.roasterData.confirmPassword}
						style={{ border: "none", outline: "none", width: "100%" }}
						onChange={(e) =>
							props.setRoasterData({
								...props.roasterData,
								confirmPassword: e.target.value,
							})
						}
						placeholder={
							isPt()
								? farmlyTexts.signupConfirmPassword_pt
								: isCo()
									? "Confirma tu contraseña"
									: farmlyTexts.signupConfirmPassword_en
						}
					/>
				</InputInformationData>
			</SignUpCompoundRow>
			<SignUpCompoundRow>
				<InputInformationData>
					<input
						type="text"
						value={props.roasterData.name}
						style={{ border: "none", outline: "none", width: "100%" }}
						placeholder={isPt() ? farmlyTexts.requestName_pt : farmlyTexts.requestName_en}
						onChange={(e) => {
							props.setRoasterData({ ...props.roasterData, name: e.target.value });
						}}
					/>
				</InputInformationData>
				<InputInformationData>
					<input
						type="text"
						style={{ border: "none", outline: "none", width: "100%" }}
						placeholder={isPt() ? farmlyTexts.requestAddress_pt : farmlyTexts.requestAddress_en}
						onChange={(e) => {
							props.setRoasterData({ ...props.roasterData, address: e.target.value });
						}}
					/>
				</InputInformationData>
			</SignUpCompoundRow>
			<SignUpCompoundRow>
				<InputInformationData>
					<input
						type="text"
						style={{ border: "none", outline: "none", width: "100%" }}
						onChange={(e) =>
							checkNumber(e.target.value, {
								...props.roasterData,
								phoneNumber: e.target.value,
							})
						}
						placeholder={isPt() ? farmlyTexts.signupPhone_pt : farmlyTexts.signupPhone_en}
					/>
				</InputInformationData>
				<InputInformationData>
					<input
						type="text"
						style={{ border: "none", outline: "none", width: "100%" }}
						placeholder={isPt() ? farmlyTexts.requestCEP_pt : farmlyTexts.requestCEP_en}
						onChange={(e) => {
							props.setRoasterData({ ...props.roasterData, postalCode: e.target.value });
						}}
					>
					</input>
				</InputInformationData>
			</SignUpCompoundRow>
			<SignUpCompoundRow>
				<InputInformationData>
					<SelectItems>
						<InputLabel
							style={{
								marginTop: "10px", overflow: "hidden",
								textOverflow: "ellipsis", whiteSpace: "nowrap"
							}}
						>
							{isPt() ? farmlyTexts.signupCountry_pt : farmlyTexts.signupCountry_en}
						</InputLabel>
						<Select
							defaultValue={0}
							style={{ border: "none", outline: "none", width: "80%" }}
							value={selectedCountry}
							onChange={(e: any) => {
								let countryName = findCountryNameByEvent(e.target.value)
								setSelectedCountry(e.target.value)
								props.setRoasterData({ ...props.roasterData, country: countryName });
							}}
						>
							{renderCountries()}
						</Select>
					</SelectItems>
				</InputInformationData>
				<InputInformationData>
					<input
						type="text"
						style={{ border: "none", outline: "none", width: "100%" }}
						placeholder={isPt() ? farmlyTexts.requestRoastery_pt : farmlyTexts.requestRoastery_en}
						onChange={(e) => {
							props.setRoasterData({ ...props.roasterData, roastery: e.target.value });
						}}
					/>
				</InputInformationData>
			</SignUpCompoundRow>
			{checkCountries(props.roasterData.country)}
			<SignupCompoundButtons>
				<SignupBackButton onClick={() => props.setStep(0)}>
					{isPt() ? farmlyTexts.signupGoBack_pt : farmlyTexts.signupGoBack_en}
				</SignupBackButton>
				<SignupNextButton
					onClick={() => {
						if (!countryNotFilled || !phoneNumberNotFilled) {
							if (!countryNotFilled && phoneNumberNotFilled) {
								props.setModalMessage(
									isPt()
										? 'O campo "País" não foi preenchido'
										: 'The field "Country" is missing'
								);
								props.toggleModal();
							} else if (countryNotFilled && !phoneNumberNotFilled) {
								props.setModalMessage(
									isPt()
										? 'O campo "Telefone" não foi preenchido'
										: 'The field "Phone" is missing'
								);
								props.toggleModal();
							} else {
								props.setModalMessage(
									isPt()
										? farmlyTexts.signupPleaseFill_pt
										: farmlyTexts.signupPleaseFill_en
								);
								props.toggleModal();
							}
						} else {
							props.signUpForm();
						}
					}}
				>
					{isPt() ? farmlyTexts.signupSend_pt : farmlyTexts.signupSend_en}
				</SignupNextButton>
			</SignupCompoundButtons>
		</SignUpContainer>
	);
};

export default SampleCartSignUp;
