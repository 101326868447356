import React, { Fragment, useState, useEffect } from "react";
import './CoffeeCuppingCard.css'
import ReactGA from 'react-ga'


const coffeeImg = require ('../../assets/producer.jpg')
const CoffeeCuppingCardOrigin = (props) => {

    const {coffeeData, setCoffeeData, setUpdateCoffee} = props
    const selectColor = coffeeData.coffee_color

    //Report Analytics
    const clickCoffeeCard = () =>{
        ReactGA.event({
            category: 'Create Coffee Cupping',
            action: 'User clicked on coffee card at the create coffee cupping page'
        })
    }

    return(
        <>
            <div className="coffee-img-and-color-cupping-card coffee-cupping-hover" onClick={() => { setCoffeeData({...coffeeData, img: null}); setUpdateCoffee(true); clickCoffeeCard() }}>
                    <div>
                        <div className="picture-modal-cupping">
                            <img className="coffee-img-cupping-card" src={coffeeData.img ? coffeeData.img : coffeeImg}></img>
                        </div>
                        <div className="select-color-cupping-card-modal-cupping" style={selectColor == 1 ? {backgroundColor: '#b73952'} : selectColor == 2 ? {backgroundColor: '#f47a54'} : selectColor==3 ? {backgroundColor: '#bba726'} : selectColor==4 ? {backgroundColor: 'rgb(27, 98, 94)'} : selectColor == 5 ? {backgroundColor:'#6dd7f3'} : {backgroundColor:'rgb(27, 98, 94)'}}>
                            <div className="coffee-name-modal-cupping">{coffeeData.name}</div>
                            <div className="description-modal-cupping">
                                {coffeeData.description}
                            </div>
                            <div className="coffee-id-modal-cupping">
                                ID: {coffeeData.coffee_id}
                            </div>
                        </div>
                    </div>
            </div>
        </>
    )
}
export default CoffeeCuppingCardOrigin
