import styled from 'styled-components';

export const CoffeeListContainer = styled.div`
    width: 100%;
    height: 100%;
`

export const CoffeeListBox = styled.div`
`

export const SampleCartItem = styled.div`
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    padding: 3px;
    margin: 5% auto;
    border-radius: 5px;
    cursor: pointer;
    justify-content: space-between;
`

export const Title = styled.p`
    text-align: center;
    transition-timing-function: ease-in-out;
    transition: all 0.3s;
`

export const LoadingImage = styled.div`
    height: 1rem;
    margin-left: 1rem;
`