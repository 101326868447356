import styled from "styled-components";

export const FiltersCoffeesContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
`;

export const CoffeesTable = styled.table`
    width: 100vw;
    padding: 2vw;
    color:rgb(27,98, 94);
    font-size: 1vw;
    font-style: normal;
`;

export const SearchBarContainer = styled.div`
    width: 100vw;
    display: flex;
    justify-content: center;
`;

export const SearchBar = styled.input`
    width: 40vw;
    font-size: 1.318vw;
    font-family: 'Poppins','Montserrat', sans-serif;
    margin-bottom: 2vw;
    font-weight:500;
    padding: 0.7vw;
`
export const CreateShippingButton = styled.input`
    height: 35px;
    width: 15vw;
    border-radius: 3px;
    display: flex;
    margin-left: 2vw;
    background-color: var(--farmly-elm);
    align-items: center;
    justify-content: center;
    color: rgb(245, 245, 245);
    cursor: pointer;
    margin-bottom: 1vw;
`