import React, { useState } from 'react'
import './Main.css'
import Cookies from 'js-cookie'

import NavCupping from './NavCupping.jsx'
import Footer from './Footer.jsx'
import FarmlyModal from './FarmlyModal.jsx'
import Title from './Title.jsx'
import { isPt, farmlyTexts, acceptedCookies } from '../../common.js'

const decorInfEsq = require('../../assets/desktop-inf-esq.png')
const decorInfDir = require('../../assets/desktop-inf-dir.png')


const eraseAllCookies = () => {
    document.cookie.split(';').forEach(cookie => {
        const name = cookie.split('=')[0]
        document.cookie = `${name.trim()}=0;max-age=0;path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;`
    })
}

export default props => {

    const [handledCookies, setHandledCookies] =
        useState(acceptedCookies())

    return (
        <div id="main-container" style={{ backgroundColor: 'white'}}>
            {(!handledCookies && !window.location.href.includes('privacy-policy')) &&
                <FarmlyModal noClose >
                    <div className="cookies-policy-container">
                        <Title title={isPt() ? farmlyTexts.cookiesTitle_pt : farmlyTexts.cookiesTitle_en} />
                        <div className="cookies-policy-text">
                            {isPt() ? farmlyTexts.cookiesText_pt : farmlyTexts.cookiesText_en}
                            <a href="/privacy-policy" style={{ textDecoration: 'none', color: 'var(--farmly-fuzzy)' }}>
                                {isPt() ? farmlyTexts.cookiesClick_pt : farmlyTexts.cookiesClick_en}
                            </a>.

                        </div>
                        <div className="cookies-policy-buttons">
                            <div className="cookies-policy-reject" onClick={() => {
                                setHandledCookies(true)
                            }}>
                                {isPt() ? farmlyTexts.cookiesReject_pt : farmlyTexts.cookiesReject_en}
                            </div>
                            <div className="cookies-policy-accept" onClick={() => {
                                Cookies.set('accepted_cookies', 'accepted_cookies', {expires: 100, path: '/'})
                                setHandledCookies(true)
                            }}>
                                {isPt() ? farmlyTexts.cookiesAccept_pt : farmlyTexts.cookiesAccept_en}
                            </div>
                        </div>
                    </div>
                </FarmlyModal>
            }
            <NavCupping isHome={props.isHome ? true : false} noBanner={props.noBanner || false} smallImage={props.smallImage} />
            <br />

            <main className="content"  >
                <div className="main-content-holder" style={{ width: '100%' , overflowY: 'hidden'}}>
                    {props.children}
                </div>
            </main>
            <img id='logo-cupping' src={decorInfEsq} alt="logo" style={{ position: 'fixed', bottom: '-5%', zIndex: '0' }} />
            {/* <img id='logo-cupping' className="img-direita-cupping" src={decorInfDir} alt="logo" style={{ float: 'right' }} /> */}

        </div>
    )
}