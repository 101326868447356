import {
    ADD_TO_LIST,
    LOAD_LIST,
    CLEAR_LIST,
    REMOVE_FROM_LIST,
    ADD_EMAIL
} from '../types.js'

export default (state, action) => {
    switch (action.type) {
        case ADD_TO_LIST:
            return {
                ...state,
                offerList: action.payload
            }
        case LOAD_LIST:
            return {
                ...state,
                offerList: action.payload
            }
        case CLEAR_LIST:
            return {
                ...state,
                offerList: []
            }
        case REMOVE_FROM_LIST:
            return {
                ...state,
                cartList: action.payload
            }
        case ADD_EMAIL:
            return {
                ...state,
                email: action.payload
            }
        default:
            return state
    }
}