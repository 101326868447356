import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import { language } from "../../common";

import "./InformationCoffeeBox.css";

const InformationCoffeeBox = props => {
    const {
        setShowInformationBox
    } = props

    return (
        <div className="coffee-container-information-box">
            <div className="coffees-container-information">
                <div className="coffees-container-information-icon-box">
                    <FontAwesomeIcon
                        className="coffees-container-information-icon"
                        icon={faInfoCircle}
                    />
                </div>
                <div className="coffees-container-information-text">
                    {
                        {
                            pt: "Nós zeramos a emissão de carbono que vem do processo de produção dos cafés que passam por aqui",
                            en: "We have zeroed the carbon emission that comes from the production process of the coffees that pass through here",
                            es: "Hemos cero la emisión de carbono que proviene del proceso de producción de los cafés que pasan por aquí"
                        }[language]
                    }
                </div>
                <div className="coffees-container-information-close-button" onClick={() => setShowInformationBox(false)}>
                    <FontAwesomeIcon
                        className="coffees-container-information-close-icon"
                        icon={faTimesCircle}
                    />
                </div>
            </div>
        </div>
    )
}

export default InformationCoffeeBox;