const selectNotNull = (val1, val2) => {
    if (val1 === null) return val2
    return val1
}

// TIRAR TODOS OS 7/6 QUANDO TRATAR DO BRASIL 

const totalProducersPrice = (coffees, paymentData) => coffees.reduce((total, coffee) => {
    return total + (coffee.origin == "Brazil" ? coffee.sackPrice * ((Math.min(coffee.quantity, coffee.sackRemain)) || 0) : coffee.sackPrice * 5.477 * ((Math.min(coffee.quantity, coffee.sackRemain)) || 0))//  * 7/6
}, 0)
const producersPrice = coffee => parseFloat(coffee.sackPrice)
const producersPriceKg = coffee => parseFloat(coffee.sackPrice) / 60

const totalPrice = (coffees, paymentData) =>
    totalProducersPrice(coffees, paymentData)
    + totalLogisticsFee(coffees, paymentData) // * 7/6
    + totalImportDuties(coffees, paymentData) // * 7/6
    + totalFarmlyFee(coffees, paymentData)

const totalCoffeePrice = (coffee, paymentData) =>
    (producersPrice(coffee)
        + logisticsFee(coffee, paymentData)
        + importDuties(coffee, paymentData)
        + farmlyFee(coffee, paymentData)) || 0

const logisticsFee = (coffee, paymentData) => selectNotNull(coffee.logistics_fee, paymentData.logistics_fee)*coffee.quantity + (coffee.logistics_fee_euro * coffee.quantity)
const totalLogisticsFee = (coffees, paymentData) => coffees.reduce((total, coffee) => total + (coffee.origin == "Brazil" ? logisticsFee(coffee, paymentData) : logisticsFee(coffee, paymentData)*5.477), 0)

const importDuties = (coffee, paymentData) =>
    selectNotNull(coffee.import_duties, paymentData.import_duties)
    * selectNotNull(coffee.import_duties_adjustment, paymentData.import_duties_adjustment)
    * coffee.sackPrice * coffee.quantity + (coffee.import_duties_euro * coffee.quantity)
const totalImportDuties = (coffees, paymentData) => coffees.reduce((total, coffee) => total + (coffee.origin == "Brazil" ? importDuties(coffee, paymentData) : importDuties(coffee, paymentData)*5.477),0)

const farmlyFee = (coffee, paymentData) => (selectNotNull(coffee.farmly_fee, paymentData.farmly_fee) / 100) * coffee.sackPrice * coffee.quantity + (coffee.farmly_fee_euro * coffee.quantity)
const totalFarmlyFee = (coffees, paymentData) => coffees.reduce((total, coffee) => total + (coffee.origin == "Brazil" ? farmlyFee(coffee, paymentData) : farmlyFee(coffee, paymentData)*5.477), 0)

const toEuro = (value, paymentData, coffee) => {
    if(coffee.euro_value === paymentData.euro_value || coffee.euro_value === 0 || coffee.euro_value === null ) return value / paymentData.euro_value
    else return value / coffee.euro_value
}

const simpleIRPJ_CSLL = (sackPrice, euroValue) => {
        if(euroValue === null) {
            euroValue =  5.09
        }
        return (sackPrice * 0.0308)/(euroValue* 60)

}
// (producersPriceKg/euroValue).'toFixed'(2)
const simpleEuroProdKg = (producersPriceKg, euroValue) => euroValue !== null ? producersPriceKg/euroValue : producersPriceKg/5.09
const simpleImportDuties = (euro) => (euro *0.02) * 1.15;
const simpleLogisticsFee = (country) => country === "Brazil" ? 2.61 : 2.06
const simpleFarmlyFee = (euro, IRPJ_CSLL = 0, farmlyFee = 20) =>  (parseFloat(euro)+IRPJ_CSLL)*(farmlyFee/100)
// ((parseFloat(euro)+IRPJ_CSLL)*(farmlyFee/100)).toFixed(2)


const totalProducersPriceWithDiscount = (coffees, discountData) => coffees.reduce((total, coffee) => {
    return total + (coffee.origin == "Brazil" ? discountData.sack_price * ((Math.min(coffee.quantity, coffee.sackRemain)) || 0) : discountData.sack_price * discountData.euro_price * ((Math.min(coffee.quantity, coffee.sackRemain)) || 0))//  * 7/6
}, 0)

const totalLogisticsFeeWithDiscount = (coffees, discountData) => coffees.reduce((total, coffee) => total + (coffee.origin == "Brazil" ? 
logisticsFeeWithDiscount(coffee, discountData) : logisticsFeeWithDiscount(coffee, discountData) * discountData.euro_price), 0)

const logisticsFeeWithDiscount = (coffee, discountData) => discountData.logistics * coffee.quantity + (coffee.logistics_fee_euro * coffee.quantity)

const totalImportDutiesWithDiscount = (coffee,  discountData) => coffee.reduce((total, coffee) => total + (coffee.origin == "Brazil" ? importDutiesWithDiscount(coffee, discountData) : importDutiesWithDiscount(coffee, discountData)*discountData.euro_price),0)


const importDutiesWithDiscount = (coffee, discountData) =>
    selectNotNull(coffee.import_duties, 0.02)
    * selectNotNull(coffee.import_duties_adjustment, 1.15)
    * discountData.sack_price * coffee.quantity + (coffee.import_duties_euro * coffee.quantity)

const farmlyFeeWithDiscount = (coffee,discountData) => (discountData.farmly_fee / 100) * discountData.sack_price * coffee.quantity + (coffee.farmly_fee_euro * coffee.quantity)


const totalFarmlyFeeWithDiscount = (coffees,discountData) => coffees.reduce((total, coffee) => total + (coffee.origin == "Brazil" ? farmlyFeeWithDiscount(coffee,discountData) : farmlyFeeWithDiscount(coffee,discountData) * discountData.euro_price), 0)


const totalPriceWithDiscount = (coffees, discountData) =>
    totalProducersPriceWithDiscount(coffees, discountData)
    + totalLogisticsFeeWithDiscount(coffees, discountData) // * 7/6
    + totalImportDutiesWithDiscount(coffees, discountData) // * 7/6
    + totalFarmlyFeeWithDiscount(coffees, discountData)

const toEuroWithDiscount = (value, discountData) => value / discountData.euro_price

const calculateCoffeeTaxes = (coffee, shipments, paymentData) => {
    if(coffee.sackPrice !== undefined) {
        const shipment = shipments.filter(c => c.shipping_id == coffee.shipment)
        if(shipment.length === 0){
            const baseFarmlyFee = coffee.farmlyFee !== null ? coffee.farmlyFee / 100 : 0.02 
            const sack = parseFloat(coffee.sackPrice)
            const producersPriceKg = coffee.origin === "Brazil" ? parseFloat((sack /60).toFixed(2)) : parseFloat((sack /70).toFixed(2));
            const prodEuro = coffee.origin === "Brazil" ? parseFloat((producersPriceKg / 5.09).toFixed(2)) : parseFloat((producersPriceKg / 2.06).toFixed(2))
            const irpj =  coffee.origin === "Brazil" ? (sack * 0.0308)/(5.09 * 60) : 0
            const importDuties = ((prodEuro * baseFarmlyFee) * 1.15) + irpj
            const logisticsFee = coffee.origin === "Brazil" ? 2.61 : 2.06
            const farmlyFee = ((parseFloat(prodEuro) + irpj) * baseFarmlyFee).toFixed(2)
            let euroPriceKg = 0
            if(coffee.is_applied_taxes) {
                const kgPrice = totalCoffeePrice({ ...coffee, quantity: "1" },paymentData);
                const totalKgPrice = isNaN(kgPrice) ? 0.00 : (coffee.origin == "Brazil" ? (kgPrice / 60).toFixed(2) : (kgPrice / 70).toFixed(2))
                euroPriceKg = coffee.origin == "Brazil" ? toEuro(totalKgPrice, paymentData, coffee).toFixed(2) : totalKgPrice
            } else {
                euroPriceKg = coffee.origin === "Brazil" ? prodEuro + parseFloat(farmlyFee)+importDuties+logisticsFee: 
                prodEuro + parseFloat(farmlyFee) + importDuties + logisticsFee
            }
            const coffeeTaxes = {
                IRPJ_CSLL: 0.00,
                producerFee: parseFloat(prodEuro),
                euroKiloPrice: parseFloat(euroPriceKg.toFixed(2)),
                importDuties: parseFloat(importDuties.toFixed(2)),
                logisticsFee: logisticsFee,
                farmlyFee: parseFloat(farmlyFee)
            }

            return coffeeTaxes
        }	
            const sack = parseFloat(coffee.sackPrice)
            const producersPriceKg = coffee.origin === "Brazil" ? parseFloat((sack /60).toFixed(2)) : parseFloat((sack /70).toFixed(2));
            let farmlyFee = 0, IRPJ_CSLL = 0, eurProdKg = 0, logisticFee = 0
            if(!shipment[0].farmly_fee) {
                shipment[0].farmly_fee = 20  
            } 
            if(coffee.origin === "Brazil") {
                if(!shipment[0].price_br) {
                    shipment[0].price_br = 5.09
                }
                eurProdKg = simpleEuroProdKg(producersPriceKg, shipment[0].price_br)
                IRPJ_CSLL = simpleIRPJ_CSLL(sack, shipment[0].price_br)
            } else  {
                if(!shipment[0].price_co) {
                    shipment[0].price_co = 2
                }	
                        
            }
            const import_duties = coffee.origin === "Brazil" ? simpleImportDuties(eurProdKg) + IRPJ_CSLL : simpleImportDuties(parseFloat(producersPriceKg) + IRPJ_CSLL) ;
            if(coffee.origin === "Brazil") {
                if(!shipment[0].logistics_br) {
                    logisticFee = simpleLogisticsFee(coffee.origin)
                } else {
                    logisticFee = shipment[0].logistics_br
                }
            }  else {
                if(!shipment[0].logistics_co) {
                    logisticFee = simpleLogisticsFee(coffee.origin)
                } else {
                    logisticFee = shipment[0].logistics_co
                }
            }
            const armazenamento = shipment[0].storage;
            const baseFarmlyFee = coffee.farmly_fee && coffee.farmly_fee !== shipment[0].farmly_fee ? coffee.farmly_fee : shipment[0].farmly_fee
            farmlyFee = coffee.origin === "Brazil" ? simpleFarmlyFee(eurProdKg, IRPJ_CSLL, baseFarmlyFee) : simpleFarmlyFee(producersPriceKg, IRPJ_CSLL, baseFarmlyFee)
            let euroPriceKg = 0
            if(coffee.is_applied_taxes) {
                const kgPrice = totalCoffeePrice({ ...coffee, quantity: "1" }, paymentData);
                const totalKgPrice = isNaN(kgPrice) ? 0.00 : (coffee.origin == "Brazil" ? kgPrice / 60  : kgPrice / 70)
                euroPriceKg = coffee.origin == "Brazil" ? parseFloat(toEuro(totalKgPrice, paymentData, coffee)) : parseFloat(totalKgPrice)
                console.log("olha o valor do euro" + euroPriceKg)
            } else {
                euroPriceKg = coffee.origin === "Brazil" ? eurProdKg + farmlyFee+import_duties+logisticFee+armazenamento : 
                producersPriceKg + farmlyFee + import_duties + logisticFee + armazenamento
            }
            // const euroPriceKg = coffee.origin === "Brazil" ? parseFloat(eurProdKg) +parseFloat(farmlyFee)+import_duties+logisticFee+armazenamento : 
            // producersPriceKg + parseFloat(farmlyFee) + import_duties + logisticFee + armazenamento
            if(coffee.origin === "Brazil") {
                eurProdKg = eurProdKg.toFixed(2)
            } else {
                eurProdKg = parseFloat((sack /70).toFixed(2))
            }

            const coffeeTaxes = {
                IRPJ_CSLL: parseFloat(IRPJ_CSLL.toFixed(2)),
                producerFee: parseFloat(eurProdKg),
                euroKiloPrice: parseFloat(euroPriceKg.toFixed(2)),
                importDuties: parseFloat(import_duties.toFixed(2)),
                logisticsFee: logisticFee,
                farmlyFee: parseFloat(farmlyFee.toFixed(2)),
                euroKilo: euroPriceKg
            }

            return coffeeTaxes

        }
    
    
}	

export {
    totalProducersPrice,
    totalPrice,
    totalCoffeePrice,
    logisticsFee,
    totalLogisticsFee,
    importDuties,
    totalImportDuties,
    farmlyFee,
    totalFarmlyFee,
    producersPriceKg,
    simpleIRPJ_CSLL,
    simpleEuroProdKg,
    simpleFarmlyFee,
    simpleLogisticsFee,
    simpleImportDuties,
    toEuro,
    toEuroWithDiscount,
    totalPriceWithDiscount,
    totalProducersPriceWithDiscount,
    //  ,
    logisticsFeeWithDiscount,
    totalLogisticsFeeWithDiscount,
    importDutiesWithDiscount,
    totalImportDutiesWithDiscount,
    farmlyFeeWithDiscount,
    totalFarmlyFeeWithDiscount,
    calculateCoffeeTaxes
}