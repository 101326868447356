import React, { useState, Suspense, useContext } from 'react'
import Cookies from 'js-cookie'
import './Main.css'

import NewNav from './NewNav.jsx';
// const NewNav = React.lazy(() => ('./NewNav.jsx'))

import Nav from './Nav.jsx'

import SimpleFooter from './SimpleFooter.jsx'

import { isPt, farmlyTexts, acceptedCookies } from '../../common.js'
import { LoadingContext } from '../../context/loading/LoadingContext';

// Lazy imports:

// import Title from './Title.jsx'
const Title = React.lazy(() => import('./Title.jsx'))

// import NewFooter from './NewFooter.jsx';
const NewFooter = React.lazy(() => import('./NewFooter.jsx'))

// import FarmlyModal from './FarmlyModal.jsx'
const FarmlyModal = React.lazy(() => import('./FarmlyModal.jsx'));

const loadAnim = require("../../assets/load.svg");

const eraseAllCookies = () => {
    document.cookie.split(';').forEach(cookie => {
        const name = cookie.split('=')[0]
        document.cookie = `${name.trim()}=0;max-age=0;path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;`
    })
}

export default props => {

    const [handledCookies, setHandledCookies] =
        useState(acceptedCookies())

    const loadingContext = useContext(LoadingContext);

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <div id="main-container">
                {(!handledCookies && !window.location.href.includes('privacy-policy')) &&
                    <FarmlyModal noClose >
                        <div className="cookies-policy-container">
                            <Title title={isPt() ? farmlyTexts.cookiesTitle_pt : farmlyTexts.cookiesTitle_en} />
                            <div className="cookies-policy-text">
                                {isPt() ? farmlyTexts.cookiesText_pt : farmlyTexts.cookiesText_en}
                                <a href="/privacy-policy" style={{ textDecoration: 'none', color: 'var(--farmly-fuzzy)' }}>
                                    {isPt() ? farmlyTexts.cookiesClick_pt : farmlyTexts.cookiesClick_en}
                                </a>.

                            </div>
                            <div className="cookies-policy-buttons">
                                <div className="cookies-policy-reject" onClick={() => {
                                    setHandledCookies(true)
                                }}>
                                    {isPt() ? farmlyTexts.cookiesReject_pt : farmlyTexts.cookiesReject_en}
                                </div>
                                <div className="cookies-policy-accept" onClick={() => {
                                    Cookies.set('accepted_cookies', 'accepted_cookies', {expires: 100, path: '/'})
                                    setHandledCookies(true)
                                }}>
                                    {isPt() ? farmlyTexts.cookiesAccept_pt : farmlyTexts.cookiesAccept_en}
                                </div>
                            </div>
                        </div>
                </FarmlyModal>
            }
            {!props.noNav && <NewNav mainTitle={props.titleHeader ? props.titleHeader : ''} isHome={props.isHome ? true : false} noBanner={props.noBanner || false} isCuppingLive = {props.isCuppingLive ? true : false} smallImage={props.smallImage} />}
            <main className="content" style={{display: props.isBlock ? 'block' : 'flex'}}>
                <div className="">
                    {
                    loadingContext.loading === true
                    ?
                    <div className="loading-full-window">
                        <img
                            src={loadAnim}
                            alt="loading animation"
                            style={{
                                display: "inline",
                                width: "50px",
                                height: "50px",
                                alignSelf: "center",
                            }}
                        />
                     </div>
                    :
                    props.children
                    }
                </div>
            </main>
            {!props.noFooter && <NewFooter/>}
        </div>     
        </Suspense>
    )
}