import React, { FunctionComponent } from 'react';

import { Container, coffeePicture3 } from './styles';

import CardDivider from './components/CardDivider'
import CardHeader from './components/CardHeader'
import CardBody from './components/CardBody'

export interface IntlCoffeeCardProps {
    id      ?: number,
    origin  ?: string,
    name    ?: string,
    process ?: string,
    prices  ?: [number,number],
    farmCode?: string,
    cardImg ?: string,
}
 
const IntlCoffeeCard: FunctionComponent<IntlCoffeeCardProps> = (
        {
            id      = 1,
            origin  = "Brazil",
            name    = "Random Name",
            process = "Random Process",
            prices  = [0.0,0.0],
            farmCode= "21X-Y000",
            cardImg = coffeePicture3,
        } : IntlCoffeeCardProps
    ) => {

    return (
        <Container>
            <CardHeader image={cardImg} />
            <CardDivider/>
            <CardBody id={id} origin={origin} name={name} process={process} prices={prices} farmCode={farmCode}/>
        </Container>
    );
}
 
export default IntlCoffeeCard;