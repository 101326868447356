import React from "react";

import "../../../styles/profile/components/UserAvatar.css";
// const CofeeBean = require("../../../assets/Icons/coffee-beans.svg");

import ButtonBase from '@material-ui/core/ButtonBase';
import WallpaperIcon from '@material-ui/icons/Wallpaper';
import { language } from "../../../common";

const UserAvatar = (props) => {
	const {
		isOwner,
		isAdmin,
		submitUserImg,
		imgs,
		promptUpdateUserImg
	} = props;

	const profileImg = imgs && imgs.filter(img => img.role === 'profile').length > 0 ? imgs && imgs.filter(img => img.role === 'profile')[0] : null
	// const profileIndx = imgs && imgs.indexOf(profileImg)

	// console.log(profileImg)

	const uploadFile = () => {
		const inputFile = document.getElementById('myAvatar');
		inputFile.click();
	}

	const updateImg = (file, role) => {
		if (profileImg) {
			promptUpdateUserImg(profileImg, file)
		}
		else {
			submitUserImg(file, role)
			document.getElementById('myAvatar').value = null;
		}
	}

	return (
		isOwner || isAdmin
			?
			<div className="new-profile avatar-field">
				{
					profileImg
						?
						<img
							className="new-profile avatar"
							src={imgs.filter(img => img.role === 'profile')[0].img_url}
							alt="profile avatar"
						/>
						:
						<ButtonBase className="new-farmly-button f-button-bt f-button-grey new-profile avatar" onClick={uploadFile}><WallpaperIcon />{{ pt: 'Adicione uma foto de perfil', es: 'Añadir una foto de perfil' }[language]}</ButtonBase>
				}
				{
					profileImg &&
					<div className='user-avatar-actions'>
						<ButtonBase className="new-farmly-button f-button-bt f-button-o-green" onClick={uploadFile}>{{ pt: 'Trocar foto de perfil', es: 'Cambiar foto de perfil', en: 'Change profile photo' }[language]}</ButtonBase>
					</div>
				}
				<input type="file" id="myAvatar" name="filename" accept="image/*" hidden onChange={(e) => { updateImg(e.target.files, 'profile') }}></input>
			</div>
			:
			!profileImg
				?
				null
				:
				<div className="new-profile avatar-field">
					<img
						className="new-profile avatar"
						src={imgs.filter(img => img.role === 'profile')[0].img_url}
						alt="profile avatar"
					/>
				</div>
	)
};

export default UserAvatar;
