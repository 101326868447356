import axios from 'axios'
import { server } from 'common';

require('dotenv').config();

let serverUrl
if (process.env.REACT_APP_LOCAL && process.env.REACT_APP_LOCAL==="true") {
    serverUrl = process.env.REACT_APP_BASE_SERVER === 'development' ? process.env.REACT_APP_DEV_URL : process.env.REACT_APP_BASE_SERVER === 'production' ? process.env.REACT_APP_PRD_URL : process.env.REACT_APP_LCL_URL
} else {
    serverUrl = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_URL : process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PRD_URL : process.env.REACT_APP_LCL_URL
}

export default axios.create({
    baseURL: server,
    timeout: 10000,
})