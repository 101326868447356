import styled from 'styled-components';

export const Title = styled.p`
    text-align: center;
    transition-timing-function: ease-in-out;
    transition: all 0.3s;
`

export const LoadingImage = styled.div`
    height: 1rem;
    margin-left: 1rem;
`