import React, { useState, useEffect } from 'react'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import ReactGA from 'react-ga'
import './ThankYou.css'
import { isPt } from '../../../common'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ThankYou = props => {
    const { firstName, isProd, inviteLink, state } = props

    const [dest, setDest] = useState(state)
    useEffect(() => {
        setDest(state)
    }, [state])

    const welcomeTextProd_pt = 'Seja bem-vindo(a) a FARMly! Enviamos para você um email de confirmação.'
    const welcomeTextProd_en = 'You are signed up to FARMly. We just sent you a confirmation email.'

    const welcomeTextRoaster_pt = 'Você está cadastrado(a) na FARMly, bem vindo(a)!'
    const welcomeTextRoaster_en = 'You are signed up to FARMly, welcome!'


    const welcomeText = (
        isProd ?
            isPt() ?
                welcomeTextProd_pt :
                welcomeTextProd_en :
            isPt() ?
                welcomeTextRoaster_pt :
                welcomeTextRoaster_en
    )

    const onCopyLinkClicked = () => {
        const hover = document.querySelector('.copied-hover-signup')
        hover.style.opacity = 1
        setTimeout(() => {
            hover.style.opacity = 0
        }, 2000)
        const a = document.getElementById("link-copy");
        a.focus()
        a.select()
        document.execCommand("copy");
    }

    const inviteString = 'https://farmlyclub.com/ReceivedLink?invite=' + inviteLink

    const analyticsInfo = () => {
            ReactGA.event({
                category: 'User clicked "go to profile"',
                action: 'User sign up and clicked "go to profile" at "thank you" page'
            })
    }

    const renderInvite = () => {
        return (
            <>
                <label id="thank-you-sublabel">
                    Convide outros produtores usando o link abaixo! Ao convidá-los 
                    para fazer parte da FARMly, você recebe uma corretagem em todos os cafés vendidos pelo produtor
                    que se cadastrar por esse link! Caso queria enviar o convite agora por Whatsapp, basta clicar no ícone abaixo!
            </label>
                <div className="rowflex">
                    <p className="invite-link" onClick={() => onCopyLinkClicked()}>
                        https://farmlyclub.com/ReceivedLink?invite={inviteLink}
                        <div className="invite-copy-signup">
                            <FontAwesomeIcon className="copy-icon-signup" icon={faCopy} />
                            <label className="copied-hover-signup">
                                Copiado
                            </label>
                        </div>
                    </p>
                    <a target="_blank" rel="noopener noreferrer" href={`https://api.whatsapp.com/send?text=Use+o+link+de+convite+de+${firstName}+para+entrar+para+sua+rede+de+produtores+parceiros+na+FARMly!+https://www.farmlyclub.com/ReceivedLink?invite=${inviteLink}`}>
                        <FontAwesomeIcon className="whatsapp-icon" icon={faWhatsapp} />
                    </a>
                </div>
                <textarea id="link-copy" style={{ zIndex: -1 }} value={inviteString} readOnly />

            </>
        )
    }

    return (
        <div id="signup-thank-you">
            <label id="thank-you-label">
                {isPt() ? `Obrigado ${firstName}!` : `Thank you ${firstName}!`}
            </label>
            <label id="thank-you-sublabel">{welcomeText}</label>
            {isProd && renderInvite(firstName)}
            <Link id='back-to-home-link' to={dest} onClick={analyticsInfo}>
                {isPt() ? 'Ir para perfil' : 'Go to Profile'}
            </Link>
        </div>
    )
}

export default ThankYou

