import React, { useState, useEffect, useContext, useRef } from "react";
import Main from "../../templates/Main";
import axios from "axios";
import "./CuppingLive.css";
import { makeStyles } from "@material-ui/core/styles";
import ReactGA from "react-ga";
import CuppingLiveCard from "./CuppingLiveCard";
import CuppingLiveMediumCard from "./CuppingLiveMediumCard";
import CuppingLiveSmallCard from "./CuppingLiveSmallCard";
import CuppingLiveSlider from "react-slick";
import Divider from "@material-ui/core/Divider";
import { isPt, farmlyTexts, server, acceptedCookies } from "../../../common";
import Carousel from "react-material-ui-carousel";
import UserContext from "../../../context/user/UserContext.js";
import FarmlyModal from "../../templates/FarmlyModal.jsx";
import Avatar from "@material-ui/core/Avatar";
import { Link, useHistory } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert, AlertTitle } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const waitCuppingLive = require("../../../assets/CoffeeCupping.jpeg");

const CuppingLiveProducer2 = (props) => {
  const userContext = useContext(UserContext);
  const history = useHistory();
  const [coffees, setCoffees] = useState([]);
  const [oldCoffees, setOldCoffees] = useState([]);
  const [userData, setUserData] = useState();
  const [modalMessage, setModalMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [pleaseLogin, setPleaseLogin] = useState(false);
  const [loginToComment, setLoginToComment] = useState(false);
  const [activeCard, setActiveCard] = useState(0);
  const [comments, setComments] = useState([]);
  const [content, setContent] = useState(null);
  const [load, setLoad] = useState(false);
  const [timer, setTimer] = useState(false);
  const commentsRef = useRef(null);

  useEffect(() => {
    getCoffees();
    getUserData();
  }, []);

  useEffect(() => {
    getComments();
  }, [load]);

  useEffect(() => {
    setTimeout(() => {
      setLoad(!load);
      setTimer(!timer);
    }, 1000);
  }, [timer]);

  const getCoffees = () => {
    const queryString = `${server}/coffees`;
    axios
      .get(queryString)
      .then((res) => {
        setCoffees(
          res.data.filter(
            (item) =>
              item.farmCode == 402 ||
              item.farmCode == 416 ||
              item.farmCode == 419
          )
        );
        setOldCoffees(res.data.filter((item) => item.old_display == true));
      })
      .catch((_) => {});
  };

  const getUserData = async () => {
    if (userContext.user) {
      await axios
        .get(`${server}/producer/${userContext.user.id}`)
        .then(async (res) => {
          setUserData(res.data);
        });
    } else {
      setPleaseLogin(true);
    }
  };

  const getComments = async () => {
    await axios.get(`${server}/getAllComments`).then(async (res) => {
      if (comments.length != res.data.length) {
        await setComments(res.data);
        commentsRef.current.scrollTop = commentsRef.current.scrollHeight;
      }
      setComments(res.data);
    });
  };

  const createComment = async () => {
    if (userContext.user) {
      await axios
        .post(`${server}/createComment`, {
          id: userData.id,
          name: userData.name,
          content: content,
          isProd: userContext.user.isProd,
        })
        .then((res) => {
          setLoad(!load);
          setContent("");
        });
    } else {
      setLoginToComment(true);
    }
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const renderLiveComments = () =>
    comments.map((item) => {
      const names = item.name.split(" ");
      const initials = names[0][0];
      return (
        <div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Avatar style={{ backgroundColor: "#C95A56" }}>{initials}</Avatar>
            <p className="card-live-roaster-name-stream">{item.name}</p>
          </div>
          <div className="card-live-roaster-comment-stream">{item.content}</div>
          <br />
        </div>
      );
    });

  const renderCuppingLiveCard = () =>
    coffees.map((coffee) => {
      return (
        <>
          {window.screen.width > 800 ? (
            <div style={{ width: "500px" }}>
              <CuppingLiveCard
                coffee={coffee}
                user={userData}
                setModalMessage={setModalMessage}
                toggleModal={toggleModal}
              />
              <br />
            </div>
          ) : (
            <div style={{ width: "420px" }}>
              <CuppingLiveMediumCard
                coffee={coffee}
                setModalMessage={setModalMessage}
                toggleModal={toggleModal}
              />
              <br />
            </div>
          )}
        </>
      );
    });

  const sendToPage = (aux) => {
    if (aux) {
      history.push({
        pathname: "/login",
        state: window.location.pathname,
      });
      history.go();
    } else {
      history.push({
        pathname: "/signup",
        state: window.location.pathname,
      });
      history.go();
    }
  };

  const renderSamplesCard = () =>
    coffees.map((coffee) => {
      return (
        <>
          {window.screen.width > 800 ? (
            <div className="each-medium-card">
              <CuppingLiveMediumCard
                coffee={coffee}
                setModalMessage={setModalMessage}
                toggleModal={toggleModal}
              />
            </div>
          ) : (
            <div className="each-medium-card">
              <CuppingLiveSmallCard
                coffee={coffee}
                setModalMessage={setModalMessage}
                toggleModal={toggleModal}
              />
            </div>
          )}
        </>
      );
    });

  const settings = {
    dots: true,
    centerMode: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    dotsClass: "slick-dots-cupping-live",
    customPaging: function (i) {
      return <a className="each-dot-cupping-live">123</a>;
    },
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") createComment();
  };

  const handleCloseCart = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setLoginToComment(false);
  };

  return (
    <>
      {loginToComment && (
        <Snackbar
          open={loginToComment}
          autoHideDuration={30000}
          onClose={handleCloseCart}
        >
          <Alert
            elevation={6}
            variant="standard"
            onClose={handleCloseCart}
            severity="success"
          >
            <AlertTitle>
              {isPt()
                ? "Faça login para enviar um comentário!"
                : "Please log in to send a comment!"}
            </AlertTitle>
            <div className="register-button-sub-container-cupping-live">
              <button
                className="register-button-live-modal"
                style={{ cursor: "pointer" }}
                onClick={() => sendToPage(true)}
              >
                {" "}
                {isPt() ? "Login" : "Login"}
              </button>
              <div id="login-new-account" style={{ alignSelf: "center" }}>
                <label id="new-account-label">
                  {isPt()
                    ? farmlyTexts.loginNewAccount_pt
                    : farmlyTexts.loginNewAccount_en}
                </label>
                <Link
                  id="new-account-link"
                  to={{ pathname: "/signup" }}
                  onClick={() => sendToPage(false)}
                >
                  {isPt()
                    ? farmlyTexts.loginSignUp_pt
                    : farmlyTexts.loginSignUp_en}
                </Link>
              </div>
            </div>
          </Alert>
        </Snackbar>
      )}
      {pleaseLogin && (
        <FarmlyModal
          closeModal={() => {
            setPleaseLogin(false);
          }}
          className="modal-sample"
        >
          <div className="modal-landing-page">
            <label id="request-title">
              {isPt()
                ? "Faça login para melhorar sua experiência!"
                : "Log in to enhance your experience!"}
            </label>
            <br />
            <div className="register-button-container-cupping-live">
              <div className="register-button-sub-container-cupping-live">
                <button
                  className="register-button-live-modal"
                  style={{ cursor: "pointer" }}
                  onClick={() => sendToPage(true)}
                >
                  {" "}
                  {isPt() ? "Login" : "Login"}
                </button>
                <div id="login-new-account" style={{ alignSelf: "center" }}>
                  <label id="new-account-label">
                    {isPt()
                      ? farmlyTexts.loginNewAccount_pt
                      : farmlyTexts.loginNewAccount_en}
                  </label>
                  <Link
                    id="new-account-link"
                    to={{ pathname: "/signup" }}
                    onClick={() => sendToPage(false)}
                  >
                    {isPt()
                      ? farmlyTexts.loginSignUp_pt
                      : farmlyTexts.loginSignUp_en}
                  </Link>
                </div>
                <button
                  className="register-button-live-modal-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => setPleaseLogin(false)}
                >
                  {" "}
                  {isPt()
                    ? "Continuar sem uma conta"
                    : "Continue without an account"}
                </button>
              </div>
            </div>
          </div>
        </FarmlyModal>
      )}
      <Main isCuppingLive={true}>
        {showModal && (
          <FarmlyModal id="page-modal" closeModal={toggleModal}>
            {modalMessage}
          </FarmlyModal>
        )}
        <div className="cupping-live-ultra-container">
          <br />
          <br />
          {/* <div className="youtube-stream-preview">
                        <div className="wait-cupping-live-text">
                            <p>Nosso cupping online acontecerá no dia 08 de Janeiro de 2021!</p>
                            <p>Ás 14:00!</p>
                            <p>Nos vemos lá!</p>
                        </div>
                    </div> */}
          {/* Não esquecer da className = youtube-stream */}
          <div className="youtube-stream-container">
            {/* <iframe width="560" height="315" className="youtube-stream" src="https://www.youtube.com/embed/gDSqd4_orDg" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
            <iframe
              src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Ffarmlyclub%2Fvideos%2F240861797410455%2F&width=1280"
              width="1280"
              height="720"
              className="youtube-stream"
              style={{ border: "none", overflow: "hidden" }}
              scrolling="no"
              frameborder="0"
              allowfullscreen="true"
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
              allowFullScreen="true"
            ></iframe>
            <div className="youtube-stream-comments">
              <div className="comments-section">
                <div className="get-all-comments" ref={commentsRef}>
                  {comments.length && renderLiveComments()}
                </div>
                <div className="card-live-make-a-comment-container-stream">
                  <input
                    className="live-comment-input"
                    placeholder="Comente aqui!"
                    value={content}
                    setValue={(value) => setContent(value)}
                    onChange={(event) => setContent(event.target.value)}
                    onKeyPress={handleKeyPress}
                  ></input>
                  <div className="card-live-comment-buttons-container">
                    <button
                      className="card-live-cancel-comment-button"
                      style={{
                        backgroundColor: "white",
                        marginRight: "3px",
                        borderRadius: "3px",
                        cursor: "pointer",
                      }}
                      onClick={() => setContent("")}
                    >
                      Apagar
                    </button>
                    <button
                      className="card-live-send-comment-button"
                      style={{ cursor: "pointer" }}
                      onClick={createComment}
                    >
                      Enviar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="cupping-coffees-title">Cafés do Cupping:</div>
          <div className="cupping-card-live-container">
            <div className="indicators-container">
              <div
                className={
                  activeCard == 0 ? "active-indicator" : "normal-indicator"
                }
                onClick={() => setActiveCard(0)}
              >
                419
              </div>
              <div
                className={
                  activeCard == 1 ? "active-indicator" : "normal-indicator"
                }
                onClick={() => setActiveCard(1)}
              >
                402
              </div>
              <div
                className={
                  activeCard == 2 ? "active-indicator" : "normal-indicator"
                }
                onClick={() => setActiveCard(2)}
              >
                416
              </div>
            </div>
            <Carousel
              autoPlay={false}
              animation="slide"
              indicators={false}
              next={(next, active) => setActiveCard(next)}
              prev={(prev, active) => setActiveCard(prev)}
              index={activeCard}
            >
              {renderCuppingLiveCard()}
            </Carousel>
          </div>
        </div>
      </Main>
    </>
  );
};
export default CuppingLiveProducer2;
