import React from "react";
import { ShipmentLineContainer, ShipmentInfo, ShipmentInfoCheckbox, ShipmentInfoButton  } from "./styles";
import IShipmentModel from "../../models/ShipmentModel"
import useUpdateShipment from "../../hooks/updateShipment"
import utcStringToDate from "utils/utcStringToDate"

interface ShipmentLine {
    shipment : IShipmentModel
}

export default function CoffeesLineMain(props: ShipmentLine) {

    const { shipment } = props;

    const update = useUpdateShipment(shipment)
    
    return (
        <ShipmentLineContainer>
            <ShipmentInfo>
                {shipment.departure_date && utcStringToDate(shipment.departure_date)}
            </ShipmentInfo>
            <ShipmentInfo>
                <ShipmentInfoCheckbox type="checkbox" checked={update.checked()} onChange={(e:any) => { update.updateShipmentDisplay(e.target.checked)}}/>
            </ShipmentInfo>
            <ShipmentInfo>
                <ShipmentInfoButton onClick={() => update.updateShipmentData(shipment.shipping_id)} >
                    Salvar
                </ShipmentInfoButton>
            </ShipmentInfo>
        
        </ShipmentLineContainer>
    );
    }
