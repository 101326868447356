import React, { useState, useEffect, useContext } from 'react'
import './SignUp.css'
import Main from '../../templates/Main'
import FarmlyModal from '../../templates/FarmlyModal'
import CategoryPicker from './CategoryPicker'
import ProducerForm from './ProducerForm'
import RoasterForm from './RoasterForm'
import TellUsMore from './TellUsMore'
import ThankYou from './ThankYou'
import Countries from '../../../assets/countries.json'
import UserContext from '../../../context/user/UserContext.js'
import CartContext from '../../../context/cart/CartContext.js'
import ReactGA from 'react-ga'
import Cookies from 'js-cookie'
import { Link } from 'react-router-dom'

import { server, farmlyColors, isPt, isBr, farmlyTexts, acceptedCookies } from '../../../common'
import axios from 'axios'

const SignUp = props => {

    const userContext = useContext(UserContext)
    const cartContext = useContext(CartContext)

    const [userData, setUserData] = useState(props.history.location.state ? props.history.location.state.userData : {})

    const state = (props.location.state || '/profile')
    const [isProd, setIsProd] = useState(props.location.isProd || false)
    const [invitedByUsername, setInvitedByUsername] = useState(null)
    const invitedBy = (props.location.search).split('=')[1] || null
    const [inviteLink, setInviteLink] = useState('')
    const [modalMessage, setModalMessage] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [regionOptions, setRegionsOptions] = useState([])
    const countryOptions = Countries

    const [producerData, setProducerData] = useState({
        farmName: '',
        phoneNumber: '',
        farmSize: '',
        farmHeight: '',
        region: '',
        averageYield: '',
        varieties: '',
        harvestDate: '',
        salesDate: ''
    })

    const [roasterData, setRoasterData] = useState({
        country: '',
        postalCode: '',
        address: '',
        phoneNumber: ''
    })

    const [coffeeScores, setCoffeeScores] = useState({
        sub80: '',
        s80_82: '',
        s82_84: '',
        s84_86: '',
        s86_88: '',
        over88: ''
    })

    const [coffeeAmt, setCoffeeAmt] = useState({
        sub80: '0',
        s80_82: '0',
        s82_84: '0',
        s84_86: '0',
        s86_88: '0',
        over88: '0'
    })

    const [sensoryNotes, setSensoryNotes] = useState({
        strawberry: '',
        chocolate: '',
        caramel: '',
        lime: '',
        banana: '',
        orange: ''
    })

    useEffect(() => {
        axios.get(`${server}/getAllRegions`)
            .then(res => {
                setRegionsOptions(res.data)
            })
    }, [])

    useEffect(() => {
        axios.post(`${server}/invite`, {
            invitedBy: invitedBy
        })
            .then(res => {
                const answer = res.data
                if (answer === 'invalid code') {
                    setInvitedByUsername('Código de convite Inválido')
                }
                else {
                    setInvitedByUsername(answer.name)
                }
            })
            .catch(err => console.log(err))
    }, [invitedBy])

    const submitSignup = async () => {
        let user = { ...userData }
        if (isProd) {
            user.coffeeScores = Object.values(coffeeScores).reduce((prev, curr) => prev + curr)
            user.notfication_new = true
            user = { ...user, ...producerData }
            ReactGA.event({
                category: 'SignUp',
                action: `New Producer Signed Up ${user.email}`
            })

        } else {
            user.notfication_new = true
            user.coffeeScores = (
                `sub80:${coffeeAmt.sub80},` +
                `s80_82:${coffeeAmt.s80_82},` +
                `s82_84:${coffeeAmt.s82_84},` +
                `s84_86:${coffeeAmt.s84_86},` +
                `s86_88:${coffeeAmt.s86_88},` +
                `over88:${coffeeAmt.over88},`
            )
            // user.sensoryNotes = Object.values(sensoryNotes).reduce((prev, curr) => prev + curr)
            user = { ...user, ...roasterData }
            ReactGA.event({
                category: 'SignUp',
                action: `New Roaster Signed Up ${user.email}`
            })
        }
        delete user.confirmPassword

        user.invitedBy = invitedBy
        if (!isProd) delete user.invitedBy

        try {
            const payload = await axios.post(`${server}/signup/${isProd ? '1' : '0'}`, {
                ...user
            })

            setInviteLink(payload.data.inviteCode)

            userContext.userLogin({
                isProd: payload.data.isProd,
                jwt: payload.data.jwt,
                id: payload.data.id
            })
            const cookie = acceptedCookies() ?
                document.cookie : ""
            if (cookie.includes('cartList') && !cartContext.cookiesCartIsEmpty) {
                cartContext.setCartToServer(payload.data.jwt)
            }

            if (acceptedCookies()) {
                Cookies.set('jwt', payload.data.jwt, {expires: payload.data.exp, path: '/'})
                Cookies.set('isProd', payload.data.isProd, {expires: payload.data.exp, path: '/'})
                Cookies.set('userId', payload.data.id, {expires: payload.data.exp, path: '/'})
                Cookies.set('main_id', payload.data.main_id, {expires: payload.data.exp, path: '/'})
            }

            await axios.post(`${server}/createNotification`, {
                userId: payload.data.id,
                isProd: payload.data.isProd,
                title: payload.data.isProd ?
                    `Bem-vindo(a) à FARMly!`
                    :
                    `Welcome to FARMly!`,
                message: payload.data.isProd ?
                    `Obrigado por se cadastrar na nossa plataforma! Para começar a vender o seu café é preciso nos enviar uma amostra e aguardar a aprovação! Para enviar amostra basta entrar no seu Perfil, ir na aba "Enviar Amostra" e seguir as instruções para o envio!`
                    :
                    `Thank you for signing up in our website! You can start looking up for coffees clicking on "Coffees"!`
            })

            setStep(5)
        } catch (err) {
            console.log(err)
        }
    }

    const toggleModal = () => {
        setShowModal(!showModal)
    }

    const [step, setStep] = useState(0)

    useEffect(() => {
        let gaObj = null
        switch (step) {
            case 1:
                gaObj = {
                    category: 'Signup',
                    action: 'Page 1 - Roaster or Producer'
                }
                break
            case 2:
                gaObj = {
                    category: 'Signup',
                    action: 'Page 2 - Producer Form'
                }
                break
            case 3:
                gaObj = {
                    category: 'Signup',
                    action: 'Page 3 - Roaster Form'
                }
                break
            case 5:
                gaObj = {
                    category: 'Signup',
                    action: 'User Signed Up'
                }
                break
            default:
        }
    }, [step])

    const stepFlow = () => {

        switch (step) {
            case 0:
                return <CategoryPicker next={isProd => setStep(isProd ? 2 : 3)}
                    prev={() => setStep(0)} setIsProd={setIsProd} userData={userData}/>
            case 2:
                return <ProducerForm prev={() => setStep(1)} producerData={producerData}
                    setProducerData={setProducerData} coffeeScores={coffeeScores}
                    setCoffeeScores={setCoffeeScores} regionOptions={regionOptions} next={() => submitSignup()}
                    toggleModal={toggleModal} setModalMessage={setModalMessage} />
            case 3:
                return <RoasterForm prev={() => setStep(1)} next={() => submitSignup()}
                    roasterData={roasterData} setRoasterData={setRoasterData} countryOptions={countryOptions}
                    toggleModal={toggleModal} setModalMessage={setModalMessage} />
            case 4:
                return <TellUsMore prev={() => setStep(3)} next={() => submitSignup()}
                    coffeeScores={coffeeScores} setCoffeeScores={setCoffeeScores}
                    sensoryNotes={sensoryNotes} setSensoryNotes={setSensoryNotes}
                    roasterData={roasterData} setRoasterData={setRoasterData}
                    coffeeAmt={coffeeAmt} setCoffeeAmt={setCoffeeAmt} />
            case 5:
                return <ThankYou firstName={userData.name.split(' ')[0]} isProd={isProd} inviteLink={inviteLink} state={state} />
            default:
                setStep(0)
        }
    }

    return (
        <Main>
            <div className="signup-container">
                {invitedBy && invitedByUsername && <p style={{ color: farmlyColors.fuzzyWuzzyBrown }}>
                    Código de convite: {invitedBy} - {invitedByUsername}

                </p>}
                {stepFlow()}
                {showModal && <FarmlyModal id="page-modal" closeModal={toggleModal}>{modalMessage}</FarmlyModal>}
            </div>
        </Main>
    )
}

export default SignUp