import styled from 'styled-components';

export const StatusCodeCoffeeLabel = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 15px;
`
