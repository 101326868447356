import React, { FunctionComponent } from 'react'
import {
    TextField
} from '@material-ui/core';

import TitleLabel from '../TitleLabel';
import CoffeeModel from 'modules/SampleCart/models/coffeeModel';

interface InputLabelFarmCodeProps {
    query: string;
    searchCoffees: () => Array<CoffeeModel> | void;
    setQuery: React.Dispatch<React.SetStateAction<string>>
}

const InputLabelFarmCode: FunctionComponent<InputLabelFarmCodeProps> = (props) => {
    return (
        <>
            <TitleLabel title="Sample" />
            <TextField
                id="standard-basic"
                label="Farmly Code"
                type="text"
                value={props.query}
                className="sample-cart-expire-date-input"
                onKeyPress={(e) => { e.key === 'Enter' && props.searchCoffees() }}
                onChange={(e) => { props.setQuery(e.target.value) }}
                inputProps={{ maxLength: 10 }}
                style={{textTransform: 'uppercase'}}
            />
        </>
    );
}

export default InputLabelFarmCode;