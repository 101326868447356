import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import './FarmlyModal.css'

const FarmlyModal = props => {

    const { closeModal, noClose } = props

    return (
        <div id="modal-bg" onClick={!noClose ? event => {
            event.target.id === 'modal-bg' && closeModal()
        }: null }>
            <div id="modal-box">
                {!noClose && <div className="close-modal-button" onClick={closeModal}>
                    <FontAwesomeIcon className="close-modal-icon" icon={faTimes} />
                </div>}
                <div className="modal-wrap" style={props.style}>
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default FarmlyModal