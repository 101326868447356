const tasterOptions = [
    {
      label: "Cabral",
      value: 100,
    },
    {
      label: "Jack",
      value: 200,
    },
    {
      label: "Salomão",
      value: 300,
    },
    {
      label: "Luz",
      value: 400,
    },
    {
      label: "Todos",
      value: 0,
    }
  ]

  export default tasterOptions