import React from "react";

import ButtonBase from '@material-ui/core/ButtonBase';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import InputMask from 'react-input-mask'

import FarmlyInput from "../templates/FarmlyInput";
import Countries from "../../assets/countries.json";

import { farmlyTexts, isBr, isCo, language} from '../../common.js'

import "./EditInformations.css"
import { useState } from "react";
import { useEffect } from "react";

const EditInformations = (props) => {
    const {
        userDataOrigin,
        submitUserData,
        type
    } = props;

    const [data, setData] = useState({ ...userDataOrigin, scoreChecks: { 'sub80': false, '80_82': false, '82_84': false, '84_86': false, '86_88': false, 'over88': false } })
    const [newCountry,setNewCountry] = useState("");

    useEffect(
        () => {
            if (userDataOrigin) {
                const newData = { ...data };
                const coffeeScores = userDataOrigin.coffeeScores.split(',')
                coffeeScores.forEach(key => newData.scoreChecks[key] = true)
                setData(newData)
            }
        }, []
    )

    const checkboxChange = (box) => {
        const value = data.scoreChecks[box.name];
        const newData = { ...data }
        newData.scoreChecks[box.name] = !value;
        setData(newData)
    }

    const parseScore = () => {
        const scores = Object.keys(data.scoreChecks);
        const newData = { ...data };
        delete newData['scoreChecks'];
        newData.coffeeScores = scores.filter(key => data.scoreChecks[key] === true).toString();
        return newData
    }

    const parseData = () => {
        const newData = parseScore();
        if(newCountry !== ""){
            newData.country = newCountry;
        }
        return newData;
    }

    if (type === 'producers') {
        return (
            <div className="new-profile profile-information-edit-modal">
                <h3>{{ pt: 'Edição de Informações', es: 'Edición de información', en: "Editing information" }[language]}</h3>
                <div className="new-profile profile-information-edit-form">
                    <h4>{{ pt: 'Dados do Perfil', es: 'Datos del perfil', en:'Profile Data' }[language]}</h4>
                    <div className="new-profile profile-information-edit-grid">
                        <div className="new-profile profile-information-edit-field">
                            <label htmlFor="profile-name" className="user-send-sample-input-label">{{ pt: 'Nome do Produtor', es: 'Nombre del productor', en:"Farmer's Name" }[language]}</label>
                            <FarmlyInput styleClass="new-profile user-send-sample-input" elementId="profile-name" placeholder="Tobias"
                                value={data.name} setValue={value => setData({ ...data, name: value })}
                            />
                        </div>
                        <div className="new-profile profile-information-edit-field">
                            <label htmlFor="profile-telephone" className="user-send-sample-input-label">{{ pt: 'Telefone', es: 'Teléfono', en:'Phone Number' }[language]}</label>
                            <InputMask id="profile-telephone" className="new-profile user-send-sample-input" mask={isBr()?"(99) 99999-9999":"+99999999999999"}
                                placeholder={'telefone...'} value={data.phoneNumber} onChange={(e) => setData({ ...data, phoneNumber: e.target.value })} />
                        </div>
                        <div className="new-profile profile-information-edit-field">
                            <label htmlFor="profile-address" className="user-send-sample-input-label">{{ pt: 'Endereço', es: 'Dirección', en:'Address' }[language]}</label>
                            <FarmlyInput styleClass="new-profile user-send-sample-input" elementId="profile-address" placeholder={{ pt: 'Rua, numero, cidade, estado, país', es: 'Calle, número, ciudad, región, país', en: 'Street, number, City, State, Country' }[language]}
                                value={data.address} setValue={value => setData({ ...data, address: value })}
                            />
                        </div>
                        <div className="new-profile profile-information-edit-field">
                            <label htmlFor="country" className="user-send-sample-input-label">{{ pt: 'País', es: 'País', en:'Country' }[language]}</label>
                            <select className="new-profile user-send-sample-input" value={data.country} name="country" 
                                id="country" onChange={(e) => setData({ ...data, country: e.target.value })}>
                                <option value="">{{pt:'Clique aqui e selecione uma opção',es:'Clic aquí y seleccione una opción',en:'Click here and select an option'}[language]}</option>
                                {<option value="Brazil">{{pt:"Brasil",es:"Brasil",en:"Brazil"}[language]}</option>}
                                {<option value="Colombia">{{pt:"Colômbia",es:"Colombia",en:"Colombia"}[language]}</option>}
                            </select>
                        </div>
                        <div className="new-profile profile-information-edit-big-field">
                            <label htmlFor="profile-aboutMe" className="user-send-sample-input-label">{{ pt: 'Sobre mim', es: 'Sobre mí', en: 'About Me' }[language]}</label>
                            <FarmlyInput textArea rows={4} styleClass="new-profile user-send-sample-input" elementId="profile-aboutMe" placeholder={{ pt: "Descreva a sua história como produtor de café!", es: "¡Describa su historia como caficultor!", en:"Describe your history as a coffee producer!" }[language]}
                                value={data.description} setValue={value => setData({ ...data, description: value })}
                            />
                        </div>
                    </div>
                    <h4>{{ pt: 'Dados da fazenda', es: 'Datos de la granja' }[language]}</h4>
                    <div className="new-profile profile-information-edit-grid">
                        <div className="new-profile profile-information-edit-field">
                            <label htmlFor="profile-farmName" className="user-send-sample-input-label">{{ pt: 'Nome da Fazenda', es: 'Nombre de la granja', en: "Farm's Name" }[language]}</label>
                            <FarmlyInput styleClass="new-profile user-send-sample-input" elementId="profile-farmName" placeholder={{ pt: 'Fazenda Catuaí', es: 'Hacienda Catuaí', en: "Catuaí Farm" }[language]}
                                value={data.farmName} setValue={value => setData({ ...data, farmName: value })}
                            />
                        </div>
                        <div className="new-profile profile-information-edit-field">
                            <label htmlFor="profile-farmSize" className="user-send-sample-input-label">{{ pt: 'Tamanho da fazenda', es: 'Tamaño de la granja', en:"Farm's Size" }[language]}</label>
                            <FarmlyInput styleClass="new-profile user-send-sample-input" elementId="profile-farmSize" placeholder="50"
                                value={data.farmSize} setValue={value => setData({ ...data, farmSize: value })}
                                type="measure" measureUnit="ha" />
                        </div>
                        <div className="new-profile profile-information-edit-field">
                            <label htmlFor="profile-farmSize" className="user-send-sample-input-label">{{ pt: 'Altitude da fazenda', es: 'Altitud de la granja', en:"Farm's Altitude" }[language]}</label>
                            <FarmlyInput styleClass="new-profile user-send-sample-input" elementId="profile-farmSize" placeholder="50"
                                value={data.farmHeight} setValue={value => setData({ ...data, farmHeight: value })}
                                type="measure" measureUnit="m" />
                        </div>
                        <div className="new-profile profile-information-edit-field">
                            <label htmlFor="profile-region" className="user-send-sample-input-label">{{ pt: 'Região', es: 'Región', en:'Region' }[language]}</label>
                            <FarmlyInput styleClass="new-profile user-send-sample-input" elementId="profile-region" placeholder="Zona da Mata"
                                value={data.region} setValue={value => setData({ ...data, region: value })}
                            />
                        </div>
                        <div className="new-profile profile-information-edit-field">
                            <label htmlFor="profile-production" className="user-send-sample-input-label">{{ pt: 'Produção média', es: 'Producción media', en:'Average Yield' }[language]}</label>
                            <FarmlyInput styleClass="new-profile user-send-sample-input" elementId="profile-production" placeholder="50"
                                value={data.averageYield} setValue={value => setData({ ...data, averageYield: value })}
                                type="measure" measureUnit=" sacas" />
                        </div>
                        <div className="new-profile profile-information-edit-field">
                            <label htmlFor="profile-variety" className="user-send-sample-input-label">{{ pt: 'Variedade produzida', es: 'Variedad producida', en:'Variety produced' }[language]}</label>
                            <FarmlyInput styleClass="new-profile user-send-sample-input" elementId="profile-variety" placeholder="Catuaí, Novo Mundo..."
                                value={data.varieties} setValue={value => setData({ ...data, varieties: value })}
                            />
                        </div>
                        <div className="new-profile profile-information-edit-field">
                            <label htmlFor="profile-harvestDate" className="user-send-sample-input-label">{{ pt: 'Início da colheita', es: 'Inicio de la cosecha', en: 'Start of harvest' }[language]}</label>
                            <FarmlyInput styleClass="new-profile user-send-sample-input" elementId="profile-harvestDate" placeholder={{ pt: 'Janeiro 2018', es: 'Enero de 2018', en:"January 2018" }[language]}
                                value={data.harvestDate} setValue={value => setData({ ...data, harvestDate: value })}
                            />
                        </div>
                    </div>
                    <label htmlFor="profile-meanScore" className="user-send-sample-input-label">{{ pt: 'Pontuação média dos cafés', es: 'Puntuación media de los cafés', en: "Average coffees' score"}[language]}</label>
                    <div className="new-profile info-coffee-scores">
                        <FormControlLabel control={<Checkbox name="sub80" checked={data.scoreChecks['sub80']} />} label={farmlyTexts.signupBelow80_pt} onChange={(e) => checkboxChange(e.target)} />
                        <FormControlLabel control={<Checkbox name="80_82" checked={data.scoreChecks['80_82']} />} label={isCo() ? farmlyTexts.signup8082_co : farmlyTexts.signup8082_pt} onChange={(e) => checkboxChange(e.target)} />
                        <FormControlLabel control={<Checkbox name="82_84" checked={data.scoreChecks['82_84']} />} label={isCo() ? farmlyTexts.signup8284_co : farmlyTexts.signup8284_pt} onChange={(e) => checkboxChange(e.target)} />
                        <FormControlLabel control={<Checkbox name="84_86" checked={data.scoreChecks['84_86']} />} label={isCo() ? farmlyTexts.signup8486_co : farmlyTexts.signup8486_pt} onChange={(e) => checkboxChange(e.target)} />
                        <FormControlLabel control={<Checkbox name="86_88" checked={data.scoreChecks['86_88']} />} label={isCo() ? farmlyTexts.signup8688_co : farmlyTexts.signup8688_pt} onChange={(e) => checkboxChange(e.target)} />
                        <FormControlLabel control={<Checkbox name="over88" checked={data.scoreChecks['over88']} />} label={isCo() ? farmlyTexts.signupOver88_co : farmlyTexts.signupOver88_pt} onChange={(e) => checkboxChange(e.target)} />
                    </div>
                </div>
                <div className='profile-information-edit-actions'>
                    <ButtonBase className="new-farmly-button f-button-orange" onClick={() => submitUserData(parseScore(data))}>{{ pt: 'Confirmar edições', es: 'Confirmar ediciones', en:'Confirm Edits' }[language]}</ButtonBase>
                </div>
            </div>
        );
    }
    else if (type === 'roasters') {
        return (
            <div className="new-profile profile-information-edit-modal">
                <h3>{{ pt: 'Edição de Informações', es: 'Edición de información', en: "Editing information" }[language]}</h3>
                <div className="new-profile profile-information-edit-form">
                    <h4>{{ pt: 'Dados do Perfil', es: 'Datos del perfil', en:'Profile Data' }[language]}</h4>
                    <div className="new-profile profile-information-edit-grid">
                        <div className="new-profile profile-information-edit-field">
                            {/* <label htmlFor="profile-name" className="user-send-sample-input-label">{{ pt: 'Nome', es: 'Nombre', en:"Name" }[language]}</label>
                            <FarmlyInput styleClass="new-profile user-send-sample-input" elementId="profile-name" placeholder="Jack"
                                value={data.name} setValue={value => setData({ ...data, name: value })}
                            /> */}
                        </div>
                        <div className="new-profile profile-information-edit-field">
                            <label htmlFor="profile-telephone" className="user-send-sample-input-label">{{ pt: 'Telefone', es: 'Teléfono', en:'Phone Number' }[language]}</label>
                            <InputMask id="profile-telephone" className="new-profile user-send-sample-input" mask="+99999999999999" alwaysShowMask={false}
                                placeholder={{ pt: 'telefone...', en: 'phone...' }[language]} value={data.phoneNumber} onChange={(e) => setData({ ...data, phoneNumber: e.target.value })} />
                        </div>
                        <div className="new-profile profile-information-edit-field">
                            <label htmlFor="profile-address" className="user-send-sample-input-label">{{ pt: 'Endereço', es: 'Dirección', en:'Address' }[language]}</label>
                            <FarmlyInput styleClass="new-profile user-send-sample-input" elementId="profile-address" placeholder={{ pt: 'Rua, numero, cidade, estado, país', es: 'Calle, número, ciudad, región, país', en: 'Street, number, City, State, Country' }[language]}
                                value={data.address} setValue={value => setData({ ...data, address: value })}
                            />
                        </div>
                        <div className="new-profile profile-information-edit-field">
                            <label htmlFor="postal-code" className="user-send-sample-input-label">{{ pt: 'Código Postal', es: 'Código Postal', en:'Postal Code' }[language]}</label>
                            <FarmlyInput styleClass="new-profile user-send-sample-input" elementId="postal-code" placeholder={12345678}
                                value={data.postalCode} setValue={value => setData({ ...data, postalCode: value })}
                            />
                        </div>
                        <div className="new-profile profile-information-edit-field">
                            <label htmlFor="country" className="user-send-sample-input-label">{{ pt: 'País', es: 'País', en:'Country' }[language]}</label>
                            <select className="new-profile user-send-sample-input" value={Countries.includes(data.country)?data.country:"Other"} name="country" 
                                id="country" onChange={(e) => setData({ ...data, country: e.target.value })}>
                                <option value="">{{pt:'Clique aqui e selecione uma opção',es:'Clic aquí y seleccione una opción',en:'Click here and select an option'}[language]}</option>
                                {
                                    Countries.map(country => <option>{country}</option>)
                                }
                            </select>
                        </div>
                        {
                            data.country === "Other"
                            ?
                            <div className="new-profile profile-information-edit-field">
                                <label htmlFor="different-country" className="user-send-sample-input-label">{{ pt: 'Outro País', es: 'Otro País', en:'Different Country' }[language]}</label>
                                <FarmlyInput styleClass="new-profile user-send-sample-input" elementId="different-country" placeholder=""
                                value={newCountry} setValue={value => setNewCountry(value)}
                                />
                            </div>
                            :
                            null
                        }
                        <div className="new-profile profile-information-edit-big-field">
                            <label htmlFor="profile-aboutMe" className="user-send-sample-input-label">{{ pt: 'Sobre mim', es: 'Sobre mí', en: 'About Me' }[language]}</label>
                            <FarmlyInput textArea rows={4} styleClass="new-profile user-send-sample-input" elementId="profile-aboutMe" placeholder={{ pt: "Descreva mais a sua torrefadora!", en: "Describe more your roaster!", es:'¡Describa más su tostadora!' }[language]}
                                value={data.description} setValue={value => setData({ ...data, description: value })}
                            />
                        </div>
                    </div>
                    <div className="new-profile profile-information-edit-grid">
                        <div className="new-profile profile-information-edit-field">
                            <label htmlFor="profile-roastery" className="user-send-sample-input-label">{{ pt: 'Nome da Torrefadora', en: "Roastery's Name", es:'Nombre de la Tostadora' }[language]}</label>
                            <FarmlyInput styleClass="new-profile user-send-sample-input" elementId="profile-roastery" placeholder={"Black Coffee Roaster"}
                                value={data.roastery} setValue={value => setData({ ...data, roastery: value })}
                            />
                        </div>
                    </div>
                </div>
                <div className='profile-information-edit-actions'>
                    <ButtonBase className="new-farmly-button f-button-orange" onClick={() => submitUserData(parseData())}>{{ pt: 'Confirmar edições', es: 'Confirmar ediciones', en: "Confirm edits" }[language]}</ButtonBase>
                </div>
            </div>
        );
    }
};

export default EditInformations;
