import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Main from './Main.jsx'
import './PageLanding.css'
import { isPt, server, isBr, acceptedCookies } from '../../common'
import FarmlyInput from './FarmlyInput.jsx'
import FarmlyModal from './FarmlyModal.jsx'
import UserContext from '../../context/user/UserContext.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faTimes } from '@fortawesome/free-solid-svg-icons'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import ReactGA from 'react-ga'

const separatorSeaSalt = require('../../assets/topinho.png')
const BottomSeaSalt = require('../../assets/bottomunderlay.png')
const separatorDiaphonous = require('../../assets/topinho2.png')
const separatorDiaphonousBottom = require('../../assets/bottomcut.png')
const separatorBlueStone = require('../../assets/topinho3.png')
const CoffeeDecoration = require('../../assets/coffee_decoration_homepage.jpg')
const CoffeeCupping = require('../../assets/Coffeecolheita.jpg.jpeg')// Imagem que usaremos
const firstWave = require('../../assets/wave-first.jpeg')



const LandingPageCupping = props => {

    const history = useHistory()
    const userContext = useContext(UserContext)
    const [userData, setUserData] = useState({})
    const [pleaseType, setPleaseType] = useState(false)
    const [alreadyResgistered, setAlreadyResgistered] = useState(false)
    const [showForm, setShowForm] = useState(false)

    const register = () => {
        
        if(userData.email != null && userData.address != null && userData.postal_code!= null && userData.country != null && userData.phone_number != null){
            axios.get(`${server}/getRegistrationData`, { email: userData.email})
            .then(res => {
                if (res.data.length) { 
                    axios.post(`${server}/newRegister`, { ...userData })
                        .then(_ => {
                            history.push({
                                pathname: '/thanksForRegistering',
                                state: { 
                                    user: userData
                                }
                            })
                            history.go()
                        })
                }
                 else {
                     setAlreadyResgistered(true)
                     setPleaseType(false)
                    }  
            })
            axios.post(`${server}/newRegister`, { ...userData })
            .then(_ => {
                history.push({
                    pathname: '/thanksForRegistering',
                    state: { 
                        user: userData
                    }
                })
                history.go()
            })
            
        }
        else {
            setPleaseType(true)
            setAlreadyResgistered(false)
        }  
    }   
   
    return (
        <Fragment>
            <Main noBanner>
                <div className="homepage-overview">
                    <div id="home-about" className="landing-begining" >
                        <div className="home-title" >
                            <span className="landing-title" style={{color: 'var(--farmly-diaphonous)'}}>Virtual Cupping </span>
                        </div>
                        <div className="home-section-container" style={{ paddingBottom: (window.screen.width < 430 && showForm) ? '14em' : '2em' }} >
                            <div className="homepage-text-container half-size">
                                <h2 className="bold" style={{ fontWeight: '600', color: 'var(--farmly-diaphonous)'}}>09/01 - 6pm </h2>
                                <h2 className="thin" style={{color: 'var(--farmly-diaphonous)', maxWidth: (window.screen.width == 768) ? '250px' : 'auto'}}>
                                    Meet the producer,
                                    <br />
                                    try their coffee and
                                    <br />
                                    buy it directly from the origin.
                                    <br />
                                    Do all that from home.
                                </h2>
                                {/* <input className="landing-input" placeholder="Type your email here" value={userData.email} onChange={e => setUserData({ ...userData, email: e.target.value })}></input>
                                <br />
                                <button className="register-button-2" onClick={register}>Register for the Cupping</button>
                                {pleaseType && <label className="login-fail-label">Please type an email first!</label>}
                                <h4 className="thin" style={{ lineHeight: '27px', color: 'var(--farmly-diaphonous)' }}>
                                    Click on the button above to register!</h4> */}
                            </div>
                        </div>
                        <h2 className="see-more"> See more </h2>
                        <div className="see-more-icon">
                            <FontAwesomeIcon icon={faAngleDown} style={{ height: '90px', width: '70px' }}></FontAwesomeIcon>
                        </div>
                        <div className="form-cupping-container-responsive" style={{display: window.screen.width < 800 ? '' : 'none', width: window.screen.width}}>
                                <div className="form-responsive-label">
                                    <label className="label-form-cupping">Register for the cupping and receive free 100g green coffee samples!</label>
                                </div>
                                {!showForm ?
                                    <div className="thanks-button" style={{marginTop: '0px'}}>
                                        <button className="register-button-3" onClick={() => {setShowForm(true)}}> Register for the cupping</button>
                                    </div>
                                :
                                 <div className="thanks-button" style={{marginTop: '0px'}}>
                                    <button className="register-button-3" onClick={register}> Register for the cupping</button>
                                </div>
                                }                             
                            </div>
                            <div className="form-cupping-container" style={{display: (window.screen.width > 800 || showForm )? '' : 'none'}}>
                                {!showForm?
                                    <>
                                        <label className="label-form-cupping" style={{fontSize: 'large', fontWeight: '600'}}>Register for the cupping and receive free 100g green coffee samples!</label>
                                        <br/>
                                        <br/>   
                                    </>
                                    :
                                    <div style={{marginTop: '20px', display: 'flex', flexDirection: 'column'}}>
                                        {pleaseType ?
                                            <label className="label-form-cupping" style={{fontSize: 'large', fontWeight: '600'}}> Please complete all fields!</label>
                                             : <>{alreadyResgistered ?
                                                <label className="label-form-cupping" style={{fontSize: 'large', fontWeight: '600'}}> Email already registered!</label>
                                                :
                                                <label className="label-form-cupping" style={{fontSize: 'large', fontWeight: '600'}}> Complete the fields below!</label>
                                             } 
                                            
                                            </>}
                                        <FontAwesomeIcon className='close-form-icon' icon={faTimes} onClick={() => {setShowForm(false)}}/>
                                    </div>
                                }
                                <div >
                                    <div className="form-cupping-inputs" >
                                        <div className="form-one-input">
                                            <label className="label-form-cupping">Email:</label>
                                            <input className="landing-input-form" value={userData.email} onChange={e => setUserData({...userData, email: e.target.value})}/>   
                                        </div> 
                                        <div className="form-one-input">
                                            <label className="label-form-cupping">Address:</label>
                                            <input className="landing-input-form" value={userData.address} onChange={e => setUserData({...userData, address: e.target.value})}/>   
                                        </div>                                   
                                        <div className="form-one-input">
                                            <label className="label-form-cupping">Postal Code:</label>
                                            <input className="landing-input-form" value={userData.postal_code} onChange={e => setUserData({...userData, postal_code: e.target.value})}/>
                                        </div>
                                        <div className="form-one-input">
                                            <label className="label-form-cupping">Country:</label>
                                            <input className="landing-input-form" value={userData.country} onChange={e => setUserData({...userData, country: e.target.value})}/>                                      
                                        </div>
                                        <div className="form-one-input">
                                            <label className="label-form-cupping">Phone:</label>
                                            <input className="landing-input-form" value={userData.phone_number} onChange={e => setUserData({...userData, phone_number: e.target.value})}/>
                                        </div>
                                    </div>
                                    <br/>
                                    <div className="thanks-button" style={{display: (window.screen.width > 800 )? '' : 'none'}}>
                                        <button className="register-button-2" style={{fontSize: 'larger', width: '70%'}} onClick={register}> Register for the cupping</button>
                                    </div>
                                    {pleaseType && <><br/><label className="login-fail-label" style={{alignSelf: 'center'}} >Please complete all fields to continue!</label></>}
                                    {alreadyResgistered && <><br/><label className="login-fail-label" style={{alignSelf: 'center'}} >Email already registered!</label></>}
                                </div>
                            </div>
                        
                    </div>
                    <div className="white-wave" style={{width: window.screen.width}}><svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{height: '100%', width: '100%'}}><path d="M0.00,49.99 C150.00,150.00 349.20,-49.99 500.00,49.99 L500.00,150.00 L0.00,150.00 Z" style={{stroke: 'none', fill: '#fff'}}></path></svg></div>
                    <div id="home-about" className="home-layer" >
                        <div className="home-title">
                            <span className="bold" style={{ color: 'var(--farmly-bistre)' }}>How does it work?</span>
                        </div>
                        <div className="home-section-container"  >
                            <div>
                                <img src={CoffeeCupping} className="coffee-colheita" alt="coffee beans" />
                            </div>
                            <div className="homepage-text-container half-size">
                                <br />
                            A lot of roasters, around this time of the year, would be visiting the origin to meet the producer that will supply them for the following months.
                            <br />
                                <br />
                            We decided to mimic this on an online event, so you will be able to order samples and try it along with the producer.
                            <br />
                                <br />
                            This way you will be able to buy directly from the origin without need to travel to the origin.
                            <br />
                                <br />
                            You are going to receive 3 samples (100g green coffee samples) that we will cup together at the event. You can also choose 4 more samples that may suit a coffee profile that you like, in case you are looking forward to buying some great coffee directly from the producer.
                            <br />
                                <br />
                            If you decide to buy any of the coffees FARMly will take care of all the export, import, and logistics so no need to worry about that, even if you are going to buy one bag of coffee.
                               {window.screen.width > 800 &&
                                    <Fragment>
                                        <br />
                                    </Fragment>
                                }
                            </div>
                        </div>
                        <div className="home-section-container" style={{ paddingBottom: '2em' }} >
                            <img src={CoffeeDecoration} className="coffee-colheita" alt="coffee beans" />
                            <div className="homepage-text-container half-size" style={{ alignItems: 'center', display: window.screen.width < 800 ? 'none' : '' }}>
                                {window.screen.width > 800 &&
                                    <Fragment>
                                        <br />
                                    </Fragment>
                                }
                                <button className="register-button-2" onClick={() => {
                                    window.scrollTo({
                                        top: (window.screen.width < 430) ? (window.screen.width < 330 ? 700 : 500) : 50,
                                        behavior: 'smooth'
                                    })}}>
                                    Register for the cupping
                                </button>
                                <h4 className="obs-register" style={{ lineHeight: '27px' }}>
                                    Click on the button above and fill the form to register!</h4>
                            </div>
                        </div>
                    </div>
                    <div className="green-wave" style={{width: window.screen.width}}><svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{height: '100%', width: '100%'}}><path d="M0.00,49.99 C150.00,150.00 349.20,-49.99 500.00,49.99 L500.00,150.00 L0.00,150.00 Z" style={{stroke: 'none', fill: 'rgb(27, 98, 94)'}}></path></svg></div>
                    <div id="home-signup-prompt" className="home-how-much">
                        <div className="home-title" style={{ color: 'black', margin: '0px', fontWeight:'520' }}>
                            How much is it?
                    </div>
                        <br />
                        <div className="money-text">
                            We will ship you 7 samples of coffee for free, FARMly gets a small fee when you purchase the coffee from the producer, which is the payment for handling all of the logistics, export and import even for small quantities. So if in the end you don't buy coffee from the producer, no worries there is nothing to pay ; )
                        <br />
                            <br />
                        FARMly is shipping the samples for free to promote the connection between the producer and the roaster and to support small producers that need a little help to continue the great work that they are doing at the origin.
                    
                    </div>
                        <div className="home-title" style={{ color: 'black', margin: '0px', fontSize: '25px' }}>
                            If you have any questions feel free to ask!
                    </div>
                        <div className="help-contacts">
                            <a target="_blank" rel="noopener noreferrer" href={`https://api.whatsapp.com/send?phone=+5527999802545`} style={{ display: 'flex', flexDirection: 'row', padding: '1vw' }}>
                                <FontAwesomeIcon className="whatsapp-icon-cart" icon={faWhatsapp} style={{ color: 'white' }} />
                                <label style={{ color: 'white' }}> +55 27 99980 2545</label>
                            </a>

                            <div style={{ display: 'flex', flexDirection: 'row', padding: '1vw' }}>
                                <FontAwesomeIcon className='footer-contacts-icon' icon={faEnvelope} style={{ color: 'white' }} />
                                <label style={{ color: 'white' }}>lfaria@farmlyclub.com</label>
                            </div>
                        </div>
                    </div>

                </div>
            </Main >
        </Fragment>
    )
}
export default LandingPageCupping

//REMOVED CONTENT

/*  const [emailSent, setEmailSent] = useState(false)
    const [finishPopUp, setFinishPopUp] = useState(false)
    const [addressPopUp, setAddressPopUp] = useState(false)
    const [moreSamplesPopUp, setMoreSamplesPopUp] = useState(false)
    const [pleaseTypeAddress, setPleaseTypeAddress] = useState(false)


    const finish = (from) => {
        if(from) setEmailSent(false)
        else setMoreSamplesPopUp(false)
        setFinishPopUp(true)
        
    }

    const collectAddress = () => {
        setEmailSent(false)
        setAddressPopUp(true)   
    }

        // const registerAddress = () => {
    //     if(userData.address == null || userData.country == null || userData.postal_code == null || userData.phone_number == null){
    //         setPleaseType(true)
    //     }
    //     else {
    //         axios.patch(`${server}/updateRegistrationData`, { 
    //             email: userData.email,
    //             address: userData.address,
    //             country: userData.country,
    //             postal_code: userData.postalCode,
    //             phone_number: userData.phoneNumber 
    //         })
    //         .then(_ => {
    //             history.push({
    //                 pathname: '/thanksForRegistering',
    //                 state: { 
    //                     user: userData
    //                 }
    //             })
    //             history.go()
    //         }) 
    //     }
    // }

     const registerAddress = () => {
        if(userData.address == null || userData.country == null || userData.postalCode == null || userData.phoneNumber == null){
            setPleaseTypeAddress(true)
        }
        else {
            axios.patch(`${server}/updateRegistrationData`, { 
                email: userData.email,
                address: userData.address,
                country: userData.country,
                postal_code: userData.postalCode,
                phone_number: userData.phoneNumber 
            }) 
            setAddressPopUp(false)
            setMoreSamplesPopUp(true)
            setPleaseTypeAddress(false)
        }
    }


            {emailSent && <FarmlyModal style={{ display: 'flex', flexWrap: 'wrap' }} closeModal={() => setEmailSent(false)}>
                <label id="request-title">
                    Thanks for registering for the online cupping!
                </label>
                <br />
                <br />
                <label>It will be a pleasure to have you with us!</label>
                <br/>
                <label> Would you like to receive the 3 samples that are going to be on the cupping?</label>
                <br />
                <br />
                <div className="two-buttons-landing">
                    <button className="register-button-samples" onClick={() => finish(true)}> No</button>
                    <button className="register-button-samples"onClick={collectAddress}> Yes</button>
                </div>
                
            </FarmlyModal>}

            {addressPopUp && <FarmlyModal closeModal={() => { setAddressPopUp(false) }} className="modal-sample">
                    <div className="modal-landing-page">
                        <label id="request-title">
                            Give us some information so we can send you the samples!
                        </label>
                        <br />
                        <div className="producer-profile-row" style={{ width: '100%' }}>
                            <div className="producer-profile-item">
                                <label htmlFor="" className="producer-profile-label">Address: </label>
                                <FarmlyInput value={userData.address} setValue={value => setUserData({ ...userData, address: value })}
                                    styleClass="modal-input" elementId="address" placeholder="address..." />
                            </div>
                            <div className="producer-profile-item" style={{ marginLeft: '2vw' }}>
                                <label htmlFor="" className="producer-profile-label">Postal Code: </label>
                                <FarmlyInput value={userData.postalCode} setValue={value => setUserData({ ...userData, postalCode: value })}
                                    styleClass="modal-input" elementId="postalCode" placeholder="postal code..." />
                            </div>
                        </div>
                        <div className="producer-profile-row" style={{ width: '100%' }}>
                            <div className="producer-profile-item">
                                <label htmlFor="" className="producer-profile-label">Country: </label>
                                <FarmlyInput value={userData.country} setValue={value => setUserData({ ...userData, country: value })}
                                    styleClass="modal-input" elementId="country" placeholder="country..." />
                            </div>
                            <div className="producer-profile-item">
                                <label htmlFor="" className="producer-profile-label">Phone Number: </label>
                                <FarmlyInput value={userData.phoneNumber} setValue={value => setUserData({ ...userData, phoneNumber: value })}
                                    styleClass="modal-input" elementId="phone" placeholder="phone..." />
                            </div>
                        </div>
                        <div className="sign-up-button">
                            <button className="register-button-samples" onClick={registerAddress}> Done</button>
                        </div>
                        <br/>
                        {pleaseTypeAddress && <label className="login-fail-label">Please fill in all information first!</label>}
                    </div>
                </FarmlyModal>}

                 {finishPopUp && <FarmlyModal style={{ display: 'flex', flexWrap: 'wrap' }} closeModal={() => setFinishPopUp(false)}>
                <label id="request-title">
                    Thanks once again!
                </label>
                <br />
                <br />
                <label> We just sent you more information via email, please take a look if you just received one of our emails. </label>
                <br/>
                <label>If it ended up on the spam folder, please check it as “not spam”.</label>
                <br/>
                <label>This way you make sure that you will receive the link for the cupping via email on the following days!</label>
                <br />
            </FarmlyModal>}
            {moreSamplesPopUp && <FarmlyModal style={{ display: 'flex', flexWrap: 'wrap' }} closeModal={() => setMoreSamplesPopUp(false)}>
                <label id="request-title">
                    Your order has been registered!
                </label>
                <br />
                <br />
                <label>Would you like to taste some more coffee samples? We can add 4 more to your shipment! </label>
                <br/>
                <label>If you're interested, go check our offer list!</label>
                <br />
                <br />
                <div className="two-buttons-landing">
                    <button className="register-button-samples" onClick={() => finish(false)}> No thanks</button>
                    <button className="register-button-samples"onClick={offerList}> Offer List</button>
                </div>
                
            </FarmlyModal>}
            */