import React, { useEffect } from 'react';
import ListSampleCarts from "./components/ListSampleCarts";
import InputLabelFarmCode from "./components/InputLabelFarmCode";
import AccordionRoasterData from "./components/AccordionRoasterData";
import CoffeeResultDisplay from "./components/CoffeeResultDisplay";
import AddCoffeeToListButton from "./components/AddCoffeeToListButton";

import {
    SampleCartBox, SampleSelector, CartActions,
    SampleCartContainer, ColumnSampleInfos, ExpireContainer,
    CreateNewCartButtonContainer
} from "./styles";
import CoffeeList from './components/CoffeeList';
import CreateNewCartButton from './components/CreateNewCartButton';
import InputLabelExpireDate from './components/InputLabelExpireDate';
import TextFieldCartName from './components/TextFieldCartName';
import LinkToCart from './components/LinkToCart';
import CreateSampleCartButton from './components/createSampleCartButton';
import UpdateDateButton from './components/UpdateDateButton';
import useSampleCarts from './hooks/useSampleCarts';
import CoffeeModel from '../models/coffeeModel';

const SampleCart =  () => {
    const sampleCartsHook = useSampleCarts();

    return (
        <>
            <SampleCartBox>
                <ListSampleCarts
                    sampleCartList={sampleCartsHook.sampleCartList}
                    getSampleCart={sampleCartsHook.getSampleCart}
                    deleteSampleCart={sampleCartsHook.deleteSampleCart}
                />
                <SampleSelector >
                    <ColumnSampleInfos>
                        <InputLabelFarmCode
                            query={sampleCartsHook.query}
                            searchCoffees={sampleCartsHook.searchCoffees}
                            setQuery={sampleCartsHook.setQuery}
                        />
                        <CoffeeResultDisplay
                            searchedCoffee={sampleCartsHook.searchedCoffee as Array<CoffeeModel>}
                            alreadyIn={sampleCartsHook.alreadyIn}
                        />
                        <AddCoffeeToListButton
                            alreadyIn={sampleCartsHook.alreadyIn }
                            searchedCoffee={sampleCartsHook.searchedCoffee}
                            addToSampleCart={sampleCartsHook.addToSampleCart}
                        />
                    </ColumnSampleInfos>
                    <CreateNewCartButtonContainer>
                        <CreateNewCartButton
                            sampleCart={sampleCartsHook.sampleCart}
                        />
                    </CreateNewCartButtonContainer>
                    <SampleCartContainer>
                        <CoffeeList
                            sampleCart={sampleCartsHook.sampleCart}
                            removeFromSampleCart={sampleCartsHook.removeFromSampleCart}
                        />
                        <ExpireContainer>
                            <LinkToCart
                                sampleCart={sampleCartsHook.sampleCart}
                            />
                            <InputLabelExpireDate
                                sampleCart={sampleCartsHook.sampleCart}
                                setUpdateDate={sampleCartsHook.setUpdateDate}
                            />
                        </ExpireContainer>
                        <CartActions>
                            <TextFieldCartName
                                sampleCart={sampleCartsHook.sampleCart}
                                setSampleCart={sampleCartsHook.setSampleCart}
                                setChangesHappen={sampleCartsHook.setChangesHappen}
                            />
                            <AccordionRoasterData
                                sampleCart={sampleCartsHook.sampleCart}
                                setSampleCart={sampleCartsHook.setSampleCart}
                                setChangesHappen={sampleCartsHook.setChangesHappen}
                            />
                            <CreateSampleCartButton
                                sampleCart={sampleCartsHook.sampleCart}
                                changesHappen={sampleCartsHook.changesHappen}
                                createSampleCart={sampleCartsHook.createSampleCart}
                            />
                            <UpdateDateButton
                                updateDate={sampleCartsHook.updateDate}
                                updateExpireDate={sampleCartsHook.updateExpireDate}
                            />
                        </CartActions>
                    </SampleCartContainer>
                </SampleSelector>
            </SampleCartBox>
        </>
    );
}

export default SampleCart;