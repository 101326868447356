import backend from '../../../../lib/backend'
import IShipmentModel from '../../models/ShipmentModel'
import { useState, useEffect } from 'react';
import { server } from 'common';

const useUpdateCoffee = (shipmentData: IShipmentModel) => {

    const [shipmentUpdate, setShipmentUpdate] = useState(shipmentData)

    useEffect(() => {
        setShipmentUpdate({...shipmentData})    
    }, [shipmentData])   

    const updateShipmentData = (id?: number) => {
        backend.patch(`${server}/shipping?shipping_id=${id}`, {
            ...shipmentUpdate,
        })
        .then(_ => console.log("Success"))
        .catch(err => console.log(err))
    }

    const updateShipmentDisplay = (value: boolean) => {
        setShipmentUpdate({...shipmentUpdate, offerlist_display: value})
    }

    const checked = () => { return shipmentUpdate.offerlist_display ? shipmentUpdate.offerlist_display : false }    

return { updateShipmentData, updateShipmentDisplay, checked }

}

export default useUpdateCoffee