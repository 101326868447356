import * as React from 'react';
import { FunctionComponent } from 'react';

import {Container, CardImg} from './styles';

interface CardHeaderProps {
    image   : string,
}
 
const CardHeader: FunctionComponent<CardHeaderProps> = (props) => {

    const {image} = props

    return (
        <Container>
            <CardImg src={image} alt="" />
        </Container>
    );
}
 
export default CardHeader;