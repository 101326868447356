import React, { useState, useEffect } from 'react'
import './PaymentData.css'

import FarmlyInput from '../templates/FarmlyInput.jsx'
import axios from 'axios'
import { isPt, farmlyTexts, server } from '../../common'

const PaymentData = props => {

    const { coffeeId, close } = props

    const stdObj = {
        farmly_fee: 0,
        logistics_fee: 0,
        import_duties: 0,
        import_duties_adjustment: 0,
        euro_value: 0
    }

    const [paymentData, setPaymentData] = useState({
        ...stdObj,
        farmly_fee_euro: 0,
        logistics_fee_euro: 0,
        import_duties_euro: 0
    })

    const refreshData = () => {
        Promise.all([axios.get(`${server}/coffee/${coffeeId}`), axios.get(`${server}/paymentData`)])
            .then(([coffeeDataRes, defaultsRes]) => {
                const coffeeData = coffeeDataRes.data
                paymentData['is_applied_taxes'] = coffeeData.is_applied_taxes
                document.querySelector('#isAppliedTaxes').checked = coffeeData.is_applied_taxes
                const defaults = defaultsRes.data
                const updatedData = {}
                Object.keys(paymentData).forEach(key => updatedData[key] = coffeeData[key])
                Object.keys(stdObj).forEach(key => {
                    if(coffeeData[key] === null) updatedData[key] = defaults[key]
                    else updatedData[key] = coffeeData[key]
                })
                setPaymentData(updatedData)
            })
    }


    const stringToFloat = obj => {
        const updatedObj = {}
        Object.keys(obj).forEach(key => {
            let val
            if(key !== 'is_applied_taxes'){
               val = parseFloat(obj[key])
                if(val === NaN) val = null
                updatedObj[key] = val            
            }
            else {
                updatedObj[key] = obj[key]
            }
        })
        return updatedObj
    }

    useEffect(refreshData, [])

    const inputItem = (name, title) => {

        return (
            <div className="coffee-payment-input-item">
                <label className='control-panel-payments-label'
                htmlFor={`admin-payments-${name}`}>{title}</label>
                <FarmlyInput value={paymentData[name]}
                    setValue={val => {
                        const updatedData = {...paymentData}
                        updatedData[name] = val
                        setPaymentData(updatedData)
                    }} elementId={`admin-payments-${name}`}
                    styleClass="coffee-payment-input" />
            </div>
        )
    }

    
    document.addEventListener('DOMContentLoaded', () => {
        const inputFields = document.querySelector('.coffee-profile-payment-data').children
        let input, inputId
        for(let curInput = 0; curInput < inputFields.length; curInput++){
            inputId = inputFields.item(curInput).id
            input = inputFields.item(curInput).addEventListener('click', () => {
                if(inputId != 'euro_value' && inputId != 'import_duties_adjustment'){

                }
            })
        }
    })

    return (
        <div className="coffee-profile-payment-data">
            <label>Modificar por taxas</label>
            <input type="checkbox" id="isAppliedTaxes" onChange={(e) => {
                     paymentData['is_applied_taxes'] =  e.target.checked
                     const updatedData = {...paymentData}
                     setPaymentData(updatedData)
            }}  />
            {inputItem('euro_value', 'Valor do Euro')}
            {/* <label>Considerar Farmly Fee</label>
            <input type="checkbox" onChange={(e) => {
                    const isModifiedValue =  e.target.value === 'on' ? true : false
                     paymentData['isAppliedTaxes'] = isModifiedValue
                     const updatedData = {...paymentData}
                     setPaymentData(updatedData)
            }} id="farmlyFeeChk"  /> */}
            {inputItem('farmly_fee', 'Tarifa da FARMly (%)')}
            {/* <label>Considerar Farmly Fee Euro</label>
            <input type="checkbox" onChange={(e) => {
                    const isModifiedValue =  e.target.value === 'on' ? true : false
                     paymentData['isAppliedTaxes'] = isModifiedValue
                     const updatedData = {...paymentData}
                     setPaymentData(updatedData)
            }} id="farmlyFeeEuroChk"  /> */}
            {inputItem('farmly_fee_euro', 'Tarifa da FARMly em Euro')}
            <br></br>
            {/* <label>Considerar Logistics Fee</label>
            <input type="checkbox" onChange={(e) => {
                    const isModifiedValue =  e.target.value === 'on' ? true : false
                     paymentData['isAppliedTaxes'] = isModifiedValue
                     const updatedData = {...paymentData}
                     setPaymentData(updatedData)
            }} id="logisticsFeeChk"  /> */}
            {inputItem('logistics_fee', 'Custos de Logística (Valor fixo em R$)')}
            {/* <label>Considerar Logistics Fee Euro</label>
            <input type="checkbox" onChange={(e) => {
                    const isModifiedValue =  e.target.value === 'on' ? true : false
                     paymentData['isAppliedTaxes'] = isModifiedValue
                     const updatedData = {...paymentData}
                     setPaymentData(updatedData)
            }} id="logisticsFeeEuroChk"  /> */}
            {/* <label>Considerar Import Duties</label>
            {inputItem('logistics_fee_euro', 'Custos de Logística (Valor fixo em euro)')}
            <input type="checkbox" onChange={(e) => {
                    const isModifiedValue =  e.target.value === 'on' ? true : false
                     paymentData['isAppliedTaxes'] = isModifiedValue
                     const updatedData = {...paymentData}
                     setPaymentData(updatedData)
            }} id="importDutiesChk"  /> */}
            {inputItem('import_duties', 'Custos de importação (Sobre o custo da saca)')}
            {inputItem('import_duties_adjustment', 'Ajuste do valor da moeda para os custos de importação')}
            {/* <label>Considerar Import Duties Euro</label>
            <input type="checkbox" onChange={(e) => {
                    const isModifiedValue =  e.target.value === 'on' ? true : false
                     paymentData['isAppliedTaxes'] = isModifiedValue
                     const updatedData = {...paymentData}
                     setPaymentData(updatedData)
            }} id="importDutiesEuroChk" /> */}
            {inputItem('import_duties_euro', 'Custos de importação em euro')}
            <div className="control-panel-payments-buttons" style={{ marginTop: "30px" }}>
                <div className="control-panel-button-yes"
                    onClick={() => {
                        const obj = {
                            ...stringToFloat(paymentData),
                            id: parseInt(coffeeId)
                        }
                        console.log(obj)
                        axios.patch(`${server}/coffees`, obj)
                            .then(_ => {
                                alert("Dados atualizados!")
                            })
                            .catch(err => {
                                alert("Erro de servidor!")
                                console.log(err)
                            })
                    }}>
                    Atualizar
                </div>
                <div className="control-panel-button-no"
                    onClick={close}>
                    Cancelar
                </div>
                {/* <label>Considerar Farmly Fee</label>
            <input type="checkbox" onChange={(e) => {
                    const isModifiedValue =  e.target.value === 'on' ? true : false
                     paymentData['isAppliedTaxes'] = isModifiedValue
                     const updatedData = {...paymentData}
                     setPaymentData(updatedData)
            }} id="farmlyFeeChk"  />
            
                <div style={{width: "30%", maxWidth: '50%'}}>
                    <div style={{maxWidth: '18vw', marginTop: "10px"}}>
                        <label>Considerar Import Duties Euro</label>
            <input type="checkbox" onChange={(e) => {
                    const isModifiedValue =  e.target.value === 'on' ? true : false
                     paymentData['isAppliedTaxes'] = isModifiedValue
                     const updatedData = {...paymentData}
                     setPaymentData(updatedData)
            }} id="importDutiesEuroChk" />
                    </div>
                </div> */}
            </div>
        </div>
    )

}

export default PaymentData