import backend from '../../../../lib/backend'
import { useState, useEffect } from 'react';
import { server } from 'common';

const useShipments = () => {

    const [shipments, setShipments] = useState([{}])

    useEffect(() => {
        getShipments()
    }, [])  

    const getShipments = () => {
        backend.get(`${server}/shipping`)
        .then(shipments => {
            setShipments(shipments.data)
        })
        .catch(err => console.log(err))
    }

    return { shipments }

}

export default useShipments