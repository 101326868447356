import React from "react";
import { LegendsLine, LegendsTitle } from "./styles";

export default function TableLegends(props: {selectBoard: string}) {

  const {selectBoard} = props;

  return (
    <LegendsLine>
        <LegendsTitle> Código </LegendsTitle>
        <LegendsTitle> Produtor </LegendsTitle>
        <LegendsTitle> Processo </LegendsTitle>

        <LegendsTitle> 
          {{
              main: "Nº de sacas",
              approval: "Nº de sacas",
              label: "Variedade"
          }[selectBoard]}
        </LegendsTitle>

        <LegendsTitle> 
          {{
              main: "Preço R$/saca",
              approval: "Pontuação",
              label: "Altitude"
          }[selectBoard]} 
          </LegendsTitle>

          {selectBoard === "main" && <LegendsTitle> Taxa da Farmly </LegendsTitle>}


        <LegendsTitle> 
          {{
              main: "Provador",
              approval: "Notas Sensoriais",
              label: "Região"
          }[selectBoard]} 
          </LegendsTitle>

        <LegendsTitle>
          {{
              main: "Embarque",
              approval: "Comentários",
              label: "Fazenda"
          }[selectBoard]}
        </LegendsTitle>

        <LegendsTitle>
          {{
              main: "Offerlist",
              approval: "Aprovar",
              label: "Link"
          }[selectBoard]}
        </LegendsTitle>

        {selectBoard != 'label' && <LegendsTitle> Salvar </LegendsTitle>}
    </LegendsLine>
  );
}
