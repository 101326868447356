import React, { useState, Fragment } from 'react'
import './FloatingButton.css'
import AddIcon from '@material-ui/icons/Add'
import ReactGA from 'react-ga'
import { isPt } from '../../common';


const sensoryNotesIcon = require('../../assets/svg/Group 126.svg');

const FloatingButton = props => {

    const { setNotesModalFunction } = props

    //REPORT ANALYTICS
    const clickAddSensoryNotes = () =>{
        ReactGA.event({
            category: 'Cupping',
            action: 'User clicked on add sensory notes button at cupping page'
        })
    }

    return (
        <Fragment>
            {
                window.screen.width < 768 
                    ?
                    <div>
                        <img src={sensoryNotesIcon} className="button" alt="sensory-notes-icon" onClick={setNotesModalFunction}></img>
                    </div>
                    :
                        <div className="sensory-notes-button-group">
                            <img src={sensoryNotesIcon} id="sensory-notes" className="" alt="sensory-notes-icon"></img>
                            <button className='button-desktop' onClick={()=>{setNotesModalFunction(); clickAddSensoryNotes()}}>
                                {isPt() ? '+ Adicionar notas sensoriais' : '+ Add sensory notes'}
                            </button>
                        </div>
            }
        </Fragment>
        // <button className={window.screen.width < 768 ? 'button' : 'button-desktop'} onClick={setNotesModalFunction}>
        //     Sensory Notes
        // </button>
    )
}
export default FloatingButton