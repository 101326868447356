import styled from "styled-components";

export const CoffeeLineContainer = styled.tr`
    height: 4vw;
`

export const CoffeeInfo = styled.td`
    width: 10vw;
    text-align: center;
`

export const CoffeeLink = styled.a`
    width: 10vw;
    cursor: pointer;
`