import React, { FunctionComponent } from 'react'

import {
    Button
} from '@material-ui/core';

import { SampleCartActionButton } from './style'
interface UpdateDateButtonProps {
    updateDate: boolean,
    updateExpireDate: () => void
}

const UpdateDateButton: FunctionComponent<UpdateDateButtonProps> = (props) => {
    return (
        <>
            <SampleCartActionButton>
                <Button className="sample-cart-action-button"
                    disabled={!props.updateDate}
                    variant="contained"
                    color="primary"
                    onClick={props.updateExpireDate}
                >
                    Atualizar data
                </Button>
            </SampleCartActionButton>
        </>
    );
}
export default UpdateDateButton;