import React, { Component } from "react";
import { server } from "../../common.js";
import { CSVLink } from "react-csv";
import axios from "axios";
import "./roaster.css"

class RoasterCSV extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: false,
    };
    this.csvLinEL = React.createRef();
    this.headers = [
      { label: 'Name', key: 'name' },
      { label: "Email", key: "email" },
      { label: "Phone", key: "phoneNumber" },
    ];
  }

  getUserList = () => {
    axios
      .post(`${server}/allroasters`, {
        jwt: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6MCwiaXNQcm9kIjp0cnVlfQ.jz7ndAizRk4RLL8kUxctukBVuvbs8qsXooLifDSDwB4",
      })
      .then((res) => res.data);
  };

  downloadReport = async () => {
    this.setState({ loading: true });
    await axios
      .post(`${server}/allroasters`, {
        jwt: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6MCwiaXNQcm9kIjp0cnVlfQ.jz7ndAizRk4RLL8kUxctukBVuvbs8qsXooLifDSDwB4",
      })
      .then((res) => {
        this.setState({ data: res.data, loading: false }, () => {
          setTimeout(() => {
            this.csvLinEL.current.link.click();
          });
          console.log(res.data);
        });
      });
  };

  render() {
    const { data, loading } = this.state;
    return (
      <div>
        <input
        className = "button1"
          type="button"
          value={loading ? "Downloading..." : "Export To CSV"}
          onClick={this.downloadReport}
          disabled={loading}
        />
        <CSVLink
          headers={this.headers}
          data={data}
          filename="Torrefadores.csv"
          ref={this.csvLinEL}
        />
      </div>
    );
  }
}

export default RoasterCSV;
