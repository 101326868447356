import React, { Fragment } from 'react'

import './Sensory.css'
import Title from '../templates/Title.jsx'
import RadarChart from '../templates/RadarChart.jsx'
import FarmlyInput from '../templates/FarmlyInput.jsx'
import DataList from '../templates/Datalist.jsx'
import { isPt, farmlyTexts, isCo, server } from '../../common'
import RatingCard from '../templates/Rating'

const sensoryIcon = require('../../assets/Icons/almond.svg')

const ScoreInput = props => {
    const { label, value, setValue } = props

    return (
        <div className="score-input-container">
            <label className="score-label">{label}</label>
            <FarmlyInput value={value !== '0' ? value : ""} setValue={setValue} type="numeric" upperBound={10}
                lowerBound={6} decimals={2} styleClass="score-input" elementId={label} />
        </div>
    )
}

const Sensory = props => {

    const { chartAttributes, isOwner, isProducer, hasEdited, coffeeData, setCoffeeData, scoreAttributes,
        autocomplete, sensoryList, setSensoryList, handleSave, reset } = props

    const removeItem = (item) => {

        const oldList = [...sensoryList]
        if ((sensoryList.indexOf(item) >= 0)) {
            oldList.splice(sensoryList.indexOf(item), 1)
            setSensoryList(oldList)
        }
    }


    const renderNotes = () => {
        return (sensoryList.map((item) =>
            <div id={item} key={item} className="notes-label">
                <img className="coffee-icon" src={sensoryIcon} alt='sensorial' />
                {item.slice(0, 1) === ' ' ? item.slice(1, 2).toUpperCase() + item.slice(2, item.length) : item.slice(0, 1).toUpperCase() + item.slice(1, item.length)}
                {isOwner && !isProducer && <div id={item} className="remove-notes" onClick={event => {
                    event.target.id === item && removeItem(item)
                }}>
                    x
                </div>}
            </div>))
    }

    const scoreInputs = () => {
        const labels = Object.keys(chartAttributes[0])
        return scoreAttributes.map((key, index) =>
            <ScoreInput key={`si-${key}`} label={labels[index]} value={coffeeData[key] || '0'}
                setValue={value => {
                    const data = { ...coffeeData }
                    data[key] = value
                    setCoffeeData(data)
                }} />)
    }

    return (
        <div id="coffee-sensory" className={`${isPt() ? farmlyTexts.menuSensoryTitle_pt : isCo() ? farmlyTexts.menuSensoryTitle_es : farmlyTexts.menuSensoryTitle_en} coffee-section-margin-top`}>
            <Title title={isPt() ? farmlyTexts.menuSensoryTitle_pt : isCo() ? farmlyTexts.menuSensoryTitle_es : farmlyTexts.menuSensoryTitle_en} showIcon uppercase ml_20 />
            {(isOwner && !isProducer) ?
                //owner
                <div className="sensory-container">
                    {(coffeeData.farmCode == 321 || coffeeData.farmCode == 319 || coffeeData.farmCode == 320 || coffeeData.farmCode == 322 || coffeeData.farmCode == 323 || coffeeData.farmCode == 324) ?
                        <Fragment />
                        :
                        <div className="radar-container">
                            <div className="sensory-radar-chart">
                                <RadarChart attributes={chartAttributes} />
                            </div>
                            <div className="sensory-notes-container">
                                {coffeeData[scoreAttributes[0]] !== undefined && scoreInputs()}
                            </div>
                        </div>}
                    <div className="notes-list">
                        <p className="notes-title-label">{isPt() ? farmlyTexts.sensoryTitle_pt : isCo() ? farmlyTexts.sensoryTitle_es : farmlyTexts.sensoryTitle_en}</p>
                        <div className="sensory-notes">
                            {renderNotes()}
                            <DataList options={autocomplete} userSelection={sensoryList} setUserSelection={setSensoryList} />
                        </div>
                    </div>
                </div>

                :

                // not owner
                (coffeeData.id == 166 || coffeeData.id == 167 || coffeeData.id == 168 || coffeeData.farmCode == 319 || coffeeData.farmCode == 320 || coffeeData.farmCode == 321 || coffeeData.farmCode == 322 || coffeeData.farmCode == 323 || coffeeData.farmCode == 324) ?
                    <div className="sensory-container-roaster">
                        <div className="notes-list-roaster">
                            <p className="notes-title-label">{isPt() ? farmlyTexts.sensoryTitle_pt : isCo() ? farmlyTexts.sensoryTitle_co : farmlyTexts.sensoryTitle_en}</p>
                            <div className="sensory-notes">
                                {renderNotes()}
                            </div>
                        </div>
                    </div>
                    :
                    <div className="sensory-container-roaster">
                        <div className="sensory-radar-chart">
                            <RadarChart attributes={chartAttributes} />
                        </div>
                        <div className="notes-list-roaster">
                            <p className="notes-title-label">{isPt() ? farmlyTexts.sensoryTitle_pt : isCo() ? farmlyTexts.sensoryTitle_es : farmlyTexts.sensoryTitle_en}</p>
                            <div className="sensory-notes">
                                {renderNotes()}
                            </div>
                        </div>
                    </div>

            }

            {isOwner && hasEdited &&
                <div style={{ display: 'flex' }}>
                    <div className="edit-coffee-container" style={{ justifyContent: 'center' }}>
                        <div className="edit-coffee-button delete-coffee-button" onClick={reset}>{isPt() ? farmlyTexts.sensoryCancelButton_pt : isCo() ? farmlyTexts.sensoryCancelButton_es : farmlyTexts.sensoryCancelButton_en}</div>
                        <div className="edit-coffee-button" onClick={handleSave}>{isPt() ? farmlyTexts.sensorySaveButton_pt : isCo() ? farmlyTexts.sensorySaveButton_es : farmlyTexts.sensorySaveButton_en}</div>
                    </div>
                </div>
            }
        </div>
    )
}

export default Sensory