import styled from "styled-components";

export const CardDividerSVG1 = require('assets/svg/vector1.svg');
export const CardDividerSVG2 = require('assets/svg/vector2.svg');
export const CardDividerSVG3 = require('assets/svg/vector3.svg');
export const CardDividerSVG4 = require('assets/svg/vector4.svg');

export const Container = styled.div`
    z-index: 0;
    width: 100%;
    height: 10%;
    position: relative;
    img{
        width: 100%;
        position: absolute;
    }
    .fst-d{
    top: -9px;
    }
    .scn-d{
    top: -7px;
    }
    .thr-d{
    top: 0px;
    }
    .frt-d{
    top: 10px;
    }
`
