import React, { Fragment, useState, useEffect, useContext } from "react";
import MainFarmlyCupping from './MainFarmlyCupping'
import backend from 'axios'
import { server, isPt, isBr, acceptedCookies } from '../../../common'
import UserContext from '../../../context/user/UserContext'
import CoffeeFarmlyCuppingCard from './CoffeeFarmlyCuppingCard'
import './CreateCoffeeFarmlyCupping.css'
import Dropzone from 'react-dropzone'
import { storage } from '../../../firebase'
import crypto from 'crypto'

import ReactGA from 'react-ga'

const CreateCoffeeFarmlyCupping = (props) => {
    const [createCoffee, setCreateCoffee] = useState(false)
    const [reload, setReload] = useState(false)
    const [selectColor, setSelectColor] = useState(0)
    const [coffeeData, setCoffeeData] = useState({})
    const [selectedCoffeesBySection, setSelectedCoffeesBySection] = useState([])
    const [cuppingCoffees, setCuppingCoffees] = useState([])
    const [nextId, setNextId] = useState([])
    const [category, setCategory] = useState(1)
    const [currentShipment, setCurrentShipment] = useState(9)
    const [shipments, setShipments] = useState([])
    const [currentTaster, setCurrentTaster] = useState(0)
    const userContext = useContext(UserContext)
    let addingCoffee = false;

    const userId = acceptedCookies() ?
            document.cookie.split(';').filter(item => item.includes('userId'))[0].split('userId=')[1]
            : 10000
    const taster = (userId == 545 || userId == 577) ? 300 : (userId == 558 ? 400 : (userId == 576 ? 100 : 200))
    // const taster = 400
        
    useEffect(() => {
        const existingShipments = backend.get(`${server}/shipping`)
        const existingCoffeesBySection = backend.get(`${server}/coffeesBySection/${taster + category - 1}`)
        Promise.all([existingShipments, existingCoffeesBySection]).then(response => {
            let shipmentId = response[0].data.reverse()
            shipmentId = shipmentId[0].shipping_id
            setShipments(response[0].data)
            setCurrentShipment(shipmentId)
            setSelectedCoffeesBySection(response[1].data)
            setCurrentTaster(taster + category - 1)
        }).catch(error => {
            alert('Houve um erro. Não foi possível completar a consulta com sucesso.')
            console.error(error)
        })
        // backend.get(`${server}/shipping`).then(response => {
        //     let shipmentId = response.data.reverse()
        //     shipmentId = shipmentId[0].shipping_id 
        //     setShipments(response.data)
        //     setCurrentShipment(shipmentId)
        // }).catch(err => {
        //     alert('Acontenceu um erro. Não foi possível buscar os dados dos embarques')
        //     console.error(err)
        // })
    }, [])

    useEffect(() => {
        loadShipments()
    }, [shipments])

    useEffect(() => {
        
            setCurrentTaster(taster + category - 1)
            backend.get(`${server}/coffeesBySection/${taster + category - 1}`)
                .then(async coffees => {
                    setCuppingCoffees(coffees.data.filter(coffee => coffee.shipment == currentShipment))               
                })
         
        
    }, [createCoffee, category, reload, currentShipment])

    const loadShipments = () => {
        return shipments.map(shipment => {
            return(<option value={shipment.shipping_id}>{shipment.shipping_id + "º"} Embarque</option>)
        })
       
    }
    const addCoffee = async () => {
        if (addingCoffee === false) {
            addingCoffee = true
        try {
            const res = await backend.post(`${server}/addCoffee`, {
                producerId: 1198, //Usuario para cafés sem dono
                isBr: isBr()
            })
            const date = new Date
            const day = ("0" + date.getDate().toString()).slice(-2)
            const month = ("0" + (date.getMonth() + 1).toString()).slice(-2)
            const year = date.getFullYear().toString()
            const dateString = [year, month, day].join('-')

            await backend.patch(`${server}/coffees`, {
                id: res.data[0].id,
                producer: coffeeData.name,
                description: coffeeData.description,
                sampleSentDate: dateString,
                section: taster,
                sensoryNotes: ""
            })
            addingCoffee = false
            setCreateCoffee(false)
            setCoffeeData({})
        } catch (err) {
            console.log(err)
            }
        }            
    }

    const renderCoffeeCards = () => 
        cuppingCoffees.map(coffee => {
            return (<CoffeeFarmlyCuppingCard coffeeData={coffee} category={category} reload={reload} setReload={setReload} taster={taster}/>)
    })

    const fileUpload = async (acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            const trapdoor = crypto.randomBytes(6).toString('HEX')
            const fileName = `${trapdoor}_${acceptedFiles[0].name}`
            await storage.ref(`producers/${fileName}`).put(acceptedFiles[0])
            await storage.ref('producers').child(`${fileName}`).getDownloadURL()
                .then((url) => { setCoffeeData({...coffeeData, img: url}) })
                .catch(e => console.log(e))
        }
    }

    const getShipments = async () => {
        try {
            let shipments = await backend.get(`${server}/shipping`);
            setShipments(shipments.data);
            
        } catch (error) {   
            alert('Algo deu errado. Não foi possível resgatar os emabarques.')
            console.error(error)
        }
    


    }
    const onDropRejected = () => {
        // setModalMessage("Imagem não pode ser maior do que 2 megabytes.")
        // toggleModal()
    }

    //REPORTS ANALYTICS
    useEffect(() => {
        ReactGA.event({
            category: 'Create Coffee Cupping',
            action: `A user visited the Create Coffee Cupping Page`
        })
    }, [])

    const clickAddCoffeeCard = () =>{
        ReactGA.event({
            category: 'Create Coffee Cupping',
            action: 'User clicked on Add Coffee card to open the modal'
        })
    }
    const clickAddCoffeeFinal = () =>{
        ReactGA.event({
            category: 'Create Coffee Cupping',
            action: 'User clicked on Add Coffee button and finished the register'
        })
    }
    const clickAddImgCoffee = () =>{
        ReactGA.event({
            category: 'Create Coffee Cupping',
            action: 'User clicked on dropzone to add an image coffee'
        })
    }

    return(
        <>
            {createCoffee &&
                <div className="black-background-modal-cupping">
                    <div className="create-coffee-modal-container-cupping">
                        <div className="coffee-img-and-color-modal-cupping">
                            <div style={{flexDirection: 'column'}}>
                                    {coffeeData.img ? 
                                    <img className="coffee-img-cupping-card-preview" src={coffeeData.img}></img>
                                    :
                                    <Dropzone onDrop={fileUpload} onDropRejected={onDropRejected} maxSize={2097152}
                                    accept="image/*" multiple onClick={clickAddImgCoffee()}>
                                    {({ getRootProps, getInputProps }) => (
                                        <section>
                                            <div className="add-picture-modal-cupping"{...getRootProps()}>
                                                <input {...getInputProps()} accept="image/*" />
                                                <p>{isPt() ? '+ Clique para adicionar uma imagem para o café' : '+ Click to add a picture of the coffee'}</p>
                                            </div>
                                        </section>
                                    )}
                                </Dropzone>}
                                <div className="select-color-modal-cupping" style={selectColor == 1 ? {backgroundColor: '#b73952'} : selectColor == 2 ? {backgroundColor: '#f47a54'} : selectColor==3 ? {backgroundColor: '#bba726'} : selectColor==4 ? {backgroundColor: 'rgb(27, 98, 94)'} : selectColor == 5 ? {backgroundColor:'#6dd7f3'} : {backgroundColor:'rgb(27, 98, 94)'}}>
                                    <div className="coffee-name-modal-cupping">{coffeeData.name ? coffeeData.name : (isPt() ? "Nome do Café" : "Coffee Name")}</div>
                                    <div className="description-modal-cupping">
                                    {coffeeData.description ? coffeeData.description : (isPt() ? "Descrição do café para ajudar a identificar o tipo" : "Description of this cafe to help understand what type it is.")}
                                    </div>
                                </div>
                                <div className="coffee-id-modal-cupping">
                                    ID: {parseInt(nextId, 10) + 1}
                                </div>
                            </div>
                            <div className="colors-container-modal-cupping">
                                    <div className={selectColor==1 ? "color-red color-selected" : "color-red"} onClick={()=> setSelectColor(1)}></div>
                                    <div className={selectColor==2 ? "color-orange color-selected" : "color-orange"}  onClick={()=> setSelectColor(2)}></div>
                                    <div className={selectColor==3 ? "color-lime color-selected" : "color-lime"}  onClick={()=> setSelectColor(3)}></div>
                                    <div className={selectColor==4 ? "color-dark-green color-selected" : "color-dark-green"} onClick={()=> setSelectColor(4)}></div>
                                    <div className={selectColor==5 ? "color-blue color-selected" : "color-blue"}  onClick={()=> setSelectColor(5)}></div>
                            </div>
                        </div>
                        <div className="coffee-input-info-modal-cupping">
                            <input className="coffee-info-input-modal-cupping" value={coffeeData.name} setValue={value => setCoffeeData({...coffeeData, name: value})} onChange={(event) =>  setCoffeeData({...coffeeData, name: event.target.value})} placeholder={isPt() ? "Nome do Café" : "Coffee name"} maxLength="254"></input>
                            <textarea className="coffee-info-input-modal-cupping" value={coffeeData.description} setValue={value => setCoffeeData({...coffeeData, description: value})} onChange={(event) =>  setCoffeeData({...coffeeData, description: event.target.value})} rows='3' placeholder={isPt() ? "Descrição" : "Description"} maxLength="254"></textarea>
                        </div>
                    </div>
                    <div className="add-or-cancel-create-coffee-container-modal-cupping">
                        <div className="cancel-coffee-modal-cupping coffee-modal-animate" onClick={()=>{setCreateCoffee(false); setCoffeeData({})}}>X</div>
                        <div className="create-coffee-modal-cupping coffee-modal-animate" id="cupping-add-coffee-button" onClick={()=> { addCoffee(); clickAddCoffeeFinal() } } >{isPt() ? '+ Adicionar Café' : "+ Add Coffee"}</div>
                    </div>
                </div>
            }
            <MainFarmlyCupping noBanner style={{overflowY: 'hidden'}}>
                <br/>
                <br/>
                <br/>
                <br/>
                <div className="cupping-title-container">
                    <label className="cupping-page-title">Cafés</label>
                </div>
                <div className="farmly-categories-container">
                    {window.screen.width > 800 ?
                    <div className="farmly-coffees-status">
                        <label className={category == 1 ? 'farmly-coffee-status-active' : 'farmly-coffee-status'} onClick={() => setCategory(1)}>{isPt() ? 'Pendentes' : 'Pendientes'}</label>
                        <label className={category == 2 ? 'farmly-coffee-status-active' : 'farmly-coffee-status'} onClick={() => setCategory(2)}>Recebido</label>
                        <label className={category == 3 ? 'farmly-coffee-status-active' : 'farmly-coffee-status'} onClick={() => setCategory(3)}>{isPt() ? 'Em Prova' : 'En Cata'}</label>
                        <label className={category == 4 ? 'farmly-coffee-status-active' : 'farmly-coffee-status'} onClick={() => setCategory(4)}>{isPt() ? 'Não Aprovados' : 'No aprobado'}</label>
                        <label className={category == 5 ? 'farmly-coffee-status-active' : 'farmly-coffee-status'} onClick={() => setCategory(5)}>{isPt() ? 'Histórico' : 'Historial'}</label>
                        <label className={category == 6 ? 'farmly-coffee-status-active' : 'farmly-coffee-status'} onClick={() => setCategory(6)}>{isPt() ? 'Aprovados' : 'Aprobado'}</label>
                    </div>
                    :
                    <select className='farmly-coffee-select-shipment' value={category} onChange={(e) => setCategory(e.target.value)}>
                            <option value={1}> {isPt() ? 'Pendentes' : 'Pendientes'} </option>
                            <option value={2}>Recebido</option>
                            <option value={3}>{isPt() ? 'Em Prova' : 'En Cata'}</option>
                            <option value={4}>{isPt() ? 'Reprovados' : 'No aprobado'}</option>
                            <option value={5}>{isPt() ? 'Histórico' : 'Historial'}</option>
                            <option value={6}>{isPt() ? 'Aprovados' : 'Aprobado'}</option>
                    </select>
                    }

                    <select className='farmly-coffee-select-shipment' value={currentShipment} onChange={(e) => setCurrentShipment(e.target.value)}>
                        {
                            loadShipments()
                        }
                        {/* <option value={17}>17º Embarque </option>
                        <option value={16}>16º Embarque </option>
                        <option value={15}>15º Embarque </option>
                        <option value={14}>14º Embarque </option>
                        <option value={13}>13º Embarque </option>
                        <option value={12}>12º Embarque </option>
                        <option value={11}>11º Embarque </option>
                        <option value={10}>10º Embarque</option>
                        <option value={9}>9º Embarque</option>
                        <option value={8}>8º Embarque</option>
                        <option value={7}>7º Embarque</option>
                        <option value={6}>6º Embarque</option> */}
                    </select>
                </div>
                <div className="new-coffees-container-create-coffee">
                    <div className="add-coffee-card-create coffee-cupping-hover" onClick={()=> {setCreateCoffee(true); clickAddCoffeeCard(); setCoffeeData({...coffeeData, img: null})}}>
                        {isPt() ? '+ Adicionar Café' : "+ Añadir café"}
                    </div>
                    {cuppingCoffees.length > 0 && renderCoffeeCards()}
                </div>


            </MainFarmlyCupping>
        </>
    )
}
export default CreateCoffeeFarmlyCupping
