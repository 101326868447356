import styled from "styled-components";

export const LandingContent = styled.div`
    background: #ec6738;
    display: flex;
    flex-direction: column;
    padding-bottom: 5vw;
`

export const Title = styled.h2`
    padding-top: 5vw;
    padding-left: 2vw;
    height: 10vw;
    color: white;
    font-size: 3.6vw;
    font-weight: 500;  

    @media(max-width: 500px){
        padding-top: 24vw;
        font-size: 7vw;
        font-weight: 400;
    }
`

export const Container = styled.div`
    width: 100%;
  
`

export const Paragraph = styled.div`
    color: white;
    padding-left: 5vw;
    padding-bottom: 3vw;
    font-size: x-large;
    text-align: justify;
    width: 80%;

    @media(max-width: 500px){
        font-size: 4vw;
        width: 88%;
    }
`

export const LucasImage = styled.img`
    height: 50vw;
    margin-left: 5vw;
    margin-bottom: 3vw;
`

export const SecondParagraph = styled.div`
    color: white;
    padding-left: 5vw;
    padding-bottom: 3vw;
    font-size: x-large;
    text-align: justify;
    width: 75%;

    @media(max-width: 500px){
        font-size: 4vw;
        width: 84%;
    }
`

export const Span = styled.span`
    font-weight: bold;
    
`

export const CuponImage = styled.img`
    height: 50vw;
    margin-left: 25vw;
    margin-bottom: 3vw;

    @media(max-width: 500px){
        margin-left: 15vw;
        height: 70vw;
    }
`

export const ImagesBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3vw;
`
export const ImagesSubBox = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-evenly;
`

export const Image = styled.img`
    height: 9vw;
    margin-bottom: 5vw;
    padding: 0 10vw 0 10vw;

    @media(max-width: 500px){
        height: 15vw;
    }
    
`

export const BigImage = styled.img`
    height: 5vw;
    margin-bottom: 3vw;

    @media(max-width: 500px){
        height: 8vw;
    }
    
`

export const WppLink = styled.a`
    width: 24vw;
    height: fit-content;
    background: #FFFFFF;
    box-shadow: 0 1vw 3vw rgba(0, 0, 0, 0.5);
    border-radius: 3vw;
    display: flex;
    align-items: center;
    justify-content: center;

    @media(max-width: 1000px){
        width: 32vw;
    }

    @media(max-width: 500px){
        width: 56vw;
    }

    @media(max-width: 400px){
        width: 72vw;
    }
    
` 

export const WppContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-evenly;
`

export const FormContainer = styled.div`
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    width: 100%;
    align-items: center;
    height: 36vw;
    @media(max-width: 1100px){
        height: 42vw;
    }
    @media(max-width: 800px){
        height: 80vw;
    }
    @media(max-width: 400px){
        height: 90vw;
    }
`

export const FormTitle = styled.div`
    color: white;
    margin: 4vw 0 1vw 0;
    font-size: xx-large;
    font-weight: 500;
    align-self: center;
    @media(max-width: 800px){
        font-size: x-large;
    }
    @media(max-width: 400px){
        font-size: large;
    }
`

export const FormBox = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5vw;
`

export const FormLabel = styled.label`
    color: white;
    font-size: larger;
    font-weight: 400;
    @media(max-width: 800px){
        font-size: medium;
        font-weight: 300;
    }
    @media(max-width: 400px){
        font-size: small;
    }
    
`

export const FormInput = styled.input`
    width: 15vw;
    height: 2vw;
    border-radius: 0.5vw;
    border: none;
    box-shadow: 0 1vw 1vw rgba(0, 0, 0, 0.5);
    text-indent: 0.5vw;
    @media(max-width: 800px){
        width: 24vw;
        height: 4vw;
    }
    @media(max-width: 800px){
        font-size: x-small;
    }
`

export const FormButton = styled.button`
    margin-top: 1vw;
    background: rgb(27, 98, 94);
    color: white;
    width: 12vw;
    height: 2.5vw;
    font-size: larger;
    border-radius: 2vw;
    border: none;
    box-shadow: 0 1vw 1vw rgba(0, 0, 0, 0.5);
    cursor: pointer;

    :hover{
        background: white;
        color: rgb(27, 98, 94);
    }

    @media(max-width: 800px){
        width: 20vw;
        height: 5vw;
        font-size: medium;
    }
    @media(max-width: 400px){
        font-size: small;
    }

`