import backend from 'axios'
import { server } from 'common'
export const createNewShipping = async (departure_date: string, origin: string, export_link: string) => {
    const departure_dates = departure_date.split('/')
    const day = parseInt(departure_dates[0])
    const month = parseInt(departure_dates[1])
    const year = parseInt(departure_dates[2])
    const monthLastDay = new Date(year,month,0).getDate()
    if(day > monthLastDay){
        alert('O mês fornecido só tem ' + monthLastDay + ' dias')
        return
    } else if(month > 12){
        alert('Mês inválido')
        return
    } else if(year.toString().length > 4) {
        alert('Ano inválido')
        return
    }
    departure_date = `${departure_dates[2]}-${departure_dates[1]}-${departure_dates[0]}`
    try{
        const newShippingResponse = backend.post(`${server}/createNewShipping`, {departure_date, origin, export_link})
    if((await newShippingResponse).status === 201){
        alert('Seu embarque foi criado com sucesso!')
    }
    } 
    catch(error){
        alert("Houve um erro na criação do embarque")
        console.log(error);
    }
    
}



