import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt, faEnvelope, faPhoneAlt  } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import './Footer.css'
import { isPt, farmlyTexts, isCo } from '../../common'
const footerArt = require('../../assets/footer-art.png')
const logo = require('../../assets/logo-white-text-green.png')
const redWave = require ('../../assets/landing-page-signup-red-wave.svg')
const youtube = require ('../../assets/youtube-icon.svg')
const instagram = require ('../../assets/instagram-icon.svg')
const whatsapp = require ('../../assets/whatsapp-icon.svg')

const mapsUrl = 'https://www.google.com/maps/place/Condom%C3%ADnio+do+Edif%C3%ADcio+Metropolitan+Office+-+R.+Dr.+Eurico+de+Aguiar,+888+-+Santa+Lucia,+Vit%C3%B3ria+-+ES,+29056-264/@-20.3045244,-40.3025723,17z/data=!3m1!4b1!4m5!3m4!1s0xb817c483cf84b5:0x2e9d20a482a8eb90!8m2!3d-20.3045244!4d-40.3003836'

const Footer = props => {

    return (
        <div className="simple-footer-container">
            <img src={redWave} className='red-wave-simple-footer' alt="separator"></img>
            <div className="red-footer-container">
                <a target="_blank" rel="noopener noreferrer" className="icon-simple-footer" href={'https://www.youtube.com/channel/UCCeXFqzhnAvjXbSMxkdRCMw'}>
                    <img className="icon-simple-footer" src={youtube} alt="youtube icon"></img>
                </a>
                <a target="_blank" rel="noopener noreferrer" className="instagram-icon-simple-footer icon-simple-footer" href={'https://www.instagram.com/farm_ly/'}>
                    <img className="icon-simple-footer" src={instagram} alt="instagram icon"></img>
                </a>
                <a target="_blank" rel="noopener noreferrer" className="icon-simple-footer" href={isCo() ? `https://api.whatsapp.com/message/OV4X674MP6R2P1?autoload=1&app_absent=0` : (isPt() ? `https://api.whatsapp.com/send?phone=+5535999432879` : `https://api.whatsapp.com/send?phone=+5537991036677` ) }>
                    <img className="icon-simple-footer" src={whatsapp} alt="whatsapp icon"></img>

                </a>
            </div>
        </div>
    )
}

export default Footer