import React, { FunctionComponent } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from "react-router-dom";
import {
    faExternalLinkSquareAlt
} from '@fortawesome/free-solid-svg-icons'

// import { serverUrl } from "../../../../../lib/backend"

import { LinkContainer, LinkContainerExpireDate, LinkContainerSpan, LinkContainerTypography } from './style';
import CartModel from 'modules/SampleCart/models/cartModel';

declare global {
    interface Date {
        formatDDMMYYYY(): string | any;
    }
}

// console.log(serverUrl)

interface LinkToCartProps {
    sampleCart: CartModel,
}

const LinkToCart: FunctionComponent<LinkToCartProps> = (props) => {

    Date.prototype.formatDDMMYYYY = function () {
        return this.getDate() + "/" +
            (this.getMonth() + 1) +
            "/" + this.getFullYear()
    }

    const correctUrl = () => {
        return (window.location.href.includes("localhost") ?
            "http://localhost:3000/samplecart/" :
            window.location.href.includes("development") ?
                "https://development.farmlyclub.com/samplecart/"
                : "samplecart/")
    }

    return (
        <>
            <LinkContainer>
                <LinkContainerTypography>
                    Link:
                    <LinkContainerSpan>
                        {props.sampleCart.sample_list.length > 0 ? props.sampleCart.list_link : ""}
                    </LinkContainerSpan>
                    <Link
                        to={{
                            pathname: correctUrl() + props.sampleCart.list_link,
                        }}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        <FontAwesomeIcon className="copy-icon" icon={faExternalLinkSquareAlt} />
                    </Link>
                </LinkContainerTypography>
                <LinkContainerExpireDate>
                    Expira em:
                    {props.sampleCart.sample_list.length > 0 && props.sampleCart.expires_at !== null
                        ? new Date(props.sampleCart.expires_at).toLocaleDateString()
                        : ""}
                </LinkContainerExpireDate>
            </LinkContainer>
        </>
    );
}
export default LinkToCart;