import React from "react";
import { Container, Title, TableSelectContainer, TableSelect, TableSelected } from "./styles"
import TablesT from '../../models/Tables'

interface SelectTableProps {  
    selectBoard ?: string, 
    setSelectBoard : React.Dispatch<React.SetStateAction<string>>
  }

export default function SelectTable(props: SelectTableProps) {

    const { selectBoard, setSelectBoard } = props;
     
    return (
        <Container>
            <Title>Selecione seu painel</Title>
            <TableSelectContainer>
                {selectBoard === 'main' ? <TableSelected>Principal</TableSelected> : <TableSelect onClick={() => setSelectBoard('main')}>Principal</TableSelect>}
                {selectBoard === 'approval' ? <TableSelected>Aprovar Cafés</TableSelected> : <TableSelect onClick={() => setSelectBoard('approval')}>Aprovar Cafés</TableSelect>}
                {selectBoard === 'label' ? <TableSelected>Etiquetas</TableSelected> : <TableSelect onClick={() => setSelectBoard('label')}>Etiquetas</TableSelect>}
            </TableSelectContainer>
        </Container>
        );
    }
