import React from "react";
import styled from "styled-components";
import imageCoffee from "../../assets/Icons/coffee-cup.svg"
import "./Rating.css"

const Container = styled.div`
width: 30%;
@media (max-width: 500px) {
	width: 50%;
}
`
const DivStile = styled.div`
max-width: 18vw;
margin-top: 10px;
`
const NameRoasterReview = styled.h1`
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 24px;
color: #1B625E;
@media (max-width: 500px) {
	font-size: 16px;
}
`


const DataWithReview = styled.span`
width: 134px;
height: 18px;
`

const  Review = styled.span`
font-family: 'Poppins';
font-style: normal;
font-size: 16px;
color: #505050;
@media (max-width: 500px) {
	font-size: 14px;
}
`
const Avatar = styled.img`
width: 36px;
height: 36px;
margin: 0%;

`
const RatingCard = (props) => {
	const { score, data, roaster, comment, coffeeId } = props;
console.log(roaster)
	return (

		<Container>
			
				<DivStile>
					<NameRoasterReview>
						{roaster? roaster : "Anônimo"} 
					</NameRoasterReview>
					</DivStile>
					<DivStile>
					<DataWithReview>
						{data}
					</DataWithReview>
					</DivStile>
					<DivStile>
					<Review/>
					{comment}
					</DivStile>
		
		</Container>
		
	)

};

export default RatingCard;
