import React, { useContext, Fragment } from 'react'
import { useEffect } from 'react'
import './NavCupping.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingBasket, faBell } from '@fortawesome/free-solid-svg-icons'

import { Link } from 'react-router-dom'
import { isPt, isBr, isCo, farmlyTexts, acceptedCookies, server } from '../../common'
import UserContext from '../../context/user/UserContext.js'
import CartContext from '../../context/cart/CartContext.js'
import { useLocation, useHistory } from 'react-router-dom'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import FarmlyModal from '../templates/FarmlyModal.jsx'
import { useState } from 'react'
import axios from 'axios'
import ReactGA from 'react-ga'

// import { isClipEffect } from 'html2canvas/dist/types/render/effects'
const headerImage = require('../../assets/header-banner.png')
const homeHeaderImage = require('../../assets/basetopcut.png')
const logoImage = require('../../assets/logo-cupping.png')
const logoAlt = require('../../assets/title-home-small.png')
const decorSupRight = require('../../assets/desktop-sup-dir.png')

const NavCupping = props => {

    const userContext = useContext(UserContext)
    const cartContext = useContext(CartContext)
    const location = useLocation()
    const history = useHistory()

    const [timeAlert, setTimeAlert] = useState(false)
    const [endTime, setEndTime] = useState(false)
    const [startTimer, setStartTimer] = useState(false)

    const [notificationList, setNotificationList] = useState()

    useEffect(() => {
        {
            userContext.user &&
                axios.get(`${server}/getAllNotifications/${userContext.user.id}/${userContext.user.isProd}`)
                    .then(async notifications => {
                        await setNotificationList(notifications.data.filter((novas) => novas.read == false))
                    })
                    .catch(err => console.log(err))
        }
    }, [])


    const plusTime = () => {
        cartContext.setControlTimer({ control: true, zero: false })
    }

    const logout = () => {

        if (acceptedCookies()) {
            document.cookie = 'jwt=0; max-age=0'
            document.cookie = 'isProd=0; max-age=0'
            document.cookie = 'userId=0; max-age=0'
            document.cookie = 'cartList=0; max-age=0'
        }
        { (!userContext.user || (userContext.user && !userContext.user.isProd)) && cartContext.clearCart() }
        userContext.userLogout()
        window.location.href = '/'
    }

    const renderLogo = () => (
        <Link to="/">
            <div id="logo-container-cupping">
                {window.width > 500 ?
                    <img id='logo-cupping' src={logoImage} alt="logo" />
                :
                    <img id='logo-cupping' src={logoAlt} alt="logo" />
                }
            </div>
        </Link>
    )

    const burgerClick = () => {
        const burger = document.querySelector("#burger-cupping")
        const nav = document.querySelector(".nav-cupping")
        const navItems = document.querySelectorAll('.nav-item')

        nav.classList.toggle('nav-active-cupping')
        burger.classList.toggle('burger-open')

        navItems.forEach((item, index) => {
            item.style.animation = item.style.animation ? '' : `navLinkFade 0.5s ease forwards ${index / 7 + 0.2}s`
        })
    }

    const clickCoffeeNav = () =>{
        ReactGA.event({
            category: 'Nav Cupping',
            action: 'User clicked on Coffee nav item'
        })
    }
    const clickCuppingNav = () =>{
        ReactGA.event({
            category: 'Nav Cupping',
            action: 'User clicked on Cupping nav item'
        })
    }
    const clickProfileNav = () =>{
        ReactGA.event({
            category: 'Nav Cupping',
            action: 'User clicked on Profile nav item'
        })
    }

    const renderNavBar = () => (
        <div className="nav-area-cupping">
            <nav className="nav-cupping">
                {/* <Link className='nav-item' to='/'>
                    Comparações
                </Link> */}
                <Link className='nav-item' to='/createCuppingCoffee' onClick={clickCoffeeNav()}>
                    {(isPt() || isCo()) ? 'Cafés' : 'Coffees'}
                </Link>
                <Link className='nav-item' to='/cuppingSelect' onClick={clickCuppingNav()}>
                    Cupping
                </Link>
                {/* <Link className='nav-item' to='/profile' onClick={clickProfileNav()}>
                    {isPt() ? 'Perfil' : 'Profile'}
                </Link> */}
            </nav>
            <img id='logo-cupping' src={decorSupRight} alt="logo" className="nav-cupping-decor-sup-right"/>

            <div id="burger-cupping" onClick={burgerClick}>
                <div id="line1"></div>
                <div id="line2"></div>
                <div id="line3"></div>
            </div>
        </div >
    )

    const renderHeaderBanner = () => (
        <div id="header-container">
            <img id="header" src={headerImage} alt="" />
        </div>
    )

    const renderHomeBanner = () => (
        <div id="home-header-container">
            <img id="home-header" src={homeHeaderImage} alt="" />
        </div>
    )

    return (
        <>
            {timeAlert &&
                <FarmlyModal closeModal={() => setTimeAlert(false)}>
                    <label id="request-title">{isBr() ? 'Finalize sua compra!' : 'Confirm purchase'}</label>
                    {isBr() ?
                        <Fragment>
                            <p>As sacas de cafés que estão no seu carrinho ficarão reservadas para você por mais 3 minutos!</p>
                            <p>Finalize sua compra para garantir seus cafés ou continue comprando para garantir a reserva de sacas de café no seu carrinho
                            por mais tempo!</p>
                        </Fragment>
                        :
                        <Fragment>
                            <p>The products in your cart will be reserved for only 3 more minutes!</p>
                            <p>Confirm your purchase to guarantee your coffees or press keep buying to restart the reservation timer!</p>
                        </Fragment>
                    }
                    <div className='time-modal'>
                        <Link to="/coffees">
                            <div className="edit-coffee-button" onClick={plusTime} >{isBr() ? 'Continuar comprando' : 'Keep buying'}</div>
                        </Link>
                        <Link to="/cart">
                            <div className="edit-coffee-button" >{isBr() ? 'Finalizar compra' : 'Confirm purchase'}</div>
                        </Link>
                    </div>
                </FarmlyModal>
            }
            {endTime &&
                <FarmlyModal closeModal={() => setEndTime(false)}>
                    <label id="request-title">{isBr() ? 'O tempo de reserva dos cafés do seu carrinho se esgotou!' : 'The reservation time of the products in your cart has ended!'}</label>
                    {isBr() ?
                        <Fragment>
                            <p>Seu carrinho foi esvaziado, porque o tempo de reserva acabou!</p>
                            <p>Veja nossos produtos e comece novamente sua compra!</p>
                            <Link to="/coffees">
                                <div className="edit-coffee-button">Cafés</div>
                            </Link>
                        </Fragment>
                        :
                        <Fragment>
                            <p>Your cart has been cleared, because the reservation time has ended!</p>
                            <p>Check out our coffees and start you purchase again!</p>
                            <div style={{ alignItems: 'center', marginLeft: '157px' }} onClick={() => setEndTime(false)}>
                                <Link to="/coffees">
                                    <div className="cart-timer-button">Coffees</div>
                                </Link>
                            </div>
                        </Fragment>
                    }
                </FarmlyModal>
            }
            <div id="nav-container" style={props.noBanner ? { position: 'fixed', width: '100%', zIndex: '2' } : {}}>

                {renderLogo()}
                {renderNavBar()}

                {!props.noBanner && (props.isHome ? renderHomeBanner() : renderHeaderBanner())}
            </div>
        </>
    )
}

export default NavCupping