import React, { useState, useEffect, useContext, Fragment } from 'react'
import axios from 'axios'
import { server, isPt } from '../../common'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faTimes } from '@fortawesome/free-solid-svg-icons'
import { useHistory, Link } from 'react-router-dom'
import UserContext from '../../context/user/UserContext.js'
import Paper from '@material-ui/core/Paper'
import { makeStyles, withStyles, Theme, createStyles } from '@material-ui/core/styles'
import FarmlyInput from '../templates/FarmlyInput'
import './CuppingIndv.css'
import Grid from '@material-ui/core/Grid'
import Volume from '@material-ui/core/Slider'
import Slider from '@material-ui/core/Slider'
// import Slider from "react-slick"
import Divider from '@material-ui/core/Divider'
import RadarChart from '../templates/RadarChartCuppingDesktop'
import RadarChartResultsCupping from '../templates/RadarChartResultsCupping'
import FarmlyModal from '../templates/FarmlyModal'
import ReactGA from 'react-ga'


const CuppingIndvDesktop = props => {

    const { coffee, sensoryList, cuppingDone, checkCuppings, setCheckCuppings, updateAll, setFillAllSpaces, goToResults, notesModal, auxSensoryList, cupping_id } = props
    const userContext = useContext(UserContext)
    const [showResults, setShowResults] = useState(true)
    const [init, setInit] = useState(true)
    const [coffeeData, setCoffeeData] = useState()
    const [cuppingData, setCuppingData] = useState({})
    const [score, setScore] = useState(0)
    const [roast1, setRoast1] = useState(false)
    const [roast2, setRoast2] = useState(false)
    const [roast3, setRoast3] = useState(false)
    const [roast4, setRoast4] = useState(false)
    const [roast5, setRoast5] = useState(false)

    const [defect0, setDefect0] = useState(false)
    const [defect1, setDefect1] = useState(false)
    const [defect2, setDefect2] = useState(false)
    const [defect3, setDefect3] = useState(false)
    const [defect4, setDefect4] = useState(false)
    const [defect5, setDefect5] = useState(false)

    const [bigDefect0, setBigDefect0] = useState(false)
    const [bigDefect1, setBigDefect1] = useState(false)
    const [bigDefect2, setBigDefect2] = useState(false)
    const [bigDefect3, setBigDefect3] = useState(false)
    const [bigDefect4, setBigDefect4] = useState(false)
    const [bigDefect5, setBigDefect5] = useState(false)

    const [sweet1, setSweet1] = useState(false)
    const [sweet2, setSweet2] = useState(false)
    const [sweet3, setSweet3] = useState(false)
    const [sweet4, setSweet4] = useState(false)
    const [sweet5, setSweet5] = useState(false)
    const [sweet, setSweet] = useState(0)

    const [clean1, setClean1] = useState(false)
    const [clean2, setClean2] = useState(false)
    const [clean3, setClean3] = useState(false)
    const [clean4, setClean4] = useState(false)
    const [clean5, setClean5] = useState(false)
    const [cleanCup, setCleanCup] = useState(0)

    const [equal1, setEqual1] = useState(false)
    const [equal2, setEqual2] = useState(false)
    const [equal3, setEqual3] = useState(false)
    const [equal4, setEqual4] = useState(false)
    const [equal5, setEqual5] = useState(false)
    const [equalty, setEqualty] = useState(0)

    const [valueAroma, setValueAroma] = useState()
    const [valueAroma2, setValueAroma2] = useState(0)
    const [valueAroma3, setValueAroma3] = useState(0)
    const [valueFlavor, setValueFlavor] = useState()
    const [valueCompletion, setValueCompletion] = useState()
    const [valueAcidity, setValueAcidity] = useState()
    const [valueAcidity2, setValueAcidity2] = useState(0)
    const [valueBody, setValueBody] = useState()
    const [valueBody2, setValueBody2] = useState(0)
    const [valueBalance, setValueBalance] = useState()
    const [valueOverall, setValueOverall] = useState()

    const [roastWarning, setRoastWarning] = useState(false)
    const [aromaWarning, setAromaWarning] = useState(false)
    const [flavorWarning, setFlavorWarning] = useState(false)
    const [completionWarning, setCompletionWarning] = useState(false)
    const [acidityWarning, setAcidityWarning] = useState(false)
    const [bodyWarning, setBodyWarning] = useState(false)
    const [balanceWarning, setBalanceWarning] = useState(false)
    const [overallWarning, setOverallWarning] = useState(false)
    const [scoreVar, setScoreVar] = useState(false)
    const [updateNotes, setUpdateNotes] = useState(false)

    const [defectLight, setDefectLight] = useState()
    const [defectHeavy, setHeavyDefect] = useState()
    

    const history = useHistory()

    useEffect(() => {
        if (init) {
            initializeData()
        }
        setShowResults(false)
    }, [])

    useEffect(() => {
        if (goToResults.status) {
            if(goToResults.coffeeId == coffee.coffee_id) {
                handleResult(false)
                setFillAllSpaces(false)
            }
        }
    }, [goToResults])

    useEffect(() => {
        if (cuppingDone) {
            axios.patch(`${server}/updateCupping`, {
                ...cuppingData,
                clean_cup: cleanCup,
                sweetness: sweet,
                uniformity: equalty,
                score: score,
                cupping_done: true
            })
        }
    }, [showResults, cuppingDone])

    useEffect(() => {
        if (scoreVar) {
            setScore(parseInt(cuppingData.aroma) + parseInt(cuppingData.flavor) + parseInt(cuppingData.completion) + parseInt(cuppingData.acidity) + parseInt(cuppingData.body) + parseInt(cuppingData.balance) + cleanCup + sweet + equalty - parseInt(cuppingData.defects) - parseInt(cuppingData.big_defect) + parseInt(cuppingData.overall)+30)
            console.log(cuppingData.aroma, cuppingData.completion, cuppingData.acidity)
            alert('oi')
        } else {
            setScore(0)
        }
    }, [cuppingData, cleanCup, equalty, sweet, init, scoreVar])

    useEffect(() => {
        axios.post(`${server}/getCuppingCoffee`, { user_id: coffee.user_id, coffee_id: coffee.coffee_id})
            .then(res => {
                if (res.data.sensoryNotes !== null && res.data.sensoryNotes !== "") setCoffeeData(res.data)
                else setCoffeeData({ ...res.data, sensoryNotes: "Sem notas" })
            })
    }, [showResults, auxSensoryList, updateNotes])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }, [showResults])

    const initializeData = () => {
        axios.get(`${server}/getCupping/${coffee.coffee_id}/${cupping_id}`)
            .then(res => {
                if (res.data.length == 0) {
                    setCuppingData({
                        coffee_id: coffee.coffee_id,
                        user_id: coffee.user_id,
                        aroma: '0',
                        flavor: '0',
                        completion: '0',
                        acidity: '0',
                        body: '0',
                        balance: '0',
                        defects: '0',
                        big_defect: '0',
                        overall: '0',
                        score: 0,
                        isFarmly: false,
                        cupping_id: cupping_id
                    })
                    setScore(0)
                }
                else {
                    setCuppingData({ ...res.data, cupping_done: false })
                    setScore(parseInt(res.data.aroma) + parseInt(res.data.flavor) + parseInt(res.data.completion) + parseInt(res.data.acidity) + parseInt(res.data.body) + parseInt(res.data.balance) + cleanCup + sweet + equalty + 10 - parseInt(res.data.defects) - parseInt(res.data.big_defect) + parseInt(res.data.overall) + 30)
                    console.log(res.data.aroma, res.data.completion, res.data.acidity)
                    setScoreVar(true)
                }
                setInit(false)
            })
    }

    const handleResult = async (force) => {
        if ((!roastWarning || !aromaWarning || !flavorWarning || !acidityWarning || !bodyWarning || !balanceWarning || !overallWarning || !completionWarning) && force ) {
            setFillAllSpaces(true)
        } else {
            axios.patch(`${server}/updateCupping`, {
                ...cuppingData,
                clean_cup: cleanCup,
                sweetness: sweet,
                uniformity: equalty,
                score: score,
                cupping_done: true
            }) .then(_ => {  
                    setCheckCuppings(!checkCuppings)
                    setShowResults(true)
            })
        }
    }

    const handleReturn = async () => {
        await axios.patch(`${server}/updateCuppingStatus`, {
            coffee_id: coffee.coffee_id,
            cupping_id: cupping_id,
            status: false
        })
        setCheckCuppings(!checkCuppings)
        setShowResults(false)
        setScoreVar(true)
    }

    const setRoastPoint = (which) => {
        switch (which) {
            case 1:
                setCuppingData({ ...cuppingData, roasting_point: 1 })
                setRoast1(!roast1)
                setRoast2(false)
                setRoast3(false)
                setRoast4(false)
                setRoast5(false)
                break;
            case 2:
                setCuppingData({ ...cuppingData, roasting_point: 2 })
                setRoast2(!roast2)
                setRoast1(false)
                setRoast3(false)
                setRoast4(false)
                setRoast5(false)
                break;
            case 3:
                setCuppingData({ ...cuppingData, roasting_point: 3 })
                setRoast3(!roast3)
                setRoast2(false)
                setRoast1(false)
                setRoast4(false)
                setRoast5(false)
                break;
            case 4:
                setCuppingData({ ...cuppingData, roasting_point: 4 })
                setRoast4(!roast4)
                setRoast2(false)
                setRoast3(false)
                setRoast1(false)
                setRoast5(false)
                break;
            case 5:
                setCuppingData({ ...cuppingData, roasting_point: 5 })
                setRoast5(!roast5)
                setRoast2(false)
                setRoast3(false)
                setRoast4(false)
                setRoast1(false)
                break;

            default:
                break;
        }
    }

    const setDefect = (which) => {
        setDefectLight(which)
        switch (which*2) {
            case 0:
                setCuppingData({ ...cuppingData, defects: 0 })
                setDefect0(!defect0)
                setDefect2(false)
                setDefect1(false)
                setDefect3(false)
                setDefect4(false)
                setDefect5(false)
                break;
            case 2:
                setCuppingData({ ...cuppingData, defects: 2 })
                setDefect1(!defect1)
                setDefect2(false)
                setDefect0(false)
                setDefect3(false)
                setDefect4(false)
                setDefect5(false)
                break;
            case 4:
                setCuppingData({ ...cuppingData, defects: 4 })
                setDefect2(!defect2)
                setDefect0(false)
                setDefect1(false)
                setDefect3(false)
                setDefect4(false)
                setDefect5(false)
                break;
            case 6:
                setCuppingData({ ...cuppingData, defects: 6 })
                setDefect3(!defect3)
                setDefect0(false)
                setDefect2(false)
                setDefect1(false)
                setDefect4(false)
                setDefect5(false)
                break;
            case 8:
                setCuppingData({ ...cuppingData, defects: 8 })
                setDefect4(!defect4)
                setDefect0(false)
                setDefect2(false)
                setDefect3(false)
                setDefect1(false)
                setDefect5(false)
                break;
            case 10:
                setCuppingData({ ...cuppingData, defects: 10 })
                setDefect5(!defect5)
                setDefect0(false)
                setDefect2(false)
                setDefect3(false)
                setDefect4(false)
                setDefect1(false)
                break;

            default:
                break;
        }
    }


    const setBigDefect = (which) => {
        setHeavyDefect(which)
        switch (which*4) {
            case 0:
                setCuppingData({ ...cuppingData, big_defect: 0 })
                setBigDefect0(!bigDefect0)
                setBigDefect2(false)
                setBigDefect1(false)
                setBigDefect3(false)
                setBigDefect4(false)
                setBigDefect5(false)
                break;
            case 4:
                setCuppingData({ ...cuppingData, big_defect: 4 })
                setBigDefect1(!bigDefect1)
                setBigDefect2(false)
                setBigDefect0(false)
                setBigDefect3(false)
                setBigDefect4(false)
                setBigDefect5(false)
                break;
            case 8:
                setCuppingData({ ...cuppingData, big_defect: 8 })
                setBigDefect2(!bigDefect2)
                setBigDefect0(false)
                setBigDefect1(false)
                setBigDefect3(false)
                setBigDefect4(false)
                setBigDefect5(false)
                break;
            case 12:
                setCuppingData({ ...cuppingData, big_defect: 12 })
                setBigDefect3(!bigDefect3)
                setBigDefect2(false)
                setBigDefect1(false)
                setBigDefect0(false)
                setBigDefect4(false)
                setBigDefect5(false)
                break;
            case 16:
                setCuppingData({ ...cuppingData, big_defect: 16 })
                setBigDefect4(!bigDefect4)
                setBigDefect2(false)
                setBigDefect1(false)
                setBigDefect3(false)
                setBigDefect0(false)
                setBigDefect5(false)
                break;
            case 20:
                setCuppingData({ ...cuppingData, big_defect: 20 })
                setBigDefect5(!bigDefect5)
                setBigDefect2(false)
                setBigDefect1(false)
                setBigDefect3(false)
                setBigDefect4(false)
                setBigDefect0(false)
                break;

            default:
                break;
        }
    }

    const handleChangeAroma = (newValue) => {
        console.log(cuppingData)
        setValueAroma(newValue)
        setCuppingData({ ...cuppingData, aroma: newValue })
        setScoreVar(true)
        setAromaWarning(true)
    }

    const handleChangeAroma2 = (event, newValue) => {
        setScoreVar(true)
        setValueAroma2(newValue)
    }

    const handleChangeAroma3 = (event, newValue) => {
        setScoreVar(true)
        setValueAroma3(newValue)
    }


    const handleChangeFlavor = ( newValue) => {
        setValueFlavor(newValue)
        if((newValue>=6 && newValue<=10) || (newValue==0)  ){
            setScoreVar(true)
            setCuppingData({ ...cuppingData, flavor: newValue })
            setFlavorWarning(true)
        }
    }

    const handleChangeCompletion = (newValue) => {
        setValueCompletion(newValue)
        if((newValue>=6 && newValue<=10) || (newValue==0)  ){
            setCuppingData({ ...cuppingData, completion: newValue })
            setScoreVar(true)
            setCompletionWarning(true)
        }
    }

    const handleChangeAcidity = (newValue) => {        
        setValueAcidity(newValue)
        if((newValue>=6 && newValue<=10) || (newValue==0)  ){
            setCuppingData({ ...cuppingData, acidity: newValue })
            setScoreVar(true)
            setAcidityWarning(true)
        }
    }

    const handleChangeAcidity2 = (event, newValue) => {
        setScoreVar(true)
        setValueAcidity2(newValue)
    }

    const handleChangeBody = (newValue) => {
        setValueBody(newValue)
        if((newValue>=6 && newValue<=10) || (newValue==0)  ){
            setCuppingData({ ...cuppingData, body: newValue })
            setScoreVar(true)
            setBodyWarning(true)
        }
    }

    const handleChangeBody2 = (event, newValue) => {
        setScoreVar(true)
        setValueBody2(newValue)
    }

    const handleChangeBalance = (newValue) => {
        setValueBalance(newValue)
        if((newValue>=6 && newValue<=10) || (newValue==0)  ){
            setCuppingData({ ...cuppingData, balance: newValue })
            setScoreVar(true)
            setBalanceWarning(true)
        }
    }

    const handleChangeOverall = (newValue) => {
        setValueOverall(newValue)
        if((newValue>=6 && newValue<=10) || (newValue==0)  ){
            setCuppingData({ ...cuppingData, overall: newValue })
            setScoreVar(true)
            setOverallWarning(true)
        }
    }

    const removeNote = async (item) => {

        let oldList = coffeeData.sensoryNotes.split(',')
        if ((coffeeData.sensoryNotes.split(',').indexOf(item) >= 0)) {
            oldList.splice(coffeeData.sensoryNotes.split(',').indexOf(item), 1)
            console.log(oldList);
            const reqBody = {
                ...coffeeData,
                sensoryNotes: oldList.join(',')
            }
            try {
                await axios.patch(`${server}/updateCuppingCoffee`, reqBody)
                setUpdateNotes(!updateNotes)
            } catch (err) {
                console.log(err)
            }
        }
    }

    const renderSensoryCard = () =>
        coffeeData.sensoryNotes.split(',').map((note) => {
            let random = Math.floor(Math.random() * 3)
            return (
                <Fragment>
                    {note !== "Sem notas" ? <div className={(random == 0) ? 'sensory-red' : ((random == 1 ? 'sensory-lime' : 'sensory-orange'))} style={{display: 'flex', flexDirection: 'row', width: 'auto'}}>
                        {note}
                        <div onClick={()=>removeNote(note)} style={{marginLeft: '8px', marginRight: '3px'}}>x</div>
                        </div> : note}
                    &nbsp;
                    <br />
                </Fragment>
            )
        })
 
        
    const useStyles = makeStyles((theme) => ({
        mainPaper: {
            height: 'auto',
            marginBottom: '-55px',
            borderRadius: '25px',
            width: '100%',
            backgroundColor: 'rgb(27, 98, 94)',
            marginTop: '-10vw',
            paddingTop: '20vw'
        },
        mainPaperDesktop: {
            height: 'auto',
            borderRadius: '25px',
            width: '100%',
            backgroundColor: '#fffff',
        },
        volume: {
            height: 300,
        },
        scorePaper: {
            marginTop: '-45px',
            height: 'auto',
            marginBottom: '5px',
            borderRadius: '25px',
            width: '100%',
            backgroundColor: 'white',
            zIndex: '0',
            paddingBottom: '25px'
        },
        margin: {
            margin: theme.spacing(1),
        },
        rootslider: {
            width: 50 + theme.spacing(3) * 2,
            color: '#000000',
            height: 2,
            padding: '15px 0',
        },
    }))
    const classes = useStyles()

    const acidityMarks = [
        {
            value: 6,
            label: 'Baixa',
        },
        {
            value: 7,
            label: '',
        },
        {
            value: 8,
            label: 'Média',
        },
        {
            value: 9,
            label: '',
        },
        {
            value: 10,
            label: 'Alta',
        },
    ]

    const chartAttributes = [{
        Acidez: cuppingData.acidity,
        Aroma: cuppingData.aroma,
        Corpo: cuppingData.body,
        Equilibrio: cuppingData.balance,
        Finalização: cuppingData.completion,
        Sabor: cuppingData.flavor
    }]

    const thumbShadow = '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

    const CuppingSlider = withStyles({
        thumb: {
          height: 30,
          width: 8,
          borderRadius:2,
          backgroundColor: '#DC635E',
          boxShadow: thumbShadow,
          marginTop: -14,
          marginLeft: 0,
          '&:focus, &:hover, &$active': {
            boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
            '@media (hover: none)': {
              boxShadow: thumbShadow,
            },
          },
        },
        active: {},
        track: {
          height: 2,
          backgroundColor: '#000000',
        },
        rail: {
          height: 2,
          opacity: .5,
          backgroundColor: '#000000',
        },
        mark: {
          backgroundColor: '#000000',
          height: 24,
          width: 3,
          marginTop: -10,
        },
        markActive: {
          opacity: 1,
          backgroundColor: '#000000',
        },
      })(Slider);
      

    const clickResultButton = () =>{
        ReactGA.event({
            category: 'Cupping',
            action: 'User clicked on result button at cupping page'
        })
    }

    return (
        <div>
               <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div className='cupping-indv-container-overflow' style={{ height:'100%', width: '60%', overflowY: 'scroll' }} >
                        <div style={{ direction: 'ltr' }}>
                            <div style={{ margin: '2vw', borderRadius: '25px', marginBottom: '0vw' }}>
                                <div className="row roast-container-desktop">
                                    <div className="coffee-roast-desktop" style={{ padding: '15px' }}>
                                        <label htmlFor="" className="roast-title-desktop" >{isPt() ? 'Ponto da Torra:' : 'Roasting  Point'}</label>
                                        <div className={roast1 ? 'selected-roast-desktop' : ''} onClick={() => {
                                            setRoastPoint(1)
                                            setRoastWarning(true)
                                        }}>
                                            <div className='cafe-fraco-desktop'></div>
                                        </div>
                                        <div className={roast2 ? 'selected-roast-desktop' : ''} onClick={() => {
                                            setRoastPoint(2)
                                            setRoastWarning(true)
                                        }}>
                                            <div className='cafe-fraco2-desktop'></div>
                                        </div>
                                        <div className={roast3 ? 'selected-roast-desktop' : ''} onClick={() => {
                                            setRoastPoint(3)
                                            setRoastWarning(true)
                                        }}>
                                            <div className='cafe-medio-desktop'></div>
                                        </div>
                                        <div className={roast4 ? 'selected-roast-desktop' : ''} onClick={() => {
                                            setRoastPoint(4)
                                            setRoastWarning(true)
                                        }}>
                                            <div className='cafe-medio2-desktop'></div>
                                        </div>
                                        <div className={roast5 ? 'selected-roast-desktop' : ''} onClick={() => {
                                            setRoastPoint(5)
                                            setRoastWarning(true)
                                        }}>
                                            <div className='cafe-forte-desktop'></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Divider variant="middle" />

                            <div style={{margin:'2vw', borderRadius: '25px' }}>
                                <div className={classes.rootslider} className="row" style={{ padding: '15px' }}>
                                    <div className='cupping-col-contents col'>
                                        <label className="info-title-desktop" style={{marginRight:'1vw'}}>{isPt() ? 'Aroma:' : 'Aroma:'}</label>
                                        {/* <FarmlyInput styleClass="farmly-input input-cupping-categories" value={cuppingDataComments.aromaComment || ""} setValue={value => setCuppingDataComments({ ...cuppingDataComments, aromaComment: value })}></FarmlyInput>    */}
                                        <input type="number" className="farmly-input input-cupping-categories" min={6} max={10} step={1} value={valueAroma} onChange={e => handleChangeAroma(e.target.value)} placeholder=""/> 
                                         {(valueAroma>10 || valueAroma<6 )  && <label className='invalid-input-cupping' style={{ color: 'var(--farmly-fuzzy)' }}>{isPt() ? 'Nota entre 6 e 10.' : 'Grade between 6 and 10'}</label>}                                     
                                    </div>
                                    
                                    <div className='cupping-col-contents col'>
                                    <Grid container className="medium-volume-desktop">
                                        <label className='info2-title-desktop' style={{marginRight:'1vw'}}>{isPt() ? 'Seco:' : 'Dry:'}</label>
                                        <CuppingSlider style={{marginRight:'1vw'}} value={valueAroma3} onChange={handleChangeAroma3} marks={true} min={6} max={10} valueLabelDisplay="off" orientation="horizontal" aria-labelledby="continuous-slider" step={1} track="normal"/>
                                    </Grid>
                                    </div>
                                    <div className='cupping-col-contents col'>
                                        <Grid container className="medium-volume-desktop">
                                            <label className='info2-title-desktop' style={{marginRight:'1vw'}}>{isPt() ? 'Crosta:' : 'Crust:'}</label>
                                            <CuppingSlider value={valueAroma2} onChange={handleChangeAroma2} marks={true} min={6} max={10} valueLabelDisplay="off" orientation="horizontal" aria-labelledby="continuous-slider" step={1} track="normal"/>
                                        </Grid>
                                    </div>
                                </div>
                            </div>

                            <Divider variant="middle" />

                            <div style={{ margin: '2vw', borderRadius: '25px' }}>
                                <div style={{ padding: '15px' }}>
                                    <div className='cupping-col-contents col'>
                                        <label className="info-title-desktop" style={{marginRight:'1vw'}}>{isPt() ? 'Sabor:' : 'Flavor:'}</label>
                                         <input type="number" className="farmly-input input-cupping-categories" min={6} max={10} step={1} value={valueFlavor} onChange={e => handleChangeFlavor(e.target.value)} placeholder=""/> 
                                         {(valueFlavor>10 || valueFlavor<6 )  && <label className='invalid-input-cupping' style={{ color: 'var(--farmly-fuzzy)' }}>{isPt() ? 'Nota entre 6 e 10.' : 'Grade between 6 and 10'}</label>}
                                    </div>
                                    <div className='cupping-col-contents col'>
                                        <label className="info-title-desktop" style={{marginRight:'1vw', marginLeft:'3vw'}}>{isPt() ? 'Finalização:' : 'After-taste:'}</label>
                                        <input type="number" className="farmly-input input-cupping-categories" min={6} max={10} step={1} value={valueCompletion} onChange={e => handleChangeCompletion(e.target.value)}/> 
                                        {(valueCompletion>10 || valueCompletion<6 )  && <label className='invalid-input-cupping' style={{ color: 'var(--farmly-fuzzy)' }}>{isPt() ? 'Nota entre 6 e 10.' : 'Grade between 6 and 10'}</label>}
                                    </div>
                                </div>
                            </div>

                            <Divider variant="middle" />

                            <div style={{ margin: '2vw', borderRadius: '25px' }}>
                                <div style={{ padding: '15px' }}>
                                    <div className='cupping-col-contents col'>
                                        <label className="info-title-desktop" style={{marginRight:'1vw'}}>{isPt() ? 'Acidez:' : 'Acidity:'}</label>
                                        <input type="number" className="farmly-input input-cupping-categories" min={6} max={10} step={1} value={valueAcidity} onChange={e => handleChangeAcidity(e.target.value)} placeholder=""/> 
                                         {(valueAcidity>10 || valueAcidity<6 )  && <label className='invalid-input-cupping' style={{ color: 'var(--farmly-fuzzy)' }}>{isPt() ? 'Nota entre 6 e 10.' : 'Grade between 6 and 10'}</label>}
                                    </div>
                                    <div className={classes.rootslider} className='cupping-col-contents col'>
                                        <label className='info2-title-desktop' style={{marginRight:'2vw',marginLeft:'2vw'}}>{isPt() ? 'Intensidade:' : 'Intensity:'}</label>    
                                        <Grid container spacing={2} className='medium-volume-desktop'>
                                                <CuppingSlider style={{marginRight:'1vw'}} value={valueAcidity2} onChange={handleChangeAcidity2} marks={acidityMarks} min={6} max={10} valueLabelDisplay="off" orientation="horizontal" aria-labelledby="continuous-slider" step={0.5} />
                                        </Grid>                                        
                                    </div>
                                </div>
                            </div>

                            <Divider variant="middle" />

                            <div style={{ margin: '2vw', borderRadius: '25px' }}>
                                <div style={{ padding: '15px' }}>
                                    <div className='cupping-col-contents col'>
                                        <label className="info-title-desktop" style={{marginRight:'1vw'}}>{isPt() ? 'Corpo:' : 'Body:'}</label>
                                        <input type="number" className="farmly-input input-cupping-categories" min={6} max={10} step={1} value={valueBody} onChange={e => handleChangeBody(e.target.value)} placeholder=""/> 
                                         {(valueBody>10 || valueBody<6 )  && <label className='invalid-input-cupping' style={{ color: 'var(--farmly-fuzzy)' }}>{isPt() ? 'Nota entre 6 e 10.' : 'Grade between 6 and 10'}</label>}
                                    </div>
                                    <div className={classes.rootslider} className='cupping-col-contents col'>
                                        <label className='info2-title-desktop' style={{marginRight:'2vw',marginLeft:'2vw'}}>{isPt() ? 'Intensidade:' : 'Intensity:'}</label>    
                                        <Grid container spacing={2} className='medium-volume-desktop'>
                                            <CuppingSlider value={valueBody2} style={{marginRight:'1vw'}}  onChange={handleChangeBody2} marks={true} min={6} max={10} valueLabelDisplay="off" orientation="horizontal" aria-labelledby="continuous-slider" step={1} />
                                        </Grid>                                        
                                    </div>    
                                </div>
                            </div>
                             
                            <Divider variant="middle" />

                            <div style={{ margin: '2vw', borderRadius: '25px' }}>
                                <div style={{ padding: '15px' }}>
                                    <div className='cupping-col-contents col'>
                                        <label className="info-title-desktop" style={{marginRight:'1vw'}}>{isPt() ? 'Equilíbrio:' : 'Balance:'}</label>
                                        <input type="number" className="farmly-input input-cupping-categories" min={6} max={10} step={1} value={valueBalance} onChange={e => handleChangeBalance(e.target.value)} placeholder=""/> 
                                         {(valueBalance>10 || valueBalance<6 )  && <label className='invalid-input-cupping' style={{ color: 'var(--farmly-fuzzy)' }}> {isPt() ? 'Nota entre 6 e 10.' : 'Grade between 6 and 10'}</label>}
                                    </div>
                                    { }
                                    <div className='cupping-col-contents col'>
                                        <label className="info-title-desktop" style={{marginRight:'1vw', marginLeft:'3vw'}}>{isPt() ? 'Notal final:' : 'Overall:'}</label>
                                        <input type="number" className="farmly-input input-cupping-categories" min={6} max={10} step={1} value={valueOverall} onChange={e => handleChangeOverall(e.target.value)} placeholder=""/> 
                                         {(valueOverall>10 || valueOverall<6 )  && <label className='invalid-input-cupping' style={{ color: 'var(--farmly-fuzzy)' }}>{isPt() ? 'Nota entre 6 e 10.' : 'Grade between 6 and 10'}</label>}
                                    </div>
                                </div>
                            </div>
                            <Divider variant="middle" />
                            <div zIndex="tooltip" style={{ margin: '2vw', borderRadius: '25px', position: 'relative' }}>
                                <div style={{ padding: '15px', textAlign: 'center' }}>
                                    <div className="producer-profile-item">
                                        <label className="info-title-desktop">{isPt() ? 'Xícara Limpa:' : 'Clean Cup:'}</label>
                                        <span>{isPt() ? 'Marque a xícara que não for uma xícara limpa' : 'Mark the cup that is not a clean cup'}</span>
                                    </div>
                                    <div>
                                        {clean1 && <FontAwesomeIcon icon={faTimes} className="select-cross-desktop" onClick={() => {
                                            clean1 ? setCleanCup(cleanCup + 2) : setCleanCup(cleanCup - 2)
                                            setClean1(!clean1)
                                        }}></FontAwesomeIcon>}
                                        <FontAwesomeIcon className="select-cup-desktop" icon={faCoffee} onClick={() => {
                                            clean1 ? setCleanCup(cleanCup + 2) : setCleanCup(cleanCup - 2)
                                            setClean1(!clean1)
                                        }}></FontAwesomeIcon>
                                        {clean2 && <FontAwesomeIcon icon={faTimes} className="select-cross-desktop" onClick={() => {
                                            clean2 ? setCleanCup(cleanCup + 2) : setCleanCup(cleanCup - 2)
                                            setClean2(!clean2)
                                        }}></FontAwesomeIcon>}
                                        <FontAwesomeIcon className="select-cup-desktop" icon={faCoffee} onClick={() => {
                                            clean2 ? setCleanCup(cleanCup + 2) : setCleanCup(cleanCup - 2)
                                            setClean2(!clean2)
                                        }}></FontAwesomeIcon>
                                        {clean3 && <FontAwesomeIcon icon={faTimes} className="select-cross-desktop" onClick={() => {
                                            clean3 ? setCleanCup(cleanCup + 2) : setCleanCup(cleanCup - 2)
                                            setClean3(!clean3)
                                        }}></FontAwesomeIcon>}
                                        <FontAwesomeIcon className="select-cup-desktop" icon={faCoffee} onClick={() => {
                                            clean3 ? setCleanCup(cleanCup + 2) : setCleanCup(cleanCup - 2)
                                            setClean3(!clean3)
                                        }}></FontAwesomeIcon>
                                        {clean4 && <FontAwesomeIcon icon={faTimes} className="select-cross-desktop" onClick={() => {
                                            clean4 ? setCleanCup(cleanCup + 2) : setCleanCup(cleanCup - 2)
                                            setClean4(!clean4)
                                        }}></FontAwesomeIcon>}
                                        <FontAwesomeIcon className="select-cup-desktop" icon={faCoffee} onClick={() => {
                                            clean4 ? setCleanCup(cleanCup + 2) : setCleanCup(cleanCup - 2)
                                            setClean4(!clean4)
                                        }}></FontAwesomeIcon>
                                        {clean5 && <FontAwesomeIcon icon={faTimes} className="select-cross-desktop" onClick={() => {
                                            clean5 ? setCleanCup(cleanCup + 2) : setCleanCup(cleanCup - 2)
                                            setClean5(!clean5)
                                        }}></FontAwesomeIcon>}
                                        <FontAwesomeIcon className="select-cup-desktop" icon={faCoffee} onClick={() => {
                                            clean5 ? setCleanCup(cleanCup + 2) : setCleanCup(cleanCup - 2)
                                            setClean5(!clean5)
                                        }}></FontAwesomeIcon>
                                    </div>
                                </div>
                            </div>

                            <Divider variant="middle" />

                            {/* Doçura */}
                            <div zIndex="tooltip" style={{ margin: '2vw', borderRadius: '25px', position: 'relative' }}>
                                <div style={{ padding: '15px', textAlign: 'center' }}>
                                    <div className="producer-profile-item">
                                        <label htmlFor="" className="info-title-desktop">{isPt() ? 'Doçura:' : 'Sweetness:'}</label>
                                        <span>{isPt() ? 'Marque a xícara que não tiver a presença de doçura' : 'Mark the cup that has no sweetness'}</span>
                                    </div>
                                    <div>
                                        {sweet1 && <FontAwesomeIcon icon={faTimes} className="select-cross-desktop" onClick={() => {
                                            sweet1 ? setSweet(sweet + 2) : setSweet(sweet - 2)
                                            setSweet1(!sweet1)
                                        }}></FontAwesomeIcon>}
                                        <FontAwesomeIcon className="select-cup-desktop" icon={faCoffee} onClick={() => {
                                            sweet1 ? setSweet(sweet + 2) : setSweet(sweet - 2)
                                            setSweet1(!sweet1)
                                        }}></FontAwesomeIcon>
                                        {sweet2 && <FontAwesomeIcon icon={faTimes} className="select-cross-desktop" onClick={() => {
                                            sweet2 ? setSweet(sweet + 2) : setSweet(sweet - 2)
                                            setSweet2(!sweet2)
                                        }}></FontAwesomeIcon>}
                                        <FontAwesomeIcon className="select-cup-desktop" icon={faCoffee} onClick={() => {
                                            sweet2 ? setSweet(sweet + 2) : setSweet(sweet - 2)
                                            setSweet2(!sweet2)
                                        }}></FontAwesomeIcon>
                                        {sweet3 && <FontAwesomeIcon icon={faTimes} className="select-cross-desktop" onClick={() => {
                                            sweet3 ? setSweet(sweet + 2) : setSweet(sweet - 2)
                                            setSweet3(!sweet3)
                                        }}></FontAwesomeIcon>}
                                        <FontAwesomeIcon className="select-cup-desktop" icon={faCoffee} onClick={() => {
                                            sweet3 ? setSweet(sweet + 2) : setSweet(sweet - 2)
                                            setSweet3(!sweet3)
                                        }}></FontAwesomeIcon>
                                        {sweet4 && <FontAwesomeIcon icon={faTimes} className="select-cross-desktop" onClick={() => {
                                            sweet4 ? setSweet(sweet + 2) : setSweet(sweet - 2)
                                            setSweet4(!sweet4)
                                        }}></FontAwesomeIcon>}
                                        <FontAwesomeIcon className="select-cup-desktop" icon={faCoffee} onClick={() => {
                                            sweet4 ? setSweet(sweet + 2) : setSweet(sweet - 2)
                                            setSweet4(!sweet4)
                                        }}></FontAwesomeIcon>
                                        {sweet5 && <FontAwesomeIcon icon={faTimes} className="select-cross-desktop" onClick={() => {
                                            sweet5 ? setSweet(sweet + 2) : setSweet(sweet - 2)
                                            setSweet5(!sweet5)
                                        }}></FontAwesomeIcon>}
                                        <FontAwesomeIcon className="select-cup-desktop" icon={faCoffee} onClick={() => {
                                            sweet5 ? setSweet(sweet + 2) : setSweet(sweet - 2)
                                            setSweet5(!sweet5)
                                        }}></FontAwesomeIcon>
                                    </div>
                                </div>
                            </div>

                            <Divider variant="middle" />

                            {/* Uniformidade */}
                            <div zIndex="tooltip" style={{ margin: '2vw', borderRadius: '25px', position: 'relative' }}>
                                <div style={{ padding: '15px', textAlign: 'center' }}>
                                    <div className="producer-profile-item">
                                        <label htmlFor="" className="info-title-desktop">{isPt() ? 'Uniformidade:' : 'Equality:'}</label>
                                        <span>{isPt() ? 'Marque a xícara que não for uniforme' : 'Mark the cup that is not uniform'}</span>
                                    </div>
                                    <div>
                                        {equal1 && <FontAwesomeIcon icon={faTimes} className="select-cross-desktop" onClick={() => {
                                            equal1 ? setEqualty(equalty + 2) : setEqualty(equalty - 2)
                                            setEqual1(!equal1)
                                        }}></FontAwesomeIcon>}
                                        <FontAwesomeIcon className="select-cup-desktop" icon={faCoffee} onClick={() => {
                                            equal1 ? setEqualty(equalty + 2) : setEqualty(equalty - 2)
                                            setEqual1(!equal1)
                                        }}></FontAwesomeIcon>
                                        {equal2 && <FontAwesomeIcon icon={faTimes} className="select-cross-desktop" onClick={() => {
                                            equal2 ? setEqualty(equalty + 2) : setEqualty(equalty - 2)
                                            setEqual2(!equal2)
                                        }}></FontAwesomeIcon>}
                                        <FontAwesomeIcon className="select-cup-desktop" icon={faCoffee} onClick={() => {
                                            equal2 ? setEqualty(equalty + 2) : setEqualty(equalty - 2)
                                            setEqual2(!equal2)
                                        }}></FontAwesomeIcon>
                                        {equal3 && <FontAwesomeIcon icon={faTimes} className="select-cross-desktop" onClick={() => {
                                            equal3 ? setEqualty(equalty + 2) : setEqualty(equalty - 2)
                                            setEqual3(!equal3)
                                        }}></FontAwesomeIcon>}
                                        <FontAwesomeIcon className="select-cup-desktop" icon={faCoffee} onClick={() => {
                                            equal3 ? setEqualty(equalty + 2) : setEqualty(equalty - 2)
                                            setEqual3(!equal3)
                                        }}></FontAwesomeIcon>
                                        {equal4 && <FontAwesomeIcon icon={faTimes} className="select-cross-desktop" onClick={() => {
                                            equal4 ? setEqualty(equalty + 2) : setEqualty(equalty - 2)
                                            setEqual4(!equal4)
                                        }}></FontAwesomeIcon>}
                                        <FontAwesomeIcon className="select-cup-desktop" icon={faCoffee} onClick={() => {
                                            equal4 ? setEqualty(equalty + 2) : setEqualty(equalty - 2)
                                            setEqual4(!equal4)
                                        }}></FontAwesomeIcon>
                                        {equal5 && <FontAwesomeIcon icon={faTimes} className="select-cross-desktop" onClick={() => {
                                            equal5 ? setEqualty(equalty + 2) : setEqualty(equalty - 2)
                                            setEqual5(!equal5)
                                        }}></FontAwesomeIcon>}
                                        <FontAwesomeIcon className="select-cup-desktop" icon={faCoffee} onClick={() => {
                                            equal5 ? setEqualty(equalty + 2) : setEqualty(equalty - 2)
                                            setEqual5(!equal5)
                                        }}></FontAwesomeIcon>
                                    </div>
                                </div>
                            </div>

                            <Divider variant="middle" />

                            {/* Defeitos */}
                            <div style={{ margin: '2vw', borderRadius: '25px', position: 'relative' }}>
                                <div style={{ padding: '15px' }}>
                                    <div className='cupping-col-contents col ' style={{ marginRight: '2vw'}}>
                                        <label style={{ marginRight: '1vw'}} className="info-title-desktop">{isPt() ? 'Defeitos leves:' : 'Minor Defects:'}</label> 
                                        <input type="number" className="farmly-input input-cupping-categories" min={0} max={5} step={1} value={defectLight} onChange={e => setDefect(e.target.value)} placeholder=""/> 
                                        {(defectLight>5 || defectLight<0 )  && <label className='invalid-input-cupping' style={{ color: 'var(--farmly-fuzzy)' }}>{isPt() ? 'Nota entre 0 e 5. ' : 'Grade between 0 and 5'}</label>}
                                    </div>
                                    <label className='defect-comment'><b>{isPt() ? 'Leve' : 'Taint'}</b> x2</label>
                                    <div className='cupping-col-contents col ' style={{ marginRight: '2vw'}}>
                                        <label style={{ marginRight: '1vw'}} className="info-title-desktop">{isPt() ? 'Defeitos fortes:' : 'Minor Defects:'}</label> 
                                        <input type="number" className="farmly-input input-cupping-categories" min={0} max={5} step={1} value={defectHeavy} onChange={e => setBigDefect(e.target.value)} placeholder=""/> 
                                        {(defectHeavy>5 || defectHeavy<0 )  && <label className='invalid-input-cupping' style={{ color: 'var(--farmly-fuzzy)' }}>{isPt() ? 'Nota entre 0 e 5.' : 'Grade between 0 and 5'}</label>}
                                    </div>
                                    <label className='defect-comment'><b>{isPt() ? 'Forte' : 'Fault'}</b> x4</label>
                                </div>
                            </div>
                            <Divider variant="middle" />
                        </div>
                    </div>
                    <div className={classes.scorePaper} style={{ width: '30%', marginLeft: '60px',marginTop:'-135px' }}>
                        <div className="producer-profile-item" style={{ padding: '1vw', paddingTop: '1vw', textAlign: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', marginBottom:'0px' }}>
                            <label htmlFor="" className="info-title-desktop" >{isPt() ? 'Pontuação:' : 'Score:'}</label>
                            <label style={{ color: 'rgb(230, 179, 143)', fontSize: '40px', fontWeight: 'normal' }}> {scoreVar && !!parseFloat(score) ? parseFloat(score) : 0}pts</label>
                        </div>
                        <div className="producer-profile-item" style={{  textAlign: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                                    <label htmlFor="" className="info2-title-desktop" >{coffee.name}</label>
                            <label style={{ color: 'rgb(230, 179, 143)', fontSize: '25px', fontWeight: 'normal' }}>{isPt() ? 'código:' : 'code:'} {coffee.coffee_id}</label>
                        </div>
                        <div>
                        {/* <div style={{ height: '17vw', overflowY: 'scroll' }}> */}
                            <div >
                                <RadarChart attributes={chartAttributes} maxHeight={'12vw'} />
                            </div>
                            <br />
                            {coffeeData &&
                                <div className='sensory-cards' style={{overflowY: 'scroll'}}>
                                    {renderSensoryCard()}
                                </div>
                            }
                        </div>
                        <div style={{borderRadius: '25px', position: 'relative',marginTop: '-20px' }}>
                                <div style={{ padding: '15px', textAlign: 'center' }}>
                                    <div className="comment-box" style={{ textAlign: 'center' }}>
                                    <label className="info2-title-desktop">{isPt() ? 'Comentário geral:' : 'General comment:'}</label>
                                        <FarmlyInput textarea styleClass="farmly-input comments-cupping-desktop" value={cuppingData.generalComment || ""} setValue={value => setCuppingData({ ...cuppingData, generalComment: value })}></FarmlyInput>
                                    </div>
                                </div>
                        </div>
                        <div style={{ textAlign: '-webkit-center' }}>
                            <div>
                                {!showResults ? 
                                <button className="score-paper-button-score-cupping" onClick={() => handleResult(true)} style={{ marginBottom: '8px', width: '50%' }}>
                                    {isPt() ? 'Finalizar' : 'Generate Result'}
                                </button>
                                :
                                <>
                                <button className="score-paper-button-score-cupping" onClick={() => handleReturn()} style={{ marginBottom: '8px', width: '50%' }}>
                                    {isPt() ? 'Editar' : 'Edit'}
                                </button>
                                <button className="score-paper-button-score-cupping" onClick={() => updateAll()} style={{ marginBottom: '8px', width: '50%' }}>
                                    {isPt() ? 'Voltar ao Menu' : 'Return to main Menu'}
                                </button>
                                </>
                                }
                            </div>
                        </div>
                    </div>
                </div >
        </div >
    )
}

export default CuppingIndvDesktop