import React, { FunctionComponent } from 'react';

import { Container, DescriptionContainer, DescriptionItem } from './styles';

import CountryFlag from './components/CountryFlag';

interface CardDescriptionProps {
    process     : string,
    prices      : [number,number],
    farmCode    : string,
    origin      : string,
}
 
const CardDescription: FunctionComponent<CardDescriptionProps> = (props) => {
    return (
        <Container>
            <CountryFlag origin={props.origin} />
            <DescriptionContainer>
                <DescriptionItem>{props.process}</DescriptionItem>
                <DescriptionItem>{props.prices[0]}</DescriptionItem>
                <DescriptionItem>{props.farmCode}</DescriptionItem>
            </DescriptionContainer>
        </Container>
    );
}
 
export default CardDescription;