import React, { useState } from 'react'
import {
    MenuItem,
    Button,
    Slider,
    Menu,
    Accordion,
    AccordionSummary
} from '@material-ui/core'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faSearch,
    faSortDown,
    faSortUp,
    faSlidersH,
    faTimesCircle
} from '@fortawesome/free-solid-svg-icons'
import './SearchBar.css'

import { language } from "../../common";
import { AccordionDetails } from '@material-ui/core'

const SearchBar = (props) => {

    const {
        code,
        selectedScores,
        selectedPrices,
        handleRangeScore,
        handleRangePrice,
        handleCode
    } = props

    const [anchorEl, setAnchorEl] = useState(null);
    const [sortScore, setSortScore] = useState(false);
    const [sortPrice, setSortPrice] = useState(false);
    const [sortSensorytNote, setSortSensoryNote] = useState(false);

    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        console.log(event)
    };

    return (
        <div className="farmly-search-bar-container">
            <div className="farmly-search-submit">
                <button className="farmly-button-clear-input" value={""} onClick={handleCode}>
                    <FontAwesomeIcon className="farmly-search-icon" icon={code?.length ? faTimesCircle : faSearch}/>
                </button>
               
            </div>
            <input
                type="text"
                maxLength="12"
                placeholder={{
                    pt: "Buscar (code)",
                    en: "Search (code)",
                    es: "Buscar (code)",
                }[language]}
                value={code? code : ""}
                onChange={handleCode}
                className="farmly-search-bar" id={props.idName || "search-bar"}
            />
            <div className="farmly-search-setting">
                <Button
                    aria-controls="fade-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                >
                    <FontAwesomeIcon className="search-bar-icon-slider" icon={faSlidersH} />
                </Button>
                <Menu
                    className="search-advanced"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem>
                        <Accordion style={{ width: '100%', height: '90%' }}>
                            <AccordionSummary
                                expandIcon={
                                    <FontAwesomeIcon
                                        icon={faSortDown}
                                        color="#1F625E"
                                        style={{ marginBottom: ".5em", padding: ".5em" }}
                                        size={16}
                                    />}
                            >
                                <div className="farmly-search-bar-advanced">
                                    {
                                        {
                                            pt: "Filtrar pela pontuação do café",
                                            en: "Filter by coffee score",
                                            es: "Filtrar por puntuación de café",
                                        }[language]
                                    }
                                </div>
                            </AccordionSummary>
                            <AccordionDetails
                                expandIcon={
                                    <FontAwesomeIcon
                                        icon={faSortUp}
                                        color="#1F625E"
                                        style={{ marginBottom: ".5em", padding: ".5em" }}
                                        size={16}
                                    />
                                }
                            >
                                <div className="range-advanced-search">
                                    <label>
                                        {
                                            {
                                                pt: "Intervalo de pontuação",
                                                en: "Score Range",
                                                es: "Rango de puntuación",
                                            }[language]
                                        }
                                    </label>
                                    <label>
                                        <span style={{ fontSize: ".8em" }}>min </span>
                                        <b>{selectedScores[0]}</b>-
                                        <span style={{ fontSize: ".8em" }}>max </span>
                                        <b>{selectedScores[1]}</b>
                                    </label>
                                    <Slider
                                        min={82}
                                        max={100}
                                        value={selectedScores}
                                        onChange={handleRangeScore}
                                        valueLabelDisplay="auto"
                                        className="range-picker"
                                        aria-labelledby="range-slider"
                                    />
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </MenuItem>

                    <MenuItem>
                        <Accordion style={{ width: '100%', height: '90%' }}>
                            <AccordionSummary
                                expandIcon={
                                    <FontAwesomeIcon
                                        icon={faSortDown}
                                        style={{ marginBottom: ".5em", padding: ".5em" }}
                                        color="#1F625E"
                                        size={16}
                                    />
                                }
                            >
                                <div className="farmly-search-bar-advanced">
                                    {
                                        {
                                            pt: "Filtrar pelo preço do café",
                                            en: "Filter by coffee price",
                                            es: "Filtrar por precio del café",
                                        }[language]
                                    }
                                </div>
                            </AccordionSummary>
                            <AccordionDetails
                                expandIcon={<FontAwesomeIcon
                                    icon={faSortUp}
                                    color="#1F625E"
                                    style={{ marginBottom: ".5em", padding: ".5em" }}
                                    size={16}
                                />}
                            >
                                <div className="range-advanced-search">
                                    <label>
                                        {
                                            {
                                                pt: "Intervalo de preços",
                                                en: "Price Range",
                                                es: "Rango de precio",
                                            }[language]
                                        }
                                    </label>
                                    <label>
                                        <span style={{ fontSize: ".8em" }}>min </span>
                                        <b>€{selectedPrices[0]}</b>-
                                        <span style={{ fontSize: ".8em" }}>max </span>
                                        <b>€{selectedPrices[1]}</b>
                                    </label>
                                    <Slider
                                        min={0}
                                        max={100}
                                        value={selectedPrices}
                                        onChange={handleRangePrice}
                                        valueLabelDisplay="auto"
                                        className="range-picker"
                                        aria-labelledby="range-slider"
                                    />
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </MenuItem>

                    {/* NOTAS SENSORIAIS */}
                    {/* <MenuItem>
                        <Accordion style={{ width: '100%', height: '90%' }}>
                            <AccordionSummary
                                expandIcon={
                                    <FontAwesomeIcon
                                        icon={faSortDown}
                                        style={{ marginBottom: ".5em", padding: ".5em" }}
                                        color="#1F625E"
                                        size={16}
                                    />
                                }
                            >
                                <div className="farmly-search-bar-advanced">
                                    {
                                        {
                                            pt: "Filtrar por notas sensoriais",
                                            en: "Filter by sensory notes",
                                            es: "Filtrar notas sensoriales",
                                        }[language]
                                    }
                                </div>
                            </AccordionSummary>
                            <AccordionDetails
                                expandIcon={<FontAwesomeIcon
                                    icon={faSortUp}
                                    color="#1F625E"
                                    style={{ marginBottom: ".5em", padding: ".5em" }}
                                    size={16}
                                />}
                            >
                                <div className="range-advanced-search">
                                    <label>
                                        {
                                            {
                                                pt: "Notas Sensoriais",
                                                en: "Sensory Notes",
                                                es: "Notas Sensoriales",
                                            }[language]
                                        }
                                    </label>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </MenuItem> */}
                </Menu>
            </div>
        </div>
    )
}

export default SearchBar