export default function ReceiveQuery(){

    let emailQuery, returnUrlQuery, inviteCode
    const landingPageQueryParams = new URL(document.location.href).searchParams

    emailQuery = landingPageQueryParams.get('email')
    returnUrlQuery = landingPageQueryParams.get('returnUrl')

    return {emailQuery, returnUrlQuery}
    
}




