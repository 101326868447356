import React, { useContext, useEffect, Suspense } from 'react'
import { Switch, Route, Redirect } from 'react-router'
import { useHistory } from 'react-router-dom'
import Cookie from 'js-cookie'

import UserContext from '../context/user/UserContext.js'
import LandingPageCupping from '../components/templates/LandingPageCupping.jsx'
import Login from '../components/login/Login.jsx'
import SignUp from '../components/signup/SignUp.jsx'
import SignUpInvite from '../components/signup/signupNoInvite/SignUpNoInvite'
import SignUpGoogle from '../components/signup/google/SignUp.jsx'
import SignUp_old from '../components/signup_old/SignUp.jsx'
import Intersticial from "../modules/Insterticial"
// import Profile from '../components/profile/Profile.jsx'
// import ProfileToSample from '../components/profile/ProfileToSample.jsx'
// import ProfileToNotification from '../components/profile/ProfileToNotification.jsx'
import ProducerProfile from '../components/profile/producers/ProducerProfile.jsx'
import RoasterProfile from '../components/profile/roasters/Roasters.jsx'
// import Coffees from '../components/coffees/Coffees.jsx'
import Chat from 'components/profile/Chat.jsx'
import NewOfferList from '../components/coffees/NewOfferList.jsx'
import PasswordRecovery from '../components/passwordRecovery/PasswordRecovery.jsx';
import RecoveryPage from '../components/passwordRecovery/RecoveryPage.jsx'
import CoffeeProfile from '../components/coffeeProfile/CoffeeProfile.jsx'
import RequestSample from '../components/coffeeProfile/RequestSample'
import ErrorPage from '../components/templates/Error.jsx'
import CartPage from '../components/cart/CartScreen.jsx'
import ThanksForBuying from '../components/cart/ThanksForYourPurchase.jsx'
import ThanksForRegistering from '../components/landingPage/ThanksForYourRegistration.jsx'
import RegisteredRequisitions from '../components/landingPage/RegisteredRequisitions.jsx'
import CartPageAdmin from '../components/adminCart/CartScreenAdmin.jsx'
import Checkout from '../components/checkout/Checkout.jsx'
import VerifyEmail from '../components/verifyEmail/VerifyEmail.jsx'
import Help from '../components/help/Help.jsx'
import ControlPanel from '../components/admin/ControlPanel.jsx'
import GreetingCard from '../components/admin/greetingCard.jsx'
import LandingPage from '../components/landingPage/LandingPage.jsx'
import NegociationProfile from '../components/profile/roasters/NegociationProfile.jsx'
import PrivacyPolicy from '../components/policy/PrivacyPolicy.jsx'
import CookiesPolicy from '../components/policy/CookiesPolicy.jsx'
// import NewProfileTest from '../components/profile/NewProfileTest'
import NewProfileTemplate from '../components/templates/NewProfileTemplate.jsx'
// import NewRoasterProfile from '../components/profile/NewRoasterProfile'
import PageLanding from '../components/templates/PageLanding.jsx'
import ControlCupping from '../components/cupping/ControlCupping'
// import SelectCupping from '../components/cupping/SelectCupping'
import Cupping from '../components/cupping/Cupping.jsx'
import CuppingResults from '../components/cupping/CuppingResults.jsx'
import CuppingLive from '../components/cupping/cuppingLive/CuppingLive.jsx'
import CuppingLiveProducer from '../components/cupping/cuppingLive/CuppingLiveProducer.jsx'
import CuppingLiveProducer2 from '../components/cupping/cuppingLive/CuppingLiveProducer2.jsx'
import CuppingLive2 from '../components/cupping/cuppingLive/CuppingLive2.jsx'
import CreateCoffeeCupping from '../components/cupping/CreateCoffeeCupping.jsx'
import CuppingSelect from '../components/cupping/CuppingSelect.jsx'
import CuppingExclusive from '../components/cupping/cuppingExclusive/CuppingExclusive.jsx'
import CuppingLogin from '../components/cupping/CuppingLogin.jsx'
import CuppingSignUp from '../components/cupping/CuppingSignUp.jsx'
// import {CoffeeCardTest} from '../components/CoffeeCard.jsx'
import CoffeeTable from '../components/CoffeeTable.jsx'
import SampleCart from "modules/SampleCart/SampleCartUser"

import ReceivedLink from '../components/signup/ReceivedLink.jsx'
import Cookies from '../components/admin/Cookies.jsx'
import NoCoffeesLandingPage2 from '../components/landingPage/NoCoffeesLandingPage2'
import FarmlyCuppingSelect from '../components/cupping/cuppingTasters/CreateCoffeeFarmlyCupping.jsx'
import FarmlyCupping from '../components/cupping/cuppingTasters/FarmlyCupping.jsx'
import NewCoffees from '../components/coffees/NewCoffees.jsx'
import ManageCoffee from '../modules/CoffeeManagement'
import ManageShipments from '../modules/ShipmentManagement'
import ShipmentManage from '../components/shipmentManage/ShipmentManage.jsx'
import LandingPageLucasTrip from '../modules/LandingPageLucasTrip'
import Testing from '../modules/Testing'
import ColombianTestOfferList from 'components/coffees/ColombianTestOfferList.jsx'
import HomeTest from 'components/coffees/HomeTest.jsx'
import CuppingTest from 'components/coffees/CuppingTest.jsx'
import CoffeeExchangeRate from 'components/coffees/ExchangeRate.jsx'

// em desenvolvimento
// import ImpactPage from '../components/ImpactPage/ImpactPage.jsx'

import { HomeAB } from '../modules/Home/hooks/homeTestAB'

import ReactGA from 'react-ga'
import { isBr, isClub, isFarmly } from '../common.js'
//import Home from '../modules/Home/index.tsx'

// Lazy Imports:

// import LandingCuppingFinal from '../components/landingPage/LandingCuppingFinal'
const LandingCuppingFinal = React.lazy(() => import('../components/landingPage/LandingCuppingFinal'))

//import LandingPageProducer from '../components/templates/LandingPageProducer.jsx'
const LandingPageProducer  = React.lazy(() => import('../components/templates/LandingPageProducer.jsx'))

const OldHome = React.lazy(() => import('../components/home/Home.jsx'))
// const Home = React.lazy(() => import('../modules/Home'))

// import OfferList from '../components/coffees/OfferList.jsx'
// const OfferList = React.lazy(() => import('../components/coffees/OfferList.jsx'))

// import NoCoffeesLandingPage from '../components/landingPage/NoCoffeesLandingPage'
const NoCoffeesLandingPage = React.lazy(() => import('../components/landingPage/NoCoffeesLandingPage'))

const homeAB = Cookie.get('home-ab')

export default props => {
    const userContext = useContext(UserContext)

    const history = useHistory()

    useEffect(() => {
        if (isClub() && !isFarmly()) {
            ReactGA.initialize('UA-148326570-1')
            ReactGA.pageview(history.location.pathname + history.location.search)
            history.listen(location => {
                ReactGA.pageview(location.pathname + location.search)
            })
        }
    }, [history])

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Switch>
                
            <Route exact path='/' component={OldHome} />
                <Route path='/login' component={Login} />
                <Route path='/signup' component={SignUp} />
                <Route path='/intersticial' component={Intersticial}/>
                <Route path='/signupInvite' component={SignUpInvite} />
                <Route path='/signupGoogle' component={SignUpGoogle} />
                <Route path='/signup/:invite' component={SignUp} />
                <Route path='/signup_old' component={SignUp_old} />
                {userContext.user && <Route path='/profile' component={NewProfileTemplate} />}
                {userContext.user && <Route path='/profileMkt' component={NewProfileTemplate} />}
                {userContext.user && <Redirect from='/profiletosample' to='/profile' state={{ showUserSendSampleContent: true}}/>}
                {userContext.user && <Route path='/request-sample' component={RequestSample} />}
                {/* {userContext.user && <Route path='/profiletonotification' component={NewProfileTemplate} />} */}
                {/* {userContext.user && <Route path='/profiletonotification' component={NewProfileTemplate} />} */}
                <Route path='/newcoffees' component={NewCoffees} />
                <Route path='/coffees' component={NewCoffees} />
                <Route path='/box' component={NewCoffees} />
                <Route path='/offerlist' component={NewOfferList} />
                <Route path='/Offerlist' component={NewOfferList} />
                <Route path='/offerlist-fb' component={NewOfferList} />
                <Route path='/newofferlist' component={NewOfferList} />
                <Route path='/help' component={Help} />
                <Route path='/recover' component={PasswordRecovery} />
                <Route path='/reset/:token' component={RecoveryPage} />
                <Route path='/verify/:token' component={VerifyEmail} />
                <Route path='/coffee/:coffeeId' component={CoffeeProfile} />
                <Route path='/error' component={ErrorPage} />
                <Route path='/cart' component={CartPage} />
                <Route path='/privacy-policy' component={PrivacyPolicy} />
                <Route path='/cookies' component={CookiesPolicy} />
                {userContext.user && userContext.isAdmin && <Route path='/mestre_dos_magos' component={ControlPanel} />}
                {userContext.user && userContext.isAdmin && <Route path='/producer/profile/:id' component={NewProfileTemplate} />}
                {userContext.user && userContext.isAdmin && <Route path='/roaster/profile/:id' component={NewProfileTemplate} />}
                {userContext.user && userContext.isAdmin && <Route path='/greeting_card' component={GreetingCard} />}
                {userContext.user && userContext.isAdmin && <Route path='/negociation/:id' component={NegociationProfile} />}
                <Route path='/exclusiveCart/:reference' component={CartPageAdmin} />
                <Route path='/landingPageCupping' component={LandingPageCupping} />
                <Route path='/landingPageProducer' component={LandingPageProducer} />
                <Route path='/controlCupping' component={ControlCupping} />
                <Route path='/cupping' component={Cupping} />
                <Route path='/cuppingLive' component={CuppingLive} />
                <Route path='/cuppingLiveFriday' component={CuppingLive2} />
                <Route path='/cuppingLiveProducerSexta' component={CuppingLiveProducer2} />
                <Route path='/cuppingLiveProducer' component={CuppingLiveProducer} />
                <Route path='/landingPage' component={PageLanding} />
                <Route path='/checkout/:paymentId' component={Checkout} />
                <Route path='/thanksForBuying' component={ThanksForBuying} />
                <Route path='/thanksForRegistering' component={ThanksForRegistering} />
                <Route path='/registeredRequisitions' component={RegisteredRequisitions} />
                <Route path='/start_here' component={LandingPage} />
                <Route path='/createCuppingCoffee' component={CreateCoffeeCupping} />
                <Route path='/cuppingSelect' component={CuppingSelect} />
                <Route path='/cuppingSelect/new' component={CuppingSelect} />
                <Route path='/cuppingResults' component={CuppingResults} />
                <Route path='/cuppingExclusive' component={CuppingExclusive} />
                <Route path='/noCoffees/gl' component={NoCoffeesLandingPage} />
                <Route path='/noCoffees/fb' component={NoCoffeesLandingPage} />
                <Route path='/noCoffees' component={NoCoffeesLandingPage} />
                <Route path='/noCoffees2' component={NoCoffeesLandingPage2} />
                <Route path='/noCoffeesThx' component={NoCoffeesLandingPage} />
                <Route path='/colombianOfferlist' component={ColombianTestOfferList} />
                <Route path='/home' component={HomeTest} />
                <Route path='/newcupping' component={CuppingTest} />
                <Route path='/CuppingLandingFinal' component={LandingCuppingFinal}/>
                <Route path='/ReceivedLink' component={ReceivedLink}/>            
                <Route path='/CuppingLogin' component={CuppingLogin} />
                <Route path='/CuppingSignUp' component={CuppingSignUp} />
                <Route path='/FogueteNaoTemRe' component={Cookies}/>
                <Route path='/SampleCart/:listLink' component={SampleCart}/>
                <Route path='/Chat/:id' component={Chat} />
                {isBr() &&<Route path='/CoffeeExchangeRate' component={CoffeeExchangeRate} />}
                {/* <Route path='/CoffeeExchangeRate' component={CoffeeExchangeRate} /> */}
                <Route path='/NewProfileTest' component={NewProfileTemplate}/>
                {/* <Route path='/NewRoasterTemplate' component={NewRoasterProfile} /> */}
                {/* <Route path='/roaster/:roasterId' component={NewRoasterProfile}/> */}
                {/* <Route path='/CoffeeCardTest' component={CoffeeCardTest} /> */}
                <Route path='/CoffeeTableTest' component={CoffeeTable} />
                <Route path='/farmlyCuppingSelect' component={FarmlyCuppingSelect} />
                <Route path='/farmlyCupping' component={FarmlyCupping} />
                <Route path='/welcome-to-farmly' component={LandingPageLucasTrip} />
                {/* <Route path='/ImpactPage' component={ImpactPage} /> still in development */}
                {userContext.user && userContext.isAdmin && <Route path='/manageCoffee' component={ManageCoffee} />}
                {userContext.user && userContext.isAdmin && <Route path='/manageShipments' component={ManageShipments} />}
                {userContext.user && userContext.isAdmin && <Route path='/shipmentManage' component={ShipmentManage} />}
                <Redirect from='/profile' to='/' />
                <Redirect from='/reset' to='/' />
                <Redirect from='/recover' to='/' />
                <Redirect from='/localhost' to='/' />
                <Route path='/blog' component={() => {
                    window.location.href = 'https://blog.farmlyclub.com/'
                    return null
                }} />

                <Route path='/test' component={Testing} />
                
            </Switch>
        </Suspense>
    )
}

//Signup
/*export default props =>
    <Switch>
        <Route exact path='/' component={SignUp} />
        <Route path='/redirect' component={() => {
            window.location.href = 'http://farmlyclub.com'
            return null
        }} />
        <Redirect from='*' to='/' />
    </Switch>*/
