import React, { useContext, useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import { Link } from 'react-router-dom'
import MuiAlert from '@material-ui/lab/Alert';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Snackbar from '@material-ui/core/Snackbar';
import { PlayCircleFilledWhite, SettingsInputComponent, SettingsPowerRounded } from '@material-ui/icons';
import SampleContext from '../../context/sample/SampleContext'
import UserContext from '../../context/user/UserContext'
import axios from 'axios'
import { isPt, farmlyTexts, server, acceptedCookies } from '../../common'
import FarmlyModal from '../templates/FarmlyModal'
import FarmlyInput from '../templates/FarmlyInput'
import ChipArray from './Chip.jsx'
import { CLEAR_LIST } from '../../context/types';
import { set } from 'react-ga';
import './OptionsTable.css'
import {
    toEuro,
    totalCoffeePrice,
    logisticsFee,
    importDuties,
    farmlyFee,
    producersPriceKg
} from './pricing.js'

const coFlag = require("../../assets/colombia.png");
const brFlag = require("../../assets/brazil.png");

function createData(Code, Producer, Score, Process, Bags, Price, Description) {
    return { Code, Producer, Score, Process, Bags, Price, Description };
}


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    { id: 'Code', numeric: true, disablePadding: true, label: 'Code' },
    { id: 'Country', numeric: false, disablePadding: false, label: 'Country' },
    { id: 'Producer', numeric: false, disablePadding: false, label: 'Producer' },
    { id: 'Score', numeric: true, disablePadding: false, label: 'Score' },
    { id: 'Process', numeric: false, disablePadding: false, label: 'Process' },
    { id: 'Bags', numeric: true, disablePadding: false, label: 'Bags' }, //(60kg)
    { id: 'Price', numeric: true, disablePadding: false, label: 'Price/kg' },
    { id: 'Description', numeric: false, disablePadding: false, label: 'Sensory Notes' },
    { id: 'Coffee', numeric: false, disablePadding: false, label: '' },
];

function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const sampleContext = useContext(SampleContext)
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead stickyHeader>
            <TableRow>
                <TableCell padding="checkbox">
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'center'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{ color: 'white' }}
                    >
                        <TableSortLabel
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                            style={{ color: 'white' }}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden} style={{ color: 'white' }}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};
const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        color: 'white'
    },
    highlight: {
        color: 'white',
        backgroundColor: 'rgb(154, 199, 177)',
    },
    title: {
        flex: '1 1 100%',
        color: 'white'
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected, setStartRequest, setRequestPopup, setRequestPopupFollowing, noRequest, setNoSamplePopup } = props;
    const userContext = useContext(UserContext)
    const sampleContext = useContext(SampleContext)
    const decideWitchModal = () => {
        if (userContext.user) {
            setRequestPopupFollowing(true)
        } else {
         setRequestPopup(true)
        }
        setNoSamplePopup(true)
    }

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            {numSelected > 0 ? (
                <Typography className={classes.title} color="inherit" variant="subtitle1" component="div" style={{ color: 'rgb(27, 98, 94)', display: 'flex', flexDirection: 'column', margin: '3vw', marginBottom: '0vw' }}>
                    <div style={{display: 'flex', flexDirection: 'row',justifyContent: 'space-between'}}>
                        {sampleContext.offerList.length} selected
                        <div className="requestselectedSamples-buttons" onClick={() => decideWitchModal()}>
                            Request these samples
                        </div>
                    </div>
                    <div style={{display: 'flex', marginBottom: '15px', flexWrap: 'wrap'}}>
                        <ChipArray></ChipArray>
                    </div>
                </Typography>
            ) : (
                    <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                        FARMly Samples
                    </Typography>
                )}

        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        color: 'white'
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        backgroundColor: 'rgb(27, 98, 94)',
        color: 'white'
    },
    table: {
        minWidth: 800,
        color: 'white'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
        color: 'white'
    },
    add: {
        color: 'white',
        fontWeight: 'bold',
        fontSize: '20px',
        textAlign: 'center',
        cursor: 'pointer',

    },
}));

export default function EnhancedTable(props) {
    const { coffees, price, setRequestPopup, requestPopup, requestPopupFollowing, setRequestPopupFollowing, triggerRequest, noRequest, setNoSamplePopup } = props;
    const sampleContext = useContext(SampleContext)
    const userContext = useContext(UserContext)
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('Code');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [warning, setWarning] = React.useState(false)
    const [open, setOpen] = React.useState(false)
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [startRequest, setStartRequest] = useState(false)
    const [moreSampleInfo, setMoreSampleInfo] = useState(false)
    const [roasterName, setRoasterName] = useState('')
    const [roasterEmail, setRoasterEmail] = useState('')
    const [cafes, setCafes] = useState('')
    const [pageList, setPageList] = useState([{}])
    const selectedCode = []
    const [paymentData, setPaymentData] = useState({
        farmly_fee: 0,
        logistics_fee: 0,
        import_duties: 0,
        import_duties_adjustment: 0,
        euro_value: 0
    })

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false)
    }

    const handleClick = (event, coffee, isItemSelected) => {
        if (noRequest) {
            setNoSamplePopup(true)
        } else {
            const selectedIndex = selected.indexOf(coffee.farmCode);
            let newSelected = [];


            // if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedCode, coffee.farmCode);

            // } else if (selectedIndex === 0) {
            //     newSelected = newSelected.concat(selected.slice(1));

            // } else if (selectedIndex === selected.length - 1) {
            //     newSelected = newSelected.concat(selected.slice(0, -1));


            // } else if (selectedIndex > 0) {
            //     newSelected = newSelected.concat(
            //         selected.slice(0, selectedIndex),
            //         selected.slice(selectedIndex + 1),
            //     )
            // }
            if (sampleContext.offerList.length < 5) {
                setSelected(newSelected);
                newSelected.map(cada => {
                    axios.get(`${server}/coffeeByCode/${cada}`)
                        .then(res => {
                            addToList(res.data)
                        }
                        )
                })

            } else {
                isItemSelected = false
                setOpen(true)
                setWarning(true)
                setMoreSampleInfo(true)
            }
        }
    };

    const addToList = (selectedId) => {
        sampleContext.addToList(selectedId)
    }

    const removeSample = (coffee) => {
        sampleContext.removeFromList(coffee.farmCode)
    }

    const isSelected = (name) => sampleContext.offerList.forEach(item => true)

    useEffect(() => {
        sampleContext.offerList.forEach(item => {
            selectedCode.push(item.farmCode)
        })
        axios.get(`${server}/paymentData`)
            .then(res => setPaymentData(res.data))
        if (sampleContext.offerList.length === 5) {
            setOpen(true)
            setWarning(true)

        }
    }, [sampleContext.offerList.length])

    // useEffect(() => {
    //     console.log("essa é a offer list", sampleContext.offerList);
    //     const vetor = []
    //     if (userContext.user) {
    //         coffees.forEach(coffee => {
    //             axios.get(`${server}/requested_from/${userContext.user.id}`)
    //                 .then(res => {
    //                     res.data.forEach(cada => {
    //                         if (cada.id !== coffee.data.id) {
    //                             vetor.push(cada)
    //                         }
    //                     })
    //                 })
    //         })

    //     }
    // }, [sampleContext.offerList.length, userContext.user])



    const emptyRows = rowsPerPage - Math.min(rowsPerPage, coffees.length - page * rowsPerPage);

    const moreSample = (isItemSelected) => {
        isItemSelected = true
        setMoreSampleInfo(true)
    }

    const sendEmailMoreSample = (roasterName, roasterEmail, cafes) => {
        axios.post(`${server}/moreSample`, {
            name: roasterName,
            email: roasterEmail,
            cafes: cafes
        })
        setMoreSampleInfo(false)
    }

    const allFilled = (
        roasterName !== '' &&
        roasterEmail !== '' &&
        cafes !== ''
    )

    return (
        <>
            { warning &&
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="warning">
                        You can't add any more samples to the list!
                    </Alert>
                </Snackbar>
            }
            {startRequest &&
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="warning">
                        START
            </Alert>
                </Snackbar>}
            {moreSampleInfo &&
                <FarmlyModal closeModal={() => setMoreSampleInfo(false)}>
                    <div className="confirm-info-container">
                        <label className="pricing-info-title">You have already selected 5 samples to request!</label>
                        <br />
                        <label>If you would like some more, please fill the form below and someone from FARMly will </label>
                        <label>get in touch with you to discuss the possibility of requesting more than 5 samples!</label>
                        <br />
                        <div className="pricing-info-item">
                            <label className="pricing-info-label">Name:</label>
                            <FarmlyInput type='text' value={roasterName} setValue={value => setRoasterName(value)}
                                className="pricing-info-value" elementId="name" placeholder="Name..." />
                        </div>
                        <div className="pricing-info-item">
                            <label className="pricing-info-label">Email:</label>
                            <FarmlyInput type='text' value={roasterEmail} setValue={value => setRoasterEmail(value)}
                                className="pricing-info-value" elementId="phoneNumber" placeholder="Email..." />
                        </div>
                        <div className="pricing-info-item">
                            <label className="pricing-info-label">Samples*:</label>
                            <FarmlyInput type='text' value={cafes} setValue={value => setCafes(value)}
                                className="pricing-info-value" elementId="email" placeholder="BR21-999, CO21-998..." />
                        </div>
                        <label>*Write the code of the coffees you haven't selected in the offer list. Example: "FARM 999"</label>
                        <br />
                        <div className="checkout-buttons">
                            <div className="cart-checkout-button" onClick={() => sendEmailMoreSample(roasterName, roasterEmail, cafes)}>Send Request</div>
                        </div>
                        {!allFilled && <div className="request-alert"> {isPt() ? farmlyTexts.requestFillAll_pt : farmlyTexts.requestFillAll_en} </div>}
                    </div>
                </FarmlyModal>
            }
            <div className={classes.root} style={{ color: 'white' }}>
                <Paper className={classes.paper} style={{ color: 'white' }}>
                    <EnhancedTableToolbar requestPopup={requestPopup} setRequestPopup={setRequestPopup} setRequestPopupFollowing={setRequestPopupFollowing} numSelected={sampleContext.offerList.length} style={{ color: 'white' }} setStartRequest={setStartRequest} noRequest={noRequest} setNoSamplePopup={setNoSamplePopup} />
                    <TableContainer>
                    <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size={dense ? 'small' : 'medium'}
                            aria-label="enhanced table"
                            style={{ color: 'white', backgroundColor: 'rgb(27, 98, 94)'  }}
                        >
                    <EnhancedTableHead
                                classes={classes}
                                numSelected={sampleContext.offerList.length}
                                order={order}
                                orderBy={orderBy}
                                rowCount={coffees.length + 1}
                                style={{ color: 'white', backgroundColor: 'rgb(27, 98, 94)' }}
                            />
                            </Table>
                            {/* <TableContainer style={{position: 'absolute', width: '85%'}}>
                        <div className="offerlist-section">
                            Seção 1
                        </div>
                        </TableContainer> */}
                        <br/>
                        <br/>

                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size={dense ? 'small' : 'medium'}
                            aria-label="enhanced table"
                            style={{ color: 'white' }}
                        >
                            <TableBody style={{ color: 'white' }}>
                                {coffees.map((coffee, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    let isItemSelected
                                    if (sampleContext.offerList.length > 0) {
                                        sampleContext.offerList.forEach(item => {
                                            if (item.farmCode == coffee.farmCode) {
                                                isItemSelected = true
                                            }
                                        })
                                    }

                    //                 return (
                    //                     <>
                    //                     {coffee.section == 1 &&
                    //                         <TableRow
                    //                             hover
                    //                             role="checkbox"
                    //                             aria-checked={isItemSelected}
                    //                             tabIndex={-1}
                    //                             key={coffee.farmCode}
                    //                             selected={isItemSelected}

                    //                             style={isItemSelected ? { backgroundColor: 'rgb(184, 219, 202)', color: 'rgb(27, 98, 94)' } : { color: 'white' }}
                    //                         >
                    //                             <TableCell padding="checkbox" style={{ minWidth: '48px' }}>
                    //                                 {isItemSelected ?
                    //                                     <div className={classes.add} style={isItemSelected ? { color: 'rgb(27, 98, 94)' } : { color: 'white' }} onClick={(event) => sampleContext.removeFromList(coffee.farmCode)}>-</div>
                    //                                     :
                    //                                     <div className={classes.add} style={isItemSelected ? { color: 'rgb(27, 98, 94)' } : { color: 'white' }} onClick={(event) => handleClick(event, coffee, isItemSelected)}>+</div>
                    //                                 }
                    //                             </TableCell>
                    //                             <TableCell component="th" id={labelId} scope="row" padding="none" style={isItemSelected ? { color: 'rgb(27, 98, 94)', cursor: 'pointer', width: '6vw' } : { color: 'white', cursor: 'pointer', width: '6vw' }} onClick={(event) => {
                    //                                 if (isItemSelected) {
                    //                                     sampleContext.removeFromList(coffee.farmCode)
                    //                                 } else {
                    //                                     handleClick(event, coffee, isItemSelected)
                    //                                 }
                    //                             }
                    //                             }>FARM {coffee.farmCode}</TableCell>
                    //                             <TableCell align="center" style={isItemSelected ? { color: 'rgb(27, 98, 94)', cursor: 'pointer', width: '7vw' } : { color: 'white', cursor: 'pointer', width: '7vw' }} onClick={(event) => {
                    //                                 if (isItemSelected) {
                    //                                     sampleContext.removeFromList(coffee.farmCode)
                    //                                 } else {
                    //                                     handleClick(event, coffee, isItemSelected)
                    //                                 }
                    //                             }
                    //                             }>{coffee.producer}</TableCell>
                    //                             <TableCell align="center" style={isItemSelected ? { color: 'rgb(27, 98, 94)', cursor: 'pointer', width: '9vw' } : { color: 'white', cursor: 'pointer', width: '9vw' }} onClick={(event) => {
                    //                                 if (isItemSelected) {
                    //                                     sampleContext.removeFromList(coffee.farmCode)
                    //                                 } else {
                    //                                     handleClick(event, coffee, isItemSelected)
                    //                                 }
                    //                             }
                    //                             }>{!coffee.score ? "-" : coffee.score}</TableCell>
                    //                             <TableCell align="center" style={isItemSelected ? { color: 'rgb(27, 98, 94)', cursor: 'pointer', width: '7vw' } : { color: 'white', cursor: 'pointer', width: '7vw' }} onClick={(event) => {
                    //                                 if (isItemSelected) {
                    //                                     sampleContext.removeFromList(coffee.farmCode)
                    //                                 } else {
                    //                                     handleClick(event, coffee, isItemSelected)
                    //                                 }
                    //                             }
                    //                             }>{coffee.process}</TableCell>
                    //                             <TableCell align="center" style={isItemSelected ? { color: 'rgb(27, 98, 94)', cursor: 'pointer', width: '12vw' } : { color: 'white', cursor: 'pointer', width: '12vw' }} onClick={(event) => {
                    //                                 if (isItemSelected) {
                    //                                     sampleContext.removeFromList(coffee.farmCode)
                    //                                 } else {
                    //                                     handleClick(event, coffee, isItemSelected)
                    //                                 }
                    //                             }
                    //                             }>{coffee.sackRemain}</TableCell>
                    //                             <TableCell align="center" style={isItemSelected ? { color: 'rgb(27, 98, 94)', cursor: 'pointer', width: '7vw' } : { color: 'white', cursor: 'pointer', width: '7vw' }} onClick={(event) => {
                    //                                 if (isItemSelected) {
                    //                                     sampleContext.removeFromList(coffee.farmCode)
                    //                                 } else {
                    //                                     handleClick(event, coffee, isItemSelected)
                    //                                 }
                    //                             }
                    //                             }>€ {toEuro(((totalCoffeePrice({ ...coffee, quantity: '1' }, paymentData)) / 60), paymentData).toFixed(2)}</TableCell>
                    //                             <TableCell align="center" style={isItemSelected ? { color: 'rgb(27, 98, 94)', cursor: 'pointer', width: '14vw' } : { color: 'white', cursor: 'pointer', width: '14vw' }} onClick={(event) => {
                    //                                 if (isItemSelected) {
                    //                                     sampleContext.removeFromList(coffee.farmCode)
                    //                                 } else {
                    //                                     handleClick(event, coffee, isItemSelected)
                    //                                 }
                    //                             }
                    //                             }>{coffee.sensory_notes_en}</TableCell>

                    //                             <TableCell align="center" style={isItemSelected ? { color: 'rgb(27, 98, 94)', cursor: 'pointer', width: '1vw' } : { color: 'white', cursor: 'pointer', width: '1vw' }}>
                    //                                 <Link to={`/coffee/${coffee.id}`} >
                    //                                     <ArrowForwardRoundedIcon style={{ color: 'white', padding: '5px', borderRadius: '40px', backgroundColor: 'rgb(203, 86, 81)' }} />
                    //                                 </Link>
                    //                             </TableCell>

                    //                         </TableRow>
                    //             }
                    //                     </>
                    //                 );
                    //             })}
                    //         </TableBody>
                    //     </Table>
                    //     {/* <TableContainer style={{position: 'absolute', width: '85%'}}>
                    //         <div className="offerlist-section">
                    //             Seção 2
                    //         </div>
                    // </TableContainer> */}
                    // <br/>
                    // <br/>
                    //     <Table
                    //         className={classes.table}
                    //         aria-labelledby="tableTitle"
                    //         size={dense ? 'small' : 'medium'}
                    //         aria-label="enhanced table"
                    //         style={{ color: 'white' }}
                    //     >
                    //         <TableBody style={{ color: 'white' }}>
                    //             {coffees.map((coffee, index) => {
                    //                 const labelId = `enhanced-table-checkbox-${index}`;
                    //                 let isItemSelected
                    //                 if (sampleContext.offerList.length > 0) {
                    //                     sampleContext.offerList.forEach(item => {
                    //                         if (item.farmCode == coffee.farmCode) {
                    //                             isItemSelected = true
                    //                         }
                    //                     })
                    //                 }
                    //                 return (
                    //                     <>
                    //                     {coffee.section == 2 &&
                    //                        <TableRow
                    //                        hover
                    //                        role="checkbox"
                    //                        aria-checked={isItemSelected}
                    //                        tabIndex={-1}
                    //                        key={coffee.farmCode}
                    //                        selected={isItemSelected}

                    //                        style={isItemSelected ? { backgroundColor: 'rgb(184, 219, 202)', color: 'rgb(27, 98, 94)' } : { color: 'white' }}
                    //                    >
                    //                        <TableCell padding="checkbox" style={{ minWidth: '48px' }}>
                    //                            {isItemSelected ?
                    //                                <div className={classes.add} style={isItemSelected ? { color: 'rgb(27, 98, 94)' } : { color: 'white' }} onClick={(event) => sampleContext.removeFromList(coffee.farmCode)}>-</div>
                    //                                :
                    //                                <div className={classes.add} style={isItemSelected ? { color: 'rgb(27, 98, 94)' } : { color: 'white' }} onClick={(event) => handleClick(event, coffee, isItemSelected)}>+</div>
                    //                            }
                    //                        </TableCell>
                    //                        <TableCell component="th" id={labelId} scope="row" padding="none" style={isItemSelected ? { color: 'rgb(27, 98, 94)', cursor: 'pointer', width: '6vw' } : { color: 'white', cursor: 'pointer', width: '6vw' }} onClick={(event) => {
                    //                            if (isItemSelected) {
                    //                                sampleContext.removeFromList(coffee.farmCode)
                    //                            } else {
                    //                                handleClick(event, coffee, isItemSelected)
                    //                            }
                    //                        }
                    //                        }>FARM {coffee.farmCode}</TableCell>
                    //                        <TableCell align="center" style={isItemSelected ? { color: 'rgb(27, 98, 94)', cursor: 'pointer', width: '7vw' } : { color: 'white', cursor: 'pointer', width: '7vw' }} onClick={(event) => {
                    //                            if (isItemSelected) {
                    //                                sampleContext.removeFromList(coffee.farmCode)
                    //                            } else {
                    //                                handleClick(event, coffee, isItemSelected)
                    //                            }
                    //                        }
                    //                        }>{coffee.producer}</TableCell>
                    //                        <TableCell align="center" style={isItemSelected ? { color: 'rgb(27, 98, 94)', cursor: 'pointer', width: '9vw' } : { color: 'white', cursor: 'pointer', width: '9vw' }} onClick={(event) => {
                    //                            if (isItemSelected) {
                    //                                sampleContext.removeFromList(coffee.farmCode)
                    //                            } else {
                    //                                handleClick(event, coffee, isItemSelected)
                    //                            }
                    //                        }
                    //                        }>{!coffee.score ? "-" : coffee.score}</TableCell>
                    //                        <TableCell align="center" style={isItemSelected ? { color: 'rgb(27, 98, 94)', cursor: 'pointer', width: '7vw' } : { color: 'white', cursor: 'pointer', width: '7vw' }} onClick={(event) => {
                    //                            if (isItemSelected) {
                    //                                sampleContext.removeFromList(coffee.farmCode)
                    //                            } else {
                    //                                handleClick(event, coffee, isItemSelected)
                    //                            }
                    //                        }
                    //                        }>{coffee.process}</TableCell>
                    //                        <TableCell align="center" style={isItemSelected ? { color: 'rgb(27, 98, 94)', cursor: 'pointer', width: '12vw' } : { color: 'white', cursor: 'pointer', width: '12vw' }} onClick={(event) => {
                    //                            if (isItemSelected) {
                    //                                sampleContext.removeFromList(coffee.farmCode)
                    //                            } else {
                    //                                handleClick(event, coffee, isItemSelected)
                    //                            }
                    //                        }
                    //                        }>{coffee.sackRemain}</TableCell>
                    //                        <TableCell align="center" style={isItemSelected ? { color: 'rgb(27, 98, 94)', cursor: 'pointer', width: '7vw' } : { color: 'white', cursor: 'pointer', width: '7vw' }} onClick={(event) => {
                    //                            if (isItemSelected) {
                    //                                sampleContext.removeFromList(coffee.farmCode)
                    //                            } else {
                    //                                handleClick(event, coffee, isItemSelected)
                    //                            }
                    //                        }
                    //                        }>€ {toEuro(((totalCoffeePrice({ ...coffee, quantity: '1' }, paymentData)) / 60), paymentData).toFixed(2)}</TableCell>
                    //                        <TableCell align="center" style={isItemSelected ? { color: 'rgb(27, 98, 94)', cursor: 'pointer', width: '14vw' } : { color: 'white', cursor: 'pointer', width: '14vw' }} onClick={(event) => {
                    //                            if (isItemSelected) {
                    //                                sampleContext.removeFromList(coffee.farmCode)
                    //                            } else {
                    //                                handleClick(event, coffee, isItemSelected)
                    //                            }
                    //                        }
                    //                        }>{coffee.sensory_notes_en}</TableCell>

                    //                        <TableCell align="center" style={isItemSelected ? { color: 'rgb(27, 98, 94)', cursor: 'pointer', width: '1vw' } : { color: 'white', cursor: 'pointer', width: '1vw' }}>
                    //                            <Link to={`/coffee/${coffee.id}`} >
                    //                                <ArrowForwardRoundedIcon style={{ color: 'white', padding: '5px', borderRadius: '40px', backgroundColor: 'rgb(203, 86, 81)' }} />
                    //                            </Link>
                    //                        </TableCell>

                    //                    </TableRow>
                    //             }
                    //                     </>
                    //                 );
                    //             })}
                    //         </TableBody>
                    //     </Table>
                    //     {/* <TableContainer style={{position: 'absolute', width: '85%'}}>
                    //         <div className="offerlist-section">
                    //             Seção 3
                    //         </div>
                    // </TableContainer> */}
                    // <br/>
                    // <br/>
                    //     <Table
                    //         className={classes.table}
                    //         aria-labelledby="tableTitle"
                    //         size={dense ? 'small' : 'medium'}
                    //         aria-label="enhanced table"
                    //         style={{ color: 'white' }}
                    //     >
                    //         <TableBody style={{ color: 'white' }}>
                    //             {coffees.map((coffee, index) => {
                    //                 const labelId = `enhanced-table-checkbox-${index}`;
                    //                 let isItemSelected
                    //                 if (sampleContext.offerList.length > 0) {
                    //                     sampleContext.offerList.forEach(item => {
                    //                         if (item.farmCode == coffee.farmCode) {
                    //                             isItemSelected = true
                    //                         }
                    //                     })
                    //                 }

                                    return (
                                        <>
                                        {/* {coffee.section == 3 && */}
                                            <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={coffee.farmCode}
                                            selected={isItemSelected}

                                            style={isItemSelected ? { backgroundColor: 'rgb(184, 219, 202)', color: 'rgb(27, 98, 94)' } : { color: 'white' }}
                                        >
                                            <TableCell padding="checkbox" style={{ minWidth: '25px' }}>
                                                {isItemSelected ?
                                                    <div className={classes.add} style={isItemSelected ? { color: 'rgb(27, 98, 94)' } : { color: 'white' }} onClick={(event) => sampleContext.removeFromList(coffee.farmCode)}>-</div>
                                                    :
                                                    <div className={classes.add} style={isItemSelected ? { color: 'rgb(27, 98, 94)' } : { color: 'white' }} onClick={(event) => handleClick(event, coffee, isItemSelected)}>+</div>
                                                }
                                            </TableCell>
                                            <TableCell component="th" id={labelId} scope="row" padding="none" style={isItemSelected ? { color: 'rgb(27, 98, 94)', cursor: 'pointer', width: '6vw' } : { color: 'white', cursor: 'pointer', width: '6vw' }} onClick={(event) => {
                                                if (isItemSelected) {
                                                    sampleContext.removeFromList(coffee.farmCode)
                                                } else {
                                                    handleClick(event, coffee, isItemSelected)
                                                }
                                            }
                                            }>{coffee.farmCode}</TableCell>
                                            <TableCell align="center" style={isItemSelected ? { color: 'rgb(27, 98, 94)', cursor: 'pointer', width: '7vw'} : { color: 'white', cursor: 'pointer', width: '7vw'}} onClick={(event) => {
                                                if (isItemSelected) {
                                                    sampleContext.removeFromList(coffee.farmCode)
                                                } else {
                                                    handleClick(event, coffee, isItemSelected)
                                                }
                                            }
                                            }>{<img className="offerlist-country-flag" src={coffee.origin == "Brazil" ? brFlag : coFlag}></img>}</TableCell>
                                            <TableCell align="center" style={isItemSelected ? { color: 'rgb(27, 98, 94)', cursor: 'pointer', width: '10vw', paddingLeft: '3vw' } : { color: 'white', cursor: 'pointer', width: '10vw', paddingLeft: '3vw' }} onClick={(event) => {
                                                if (isItemSelected) {
                                                    sampleContext.removeFromList(coffee.farmCode)
                                                } else {
                                                    handleClick(event, coffee, isItemSelected)
                                                }
                                            }
                                            }>{coffee.producer}</TableCell>
                                            <TableCell align="center" style={isItemSelected ? { color: 'rgb(27, 98, 94)', cursor: 'pointer', width: '9vw' } : { color: 'white', cursor: 'pointer', width: '9vw' }} onClick={(event) => {
                                                if (isItemSelected) {
                                                    sampleContext.removeFromList(coffee.farmCode)
                                                } else {
                                                    handleClick(event, coffee, isItemSelected)
                                                }
                                            }
                                            }>{!coffee.score ? "-" : coffee.score}</TableCell>
                                            <TableCell align="center" style={isItemSelected ? { color: 'rgb(27, 98, 94)', cursor: 'pointer', width: '7vw' } : { color: 'white', cursor: 'pointer', width: '7vw' }} onClick={(event) => {
                                                if (isItemSelected) {
                                                    sampleContext.removeFromList(coffee.farmCode)
                                                } else {
                                                    handleClick(event, coffee, isItemSelected)
                                                }
                                            }
                                            }>{coffee.process}</TableCell>
                                            <TableCell align="center" style={isItemSelected ? { color: 'rgb(27, 98, 94)', cursor: 'pointer', width: '12vw' } : { color: 'white', cursor: 'pointer', width: '12vw' }} onClick={(event) => {
                                                if (isItemSelected) {
                                                    sampleContext.removeFromList(coffee.farmCode)
                                                } else {
                                                    handleClick(event, coffee, isItemSelected)
                                                }
                                            }
                                            }>{coffee.sackRemain}</TableCell>
                                            <TableCell align="center" style={isItemSelected ? { color: 'rgb(27, 98, 94)', cursor: 'pointer', width: '7vw', paddingRight: window.screen.width == 1024 ? '0vw' : '4vw' } : { color: 'white', cursor: 'pointer', width: '7vw', paddingRight: window.screen.width == 1024 ? '0vw' : '4vw' }} onClick={(event) => {
                                                if (isItemSelected) {
                                                    sampleContext.removeFromList(coffee.farmCode)
                                                } else {
                                                    handleClick(event, coffee, isItemSelected)
                                                }
                                            }
                                            }>€  {coffee.origin == "Brazil" ? toEuro(((totalCoffeePrice({ ...coffee, quantity: '1' }, paymentData)) / 60), paymentData, {euro_value: coffee.euro_value}).toFixed(2) : ((totalCoffeePrice({ ...coffee, quantity: '1' }, paymentData)) / 70).toFixed(2)}</TableCell>{/*{toEuro(((totalCoffeePrice({ ...coffee, quantity: '1' }, paymentData)) / 60), paymentData).toFixed(2)}*/}
                                            <TableCell align="center" style={isItemSelected ? { color: 'rgb(27, 98, 94)', cursor: 'pointer', width: '14vw' } : { color: 'white', cursor: 'pointer', width: '14vw' }} onClick={(event) => {
                                                if (isItemSelected) {
                                                    sampleContext.removeFromList(coffee.farmCode)
                                                } else {
                                                    handleClick(event, coffee, isItemSelected)
                                                }
                                            }
                                            }>{coffee.sensory_notes_en}</TableCell>

                                            <TableCell align="center" style={isItemSelected ? { color: 'rgb(27, 98, 94)', cursor: 'pointer', width: '1vw' } : { color: 'white', cursor: 'pointer', width: '1vw' }}>
                                                <Link to={`/coffee/${coffee.id}`} >
                                                    <ArrowForwardRoundedIcon style={{ color: 'white', padding: '5px', borderRadius: '40px', backgroundColor: 'rgb(203, 86, 81)' }} />
                                                </Link>
                                            </TableCell>

                                        </TableRow>
                                {/* } */}
                                        </>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </div >
        </>
    );
}
