import React, { Suspense }from 'react'
import { BrowserRouter } from 'react-router-dom'

import './App.css'
import Routes from './Routes'
import UserState from '../context/user/UserState'
import CartState from '../context/cart/CartState'
import SampleState from '../context/sample/SampleState'
import LoadingContext from '../context/loading/LoadingContext'

require('dotenv').config();

export default props =>
    <CartState>
        <SampleState>
            <UserState>
                <LoadingContext>
                    <BrowserRouter>
                        <Suspense fallback={<div>Loading...</div>}>
                            <div className="app">
                                <Routes />
                            </div>
                        </Suspense>
                    </BrowserRouter>
                </LoadingContext>
            </UserState>
        </SampleState>
    </CartState>