import styled from 'styled-components';

// className="sample-cart-holder" 
export const SampleCartBox = styled.div`
    text-align: center;
    transition-timing-function: ease-in-out;
    transition: all 0.3s;
    display: flex;
    flex-direction: row;
    width: 65vw;
    overflow-y: scroll !important;
`
export const SampleListTitleLabel = styled.p`
    transition-timing-function: ease-in-out;
    transition: all 0.3s;
    text-align: center;
`

export const CreateNewCartButtonContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    margin-top: 20px;
`
export const SampleSelector = styled.div`
    width: 45vw;
    margin-left: 25px;
`
export const CartActions = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-content: space-between;
    justify-content: space-between;
`
export const SampleCartContainer = styled.div`
    display: flex;
    flex-direction: column;
`
export const ColumnSampleInfos = styled.div`
    display: flex;
    flex-direction: column;
`
export const ExpireContainer = styled.div`
    display: flex;
    flex-direction: column;
`