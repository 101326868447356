import React, { useState, useEffect, useContext } from 'react';

import Main from '../templates/Main';
import SearchBar from '../templates/SearchBar';
import CoffeeTable from '../CoffeeTable';
import InformationCoffeeBox from './InformationCoffeeBox';
import FarmlyLoading from '../templates/FarmlyLoading';
import UserContext from '../../context/user/UserContext';

// import axios from 'axios';
import backend from '../../lib/backend';
import { getfavoriteCoffees } from '../../services/favorite';
import { language } from '../../common';
import './Coffees.css';

const NewCoffees = props => {

    const titleHeaderLanguage = { 'pt': 'Cafés', 'en': 'Coffees', 'es': 'Cafés' }[language]

    const [page, setPage] = useState(0);
    const [coffees, setCoffees] = useState([]);
    const [loading, setLoading] = useState(true);
    const [hasMoreCoffees, setHasMoreCoffees] = useState(true);
    const [showInformationBox, setShowInformationBox] = useState(false);


    const addCoffee = () => { props.history.push('/coffee/new') }

    const [selectedScores, setSelectedScores] = useState([82, 100]);
    const [selectedPrices, setSelectedPrices] = useState([0, 100]);
    const [code, setCode] = useState('');

    const handleRangeScore = (event, newValue) => {
        setSelectedScores(newValue);
        setHasMoreCoffees(true);
        setPage(0);
    };

    const handleRangePrice = (event, newValue) => {
        setSelectedPrices(newValue);
        setHasMoreCoffees(true);
        setPage(0);
    };

    const handleCode = (event, newValue) => {
        // por algum motivo nao está pegando o valor alterado pelo newValue
        setCode(event.target.value);
        setHasMoreCoffees(true);
        setPage(0);
    };

    const [scores, setScores] = useState({
        min_score: 82,
        max_score: 100
    })

    const [prices, setPrices] = useState({
        min_price: 0,
        max_price: 100
    })

    const changeParameters = (selectedScores, selectedPrices) => {
        setScores({
            min_score: selectedScores[0],
            max_score: selectedScores[1]
        })

        setPrices({
            min_price: selectedPrices[0],
            max_price: selectedPrices[1]
        })
    }

    useEffect(() => {
        changeParameters(selectedScores, selectedPrices)
    }, [selectedScores, selectedPrices])

    const userContext = useContext(UserContext)

    const getCoffees = (
        atPage = 0,
        min_score = 82,
        max_score = 100,
        min_price = 0,
        max_price = 100,
        code
    ) => {
        setLoading(true);
        // página -> route params
        // display -> query params
        const queryString = `/coffees/${atPage}`
            + '?display=true'
            + (`&min_score=${min_score}`)
            + (`&max_score=${max_score}`)
            + (`&min_price=${min_price}`)
            + (`&max_price=${max_price}`)
            + (code ? `&code=${code}` : '')

        backend.get(queryString)
            .then(async res => {
                if (res.data.length === 0) {
                    setHasMoreCoffees(false);
                    setLoading(false);
                    if(atPage === 0 ){
                        setCoffees([]);
                    }
                }
                else {
                    const newCoffees = (atPage === 0) ? [] : [...coffees]
                    newCoffees.push(...res.data);
                    setCoffees(newCoffees);
                    setLoading(false);
                }
            }).catch(error => {
                console.log(error);
            });
    }

    const handleScroll = async () => {
        if (((window.innerHeight + document.documentElement.scrollTop) < (document.documentElement.offsetHeight - 300) && loading === false)
            ||
            hasMoreCoffees === false) {
            return;
        }
        setPage(page + 1);
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
    })

    useEffect(() => {
        getCoffees(
            page,
            scores.min_score,
            scores.max_score,
            prices.min_price,
            prices.max_price,
            code
        )
    },
        [
            page,
            scores,
            code,
            prices
        ]
    )

    const renderInfoBox = () => {
        return (
            <InformationCoffeeBox
                showInformationBox={showInformationBox}
                setShowInformationBox={setShowInformationBox}
            />
        )
    }

    return (
        <>
            <Main titleHeader={titleHeaderLanguage}>
                <div className="coffees-container" id="coffee-container-id">
                    <div className="coffees-title-container">
                        <SearchBar
                            code={code}
                            selectedScores={selectedScores}
                            selectedPrices={selectedPrices}
                            handleRangeScore={handleRangeScore}
                            handleRangePrice={handleRangePrice}
                            handleCode={handleCode}
                        />
                        {/* {userContext.user && userContext.user.isProd &&
                            <div className="coffees-add-coffee-button"
                                onClick={addCoffee}
                            >
                                Adicionar Café
                        </div>
                        } */}
                    </div>

                    {
                        showInformationBox === true ? renderInfoBox() : ''
                    }
                    <div id="coffee-card-container">
                        <CoffeeTable
                            coffees={coffees}
                        />
                        <div style={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
                            <FarmlyLoading display={loading} />
                        </div>
                    </div>
                </div>
            </Main>
        </>
    )
}

export default NewCoffees;