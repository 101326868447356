import React, { useState, useEffect, useContext, Fragment } from 'react'
import Avatar from '@material-ui/core/Avatar';
import { farmlyColors, server,isPt, isBr, isCo  } from '../../../common.js'
import axios from 'axios'
import CartContext from '../../../context/cart/CartContext'
import UserContext from '../../../context/user/UserContext.js'
import './CuppingLiveCard.css'
import {
    toEuro,
    totalCoffeePrice,
    logisticsFee,
    importDuties,
    farmlyFee,
    producersPriceKg
} from '../../templates/pricing.js'
import { storage } from '../../../firebase'

const coffeeImage = require('../../../assets/produtor3.jpg')
const coffeePicture3 = require('../../../assets/coffee_farm.jpg')

const CuppingLiveCard = props => {
    const {coffee, user, toggleModal, setModalMessage} = props
    const userContext = useContext(UserContext)
    const cartContext = useContext(CartContext)
    const [isInCart, setIsInCart] = useState(false)
    const [sackQuantity, setSackQuantity] = useState(1)
    const [reviews, setReviews] = useState ([])
    const [auxReview, setAuxReview] = useState(false)
    const [cardImg, setCardImg] = useState()
    const [farmImg, setFarmImg] = useState()
    const [coffeeImageUrl, setCoffeeImageUrl] = useState()
    const newDate = new Date
    const day = ("0" + newDate.getDate().toString()).slice(-2)
    const month = ("0" + (newDate.getMonth() + 1).toString()).slice(-2)
    const year = newDate.getFullYear().toString()
    const dateString = [month, day, year].join('-')
    const [reviewInfo, setReviewInfo] = useState({
        coffeeId: coffee.id,
        roasterId: userContext.user ? userContext.user.id : null,
        date: dateString,
        score: 0,
        comment: null,
        isAdmin: false
    })
    const [paymentData, setPaymentData] = useState({
        farmly_fee: 0,
        logistics_fee: 0,
        import_duties: 0,
        import_duties_adjustment: 0,
        euro_value: 0
    })
    const [totalKgPrice, setTotalKgPrice] = useState("0.00")
    const priceKgEuro = toEuro(totalKgPrice, paymentData, coffee).toFixed(2)
    const [hasReview, setHasReview] = useState(false)

    const decreaseQuantity = () =>{
        if(sackQuantity>0){
            setSackQuantity(sackQuantity-1)
        }
    }
    const increaseQuantity = () =>{
        setSackQuantity(sackQuantity+1)
    }
    const renderNotes = () =>
         coffee.sensory_notes_en.split(',').map(note=>{
            return(
                <div className={(note.length < 7) ? 'sensory-red' : (((note.length < 10 && note.length > 6) ? 'sensory-lime' : 'sensory-orange'))}>{note}</div>
            )
        })
    useEffect(() => {
        getReviews()
    }, [auxReview])

    useEffect(() => {
        axios.get(`${server}/paymentData`)
            .then(res => setPaymentData(res.data))
    }, [])

    useEffect(() => {
        if(cartContext && cartContext.cartList) {
            cartContext.cartList.map(item => {
                if (item.id == coffee.id) {
                    setIsInCart(true)
                }
            })
    }
    }, [])

    useEffect(() => {
        if (coffee && paymentData) {
            const price = totalCoffeePrice({ ...coffee, quantity: sackQuantity }, paymentData)
            const kgPrice = totalCoffeePrice({ ...coffee, quantity: '1' }, paymentData)
            setTotalKgPrice(isNaN(kgPrice) ? "0.00" : (kgPrice / 60).toFixed(2))
        }
    }, [coffee, paymentData, sackQuantity])

    const getReviews = async () =>{
        await axios.get(`${server}/getReview/${coffee.id}`)
            .then(async res=>{
                setReviews(res.data)
            })
    }

    useEffect(() => {
        if (userContext.user) {
            axios.get(`${server}/searchReview/${coffee.id}/${userContext.user.id}`)
                .then(async review => {
                    if (review.data) {
                        setHasReview(true)
                    }
                })
        }
    }, [auxReview])

    const addToCart = () => {
        cartContext.addToCart(parseInt(coffee.id), sackQuantity)
        setIsInCart(true)
        setModalMessage("Coffee Added!")
        toggleModal()
    }

    const updateCoffeeOnCart = () => {
        cartContext.cartList.map(item => {
            if (item.id == coffee.id) {
                axios.patch(`${server}/coffeeSackRemain/${coffee}`, {
                    sackQuantity: sackQuantity - item.quantity,
                    sackRemain: coffee.sackRemain
                })
            }
        })
        cartContext.updateCoffeeOnCart(parseInt(coffee.id), sackQuantity)
        setModalMessage("Cart updated!")
        toggleModal()
    }


    const renderComments = () =>
        reviews.map(item=>{
            const names = item.roaster.split(" ")
            const initials = names[0][0]
            return(
                <div style={{marginRight: '1vw'}}>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <Avatar style={{backgroundColor: 'red'}}>{initials}</Avatar>
                        <p className="card-live-roaster-name">{item.roaster}</p>
                    </div>
                    <div>{item.comment}</div>
                    <br/>
                </div>
            )
        })
    const sendReview = async () =>{
        await axios.post(`${server}/createReview`, reviewInfo)
            .then(res=>{
                setAuxReview(!auxReview)
                setHasReview(true)
            })
    }

    const deleteReview = () => {
        axios.delete(`${server}/deleteReview/${coffee.id}/${userContext.user.id}`)
        setHasReview(false)
        setAuxReview(!auxReview)
    }

    useEffect(() => {
        if (coffee.imageUrl) {
            storage.ref('producers').child(coffee.imageUrl.split(',')[0]).getDownloadURL()
                .then(imgUrl => setCoffeeImageUrl(imgUrl))
                .catch(() => { })
        }
    }, [])

    useEffect(() => {
        if (coffee.cardImg) {
            storage.ref('producers').child(coffee.cardImg).getDownloadURL()
                .then(img => setCardImg(img))
                .catch(() => { })
        }
    }, [])

    useEffect(() => {
        axios.get(`${server}/producer/${coffee.producerId}`)
            .then(user => {
                if (user.data.farmImg) {
                    storage.ref('producers').child(user.data.farmImg.length > 1 ? user.data.farmImg.split(',')[0] : user.data.farmImg).getDownloadURL()
                        .then(imgUrl => setFarmImg(imgUrl))
                        .catch(() => { })
                }
            })
            .catch()
    }, [])
    return (
        <div className="live-card-container">
            <div className="second-container-live-card">
                <div className="third-container-live-card">
                    <img src={cardImg || coffeeImageUrl || farmImg || coffeePicture3} className="card-live-image"></img>
                    <div className="card-live-code">{coffee.farmCode}</div>
                    {(isBr() || isCo()) ?
                        <Fragment></Fragment>
                    :
                        <div className="card-live-add-button-container">
                        { (coffee.farmCode == 'C502') ?
                            <button className="card-live-add-button">Sold Out</button>

                        :
                        <>

                        {isInCart ? 
                            <button className="card-live-add-button" onClick={updateCoffeeOnCart}>Update Cart</button>
                        :
                            <button className="card-live-add-button" onClick={addToCart}>Add to Card</button>
                        }
                       
                        <div className="card-live-quantity-container">
                                    <button className="card-live-button-quantity" onClick={()=> decreaseQuantity()}>-</button>
                                    <input className="card-live-input-quantity" value={sackQuantity}></input>
                                    <button className="card-live-button-quantity" onClick={()=> increaseQuantity()}>+</button>
                                </div>
                                </>
                        }
                    </div>}

                </div>
                <div className="third-container-live-card middle-container-live-card">
                    <p className="card-live-producer-name">{coffee.producer}</p>
                    <div style={{display: 'flex', flexDirection: 'row', marginTop: '-5px'}}>
                        <div className="card-live-small-info">{true ? `€${totalKgPrice}`:`€${priceKgEuro}`}</div> {/*isBr()*/}
                        <div className="card-live-small-info">{coffee.score}pts</div>
                    </div>
                    <div className="card-live-more-info-container">
                        <div><b>{isBr() ?'Processo:': (isCo() ? 'Proceso:' : 'Process:')}</b> {coffee.process}</div>
                        <div><b>{isBr() ?'Variedade:':(isCo() ? 'Variedad:' : 'Variety:')}</b> {coffee.variety}</div>
                        <div><b>{isBr() ?'Região:':(isCo() ? 'Región:' : 'Region:')}</b> {coffee.region}</div>
                    </div>
                    <div className="card-live-sensory-container">
                        {coffee.sensory_notes_en != null &&
                            renderNotes()
                        }
                    </div>
                </div>
                <div className="third-container-live-card" style={{marginLeft: '1vw', marginRight: '2vw'}}>
                    <div className="card-live-comments-container">
                        {reviews.length ? renderComments()
                        :
                        (isBr() || isCo()) ? 
                        <div style={{marginLeft: '1vw'}}>
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <Avatar style={{backgroundColor: 'red'}}>F</Avatar>
                                <p className="card-live-roaster-name">FARMly</p>
                            </div>
                            <div>{isCo() ? 'Los tostadores dejarán aquí sus comentarios sobre este café.' : 'Os torrefadores deixarão comentários sobre esse café aqui!'}</div>
                            <br/>
                        </div>
                        :
                        <div style={{marginLeft: '1vw'}}>
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <Avatar style={{backgroundColor: 'red'}}>F</Avatar>
                                <p className="card-live-roaster-name">FARMly Bot</p>
                            </div>
                            <div>Leave your review of this coffee here!</div>
                            <br/>
                        </div>}
                    </div>
                    {(isBr() || isCo()) ? 
                    <Fragment></Fragment>
                    :
                    <div className="card-live-make-a-comment-container">
                        <input className="card-live-comment-input" placeholder="Make a comment about this coffee!" value={reviewInfo.comment} setValue={value => setReviewInfo({ ...reviewInfo, comment: value })} onChange={(event) => setReviewInfo({ ...reviewInfo, comment: event.target.value })}></input>
                        <div className="card-live-comment-buttons-container">
                            <button className="card-live-cancel-comment-button" onClick={()=>setReviewInfo({...reviewInfo, comment: ""})}>Cancel</button>
                            {hasReview?
                                <button className="card-live-send-comment-button" style={{width: '115px'}} onClick={()=>deleteReview()}>Delete Review</button>
                            :
                                <button className="card-live-send-comment-button" onClick={()=>sendReview()}>Send</button>
                            }


                        </div>
                    </div>
                    }
                </div>

            </div>
        </div>
    )
}
export default CuppingLiveCard
