import React, { useState, useContext, useEffect } from 'react'
import './CoffeesEmptySearch.css'

import { useHistory } from 'react-router-dom'
import UserContext from '../../context/user/UserContext'
import { isPt, server } from '../../common.js'
import axios from 'axios'

const CoffeesEmptySearch = props => {

    const { display, notProd } = props
    const history = useHistory()

    const coffeeEmptySearchMessage_pt = 
        notProd ? 'Em breve, você encontrará vários cafés maravilhosos aqui!' 
        : 'Ops, não conseguimos encontrar nenhum café com esta descrição!'
    const coffeeEmptySearchMessage_en = 
        notProd ? "Soon, you will find many great coffees here!"
        : "We couldn't find any coffees that match your search."
    const coffeeEmptySearchCall_pt = notProd ? 
    'Enquanto isso, cadastre-se ou entre em contato para te ajudarmos a procurar ou anunciar seu café especial!' 
    : 'Entre em contato para te ajudarmos a procurar ou anunciar seu café especial!'
    const coffeeEmptySearchCall_en = notProd ? 
    "In the meanwhile, get in touch so we can help you find your specialty coffee!" 
    : "Get in touch so we can help you find your specialty coffee!"

    return (
            <div className="empty-search-container" style={{ justifyContent: 'space-between', display: display ? 'flex' : 'none' }}>
                <div className="empty-divisory-img" alt="" />
                <div className="empty-search-text-container" style={{ color: 'var(--farmly-slateGray)' }}>
                    {isPt() ? coffeeEmptySearchMessage_pt : coffeeEmptySearchMessage_en}
                    <br /> <br />
                    {isPt() ? coffeeEmptySearchCall_pt : coffeeEmptySearchCall_en}
                    <br /> <br />
                    {(notProd && !isPt()) &&
                    <div className="empty-button" onClick={() => history.push('/start_here')}>
                        {isPt() ? "Entre Em Contato" : "Contact Us"}
                    </div>}
                </div>
                <div className="empty-divisory-img" alt="" />
        </div>
    )
}

export default CoffeesEmptySearch