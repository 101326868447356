import React, { useEffect, useState } from 'react'
import './RadarChart.css'
import { isPt } from '../../common'

//Chart scale: from 6 to 10, represented in the 4-10 interval.
//Scaled Score = (Score - 4) / 6

const RadarChartCuppingDesktop = props => {

    const pAttributes = props.attributes
    const [attributes, setAttributes] = useState(pAttributes)

    useEffect(() => {
        if (pAttributes) {
            const updAttr = [...pAttributes]
            updAttr.forEach((data, ind) => {
                Object.keys(data).forEach(key => {
                    if (!data[key]) updAttr[ind][key] = 6.0
                })
            })
            setAttributes(updAttr)
        }
    }, [pAttributes])

    const attributeNames = Object.keys(attributes[0])
    const attributesNorm = []

    attributes.forEach(col => {
        const normCol = {}
        attributeNames.forEach(attr => {
            normCol[attr] = (Math.min(10, Math.max(6, col[attr])) - 4) / 6
        })
        attributesNorm.push(normCol)
    })

    const chartSize = 350
    const nOfScales = 4
    const scales = []
    const chart = []

    const borderPath = points => {
        let d = 'M' + points[0][0].toFixed(4) + ',' + points[0][1].toFixed(4)
        for (let i = 1; i < points.length; i++) {
            d += 'L' + points[i][0].toFixed(4) + ',' + points[i][1].toFixed(4)
        }
        return d + 'z'
    }

    const polarToX = (angle, score) => Math.cos(angle - Math.PI / 2) * score
    const polarToY = (angle, score) => Math.sin(angle - Math.PI / 2) * score
    const columns = attributeNames.map((attr, index, attrList) => {
        return {
            key: attr,
            angle: (Math.PI * 2 * index) / attrList.length
        }
    })

    const straightScale = scaleIndex => (
        <path
            key={`scale-${scaleIndex}`}
            d={borderPath(
                columns.map(col => {
                    return [
                        polarToX(col.angle, (scaleIndex / nOfScales) * chartSize / 2),
                        polarToY(col.angle, (scaleIndex / nOfScales) * chartSize / 2)
                    ]
                })
            )}
            fill="rgba(240, 240, 240, 1)"
            stroke="rgb(20, 20, 20)"
            strokeWidth="0.8"
        />
    )

    for (let i = nOfScales; i > 0; i--)
        scales.push(straightScale(i))

    const axisPoints = points => {
        return points.map(point => point[0].toFixed(4) + ',' + point[1].toFixed(4)).join(' ')
    }

    const axis = () => (column, index) => (
        <polyline
            key={`chart-axis-${index}`}
            points={axisPoints([
                [0, 0],
                [polarToX(column.angle, chartSize / 2), polarToY(column.angle, chartSize / 2)]
            ])}
            stroke='rgb(20, 20, 20)'
            strokeWidth='0.8'
        />
    )

    const shape = columns => (item, index) => {

        return (
            <path
                key={`shape-${index}`}
                d={borderPath(
                    columns.map(col => {
                        const score = attributesNorm[0][col.key]
                        return [
                            polarToX(col.angle, score * chartSize / 2),
                            polarToY(col.angle, score * chartSize / 2)
                        ]
                    })
                )}
                stroke={'var(--farmly-pancho)'}
                strokeWidth={2}
                fill={'var(--farmly-pancho)'}
                fillOpacity={0.5}
            />
        )
    }

    const captions = () => col => {
        const offsets = {
            Finalização: {
                name: isPt() ? "Finalz." : "After Taste",
                x: "-17.5%",
                y: "8%"
            },
            Aroma: {
                name: "Aroma",
                x: "6%",
                y: "-4.8%"
            },
            Acidez: {
                name: isPt() ? "Acidez" : "Acidity",
                x: "-8%",
                y: "-7%"
            },
            Equilibrio: {
                name: isPt() ? "Equilíbrio" : "Balance",
                x: "-10%",
                y: "7.8%"
            },
            Corpo: {
                name: isPt() ? "Corpo" : "Body",
                x: "6%",
                y: "8%"
            },
            Sabor: {
                name: isPt() ? "Sabor" : "Flavor",
                x: "-17.5%",
                y: "-4.8%"
            },
        }

        return (
            <text
                key={`radar-caption-${col.key}`}
                x={polarToX(col.angle, (chartSize / 2) * 0.80).toFixed(4)}
                y={polarToY(col.angle, (chartSize / 2) * 0.90).toFixed(4)}
                dy={offsets[col.key].y}
                dx={offsets[col.key].x}
                fill="rgb(46, 124, 121)"
                fontSize="1.1em"
                fontWeight="500"
                padding='5px'
            >
                {offsets[col.key].name}
                <br />

            </text>
        )
    }

    const scoreCaptions = () => (col, index) => {
        const offsets = {
            Finalização: {
                x: "0%",
                y: "0%"
            },
            Aroma: {
                x: "0%",
                y: "0%"
            },
            Acidez: {
                x: "0%",
                y: "0%"
            },
            Equilibrio: {
                x: "0%",
                y: "0%"
            },
            Corpo: {
                x: "-2%",
                y: "1.5%"
            },
            Sabor: {
                x: "-1%",
                y: "-1%"
            },
        }

        return (
            <text
                key={`score-caption-${col.key}`}
                x={polarToX(col.angle, (chartSize / 1.9) * attributesNorm[0][col.key]).toFixed(4)}
                y={polarToY(col.angle, (chartSize / 1.9) * attributesNorm[0][col.key]).toFixed(4)}
                dy={offsets[col.key].y}
                dx={offsets[col.key].x}
                fill="rgb(10, 10, 10)"
                fontSize="1.1em"
                fontWeight="600"
            >
                {attributes[0][col.key]}
            </text>
        )
    }

    chart.push(<g key="chart-scales">{scales}</g>)
    chart.push(<g key="chart-axes">{columns.map(axis())}</g>)
    chart.push(<g key="chart-scores">{attributes.map(shape(columns))}</g>)
    chart.push(<g key="chart-captions">{columns.map(captions())}</g>)
    //chart.push(<g key="score-captions">{columns.map(scoreCaptions())}</g>)

    return (
        <React.Fragment>
            <svg xmlns="http://www.w3.org/2000/svg" fill="red" style={window.screen.width > 1100 ? { maxHeight: '12vw' } : { maxHeight: '16vw' }}
                viewBox={`-20, -25, ${chartSize + 60}, ${chartSize + 40}`}>
                <g transform={`translate(${chartSize / 2 + 1},${chartSize / 2 + 1})`} >
                    {chart}
                </g>
            </svg>
        </React.Fragment>
    )
}

export default RadarChartCuppingDesktop