import React, { useState, useEffect, useContext } from 'react'
import './Help.css'

import Main from '../templates/Main.jsx'
import FarmlyInput from '../templates/FarmlyInput.jsx'
import UserContext from '../../context/user/UserContext'
import FarmlyModal from '../templates/FarmlyModal.jsx'
import { useHistory } from 'react-router-dom'

import axios from 'axios'
import { server, farmlyTexts, isPt, isCo, language } from '../../common.js'

const Help = props => {

    const titleHeaderLanguage = {'pt':'Ajuda','en':'Help','es':'Ayuda'}[language]


    const userContext = useContext(UserContext)
    const history = useHistory()

    useEffect(() => {
        if(userContext.user) {
            axios.post(`${server}/users`, {
                jwt: userContext.user.jwt
            })
            .then(user => {
                const { name, email } = user.data
                setUser({ name, email })
            })
            .catch(err => {
                console.log(err)
            })
        }
    }, [])

    const [user, setUser] = useState({
        name: "",
        email: ""
    })
    const [message, setMessage] = useState("")
    const [status, setStatus] = useState("unsent")

    const sendHelpRequest = () => {
        setStatus(isPt() ? farmlyTexts.helpWait_pt : (isCo() ? 'Espera un momento...' : farmlyTexts.helpWait_en))
        axios.post(`${server}/help`, {
            name: user.name,
            email: user.email,
            message
        })
        .then(res => {
            setStatus(isPt() ? farmlyTexts.helpSuccess_pt : (isCo() ? '¡Tu mensaje fue enviado con éxito!' : farmlyTexts.helpSuccess_en))
        })
        .catch(err => setStatus(isPt() ? farmlyTexts.helpError_pt : (isCo() ? 'Algo salió mal... Por favor, espere unos minutos e inténtelo de nuevo!' : farmlyTexts.helpError_en)))
    }

    const closeModal = () => {
        history.push({
            pathname: "/"
        })
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <Main titleHeader={titleHeaderLanguage}>
            { status !== "unsent" &&
                <FarmlyModal closeModal={closeModal}>
                    {status}
                </FarmlyModal>
            }
            <div className="help-screen">
                <label className="help-title">
                    {isPt() ? farmlyTexts.helpTitle_pt : (isCo() ? '¿Tienes alguna pregunta o sugerencia? Envíenos un mensaje!' : farmlyTexts.helpTitle_en)}
                </label>
                <div className="help-row">
                    <label htmlFor="help-name" className="help-label">{isPt() ? farmlyTexts.helpName_pt : (isCo() ? 'Nombre' : farmlyTexts.helpName_en)}</label>
                    <FarmlyInput value={user.name} setValue={name => setUser({ ...user, name })}
                        elementId="help-name" styleClass="help-input" />
                </div>
                <div className="help-row">
                    <label htmlFor="help-email" className="help-label">{isPt() ? farmlyTexts.helpEmail_pt : farmlyTexts.helpEmail_en}</label>
                    <FarmlyInput value={user.email} setValue={email => setUser({ ...user, email })}
                        elementId="help-email" styleClass="help-input" />
                </div>
                <div className="help-row">
                    <label htmlFor="help-message" className="help-label">{(isPt() || isCo()) ? farmlyTexts.helpMessage_pt : (isCo() ? 'Mensaje' : farmlyTexts.helpMessage_en)}</label>
                    <FarmlyInput value={message} setValue={message => setMessage(message)}
                        elementId="help-message" styleClass="help-paragraph-input" textArea />
                </div>
                <div className="help-send" onClick={sendHelpRequest}>{isPt() ? farmlyTexts.helpSend_pt : (isCo() ? 'Enviaré' : farmlyTexts.helpSend_en)}</div>
            </div>
        </Main>
    )
}

export default Help