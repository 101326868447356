import styled from 'styled-components';

export const SliderContainer = styled.div`
    // width: "90vw";
    // max-width: "400",
    display: flex;
    flex-direction: row;
`
export const NextArrowBackGround = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: -25px;
    width: 30px;
    height: 30px;
    top: 50%;
    border-radius: 50%;
    background-color: #1F625E;
    cursor: pointer;
`
export const PrevArrowBackGround = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 30px;
    left: -27px;
    height: 30px;
    top: 50%;
    border-radius: 50%;
    background-color: #1F625E;
    cursor: pointer;
`
export const SlickDots = styled.li`
    margin: 0 !important;
    max-width: 500px
`


export const SamplesContainer = styled.div`
    max-width: 45vw;
`
