import React, { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { isPt, isCo } from '../../common'
import './NewFooter.css'
import UserContext from 'context/user/UserContext'


const NewFooter = props => {

    const userContext = useContext(UserContext)

    return (
        <>
            <div className="new-wave-group-separator" style={props.noMargin ? {marginTop: '-3vw'} : {}}></div>

            <div className="footer">
                <div className="footer-item">
                    <a rel="noopener noreferrer" className="footer-icons" target="_blank" href={'https://www.youtube.com/channel/UCCeXFqzhnAvjXbSMxkdRCMw'}>
                        <FontAwesomeIcon icon={faYoutube} />
                    </a>
                    <p>Youtube</p>
                </div>
                <div className="footer-item">
                    <a rel="noopener noreferrer" className="footer-icons" target="_blank" href={'https://www.instagram.com/farm_ly/'}>
                        <FontAwesomeIcon icon={faInstagram} />
                    </a>
                    <p>@farm_ly</p>
                </div>
                <div className="footer-item">
                    <a rel="noopener noreferrer" className="footer-icons" target="_blank" href={isCo() ? `https://api.whatsapp.com/send?phone=5531993173932` : (isPt() ? (userContext.user ? `https://api.whatsapp.com/send?phone=+553384054614` : `https://api.whatsapp.com/send?phone=+558888584345`) : `https://wa.me/message/OV4X674MP6R2P1` )}>
                        <FontAwesomeIcon icon={faWhatsapp} />
                    </a>
                    <p>Whatsapp</p>
                </div>
                <div className="footer-copy">© 2022 All rights reserved.</div>
            </div>
        </>
    )
}

export default NewFooter
