import React from 'react';
import { useState } from 'react';
import ButtonBase from '@material-ui/core/ButtonBase';

import FarmlyInput from "../templates/FarmlyInput";

import "../EditInformations/EditInformations.css"
import { language } from '../../common';

const EditPassword = (props) => {

    const [data, setData] = useState({
        oldPassword: "",
        password: ""
    });

    const { submitNewPassword } = props;

    return (
        <div className="new-profile profile-information-edit-modal">
            <h3>{{ pt: 'Edição de Informações', es: 'Edición de información', en: "Information edition" }[language]}</h3>
            <div className="new-profile profile-information-edit-form" style={{ height: 'auto' }}>
                <h4>{{ pt: 'Alteração de senha', es: 'Cambio de contraseña', en: "Change password" }[language]}</h4>
                <div className="new-profile profile-information-edit-grid">
                    <div className="new-profile profile-information-edit-field">
                        <label htmlFor="profile-old-password" className="user-send-sample-input-label">{{ pt: 'Senha Antiga', es: 'Contraseña antigua', en: "Old password" }[language]}</label>
                        <FarmlyInput type='password' styleClass="new-profile user-send-sample-input" elementId="profile-old-password" placeholder={{ pt: 'Senha Antiga', es: 'Contraseña antigua', en: "Old password" }[language]}
                            value={data.oldPassword} setValue={value => setData({ ...data, oldPassword: value })}
                        />
                    </div>
                    <div className="new-profile profile-information-edit-field">
                        <label htmlFor="profile-new-password" className="user-send-sample-input-label">{{ pt: 'Nova senha', es: 'Nueva contraseña', en: "New password" }[language]}</label>
                        <FarmlyInput type='password' styleClass="new-profile user-send-sample-input" elementId="profile-new-password" placeholder={{ pt: 'Nova senha', es: 'Nueva contraseña', en: "New password" }[language]}
                            value={data.password} setValue={value => setData({ ...data, password: value })}
                        />
                    </div>
                </div>
            </div>
            <div className='profile-information-edit-actions'>
                <ButtonBase className="new-farmly-button f-button-orange" onClick={() => submitNewPassword(data)}>{{ pt: 'Confirmar edições', es: 'Confirmar ediciones', en: "Confirm edits" }[language]}</ButtonBase>
            </div>
        </div>
    );
}

export default EditPassword;