import React, { FunctionComponent } from 'react'
import { NewCartButton } from './style';
import CartModel from 'modules/SampleCart/models/cartModel';

interface CreateNewCartButtonProps {
    sampleCart: CartModel
}

const CreateNewCartButton: FunctionComponent<CreateNewCartButtonProps> = (props) => {
    return (
        <>
            {props.sampleCart.sample_list.length !== 0 ?
                <NewCartButton onClick={(e) => { window.location.reload(); }}>
                    Criar novo carrinho
                </NewCartButton>
                :
                <></>
            }
        </>
    );
}
export default CreateNewCartButton;

