import React, { useState, useEffect } from 'react';
import orderByTheDate from "../../../../../utils/orderByDate";
import backend from "../../../../../lib/backend"
import { server } from 'common';

const useSampleCarts = () => {
    const [sampleCartList, setSampleCartList] = useState([])
    const [searchedCoffee, setSearchedCoffee] = useState({});
    const [loadingCart, setLoadingCart] = useState(false);
    const [updateDate, setUpdateDate] = useState(false);
    const [loadingList, setLoadingList] = useState(false);
    const [alreadyIn, setAlreadyIn] = useState(false);
    const [changesHappen, setChangesHappen] = useState(false);
    const [query, setQuery] = useState('');

    const [sampleCart, setSampleCart] = useState({
        id: null,
        sample_list: [],
        list_link: "",
        cart_name: "",
        expires_at: null,
        country: "",
        postalCode: "",
        roastery: "",
        address: "",
        name: "",
        phoneNumber: "",
        vat: ""
    })

    const [selectedSamplesList, setSelectedSamplesList] = useState({
        acidity: "",
        aftertaste: "",
        aroma: "",
        balance: "",
        batch: "",
        body: "",
        cardImg: "" | null,
        created_at: new Date(),
        cupping_done: false | null,
        description: "" | null,
        descriptionEn: "" | null,
        description_checked: false | null, 
        display: false | null,
        earnings: 0.00 | null,
        euro_sack_value: 0.00 | null,
        euro_value: 0.00 | null,
        farmCode: "" | null,
        farmName: "" | null,
        farmly_fee: 0.00 | null,
        farmly_fee_euro: 0.00 | null,
        flavour: "" | null,
        general_comment: "" | null,
        harvestDate: "" | null,
        height: "" | null,
        id: 0,
        imageUrl: "" | null,
        import_duties: 0.00 | null,
        import_duties_adjustment: 0.00 | null,
        import_duties_euro: 0.00 | null,
        is_applied_taxes: false | null,
        kg_price: "" | null,
        latitude: 0.00,
        logistics_fee: 0.00,
        logistics_fee_euro: 0,
        longitude: 0.00,
        old_display: false,
        origin: "",
        overall: "",
        packaging: "",
        process: "",
        producer: "",
        producerId: 0,
        region: "",
        regionId: 0,
        sackPrice: "" | null,
        sackRemain: "" | null,
        sampleApprove: "",
        sampleMessage: "",
        sampleReceivedDate: "",
        sampleSentDate: "",
        sampleTastedDate: "",
        score: 0.00,
        section: 0,
        sensoryNotes: "",
        sensory_notes_en: "",
        shipment: "",
        showCoffee: true,
        sieve: "",
        storage: "",
        sugested_price: "",
        total_sample_g:0, 
        variety: ""

    })

    const getAllSampleCarts = () => {
        setLoadingList(true);
        try {
            backend
                .get('/sampleCart')
                .then((queryResult) => {
                    setSampleCartList(queryResult.data.sort((a, b) => orderByTheDate(a.created_at, b.created_at)));
                })
                .catch((queryError) => {
                    setSampleCartList([]);
                });
        }
        catch (error) {
            console.log(error)
        }
        finally {
            setLoadingList(false);
        }
    };
    useEffect(() => {
        getAllSampleCarts()
    }, []);

    const getSampleCart = (id) => {
        setLoadingCart(true)
        backend
            .get('/sampleCart/withFullCoffeeList/' + id)
            .then(queryResult => {
                const newSampleCart = {
                    sample_list: queryResult.data,
                    list_link: queryResult.data[0].list_link,
                    cart_name: queryResult.data[0].cart_name,
                    id: queryResult.data[0].sampleCartId,
                    expires_at: queryResult.data[0].expires_at === null ? null : new Date(queryResult.data[0].expires_at),
                }
                backend.get('/sampleCart/sampleCartWithRoasterData/' + id)
                    .then(roasterReturn => {
                        const newSampleCartWithRoasterData = {
                            ...newSampleCart,
                            name: roasterReturn.data[0].name,
                            address: roasterReturn.data[0].address,
                            phoneNumber: roasterReturn.data[0].phoneNumber,
                            postalCode: roasterReturn.data[0].postalCode,
                            country: roasterReturn.data[0].country,
                            roastery: roasterReturn.data[0].roastery,
                            vat: roasterReturn.data[0].vat === null ? null : roasterReturn.data[0].vat
                        }
                        setSampleCart(newSampleCartWithRoasterData)
                    })
            })
            .catch(queryError => {
                setSampleCart({})
            })
            .finally(() => {
                setLoadingCart(false)
            })
    }

    const createSampleCart = () => {
        setLoadingCart(true)
        const expireDate = document.getElementById("sample-cart-date").value;
        if (sampleCart.id === null && sampleCart.sample_list.length > 0) {
            backend.post('/sampleCart/createSampleCart',
                {
                    sampleList: sampleCart.sample_list,
                    expireDate: expireDate,
                    cart_name: sampleCart.cart_name,
                    name: sampleCart.name,
                    address: sampleCart.address,
                    phoneNumber: sampleCart.phoneNumber,
                    postalCode: sampleCart.postalCode,
                    country: sampleCart.country,
                    roastery: sampleCart.roastery,
                    vat: sampleCart.vat,
                })
                .then(res => {
                    getAllSampleCarts();
                    let newSampleCart = resetSampleCartInfo();
                    newSampleCart.id = res.data.sampleCart.id;
                    newSampleCart.list_link = res.data.sampleCart.list_link;
                    newSampleCart.cart_name = res.data.sampleCart.cart_name;
                    newSampleCart.expires_at = res.data.sampleCart.expires_at === null ? null : new Date(res.data.sampleCart.expires_at)
                    newSampleCart.name = res.data.sampleCart.name;
                    newSampleCart.address = res.data.sampleCart.address;
                    newSampleCart.phoneNumber = res.data.sampleCart.phoneNumber;
                    newSampleCart.postalCode = res.data.sampleCart.postalCode;
                    newSampleCart.country = res.data.sampleCart.country;
                    newSampleCart.roastery = res.data.sampleCart.roastery;
                    newSampleCart.vat = res.data.sampleCart.vat === null ? null : res.data.sampleCart.vat;
                    // newSampleCart.expires_at = === null ? createResponse.data.expires_at;
                    setSampleCart(newSampleCart)
                    alert('Carrinho criado com sucesso!')
                })
                .catch(err => {
                    alert('Acontenceu um erro!')
                    console.log(err)
                })
                .finally(() => {
                    setLoadingCart(false)
                })
        }
    }

    const resetSampleCartInfo = (hard = false) => {
        setUpdateDate(false)
        const newSampleCart = { ...sampleCart };
        newSampleCart.expires_at = null;
        newSampleCart.id = null;
        newSampleCart.list_link = null;
        newSampleCart.cart_name = null;
        newSampleCart.name = null;
        newSampleCart.address = null;
        newSampleCart.phoneNumber = null;
        newSampleCart.postalCode = null;
        newSampleCart.country = null;
        newSampleCart.roastery = null;
        newSampleCart.vat = null;
        if (hard) newSampleCart.sample_list = [];
        return newSampleCart
    }

    const addToSampleCart = () => {
        const newSampleCart = resetSampleCartInfo();
        if (!searchedCoffeeInSampleList()) {
            newSampleCart.sample_list.push(searchedCoffee)
            setSampleCart(newSampleCart)
            setAlreadyIn(false)

        } else {
            setAlreadyIn(true)
        }
    }

    const removeFromSampleCart = (removeCoffee) => {
        const newSampleCart = resetSampleCartInfo()
        newSampleCart.sample_list = getCoffeeSamples().filter(coffee => coffee.id !== removeCoffee.id)
        setSampleCart(newSampleCart)
        return newSampleCart.sample_list
    }

    const deleteSampleCart = (id) => {
        setLoadingCart(true)
        backend.delete(`/sampleCart/${id}`)
            .then(deleteResponse => {
                if (deleteResponse.data[0] === sampleCart.id) {
                    const newSampleCart = resetSampleCartInfo(true);
                    setSampleCart(newSampleCart)
                }
                getAllSampleCarts()
            })
            .catch(deleteError => {
                console.log(deleteError)
            })
            .finally(() => {
                setLoadingCart(false);
            })
    }

    const searchedCoffeeInSampleList = () => {
        if(sampleCart.sample_list.length === 0){
            return false;

        }
        let isInList = false
        const samples = sampleCart.sample_list
        const samplesNumber = samples.length
        let selectedSamples = []
        for(let i = 0; i < samplesNumber; i++){
            if(samples[i][0].id === searchedCoffee[0].id){
                isInList = true
                break;            
            }
            setSelectedSamplesList(selectedSamples.push(samples[i][0]))

        }
        return isInList

    }
    
    const getCoffeeSamples = () => {
    let samples = sampleCart.sample_list
    const samplesNumber = samples.length
    let selectedSamples = []
    for(let i = 0; i < samplesNumber; i++){
        if(samples[i][0]){
            selectedSamples.push(samples[i][0])
        } else {
            selectedSamples.push(samples[i])
        }
    }
        return selectedSamples
    }

    const updateExpireDate = async () => {
        setLoadingCart(true)
        const expireDate = document.getElementById("sample-cart-date").value;
        try {
           const update = await (await backend.patch(`${server}/samplecart/updateExpireDate`, { id: sampleCart.id, expireDate: expireDate })).data
            alert('Data alterada com sucesso')
            getSampleCart(update)
          }
          catch(error) {
            alert('Acontenceu um erro');
            console.log(error)
          } finally {
              setLoadingCart(true);
        }
    }

    const searchCoffees = () => {
        
        backend.get(`/coffeeByCode/${query.toUpperCase()}`)
            .then(queryResult => {
                setSearchedCoffee(queryResult.data)
                const samples = sampleCart.sample_list
                const samplesNumber = samples.length
                let selectedSamples
                for(let i = 0; i < samplesNumber; i++){
                    if(samples[i][0].id === queryResult.data[0].id){
                        setAlreadyIn(true)
                        break;            
                    } else if(i === samplesNumber - 1) {
                        setAlreadyIn(false)
                    }
                    
                    // setSelectedSamplesList(selectedSamples.push(samples[i][i]))
                }
            })
            .catch(queryError => {
                setSearchedCoffee({})
                setAlreadyIn(false)
            })
    }
    return {
        sampleCartList, setSampleCartList, searchedCoffee, setSearchedCoffee, loadingCart,
        setLoadingCart, updateDate, setUpdateDate, loadingList, setLoadingList, alreadyIn, setAlreadyIn,
        changesHappen, setChangesHappen, query, setQuery, sampleCart, setSampleCart, getAllSampleCarts,
        getSampleCart, createSampleCart, resetSampleCartInfo, addToSampleCart, removeFromSampleCart,
        deleteSampleCart, searchedCoffeeInSampleList, updateExpireDate, searchCoffees, selectedSamplesList, setSelectedSamplesList
    }
}

export default useSampleCarts;