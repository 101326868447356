import React from "react";
import { LegendsLine, LegendsTitle } from "./styles";

export default function ShipmentsLegends() {

  return (
    <LegendsLine>
        <LegendsTitle> Embarque </LegendsTitle>
        <LegendsTitle> Exibição na Offerlist </LegendsTitle>
        <LegendsTitle>{" "}</LegendsTitle>
    </LegendsLine>
  );
}
