import React, { FunctionComponent } from 'react'
import CoffeeModel from "../../../models/coffeeModel";
import { StatusCodeCoffeeLabel } from './style';

interface CoffeeResultDisplayProps {
    searchedCoffee: CoffeeModel[]
    alreadyIn: boolean
}

const CoffeeResultDisplay: FunctionComponent<CoffeeResultDisplayProps> = (props) => {
    const coffeeFound: CoffeeModel[] = props.searchedCoffee;
    return (
        <>
            <StatusCodeCoffeeLabel>
                {Object.keys(coffeeFound).length === 0 ? "Nenhum café encontrado" :
                    !props.alreadyIn ? `Café ${coffeeFound[coffeeFound.length - 1].farmCode} encontrado!` :
                        `Café ${coffeeFound[coffeeFound.length - 1].farmCode} já presente`}
            </StatusCodeCoffeeLabel>
        </>
    );
}
export default CoffeeResultDisplay;