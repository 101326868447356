import React, { useContext, useState, useEffect } from 'react'

import FarmlyModal from '../templates/FarmlyModal.jsx'
import FarmlyInput from '../templates/FarmlyInput.jsx'
import PricingInfo from '../templates/PricingInfo.jsx'
import CartContext from '../../context/cart/CartContext'
import { isPt, farmlyTexts, server } from '../../common.js'
import { storage } from '../../firebase'
import { Link } from 'react-router-dom'
import axios from 'axios'

const producerImage = require('../../assets/producer.jpg')

const CoffeeItem = props => {

    const { id, farmCode, removeFromCart, producer, price, quantity, producerFee,
        setQuantity, sacksLeft, brazil } = props
    const { farmly_fee, logistics_fee, import_duties } = props.paymentData
    const cartContext = useContext(CartContext)

    const [showPricingInfo, setShowPricingInfo] = useState(false)
    const [coffeeImgUrl, setCoffeeImageUrl] = useState("")

    const increaseQuantity = () => {
        if (quantity < sacksLeft) setQuantity(parseInt(quantity) + 1, id)
    }

    const decreaseQuantity = () => {
        if (quantity > 0) {
            if(parseInt(quantity) - 1 <= 0) {
                removeCoffeeFromCart(id);
            }
            else{
                setQuantity(parseInt(quantity) - 1, id)}
            }
    }

    const removeCoffeeFromCart = (id) => {
        // axios.patch(`${server}/coffeeSackRemain/${id}`, { // ATUALIZA O BANCO DE DADOS, EM RELACAO AO NUMERO DE SACAS
        //     sackQuantity: -quantity,
        //     sackRemain: sacksLeft
        // })
        removeFromCart(id)
        cartContext.setControlTimer({ control : true, zero: true })
    }

    useEffect(() => {
        axios.get(`${server}/coffeeProdImg/${id}`)
            .then(res => {
                const farmImg = res.data.farmImg || ""
                //GAMBIARRA PARA O INACIO! TIRAR QUANDO RESOLVER OS CROPS!
                const refUrl = props.producerId == 61
                    ? farmImg.split(',')[((props.coffeeId % 69) + 2) % 4]
                    : res.data.profileImg
                if (refUrl) {
                    storage.ref('producers').child(refUrl).getDownloadURL()
                        .then(imgUrl => setCoffeeImageUrl(imgUrl))
                        .catch(() => { })
                }
            })
    }, [])

    return (
        <div className="cart-coffee-item">
            {showPricingInfo &&
                <FarmlyModal closeModal={() => setShowPricingInfo(false)}>
                    {/*<PricingInfo producerFee={producerFee*6/7} logistics_fee={logistics_fee*6/7}
                        import_duties={import_duties*6/7} farmly_fee={farmly_fee*6/7} />*/}
                     <PricingInfo producerFee={producerFee/quantity} logistics_fee={logistics_fee/quantity}
                        import_duties={import_duties/quantity} farmly_fee={farmly_fee/quantity} brazil={brazil} /> 
                </FarmlyModal>}
            <div className="cart-item-remove-container">
                <div className="cart-item-remove" onClick={() => { removeCoffeeFromCart(id) }} >X</div>
            </div>
            <Link to={`/coffee/${id}`}>
                <img src={coffeeImgUrl || producerImage} className="cart-producer-img" alt="" />
            </Link>
            <div className="cart-coffee-info-container">
                <label className="cart-column-title cart-column-title-mobile">
                    {isPt() ? farmlyTexts.cartProduct_pt : farmlyTexts.cartProduct_en}:
                </label>
                <div style={{flexDirection:'column'}}>
                    <Link to={`/coffee/${id}`} className="cart-item-product">{producer}</Link>
                    <Link to={`/coffee/${id}`} className="cart-item-product">{farmCode}</Link>
                </div>
            </div>
            <div className="cart-coffee-info-container">
                <label className="cart-column-title cart-column-title-mobile">
                    {isPt() ? farmlyTexts.cartPrice_pt : farmlyTexts.cartPrice_en}:
                </label>
                <label className="cart-item-price">€{price.toFixed(2)}</label>
            </div>
            <div className="cart-coffee-info-container">
                <label className="cart-column-title cart-column-title-mobile">
                    {isPt() ? farmlyTexts.cartQuantity_pt : farmlyTexts.cartQuantity_en}
                </label>
                <div className="cart-item-quantity">
                    <div className="cart-quantity-minus" onClick={decreaseQuantity}>-</div>
                    <FarmlyInput value={Math.min(quantity, sacksLeft)} setValue={value => setQuantity(value, id)}
                        styleClass="cart-quantity-display" upperBound={sacksLeft} type="numeric" />
                    <div className="cart-quantity-plus" onClick={increaseQuantity}>+</div>
                </div>

            </div>
            <div className="cart-coffee-info-container">
                <label className="cart-column-title cart-column-title-mobile">
                    {isPt() ? farmlyTexts.cartProducer_pt : farmlyTexts.cartProducer_en}:
                </label>
                <label className="cart-item-producer">€{producerFee.toFixed(2)}</label>
                <div className="cart-item-pricing-details" onClick={() => setShowPricingInfo(true)}>
                    i
                </div>
            </div>
        </div>
    )
}

export default CoffeeItem