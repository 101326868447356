import React, { useState, useEffect } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { farmlyColors } from '../../common.js'
import { Link } from 'react-router-dom'
import { storage } from '../../firebase'

import './ControlPanel.css'
import { server, acceptedCookies } from '../../common.js'
import axios from 'axios'
const footerArt = require('../../assets/footer-art.png')
const avatarPlaceholder = require('../../assets/avatar.png')

const RequestCard = props => {

    const { request } = props
    const [coffee, setCoffee] = useState()
    const [roaster, setRoaster] = useState()

    useEffect(() => {
        axios.get(`${server}/coffee/${request.coffee_id}`)
            .then(res => {
                setCoffee(res.data)
            })

        axios.get(`${server}/roaster/${request.roaster_id}`)
            .then(res => {
                setRoaster(res.data)
            })
    }, [])

    return (
        <>
            <div className="request-card-container">
                <div className="request-each-card">
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <label className="user-card-label" style={{ paddingRight: '6px' }}>Data do pedido:</label>
                        <label className="user-card-info">{request.date}</label>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <label className="user-card-label" style={{ paddingRight: '6px' }}>Café:</label>
                        <label className="user-card-info">{coffee && coffee.id}</label>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <label className="user-card-label" style={{ paddingRight: '6px' }}>Produtor:</label>
                        <label className="user-card-info">{coffee && coffee.producer}</label>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="user-card-label" style={{ paddingRight: '6px' }}>Torrefador:</label>
                            <label className="user-card-info">{roaster && roaster.name}</label>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="user-card-label" style={{ paddingRight: '6px' }}>Telefone:</label>
                            <label className="user-card-info">{roaster && roaster.phoneNumber}</label>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <label className="user-card-label" style={{ paddingRight: '6px' }}>Email:</label>
                        <label className="user-card-info">{roaster && roaster.email}</label>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="user-card-label" style={{ paddingRight: '6px' }}>País:</label>
                            <label className="user-card-info">{roaster && roaster.country}</label>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="user-card-label" style={{ paddingRight: '6px' }}>Postal Code:</label>
                            <label className="user-card-info">{roaster && roaster.postalCode}</label>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <label className="user-card-label" style={{ paddingRight: '6px' }}>Endereço:</label>
                        <label className="user-card-info">{roaster && roaster.address}</label>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <label className="user-card-label" style={{ paddingRight: '6px' }}>Quantidade:</label>
                        <label className="user-card-info">{request.sample_weight}</label>
                    </div>
                </div>
            </div >
        </>
    )

}

export default RequestCard