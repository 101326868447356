import React, { useState } from 'react'
import './SampleStatusDisplay.css'

import Title from '../../templates/Title.jsx'
import FarmlyModal from '../../templates/FarmlyModal.jsx'
import { server, isCo } from '../../../common.js'
import axios from 'axios'

const pickup = require('../../../assets/Icons/FlatIcon/GoodWare/pickup-truck.svg')
const pickupGreen = require('../../../assets/Icons/FlatIcon/GoodWare/pickup-truck-green.svg')
const cup = require('../../../assets/Icons/FlatIcon/Freepik/coffee-cup.svg')
const cupGreen = require('../../../assets/Icons/FlatIcon/Freepik/coffee-cup-green.svg')
const store = require('../../../assets/Icons/FlatIcon/Freepik/mobile-store.svg')
const storeGreen = require('../../../assets/Icons/FlatIcon/Freepik/mobile-store-green.svg')
const mountain = require('../../../assets/Icons/FlatIcon/Freepik/mountain.svg')
const mountainGreen = require('../../../assets/Icons/FlatIcon/Freepik/mountain-green.svg')

const SampleStatusDisplay = props => {

    const { sampleStep, back, sampleMessage, coffeeId } = props
    const { sampleSentDate, sampleReceivedDate, sampleTastedDate } = props.sampleStatus

    const [confirmUndo, setConfirmUndo] = useState(false)

    const iconElement = (on, off, active) => {
        if (active) return (
            <div className="sample-icon-on">
                {on}
            </div>
        )
        else return (
            <div className="sample-icon-off">
                {off}
            </div>
        )
    }

    const pathColorClass = (step, onAt) => {
        return "sample-icons-path " +
            (step >= onAt - 1 ?
                (step >= onAt ?
                    "sample-active-path" :
                    "sample-en-route")
                : "")
    }

    const dateReFormat = date => {
        const splits = date.split('-')
        return splits[2] + '/' + splits[1] + '/' + splits[0]
    }

    const undoSendSample = id => {
        axios.post(`${server}/sample`, {
            id,
            sampleSentDate: 'not-sent'
        })
        .then(_ => {
            window.location.reload()
        })
        .catch(err => console.log(err))
    }

    return (
        <div className="sample-status-display">
            {confirmUndo &&
                <FarmlyModal closeModal={() => setConfirmUndo(false)}>
                    <div className="unsend-sample-modal">
                        <label>{isCo() ? '¿Estás seguro de que no enviaste esta muestra?' : 'Tem certeza que não enviou esta amostra?'}</label>
                        <div className="unsend-sample-button-modal" onClick={() => undoSendSample(coffeeId)}>
                            {isCo() ? 'No envié' : 'Não enviei'}
                        </div>
                    </div>
                </FarmlyModal>}
            <div className="samples-title-container">
                <Title title={isCo() ? 'Siga el envío de sus muestras' : "Acompanhe o envio de suas amostras!"} styleClass="sample-status-title" />
                <div className="sample-go-back" onClick={back}>{isCo() ? 'Volver' : 'Voltar'}</div>
            </div>
            <div className="sample-status-content">
                <div className="sample-status-icons">
                    {iconElement(
                        <img className="sample-status-icon" src={mountainGreen} alt="farm" />,
                        <img className="sample-status-icon" src={mountain} alt="farm" />,
                        sampleStep >= 0
                    )}
                    <div className="path-container">
                        <div className={pathColorClass(sampleStep, 1)} />
                        {sampleStep >= 1 &&
                            <>
                                <label className="sample-dates">
                                    {isCo() ? 'Enviado el' : 'Enviado em'}
                                {" " + dateReFormat(sampleSentDate)}
                                </label>
                                {sampleReceivedDate === 'not-received' &&
                                <div className="unsend-sample-button" onClick={() => setConfirmUndo(true)}>
                                    {isCo() ? '¿No enviaste esa muestra?' : 'Não enviou essa amostra?'}
                                </div>}
                            </>
                        }
                    </div>
                    {iconElement(
                        <img className="sample-status-icon" src={pickupGreen} alt="truck" />,
                        <img className="sample-status-icon" src={pickup} alt="truck" />,
                        sampleStep >= 1
                    )}
                    <div className="path-container">
                        <div className={pathColorClass(sampleStep, 2)} />
                        {sampleStep >= 2 &&
                            <label className="sample-dates">
                                {isCo() ? 'Recibido el' : 'Recebido em'}
                        {" " + dateReFormat(sampleReceivedDate)}
                            </label>
                        }
                        {sampleStep == 1 && <label className="sample-dates">{isCo() ? 'En tránsito' : 'Em trânsito'}</label>}
                    </div>
                    {iconElement(
                        <img className="sample-status-icon" src={storeGreen} alt="store" />,
                        <img className="sample-status-icon" src={store} alt="store" />,
                        sampleStep >= 2
                    )}
                    <div className="path-container">
                        <div className={pathColorClass(sampleStep, 3)} />
                        {sampleStep >= 3 &&
                            <label className="sample-dates">
                                {isCo() ? 'Probado en' : 'Provado em'}
                         {" " + dateReFormat(sampleTastedDate)}
                            </label>
                        }
                        {sampleStep == 2 && <label className="sample-dates">{isCo() ? 'A la espera de pruebas' : 'Aguardando prova'}</label>}
                        {sampleStep < 2 && <label className="sample-dates">{isCo() ? 'No probado' : 'Não provado'}</label>}
                    </div>
                    {iconElement(
                        <img className="sample-status-icon" src={cupGreen} alt="cup" />,
                        <img className="sample-status-icon" src={cup} alt="cup" />,
                        sampleStep >= 3
                    )}
                </div>
                {sampleMessage &&
                    <div className="sample-status-message">
                        {sampleMessage}
                    </div>
                }
            </div>
        </div>
    )
}

export default SampleStatusDisplay