import React, { Fragment, useState, useEffect, useContext } from "react";
import MainCupping from '../templates/MainCupping'
import axios from 'axios'
import { server, isPt } from '../../common'
import UserContext from '../../context/user/UserContext'
import CoffeeCuppingCard from './CoffeeCuppingCard'
import CoffeeCuppingCardOrigin from './CoffeeCuppingCardOrigin'
import './CreateCoffeeCupping.css'
import Dropzone from 'react-dropzone'
import { storage } from '../../firebase'
import crypto from 'crypto'

import ReactGA from 'react-ga'

const CreateCoffeeCupping = (props) => {
    const [createCoffee, setCreateCoffee] = useState(false)
    const [updateCoffee, setUpdateCoffee] = useState(false)
    const [selectColor, setSelectColor] = useState(0)
    const [coffeeData, setCoffeeData] = useState({})
    const [cuppingCoffees, setCuppingCoffees] = useState([])
    const [nextId, setNextId] = useState([])
    const userContext = useContext(UserContext)

    let addingCoffee = false;

    useEffect(() => {
        axios.post(`${server}/getAllCuppingCoffees`, {user_id: userContext.user.id})
            .then(async coffees => {
                setCuppingCoffees(coffees.data)
                let aux = 0
                coffees.data.forEach(item => {
                    if(item.coffee_id > aux) {
                        aux = item.coffee_id
                        setNextId(aux)
                    } 
                })
            })
    }, [createCoffee, updateCoffee])


    const addCoffee = () => {
        if (addingCoffee === false) {
            addingCoffee = true;
            axios.post(`${server}/createCuppingCoffee`, {
                user_id: userContext.user.id,
                name: coffeeData.name,
                description: coffeeData.description,
                coffee_color: selectColor,
                img: coffeeData.img
            })
            .then(_ => {
                setCreateCoffee(false)
                setCoffeeData({})
            })
            .catch(err => console.log(err))
            .finally(()=>{
                addingCoffee = false;
            })
        }
    }

    const patchCoffee = () => {
        axios.patch(`${server}/updateCuppingCoffee`, {
            ...coffeeData
        })
        .then(_ => setUpdateCoffee(false))
        .catch(err => console.log(err))
    }

    const renderCoffeeCards = () => 
        cuppingCoffees.map(coffee => {
            return (<CoffeeCuppingCardOrigin coffeeData={coffee} setCoffeeData={setCoffeeData} setUpdateCoffee={setUpdateCoffee}/>)
    })

    const fileUpload = async (acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            const trapdoor = crypto.randomBytes(6).toString('HEX')
            const fileName = `${trapdoor}_${acceptedFiles[0].name}`
            await storage.ref(`producers/${fileName}`).put(acceptedFiles[0])
            await storage.ref('producers').child(`${fileName}`).getDownloadURL()
                .then((url) => { setCoffeeData({...coffeeData, img: url}) })
                .catch(e => console.log(e))
        }
    }

    const onDropRejected = () => {
        // setModalMessage("Imagem não pode ser maior do que 2 megabytes.")
        // toggleModal()
    }

    //REPORTS ANALYTICS
    useEffect(() => {
        ReactGA.event({
            category: 'Create Coffee Cupping',
            action: `A user visited the Create Coffee Cupping Page`
        })
    }, [])

    const clickAddCoffeeModal = () =>{
        ReactGA.event({
            category: 'Create Coffee Cupping',
            action: 'User clicked on Add Coffee button to open the modal'
        })
    }
    const clickAddCoffeeCard = () =>{
        ReactGA.event({
            category: 'Create Coffee Cupping',
            action: 'User clicked on Add Coffee card to open the modal'
        })
    }
    const clickAddCoffeeFinal = () =>{
        ReactGA.event({
            category: 'Create Coffee Cupping',
            action: 'User clicked on Add Coffee button and finished the register'
        })
    }
    const clickUpdateCoffee = () =>{
        ReactGA.event({
            category: 'Create Coffee Cupping',
            action: 'User clicked on the update button to update the coffee info'
        })
    }
    const clickAddImgCoffee = () =>{
        ReactGA.event({
            category: 'Create Coffee Cupping',
            action: 'User clicked on dropzone to add an image coffee'
        })
    }

    return(
        <>
            {createCoffee &&
                <div className="black-background-modal-cupping">
                    <div className="create-coffee-modal-container-cupping">
                        <div className="coffee-img-and-color-modal-cupping">
                            <div style={{flexDirection: 'column'}}>
                                    {coffeeData.img ? 
                                    // <div className="add-picture-modal-cupping">
                                    // </div> 
                                    <img className="coffee-img-cupping-card-preview" src={coffeeData.img}></img>
                                    :
                                    <Dropzone onDrop={fileUpload} onDropRejected={onDropRejected} maxSize={2097152}
                                    accept="image/*" multiple onClick={clickAddImgCoffee()}>
                                    {({ getRootProps, getInputProps }) => (
                                        <section>
                                            <div className="add-picture-modal-cupping"{...getRootProps()}>
                                                <input {...getInputProps()} accept="image/*" />
                                                <p>{isPt() ? '+ Clique para adicionar uma imagem para o café' : '+ Click to add a picture of the coffee'}</p>
                                            </div>
                                        </section>
                                    )}
                                </Dropzone>}
                                <div className="select-color-modal-cupping" style={selectColor == 1 ? {backgroundColor: '#b73952'} : selectColor == 2 ? {backgroundColor: '#f47a54'} : selectColor==3 ? {backgroundColor: '#bba726'} : selectColor==4 ? {backgroundColor: 'rgb(27, 98, 94)'} : selectColor == 5 ? {backgroundColor:'#6dd7f3'} : {backgroundColor:'rgb(27, 98, 94)'}}>
                                    <div className="coffee-name-modal-cupping">{coffeeData.name ? coffeeData.name : (isPt() ? "Nome do Café" : "Coffee Name")}</div>
                                    <div className="description-modal-cupping">
                                    {coffeeData.description ? coffeeData.description : (isPt() ? "Descrição do café para ajudar a identificar o tipo" : "Description of this cafe to help understand what type it is.")}
                                    </div>
                                </div>
                                <div className="coffee-id-modal-cupping">
                                    ID: {parseInt(nextId, 10) + 1}
                                </div>
                            </div>
                            <div className="colors-container-modal-cupping">
                                    <div className={selectColor==1 ? "color-red color-selected" : "color-red"} onClick={()=> setSelectColor(1)}></div>
                                    <div className={selectColor==2 ? "color-orange color-selected" : "color-orange"}  onClick={()=> setSelectColor(2)}></div>
                                    <div className={selectColor==3 ? "color-lime color-selected" : "color-lime"}  onClick={()=> setSelectColor(3)}></div>
                                    <div className={selectColor==4 ? "color-dark-green color-selected" : "color-dark-green"} onClick={()=> setSelectColor(4)}></div>
                                    <div className={selectColor==5 ? "color-blue color-selected" : "color-blue"}  onClick={()=> setSelectColor(5)}></div>
                            </div>
                        </div>
                        <div className="coffee-input-info-modal-cupping">
                            <input className="coffee-info-input-modal-cupping" value={coffeeData.name} setValue={value => setCoffeeData({...coffeeData, name: value})} onChange={(event) =>  setCoffeeData({...coffeeData, name: event.target.value})} placeholder={isPt() ? "Nome do Café" : "Coffee name"} maxLength="254"></input>
                            {/* <input className="coffee-info-input-modal-cupping" value={coffeeData.harvest_date} setValue={value => setCoffeeData({...coffeeData, harvest_date: value})} onChange={(event) =>  setCoffeeData({...coffeeData, harvest_date: event.target.value})} placeholder="Harvest Date"></input>
                            <input className="coffee-info-input-modal-cupping" value={coffeeData.region} setValue={value => setCoffeeData({...coffeeData, region: value})} onChange={(event) =>  setCoffeeData({...coffeeData, region: event.target.value})} placeholder="Region"></input>
                            <input className="coffee-info-input-modal-cupping" value={coffeeData.variety} setValue={value => setCoffeeData({...coffeeData, variety: value})} onChange={(event) =>  setCoffeeData({...coffeeData, variety: event.target.value})} placeholder="Variety"></input>
                            <input className="coffee-info-input-modal-cupping" value={coffeeData.process} setValue={value => setCoffeeData({...coffeeData, process: value})} onChange={(event) =>  setCoffeeData({...coffeeData, process: event.target.value})} placeholder="Process"></input>
                            <input className="coffee-info-input-modal-cupping" value={coffeeData.height} setValue={value => setCoffeeData({...coffeeData, height: value})} onChange={(event) =>  setCoffeeData({...coffeeData, height: event.target.value})} placeholder="Height"></input> */}
                            <textarea className="coffee-info-input-modal-cupping" value={coffeeData.description} setValue={value => setCoffeeData({...coffeeData, description: value})} onChange={(event) =>  setCoffeeData({...coffeeData, description: event.target.value})} rows='3' placeholder={isPt() ? "Descrição" : "Description"} maxLength="254"></textarea>
                        </div>
                    </div>
                    <div className="add-or-cancel-create-coffee-container-modal-cupping">
                        <div className="cancel-coffee-modal-cupping coffee-modal-animate" onClick={()=>{setCreateCoffee(false); setCoffeeData({})}}>X</div>
                        <div className="create-coffee-modal-cupping coffee-modal-animate" id="cupping-add-coffee-button" onClick={()=> { addCoffee(); clickAddCoffeeFinal() } } >{isPt() ? '+ Adicionar Café' : "+ Add Coffee"}</div>
                    </div>
                </div>
            }
            {updateCoffee &&
                <div className="black-background-modal-cupping">
                    <div className="create-coffee-modal-container-cupping">
                        <div className="coffee-img-and-color-modal-cupping">
                            <div style={{flexDirection: 'column'}}>
                            {coffeeData.img ? 
                                    // <div className="add-picture-modal-cupping">
                                    // </div> 
                                    <img className="coffee-img-cupping-card-preview" src={coffeeData.img}></img>
                                    :
                                    <Dropzone onDrop={fileUpload} onDropRejected={onDropRejected} maxSize={2097152}
                                    accept="image/*" multiple onClick={clickAddImgCoffee()}>
                                    {({ getRootProps, getInputProps }) => (
                                        <section>
                                            <div className="add-picture-modal-cupping"{...getRootProps()}>
                                                <input {...getInputProps()} accept="image/*" />
                                                <p>{isPt() ? '+ Clique para adicionar uma imagem para o café' : '+ Click to add a picture of the coffee'}</p>
                                            </div>
                                        </section>
                                    )}
                                </Dropzone>}
                                <div className="select-color-modal-cupping" style={coffeeData.coffee_color == 1 ? {backgroundColor: '#b73952'} : coffeeData.coffee_color == 2 ? {backgroundColor: '#f47a54'} : coffeeData.coffee_color==3 ? {backgroundColor: '#bba726'} : coffeeData.coffee_color==4 ? {backgroundColor: 'rgb(27, 98, 94)'} : coffeeData.coffee_color == 5 ? {backgroundColor:'#6dd7f3'} : {backgroundColor:'rgb(27, 98, 94)'}}>
                                    <div className="coffee-name-modal-cupping">{coffeeData.name ? coffeeData.name : (isPt() ? "Nome do Café" : "Coffee Name")}</div>
                                    <div className="description-modal-cupping">
                                    {coffeeData.description ? coffeeData.description : (isPt() ? "Descrição do café para ajudar a identificar o tipo" : "Description of this cafe to help understand what type it is.")}
                                    </div>
                                </div>
                                <div className="coffee-id-modal-cupping">
                                    ID: {coffeeData.coffee_id}
                                </div>
                            </div>
                            <div className="colors-container-modal-cupping">
                                    <div className={coffeeData.coffee_color==1 ? "color-red color-selected" : "color-red"} onClick={()=> setCoffeeData({...coffeeData, coffee_color: 1})}></div>
                                    <div className={coffeeData.coffee_color==2 ? "color-orange color-selected" : "color-orange"}  onClick={()=> setCoffeeData({...coffeeData, coffee_color: 2})}></div>
                                    <div className={coffeeData.coffee_color==3 ? "color-lime color-selected" : "color-lime"}  onClick={()=> setCoffeeData({...coffeeData, coffee_color: 3})}></div>
                                    <div className={coffeeData.coffee_color==4 ? "color-dark-green color-selected" : "color-dark-green"} onClick={()=> setCoffeeData({...coffeeData, coffee_color: 4})}></div>
                                    <div className={coffeeData.coffee_color==5 ? "color-blue color-selected" : "color-blue"}  onClick={()=> setCoffeeData({...coffeeData, coffee_color: 5})}></div>
                            </div>
                        </div>
                        <div className="coffee-input-info-modal-cupping">
                            <input className="coffee-info-input-modal-cupping" value={coffeeData.name} setValue={value => setCoffeeData({...coffeeData, name: value})} onChange={(event) =>  setCoffeeData({...coffeeData, name: event.target.value})} placeholder={isPt() ? "Nome do Café" : "Coffee name"} maxLength="254"></input>
                            <textarea className="coffee-info-input-modal-cupping" value={coffeeData.description} setValue={value => setCoffeeData({...coffeeData, description: value})} onChange={(event) =>  setCoffeeData({...coffeeData, description: event.target.value})} rows='3' placeholder={isPt() ? "Descrição" : "Description"} maxLength="254"></textarea>
                        </div>
                    </div>
                    <div className="add-or-cancel-create-coffee-container-modal-cupping">
                        <div className="cancel-coffee-modal-cupping coffee-modal-animate" onClick={()=>{setUpdateCoffee(false); setCoffeeData({})}}>X</div>
                        <div className="create-coffee-modal-cupping coffee-modal-animate" id="cupping-add-coffee-button" onClick={()=>{patchCoffee(); clickUpdateCoffee()}} >{isPt() ? 'Atualizar' : 'Update'}</div>
                    </div>
                </div>
            }
            <MainCupping noBanner style={{overflowY: 'hidden'}}>
                <br/>
                <br/>
                <br/>
                <br/>
                <div className="cupping-title-container">
                    <label className="cupping-page-title">{isPt() ? 'Cafés' : 'Coffees'}</label>
                    <button className="add-coffees-button-create-coffee" onClick={()=> {setCreateCoffee(true); clickAddCoffeeModal(); setCoffeeData({...coffeeData, img: null})}}>{isPt() ? '+ Adicionar Café' : "+ Add Coffee"}</button>
                </div>
                <div className="new-coffees-container-create-coffee">
                    <div className="add-coffee-card-create coffee-cupping-hover" onClick={()=> {setCreateCoffee(true); clickAddCoffeeCard(); setCoffeeData({...coffeeData, img: null})}}>
                        {isPt() ? '+ Adicionar Café' : "+ Add Coffee"}
                    </div>
                    {cuppingCoffees.length > 0 && renderCoffeeCards()}
                </div>


            </MainCupping>
        </>
    )
}
export default CreateCoffeeCupping
