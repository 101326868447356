import React,{FunctionComponent} from 'react'
import {
    Button,
} from '@material-ui/core';

import { AddButtonContainer } from './style';
import CoffeeModel from 'modules/SampleCart/models/coffeeModel';

interface AddCoffeeToCartButtonProps {
    alreadyIn: boolean,
    searchedCoffee: CoffeeModel,
    addToSampleCart: () => void
}

const AddCoffeeToCartButton: FunctionComponent<AddCoffeeToCartButtonProps> = (props) => {
    return (
        <>
            <AddButtonContainer>
                    <Button
                        disabled={!props.alreadyIn && Object.keys(props.searchedCoffee).length === 0}
                        variant="contained" color="primary"
                        onClick={props.addToSampleCart}
                    >
                        Adicionar
                    </Button>
            </AddButtonContainer>
        </>
    );
}
export default AddCoffeeToCartButton;