import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { server } from '../../common.js'
import { Link, useHistory } from 'react-router-dom'
import './ControlCupping.css'
import AdminCuppingCard from './AdminCuppingCard'
import FarmlyModal from '../templates/FarmlyModal'
import FarmlyInput from '../templates/FarmlyInput'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import AddCoffeeWrapper from '../profile/producers/Coffees'
import SearchBar from '../templates/SearchBar.jsx'


const ViewCuppingSample = (props) => {

    const history = useHistory()
    const [coffees, setCoffees] = useState([])
    const [page, setPage] = useState(0)
    const [showModal, setShowModal] = useState(0)
    const [sampleMessage, setSampleMessage] = useState('')
    const [coffeeIdVector, setCoffeeIdVector] = useState([])
    const [query, setQuery] = useState('')

    const { newSample, setNewSample, newCoffee, setNewCoffee, setAddToCupping, setAddToRecebidos, setOutOfCupping, setOutOfRecebidos, setAddToCuppingAndRecebidos } = props
    
    useEffect(() => {
        getCoffeesInit(0)
    }, [coffees])

    const toggleModal = (id = 0) => setShowModal(id)

    const getCoffeesInit = async (atPage = 0, sampleStatus) => {
        axios.get(`${server}/coffees`)
            .then(res => {
                setCoffees(res.data)
            })
    }
    const getCoffees = (atPage = 0, sampleStatus) => {
        const queryString = `${server}/coffees/${atPage}?sampleStatus=${sampleStatus}`
        axios.get(queryString)
            .then(res => {
                const newCoffees = [...coffees]
                newCoffees.push(...res.data)
                setCoffees(newCoffees)
            }).then(_ => setPage(atPage + 1))
            .catch(_ => { })
    }

    const searchCoffees = () => {
        setCoffees([])
        getCoffeesInit(0)
    }

    const updateCoffees = (cof, setCof, coffee) => {

        // não recebida -> recebida - check
        var n = new Date()
        if (coffee.sampleReceivedDate === 'not-received') {
            coffee.sampleReceivedDate = n.getFullYear() + '-' + n.getMonth() + '-' + n.getDay()
            setCof(cof.map((el) => el.id === coffee.id ? ({ ...el, sampleReceivedDate: n.getFullYear() + '-' + n.getMonth() + '-' + n.getDay() }) : el))
            axios.post(`${server}/createNotification`, {
                userId: coffee.producerId,
                isProd: true,
                title: 'Recebemos sua amostra!',
                message: `Sua amostra de café com variedade ${coffee.variety} e processo ${coffee.process} foi recebida em ${n.getDay() + '/' + n.getMonth() + '/' + n.getFullYear()}! Ela será provada e avaliada em breve!`
            })
            setAddToRecebidos(true)
        }

        // não provada -> provada - check
        else if (coffee.sampleTastedDate === 'not-tasted') {
            coffee.sampleTastedDate = n.getFullYear() + '-' + n.getMonth() + '-' + n.getDay()
            setCof(cof.map((el) => el.id === coffee.id ? ({ ...el, sampleTastedDate: n.getFullYear() + '-' + n.getMonth() + '-' + n.getDay() }) : el))
            axios.post(`${server}/createNotification`, {
                userId: coffee.producerId,
                isProd: true,
                title: 'Provamos sua amostra!',
                message: `Sua amostra de café com variedade ${coffee.variety} e processo ${coffee.process} foi provada em ${n.getDay() + '/' + n.getMonth() + '/' + n.getFullYear()}! Ela será avaliada em breve!`
            })
            setAddToCupping(true)
        }
        // provada -> não provada - unckeck
        else if (coffee.sampleTastedDate !== 'not-tasted') {
            coffee.sampleTastedDate = 'not-tasted'
            coffee.sampleApprove = 'not-approve'
            setCof(cof.map((el) => el.id === coffee.id ? ({ ...el, sampleTastedDate: "not-tasted" }) : el))
            setOutOfCupping(true)
        }

        axios.post(`${server}/sample`, {
            id: coffee.id,
            sampleSentDate: coffee.sampleSentDate,
            sampleReceivedDate: coffee.sampleReceivedDate,
            sampleTastedDate: coffee.sampleTastedDate,
            sampleApprove: coffee.sampleApprove
        })
            .catch(e => console.log(e))
    }


    const updateApproval = (cof, setCof, coffee) => {
        //Aprovada
        if (coffee.sampleApprove !== 'approved') {
            coffee.sampleApprove = 'approved'
            setCof(cof.map((el) => el.id === coffee.id ? ({ ...el, sampleApprove: 'approved' }) : el))
            axios.post(`${server}/createNotification`, {
                userId: coffee.producerId,
                isProd: true,
                title: 'Sua amostra foi aprovada!',
                message: `Sua amostra de café com variedade ${coffee.variety} e processo ${coffee.process} atendeu aos critérios necessários para ser aprovada.`
            })
        }
        axios.post(`${server}/sample`, {
            id: coffee.id,
            sampleSentDate: coffee.sampleSentDate,
            sampleReceivedDate: coffee.sampleReceivedDate,
            sampleTastedDate: coffee.sampleTastedDate,
            sampleApprove: coffee.sampleApprove
        })
            .catch(e => console.log(e))
    }

    const updateDisapproval = (cof, setCof, coffee) => {
        //Não Aprovada
        if (coffee.sampleApprove !== 'not-approved') {
            coffee.sampleApprove = 'not-approved'
            setCof(cof.map((el) => el.id === coffee.id ? ({ ...el, sampleApprove: 'not-approved' }) : el))
            axios.post(`${server}/createNotification`, {
                userId: coffee.producerId,
                isProd: true,
                title: 'Infelizmente sua amostra não foi aprovada!',
                message: `Sua amostra de café com variedade ${coffee.variety} e processo ${coffee.process} não atendeu os critérios necessários para ser aprovada.`
            })
        }
        axios.post(`${server}/sample`, {
            id: coffee.id,
            sampleSentDate: coffee.sampleSentDate,
            sampleReceivedDate: coffee.sampleReceivedDate,
            sampleTastedDate: coffee.sampleTastedDate,
            sampleApprove: coffee.sampleApprove
        })
            .catch(e => console.log(e))
    }

    const updateDouble = (cof, setCof, coffee) => {

        // recebida -> não recebida - uncheck
        if (coffee.sampleReceivedDate !== 'not-received') {
            coffee.sampleReceivedDate = 'not-received'
            setCof(cof.map((el) => el.id === coffee.id ? ({ ...el, sampleReceivedDate: 'not-received' }) : el))
        }
        setOutOfRecebidos(true)

        axios.post(`${server}/sample`, {
            id: coffee.id,
            sampleSentDate: coffee.sampleSentDate,
            sampleReceivedDate: coffee.sampleReceivedDate,
            sampleTastedDate: coffee.sampleTastedDate
        })
            .catch(e => console.log(e))
    }

    const forceCuppingUpdate = (cof, setCof, coffee) =>{
        var n = new Date()
        if(coffee.sampleTastedDate == 'not-tasted' && coffee.sampleReceivedDate == 'not-received'){
            coffee.sampleReceivedDate = n.getFullYear() + '-' + n.getMonth() + '-' + n.getDay()
            coffee.sampleTastedDate = n.getFullYear() + '-' + n.getMonth() + '-' + n.getDay()
            setCof(cof.map((el) => el.id === coffee.id ? ({ ...el, sampleReceivedDate: n.getFullYear() + '-' + n.getMonth() + '-' + n.getDay(), sampleTastedDate: n.getFullYear() + '-' + n.getMonth() + '-' + n.getDay() }) : el))
            axios.post(`${server}/sample`, {
                id: coffee.id,
                sampleSentDate: coffee.sampleSentDate,
                sampleReceivedDate: coffee.sampleReceivedDate,
                sampleTastedDate: coffee.sampleTastedDate,
                sampleApprove: coffee.sampleApprove
            })
                .catch(e => console.log(e))
            setAddToCuppingAndRecebidos(true)
        }
    }

    const sendMessage = () => {
        axios.post(`${server}/sample`, { id: showModal, sampleMessage: sampleMessage })
            .then(document.getElementById(`modal-content`).innerHTML = 'Mensagem Enviada')
            .catch(e => document.getElementById(`modal-content`).innerHTML = 'Erro!')
    }

    const showCollapsible = (name) => {
        var content = document.getElementById(`control-panel-collapsible-${name}`);
        if (content.style.display === "flex") {
            content.style.display = "none";
        } else {
            content.style.display = "flex";
        }
    }

    const cuppingWarmUp = () => {
        let cuppingCoffees = coffees.filter((coffee) => coffee.sampleSentDate !== 'not-sent' && coffee.sampleReceivedDate !== 'not-received' && coffee.sampleTastedDate !== 'not-tasted' && coffee.sampleApprove !== 'approved')
        console.log(cuppingCoffees)
        history.push({
            pathname: '/cupping',
            state: { cuppingCoffees: cuppingCoffees }
        })
        history.go()
    }

    const undefinedSample = async () => {
        const res = await axios.post(`${server}/addCoffee`, {
            producerId: 1
        })
        setNewCoffee({ ...newCoffee, id: res.data.id })
        setNewSample(true)
    }

    const renderCards = (coffees, setCoffees, filter, position, checkParam, doubleCheckParam) =>
        coffees.map((coffee) => {
            return coffee !== undefined && filter(coffee) && <AdminCuppingCard key={coffee.id} coffees={coffees} setCoffees={setCoffees} coffee={coffee}
                farmName={coffee.farm} score={coffee.score} checkParam={checkParam} doubleCheckParam={doubleCheckParam} position={position}
                image={coffee.imageUrl} variety={coffee.variety} process={coffee.process} farmHeight={coffee.height} sensoryNotes={coffee.sensoryNotes}
                producer={coffee.name} harvestDate={coffee.harvestDate} region={coffee.region} sampleSentDate={coffee.sampleSentDate} sampleReceivedDate={coffee.sampleReceivedDate} 
                sampleTastedDate={coffee.sampleTastedDate} coffeeId={coffee.id} updateFunction={updateCoffees} updateDouble={updateDouble} width='45%' 
                updateApproval={updateApproval} updateDisapproval={updateDisapproval} loadFlag={coffee.loadFlag || false} toggleModal={toggleModal} 
                sampleApprove={coffee.sampleApprove} farmCode={coffee.farmCode} description={coffee.description} forceCuppingUpdate={forceCuppingUpdate} />
        })

    return (
        <div className="control-panel-container">
            {/* <SearchBar query={query} setQuery={setQuery} submitQuery={searchCoffees} /> */}

            <h2 className="section-title collapsible" id="pending" onClick={(e) => showCollapsible(e.target.id)}>Pedentes<span className="drop-icon" id="pending">{document.getElementById(`control-panel-collapsible-pending`) && document.getElementById(`control-panel-collapsible-pending`).style.display === "flex" ? '-' : '+'}</span></h2>
            <div id="control-panel-collapsible-pending" className="control-panel-cupping collapsed">
                {renderCards(coffees, setCoffees, ((el) => el.sampleReceivedDate === 'not-received' && el.sampleTastedDate === 'not-tasted'), 3,false, false)}
            </div>
            <h2 className="section-title collapsible" id="history" onClick={(e) => showCollapsible(e.target.id)}>Recebidos<span className="drop-icon" id="history">{document.getElementById(`control-panel-collapsible-history`) && document.getElementById(`control-panel-collapsible-history`).style.display === "flex" ? '-' : '+'}</span></h2>
            <div id="control-panel-collapsible-history" className="control-panel-cupping collapsed">
                <div className="coffee-card-plus add-new-sample" onClick={() => undefinedSample()}>
                    <FontAwesomeIcon icon={faPlus} className="plus-icon" />
                </div>
                {renderCards(coffees, setCoffees, ((el) => el.sampleReceivedDate !== 'not-received' && el.sampleApprove !== 'approved'), 2, false , true)}
            </div>
            <h2 className="section-title collapsible" id="approved" onClick={(e) => showCollapsible(e.target.id)}>Provados<span className="drop-icon" id="approved">{document.getElementById(`control-panel-collapsible-approved`) && document.getElementById(`control-panel-collapsible-approved`).style.display === "flex" ? '-' : '+'}</span></h2>
            <div id="control-panel-collapsible-approved" className="control-panel-cupping collapsed">
                {renderCards(coffees, setCoffees, ((el) => el.sampleReceivedDate !== 'not-received' && el.sampleTastedDate !== 'not-tasted' && el.sampleApprove === 'approved'), 4, true)}
            </div>

            {showModal > 0 && <FarmlyModal id="page-modal" closeModal={toggleModal}>
                <div id="modal-content">
                    <label className="delete-coffee-title">Avaliar Café</label>
                    <p>Envie uma mensagem de revisão pela amostra {showModal}</p>
                    <FarmlyInput value={sampleMessage} textArea
                        setValue={value => { setSampleMessage(value) }}
                        styleClass="coffee-message-input" placeholder="Escreva algumas linhas sobre este café!" />
                    <div className="confirm-delete-button" onClick={sendMessage}>Enviar</div>
                </div>
            </FarmlyModal>}
        </div>)
}

export default ViewCuppingSample