import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import axios from 'axios'
import { server } from '../../common.js'

import './ControlPanel.css'
import AdminCoffeeCard from './AdminCoffeeCard.jsx'
import FarmlyModal from '../templates/FarmlyModal'
import FarmlyInput from '../templates/FarmlyInput'

const ViewSample = (props) => {

    const history = useHistory()

    const [coffees, setCoffees] = useState([])
    const [page, setPage] = useState(0)
    const [showModal, setShowModal] = useState(0)
    const [sampleMessage, setSampleMessage] = useState('')

    useEffect(() => {
        getCoffeesInit(0)
    }, [])

    const toggleModal = (id = 0) => setShowModal(id)

    const getCoffeesInit = async (atPage = 0, sampleStatus) => {
        /*const queryString = `${server}/coffees/${atPage}?sampleStatus=`
        const newCoffees = [...coffees]
        // precisa fazer isso pra pegar 10 de cada
        await axios.get(queryString  + `sent`)
            .then(res => {
                newCoffees.push(...res.data)
            }).then(_ => setPage(atPage + 1))
                .then(() => axios.get(queryString  + `received`)
                                .then(res => {
                                    newCoffees.push(...res.data)
                }))
                .then(() => axios.get(queryString  + `tasted`)
                                .then(res => {
                                    newCoffees.push(...res.data)
                }))
                .then(() => axios.get(queryString  + `not-sent`)
                                .then(res => {
                                    newCoffees.push(...res.data)
                })
                ).catch(_ => {
                //implementar um tratamento para isso!
            })
            setCoffees(newCoffees)*/
        axios.get(`${server}/coffees`)
            .then(res => {
                setCoffees(res.data)
            })
    }

    const getCoffees = (atPage = 0, sampleStatus) => {
        const queryString = `${server}/coffees/${atPage}?sampleStatus=${sampleStatus}`
        axios.get(queryString)
            .then(res => {
                const newCoffees = [...coffees]
                newCoffees.push(...res.data)
                setCoffees(newCoffees)
            }).then(_ => setPage(atPage + 1))
            .catch(_ => { })
    }

    const updateCoffees = (cof, setCof, coffee) => {

        // não recebida -> recebida - check
        var n = new Date()

        console.log("Esse cafe", coffee);
        if (coffee.sampleReceivedDate === 'not-received') {
            coffee.sampleReceivedDate = n.getFullYear() + '-' + n.getMonth() + '-' + n.getDay()
            setCof(cof.map((el) => el.id === coffee.id ? ({ ...el, sampleReceivedDate: n.getFullYear() + '-' + n.getMonth() + '-' + n.getDay() }) : el))
            axios.post(`${server}/createNotification`, {
                userId: coffee.producerId,
                isProd: true,
                title: 'Recebemos sua amostra!',
                message: `Sua amostra de café com variedade ${coffee.variety} e processo ${coffee.process} foi recebida em ${n.getDay() + '/' + n.getMonth() + '/' + n.getFullYear()}! Ela será provada e avaliada em breve!`
            })
        }

        // não provada -> provada - check
        else if (coffee.sampleTastedDate === 'not-tasted') {
            coffee.sampleTastedDate = n.getFullYear() + '-' + n.getMonth() + '-' + n.getDay()
            setCof(cof.map((el) => el.id === coffee.id ? ({ ...el, sampleTastedDate: n.getFullYear() + '-' + n.getMonth() + '-' + n.getDay() }) : el))
            axios.post(`${server}/createNotification`, {
                userId: coffee.producerId,
                isProd: true,
                title: 'Provamos sua amostra!',
                message: `Sua amostra de café com variedade ${coffee.variety} e processo ${coffee.process} foi provada em ${n.getDay() + '/' + n.getMonth() + '/' + n.getFullYear()}! Ela será avaliada em breve!`
            })
        }
        // provada -> não provada - unckeck
        else if (coffee.sampleTastedDate !== 'not-tasted') {
            coffee.sampleTastedDate = 'not-tasted'
            setCof(cof.map((el) => el.id === coffee.id ? ({ ...el, sampleTastedDate: "not-tasted" }) : el))
        }

        axios.post(`${server}/sample`, {
            id: coffee.id,
            sampleSentDate: coffee.sampleSentDate,
            sampleReceivedDate: coffee.sampleReceivedDate,
            sampleTastedDate: coffee.sampleTastedDate
        })
            .catch(e => console.log(e))
    }


    const updateApproval = (cof, setCof, coffee) => {
        //Aprovada
        if (coffee.sampleApprove !== 'approved') {
            coffee.sampleApprove = 'approved'
            setCof(cof.map((el) => el.id === coffee.id ? ({ ...el, sampleApprove: 'approved' }) : el))
            axios.post(`${server}/createNotification`, {
                userId: coffee.producerId,
                isProd: true,
                title: 'Sua amostra foi aprovada!',
                message: `Sua amostra de café com variedade ${coffee.variety} e processo ${coffee.process} atendeu aos critérios necessários para ser aprovada.`
            })
        }
        axios.post(`${server}/sample`, {
            id: coffee.id,
            sampleSentDate: coffee.sampleSentDate,
            sampleReceivedDate: coffee.sampleReceivedDate,
            sampleTastedDate: coffee.sampleTastedDate,
            sampleApprove: coffee.sampleApprove
        })
            .catch(e => console.log(e))
    }

    const updateDisapproval = (cof, setCof, coffee) => {
        //Não Aprovada
        if (coffee.sampleApprove !== 'not-approved') {
            coffee.sampleApprove = 'not-approved'
            setCof(cof.map((el) => el.id === coffee.id ? ({ ...el, sampleApprove: 'not-approved' }) : el))
            axios.post(`${server}/createNotification`, {
                userId: coffee.producerId,
                isProd: true,
                title: 'Infelizmente sua amostra não foi aprovada!',
                message: `Sua amostra de café com variedade ${coffee.variety} e processo ${coffee.process} não atendeu os critérios necessários para ser aprovada.`
            })
        }
        axios.post(`${server}/sample`, {
            id: coffee.id,
            sampleSentDate: coffee.sampleSentDate,
            sampleReceivedDate: coffee.sampleReceivedDate,
            sampleTastedDate: coffee.sampleTastedDate,
            sampleApprove: coffee.sampleApprove
        })
            .catch(e => console.log(e))
    }

    const updateDouble = (cof, setCof, coffee) => {

        // recebida -> não recebida - uncheck
        if (coffee.sampleReceivedDate !== 'not-received') {
            coffee.sampleReceivedDate = 'not-received'
            setCof(cof.map((el) => el.id === coffee.id ? ({ ...el, sampleReceivedDate: 'not-received' }) : el))
        }

        axios.post(`${server}/sample`, {
            id: coffee.id,
            sampleSentDate: coffee.sampleSentDate,
            sampleReceivedDate: coffee.sampleReceivedDate,
            sampleTastedDate: coffee.sampleTastedDate
        })
            .catch(e => console.log(e))
    }

    const sendMessage = () => {
        axios.post(`${server}/sample`, { id: showModal, sampleMessage: sampleMessage })
            .then(document.getElementById(`modal-content`).innerHTML = 'Mensagem Enviada')
            .catch(e => document.getElementById(`modal-content`).innerHTML = 'Erro!')
    }

    const showCollapsible = (name) => {
        var content = document.getElementById(`control-panel-collapsible-${name}`);
        if (content.style.display === "flex") {
            content.style.display = "none";
        } else {
            content.style.display = "flex";
        }
    }

    const renderCards = (coffees, setCoffees, filter, checkParam, doubleCheckParam) =>
        coffees.map((coffee) => {
            return coffee !== undefined && filter(coffee) && <AdminCoffeeCard key={coffee.id} coffees={coffees} setCoffees={setCoffees} coffee={coffee}
                farmName={coffee.farm} score={coffee.score} checkParam={checkParam} doubleCheckParam={doubleCheckParam}
                image={coffee.imageUrl} variety={coffee.variety} process={coffee.process} farmHeight={coffee.height}
                producer={coffee.name} harvestDate={coffee.harvestDate} region={coffee.region} sampleSentDate={coffee.sampleSentDate} sampleReceivedDate={coffee.sampleReceivedDate} sampleTastedDate={coffee.sampleTastedDate}
                coffeeId={coffee.id} updateFunction={updateCoffees} updateDouble={updateDouble} width='45%' updateApproval={updateApproval} updateDisapproval={updateDisapproval}
                loadFlag={coffee.loadFlag || false} toggleModal={toggleModal} sampleApprove={coffee.sampleApprove} />
        })

    return (


        <div className="control-panel-container">
            <button onClick={() => {history.push({pathname: '/manageCoffee'})}}>PAINEL DE GESTÃO DOS CAFÉS</button>
            <h2 className="section-title">Pendentes</h2>
            <div className="control-panel-coffees">
                {renderCards(coffees, setCoffees, ((el) => el.sampleSentDate !== 'not-sent' && el.sampleReceivedDate === 'not-received' && el.sampleTastedDate === 'not-tasted'), false)}
            </div>
            <h2 className="section-title collapsible" id="history" onClick={(e) => showCollapsible(e.target.id)}>Recebidos<span className="drop-icon" id="history">{document.getElementById(`control-panel-collapsible-history`) && document.getElementById(`control-panel-collapsible-history`).style.display === "flex" ? '-' : '+'}</span></h2>
            <div id="control-panel-collapsible-history" className="control-panel-coffees collapsed">
                {renderCards(coffees, setCoffees, ((el) => el.sampleSentDate !== 'not-sent' && el.sampleReceivedDate !== 'not-received' && el.sampleTastedDate === 'not-tasted'), false, true)}
            </div>
            <h2 className="section-title collapsible" id="tasted" onClick={(e) => showCollapsible(e.target.id)}>Avaliados<span className="drop-icon" id="tasted">{document.getElementById(`control-panel-collapsible-tasted`) && document.getElementById(`control-panel-collapsible-tasted`).style.display === "flex" ? '-' : '+'}</span></h2>
            <div id="control-panel-collapsible-tasted" className="control-panel-coffees collapsed">
                {renderCards(coffees, setCoffees, ((el) => el.sampleSentDate !== 'not-sent' && el.sampleReceivedDate !== 'not-received' && el.sampleTastedDate !== 'not-tasted'), true)}
            </div>
            <h2 className="section-title collapsible" id="unsent" onClick={(e) => showCollapsible(e.target.id)}>Não Enviados<span className="drop-icon" id="unsent">{document.getElementById(`control-panel-collapsible-unsent`) && document.getElementById(`control-panel-collapsible-unsent`).style.display === "flex" ? '-' : '+'}</span></h2>
            <div id="control-panel-collapsible-unsent" className="control-panel-coffees collapsed">
                {renderCards(coffees, setCoffees, ((el) => el.sampleSentDate === 'not-sent'))}
            </div>
            <h2 className="section-title collapsible" id="approved" onClick={(e) => showCollapsible(e.target.id)}>Aprovados<span className="drop-icon" id="approved">{document.getElementById(`control-panel-collapsible-approved`) && document.getElementById(`control-panel-collapsible-approved`).style.display === "flex" ? '-' : '+'}</span></h2>
            <div id="control-panel-collapsible-approved" className="control-panel-coffees collapsed">
                {renderCards(coffees, setCoffees, ((el) => el.sampleSentDate !== 'not-sent' && el.sampleReceivedDate !== 'not-received' && el.sampleTastedDate !== 'not-tasted' && el.sampleApprove === 'approved'), true)}
            </div>
            <h2 className="section-title collapsible" id="disapproved" onClick={(e) => showCollapsible(e.target.id)}>Não Aprovados<span className="drop-icon" id="disapproved">{document.getElementById(`control-panel-collapsible-disapproved`) && document.getElementById(`control-panel-collapsible-disapproved`).style.display === "flex" ? '-' : '+'}</span></h2>
            <div id="control-panel-collapsible-disapproved" className="control-panel-coffees collapsed">
                {renderCards(coffees, setCoffees, ((el) => el.sampleSentDate !== 'not-sent' && el.sampleReceivedDate !== 'not-received' && el.sampleTastedDate !== 'not-tasted' && el.sampleApprove === 'not-approved'), true)}
            </div>
            {showModal > 0 && <FarmlyModal id="page-modal" closeModal={toggleModal}>
                <div id="modal-content">
                    <label className="delete-coffee-title">Avaliar Café</label>
                    <p>Envie uma mensagem de revisão pela amostra {showModal}</p>
                    <FarmlyInput value={sampleMessage} textArea
                        setValue={value => { setSampleMessage(value) }}
                        styleClass="coffee-message-input" placeholder="Escreva algumas linhas sobre este café!" />
                    <div className="confirm-delete-button" onClick={sendMessage}>Enviar</div>
                </div>
            </FarmlyModal>}
        </div>
        )
}

export default ViewSample