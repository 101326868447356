import React, { useState, useEffect, useContext } from 'react'
import Main from '../templates/Main.jsx'
import { Link, useHistory } from 'react-router-dom'
import ReactGA from 'react-ga'
import FarmlyModal from '../templates/FarmlyModal.jsx'
import "./ThanksForYourRegistration.css"
import { server, isPt, farmlyTexts, acceptedCookies } from '../../common.js'
import axios from 'axios'
import CoffeeCard from '../coffees/CoffeeCard'
import RequestSample from '../coffeeProfile/RequestSample'
import SampleContext from '../../context/sample/SampleContext.js'

const coffeePicture1 = require('../../assets/produtor1.jpg')

const ThanksForYourRegistration = props => {
    const CoffeeDecoration = require('../../assets/coffee_decoration_homepage.jpg')
    const [coffees, setCoffees] = useState(null)
    const history = useHistory()
    const sampleContext = useContext(SampleContext)
    const [resendPopUp, setResendPopUp] = useState(false)

    useEffect(() => {
        getCoffees()
    }, [])

    const getCoffees = () => {
        let list = props.location.state.offerList
        let coffeesList = [{}]
        let aux
        console.log(list);
        list.forEach( async item => {
            aux = await axios.get(`${server}/coffee/${item.id}`)
            coffeesList.push(aux.data)
            console.log(coffeesList);
            if(coffeesList.length == list.length) {
                setCoffees(coffeesList)
            }
        })
    }

    const renderCards = requests =>
        requests.map((coffee) => {
            return (coffee.farmCode && <CoffeeCard key={coffee.id} coffee={coffee} farmCode={coffee.farmCode} coffeeSample={true}
                farmName={coffee.farmName} score={coffee.score} sensoryNotesEn={coffee.sensory_notes_en}
                producerId={coffee.producerId} sensoryNotes={coffee.sensoryNotes} imageUrl={coffee.imageUrl}
                variety={coffee.variety} process={coffee.process} coffeeId={coffee.id} width='25vw' background={coffee.image} />)
        })

    const reSend = () => {
        axios.post(`${server}/resend`, { email: props.location.state.email })
        setResendPopUp(true)
    }


    return (
        <>
        {resendPopUp && <FarmlyModal closeModal={() => { setResendPopUp(false) }} className="modal-sample">
                    <div className="modal-landing-page">
                        <label id="request-title">
                            Email resent!
                        </label>
                        <br />
                        <br />
                        <label id="request-content">
                            Still having trouble? Contact us at comercial@farmlyclub.com
                        </label>
                    </div>
                </FarmlyModal>}
        <Main>
            <div>
                <label className="thanks-title-label-2" style={{justifyContent: 'center', display: 'flex'}}>
                    Your order has been registered!
                </label> 
                
                <div className="thanks-second-container">
                    <div className="thanks-second-container-sub">
                        <label className="thanks-second-info">Our comunication is going to be by email. So keep tuned to receive more information!</label>
                        <br/>
                        <label className="thanks-second-info">Didn't receive any email?</label>
                        <br/>
                        <div>
                            <button className="thanks-button-yes" onClick={reSend}> Resend email</button>
                        </div>
                    </div>
                    <img src={CoffeeDecoration} className="decor-img" alt="coffee beans" />
                </div>

                <div className="thanks-third-container">
                    <label className="thanks-second-info">Here are the coffees whose samples you requested:</label>
                    <div style={{display: 'flex', flexWrap: 'wrap'}}>
                        {coffees && coffees.length > 0 && renderCards(coffees)}
                    </div>
                </div>
            </div>
        </Main>
        </>
    )
}

export default ThanksForYourRegistration

