import React, { useEffect, useState } from "react"

import axios from 'axios'
import { server } from '../../common'
import ReceiveQuery from "./hooks/ReceiveQuery/index"

const Interticial = () => {
    const [exists,setExists] = useState();
    
    let isDev = false 
    const serverFront = isDev? "http://localhost:3000"  : "https://www.farmlyclub.com" 

    const{
        emailQuery,
        returnUrlQuery
    } = ReceiveQuery()

    useEffect(()=>{
        axios.post(`${server}/roaster/validate-email`, [emailQuery])
            .then((res)=>{
                setExists(res.data[0].exists)
            }).catch((error)=>{
                console.log(error)
            })
    })

    if(exists === true && exists !== undefined){
        window.location.href = `${serverFront}/login?email=${emailQuery}&returnUrl=${returnUrlQuery}`
    }else if(exists !== undefined){
        window.location.href = `${serverFront}/signup?email=${emailQuery}&returnUrl=${returnUrlQuery}`
    }

    return(
        <></>
    ) 
}

export default Interticial

