import React, { Fragment } from 'react'
import './SideMenu.css'

const SideMenu = ({ sideMenuOptions, selectedOption, setSelectedOption, menuWidth }) => {

    const renderMenu = sideMenuOptions =>
        sideMenuOptions.map((menuOption) => {
            return <Fragment key={menuOption}>
                <div className={`sidemenu-option ${selectedOption === menuOption ? 'sidemenu-option-selected' : ''}`} 
                    onClick={() => setSelectedOption(menuOption)} >
                    {menuOption}
                </div>
            </Fragment>
        })

    return (
        <div className="sidemenu-container" style={{ width: menuWidth }}>
                {renderMenu(sideMenuOptions)}
        </div>
    )
}

export default SideMenu