import React from "react"

const HomeTest = props => {
    return (
        <>
         <iframe src='https://farmly-offerlist.bubbleapps.io/version-test/home' style={{position:"fixed", top:"0", left:"0", bottom:"0", right:"0", width:"100%", height:"100%", border:"none", margin:"0", padding:"0", overflow:"hidden", zIndex:"999999"}} allowFullScreen="true"></iframe>
        </>
    )
}

export default HomeTest