import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faTruck } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

const footerArt = require('../../../assets/footer-art.png')

const ClickWrapper = props => {
    const { handleClick, id, hover } = props

    return handleClick ?
        <div className="producer-coffee-card-content" id="coffee-card-info-container"
            style={{ height: hover ? '75%' : '23%', zIndex: 1 }} onClick={handleClick}>
            {props.children}
        </div>
        : <Link to={{ pathname: `/coffee/${id}` }} id="coffee-card-info-container"
            style={{ height: hover ? '75%' : '23%', zIndex: 1 }} >
            {props.children}
        </Link>
}

const ProducerCoffeeCard = props => {

    const {
        image,
        id,
        farmName,
        sensoryNotes,
        variety,
        score
    } = props.coffee
    const { sampleStep, displaySend, displayStatus, prodScreen, handleClick } = props

    const hover = true

    return (
        <div className="producer-coffee-card-container">
            <div className='producer-coffee-card'>
                <img id='coffee-card-footer' style={{
                    bottom: hover ? '35%' : '0%'
                }} src={footerArt} alt='' />
                <img id='coffee-card-image' style={{
                    opacity: hover ? '0' : '1',
                    width: '100%'
                }} src={image} alt='' />
                <div id="underlay" style={{
                    height: hover ? '55%' : '0%', zIndex: 0
                }} />
                <ClickWrapper handleClick={handleClick} id={id} hover={hover} >
                    <div id="coffee-card-info-labels">
                        <label className="coffee-card-label">Fazenda:</label>
                        <label className="coffee-card-info">{farmName}</label>
                        <label className="coffee-card-label">Notas sensoriais:</label>
                        <label className="coffee-card-info">{sensoryNotes}</label>
                        <label className="coffee-card-label">Variedade:</label>
                        <label className="coffee-card-info">{variety}</label>
                        <label className="coffee-card-label">Pontuação:</label>
                        <label className="coffee-card-score">{score}</label>
                    </div>
                    {<FontAwesomeIcon id='coffee-card-icon' icon={prodScreen ? faPencilAlt : faTruck} />}
                </ClickWrapper>
            </div>
            {prodScreen &&
                <>
                    {sampleStep >= 1 &&
                        <label onClick={displayStatus} className="coffee-sample-label sample-label-sent">
                            Acompanhe o envio de sua amostra
                        </label>}
                    {sampleStep < 1 &&
                        <label onClick={displaySend} className="coffee-sample-label sample-label-unsent">
                            Enviar amostra
                        </label>}
                </>}
        </div>
    )


}

export default ProducerCoffeeCard