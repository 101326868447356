import React, { useState, useEffect, Fragment } from 'react'
import ReactGA from 'react-ga'
import './Samples.css'

import Title from '../../templates/Title.jsx'
import CoffeesFilteredSection from './CoffeesFilteredSection.jsx'
import ProducerSampleCard from './ProducerSampleCard.jsx'
import { AddCoffeeCard } from './Coffees.jsx'
import FarmlyModal from '../../templates/FarmlyModal'
import FarmlyInput from '../../templates/FarmlyInput'
import { server, isPt, farmlyTexts, isCo, isBr } from '../../../common'
import axios from 'axios'
import { useLocation, useHistory } from 'react-router-dom'
import UserContext from '../../../context/user/UserContext'

const loadAnim = require('../../../assets/load.svg')

const CheckboxItem = props => {
    
    const { toggleCheck, checked, label } = props

    return (
        <div className="profile-checkbox-item-send-samples"
            onClick={()=> {toggleCheck()}}>
            <div className="profile-checkbox"
                style={{ backgroundColor: checked ? 'var(--farmly-blueStone)' : 'white' }} />
            {label == 1 ?
            <label className="profile-checkbox-label-send-sa
            ,mples">
                Cabral: (33)99985-2990<br/>  Rua Dona Bininha, nº 151 - Bairro Esplanada<br /> CEP: 35334-000 São Sebastião do Anta - MG
            </label> 
            :
            <label className="profile-checkbox-label-send-samples">
                Jack: (35) 99925-4566<br /> Rua José Luis Pressato, nº25 - Bairro Urupês<br /> CEP: 37063-110 Varginha - MG
            </label> 
            }
        </div>
    )
}

const CoffeeIconItem = props => {
    const { text, id } = props

    const textId = `coffee-icon-text-${id}`

    const highlight = raw => {
        return raw.split('__').reduce((res, curr, i) => {
            if (i % 2 === 1) return res + `\n<label class="samples-text-important">${curr}</label>\n`
            return res + curr
        }, "")
    }

    useEffect(() => {
        document.getElementById(textId).innerHTML = highlight(text)
    }, [])

    return (
        <div className="coffee-icon-item">
            <div className="coffee-icon" id="coffee-icon-item-icon" />
            <div id={textId} className="coffee-icon-text" ></div>
        </div>
    )
}

const SendSampleSection = props => {

    const { handleClickSend, handleClickAdd, userData, setUserData, updateUserData, selectCoffee, showSend, producerId } = props
    const [show, setShow] = useState(false)
    const [confirmSample, setConfirmSample] = useState(false)
    const location = useLocation()
    const [modalMessage, setModalMessage] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [thankYouSample, setThankYouSample] = useState(false)
    const [newSample, setNewSample] = useState(false)
    const [noSending, setNoSending] = useState(false)
    const [farmCode, setFarmCode] = useState()
    const [pleaseFill, setPleaseFill] = useState(false)
    const [sampleActiveButton,setSampleActiveButton] = useState(true)
    const history = useHistory()


    const [newCoffee, setNewCoffee] = useState({
        producer: userData.name ? userData.name : null,
        farmName: userData.farmName ? userData.farmName : null,
        region: userData.region ? userData.region : null,
        height: userData.height ? userData.height : null,
        variety: null,
        sackQuantity: null,
        process: null
    })
    // const renderCoffeeCards = coffees => coffees.map(coffee =>
    //     <ProducerSampleCard coffee={coffee} handleClick={() => {
    //         handleClickSend(coffee.id)
    //     }} key={`coffee-samples-${coffee.id}`} />)

    const toggleModal = () => {
        setShowModal(!showModal)
    }

    const handleFirstModal = () => {
        if(newCoffee.process === null || newCoffee.sackQuantity === null || newCoffee.variety === null || newCoffee.height === null || newCoffee.region === null || newCoffee.farmName === null || newCoffee.producer === null ){
            setPleaseFill(true)
        }
        else {
            ReactGA.event({
                category: 'Profile - Samples',
                action: `A producer clicked on send sample button at the end of the form modal`
            })
            setNewSample(false)
            setConfirmSample(true)
        }
        
    }

    const verifyOrigin = () => {
        ReactGA.event({
            category: 'Profile - Samples',
            action: `A producer clicked on send sample button`
        })
        setNewSample(true)
        // if (isCo()) setNewSample(true)
        // else setNoSending(true)
    }

    const handleClickAddSample = async () => {
        const reqBody = {
            ...newCoffee
        }
        setSampleActiveButton(false);
        try {
            const res = await axios.post(`${server}/addCoffee`, {
                producerId: producerId,
                isBr: isBr()
            })
            reqBody.id = res.data[0].id
            setFarmCode(res.data[0].farmCode)
            const date = new Date
            const day = ("0" + date.getDate().toString()).slice(-2)
            const month = ("0" + (date.getMonth() + 1).toString()).slice(-2)
            const year = date.getFullYear().toString()
            const dateString = [year, month, day].join('-')

            await axios.patch(`${server}/coffees`, {
                id: reqBody.id,
                height: reqBody.height,
                variety: reqBody.variety,
                process: reqBody.process,
                region: reqBody.region,
                farmName: reqBody.farmName,
                storage: reqBody.sackQuantity,
                producer: reqBody.producer,
                sampleSentDate: dateString,
                section: reqBody.section
            })
            setConfirmSample(false)
        } catch (err) {
            console.log(err)
            setModalMessage('Algo deu errado...')
            toggleModal()
        } finally {
            setThankYouSample(true)
            setSampleActiveButton(true)
        }
    }

    //REPORTS ANALYTICS
  
    const clickConfirmSendSample = () => {
        ReactGA.event({
            category: 'Profile - Samples',
            action: `A producer clicked on confirm send sample button`
        })
    }


    return (
        <>
            {noSending && <FarmlyModal closeModal={() => setNoSending(false)}>
                <label id="request-title">
                    {isCo() ? 'Actualmente no estamos recibiendo muestras de café, ¡pero volveremos pronto!' : 'No momento, não estamos recebendo amostras de café, voltaremos a receber a partir do dia 10/05!'}
                </label>
                <div className="request-content">
                    <label id="request-text"> {isCo() ? 'Si tiene alguna pregunta, envíe un mensaje a:' : 'Em caso de dúvidas, envie uma mensagem para:'} comercial@farmlyclub.com</label>
                </div>
            </FarmlyModal>}
            {confirmSample && <FarmlyModal closeModal={() => setConfirmSample(false)}>
                <label id="request-title">
                    {isCo() ? 'Confirmación del envío de la muestra' : 'Confirmação do envio de amostras'}
                </label>
                <div className="request-content">
                    {isPt() ? 
                        <label id="request-text"> Selecione um dos endereços abaixo para enviar a sua amostra!</label>
                    :
                    <>
                        <label id="request-text">{isCo() ? 'Si se trata de un café que cree que tiene una puntuación superior a 83' : 'Se é um café que você acha que tem uma pontuação acima de 83' }, envíe
                        <b> 2 kg</b> de {isCo() ? 'la muestra' : 'amostra'} CATADA, <br />{isCo() ? 'tamiz' : 'peneira'} 16 +, {isCo() ? 'con un contenido de humedad inferior al' : 'com umidade inferior a'} 11,5%</label>
                    </>
                    }
                    <br />
                    {isPt() &&
                        <>
                        <CheckboxItem checked={newCoffee.section == 100}
                            toggleCheck={() => setNewCoffee({...newCoffee, section: 100})} label={1}/>
                        <CheckboxItem checked={newCoffee.section == 200}
                            toggleCheck={() => setNewCoffee({...newCoffee, section: 200})} label={2}/>
                        </>
                    }
                    <br />
                    {
                        sampleActiveButton
                            ?
                            <div className="confirm-sent-button" onClick={() => {handleClickAddSample(); clickConfirmSendSample()}}>
                                {isCo() ? 'Confirmar la presentación de la muestra' : 'Confirmar o envio de amostras'}
                            </div>
                            :
                            <img src={loadAnim} alt="loading animation"/>
                    }
                    
                </div>
            </FarmlyModal>}
            {thankYouSample && <FarmlyModal closeModal={() => {
                history.push({
                    pathname: '/profile',
                    state: { samplesSection: true }
                })
                history.go()
            }}>
                <label id="request-title">
                    {isCo() ? '¡Esperamos su muestra!' : 'Estamos aguardando sua amostra!'}
                </label>
                <div className="request-content">
                    <label id="request-text">Estamos esperando 2Kg {isCo() ? 'de su muestra' : 'da sua amostra'}!</label>
                    <br />
                    <label id="request-text">{isCo() ? `El código para su café es` : `O código do seu café é` }: <br /> <span style={{ color: 'rgb(27,94,98)', fontWeight: '600', fontSize: 'larger'}}>{farmCode}</span> </label>
                    <br />
                    {isCo() ? 
                        <label id="request-text">Recuerde <span style={{color: 'rgb(27,94,98)', fontWeight: '600'}}>etiquetar el paquete</span> con "<span style={{color: 'rgb(27,94,98)', fontWeight: '600'}}>FARMly - código del café</span>", para que podamos
                        identificarte! <br /><span style={{color: 'rgb(27,94,98)', fontWeight: '600'}}>Ejemplo: FARMly - CO21-100</span></label>
                    :
                        <label id="request-text">Lembre-se de <span style={{color: 'rgb(27,94,98)', fontWeight: '600'}}>rotular a embalagem</span> com "<span style={{color: 'rgb(27,94,98)', fontWeight: '600'}}>FARMly - código do café</span>", para que 
                        possamos identificá-lo! <br /><span style={{color: 'rgb(27,94,98)', fontWeight: '600'}}>Exemplo: FARMly - BR21-100</span></label>
                    }
                    
                </div>
            </FarmlyModal>}
            {newSample && <FarmlyModal closeModal={() => { setNewSample(false) }} className="modal-sample">
                <label id="request-title">
                    {isCo() ? '¡Registre su muestra!' : 'Registre sua amostra!'}
                </label>
                <Fragment>
                    <div className="request-content">
                        <label id="request-text">{isCo() ? '¡Cuéntanos un poco sobre la muestra que se enviará!' : 'Conte um pouco sobre a amostra que será enviada!'}</label>
                        <br />
                        <div className="send-sample-inputs-container">
                            <div className="producer-profile-row" style={{ width: '100%' }}>
                                <div className="producer-profile-item">
                                    <label htmlFor="" className="producer-profile-label">{isCo() ? 'Nombre del productor' : 'Nome do Produtor'}:</label>
                                    <FarmlyInput value={newCoffee.producer} setValue={value => {setNewCoffee({ ...newCoffee, producer: value }); setPleaseFill(false)}}
                                        styleClass="producer-profile-input" elementId="name" placeholder={isCo() ? 'Nombre...' : 'Nome...'} />
                                </div>
                                <div className="producer-profile-item" style={{ marginLeft: '2vw' }}>
                                    <label htmlFor="" className="producer-profile-label">{isCo() ? 'Nombre de la granja' : 'Nome da Fazenda'}:</label>
                                    <FarmlyInput value={newCoffee.farmName} setValue={value => {setNewCoffee({ ...newCoffee, farmName: value }); setPleaseFill(false)}}
                                        styleClass="producer-profile-input" elementId="farmName" placeholder={isCo() ? "Granja..." : 'Fazenda...'} />
                                </div>
                            </div>
                            <div className="producer-profile-row" style={{ width: '100%' }}>
                                <div className="producer-profile-item">
                                    <label htmlFor="" className="producer-profile-label">{isCo() ? 'Región' : 'Região'}:</label>
                                    <FarmlyInput value={newCoffee.region} setValue={value => {setNewCoffee({ ...newCoffee, region: value }); setPleaseFill(false)}}
                                        styleClass="producer-profile-input" elementId="região" placeholder={isCo() ? "Región..." : 'Região...'} />
                                </div>
                                <div className="producer-profile-item" style={{ marginLeft: '2vw' }}>
                                    <label htmlFor="" className="producer-profile-label">{isCo() ? 'Altitud' : 'Altitude'}:</label>
                                    <FarmlyInput value={newCoffee.height} setValue={value => {setNewCoffee({ ...newCoffee, height: value }); setPleaseFill(false)}}
                                        styleClass="producer-profile-input" elementId="farmHeight" placeholder={isCo() ? "Altitud de la granja..." : "Altitude da fazenda..." } />
                                </div>
                            </div>
                            <div className="producer-profile-row" style={{ width: '100%' }}>
                                <div className="producer-profile-item">
                                    <label htmlFor="" className="producer-profile-label">{isCo() ? 'Variedad' : 'Variedade'}:</label>
                                    <FarmlyInput value={newCoffee.variety} setValue={value => {setNewCoffee({ ...newCoffee, variety: value }); setPleaseFill(false)}}
                                        styleClass="producer-profile-input" elementId="varieties" placeholder="Catuí, Catuaí..." />
                                </div>
                                <div className="producer-profile-item" style={{ marginLeft: '2vw' }}>
                                    <label htmlFor="" className="producer-profile-label">{isCo() ? 'Proceso' : 'Processo'}:</label>
                                    <FarmlyInput value={newCoffee.process} setValue={value => {setNewCoffee({ ...newCoffee, process: value }); setPleaseFill(false)}}
                                        styleClass="producer-profile-input" elementId="processo" placeholder={isCo() ? "Proceso..." : 'Processo...'} />
                                </div>
                            </div>
                            <div className="producer-profile-row" style={{ width: '100%' }}>
                                <div className="pricing-profile-item">
                                    <label className="pricing-info-label">Número de {isCo() ? 'bolsas' : 'sacas'}:</label>
                                    <FarmlyInput value={newCoffee.sackQuantity} setValue={value => {setNewCoffee({ ...newCoffee, sackQuantity: value }); setPleaseFill(false)}}
                                        styleClass="producer-profile-input" elementId="averageYield" placeholder="Número..." />
                                </div>
                            </div>
                            <div className="producer-profile-row" style={{ width: '100%' }}>
                                <label id="request-text">*{isCo() ? 'Cantidad de sacos de café 16 arriba preparados y fieles a la muestra' : 'Número de sacas de café 16+ preparadas e fiéis à amostra'}.</label>
                            </div>
                        </div>
                        <br />
                        <div className="sample-send-button" onClick={() => handleFirstModal()}>{isCo() ? 'Enviar Muestra' : 'Enviar Amostra'}</div>
                        {pleaseFill && <label className="login-fail-label" style={{alignSelf: 'center'}}>{isPt() ? `Por favor preencha todos so campos!`: `Por favor, rellene todos los campos!`}</label>}

                    </div>
                </Fragment>
            </FarmlyModal>}
            <div className="coffees-filtered-section">
                <div className="send-section-title-container">
                    <div className="send-section-title-button" onClick={() => verifyOrigin()} //setNewSample(true)
                        style={{ backgroundColor: show ? 'var(--farmly-fuzzy)' : 'var(--farmly-blueStone)' }} >
                        {isCo() ? 'Enviar Muestra' : 'Enviar Amostra'}
                    </div>
                </div>
            </div>
        </>
    )
}

const Samples = props => {

    const { coffeeData, selectCoffee, showStatus, showSend, userData, setUserData, updateUserData, producerId } = props

    const handleClickSent = id => {
        selectCoffee(id)
        showStatus()
    }

    const handleClickSend = id => {
        selectCoffee(id)
        showSend()
    }

    const handleClickAdd = () => {
        selectCoffee('new')
        showSend()
    }

    const sampleWasSent = coffee => coffee.sampleSentDate !== "not-sent"

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    //REPORTS ANALYTICS
    useEffect(() => {
        ReactGA.event({
            category: 'Profile - Samples',
            action: `A producer visited the samples Page`
        })
    }, [])

    return (
        <>
            { isCo() ?
                <div>
                    <br />
                    <Title title="Lo que buscamos" />
                    <div>
                        <br />
                    Por el momento, nos centramos en los cafés que cumplen ciertos requisitos:
                    <br /> <br />
                        <CoffeeIconItem id={0} text="__Humedad__ debajo de __11.5%__" />
                        <br />
                        <CoffeeIconItem id={1} text="Tamiz__16+__" />
                        <br />
                        <CoffeeIconItem id={2} text="Cafés con puntuaciones entre __83 e 85__. En cantidades mayores, sugerimos al menos __10__ bolsas" />
                        <br />
                        <CoffeeIconItem id={3} text="Cafés con puntuaciones superiores __86__ puntos, en __cualquier cantidad__." />
                        <br />
                        <CoffeeIconItem id={4} text="La muestra debe ser __recogida__ y__fiel__ a la bolsa que se entregará." />
                        <br />
                        <CoffeeIconItem id={5} text="Debe enviar __2kg__ de muestra." />
                        <br />
                    Si produce un café que cumple los requisitos anteriores, envíenos una muestra.
                    <br />
                        <br />
                        Al enviar el café, escriba
                    <label className="samples-text-important"> en la casilla "FARMly - código del café" </label>
                    junto a tu nombre para que podamos identificarte.
                    <br />
                    Por ejemplo
                    <label className="samples-text-important"> "FARMly - CO21-622"</label>
                    </div>
                    <SendSampleSection handleClickSend={handleClickSend} updateUserData={updateUserData} showSend={showSend}
                        coffees={coffeeData.filter(coffee => !sampleWasSent(coffee))} selectCoffee={selectCoffee} producerId={producerId}
                        handleClickAdd={handleClickAdd} userData={userData} setUserData={setUserData} />
                    <br />

                    <CoffeesFilteredSection subtitle="Haga clic en las tarjetas para seguir el envío de su muestra"
                        title="Enviados" handleClickSend={handleClickSent}
                        coffees={coffeeData.filter(coffee => sampleWasSent(coffee))}
                        userData={userData} />
                </div>
                :
                <div>
                    <br />
                    <Title title="O que estamos procurando" />
                    <div>
                        <br />
                    No momento, nosso foco são cafés que atendem alguns requisitos:
                    <br /> <br />
                        <CoffeeIconItem id={0} text="__Umidade__ abaixo de __11.5%__" />
                        <br />
                        <CoffeeIconItem id={1} text="Peneira __16+__" />
                        <br />
                        <CoffeeIconItem id={2} text="Cafés com pontuação entre __83 e 85__. Em quantidades maiores, sugerimos pelo menos __10__ sacas" />
                        <br />
                        <CoffeeIconItem id={3} text="Cafés com pontuação acima de __86__ pontos, em __qualquer quantidade__." />
                        <br />
                        <CoffeeIconItem id={4} text="A amostra deve ser __catada__ e__fiel__ à saca que será entregue." />
                        <br />
                        <CoffeeIconItem id={5} text="Você deve enviar __2kg__ de amostra." />
                        <br />
                    Se você produz um café que atenda as exigências acima, nos envie uma amostra!
                    <br />
                        <br />
                        Ao enviar o café, por favor escreva
                    <label className="samples-text-important"> na caixa, "FARMly - código do café" </label>
                    ao lado do seu nome para podermos identificar.
                    <br />
                    Por exemplo
                    <label className="samples-text-important"> "FARMly - BR21-622"</label>
                    </div>
                    <SendSampleSection handleClickSend={handleClickSend} updateUserData={updateUserData} showSend={showSend}
                        coffees={coffeeData.filter(coffee => !sampleWasSent(coffee))} selectCoffee={selectCoffee} producerId={producerId}
                        handleClickAdd={handleClickAdd} userData={userData} setUserData={setUserData} />
                    <br />
                    <label id="request-text">Você pode entregar ou enviar a sua amostra para um dos endereços:</label>
                    <br />
                    <br />
                    {/* <a href="https://g.page/castelodocafe?share" style={{ textDecoration: "none" }} target='_blank' rel="noopener noreferrer"> */}
                    <a>
                        <div className="castelo-address">
                            Cabral: (33)99985-2990<br/>  Rua Dona Bininha, nº 151 - Bairro Esplanda<br /> CEP: 35334-000<br /> São Sebastião do Anta - MG
                        </div>
                        <br />
                        <div className="castelo-address">
                            Jack: (35) 99925-4566<br /> Rua José Luis Pressato, nº25 - Bairro Urupês<br /> CEP: 37063-110<br /> Varginha - MG
                        </div>
                    </a>

                    <CoffeesFilteredSection subtitle="Clique nos cartões para acompanhar o envio da sua amostra"
                        title="Enviadas" handleClickSend={handleClickSent}
                        coffees={coffeeData.filter(coffee => sampleWasSent(coffee))}
                        userData={userData} />
                </div>
            }

        </>
    )
}

export default Samples