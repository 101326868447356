import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ReactGA from 'react-ga'
import './SignUpNoInvite.css'
import { Link } from 'react-router-dom'
import Main from '../../templates/Main'
import axios from 'axios'
import { GoogleLogin } from 'react-google-login'
import { server, language, isPt, isCo } from '../../../common'
import FarmlyModal from "../../templates/FarmlyModal";


const SignUpNoInvite = props => {

    const titleHeaderLanguage = { 'pt': 'Registre aqui', 'en': 'Signup', 'es': 'Inscrever-se' }[language]

    const [userData, setUserData] = useState({
        name: '',
        email: '',
        phone_number: ''
    })
    const [showModal, setShowModal] = useState(false)
    const [continueGoogle, setContinueGoogle] = useState(false)
    const [message, setMessage] = useState("")

    useEffect(() => {
        ReactGA.event({
            category: 'SignUp - ',
            action: `Visited the SignUp Received Link Page`
        })
    }, [])

    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    const sendData = (name, email, phone_number) => {
        if (name === "" || email === "" || phone_number === "") {
            setMessage("Favor preencher todas as informações!")
            toggleModal()
        }
        else {
            axios.post(`${server}/newRegister`, {
                name: name,
                email: email,
                phone_number: phone_number
            })
                .then(_ => {
                    setMessage("Suas informações foram salvas com sucesso!")
                    toggleModal()
                    setContinueGoogle(false)
                })
                .catch(err => {
                    console.log(err)
                    setMessage("Email já cadastrado!")
                    toggleModal()
                    setContinueGoogle(false)
                })
        }

    }

    const toggleModal = () => {
        setShowModal(!showModal)
    }

    const googleData = (response) => {
        setUserData({ ...userData, email: response.profileObj.email, name: response.profileObj.name })
        setMessage("Por favor, preencha o campo celular e clique em continuar!")
        setContinueGoogle(true)
        toggleModal()
    }

    return (
        <>
            {showModal && (
                <FarmlyModal id="page-modal" closeModal={toggleModal}>
                    {message}
                </FarmlyModal>
            )}
            <Main titleHeader={titleHeaderLanguage}>
                <div className="container-received">
                    <div className="main-text-received">
                        <p className="signup-no-invite-title" >Para se cadastrar, você precisa de um link de cadastro FARMly Club.</p>
                        <p>
                            Estamos empenhados em um atendimento mais exclusivo e em garantir o sucesso internacional dos produtores parceiros.
                        </p>
                        <p>
                            Por isso, priorizamos as indicações de quem já faz parte da nossa família para integrar essa parceria.
                        </p>
                        <p>
                            Caso não possua um convite, preencha seus dados para a lista de espera e aguarde um contato da nossa equipe para entender mais sobre você!
                        </p>
                    </div>

                    <div className="signup-received" style={{ marginTop: '0' }}>
                        <div className='signup-no-invite-form'>
                            <div className='signup-no-invite-subform'>
                                <label className='signup-no-invite-subform-label'>Nome</label>
                                <input className="signup-no-invite-input" value={userData.name} onChange={e => setUserData({ ...userData, name: e.target.value })} placeholder='Nome'></input>
                            </div>
                            <div className='signup-no-invite-subform'>
                                <label className='signup-no-invite-subform-label'>Email</label>
                                <input className="signup-no-invite-input" value={userData.email} onChange={e => { setUserData({ ...userData, email: e.target.value }); setContinueGoogle(false) }} placeholder='Email'></input>
                            </div>
                            <div className='signup-no-invite-subform'>
                                <label className='signup-no-invite-subform-label'>Celular</label>
                                <input className="signup-no-invite-input" type="number" value={userData.phone_number} onChange={e => setUserData({ ...userData, phone_number: e.target.value })} placeholder='Telefone'></input>
                            </div>
                            <div className="signup-no-invite-buttons-container">
                                <button className="signup-no-invite-button" onClick={() => { sendData(userData.name, userData.email, userData.phone_number) }}>{continueGoogle ? 'Continuar' : 'Entrar na lista de espera'}</button>
                                <label className="signup-no-invite-or-label"> ou </label>
                                <GoogleLogin
                                    clientId="503177513332-b2g42k0864opttrsn7v6v8e2o3imvse5.apps.googleusercontent.com"
                                    buttonText={isPt() ? 'Usar o email Google' : `Registro en Google`}
                                    onSuccess={googleData}
                                    onFailure={null}
                                    cookiePolicy={'single_host_origin'}
                                />
                                <div className="signup-already-have-access-box">
                                    <label className="signup-already-has-access-first-title">
                                        {isPt() ? "Já tenho" : (isCo() ? "Ya tengo" : "I already have")}
                                    </label>
                                    <Link className="signup-already-has-access-second-title" to={{ pathname: '/login' }} >
                                        {isPt() ? "acesso" : (isCo() ? "acceso" : "access")}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Main>
        </>
    )
}

export default SignUpNoInvite

// Cadastre-se nessa página e <span>0.5%</span> do valor das suas vendas será dado pela Farmly ao {invitedByUsername}. <br />
// Ao concluir o seu cadastro você poderá enviar links para outros produtores e <span>receber, por conta da Farmly,
// 0.5%</span> do que este produtor receber e se ele cadastrar mais alguém, você passa a receber <span>0.4%</span> do valor da venda
// deste terceiro. Assim sucessivamente até que você receba <span>0.1%</span> .