import styled from "styled-components";
// @ts-ignore
import Select from "react-select";

export const SelectCoffeeFilter = styled(Select)`
    width: 15vw;
`
export const CoffeeFilterLabel = styled.label`

`;

export const CoffeeFilterContainer = styled.div`
`;


