import React, {useState} from 'react'
import html2canvas from 'html2canvas';

import './greetingCard.css'

const GreetingCard = props => {

    const coffee = { ...props.location.params }
    coffee.farmHeight = coffee.farmHeight || '1200m'
    const[pixelHeight, setPixelHeight] = useState((1765 - coffee.farmHeight.match(/\d+/))*0.8)
    
    const saveImage = () => {
        html2canvas(document.querySelector("#card-canvas"), { dpi: 300, scale: 2}).then(canvas => {
            document.body.appendChild(canvas)
        });
    }

    const handleChange = (event) => {
        setPixelHeight(event.target.value);
        console.log(pixelHeight.toString() + "px")
        document.getElementById("farm-height").style.top = (pixelHeight - 295).toString() + "px"
      }

    return(
        <>
            <div id="card-canvas">
                    <div id="background">
                    <div id="foreground">
                    <input type="text" className="greeting-input" id="farm-name" placeholder={coffee.farmName}/>
                    <input type="text" className="greeting-input" id="farm-name-row2"/>
                        <div id="qr-code-slot">
                        </div>
                        <input type="text" className="greeting-input" id="farm-height" style={{top: pixelHeight.toString() + "px" }} placeholder={coffee.farmHeight}/>
                        <input type="text" className="greeting-input" id="variety" placeholder={coffee.variety}/>
                        <input type="text" className="greeting-input" id="process" placeholder={coffee.process}/>
                        <input type="text" className="greeting-input" id="region" placeholder={coffee.region}/>
                    </div>
                </div>
                </div>
                <div className="slidecontainer">
                    Altura da Fazenda:
                    <input type="range" min="120" max="795" className="slider" id="heightRange" value={pixelHeight} onChange={handleChange}/>
                    <span className="edit-coffee-button" onClick={saveImage}>Fazer Download</span>
                </div>
            </>)

}
export default GreetingCard