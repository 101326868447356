import React, { FunctionComponent } from 'react'
import {
    Button,
} from '@material-ui/core';

import { SampleCartActionButton } from "./style"
import CartModel from 'modules/SampleCart/models/cartModel';

interface CreateSampleCartButtonProps {
    changesHappen: boolean,
    sampleCart: CartModel,
    createSampleCart: () => void,
}

const CreateSampleCartButton: FunctionComponent<CreateSampleCartButtonProps> = (props) => {
    return (
        <SampleCartActionButton>
            <Button
                variant="contained"
                color="primary"
                disabled={!props.changesHappen && !(props.sampleCart.id === null || props.sampleCart.sample_list.length === 0)}
                onClick={props.createSampleCart}
            >
                Criar carrinho
            </Button>
        </SampleCartActionButton>
    );
}
export default CreateSampleCartButton;