import styled from 'styled-components';

export const CarouselWithFewSamples = styled.div`
    display: flex;;
    flex-direction: row;
    margin-left: 20px;
    display: "inline-flex"
`
export const RequestSamplesContent = styled.div`
    display: flex;
    justify-content: center;
    cursor: pointer;
    margin-top: 20px;

    :hover {
        transform: translateY(4px);
    }
`
export const RequestSamplesButton = styled.div`
    width: 80%;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1B625E;
    font-size: 18px;
    color: white !important;
    height: 40px;
    @media (max-width: 400px) {
    	font-size: 12px;
    }
`
export const ProducerProfileRow = styled.div`
    width: "100%";
    justifyContent: "center" 
`
export const ProducerProfileItem = styled.div`
`
export const EmailProfileLabel = styled.div`
    margin-bottom: 8px;
    font-weight: bold;
    font-size: 18px;
    text-align: left;
`
export const PasswordProfileLabel = styled.div`
    margin-bottom: 8px;
    margin-top: 20px;
    font-weight: 700;
    font-size: 18px;
    text-align: left;
`
export const LoginFailLabel = styled.div`
`
export const ServerFailLabel = styled.div`
`
export const ServerFailAuth = styled.div`
`
export const CarouselSamples = styled.div`
`
export const LoginButtonAccess = styled.div`
    cursor: pointer;
    font-weight: bold;
`
export const StepZeroFlowContainer = styled.div`
`
export const StepZeroFlowContainerUser = styled.div`
`
export const StepOneFlowContainer = styled.div`
`
export const AlreadyHaveAccountLabel = styled.label`
    display: flex;
    justify-content: center;
`
export const AlreadyHaveAccountDiv = styled.div`
    margin-right: 3px
`
export const BasicInfoSampleCartContainer = styled.div`
`
export const DisplaySamplesWithoutCarousel = styled.div`
    display: inline-flex;
`
export const SignUpNowLabel = styled.label`
    font-weight: 600;
    color: black;
    display: flex;
    justify-content: center;
    font-size: 35px;
    margin-top: 7vh;
`
export const SignUpSampleCartButton = styled.button`
    background-color: var(--farmly-blueStone);
    border-style: none;
    padding: 0.5em;
    width: 100%;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: medium;
    text-transform: uppercase;
    color: rgb(245, 245, 245);
    cursor: pointer;
    border-radius: 25px;
    margin-top: 34px;
    margin-right: 51px;
`