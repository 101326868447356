import React from 'react';
import useSampleCarts from '../../hooks/useSampleCarts';
import { Title, LoadingImage } from './style'

import loadAnim from '../../../../../assets/load.svg';

interface TitleLabelProps {
    title: string;
}

export default function TitleLabel(props: TitleLabelProps) {
    const sampleCartsHook = useSampleCarts();
    return (
        <>
            <Title>{props.title}{sampleCartsHook.loadingList ?
                <LoadingImage>
                    <img
                        src={loadAnim}
                        alt="loading animation"
                    />
                </LoadingImage>
                : null}
            </Title>
        </>
    );
}
