import React from 'react'

import './Roasters.css'
import CoffeeCard from '../../coffees/CoffeeCard'
import { isPt, farmlyTexts } from '../../../common'
import { Link } from 'react-router-dom'
import { Redirect } from 'react-router'

const coffeePicture1 = require('../../../assets/produtor1.jpg')

const RoasterRequests = (props) => {

    const { requests } = props

    const renderCards = requests =>
        requests.map((coffee) => {
            coffee.image = coffeePicture1
            return (
            <div className='roaster-profile-samples'>
                <CoffeeCard key={coffee.id} coffee={coffee} farmCode={coffee.farmCode} coffeeSample={true}
                    farmName={coffee.farmName} score={coffee.score} sensoryNotesEn={coffee.sensory_notes_en}
                    producerId={coffee.producerId} sensoryNotes={coffee.sensoryNotes} imageUrl={coffee.imageUrl}
                    variety={coffee.variety} process={coffee.process} coffeeId={coffee.id} width='25vw' background={coffee.image} />
            </div>)
        })


    return (
        <div className="roaster-profile-container">
            <div className="favorites-container">
                {requests.length === 0 &&
                    <div>
                        <div className="empty-page">{isPt() ? farmlyTexts.emptyPage_pt : farmlyTexts.emptyPage_en}</div>
                        <br></br>
                        <Link to='/coffees'>
                            <div className="roaster-profile-button">{isPt() ? farmlyTexts.requestRequestSample_pt : farmlyTexts.requestRequestSample_en}</div>
                        </Link>
                    </div>
                }

                {requests.length > 0 && 
                <div className="roaster-profile-container"> 
                    {renderCards(requests)}
                </div>
                }
            </div>
        </div>
    )
}

export default RoasterRequests