import React, { useState } from 'react'
import ButtonBase from '@material-ui/core/ButtonBase';


import '../../../styles/profile/components/UserSendSampleForm.css'

import FarmlyInput from '../../templates/FarmlyInput'
import { isBr, isCo, language } from '../../../common'


const UserSendSampleForm = props => {

    const {submitSample, user} = props;

    const [newCoffee, setNewCoffee] = useState({
        producer: user.name,
        farmName: user.farmName,
        region: user.region,
        height: user.farmHeight,
        variety: null,
        sackRemain: null,
        process: null,
        section: 100
    })
    return (
        <div className="new-profile user-send-sample-form" id="user-send-sample-form">
            <div className="user-send-sample-form-title">
                <h4>{{pt:'Registre o envio da sua amostra!',es:'Regístrese para enviar su muestra'}[language]}</h4>
            </div>
            <div className="new-profile user-send-sample-form-row">
                <div className = 'new-profile user-sample-input-grid'>
                    <div className="user-send-sample-input-form" >
                        <label htmlFor="name" className="user-send-sample-input-label">{{pt:'Nome do Produtor',es:'Nombre del productor', en: 'Producer Name'}[language]}</label>
                        <FarmlyInput value={newCoffee.producer} setValue={value => setNewCoffee({ ...newCoffee, producer: value })}
                            styleClass="new-profile user-send-sample-input" elementId="name" placeholder="Tobias" />
                    </div>
                    <div className="user-send-sample-input-form" >
                        <label htmlFor="farmName" className="user-send-sample-input-label">{{pt:'Nome da Fazenda',es:'Nombre de la granja', en: 'Farm Name'}[language]}</label>
                        <FarmlyInput value={newCoffee.farmName} setValue={value => setNewCoffee({ ...newCoffee, farmName: value })}
                            styleClass="new-profile user-send-sample-input" elementId="farmName" placeholder="Fazenda Catuaí" />
                    </div>
                    <div className="user-send-sample-input-form">
                        <label htmlFor="região" className="user-send-sample-input-label">{{es:'Región',pt:'Região', en: 'Region'}[language]}</label>
                        <FarmlyInput value={newCoffee.region} setValue={value => setNewCoffee({ ...newCoffee, region: value })}
                            styleClass="new-profile user-send-sample-input" elementId="região" placeholder="Zona da Mata" />
                    </div>
                    <div className="user-send-sample-input-form">
                        <label htmlFor="farmHeight" className="user-send-sample-input-label">{{pt:'Altitude',es:'Altitud', en: 'Height'}[language]}</label>
                        <FarmlyInput value={newCoffee.height} setValue={value => setNewCoffee({ ...newCoffee, height: value })}
                            styleClass="new-profile user-send-sample-input" elementId="farmHeight" placeholder="1200 metros" type="numeric"/>
                    </div>
                    <div className="user-send-sample-input-form" >
                        <label htmlFor="varieties" className="user-send-sample-input-label">{{pt:'Variedade',es:'Variedad', en: 'Variety'}[language]}</label>
                        <FarmlyInput value={newCoffee.variety} setValue={value => setNewCoffee({ ...newCoffee, variety: value })}
                            styleClass="new-profile user-send-sample-input" elementId="varieties" placeholder="Catuaí, Catucaí..." />
                    </div>
                    <div className="user-send-sample-input-form">
                        <label htmlFor="processo" className="user-send-sample-input-label">{{es:'Proceso',pt:'Processo', en: 'Process'}[language]}</label>
                        <FarmlyInput value={newCoffee.process} setValue={value => setNewCoffee({ ...newCoffee, process: value })}
                            styleClass="new-profile user-send-sample-input" elementId="processo" placeholder="Natural, Despolpado" />
                    </div>
                    <div className="user-send-sample-input-form">
                        <label htmlFor="yield" className="user-send-sample-input-label">{{es:'Número de bolsas (70kg)',pt:'Número de sacas (60 KG)', en: 'Sack Number'}[language]}</label>
                        <FarmlyInput value={newCoffee.sackRemain} setValue={value => setNewCoffee({ ...newCoffee, sackRemain: value })}
                            styleClass="new-profile user-send-sample-input" elementId="yield" placeholder="50" type="number"/>
                    </div>
                </div>

                {/* <div className="user-send-sample-input-form" > */}
                        {/* <label htmlFor="send-option" className="user-send-sample-input-label">Para onde prefere enviar sua amostra</label>
                        <select className="new-profile user-send-sample-input big" value={newCoffee.section} name="send-option" 
                            id="send-option" onChange={(e) => setNewCoffee({ ...newCoffee, section: e.target.value })}>
                            <option value="0">{isCo()?'Clic aquí y seleccione una opción':'Clique aqui e selecione uma opção'}</option>
                            {isBr() && <option value="100">Cabral: (33)99985-2990 Rua Dona Bininha, nº 151 - Bairro Esplanada CEP: 35334-000 São Sebastião do Anta - MG</option>}
                            {/* {isBr() && <option value="200">Jack: (35) 99925-4566 Rua José Luis Pressato, nº25 - Bairro Urupês CEP: 37063-110 Varginha - MG</option>} */}
                            {/* {isBr() && <option value="300">Salomão: (33) 3331-1000 Rua Oliveira, nº 100 - Bairro Pinheiro CEP: 36902-102  Manhuaçu - MG</option>} */}
                            {/* {isCo() && <option value="400">Luz Nelly Umaña: Calle 14 # 19-23 B / Pueblo Nuevo, Trujillo, Valle Cedula, 31785293 </option>} */}
                        {/* </select> */}
                {/* </div>  */}
                <div className="user-send-sample-action-group">
					<ButtonBase className="user-send-sample-button new-farmly-button f-button-orange" onClick={(e)=>submitSample(newCoffee)}>{isCo() ? 'Enviar Muestra' : 'Enviar Amostra'}</ButtonBase>
                </div>
            </div>
        </div>
    )
}

export default UserSendSampleForm