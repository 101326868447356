import React, { useEffect } from 'react'
import './CoffeeCarousel.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import CoffeeCard from '../coffees/CoffeeCard'
const CoffeeCarousel = props => {

    const coffees = props.coffees
    let nextLeft = coffees.length - 2
    let nextRight = 4 % coffees.length
    const currOffset = [-1, -1, -1, -1, -1]

    useEffect(() => {
        /*
        const carouselcoffees = document.querySelectorAll('.coffee-carousel-cof')
        const offset = carouselcoffees[1].offsetLeft - carouselcoffees[0].offsetLeft
        carouselcoffees.forEach(el => {
            el.style.transition = "transform 0s ease-in-out"
            el.style.transform = `translateX(-${offset}px)`
        })
        */
    }, [coffees])

    const moveRight = () => {
        const carouselcoffees = document.querySelectorAll('.coffee-carousel-cof')
        const offset = carouselcoffees[1].offsetLeft - carouselcoffees[0].offsetLeft
        carouselcoffees.forEach((el, ind) => {
            if (currOffset[ind] <= -ind - 1) el.src = coffees[nextRight]
            const next = currOffset[ind] <= -ind - 1 ? -ind + 3 : currOffset[ind] - 1
            el.style.transition = currOffset[ind] <= -ind -1 ? "none" : "transform 0.4s ease-in-out"
            currOffset[ind] = next;
            el.style.transform = `translateX(${next * offset}px)`
        })
        nextRight = (nextRight + 1) % coffees.length
        nextLeft = (nextLeft + 1) % coffees.length
    }

    const moveLeft = () => {
        const carouselcoffees = document.querySelectorAll('.coffee-carousel-cof')
        const offset = carouselcoffees[1].offsetLeft - carouselcoffees[0].offsetLeft
        carouselcoffees.forEach((el, ind) => {
            if (currOffset[ind] >= -ind + 3) el.src = coffees[nextLeft]
            const next = currOffset[ind] >= -ind + 3 ? -ind - 1 : currOffset[ind] + 1
            el.style.transition = currOffset[ind] >= -ind + 3 ? "none" : "transform 0.4s ease-in-out"
            currOffset[ind] = next;
            el.style.transform = `translateX(${next * offset}px)`
        })
        nextRight = nextRight === 0 ? coffees.length - 1 : nextRight - 1
        nextLeft = nextLeft === 0 ? coffees.length - 1 : nextLeft - 1
    }

    return (
        <div className="coffee-carousel" >
            <div id="coffee-carousel-left-button" className="coffee-carousel-button coffee-icon"
                onClick={moveLeft} >
                <FontAwesomeIcon className="coffee-carousel-arrow-icon" icon={faArrowLeft} />
            </div>
            <div className="coffee-carousel-container">
            { coffees.length != 0 &&
                <div className="coffee-carousel-slide">
                    <CoffeeCard key={coffees[coffees.length - 1].id} coffee={coffees[coffees.length - 1].coffee} className="coffee-carousel-cof"
                        farmName={coffees[coffees.length - 1].farmName} score={coffees[coffees.length - 1].score}
                        image={coffees[coffees.length - 1].imageUrl} sensoryNotes={coffees[coffees.length - 1].sensoryNotes}
                        variety={coffees[coffees.length - 1].variety} process={coffees[coffees.length - 1].process} width='25.5vw' id="car0" />
                    <CoffeeCard key={coffees[0].id} coffee={coffees[0].coffee} className="coffee-carousel-cof"
                        farmName={coffees[0].farmName} score={coffees[0].score}
                        image={coffees[0].imageUrl} sensoryNotes={coffees[0].sensoryNotes}
                        variety={coffees[0].variety} process={coffees[0].process} width='25.5vw' id="car1" />
                    <CoffeeCard key={coffees[1 % coffees.length].id} coffee={coffees[1 % coffees.length].coffee} className="coffee-carousel-cof"
                        farmName={coffees[1 % coffees.length].farmName} score={coffees[1 % coffees.length].score}
                        image={coffees[1 % coffees.length].imageUrl} sensoryNotes={coffees[1 % coffees.length].sensoryNotes}
                        variety={coffees[1 % coffees.length].variety} process={coffees[1 % coffees.length].process} width='25.5vw' id="car2" />     
                    <CoffeeCard key={coffees[2 % coffees.length].id} coffee={coffees[2 % coffees.length].coffee} className="coffee-carousel-cof"
                        farmName={coffees[2 % coffees.length].farmName} score={coffees[2 % coffees.length].score}
                        image={coffees[2 % coffees.length].imageUrl} sensoryNotes={coffees[2 % coffees.length].sensoryNotes}
                        variety={coffees[2 % coffees.length].variety} process={coffees[2 % coffees.length].process} width='25.5vw' id="car2" />     
                    <CoffeeCard key={coffees[3 % coffees.length].id} coffee={coffees[3 % coffees.length].coffee} className="coffee-carousel-cof"
                        farmName={coffees[3 % coffees.length].farmName} score={coffees[3 % coffees.length].score}
                        image={coffees[3 % coffees.length].imageUrl} sensoryNotes={coffees[3 % coffees.length].sensoryNotes}
                        variety={coffees[3 % coffees.length].variety} process={coffees[3 % coffees.length].process} width='25.5vw' id="car2" />     
                               
                </div>
                }
            </div>
            <div id="coffee-carousel-right-button" className="coffee-carousel-button coffee-icon"
                onClick={moveRight} >
                <FontAwesomeIcon className="coffee-carousel-arrow-icon" icon={faArrowRight} />
            </div>
        </div>
    )
}

export default CoffeeCarousel