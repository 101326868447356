import React, {useState, useEffect} from 'react'
import './Roasters.css'

import { isPt, farmlyTexts } from '../../../common'

const strawberryImage = require('../../../assets/strawberry.jpg')
const chocolateImage = require('../../../assets/chocolate.jpg')
const orangeImage = require('../../../assets/orange.jpg')
const limeImage = require('../../../assets/lime.jpg')
const bananaImage = require('../../../assets/banana.jpg')
const caramelImage = require('../../../assets/caramel.jpg')

const RenderSensoryNotes = (props) => {

    const {roasterData, setRoasterData} = props;

    const [displayData, setDisplayData] = useState(roasterData.sensoryNotes)

    useEffect(()=>{
        setRoasterData({...roasterData,sensoryNotes: displayData})
    }, [displayData])

    return (
            <div className="roaster-profile-sensory-notes">
                <div className="roaster-profile-sensory-item"
                    onClick={() => setDisplayData({...displayData, strawberry: displayData.strawberry ? '' : 'strawberry,' })}
                    style={{ opacity: displayData.strawberry ? 1 : '' }} >
                    <img className="roaster-profile-sensory-img" src={strawberryImage} alt="" />
                    <label className="roaster-profile-score-label">{isPt() ? farmlyTexts.signupStrawberry_pt : farmlyTexts.signupStrawberry_en}</label>
                </div>
                <div className="roaster-profile-sensory-item"
                    onClick={() => setDisplayData({...displayData, chocolate: displayData.chocolate ? '' : 'chocolate,' })}
                    style={{ opacity: displayData.chocolate ? 1 : '' }} >
                    <img className="roaster-profile-sensory-img" src={chocolateImage} alt="" />
                    <label className="roaster-profile-score-label">{isPt() ? farmlyTexts.signupChocolate_pt : farmlyTexts.signupChocolate_en}</label>
                </div>
                <div className="roaster-profile-sensory-item"
                    onClick={() => setDisplayData({...displayData, orange: displayData.orange ? '' : 'orange,' })}
                    style={{ opacity: displayData.orange ? 1 : '' }} >
                    <img className="roaster-profile-sensory-img" src={orangeImage} alt="" />
                    <label className="roaster-profile-score-label">{isPt() ? farmlyTexts.signupOrange_pt : farmlyTexts.signupOrange_en}</label>
                </div>
                <div className="roaster-profile-sensory-item"
                    onClick={() => setDisplayData({...displayData, lime: displayData.lime ? '' : 'lime,' })}
                    style={{ opacity: displayData.lime ? 1 : '' }} >
                    <img className="roaster-profile-sensory-img" src={limeImage} alt="" />
                    <label className="roaster-profile-score-label">{isPt() ? farmlyTexts.signupLime_pt : farmlyTexts.signupLime_en}</label>
                </div>
                <div className="roaster-profile-sensory-item"
                    onClick={() => setDisplayData({...displayData, banana: displayData.banana ? '' : 'banana,' })}
                    style={{ opacity: displayData.banana ? 1 : '' }} >
                    <img className="roaster-profile-sensory-img" src={bananaImage} alt="" />
                    <label className="roaster-profile-score-label">{isPt() ? farmlyTexts.signupBanana_pt : farmlyTexts.signupBanana_en}</label>
                </div>
                <div className="roaster-profile-sensory-item"
                    onClick={() => setDisplayData({...displayData, caramel: displayData.caramel ? '' : 'caramel,' })}
                    style={{ opacity: displayData.caramel ? 1 : '' }} >
                    <img className="roaster-profile-sensory-img" src={caramelImage} alt="" />
                    <label className="roaster-profile-score-label">{isPt() ? farmlyTexts.signupCaramel_pt : farmlyTexts.signupCaramel_en}</label>
                </div>
            </div>
    )
}

export default RenderSensoryNotes