import React from "react";
import { CoffeeLineContainer, CoffeeInfo, CoffeeInfoSelect, CoffeeInfoButton, CoffeeInfoOption } from "./styles";
import useUpdateCoffee from "../../hooks/useUpdateCoffee"
import CoffeesLineModel from '../../models/CoffeesLineModel'

interface CoffeesLineProps {
    coffee : CoffeesLineModel,
    modal : () => void
}

export default function CoffeesLineApproval(props: CoffeesLineProps) {

    const { coffee, modal } = props;

    const update = useUpdateCoffee(coffee, modal) 

    return (
        <CoffeeLineContainer>
            <CoffeeInfo>
                {coffee.farmCode}
            </CoffeeInfo>
            <CoffeeInfo>
                {coffee.producer}
            </CoffeeInfo>
            <CoffeeInfo>
                {coffee.process}
            </CoffeeInfo>
            <CoffeeInfo>
                {coffee.sackRemain}({coffee.origin === 'Brazil' ? 60 : 70}kg)
            </CoffeeInfo>
            <CoffeeInfo>
                {coffee.score}
            </CoffeeInfo>
            <CoffeeInfo>
                {coffee.sensoryNotes}
            </CoffeeInfo>
            <CoffeeInfo>
                {coffee.general_comment === null ? '-' : coffee.general_comment}
            </CoffeeInfo>
            <CoffeeInfo>
            <CoffeeInfoSelect value={update.selectedApproval()} onChange={(e:any) => { update.updateField(e.target.value, "SectionApproval")}}>
                <CoffeeInfoOption value={4} >Histórico</CoffeeInfoOption>
                <CoffeeInfoOption value={5} >Aprovado</CoffeeInfoOption>
                <CoffeeInfoOption value={3} >Reprovado</CoffeeInfoOption>
                    {/* <CoffeeInfoSelect value={update.selectedApproval()} disabled={update.disabled(2)} onChange={(e:any) => { update.updateField(e.target.value, "SectionApproval")}}>
                    <CoffeeInfoOption value={4} >Histórico</CoffeeInfoOption>
                    <CoffeeInfoOption value={5} >Aprovado</CoffeeInfoOption>
                    <CoffeeInfoOption value={3} >Reprovado</CoffeeInfoOption>
                    <CoffeeInfoOption hidden value={2} >Em prova</CoffeeInfoOption>
                    <CoffeeInfoOption hidden value={1} >Sem prova</CoffeeInfoOption>
                    <CoffeeInfoOption hidden value={0} >Sem prova</CoffeeInfoOption> */}
                </CoffeeInfoSelect>
            </CoffeeInfo>
            <CoffeeInfo>
                <CoffeeInfoButton onClick={() => update.updateCoffeeData(coffee.id)}>
                    Salvar
                </CoffeeInfoButton>
            </CoffeeInfo>
        
        </CoffeeLineContainer>
    );
    }
