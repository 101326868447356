import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, makeStyles, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useState, useEffect } from 'react';
import axios from 'axios'
import { server } from '../../common.js'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginBottom: '1vw'
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

const SimpleAccordion = (props) => {
    const classes = useStyles();
    const { userData, setUserData, updateUserData, title, message, id, aux, setAux, read } = props

    const handleRead = (notificationId) => {
        const url = read ? 'updateNotificationFalse' : 'updateNotification'
        axios.patch(`${server}/${url}/${notificationId}`)
            .then(_ =>
                setAux(aux + 1))
            .catch(err => console.log(err))

    }

    return (
        <div className={classes.root}>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-label="Expand"
                    aria-controls="additional-actions1-content"
                    id="additional-actions1-header"
                >
                    <FormControlLabel
                        aria-label="Acknowledge"
                        control={<Checkbox checked={read} onClick={() => handleRead(id)} />}
                    // label={title}
                    />
                    <label className={classes.heading} style={{ alignSelf: 'center' }}>
                        {title}
                    </label>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography color="textSecondary">
                        {message}
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}

export default SimpleAccordion
