import React, { useState } from 'react'

import { Link } from 'react-router-dom'
import Main from '../templates/Main.jsx'
import { server, isPt, farmlyTexts } from '../../common'

import axios from 'axios'

const SignUp = props => {
    const emptyUser = {
        name: '',
        email: '',
        password: '',
        farmName: '',
        country: '',
    }

    const [user, setUser] = useState({
        ...emptyUser
    })

    const [isProducer, setIsProducer] = useState(true)

    const signUp = async () => {
        const form = await isProducer ? {
            name: user.name,
            email: user.email,
            password: user.password,
            farmName: user.farmName
        } : {
                name: user.name,
                email: user.email,
                password: user.password,
                country: user.country
            }

        try {
            await axios.post(`${server}/signup/${isProducer ? '1' : '0'}`, {
                ...form
            })
            props.history.push('/')
        } catch (err) {
            alert('Deu errado!')
            console.log(err)
        }

        clear()
    }

    const clear = () => setUser({ ...emptyUser })

    const renderForm = () => {
        return (
            <div className="signup-container">
                <div className="signup-form">
                    <div className="signup-row" id='signup-title-row'>
                        <div className="coffee-icon" />
                        <label className="signup-title">{isPt() ? farmlyTexts.signupTitle_pt : farmlyTexts.signupTitle_en}</label>
                    </div>
                    <div className="signup-row">
                        <div className="signup-radio-container">
                            <div className="signup-radio-item">
                                <label className='radio-item-label' style={{ 
                                    fontWeight: isProducer ? 600 : 400,
                                    fontSize: isProducer ? '20px' : '18px'
                                }}
                                    onClick={() => setIsProducer(true)} >
                                    {isPt() ? farmlyTexts.signupProducer_pt : farmlyTexts.signupProducer_en}
                                </label>
                            </div>
                            <div className="signup-radio-item">
                                <label className='radio-item-label' style={{ 
                                    fontWeight: isProducer ? 400 : 600,
                                    fontSize: isProducer ? '18px' : '20px'
                                }}
                                    onClick={() => setIsProducer(false)} >
                                    {isPt() ? farmlyTexts.signupRoaster_pt : farmlyTexts.signupRoaster_en}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="signup-row">
                        <div className="signup-form-group">
                            <label className='signup-field-label'>{isPt() ? farmlyTexts.signupName_pt : farmlyTexts.signupName_en}</label>
                            <input type="text" className="signup-form-control"
                                name='name' onChange={e => setUser({ ...user, name: e.target.value })}
                                value={user.name} placeholder={isPt() ? farmlyTexts.signupName_pt : farmlyTexts.signupName_en} />
                        </div>
                    </div>
                    {isProducer &&
                        <div className="signup-row">
                            <div className="signup-form-group">
                                <label className='signup-field-label'>{isPt() ? farmlyTexts.signupFarmName_pt : farmlyTexts.signupFarmName_en}</label>
                                <input type="text" className="signup-form-control"
                                    name='farmName' onChange={e => setUser({ ...user, farmName: e.target.value })}
                                    value={user.farmName} placeholder={isPt() ? farmlyTexts.signupFarmName_pt : farmlyTexts.signupFarmName_en} />
                            </div>
                        </div>}
                    {!isProducer &&
                        <div className="signup-row">
                            <div className="signup-form-group">
                                <label className='signup-field-label'>{isPt() ? farmlyTexts.signupCountry_pt : farmlyTexts.signupCountry_en}</label>
                                <input type="text" className="signup-form-control"
                                    name='country' onChange={e => setUser({ ...user, country: e.target.value })}
                                    value={user.country} placeholder={isPt() ? farmlyTexts.signupCountry_pt : farmlyTexts.signupCountry_en} />
                            </div>
                        </div>}
                    <div className="signup-row">
                        <div className="signup-form-group">
                            <label className='signup-field-label'>{isPt() ? farmlyTexts.signupEmail_pt : farmlyTexts.signupEmail_en}</label>
                            <input type="text" className="signup-form-control"
                                name='email' onChange={e => setUser({ ...user, email: e.target.value })}
                                value={user.email} placeholder={isPt() ? farmlyTexts.signupEmail_pt : farmlyTexts.signupEmail_en} />
                        </div>
                    </div>
                    <div className="signup-row">
                        <div className="signup-form-group">
                            <label className='signup-field-label'>{isPt() ? farmlyTexts.signupPassword_pt : farmlyTexts.signupPassword_en}</label>
                            <input type="password" className="signup-form-control"
                                name='password' onChange={e => setUser({ ...user, password: e.target.value })}
                                value={user.password} placeholder={isPt() ? farmlyTexts.signupPassword_pt : farmlyTexts.signupPassword_en} />
                        </div>
                    </div>
                    <div className="signup-row">
                        <div className="signup-buttons-container">
                            <button className="signup-button" onClick={signUp}>
                                {isPt() ? farmlyTexts.signupSignup_pt : farmlyTexts.signupSignup_en}
                            </button>
                        </div>
                    </div>
                    <div className="signup-row">
                        <div id="signup-have-account">
                            <label id='have-account-label'>
                                {'Already have an account? '}
                            </label>
                            <Link id='have-account-link' to='/login'>
                                Login
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <Main>
            {renderForm()}
        </Main>
    )
}

export default SignUp