import React, { FunctionComponent } from 'react'

import { Container, CartList, CartListItem, DeleteButton, DeleteButtonContainer } from './style'

import CartModel from "../../../models/cartModel";
import TitleLabel from '../TitleLabel';

interface ListSampleCartsProps {
    sampleCartList?: Array<CartModel>,
    getSampleCart: (id: number | any) => Array<CartModel> | void,
    deleteSampleCart: (id: number | null) => void
}

const ListSampleCarts: FunctionComponent<ListSampleCartsProps> = (props) => {
    return (
        <Container>
            <TitleLabel title="List" />
            {props.sampleCartList && props.sampleCartList.map((cart: CartModel) => {
                return (
                    <CartList key={cart.id}>
                        <CartListItem onClick={e => {
                            props.getSampleCart(cart.id)
                        }}>
                            {cart.cart_name}
                        </CartListItem>
                        <DeleteButtonContainer>
                            <DeleteButton onClick={(e) => { props.deleteSampleCart(cart.id) }}>
                                X
                            </DeleteButton>
                        </DeleteButtonContainer>
                    </CartList>
                )
            })}
        </Container>
    );
}

export default ListSampleCarts;