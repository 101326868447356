import React, { useContext, useState, useEffect, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import TagFacesIcon from '@material-ui/icons/TagFaces';
import SampleContext from '../../context/sample/SampleContext'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(0.5),
        margin: 0,
    },
    chip: {
        margin: theme.spacing(0.5),
    },
}));

export default function ChipsArray() {
    const classes = useStyles();
    const sampleContext = useContext(SampleContext)
    const [chipData, setChipData] = React.useState([]);

    useEffect(() => {
        const aux = []
        sampleContext.offerList.forEach(item => {
            aux.push(item.farmCode)
        })
        setChipData(aux)
    }, [sampleContext.offerList])

    const handleDelete = (chipToDelete) => () => {
        sampleContext.removeFromList(chipToDelete)
        alert(chipToDelete)

    };


    return (
        <Fragment component="ul" className={classes.root}>
            {chipData.map((data) => {
                return (
                    <li key={data}>
                        <Chip
                            label={`Code:${data}`}
                            className={classes.chip}
                            onDelete={handleDelete(data)}
                        />
                    </li>
                );
            })}
        </Fragment>
    );
}
