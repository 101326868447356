import React, { useEffect } from 'react'
import './FarmlyInput.css'
import PropTypes from 'prop-types'

const FarmlyInputCoffee = props => {

    const { value, setValue, type, styleClass, textArea, upperBound, lowerBound,
        decimals, placeholder, measureUnit, elementId, setShowUpdateButtons } = props

    const types = {
        currency: 'currency',
        measure: 'measure',
        numeric: 'numeric'
    }

    const currencyCheck = text => {
        if (!isNaN(text) && text != '' && parseFloat(text) > 0) setValue(text)
        else if (text == '') setValue('0')
    }

    const measureCheck = text => {
        let number = text
        if (text.endsWith(measureUnit))
            number = text.substring(0, text.length - measureUnit.length)

        if (text === measureUnit) setValue(`0${measureUnit}`)
        else if ((!isNaN(number) && parseInt(number) > 0)) {
            number = parseInt(number).toString()
            setValue(number)
        } else if (number === '') setValue('')
    }

    const numericCheck = text => {
        if (!isNaN(text)) setValue(text)
    }

    const handleTextChange = text => {
        setShowUpdateButtons(true)
        switch (type) {
            case types.currency:
                currencyCheck(text)
                break
            case types.measure:
                measureCheck(text)
                break
            case types.numeric:
                numericCheck(text)
                break
            default:
                setValue(text)
        }
    }

    const handleFocus = () => {
        switch (type) {
            case types.measure:
                if (value.endsWith(measureUnit)) setValue(value.substring(0, value.length - measureUnit.length))
                break
        }
    }

    const handleBlur = () => {
        switch (type) {
            case types.currency:
                let curr = value
                if (curr === '') curr = (lowerBound ? lowerBound : '0')
                curr = parseFloat(curr)
                if (lowerBound && curr < lowerBound) curr = lowerBound
                else if (upperBound && curr > upperBound) curr = upperBound
                setValue(curr.toFixed(2).toString())
                break
            case types.numeric:
                let val = value
                if (val === '') val = (lowerBound ? lowerBound : '0')
                val = parseFloat(val)
                if (lowerBound && val < lowerBound) val = lowerBound
                else if (upperBound && val > upperBound) val = upperBound
                if (decimals) val = val.toFixed(decimals)
                setValue(val.toString())
                break
            case types.measure:
                if (!value.endsWith(measureUnit) && value !== '') setValue(value + measureUnit)
            default:
        }
    }

    const autoGrow = () => {
        var el = document.querySelector("textarea")
        console.log('etste')
        el.style.height = "5px";
        el.style.height = (el.scrollHeight) + "px"
    }
    
    return (
        textArea ?
            <div className="textArea-content">
                <textarea
                    rols="4" col="60" value={value} 
                    onChange={e => {handleTextChange(e.target.value); autoGrow()}}
                    className={styleClass} onBlur={handleBlur} placeholder={placeholder}
                    id={elementId} />
            </div>
            :
            <>
                <input type="text" value={value} onChange={e => handleTextChange(e.target.value)}
                    onBlur={handleBlur} className={styleClass} placeholder={placeholder}
                    onFocus={handleFocus} id={elementId} />
            </>
    )
}

FarmlyInputCoffee.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    setValue: PropTypes.func.isRequired,
    type: PropTypes.string,
    styleClass: PropTypes.string,
    textArea: PropTypes.bool,
    upperBound: PropTypes.number,
    lowerBound: PropTypes.number,
    decimals: PropTypes.number,
    placeholder: PropTypes.string,
    measureUnit: PropTypes.string,
    elementId: PropTypes.string
}

FarmlyInputCoffee.defaultProps = {
    styleClass: "farmly-input",
    elementId: "farmly-input"
}

export default FarmlyInputCoffee