import backend from '../../../../lib/backend'
import { useState, useEffect } from 'react';
import { server } from 'common';

const useUpdateCoffee = (coffeeData, modal) => {

    const [coffeeUpdate, setCoffeeUpdate] = useState(coffeeData)

    useEffect(() => {
        setCoffeeUpdate({
            process: coffeeData.process,
            sackRemain: coffeeData.sackRemain,
            sackPrice: coffeeData.sackPrice,
            section: coffeeData.section,
            shipment: coffeeData.shipment,
            display: coffeeData.display,
            })    
    }, [coffeeData])   

    const updateCoffeeData = (coffeeId) => {
        backend.patch(`${server}/coffees`, {
            id: coffeeId,
            ...coffeeUpdate,
        })
        .then(_ => modal())
        .catch(err => console.log(err))
    }

    const updateField = (value, category) => {

        switch (category) {

            case 'Process':
            setCoffeeUpdate({...coffeeUpdate, process: value})
            break;
            case 'SackRemain':
            setCoffeeUpdate({...coffeeUpdate, sackRemain: parseInt(value)})
            break;
            case 'SackPrice':
            setCoffeeUpdate({...coffeeUpdate, sackPrice: value})
            break;
            case 'Section':
            setCoffeeUpdate({...coffeeUpdate, section: (parseInt(value) + coffeeUpdate.section%100)})
            break;
            case 'Shipment':
            setCoffeeUpdate({...coffeeUpdate, shipment: value})
            break;
            case 'Display':
            setCoffeeUpdate({...coffeeUpdate, display: value})
            break;
            case 'SectionApproval':
            setCoffeeUpdate({...coffeeUpdate, section: (coffeeUpdate.section - coffeeUpdate.section%100 + parseInt(value))})
            break;
            case 'farmlyFee':
            setCoffeeUpdate({...coffeeUpdate, farmlyFee: (coffeeUpdate.farmlyFee - coffeeUpdate.section%100 + parseInt(value))})
            break;

        }
    }

    const selectValue = () => { return coffeeUpdate.section - coffeeUpdate.section%100 }

    const selectedApproval = () => { return coffeeUpdate.section%100 }
    
    const disabled = (value) => { return coffeeUpdate.section%100 <= value }

    const inputValue = (key) => { return coffeeUpdate[key] ? coffeeUpdate[key] : "" }

    const checked = () => { return coffeeUpdate.display ? coffeeUpdate.display : false }    

return { updateCoffeeData, updateField, selectValue, selectedApproval, disabled, inputValue, checked }

}

export default useUpdateCoffee