import styled from 'styled-components';

export const NewCartButton = styled.button`
    font-size: 14px;
    color: black;
    cursor: pointer;
    :hover {
        background-color: red;
        transition: 0.3s;
    }
`
