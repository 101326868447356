import React from 'react'

import './CoffeeProfile.css'

const ProfileEditButtonsCoffee = props => {
    const { updateUserData, cancelEdit, setShowUpdateButtons } = props
    const setItFalse = () => {
        setShowUpdateButtons(false)
    }
    const update = () => {
        updateUserData()
        setItFalse()
    }

    return (
        <div className="profile-row profile-buttons-row">
            <div className="cancel-changes button-changes" onClick={cancelEdit, setItFalse}>
                Cancelar
                </div>
            <div className="submit-changes button-changes" onClick={update}>
                Confirmar Mudanças
                </div>
        </div>
    )
}

export default ProfileEditButtonsCoffee