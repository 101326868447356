const sectionOptions = [
    {
      label: "Pendentes",
      value: '0'
    },
    {
      label: "Recebidos",
      value: '1'
    },
    {
      label: "Em prova",
      value: '2'
    },
    {
      label: "Provados",
      value: "tasted"
    },
    {
      label: "Histórico",
      value: '4'
    },
    {
      label: "Aprovados",
      value: '5'
    },
    {
      label: "Não Aprovados",
      value: '3'
    }
  ]

  export default sectionOptions