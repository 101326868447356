import styled from 'styled-components';

export const Container = styled.div`
    width: 50vw;
    padding-right: 20px;
`

//sample list item
export const CartList = styled.div` 
    display: flex;
    flex-direction: row;
    box-shadow: 1px 2px 2px rgba(0,0,0,0.2);
    margin-left: 25px;
    margin-top: 20px;
    cursor: pointer;
    width: 100%;
    `
    
    //sample list item
    export const CartListItem = styled.div`
    display: flex;
    width: 90%;
    justify-content: flex-start;
`

//sample list item
export const DeleteButton = styled.button`
    cursor: pointer;
`

export const DeleteButtonContainer = styled.div`
    width: 10%;
    display: flex;
    justify-content: flex-end; 
`
