import React, { useEffect, useState } from "react";
import { CoffeeLineContainer, CoffeeInfo, CoffeeInfoInput, CoffeeInfoButton, CoffeeInfoSelect, CoffeeInfoOption, CoffeeInfoCheckbox, DeleteShippingButton, CoffeeLink  } from "./styles";
import useUpdateCoffee from "../../hooks/useUpdateCoffee"
import CoffeesLineModel from '../../models/CoffeesLineModel'
import {server} from "../../../../common.js"
import axios from 'axios'
 
interface CoffeesLineProps {
    coffee : CoffeesLineModel,
    modal : () => void

}

export default function CoffeesLineMain(props: CoffeesLineProps) {

    const { coffee, modal } = props;
    
    const update = useUpdateCoffee(coffee, modal)
    const [shipments,setShipments] = useState([]);

    useEffect(()=>{
        axios.get(`${server}/getShippingData`)
            .then((res)=>{
                setShipments(res.data.sort((a:any, b:any)=>{return b.shipping_id - a.shipping_id}))
            })
    },[]);

    const renderShipments = ()=>{
        return shipments.map((shipment:any)=>{
            return(
                <CoffeeInfoOption value={shipment.shipping_id}>
                    {shipment.departure_date?.split("-")[2].split("T")[0]+"/"+shipment.departure_date?.split("-")[1]+"/"+shipment.departure_date?.split("-")[0]}
                </CoffeeInfoOption>
            )
        })
    }
    return (
        <CoffeeLineContainer>
            <CoffeeLink href={`/coffee/${coffee.id}`}>
            <CoffeeInfo>
                {coffee && coffee.farmCode}
            </CoffeeInfo>
            </CoffeeLink>
            <CoffeeInfo>
                {coffee && coffee.producer}
            </CoffeeInfo>
            <CoffeeInfo>
                <CoffeeInfoInput value={update.inputValue('process')} onChange={(e:any) => { update.updateField(e.target.value, 'Process')}} />
            </CoffeeInfo>
            <CoffeeInfo>
                <CoffeeInfoInput type="number" value={update.inputValue('sackRemain') > 0 ? update.inputValue('sackRemain') : 0} onChange={(e:any) => { update.updateField(parseInt(e.target.value), 'SackRemain')}}/>
            </CoffeeInfo>
            <CoffeeInfo>
                <CoffeeInfoInput type="number" value={update.inputValue('sackPrice') > 0 ? update.inputValue('sackPrice') : 0 } onChange={(e:any) => { update.updateField(e.target.value, 'SackPrice')}}/>
            </CoffeeInfo>
             <CoffeeInfo>
                <CoffeeInfoInput type="number" value={update.inputValue('farmlyFee') > 0 ? update.inputValue('farmlyFee') : 0 } onChange={(e:any) => { update.updateField(e.target.value, 'farmlyFee')}}/>
            </CoffeeInfo>
            <CoffeeInfo>
                <CoffeeInfoSelect value={update.selectValue()} onChange={(e:any) => { update.updateField(e.target.value, 'Section')}}>
                    <CoffeeInfoOption value={100} >Cabral</CoffeeInfoOption>
                    <CoffeeInfoOption value={200} >Jack</CoffeeInfoOption>
                    <CoffeeInfoOption value={300} >Salomão</CoffeeInfoOption>
                    <CoffeeInfoOption value={400} >Luz</CoffeeInfoOption>
                </CoffeeInfoSelect>
            </CoffeeInfo>
            <CoffeeInfo>
                <CoffeeInfoSelect value={update.inputValue('shipment')} onChange={(e:any) => { update.updateField(e.target.value, 'Shipment')}}>
                    {renderShipments()}
                </CoffeeInfoSelect>
            </CoffeeInfo>
            <CoffeeInfo>
                <CoffeeInfoCheckbox type="checkbox" checked={update.checked()} onChange={(e:any) => { update.updateField(e.target.checked, 'Display')}}/>
            </CoffeeInfo>
            <CoffeeInfo>
           
                <CoffeeInfoButton onClick={() => update.updateCoffeeData(coffee && coffee.id)} >
                    Salvar
                </CoffeeInfoButton>
            </CoffeeInfo>
        
        </CoffeeLineContainer>
    );
    }
