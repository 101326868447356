import React, { useReducer, useEffect, useState } from 'react'
import SampleContext from './SampleContext.js'
import SampleReducer from './SampleReducer.js'
import {
    ADD_TO_LIST,
    LOAD_LIST,
    CLEAR_LIST,
    REMOVE_FROM_LIST,
    ADD_EMAIL
} from '../types.js'

import axios from 'axios'
import { server, acceptedCookies } from '../../common.js'

const SampleState = props => {

    const splits = acceptedCookies() ?
        document.cookie.split(';') : []
    const jwt = splits.filter(item => item.includes('jwt'))[0] ?
        splits.filter(item => item.includes('jwt'))[0].split('=')[1] : false

    const initialState = {
        offerList: []
    }

    const [state, dispatch] = useReducer(SampleReducer, initialState)
    const listToString = list => list.reduce((prev, item, i) => (list.length === 1 ? `${item.id}:${item.farmCode}` :`${prev},${item.id}:${item.farmCode}`), "")
    const stringToList = str => str ? str.split(',')
        .filter(el => el)
        .map(el => {
            const splits = el.split(':')
            return {
                id: parseInt(splits[0]),
                farmCode: parseInt(splits[1])
            }
        })
        : []

    useEffect(() => {
        const cookie = acceptedCookies() ? document.cookie : ""
        if (cookie.includes('offerList')) {
            const offerString = splits.filter(item => item.includes('offerList'))[0].split('=')[1]
            let requestedSamples = [{id: 0, farmCode: ''}], items = []
            const oldList = offerString.split(',').map((data, i) => {
                items = data.split(':')
                if(i === 0){
                    requestedSamples[0].id = parseInt(items[0])
                    requestedSamples[0].farmCode =  items[1]
                }
                else {
                    requestedSamples.push({id: parseInt(items[0]), farmCode: items[1]})
                }
            })
            dispatch({ type: LOAD_LIST, payload: requestedSamples })
        }

    }, [])

    const addToList = (cafe) => {
        const list = [...state.offerList]
        let i = 0
        list.forEach(item => {
            if (item.farmCode !== cafe.farmCode) {
                i = i + 1
            }
        })
        if (i == list.length) {
            list.push({
                id: cafe[0].id,
                farmCode: cafe[0].farmCode
            })
        }
        let listString = listToString(list)
        listString = listString.charAt(0) === "," ? listString.substring(1) : listString  
        if (acceptedCookies()) {
            document.cookie = `offerList=${listString}; max-age=${60 * 60 * 24}; path=/`
        }
        return dispatch({ type: ADD_TO_LIST, payload: list })

    }

    const addEmail = (email) => {
        console.log("SAMPLE CONTEXT", email);
        return dispatch({ type: ADD_EMAIL, payload: email })
    }

    const offerListCode = () => {
        const newList = []
        const offerString = splits.filter(item => item.includes('offerList'))[0].split('=')[1]
        const oldList = stringToList(offerString)
        oldList.forEach(item => {
            newList.push(item.farmCode)
        })

        return newList
    }

    const removeFromList = (farmCode) => {
        const list = [...state.offerList].filter(item => item.farmCode != farmCode)
        const listString = listToString(list)
        if (acceptedCookies()) {
            document.cookie = `offerList=${listString}; max-age=${60 * 60 * 24}; path=/`
        }
        updateList(list)
        return dispatch({ type: REMOVE_FROM_LIST, payload: list })
    }

    const updateList = offerList => {
        const listString = listToString(offerList)
        if (acceptedCookies()) {
            document.cookie = `offerList=${listString}; max-age=${60 * 60 * 24}; path=/`
        }
        return dispatch({ type: LOAD_LIST, payload: offerList })
    }

    const clearList = () => dispatch({ type: CLEAR_LIST })

    return <SampleContext.Provider
        value={{
            addToList,
            offerList: state.offerList,
            clearList,
            removeFromList,
            updateList,
            offerListCode,
            addEmail,
            email: state.email
        }}
    >
        {props.children}
    </SampleContext.Provider>
}

export default SampleState