import React from 'react';

import '../styles/components/CoffeeTable.css';

import { CoffeeCard } from './CoffeeCard';

const CoffeeTable = (props) => {
    const {
        coffees,
    } = props

    return (
        <div className="new-card-container">
            {
                coffees.map(coffee => {
                    return <CoffeeCard
                        key={coffee.id}
                        coffee={coffee}
                        favoriteEvent={() => { console.log("favorite clicked") }}
                        commentEvent={() => { console.log("comment clicked") }}
                    />
                })
            }
        </div>
    );
}

export default CoffeeTable;