import Main from "components/templates/Main";
import React from "react";

const Chat = props => {
    const id = window.location.href.split('/')[4]
    const chat = `https://farmly-offerlist.bubbleapps.io/chat-roaster-producer?c=${id}`
    return (
        <>
        <Main titleHeader={"Chat"} >
         <iframe src={chat} style={{position:"fixed", top:"0", left:"0", top:"0", right:"0", width:"100%", height:"100%", border:"none", margin:"4.5%", padding:"0", overflow:"hidden", zIndex:"999999"}} allowFullScreen="true"></iframe>
        </Main>
        </>
    )
}


export default Chat