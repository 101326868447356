import React, { useEffect, useState } from 'react'
import './FarmlyLoading.css'

const loadAnim = require('../../assets/load.svg')

const FarmlyLoading = props => {
    const { display } = props

    useEffect(() => {
        if(display) {
            document.querySelector('.farmly-loading-container').style.display = 'flex'
        } else {
            document.querySelector('.farmly-loading-container').style.display = 'none'
        }
    }, [display])

    return (
        <div className="farmly-loading-container">
            <img src={loadAnim} alt=""/>
        </div>
    )
}

export default FarmlyLoading