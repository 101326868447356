import {
    USER_LOGGED_IN,
    USER_LOGGED_OUT,
    USER_IS_ADMIN
} from '../types'

export default (state, action) => {
    switch (action.type) {
        case USER_LOGGED_IN:
            return {
                ...state,
                user: { ...action.payload }
            }
        case USER_LOGGED_OUT:
            return {
                ...state,
                user: null
            }
        case USER_IS_ADMIN:
            return {
                ...state,
                user: { ...action.payload },
                isAdmin: true
            }
        default:
            return {
                state
            }
    }
}