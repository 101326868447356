import React, { FunctionComponent } from 'react'

import {
    TextField
} from '@material-ui/core';
import CartModel from 'modules/SampleCart/models/cartModel';

interface InputLabelRoasterInformationProps {
    label: string,
    attribute: string,
    sampleCart: any,
    setSampleCart: React.Dispatch<React.SetStateAction<CartModel>>,
    setChangesHappen: React.Dispatch<React.SetStateAction<boolean>>,
}

const InputLabelRoasterInformation: FunctionComponent<InputLabelRoasterInformationProps> = (props) => {
    return (
        <>
            <TextField
                id="outlined-name"
                label={props.label}
                margin="normal"
                variant="outlined"
                type="text"
                value={props.sampleCart[props.attribute]}
                onChange={(e) => { props.setSampleCart({ ...props.sampleCart, [props.attribute]: e.target.value }); props.setChangesHappen(true) }}
            />
        </>
    );
}
export default InputLabelRoasterInformation;