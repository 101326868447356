import axios from 'axios'
import { server } from 'common'
import Main from '../templates/Main.jsx'
import React, {useState, useEffect} from 'react'
import './ExchangeRate.css'
import Select from "react-select";

const ExchageRate = props => {
    const [coffeeExchageRate, setCoffeeExchageRate] = useState([{ city: '', sack_price: '', variation_with_percentage: '' }])
    const [coffeeExchageRateAtDisplay, setCoffeeExchageRateAtDisplay] = useState([{ city: '', sack_price: '', variation_with_percentage: '' }])
    const [coffeeExchageRateOptions, setCoffeeExchageRateOptions] = useState([{ value: '', label: '' }])
    const [coffeeExchangeRateTitle, setCoffeeExchangeRateTitle ] = useState("")

    useEffect(() => {
        const getCoffeeExchageRate = async () => {
            try {
                let filteredDates = []
                let coffeeExchageRateData = await axios.post(`${server}/scrapeDataFromAWebsite`, {link: 'https://www.noticiasagricolas.com.br/cotacoes/cafe/cafe-arabica-mercado-fisico-tipo-6-7'})
                setCoffeeExchageRate(coffeeExchageRateData.data)
                console.log("olha o valor da cotação", coffeeExchageRateData.data)
                const data = coffeeExchageRateData.data.filter(c => c.date === new Date().toLocaleDateString() || c.date == coffeeExchageRateData.data[0].date)
                setCoffeeExchangeRateTitle("Fechamento: " + data[0].date)
                filteredDates = coffeeExchageRateData.data.map((data, i) => {
                    let option = {
                        label: "",
                        value: ""
                      }
                    if(!filteredDates.some(c => c === data.date)){
                        filteredDates.push(data.date)
                        option.label = data.date
                        option.value = data.date
                        return option
                    }
                })
                setCoffeeExchageRateOptions(filteredDates.filter(c => c != undefined))
                setCoffeeExchageRateAtDisplay(data)
                document.querySelector('.loader').style.display = 'none'
            }
            catch(error){
                alert('an error occured while fetching data');
                console.error(error)
            }
            
        }

        getCoffeeExchageRate()
    }, [])


    const renderTable = (date) => {
        let tableBody = document.querySelector('#exchageRateCoffee').getElementsByTagName('tbody')[0];
        let tableRows = tableBody.rows.length -1 
        let filteredDates = coffeeExchageRate.filter(c => c.date === date)
        setCoffeeExchangeRateTitle("Fechamento: " + date)

        while(tableRows > -1 ){
            tableBody.deleteRow(tableRows)
            tableRows--
        }
        filteredDates.map((data, i) => {
        const tr = tableBody.insertRow(i)
        const city = tr.insertCell(0);
        const sack_price = tr.insertCell(1);
        const variation = tr.insertCell(2);
        city.innerHTML = data.city;
        city.className = "exchange-rate-table-td"
        sack_price.innerHTML = data.sack_price;
        sack_price.className = "exchange-rate-table-td"
        variation.innerHTML = data.variation_with_percentage
        variation.className = "exchange-rate-table-td"
        })
    
    } 
    


    return (
        <>
     <Main titleHeader="Cotação">
        <h3>Café Arábica-Mercado Físico (Tipo 6/7 Bebida Dura Bica Corrida)</h3>
        <Select
            id="shipping-country"
            placeholder={"Ver cotação em uma data: "}
            onChange={e => renderTable(e.value)}        
            options={coffeeExchageRateOptions}>    
        </Select>
        <div className='loader' style={{display: 'block'}}></div>
        {coffeeExchageRate.length > 1 &&
        <table id="exchageRateCoffee" className="exchange-rate-table">
            <caption className="fechamento">{coffeeExchangeRateTitle}</caption>
            <thead>
            <tr>
            <th className='exchange-rate-table-th'>Cidade</th>
            <th className='exchange-rate-table-th'>Preço da Saca</th>
            <th className='exchange-rate-table-th'>Variação</th>
            </tr>
            </thead>
            <tbody style={{textAlign: 'center'}} >
                {
                    coffeeExchageRateAtDisplay.map((data) => {
                        return(
                            <>
                            <tr>
                            <td className='exchange-rate-table-td'>{data.city}</td>
                            <td className='exchange-rate-table-td'>{data.sack_price}</td>
                            <td className='exchange-rate-table-td'> {data.variation_with_percentage}</td>
                            </tr>
                            </>
                        )
                    })
                }
                
            </tbody>
        </table>
}
        </Main>

        </>

    )
   
}

export default ExchageRate