import React, { useState, useEffect } from "react";
import axios from "axios";
import { server, acceptedCookies } from "../../common.js";
import AdminUserCard from "./AdminUserCard";
import SearchBarOld from './SearchBar/SearchBarOld.jsx'

import "./ControlPanel.css";

const ProducerPanel = (props) => {
  const [query, setQuery] = useState("");

  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [hasMoreCards, setHasMoreCards] = useState(true);
  const [loading, setLoading] = useState(true);



  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  const handleScroll = async () => {
    if (
      window.innerHeight + document.documentElement.scrollTop <
        document.documentElement.offsetHeight - 300 ||
      hasMoreCards === false
    ) {
      return;
    }
    setPage(page + 1);
    await getCards(page);
    setLoading(false);
  };
  
  useEffect(() => {
    getCards(0);
  }, []);
  
  const getCards = async (atPage = 0) => {
    setLoading(true);
    const queryString =
      `${server}/allproducers` + (query && `?search=${query}&`);
    await axios
      .get(queryString)
      .then((res) => {
        if (res.data.length === 0) {
          setHasMoreCards(false);
          setLoading(false);
        } else {
          if (hasMoreCards === false) {
            setHasMoreCards(true);
          }
          const newUsers = atPage === 0 ? [] : [...users];
          newUsers.push(...res.data);
          setUsers(newUsers);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getUsersInit(0);
  }, []);

  const getUsersInit = async (atPage = 0) => {
    const queryString =
      `${server}/allproducers` + (query && `?search=${query}&`);
    const newUsers = [];
    const cookies = acceptedCookies()
      ? document.cookie
          .split(";")
          .filter((item) => item.includes("jwt"))[0]
          .split("jwt=")[1]
      : null;

    await axios
      .post(queryString, { jwt: cookies })
      .then((res) => {
        newUsers.push(...res.data.sort(compare));
      })
      .catch((_) => {});
    setUsers(newUsers);
  };

  function compare(a, b) {
    const idA = a.id;
    const idB = b.id;

    let comparison = 0;
    if (idA < idB) {
      comparison = 1;
    } else if (idA > idB) {
      comparison = -1;
    }
    return comparison;
  }

  const searchUsers = () => {
    getUsersInit(0);
  };

  const renderCards = (users) =>
    users.map((user) => {
      return (
        <AdminUserCard
          user={user}
          width="40vw"
          height="24em"
          key={user.id}
          isProd={true}
        />
      );
    });

  return (
    <div className="control-panel-container">
      <label style={{ fontSize: "2em" }}>Total: {users.length}</label>
      <SearchBarOld query={query} setQuery={setQuery} submitQuery={searchUsers} />
      <div id="prod-panel" className="control-panel-coffees">
        {renderCards(users)}
      </div>
    </div>
  );
};

export default ProducerPanel;
