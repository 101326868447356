import { storage } from '../../firebase'


const parseCoffeeImage = async (table: string, images: string): Promise<any[]> => {
    
    const coffeeImages = await Promise.all(images.split(',').map(async(img) => {
       const image = await storage.ref(table).child(img).getDownloadURL()
        return image
    }))
    
    return coffeeImages
}

export {
    parseCoffeeImage
}