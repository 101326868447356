import React, {useState} from 'react'

const Cookies = props => {

    const [cookie, setCookie] = useState(false)
    const addCookie = () => {
        document.cookie='FARMly;max-age=315360000;path=/'
        setCookie(true)
    }

    return (
        <>
            <button style={{position: 'fixed', top: '25%', height: '20vw', backgroundColor: 'rgb(27, 98, 94)', color: 'white', width: '100%', fontWeight: '700'}} onClick={addCookie}>ATIVAR DOMINAÇÃO MUNDIAL</button>
            {cookie && <h2 style={{position: 'fixed', top: '68%', width: '100%', textAlign: 'center'}}>O MUNDO É NOSSO!</h2>}
        </>
    )
}

export default Cookies