import styled from "styled-components";

export const CoffeeLineContainer = styled.tr`
height: 3vw;
`

export const CoffeeInfo = styled.td`
    width: 10vw;
    text-align: center;
`

export const CoffeeInfoInput = styled.input`
    width: 60%;
    border-radius: 5px;
    border: none;   
    text-align: center;
   
`

export const CoffeeInfoButton = styled.button`
    height: 2vw;
    width: 5.5vw;
    border-radius: 15px;
    cursor: pointer;
    background: #C85B56;
    border: none;
    color: white;
    :hover {
        background: #C85B06;
    } 
   
`
export const CoffeeInfoCheckbox = styled.input`
    height: 1.5vw;
    width: 1.5vw;
    border-radius: 5px;
`

export const CoffeeInfoSelect = styled.select`
   color: #1A5F5C;
    padding: 0.7vw;
    border-radius: 0.5vw;
    cursor: pointer;
    width: 10vw;
    height: 4vw;
    :hover {
        background-color: white;
        color: #1A5F5C;
        border-radius: 0.5vw;
    } 
`

export const CoffeeInfoOption = styled.option`

`
