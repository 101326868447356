import React, { FunctionComponent } from 'react'
import {
    Accordion,
    AccordionSummary,
    Typography,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faArrowDown
} from '@fortawesome/free-solid-svg-icons'
import InputLabelRoasterInformation from "../InputLabelRoasterInformation";

import { InputGroup } from './style';
import CartModel from 'modules/SampleCart/models/cartModel';

interface AccordionRoasterDataProps {
    sampleCart: CartModel,
    setSampleCart: React.Dispatch<React.SetStateAction<any>>,
    setChangesHappen: React.Dispatch<React.SetStateAction<boolean>>,
}

const AccordionRoasterData: FunctionComponent<AccordionRoasterDataProps> = (props) => {
    return (
        <>
            <Accordion>
                <AccordionSummary
                    expandIcon={<FontAwesomeIcon icon={faArrowDown} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>Você pode aqui adiantar o preenchimento dos dados do torrefador</Typography>
                </AccordionSummary>
                <InputGroup>
                    <InputLabelRoasterInformation
                        label="Código postal:"
                        attribute="postalCode"
                        sampleCart={props.sampleCart}
                        setSampleCart={props.setSampleCart}
                        setChangesHappen={props.setChangesHappen}
                    />
                    <InputLabelRoasterInformation
                        label="Nome da torrefadora:"
                        attribute="roastery"
                        sampleCart={props.sampleCart}
                        setSampleCart={props.setSampleCart}
                        setChangesHappen={props.setChangesHappen}
                    />
                    <InputLabelRoasterInformation
                        label="Endereço:"
                        attribute="address"
                        sampleCart={props.sampleCart}
                        setSampleCart={props.setSampleCart}
                        setChangesHappen={props.setChangesHappen}
                    />
                    <InputLabelRoasterInformation
                        label="Nome do torrefador:"
                        attribute="name"
                        sampleCart={props.sampleCart}
                        setSampleCart={props.setSampleCart}
                        setChangesHappen={props.setChangesHappen}
                    />
                    <InputLabelRoasterInformation
                        label="Número do torrefador:"
                        attribute="phoneNumber"
                        sampleCart={props.sampleCart}
                        setSampleCart={props.setSampleCart}
                        setChangesHappen={props.setChangesHappen}
                    />
                    <InputLabelRoasterInformation
                        label="País:"
                        attribute="country"
                        sampleCart={props.sampleCart}
                        setSampleCart={props.setSampleCart}
                        setChangesHappen={props.setChangesHappen}
                    />
                    <InputLabelRoasterInformation
                        label="VAT(caso tenha):"
                        attribute="vat"
                        sampleCart={props.sampleCart}
                        setSampleCart={props.setSampleCart}
                        setChangesHappen={props.setChangesHappen}
                    />
                </InputGroup>
            </Accordion>
        </>
    );
}
export default AccordionRoasterData;
