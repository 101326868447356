import React, { Fragment, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import ButtonBase from "@material-ui/core/ButtonBase";

import '../../../styles/profile/components/UserFarmlyClub.css';
import UserFarmlyClubList from './UserFarmlyClubList.jsx';

import { language } from '../../../common'

const wpIcon = require("../../../assets/svg/wpp-colored.svg");

const UserFarmlyClub = props => {

    const {windowSize,userDataOrigin,inviteCode} = props;

    const [showContent, setShowContent] = useState(false);

    const clubLink = 'https://farmlyclub.com/ReceivedLink?invite='+inviteCode;

    return (
        <Fragment>

            <div className="new-profile user-accordion-button">
                <div className="user-accordion-action">
                    <div className={showContent ? "user-accordion user-accordion-opened new-farmly-button " : "user-accordion user-accordion-closed new-farmly-button " }
                    onClick={() => { setShowContent(!showContent) }}>
                        Farmly Club
                        {showContent?<FontAwesomeIcon icon={faAngleUp} className="user-accordion-icon-up" />:<FontAwesomeIcon icon={faAngleDown} className="user-accordion-icon-down" />}
                    </div>
                </div>
            </div>
            {
                showContent ?
                    <div className="user-farmly-club">
                        <div className="new-profile user-farmly-club-text">
                            <p>{{pt:"Indique produtores e ganhe 0,5% do que eles venderem pela Farmly.",es:"Recomiende a los cultivadores y gane el 0,5% de lo que vendan a través de Farmly.",en:"Refer producers and earn 0.5% of what they sell through Farmly."}[language]}</p>
                            <p>
                                {{
                                    pt:"Você pode convidar quantos produtores quiser! Fazemos isso para motivar que os produtores cooperem e trabalhem juntos, para produzir um café, cada vez melhor. Para indicar um produtor basta copiar o link clicando no botão abaixo e garantir que seus parceiros façam o cadastro usando ele:",
                                    es:"Puede invitar a tantos agricultores como quiera. Lo hacemos para motivar a los productores a cooperar y trabajar juntos para producir un café cada vez mejor. Para indicar un productor, sólo tiene que copiar el enlace haciendo clic en el botón de abajo y asegurarse de que sus socios se registran con él:",
                                    en:"You can invite as many producers as you like! We do this to motivate producers to cooperate and work together to produce an increasingly better coffee. To refer a producer just copy the link by clicking on the button below and make sure your partners register using it:",
                                }[language]}
                            </p>
                            <ButtonBase className="new-profile new-farmly-button f-button-t user-farmly-club-link-button"
                                onClick={(e) => {navigator.clipboard.writeText(clubLink)}}
                            >
                                <img
                                    src={wpIcon}
                                    alt="WhatsApp Icon"
                                    className="user-farmly-club-wpp-icon"
                                />
                                {/* <FontAwesomeIcon icon={faWhatsapp} className="user-farmly-club-wpp-icon" /> */}
                                {windowSize.w > 768?clubLink:'Compartilhar link'}
                            </ButtonBase>
                        </div>
                        <UserFarmlyClubList inviteCode={inviteCode}/>
                    </div>
                : null
            }
        </Fragment>
    )
}

export default UserFarmlyClub