import React, { useState } from 'react'
import './PasswordRecovery.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import { server, isPt, isCo, farmlyTexts } from '../../common'

const PasswordRecovery = props => {

    const { toggleRecovery } = props

    const [email, setEmail] = useState(props.email)

    return (
        <div id="password-recovery-bg" onClick={event => {
            event.target.id === 'password-recovery-bg' && toggleRecovery()
        }}>
            <div id="password-recovery-box">
                <div className="close-modal-button" onClick={toggleRecovery}>
                    <FontAwesomeIcon className="close-modal-icon" icon={faTimes} />
                </div>
                <div id="password-recovery-wrap">
                    <div id="password-recovery-content">
                        <label id="recovery-title">{isPt() ? 'Recuperar senha' : (isCo() ? 'Recuperar la contraseña' : 'Recover password')}</label>
                        <div id="recovery-field-container">
                            <label htmlFor="recovery-email-input" id="recovery-email-label">Email</label>
                            {/* <span id="recovery-email-input"
                                value={email} >
                                    {`${email}`}
                                </span> */}
                            <label>{`${email}`}</label>
                            {email === "" ?
                                <div className="alert-email-fill-box">
                                    {
                                        isPt() ? "Digite o seu email na caixa de texto da página!"
                                            :
                                            (isCo() ? "¡Ingrese su correo electrónico en el cuadro de texto de la página!"
                                                :
                                                "Enter your email in the text box on the page!")
                                    }
                                </div>
                                :
                                <></>
                            }

                        </div>
                        <div id="recovery-submit" onClick={() => {
                            axios.post(`${server}/recover`, {
                                email
                            })
                                .then(res => {
                                    const { answer } = res.data
                                    document.querySelector('#recovery-field-container').style.display = 'none'
                                    document.querySelector('#recovery-submit').style.display = 'none'
                                    document.querySelector('#recovery-title').style.marginTop = '0'

                                    if (answer === 'email not found') {
                                        document.querySelector('#recovery-title').innerHTML = (isPt() ? 'Email não cadastrado!' : (isCo() ? '¡Correo electrónico no registrado!' : 'Email not registered!'))
                                        document.querySelector('#recovery-title').style.fontSize = 'x-large'
                                    } else if (answer === 'success') {
                                        document.querySelector('#recovery-title').innerHTML = (isPt() ? farmlyTexts.passwordRecoveryMailSent_pt : farmlyTexts.passwordRecoveryMailSent_en)
                                    }
                                })
                                .catch(err => {

                                })
                        }}>
                            {isPt() ? 'Enviar' : (isCo() ? 'Enviar' : 'Send')}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PasswordRecovery