import styled from 'styled-components';

export const AddButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start
`
export const AddButton = styled.button`
    width: 100px;
`


