import React, { useContext, useEffect, useState, Fragment } from 'react'
import "./CartScreen.css"

import Main from '../templates/Main.jsx'
import CartContext from '../../context/cart/CartContext'
import UserContext from '../../context/user/UserContext'
import FarmlyInput from '../templates/FarmlyInput.jsx'
import FarmlyModal from '../templates/FarmlyModal.jsx'
import CoffeeItem from './CoffeeItem.jsx'
import ErrorPage from '../templates/Error.jsx'
import { Link, useHistory } from 'react-router-dom'
import Countries from '../../assets/countries.json'
import Select from 'react-select';
import DataList from '../templates/Datalist.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import Snackbar from '@material-ui/core/Snackbar';
import Paper from '@material-ui/core/Paper'
import jsPDF from 'jspdf'
import axios from 'axios'
import { server, isPt, farmlyTexts, isBr, language, isCo } from '../../common.js'
import * as pricings from '../templates/pricing.js'

const CartScreen = props => {

    const cartContext = useContext(CartContext)
    const userContext = useContext(UserContext)
    const history = useHistory()

    const [coffees, setCoffees] = useState([])
    const [timeCart, setTimeCart] = useState()
    const [coupon, setCoupon] = useState("")
    const [loginPrompt, setLoginPrompt] = useState(false)
    const [showInfo, setShowInfo] = useState(false)
    const [showInfoAddress, setShowInfoAddress] = useState(false)
    const [conclude, setConclude] = useState(false)
    const [roasterData, setRoasterData] = useState({})
    const [paymentData, setPaymentData] = useState({
        euro_value: 0,
        farmly_fee: 0,
        logistics_fee: 0,
        import_duties: 0,
        import_duties_adjustment: 0
    })
    const [countriesInfo, setCountriesInfo] = useState([])
    const [deliveryOption, setDeliveryOption] = useState('deliver') // deliver || pick-up
    const [shippingCountry, setShippingCountry] = useState(null)
    const [shippingFee, setShippingFee] = useState(0.0)
    const [countryUnlisted, setCountryUnlisted] = useState(false)
    const [delivery, setDelivery] = useState(true)

    const getRoasterData = async () => {
        if (userContext.user) {
            await axios.get(`${server}/roaster/${userContext.user.id}`)
                .then(res => {
                    setRoasterData(res.data)
                    setShowInfo(true)
                })
        } else {
            setLoginPrompt(true)
        }
    }

    useEffect(() => {
        axios.get(`${server}/paymentData`)
            .then(data => {
                setPaymentData(data.data)
            })
        axios.get(`${server}/countries`)
            .then(({ data }) => {
                setCountriesInfo(data)
            })
    }, [])

    useEffect(() => {
        if (shippingCountry != null) {
            const sacks = totalSacks(coffees)
            const countryName = shippingCountry
            const countryLC = shippingCountry.toLowerCase()
            const countryFilter = countriesInfo.filter(country => {
                const nameLC = country.name.toLowerCase()
                return nameLC.includes(countryLC) || countryLC.includes(nameLC)
            })
            if (countryFilter.length == 0) {
                setCountryUnlisted(true)
                setShippingFee(0)
            } else {
                setCountryUnlisted(false)
                const country = countryFilter[0]
                const pallets = Math.ceil(totalSacks(coffees) / 10) // 10 é a quantidade de sacas por pallet
                if (pallets <= 5) {
                    setShippingFee(country.palletPrices[pallets])
                } else {
                    setShippingFee(country.palletPrices[5] + (country.palletPrices['extra'] * (pallets - 5)))
                }
            }
        }

    }, [shippingCountry, coffees]) // coffees colocado aqui para quando houver mudanças de quantidade de cafés atualizar o preço do envio

    useEffect(() => {
        if (cartContext.cartList.length > 0) {

            const cartString = cartContext.cartList.reduce((prev, item) => `${item.id},${prev}`, "")

            axios.get(`${server}/coffeesList/${cartString}`)
                .then(res => {
                    let coffees = res.data
                    cartContext.cartList.forEach(item => {
                        coffees = coffees.map(coffee => {
                            return coffee.id === item.id ?
                                { ...coffee, quantity: Math.min(item.quantity, parseInt(coffee.sackRemain)) } : coffee
                        })
                    })
                    setCoffees(coffees)
                })
        } else setCoffees([])
    }, [cartContext])

    const totalSacks = coffees => coffees
        .reduce((total, coffee) => total + coffee.quantity, 0)

    const totalProducersPrice = () => pricings.toEuro(pricings.totalProducersPrice(coffees, paymentData), paymentData, { euro_value: coffees[0].euro_value })
    const totalPrice = () => pricings.toEuro(pricings.totalPrice(coffees, paymentData), paymentData, { euro_value: coffees[0].euro_value }) + shippingFee
    const logisticsFee = coffee => coffee.origin == "Brazil" ? pricings.toEuro(pricings.logisticsFee(coffee, paymentData), paymentData, coffee) : pricings.logisticsFee(coffee, paymentData)
    const totalLogisticsFee = coffees => pricings.toEuro(pricings.totalLogisticsFee(coffees, paymentData), paymentData, { euro_value: coffees[0].euro_value })
    const importDuties = coffee => coffee.origin == "Brazil" ? pricings.toEuro(pricings.importDuties(coffee, paymentData), paymentData, coffee) : pricings.importDuties(coffee, paymentData)
    const totalImportDuties = coffees => pricings.toEuro(pricings.totalImportDuties(coffees, paymentData), paymentData, { euro_value: coffees[0].euro_value })
    const farmlyFee = coffee => coffee.origin == "Brazil" ? pricings.toEuro(pricings.farmlyFee(coffee, paymentData), paymentData, coffee) : pricings.farmlyFee(coffee, paymentData)
    const totalFarmlyFee = coffees => pricings.toEuro(pricings.totalFarmlyFee(coffees, paymentData), paymentData, { euro_value: coffees[0].euro_value })

    const setQuantity = (value, id) => {
        const updatedCoffees = coffees.map(el =>
            el.id === id ? { ...el, quantity: value } : el)
        setCoffees(updatedCoffees)
    }

    const renderCoffes = () => coffees.map(coffee => <CoffeeItem key={`cart-item-${coffee.id}`}
        id={coffee.id} farmCode={coffee.farmCode} producer={coffee.producer} removeFromCart={cartContext.removeFromCart} brazil={coffee.origin == "Brazil" ? true : false}
        price={coffee.origin == "Brazil" ? pricings.toEuro(coffee.sackPrice, paymentData, coffee) : parseFloat(coffee.sackPrice)} setQuantity={setQuantity} sacksLeft={coffee.sackRemain}
        quantity={coffee.quantity} producerFee={coffee.origin == "Brazil" ? pricings.toEuro(coffee.sackPrice, paymentData, coffee) * coffee.quantity : coffee.sackPrice * coffee.quantity}
        paymentData={{
            farmly_fee: farmlyFee(coffee),
            logistics_fee: logisticsFee(coffee),
            import_duties: importDuties(coffee)
        }} />)

    const renderEmpty = () => <div className="empty-cart" >
        {isPt() ? farmlyTexts.cartEmpty_pt : farmlyTexts.cartEmpty_en}
    </div>

    const updateCart = () => {
        const cartList = coffees.map(coffee => { return { id: coffee.id, quantity: coffee.quantity } })
        cartContext.updateCart(cartList)
    }
    const updateRoasterPayment = async () => {
        await axios.post(`${server}/updatePaymentInfo/${roasterData.id}`, roasterData)
            .then(res => {
                proceedToCheckout()
            })
        // var doc = new jsPDF('p', 'pt')
        // doc.text(20, 20, 'DEFAULT TEXT')
        // doc.setFont('carrier')
        // doc.setFontType('normal')
        // doc.text(20, 30, 'AGAINNN')
        // doc.save('FARMly-invoice.pdf')
    }

    const proceedToCheckout = async () => {
        const paymentOptions = {
            amount: totalPrice().toFixed(2),
            description: 'FARMly',
            roasterId: userContext.user.id,
            coffees: coffees,
            roasterData: roasterData,
            shippingCountry: shippingCountry,
            producersPrice: totalProducersPrice().toFixed(2),
            logisticsFee: totalLogisticsFee(coffees).toFixed(2),
            importDuties: totalImportDuties(coffees).toFixed(2),
            farmlyFee: totalFarmlyFee(coffees).toFixed(2),
            shippingFee: shippingFee.toFixed(2)
        }

        await axios.post(`${server}/payments/`, paymentOptions)
        setShowInfo(false)
        cartContext.setControlTimer({ control: true, zero: true })
        cartContext.clearCart()
        history.push({
            pathname: '/thanksForBuying',
            state: { payment: paymentOptions }
        })
        history.go()
    }

    const renderOpts = () => {
        const country = []
        Countries.map((opt) => {
            country.push({
                value: opt,
                label: opt
            })
        })
        return country
    }

    const plusTime = () => {
        cartContext.setControlTimer({ control: true, zero: false })
    }

    const renderDeliveryOptions = option => (option === 'deliver' ? (
        <div className="shipping-fees">
            <div className="shipping-fee-field">
                <label htmlFor="shipping-country" className="shipping-fee-label">{isPt() ? 'País' : 'Country'}</label>
                {/* <input type="text" className="shipping-fee-input" id="shipping-country"
                    placeholder="Country" onChange={e => setShippingCountry(e.target.value)}
                    value={shippingCountry} list="country-list" /> */}
                <Select
                    id="shipping-country"
                    placeholder={isPt() ? 'País' : 'Country'}
                    onChange={e => setShippingCountry(e.value)}
                    options={renderOpts()}
                >
                </Select>
                <label className="shipping-fee-value">{countryUnlisted ?
                    <div>
                        <label>
                            We cannot calculate the shipping fees to your country automatically at the moment. Please get in touch so that we can help you figure it out!
                        </label>
                        <br />
                        <br />
                        <FontAwesomeIcon className="whatsapp-icon-cart" icon={faWhatsapp} />
                        <label> Click </label>
                        <a target="_blank" rel="noopener noreferrer" href={`https://api.whatsapp.com/send?phone=+5527999802545`}>
                            <label>here</label>
                        </a>
                        <label> to send us a whatsapp message!</label>
                        <br />
                        <br />
                        <FontAwesomeIcon className='footer-contacts-icon' icon={faEnvelope} />
                        <label> Click </label>
                        <a target="_blank" rel="noopener noreferrer" href={`https://api.whatsapp.com/send?phone=+5527999802545`}>
                            <label>here</label>
                        </a>
                        <label> to send us an email!</label>

                    </div>
                    :
                    <>
                        <div>
                            {isPt() ? 'Taxa de entrega: ' : 'Shipping fee:'}€{shippingFee}
                        </div>
                        <div className="shipping-fee-value-alert">
                            {coffees.length === 0 ?
                                (isPt() ? "Coloque ao menos um café no carrinho para conseguir calcular o frete."
                                    :
                                    (isCo() ? "Ponga al menos un café en el carrito para poder calcular el envío."
                                        :
                                        "Put at least one coffee in the cart to be able to calculate shipping."))
                                : ""
                            }
                        </div>
                    </>
                }</label>
                {/* <div className="update-shipping" onClick={() => updateShippingFee(shippingCountry, totalSacks(coffees))}>
                    Update
                </div> */}
            </div>
        </div>
    ) : (
        <div className="pick-up">
            <div className="pick-up-text">
                {isPt() ? 'Você pode retirar o seu café conosco no Vollers, nossos parceiros em Rotterdam. ' : 'You can pick up your coffee with us at Vollers, our partners in Rotterdam.'}

                <br />
                <br />
                {isPt() ? 'Se você escolher essa opção, não terá nenhum custo adicional na taxa de entrega. ' : 'If you choose this option, there are no aditional shipping fees.'}
                <br />
                {isPt() ? 'O endereço é: ' : 'Their address is:'}
            </div>
            <div className="vollers-address">
                Vollers Holland B.V.
                <br />
                Bunschotenweg 141,
                <br />
                3089KB Rotterdam
            </div>
        </div>
    ))

    return (
        <>
            {loginPrompt && <FarmlyModal closeModal={() => setLoginPrompt(false)}>
                <label id="request-title">
                    Checkout
                </label>
                <div className="request-content">
                    <label id="request-text">{isPt() ? farmlyTexts.cartLogin_pt : farmlyTexts.cartLogin_en}</label>
                    <Link id='request-submit' style={{ textDecoration: "none" }} to={{ pathname: '/login', state: window.location.pathname }}>
                        Login
                    </Link>
                </div>
            </FarmlyModal>}

            {showInfo &&
                <FarmlyModal closeModal={() => setShowInfo(false)}>
                    <div className="confirm-info-container">
                        <label className="pricing-info-title">Confirm info to negociate the payment</label>
                        <div className="pricing-info-item">
                            <label className="pricing-info-label">Name:</label>
                            <FarmlyInput value={roasterData.name} setValue={value => setRoasterData({ ...roasterData, name: value })}
                                className="pricing-info-value" elementId="name" placeholder="name..." />
                        </div>
                        <div className="pricing-info-item">
                            <label className="pricing-info-label">Roastery:</label>
                            <FarmlyInput value={roasterData.roastery} setValue={value => setRoasterData({ ...roasterData, roastery: value })}
                                className="pricing-info-value" elementId="name" placeholder="roastery..." />
                        </div>
                        <div className="pricing-info-item">
                            <label className="pricing-info-label">VAT number:</label>
                            <FarmlyInput value={roasterData.vat} setValue={value => setRoasterData({ ...roasterData, vat: value })}
                                className="pricing-info-value" elementId="vat" placeholder="VAT..." />
                        </div>
                        <div className="pricing-info-item">
                            <label className="pricing-info-label">Email:</label>
                            <FarmlyInput value={roasterData.email} setValue={value => setRoasterData({ ...roasterData, email: value })}
                                className="pricing-info-value" elementId="email" placeholder="email..." />
                        </div>
                        <br />
                        <div className="checkout-buttons">
                            <div className="cart-checkout-button" onClick={() => { setShowInfo(false); setShowInfoAddress(true) }}>Next</div>
                        </div>
                    </div>
                </FarmlyModal>}

            {showInfoAddress &&
                <FarmlyModal closeModal={() => setShowInfoAddress(false)}>
                    <div className="confirm-info-container">
                        <label className="pricing-info-title">Confirm billing information</label>
                        <div className="pricing-info-item">
                            <label className="pricing-info-label">Billing Address:</label>
                            <FarmlyInput value={roasterData.address} setValue={value => setRoasterData({ ...roasterData, address: value })}
                                className="pricing-info-value-adm" elementId="name" placeholder="address..." />
                        </div>
                        <div className="pricing-info-item">
                            <label className="pricing-info-label">Postal Code:</label>
                            <FarmlyInput value={roasterData.postalCode} setValue={value => setRoasterData({ ...roasterData, postalCode: value })}
                                className="pricing-info-value-adm" elementId="name" placeholder="postal code..." />
                        </div>
                        <br />
                        <div className="profile-checkbox-item"
                            onClick={() => { setDelivery(!delivery) }}>
                            <div className="profile-checkbox"
                                style={{ backgroundColor: delivery ? 'var(--farmly-blueStone)' : 'white' }} />
                            <label className="profile-checkbox-label-adm-cart">Deliver to billing address</label>
                        </div>
                        {!delivery && <>
                            <div className="pricing-info-item">
                                <label className="pricing-info-label">Delivery Address:</label>
                                <FarmlyInput value={roasterData.delivery_address} setValue={value => setRoasterData({ ...roasterData, delivery_address: value })}
                                    className="pricing-info-value-adm" elementId="vat" placeholder="address..." />
                            </div>
                            <div className="pricing-info-item">
                                <label className="pricing-info-label">Postal Code:</label>
                                <FarmlyInput value={roasterData.delivery_postal_code} setValue={value => setRoasterData({ ...roasterData, delivery_postal_code: value })}
                                    className="pricing-info-value-adm" elementId="email" placeholder="postal code..." />
                            </div>
                        </>}
                        <br />
                        <div className="checkout-buttons">
                            <div className="cart-checkout-button" style={window.screen.width < 330 ? { marginLeft: '-120px' } : { display: 'auto' }} onClick={() => updateRoasterPayment()}>Checkout</div>
                        </div>
                    </div>
                </FarmlyModal>}
            {conclude && <FarmlyModal closeModal={() => setConclude(false)}>
                <label id="request-title">
                    Thank you for your purchase!!!
                </label>
                <div className="request-content">
                    <label id="request-text">Check your email box! We will be in touch to negociate the payment method! Remember to check the Span box either!</label>

                </div>
            </FarmlyModal>}

            <div className="cart-screen">
                <div className="cart-items-table">
                    <div className="cart-top-row">
                        <label className="cart-column-title">&nbsp;</label>
                        <label className="cart-column-title">{isPt() ? farmlyTexts.cartProduct_pt : farmlyTexts.cartProduct_en}</label>
                        <label className="cart-column-title">{isPt() ? farmlyTexts.cartPrice_pt : farmlyTexts.cartPrice_en}</label>
                        <label className="cart-column-title">{isPt() ? "Sacas" : "Bags"} </label>
                        <label className="cart-column-title">{isPt() ? farmlyTexts.cartProducer_pt : farmlyTexts.cartProducer_en}</label>
                    </div>
                    {coffees.length > 0 ?
                        renderCoffes() :
                        renderEmpty()
                    }
                    <div className="cart-bottom-row">
                        <FarmlyInput value={coupon} setValue={setCoupon} placeholder={isPt() ? farmlyTexts.cartCouponCode_pt : farmlyTexts.cartCouponCode_en}
                            styleClass="cart-coupon-input" />
                        <div className="cart-apply-coupon">{isPt() ? farmlyTexts.cartApplyCoupon_pt : farmlyTexts.cartApplyCoupon_en}</div>
                        {/*Botão de update no carrinho, nao sei direito pra que serve, acho que ainda pra nada*/}
                        {/* <div className="cart-update-button" onClick={updateCart}>{isPt() ? farmlyTexts.cartUpdateCart_pt : farmlyTexts.cartUpdateCart_en}</div> */}
                    </div>
                </div>
                <br />
                {/* {(cartContext.timer.minutes !== 0 || cartContext.timer.seconds !== 0) ?
                    <Paper elevation={3}>
                        <div className="timer-cel">
                            <div className="cart-timer">
                                {cartContext.timer.minutes == 0 ? '00' : cartContext.timer.minutes < 10 ? '0' + cartContext.timer.minutes : cartContext.timer.minutes}:{cartContext.timer.seconds == 0 ? '00' : cartContext.timer.seconds < 10 ? '0' + cartContext.timer.seconds : cartContext.timer.seconds}
                            </div>
                            <div>
                                {
                                    isPt() ?
                                        <div style={{ textAlign: 'center' }}>
                                            As sacas de café que estão no seu carrinho estão reservadas para você pelo tempo estabelecido ao lado.
                                    <br />
                                        Finalize sua compra ou continue comprando para aumentar o tempo que suas sacas ficarão reservadas!
                                        <Fragment>
                                                <br />
                                                <div className='time-modal'>
                                                    <Link to="/coffees">
                                                        <div className="edit-coffee-button" onClick={plusTime} > {isBr() ? 'Continuar comprando' : 'Keep buying'}</div>
                                                    </Link>
                                                </div>
                                            </Fragment>
                                        </div>
                                        :
                                        <div style={{ textAlign: 'center' }}>
                                            The coffees will be reserved in your cart for the time indicated
                                                    <br />
                                    Confirm your purchases or press "keep buying" to restart the reservation timer!
                                    <Fragment>
                                                <br />
                                                <div className='time-modal'>
                                                    <Link to="/coffees">
                                                        <div className="cart-timer-button" onClick={plusTime} > {isBr() ? 'Continuar comprando' : 'Keep buying'}</div>
                                                    </Link>
                                                </div>
                                            </Fragment>
                                        </div>
                                }
                            </div>
                        </div>
                    </Paper>
                    :

                    <Fragment></Fragment>
                } */}

                <div className="delivery-table">
                    <div className="delivery-table-top-row">
                        <label className="cart-column-title">{isPt() ? 'Entrega' : 'Shipping'}</label>
                    </div>
                    <div className="delivery-options">
                        <div className="delivery-option delivery-option-left"
                            onClick={() => setDeliveryOption('deliver')}
                            style={deliveryOption == 'deliver' ? { fontWeight: 600 } : {}}>
                            {isPt() ? 'Entrega' : 'Deliver'}
                        </div>
                        <div className="delivery-option delivery-option-right"
                            onClick={() => setDeliveryOption('pick-up')}
                            style={deliveryOption == 'pick-up' ? { fontWeight: 600 } : {}}>
                            {isPt() ? 'Retirada' : 'Pick up'}
                        </div>
                    </div>
                    <div className="delivery-details">
                        {renderDeliveryOptions(deliveryOption)}
                    </div>
                </div>
                <div className="cart-totals">
                    <div className="cart-totals-top-row">
                        <label className="cart-column-title">{isPt() ? farmlyTexts.cartTotals_pt : farmlyTexts.cartTotals_en}</label>
                    </div>
                    <div className="cart-totals-table">
                        <label className="cart-totals-label">{isPt() ? farmlyTexts.cartProducer_pt : farmlyTexts.cartProducer_en}</label>
                        <label className="cart-totals-value">
                            {coffees.length === 0 ?
                                "€0.00" :
                                `€${totalProducersPrice().toFixed(2)}`
                            }
                        </label>
                        <label className="cart-totals-label">{isPt() ? farmlyTexts.cartShipping_pt : "Logistics"}</label>
                        <label className="cart-totals-value">
                            {coffees.length === 0 ?
                                "€0.00" :
                                `€${totalLogisticsFee(coffees).toFixed(2)}`
                            }
                        </label>
                        <label className="cart-totals-label">{isPt() ? farmlyTexts.cartImport_pt : farmlyTexts.cartImport_en}</label>
                        <label className="cart-totals-value">
                            {coffees.length === 0 ?
                                "€0.00" :
                                `€${totalImportDuties(coffees).toFixed(2)}`
                            }
                        </label>
                        <label className="cart-totals-label">{isPt() ? farmlyTexts.cartFarmlyFee_pt : farmlyTexts.cartFarmlyFee_en}</label>
                        <label className="cart-totals-value">
                            {coffees.length === 0 ?
                                "€0.00" :
                                `€${totalFarmlyFee(coffees).toFixed(2)}`
                            }
                        </label>
                        <label className="cart-totals-label">{isPt() ? "Entrega" : "Shipping"}</label>
                        <label className="cart-totals-value">{countryUnlisted ?
                            "Not Included"
                            :
                            (
                                coffees.length === 0 ?
                                    "€0.00"
                                    :
                                    `€${shippingFee.toFixed(2)}`
                            )
                        }</label>
                        <label className="cart-totals-label">{isPt() ? farmlyTexts.cartTotal_pt : farmlyTexts.cartTotal_en}</label>
                        <label className="cart-totals-value">
                            {coffees.length === 0 ?
                                "€0.00"
                                :
                                `${totalPrice().toFixed(2)}`
                            }
                        </label>
                    </div>
                    <div className={`cart-totals-checkout-button ${coffees.length === 0 ? "totals-checkout-disabled" : ""}`}
                        onClick={coffees.length > 0 ? getRoasterData : () => { }}>
                        {coffees.length === 0 ?
                            isPt() ? farmlyTexts.cartEmptyButton_pt : farmlyTexts.cartEmptyButton_en
                            : isPt() ? farmlyTexts.cartCheckout_pt : farmlyTexts.cartCheckout_en}
                    </div>
                    <label className="total-observation" style={{ fontSize: '1em', marginTop: '5px' }}>
                        * Exc VAT.
                    </label>

                </div>
            </div>
        </>
    )
}

const CartPage = () => {
    const titleHeaderLanguage = { 'pt': 'Carrinho', 'en': 'Cart' }[language]
    const cartContext = useContext(CartContext)

    return (
        <Main titleHeader={titleHeaderLanguage} >
            {cartContext ? <CartScreen /> : <ErrorPage />}
        </Main>
    )
}

export default CartPage