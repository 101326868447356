import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { server, acceptedCookies } from '../../common.js'
import AdminUserCard from './AdminUserCard'
import SearchBar from '../templates/SearchBar.jsx'

import './ControlPanel.css'
import RequestCard from './RequestCard.jsx'


const AllRequests = (props) => {

    const { users, getUsersInit } = props
    const [requests, setRequests] = useState()


    const searchUsers = () => {
        getUsersInit(0)
    }

    useEffect(() => {
        axios.get(`${server}/allrequests`)
            .then(res => {
                const y = res.data.reverse()
                setRequests(y)
            })
    }, [])

    const renderCards = () =>
        requests.map((request) => {
            return (<RequestCard request={request} key={`${request.roaster_id}-${request.coffee_id}`} />)
        })

    return (<div className="control-panel-container">
        <label style={{ fontSize: "2em" }}>Total: {requests && requests.length}</label>
        <div id="prod-panel" className="control-panel-coffees">
            {requests && renderCards()}
        </div>
    </div>)
}

export default AllRequests