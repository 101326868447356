import React from "react";

import { language } from "../../../common";

import "../../../styles/profile/components/UserFarm.css";

const coffeeIcon = require("../../../assets/svg/Variedade.svg");
const calendarIcon = require("../../../assets/svg/Data da colheita.svg");
const altitudeIcon = require("../../../assets/svg/Altitude.svg");
const globeIcon = require("../../../assets/svg/Regiao.svg");

const UserFarm = (props) => {

	const {producer} = props;

	const farmKeys = ['region','farmHeight','varieties','harvestDate'];
	const farmKeysTranslate = {
		'region':{icon:globeIcon,pt:'Região',es:'Región',en:'Region'},
		'farmHeight':{icon:altitudeIcon,pt:'Altitude da Fazenda',es:'Altitud de la granja',en:'Farm Altitude'},
		'varieties':{icon:coffeeIcon,pt:'Variedades',es:'Variedades',en:'Varieties'},
		'harvestDate':{icon:calendarIcon,pt:'Data de colheita',es:'Fecha de la cosecha',en:'Harvest date'}
	};

	return (
		<div className="new-profile user-farm">
			{
				farmKeys.map(
					key => producer[key] && 
					<div className="new-profile user-farm-data" key={`user-farm-${key}`}>
						<img src={farmKeysTranslate[key].icon} alt={farmKeysTranslate[key][language]} />
						<h5 className='new-profile'id={`user-farm-${key}`}>
							<span className="user-farm-label-decoration">{farmKeysTranslate[key][language]}:&nbsp;</span>{producer[key]}
						</h5>
					</div>
					)
			}
		</div>
	)
}

export default UserFarm;
