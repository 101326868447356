import styled from "styled-components";

export const CoffeeLineContainer = styled.tr`
    height: 4vw;
`
export const CoffeeLink = styled.a`
    text-decoration: none;
`

export const CoffeeInfo = styled.td`
    width: 10vw;
    text-align: center;
`

export const CoffeeInfoLittle = styled.td`
    width: 2vw;
    text-align: center;
`

export const CoffeeInfoInput = styled.input`
    width: 50%;
    border-radius: 15px;
    color:rgb(27,98, 94);;
    border: solid;
    padding-left: 4vw;
`

export const CoffeeInfoButton = styled.button`
    height: 2vw;
    width: 5.5vw;
    border-radius: 15px;
    cursor: pointer;
    background: #C85B56;
    border: none;
    color: white;
    :hover {
        background: #C85B06;
    } 

`
export const DeleteShippingButton = styled.button `
cursor: pointer;
background: #C85B56;
border: none;
color: white;
display: flex;
justify-content: center;
`
export const CoffeeInfoSelect = styled.select`
   color: #1A5F1C;
    padding: 1vw;
    border-radius: 0.5vw;
    cursor: pointer;
    width: 10vw;
    height: 4vw;
    :hover {
        background-color: white;
        color: #1A5F5C;
        border-radius: 0.5vw;
    } 

`

export const CoffeeInfoOption = styled.option`

`

export const CoffeeInfoCheckbox = styled.input`
height: 1.5vw;
width: 1.5vw;
border-radius: 5px;
`
