import React, {useState, useEffect, Fragment} from 'react'
import { server, isPt, farmlyTexts } from '../../../common'
import './Roasters.css'
import FarmlyInput from '../../templates/FarmlyInput'
import axios from 'axios'



const NegociationProfile = (props) => {

    const {roasterData, paymentData} = props
    const [amount, setAmount] = useState()
    const [description, setDescription] = useState()
    const [coffees, setCoffees] = useState()
    const [paymentId, setPaymentId] = useState()

    useEffect(() => {
             axios.get(`${server}/getPayment/${roasterData.id}`)
                .then(async payment => { 
                    console.log(payment, roasterData); 
                    setCoffees(payment.data.coffees)
                    setAmount(payment.data.amount)
                    setDescription(payment.data.description)
                    setPaymentId(payment.data.id)
            }) 
    }, [])

    const createMollieLink = async () => {
        const mollieInfo = {
            amount: paymentData.amount,
            description: paymentData.description,
            roasterId: roasterData.id,
            coffees: paymentData.coffees,
            paymentId: paymentData.id

        }
        await axios.post(`${server}/mollieCreate`, mollieInfo)
        .then(res => {
            window.location.href = res.data
        })
    }

    const renderCoffees = () => 
        paymentData.coffees.split(',').map((coffee) => {
        return <div>Farmcode: {coffee.split(':')[0]}, numero de sacas: {coffee.split(':')[1]}</div>
        })


    return(
    <div className="roaster-profile-container">
         <div className="roaster-profile-row">
            <label htmlFor="name" className="roaster-profile-subtitle">Nome do responsável</label>
            <input type="text" value={roasterData.payment_name ? roasterData.payment_name : roasterData.name } className="roaster-profile-data" readOnly/>
        </div>
        <div className="roaster-profile-row">
            <label htmlFor="name" className="roaster-profile-subtitle">Torrefadora</label>
            <input type="text" value={roasterData.roastery || ""} className="roaster-profile-data" readOnly/>
        </div>
        <div className="roaster-profile-row">
            <label htmlFor="email" className="roaster-profile-subtitle">Email para contato</label>
            <input type="text" value={roasterData.email || ""}  className="roaster-profile-data" readOnly/>
        </div>
        <div className="roaster-profile-row">
            <label htmlFor="address" className="roaster-profile-subtitle">Telefone para contato</label>
            <input type="text" value={roasterData.phoneNumber || ""}  className="roaster-profile-data" readOnly/>
        </div>
        <div className="roaster-profile-row">
            <label htmlFor="country" className="roaster-profile-subtitle">VAT</label>
            <input type="text" name='region-list' className="roaster-profile-data" value={roasterData.vat} readOnly/>
        </div>
        <div className="roaster-profile-row">
            <label htmlFor="country" className="roaster-profile-subtitle">Valor</label>
            <input type="text" name='region-list' className="roaster-profile-data" value={paymentData.amount} readOnly/>
        </div>
        <div className="roaster-profile-row">
            <label htmlFor="country" className="roaster-profile-subtitle">Endereço de Cobrança</label>
            <input type="text" name='region-list' className="roaster-profile-data" value={roasterData.address} readOnly/>
        </div>
        <div className="roaster-profile-row">
            <label htmlFor="country" className="roaster-profile-subtitle">Postal Code (cobrança)</label>
            <input type="text" name='region-list' className="roaster-profile-data" value={roasterData.postalCode} readOnly/>
        </div>
        <div className="roaster-profile-row">
            <label htmlFor="country" className="roaster-profile-subtitle">Endereço de Entrega</label>
            <input type="text" name='region-list' className="roaster-profile-data" value={roasterData.delivery_address} readOnly/>
        </div>
        <div className="roaster-profile-row">
            <label htmlFor="country" className="roaster-profile-subtitle">Postal Code (entrega)</label>
            <input type="text" name='region-list' className="roaster-profile-data" value={roasterData.delivery_postal_code} readOnly/>
        </div>
        {/* <div className="roaster-profile-row">
            <label htmlFor="postalCode" className="roaster-profile-subtitle">Valor para link da Mollie</label>
            <FarmlyInput value={paymentData.amount} setValue={value => setAmount(value)} styleClass="roaster-profile-data" elementId="amount"/>
        </div>
        <div className="roaster-profile-row">
            <label htmlFor="phoneNumber" className="roaster-profile-subtitle">Descrição para o link</label>
            <FarmlyInput value={paymentData.description} setValue={value => setDescription(value)} styleClass="roaster-profile-data" elementId="description"/>
        </div> */}
        <div className="roaster-profile-row">
            <label htmlFor="phoneNumber" className="roaster-profile-subtitle">Cafés Selecionados</label>
            {renderCoffees()}
            {/* <div>Farmcode: {paymentData.coffees.split(':')[0]}, numero de sacas: {paymentData.coffees.split(':')[1]}</div> */}
        </div>
        {/* <div className="buttons-container">          
            <div className="roaster-profile-button" onClick={ async () => {
                createMollieLink() 
            }}>
            Gerar link
            </div>
        </div> */}
    </div>
    )
}

export default NegociationProfile