export const USER_LOGGED_IN = "USER_LOGGED_IN"
export const USER_LOGGED_OUT = "USER_LOGGED_OUT"
export const USER_IS_ADMIN = "USER_IS_ADMIN"

export const ADD_TO_CART = "ADD_TO_CART"
export const REMOVE_FROM_CART = "REMOVE_FROM_CART"
export const LOAD_CART = "LOAD_CART"
export const UPDATE_CART = "UPDATE_CART"
export const CLEAR_CART = "CLEAR_CART"
export const PRODUCER_LOGIN = "PRODUCER_LOGIN"

export const ADD_TO_LIST = "ADD_TO_LIST"
export const LOAD_LIST = "LOAD_LIST"
export const CLEAR_LIST = "CLEAR_LIST"
export const REMOVE_FROM_LIST = "REMOVE_FROM_LIST"
export const ADD_EMAIL = "ADD_EMAIL"
