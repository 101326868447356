const local_server = "http://localhost:3001";
const dev_server = "https://thawing-meadow-51802.herokuapp.com";
//const joao_server = 'https://farmly-joao-dev.herokuapp.com'
//const signup_server = 'https://enigmatic-inlet-06528.herokuapp.com'
const prod_server = "https://farmlyclub.herokuapp.com";
//const ing_dev_server = 'https://farmly-ingrid-dev.herokuapp.com'

const server = prod_server;

const isClub = () => server === prod_server;

const landingPages = {
  a: "https://blog.farmlyclub.com/lp-micro1",
  b: "https://blog.farmlyclub.com/lp2-coffee-hunter-form/",
};

const farmlyColors = {
  elm: "rgb(46, 124, 121)",
  blueStone: "rgb(27, 98, 94)",
  desertSand: "rgb(230, 199, 179)",
  pancho: "rgb(230, 179, 143)",
  vistaBlue: "rgb(154, 199, 177)",
  bistre: "rgb(66, 40, 23)",
  fuzzyWuzzyBrown: "rgb(203, 86, 81)",
  sorbus: "rgb(236, 103, 56)",
};

const farmlyTexts = {
    homeWelcome_pt: 'Bem vindo à FARMly!',
    homeWelcome_en: 'Welcome to FARMly!',

    navHome_pt: 'Início',
    navHome_en: 'Home',
    navLogin_pt: 'Acesso',
    navLogin_en: 'Login',
    navSignup_pt: 'Cadastro',
    navSignup_en: 'Sing Up',
    navProfile_pt: 'Perfil',
    navProfile_en: 'Profile',
    navCoffees_pt: 'Cafés',
    navCoffees_en: 'Coffees',
    navLogout_pt: 'Sair',
    navLogout_co: 'Salir',
    navLogout_en: 'Logout',
    navHelp_pt: 'Ajuda',
    navHelp_co: 'Ayuda',
    navHelp_en: 'Help',
    navBlog_pt: 'Blog',
    navBlog_en: 'Blog',

    loginTitle_pt: 'Entrar',
    loginTitle_en: 'Login',
    loginEmail_pt: 'Email',
    loginEmail_en: 'Email',
    loginEmailNotFound_pt: 'Email não cadastrado.',
    loginEmailNotFound_en: 'Email not found.',
    loginInvalidEmailPassword_pt: 'Email ou senha incorretos.',
    loginInvalidEmailPassword_en: 'Incorrect email or password.',
    loginServerFailure_pt: 'Falha no servidor. Por favor, tente novamente em alguns minutos, ou nos envie uma mensagem descrevendo o problema.',
    loginServerFailure_en: 'Server error. Please, try again in a few moments, or send us a message describing the problem.',
    loginPassword_pt: 'Senha',
    loginPassword_en: 'Password',
    loginSignin_pt: 'Entrar',
    loginSignin_en: 'Login',
    loginNewAccount_pt: 'Não possui uma conta? ',
    loginNewAccount_en: 'New here? ',
    loginSignUp_pt: 'Cadastre-se',
    loginSignUp_en: 'Sign Up',
    loginForgotPassword_pt: 'Esqueceu sua senha?',
    loginForgotPassword_en: 'Forgot your password?',

    passwordRecoveryMailSent_pt: 'Um email foi enviado para você! Não se esqueça de conferir se ele foi parar na pasta de spam.',
    passwordRecoveryMailSent_en: "We sent you an email! Don't forget to see if it ended up in your spam folder.",
    signupTitle_pt: 'Faça seu cadastro',
    signupTitle_en: 'Sign Up',
    signupName_pt: 'Nome:',
    signupName_en: 'Name:',
    signupEmail_pt: 'Email:',
    signupEmail_en: 'Email:',
    signupPasswordLabel_pt: 'Crie uma senha',
    signupPasswordLabel_en: 'Choose your password',
    signupPassword_pt: 'Senha',
    signupPassword_en: 'Password',
    signupConfirmPassword_pt: 'Confirmar senha',
    signupConfirmPassword_en: 'Confirm Password',
    signupNext_pt: 'Avançar',
    signupNext_en: 'Next',
    signupCategoryTitle_pt: 'Você é',
    signupCategoryTitle_en: 'You are a',
    signupProducer_pt: 'Produtor',
    signupProducer_en: 'Producer',
    signupOr_pt: 'Ou',
    signupOr_en: 'Or',
    signupRoaster_pt: 'Torrefador',
    signupRoaster_en: 'Roaster',
    signupGoBack_pt: 'Voltar',
    signupGoBack_en: 'Go Back',
    signupAlmostThere_pt: 'Quase lá!',
    signupAlmostThere_en: 'Almost there!',
    signupFarmName_pt: 'Nome da Fazenda:',
    signupFarmName_en: 'Farm Name:',
    signupPhone_pt: 'Telefone',
    signupPhone_en: 'Phone',
    signupFarmSize_pt: 'Tamanho da Fazenda:',
    signupFarmSize_en: 'Farm Size;',
    signupFarmHeight_pt: 'Altitude da produção',
    signupFarmHeight_en: 'Farm Height',
    signupRegion_pt: 'Região:',
    signupRegion_en: 'Region:',
    signupAverageYield_pt: 'Produção média de café especial',
    signupAverageYield_en: 'Average specialty coffee yield',
    signupVarieties_pt: 'Quais variedades de café você produz?',
    signupVarieties_en: 'Which coffee varieties do you cultivate?',
    signupVarietiesPH_pt: 'Variedades',
    signupVarietiesPH_en: 'Varieties',
    signupProdCoffeeScores_pt: 'Seus cafés costumam pontuar (pode marcar mais de uma opção):',
    signupProdCoffeeScores_en: 'Your coffees usualy score (you can check multiple boxes):',
    signupBelow80_pt: 'Menos de 80',
    signupBelow80_en: 'Below 80',
    signup8082_pt: 'Entre 80 e 82',
    signup8082_co: 'Entre 80 y 82',
    signup8082_en: 'Between 80 and 82',
    signup8284_pt: 'Entre 82 e 84',
    signup8284_co: 'Entre 82 y 84',
    signup8284_en: 'Between 82 and 84',
    signup8486_pt: 'Entre 84 e 86',
    signup8486_co: 'Entre 84 y 86',
    signup8486_en: 'Between 84 and 86',
    signup8688_pt: 'Entre 86 e 88',
    signup8688_co: 'Entre 86 y 88',
    signup8688_en: 'Between 86 and 88',
    signupOver88_pt: 'Acima de 88',
    signupOver88_co: 'Más de 88',
    signupOver88_en: 'Over 88',
    signupHarvestDate_pt: 'Quando começa a colheita?',
    signupHarvestDate_en: 'When does the harvest begin?',
    signupSalesDate_pt: 'Quando espera ter café pronto para venda?',
    signupSalesDate_en: 'When do you expect to have coffee to sell?',
    signupProdDate_pt: 'Data',
    signupProdDate_en: 'Date',
    signupAllDone_pt: 'Pronto!',
    signupAllDone_en: 'All done!',
    signupConfirm_pt: 'Confirmar',
    signupConfirm_en: 'Confirm',
    signupCountry_pt: 'País',
    signupCountry_en: 'Country',
    signupPostalCode_pt: 'Código postal',
    signupPostalCode_en: 'Postal code',
    signupAddress_pt: 'Endereço:',
    signupAddress_en: 'Address',
    signupSend_pt: 'Enviar',
    signupSend_en: 'Submit',
    signupRequiredFields_pt: 'Campos marcados com * são obrigatórios!',
    signupRequiredFields_en: 'Fields with a * are required!',
    signupTellUsMore_pt: 'Conte-nos mais para sugerirmos cafés melhores!',
    signupTellUsMore_en: 'Tell us more, so that we can suggest better coffee!',
    signupLater_pt: 'Você pode fazer isso mais tarde se preferir',
    signupLater_en: "You can do it later if you're in a hurry",
    signupSkip_pt: 'Pular',
    signupSkip_en: 'Skip',
    signupSacksPerScore_pt: 'Quantas sacas de cada pontuação você espera comprar:',
    signupSacksPerScore_en: 'How many sacks do you expect to purchase of each score:',
    signupSensoryNotes_pt: 'Notas sensoriais:',
    signupSensoryNotes_en: 'Sensory notes:',
    signupStrawberry_pt: 'Morango',
    signupStrawberry_en: 'Strawberry',
    signupChocolate_pt: 'Chocolate',
    signupChocolate_en: 'Chocolate',
    signupOrange_pt: 'Laranja',
    signupOrange_en: 'Orange',
    signupLime_pt: 'Lima',
    signupLime_en: 'Lime',
    signupBanana_pt: 'Banana',
    signupBanana_en: 'Banana',
    signupCaramel_pt: 'Caramelo',
    signupCaramel_en: 'Caramel',
    signupPleaseFill_pt: 'Preencha todos os campos!',
    signupPleaseFill_en: 'Please fill out all fields!',
    signupHasAccount_pt: 'Já é cadastrado? ',
    signupHasAccount_en: 'Do you have an account? ',
    signupLogin_pt: 'Faça login',
    signupLogin_en: 'Login',

    coffeesTitle_pt: 'CAFÉS',
    coffeesTitle_en: 'COFFEES',

    profileProducer_pt: 'Produtor',
    profileProducer_en: 'Producer',
    profileRoaster_pt: 'Torrefador',
    profileRoaster_en: 'Roaster',
    profileName_pt: 'Nome',
    profileName_en: 'Name',
    profileEmail_pt: 'Email',
    profileEmail_en: 'Email',
    profileFarm_pt: 'Fazenda',
    profileFarm_en: 'Farm',
    profileCountry_pt: 'País',
    profileCountry_en: 'Country',
    profileEdit_pt: 'Editar',
    profileEdit_en: 'Edit',
    profileCancel_pt: 'Cancelar',
    profileCancel_en: 'Cancel',

    //CoffeeProfile -> CoffeeProfile
    coffeeProfileGotoProfile_pt: 'Ir para o perfil de',
    coffeeProfileGotoProfile_en: 'Go to the profile of',
    coffeeProfileBacktoProfile_pt: 'Voltar para o perfil',
    coffeeProfileBacktoProfile_en: 'Back to profile',
    coffeeProfileOptionAbout_pt: 'Sobre',
    coffeeProfileOptionAbout_en: 'About',
    coffeeProfileOptionSensory_pt: 'Sensorial',
    coffeeProfileOptionSensory_en: 'Sensory',
    coffeeProfileOptionOrigin_pt: 'Origem',
    coffeeProfileOptionOrigin_en: 'Origin',
    coffeeProfileAfterTaste_pt: 'Finalização',
    coffeeProfileAfterTaste_en: 'After taste',


    // CoffeeProfile -> About
    coffeeDelete_pt: 'Deletar Café',
    coffeeDelete_en: 'Delete Coffee',
    coffeeConfirmDelete_pt: 'Tem certeza que deseja deletar este café?',
    coffeeConfirmDelete_en: 'Are you sure you want to delete this coffee?',
    coffeeDeleteButton_pt: 'Deletar',
    coffeeDeleteButton_en: 'Delete',
    coffeeDescriptionChecked_pt: 'Versão em inglês não checada!',
    coffeeDescriptionChecked_en: 'English version not checked!',
    coffeeProfileParagraphAbout_pt: 'Escreva algumas linhas sobre este café!',
    coffeeProfileParagraphAbout_en: 'Write a few words about this coffee!',
    coffeeInfoContainer_pt: 'Edite as informações do seu café!',
    coffeeInfoContainer_en: 'Edit the information about your coffee!',
    iconRegion_pt: 'Região',
    iconRegion_en: 'Region',
    iconRegion_es: 'Región',
    iconVariety_pt: 'Variedade',
    iconVariety_en: 'Variety',
    iconVariety_es: 'Variedad',
    iconProcess_pt: 'Tipo de Processo',
    iconProcess_en: 'Process',
    iconProcess_es: 'Proceso',
    iconHarvest_pt: 'Mês e ano da colheita',
    iconHarvest_en: 'Harvest Date',
    iconHarvest_es: 'Fecha de cosecha',
    iconRemain_pt: 'Sacas restantes',
    iconRemain_en: 'Remaining bags',
    iconRemain_es: 'Bolsas',
    iconHeight_pt: 'Altitude',
    iconHeight_en: 'Altitude',
    iconHeight_es: 'Altitud',
    iconScore_pt: 'Pontuação',
    iconScore_en: 'Score',
    iconScore_es: 'Puntuación',
    iconSieve_pt: 'Peneira',
    iconSieve_en: 'Sieve',
    iconSieve_es: 'Tamiz',
    priceLabel_pt: 'Preço da Saca:',
    priceLabel_en: 'Bag price:',
    paymentMethod_pt: 'Alterar opções de pagamento',
    paymentMethod_en: 'Change payment method',
    coffeeScore_pt: 'Pontuação do Café',
    coffeeScore_en: 'SCA Score',
    coffeeTrySample_pt: 'Que tal experimentar?',
    coffeeTrySample_en: 'Would you like to try it?',
    coffeeTrySampleText_pt: 'Este café é uma pré-degustação para a próxima safra, gostaria de ser avisado quando este produtor tiver amostras da nova safra?',
    coffeeTrySampleText_en: 'This coffee is a pre-taste for the next harvest, would you like to be notified when this producer has samples from the new crop?',
    coffeeSampleRequest_pt: 'Solicitar amostra',
    coffeeSampleRequest_en: 'Request sample',
    coffeeReview_pt: 'Avalie esse café!',
    coffeeReview_en: 'Review this coffee!',
    coffeeSeeReview_pt: 'Ver minha avaliação',
    coffeeSeeReview_en: 'See my review',
    sampleRequested_pt: 'Amostra já solicitada',
    sampleRequested_en: 'Sample requested',
    sampleAddCart_pt: 'Adicionar ao carrinho',
    sampleAddCart_en: 'Add to cart',
    coffeeGotoCart_pt: 'Ir para o carrinho',
    coffeeGotoCart_en: 'Go to cart',
    editCoffeeButton_pt: 'Salvar alterações',
    editCoffeeButton_en: 'Save Changes',
    cancelCoffeeButton_pt: 'Cancelar Alterações',
    cancelCoffeeButton_en: 'Cancel Changes',
    menuAboutTitle_pt: 'sobre',
    menuAboutTitle_en: 'about',
    menuAboutTitle_es: 'Sobre',
    menuSensoryTitle_pt: 'Sensorial',
    menuSensoryTitle_en: 'Sensory',
    menuSensoryTitle_es: 'Sensorial',
    menuOriginTitle_pt: 'Origem',
    menuOriginTitle_en: 'Origin',
    menuOriginTitle_es: 'Fuente',






    //CoffeeProfile -> Origin
    originTitle_pt: 'Produtor',
    originTitle_en: 'Producer',
    originSubtitle_pt: 'Cafeicultor',
    originSubtitle_en: 'Coffee Producer',

    profileConfirmChange_pt: 'Confirmar Mudanças',
    profileConfirmChange_en: 'Confirm Changes',

    //CoffeeProfile -> RequestSample
    requestRequestSample_pt: 'Faça login',
    requestRequestSample_en: 'Log in',
    requestLogin_pt: 'Faça login ou cadastre-se para solicitar amostra ou adicionar café aos favoritos',
    requestLogin_en: 'Log in or register to request sample or add coffee to favorites',
    requestFavLogin_pt: 'Faça login ou cadastre-se para adicionar aos favoritos!',
    requestFavLogin_en: 'Log in or register to add coffee to favorites!',
    requestConfirmData_pt: 'Confira se seus dados estão corretos para o envio de amostra',
    requestConfirmData_en: 'Confirm your settings for shipping',
    requestRequest_pt: 'Solicitar',
    requestRequest_en: 'Request',
    requestFillAll_pt: 'Por favor preencha todos os campos!',
    requestFillAll_en: 'Please fill out all fields!',
    requestName_pt: 'Nome:',
    requestName_en: 'Name:',
    requestRoastery_pt: 'Nome da torrefadora:',
    requestRoastery_en: 'Roastery name:',
    requestAddress_pt: 'Endereço:',
    requestAddress_en: 'Address:',
    requestCountry_pt: 'País:',
    requestCountry_en: 'Country:',
    requestCEP_pt: 'CEP:',
    requestCEP_en: 'Postal code:',
    requestSampleWeight_pt: 'Quantidade Desejada:',
    requestSampleWeight_en: 'Desired Quantity:',
    requestSampleEmailSent_pt: 'Essa amostra vai chegar no endereço oferecido em até 1 semana',
    requestSampleEmailSent_en: 'This sample will arrive in 1 week in the offered address',
    // requestWeight_pt: 'De quantos gramas você precisa?',
    // requestWeight_en: 'How many grams do you need?', 

    //CoffeeProfile -> SectionsMenu
    sectionAbout_pt: 'Sobre',
    sectionAbout_en: 'About',
    sectionNotes_pt: 'Notas Sensoriais',
    sectionNotes_en: 'Sensory Notes',
    sectionProducer_pt: 'Produtor',
    sectionProducer_en: 'Producer',
    sectionRegion_pt: 'Região',
    sectionRegion_pt: 'Region',

    //CoffeProfile -> Sensory
    sensoryTitle_pt: 'Notas Sensoriais',
    sensoryTitle_en: 'Sensory Notes',
    sensorySaveButton_pt: 'Salvar Alterações',
    sensorySaveButton_en: 'Save Changes',
    sensoryCancelButton_pt: 'Cancelar Alterações',
    sensoryCancelButton_en: 'Cancel Changes',

    roasterYourPage_pt: 'Sua Página - Torrefador',
    roasterYourPage_en: 'Your Page - Roaster',
    roasterProfile_pt: 'Perfil',
    roasterProfile_en: 'Profile',
    roasterPreferences_pt: 'Preferências de Café',
    roasterPreferences_en: 'Coffee Preferences',
    roasterPurchases_pt: 'Compras',
    roasterPurchases_en: 'Purchases',
    roasterFavorites_pt: 'Favoritos',
    roasterFavorites_en: 'Favorites',
    roasterRequests_pt: 'Amostras',
    roasterRequests_en: 'Samples',
    roasterChangePassword_pt: 'Altere sua senha',
    roasterChangePassword_en: 'Change your password',
    oldPassword_pt: 'Senha Antiga',
    oldPassword_en: 'Old Password',
    newPassword_pt: 'Nova Senha',
    newPassword_en: 'New Password',
    confirmPassword_pt: 'Confirmar senha',
    confirmPassword_en: 'Confirm Password',
    verifyEmail_pt: 'Verifique seu email para validar as alterações da sua conta',
    verifyEmail_en: 'Verify your email so we can validate your account',
    sendVerificationLink_pt: 'Enviar novo link de verificação',
    sendVerificationLink_en: 'Send new verification link',

    //Roasters -> RoasterRequest
    emptyPage_pt: 'Você ainda não fez nenhuma solicitação!',
    emptyPage_en: 'There are no sample requests yet!',

    //Roasters -> Favorites
    roasterFavoritesMessage_pt: 'Você não adicionou nenhum café aos favoritos!',
    roasterFavoritesMessage_en: `You haven't selected any coffee to favorites!`,
    addFavorites_pt: 'Adicionar Favoritos',
    addFavorites_en: 'Add Favorites',


    roasterComingSoon_pt: 'A seção de compras aparecerá aqui em breve!',
    roasterComingSoon_en: 'The purchases section will be here soon!',

    footerConcept_pt: 'CONCEITO',
    footerConcept_en: 'CONCEPT',
    footerContact_pt: 'FALE CONOSCO',
    footerContact_en: 'CONTACT',

    error_pt: "Oh, não! Um erro ocorreu no servidor! Se for conveniente, envie um email para o time de desenvolvimento descrevendo como encontrou este erro.",
    error_en: "Oh no! An error ocurred on our servers! If convenient, email the development team describing how you found this error.",

    verified_pt: 'verificado com sucesso',
    verified_en: 'successfully verified',
    notVerified_pt: 'token inválido ou já verificado.',
    notVerified_en: 'invalid token or already verified.',

    helpWait_pt: "Aguarde um momento...",
    helpWait_en: "Please wait...",
    helpSuccess_pt: "Sua mensagem foi enviada com sucesso!",
    helpSuccess_en: "Your message was sent!",
    helpError_pt: "Algo deu errado... Por favor, aguarde alguns minutos e tente novamente!",
    helpError_en: "Something went wrong... Please, wait a few minutes and try again!",
    helpTitle_pt: "Tem alguma dúvida ou sugestão?\nEnvie-nos uma mensagem!",
    helpTitle_en: "Got any questions or suggestions?\nSend us a message!",
    helpName_pt: "Nome",
    helpName_en: "Name",
    helpEmail_pt: "Email",
    helpEmail_en: "Email",
    helpMessage_pt: "Mensagem",
    helpMessage_en: "Message",
    helpSend_pt: "Enviar",
    helpSend_en: "Send",

    cartProduct_pt: "Produto",
    cartProduct_en: "Product",
    cartPrice_pt: "Preço",
    cartPrice_en: "Price",
    cartQuantity_pt: "Quantidade",
    cartQuantity_en: "Quantity",
    cartProducer_pt: "Produtor",
    cartProducer_en: "Producer",
    cartTotals_pt: "Totais",
    cartTotals_en: "Cart Totals",
    cartShipping_pt: "Envio",
    cartShipping_en: "Shipping",
    cartImport_pt: "Taxa de importação",
    cartImport_en: "Import tax",
    cartFarmlyFee_pt: "Taxa da FARMly",
    cartFarmlyFee_en: "FARMly fee",
    cartTotal_pt: "Total*",
    cartTotal_en: "Total*",
    cartEmptyButton_pt: "Cesta vazia",
    cartEmptyButton_en: "Basket is empty",
    cartCouponCode_pt: "Código do cupom",
    cartCouponCode_en: "Coupon code",
    cartApplyCoupon_pt: "Aplicar cupom",
    cartApplyCoupon_en: "Apply coupon",
    cartUpdateCart_pt: "Atualizar carrinho",
    cartCreateCoupon_pt: "Criar cupom",
    cartCreateCoupon_en: "Create coupon",
    cartCreateCoupon_pt: "Crear coupon",
    cartUpdateCart_pt: "Atualizar carrinho",
    cartUpdateCart_en: "Update cart",
    cartEmpty_pt: "A cesta está vazia!",
    cartEmpty_en: "The basket is empty!",
    cartCheckout_pt: "Finalizar compra",
    cartCheckout_en: "Proceed to checkout",
    cartLogin_pt: "Faça login ou cadastre-se para finalizar sua compra",
    cartLogin_en: "Login or signup to proceed with your purchase",
    cartInvalidCoupon_en: "The requested coupon is invalid.",
    cartInvalidCoupon_pt: "O cupom solicitado é inválido.",
    cartInvalidCoupon_es: "El cupón solicitado no es válido",
    cartValidCoupon_en: "The requested coupon has been applied successfully!",
    cartValidCoupon_pt: "O cupom solicitado foi aplicado com sucesso!",
    cartValidCoupon_es: "¡El cupón solicitado se ha aplicado con éxito!",

    cookiesTitle_pt: "Esse site utiliza Cookies",
    cookiesTitle_en: "This website uses Cookies",
    cookiesText_pt: "Para saber mais sobre nossa política de privacidade,",
    cookiesText_en: "To learn more about our privacy policy,",
    cookiesClick_pt: " clique aqui",
    cookiesClick_en: " click here",
    cookiesAccept_pt: " Aceitar",
    cookiesAccept_en: " Accept",
    cookiesReject_pt: " Rejeitar",
    cookiesReject_en: " Reject",

    privacyTitle_pt: "Política de Privacidade",
    privacyTitle_en: "Privacy Policy",
    cookiesPolicyTitle_pt: "Política de Cookies",
    cookiesPolicyTitle_en: "Our Cookies Policy",
}

const isPt = () => navigator.language.toLowerCase().includes('pt')

const isBr = () => navigator.language.toLowerCase().includes('br')

const isCo = () => (navigator.language.toLowerCase().includes('co') || navigator.language.toLowerCase().includes('es')) 

const isEs = () => navigator.language.toLowerCase().includes('es')

const acceptedCookies = () => (document.cookie.includes("accepted_cookies"))

const isFarmly = () => (document.cookie.includes("FARMly"))

const language = (isPt() || isBr()) ? 'pt' : isCo() ? 'es' : 'en';

export { server, farmlyColors, farmlyTexts, isPt, isBr, isCo, isEs, landingPages, acceptedCookies, isClub, language, isFarmly }
