import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ReactGA from 'react-ga'

import Main from '../templates/Main'
import ThankYou from './ThankYou'
import './ReceivedLink.css'

import UserContext from '../../context/user/UserContext.js'
import CartContext from '../../context/cart/CartContext.js'
import axios from 'axios'

import { server, language, acceptedCookies, isPt, isCo } from '../../common'


const ReceivedLink = props => {

    const titleHeaderLanguage = { 'pt': 'Cadastre-se', 'en': 'Signup', 'es': 'Inscrever-se' }[language]

    const history = useHistory();

    const userContext = useContext(UserContext)
    const cartContext = useContext(CartContext)
    const [coffeeScores, setCoffeeScores] = useState({
        sub80: '',
        s80_82: '',
        s82_84: '',
        s84_86: '',
        s86_88: '',
        over88: ''
    })
    const [userData, setUserData] = useState({
        name: '',
        email: '',
        password: '',
        confirmPassword: ''
    })
    const invitedBy = (props.location.search).split('=')[1] || null
    const [invitedByUsername, setInvitedByUsername] = useState(null)
    const [showMore, setShowMore] = useState(false)
    const [showMore2, setShowMore2] = useState(false)
    const [sameEmailAlert, setSameEmailAlert] = useState(false);


    /* Valida o nome relativo ao código de convite  */
    useEffect(() => {
        axios.post(`${server}/invite`, {
            invitedBy: invitedBy
        })
            .then(res => {
                const answer = res.data
                if (answer === 'invalid code') {
                    setInvitedByUsername('Código de convite Inválido')
                }
                else {
                    setInvitedByUsername(answer.name)
                }
            })
            .catch(err => console.log(err))
    }, [invitedBy])

    useEffect(() => {
        ReactGA.event({
            category: 'SignUp - Received Link',
            action: `Visited the SignUp Received Link Page`
        })
    }, [])

    const submitSignup = async () => {
        let user = { ...userData }
        user.coffeeScores = Object.values(coffeeScores).reduce((prev, curr) => prev + curr)
        user.notfication_new = true
        delete user.confirmPassword
        user.invitedBy = invitedBy
        user.farmHeight = ""
        user.averageYield = ""
        user.farmSize = ""
        user.farmName = ""
        user.harvestDate = ""
        user.region = ""
        user.salesDate = ""
        user.varieties = ""
        ReactGA.event({
            category: 'SignUp - Received Link',
            action: `User submitted and Sign up`
        })
        try {
            const payload = await axios.post(`${server}/signup/1`, {
                ...user
            })

            userContext.userLogin({
                isProd: payload.data.isProd,
                jwt: payload.data.jwt,
                id: payload.data.id
            })

            if (acceptedCookies()) {
                document.cookie = `jwt=${payload.data.jwt}; max-age=${payload.data.exp}; path=/`
                document.cookie = `isProd=${payload.data.isProd}; max-age=${payload.data.exp}; path=/`
                document.cookie = `userId=${payload.data.id}; max-age=${payload.data.exp}; path=/`
            }

            // await axios.post(`${server}/createNotification`, {
            //     userId: payload.data.id,
            //     isProd: payload.data.isProd,
            //     title: payload.data.isProd ?
            //         `Bem-vindo(a) à FARMly!`
            //         :
            //         `Welcome to FARMly!`,
            //     message: payload.data.isProd ?
            //         `Obrigado por se cadastrar na nossa plataforma! Para começar a vender o seu café é preciso nos enviar uma amostra e aguardar a aprovação! Para enviar amostra basta entrar no seu Perfil, ir na aba "Enviar Amostra" e seguir as instruções para o envio!`
            //         :
            //         `Thank you for signing up in our website! You can start looking up for coffees clicking on "Coffees"!`
            // })

            setSameEmailAlert(false)
            history.push({
                pathname: '/profile'
            });

        } catch (err) {
            console.log(err)
            setSameEmailAlert(true)
        }
    }

    return (

        <Main titleHeader={titleHeaderLanguage}>
            <div className="container-received">
                <div className="main-text-received">
                    {
                        invitedBy
                            ?
                            <p id="title-received" >{isPt() ? 'Você recebeu um link de cadastro de' : 'Ha recibido un link de registro de'}: <br /> <span>{invitedByUsername}</span> </p>
                            :
                            <p id="title-received" > <span> {isPt() ? 'Venha vender seu café na Europa junto com a FARMly' : 'Venga a vender su café en Europa con FARMly'} </span> </p>
                    }
                    <label style={{ fontSize: '22px' }}>| {isPt() ? 'Junte-se ao' : 'Únase al'} FARMly club!</label>
                    <p>
                        {isPt() ?
                            <div>
                                Ajudamos <span>mais de 1400 produtores</span> a
                                comercializarem seu <span>café</span> com torrefadores
                                <span> europeus</span>. Cadastre-se agora e faça parte
                                desta enorme família.
                            </div>
                            :
                            <div>
                                Hemos ayudado a <span>más de 1400 productores</span> a
                                comercializar su <span>café</span> con los tostadores
                                <span> europeos</span>. Regístrese ahora y forme parte
                                de esta enorme familia.
                            </div>

                        }
                    </p>
                    <p>
                        {isPt() ?
                            <>
                                Você só se preocupa com a qualidade
                                do seu café.
                                <br />
                                Fazemos todo o restante.
                            </>
                            :
                            <>
                                Sólo te importa la calidad
                                de su café.
                                <br />
                                Hacemos todo lo demás.
                            </>
                        }
                    </p>
                    <p>
                        {isPt() ?
                            <>
                                E o melhor: <span>não paga nada por isso</span>.
                            </>
                            :
                            <>
                                Y lo mejor: <span>no pagas nada por it</span>.
                            </>
                        }
                    </p>
                    {/* <label style={{fontSize: '22px', cursor: 'pointer'}} onClick={() => {setShowMore(!showMore)}}>| Ganhe dinheiro indicando novos produtores!</label> */}
                    {showMore &&
                        <p>
                            1 - Ao se cadastrar, todo produtor recebe
                            um link em seu perfil.
                            <br />
                            2 - Ele pode enviar este link para outros
                            produtores.
                            <br />
                            3 – Quando um produtor fecha uma venda no
                            FARMly Club, a FARMly se compromete a
                            pagar (do nosso próprio bolso) o equivalente a
                            0,5% do valor dessa venda para quem indicou
                            esse produtor.
                            <br />
                            4 – Viu como é fácil? Você indica alguém e caso
                            seu indicado faça uma venda, você também vai
                            ganhar uma comissão!
                            <br />
                            <br />
                            <label style={{ cursor: 'pointer' }} onClick={() => { setShowMore2(!showMore2) }}>
                                - Mas quando eu vender meu café, vou
                                precisar pagar comissão para quem me
                                indicou?
                            </label>
                            {showMore2 &&
                                <p>
                                    <span>
                                        -Não. Você recebe o valor integral da
                                        venda. Quem paga a comissão para a
                                        pessoa que te indicou, somos nós da
                                        FARMly!
                                    </span>
                                </p>
                            }
                        </p>
                    }
                </div>

                <div className="signup-received">
                    <div className='received-form'>
                        <input className="sign-up-received-input" value={userData.name} onChange={e => setUserData({ ...userData, name: e.target.value })} placeholder={isPt() ? 'Nome' : 'Nombre'}></input>
                        <br />
                        <input className="sign-up-received-input" value={userData.email} onChange={e => setUserData({ ...userData, email: e.target.value })} placeholder='Email'></input>
                        <br />
                        <input className="sign-up-received-input" value={userData.password} type='password' onChange={e => setUserData({ ...userData, password: e.target.value })} placeholder={isPt() ? 'Senha' : 'Contraseña'}></input>
                        <br />
                        <input className="sign-up-received-input" value={userData.confirmPassword} type='password' onChange={e => setUserData({ ...userData, confirmPassword: e.target.value })} placeholder={isPt() ? 'Confirmar senha' : 'Confirmar la contraseña'}></input>
                        <br />
                        <input className="sign-up-received-input" value={userData.phoneNumber} onChange={e => setUserData({ ...userData, phoneNumber: e.target.value })} placeholder={isPt() ? 'Telefone' : 'Teléfono'}></input>
                        <br />
                        <div style={{ textAlign: 'center' }}>
                            <button className="sign-up-received-button" onClick={() => { submitSignup() }}>{isPt() ? 'Cadastre-se' : 'Inscríbete'}</button>
                        </div>
                        <br />
                        <div className="exists-email-alert">
                            {
                                sameEmailAlert === true ?
                                    isPt() ? "Este email já está em uso, escolha outro por favor" :
                                        (isCo() ? "Este correo electrónico ya está en uso, elija otro" :
                                            "This email is already in use, plesae choose another one")
                                    :
                                    ""
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Main>
    )
}

export default ReceivedLink

// Cadastre-se nessa página e <span>0.5%</span> do valor das suas vendas será dado pela Farmly ao {invitedByUsername}. <br />
// Ao concluir o seu cadastro você poderá enviar links para outros produtores e <span>receber, por conta da Farmly,
// 0.5%</span> do que este produtor receber e se ele cadastrar mais alguém, você passa a receber <span>0.4%</span> do valor da venda
// deste terceiro. Assim sucessivamente até que você receba <span>0.1%</span> .