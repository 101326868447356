import React, { useState, useEffect, useContext, Fragment } from 'react'
import './CoffeeProfile.css'

import Main from '../templates/Main.jsx'
import SideMenu from '../templates/SideMenu.jsx'
import FarmlyModal from '../templates/FarmlyModal.jsx'
import About from './About.jsx'
import Sensory from './Sensory.jsx'
import Origin from './Origin.jsx'
import RequestSample from './RequestSample.jsx'

import axios from 'axios'
import wiki from 'wikijs'
import { isPt, isCo, farmlyTexts, server, acceptedCookies } from '../../common'
import crypto from 'crypto'
import UserContext from '../../context/user/UserContext'
import CartContext from '../../context/cart/CartContext'
import SampleContext from '../../context/sample/SampleContext'
import { Link } from 'react-router-dom'
import { getUserData } from '../profile/user'
import { useLocation } from 'react-router-dom'

import { storage } from '../../firebase'
import Ratings from './Ratings'

const CoffeeProfile = props => {

    const { coffeeId } = props.match.params
    const userContext = useContext(UserContext)
    const cartContext = useContext(CartContext)
    const sampleContext = useContext(SampleContext)
    const [regionSubtitle, setRegionSubtitle] = useState('no description')
    const [modalMessage, setModalMessage] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [selectedOption, setSelectedOption] = useState(isPt() ? farmlyTexts.menuAboutTitle_pt : (isCo() ? farmlyTexts.menuAboutTitle_es : farmlyTexts.menuAboutTitle_en))
    const [requestPopup, setRequestPopup] = useState(false)
    const [coffeeDataOrigin, setCoffeeDataOrigin] = useState({})
    const [coffeeData, setCoffeeData] = useState({})
    const [requestedAlready, setRequestedAlready] = useState(false)
    const [producerDataOrigin, setProducerDataOrigin] = useState({})
    const [producerData, setProducerData] = useState({})
    const [isOwner, setIsOwner] = useState(userContext.isAdmin || false)
    const [isProducer, setIsProducer] = useState(false)
    const [hasEdited, setHasEdited] = useState(false)
    const [isInCart, setIsInCart] = useState(false)
    const [autocomplete, setAutocomplete] = useState([])
    const [sensoryList, setSensoryList] = useState([])
    const [prodImageUrl, setProdImageUrl] = useState('')
    const [farmImageUrl, setFarmImageUrl] = useState('')
    const [coffeeImageUrl, setCoffeeImageUrl] = useState('')
    const [selectedFarmFileURL, setSelectedFarmFileURL] = useState([])
    const [selectedCoffeeFileURL, setSelectedCoffeeFileURL] = useState([])
    const [sackQuantity, setSackQuantity] = useState(1)
    const [fullListPopup, setFullListPopup] = useState(false)
    const [addSampleInList, setAddSampleInList] = useState(false)
    const [alreadyOnList, setAlreadyOnList] = useState(false)
    const location = useLocation()


    useEffect(() => {
        if (!isNaN(coffeeId)) {
            axios.get(`${server}/coffee/${coffeeId}`)
                .then(res => {
                    setCoffeeDataOrigin(res.data)
                    const sensoryNotes = isPt() ? (res.data.sensoryNotes || "") : (res.data.sensory_notes_en || "") // sensory notes pode ser null
                    setSensoryList(sensoryNotes.split(',')) //aí o split iria quebrar (null não tem o metodo split)
                    setIsOwner(userContext.user && (userContext.isAdmin || (
                        userContext.user.isProd &&
                        res.data.producerId === parseInt(userContext.user.id))))
                    setIsProducer(userContext.user && (!userContext.isAdmin || (
                        userContext.user.isProd &&
                        res.data.producerId === parseInt(userContext.user.id))))
                    loadProducer(res.data.producerId)
                    var urllist = []
                    res.data.imageUrl.split(',').map(u => storage.ref('producers').child(`${u}`).getDownloadURL()
                        .then((url) => { urllist.push(url) })
                        .catch(() => { }))
                    setCoffeeImageUrl(urllist)
                })
                .catch(err => console.log(err))
        } else {
            if (coffeeId === "new") {
                setInitialAttributes()
            }
            else {
                setCoffeeDataOrigin({
                    aroma: (6).toFixed(1),
                    flavour: (6).toFixed(1),
                    body: (6).toFixed(1),
                    acidity: (6).toFixed(1),
                    aftertaste: (6).toFixed(1),
                    balance: (6).toFixed(1),
                    producer: "",
                    farmName: "",
                    sensoryNotes: "",
                    variety: "",
                    process: "",
                    region: "",
                    score: 0,
                    imageUrl: "",
                    origin: "",
                    harvestDate: "",
                    packaging: "",
                    storage: "",
                    sackRemain: 0,
                    height: "",
                    sack_price: "",
                    kg_price: "",
                    regionId: 1,
                    description: ""
                })
            }
        }
    }, [])

    const setInitialAttributes = async () => {
        const res = userContext.isAdmin ? await axios.get(`${server}/producer/${location.state.producerId}`)
            : await getUserData()
        setCoffeeDataOrigin({
            aroma: (6).toFixed(1),
            flavour: (6).toFixed(1),
            body: (6).toFixed(1),
            acidity: (6).toFixed(1),
            aftertaste: (6).toFixed(1),
            balance: (6).toFixed(1),
            producer: res.data.name,
            farmName: res.data.farmName,
            height: res.data.farmHeight,
            region: res.data.region,
            harvestDate: res.data.harvestDate,
            latitude: res.data.latitude,
            longitude: res.data.longitude,
            sensoryNotes: "",
            variety: "",
            process: "",
            score: 0,
            imageUrl: res.data.farmImg ? res.data.farmImg.split(',') : [],
            origin: "",
            packaging: "",
            storage: "",
            sackRemain: 0,
            sack_price: "",
            kg_price: "",
            regionId: 1,
            description: ""

        })
        setProducerData(res.data)

        storage.ref('producers').child(`${res.data.profileImg}`).getDownloadURL()
            .then((url) => { setProdImageUrl(url) })
            .catch(() => { })
        if (res.data.farmImg) {
            var urllist = []
            res.data.farmImg.split(',').map(u => storage.ref('producers').child(`${u}`).getDownloadURL()
                .then((url) => { urllist.push(url) })
                .catch(() => { }))
            setFarmImageUrl(urllist)
        }
        setIsOwner(true)
    }

    useEffect(() => {
        if (coffeeData.sackRemain == 0) setSackQuantity(0)
    }, [coffeeData.sackRemain])

    useEffect(() => {
        // se for o dono, pega as notas sensoriais do autocomplete
        if (isOwner) {
            axios.get(`${server}/sensory`)
                .then(res => setAutocomplete(res.data))
        }
    }, [isOwner])

    useEffect(() => {
        if (cartContext.cartList)
            setIsInCart(cartContext.cartList.filter(el => el.id === parseInt(coffeeId)).length > 0)
    }, [cartContext])

    useEffect(() => {
        setCoffeeData(coffeeDataOrigin)
    }, [coffeeDataOrigin])

    useEffect(() => {
        setProducerData(producerDataOrigin)

        // load page
        if (producerDataOrigin.region) {
            wiki({ apiUrl: 'https://pt.wikipedia.org/w/api.php' })
                .page(producerDataOrigin.region)
                .then(page => page.summary())
                .then(setRegionSubtitle)
                .catch(err => console.log(err));

        }

    }, [producerDataOrigin])

    useEffect(() => {
        const offset = document.querySelector(`.${selectedOption.replace(' ', '-')}`).offsetTop
        window.scrollTo({
            top: offset,
            behavior: 'smooth'
        })
    }, [selectedOption])

    useEffect(() => {
        const keys = Object.keys(coffeeData)
        const prodKeys = Object.keys(producerData)
        if (keys) {
            setHasEdited(!keys.reduce((prev, key) => {
                return (coffeeData[key] == coffeeDataOrigin[key]) && prev
            }, true))
        }
        if (prodKeys) {
            setHasEdited(!prodKeys.reduce((prev, key) => {
                return (producerData[key] == producerDataOrigin[key]) && prev
            }, true))
        }
        // se a sensorylist for diferente da lista original, marca como editado
        if (sensoryList.join(',') !== coffeeData.sensoryNotes) { setHasEdited(true) }
    }, [coffeeData, coffeeDataOrigin, sensoryList, producerData, producerDataOrigin])

    const triggerRequest = () => {
        // setRequestPopup(!requestPopup)
        let i = 0
        if (sampleContext.offerList.length == 5) {
            setFullListPopup(true)
        } else {
            sampleContext.offerList.forEach(item => {
                if (item.farmCode == coffeeData.farmCode) {
                    setAlreadyOnList(true)
                } else {
                    i = i + 1
                }
            })
            if (i == sampleContext.offerList.length) {
                setAddSampleInList(true)
                sampleContext.addToList(coffeeData)
            }
        }
    }

    const increaseQuantity = () => {
        sackQuantity < coffeeData.sackRemain && setSackQuantity(sackQuantity + 1)
    }

    const decreaseQuantity = () => {
        sackQuantity > 0 && setSackQuantity(sackQuantity - 1)
    }

    const toggleModal = () => {
        setShowModal(!showModal)
    }

    function refreshPage() {
        window.location.reload();
    }

    const edit = async () => {

        const reqBody = {
            ...coffeeData
        }
        reqBody.sensoryNotes = sensoryList.join(",")
        try {
            await axios.patch(`${server}/coffees`, reqBody)
            setModalMessage('Dados alterados! Aguarde alguns instantes')
            setCoffeeDataOrigin({
                ...coffeeData
            })
            setTimeout(function () {
                refreshPage();
            }, 1000);
            toggleModal()
        } catch (err) {
            setModalMessage('Algo deu errado...')
            toggleModal()
            console.log(err)
        }
    }

    const reset = () => {
        setCoffeeData(coffeeDataOrigin)
        setHasEdited(false)
    }

    const save = async () => {
        const coffee = { ...coffeeData }
        coffee.sensoryNotes = sensoryList.join(",")
        coffee.producerId = parseInt(userContext.user.id)
        try {
            await axios.post(`${server}/addCoffee`, {
                ...coffee,
                producerId: (location.state && location.state.producerId) || coffee.producerId
            })
            setModalMessage('Café adicionado com sucesso!')
            toggleModal()
            const destPathname = userContext.isAdmin ? `/producer/profile/${location.state.producerId}` : "/profile"
            props.history.push({
                pathname: destPathname,
                state: { coffeeSection: true, addedNewCoffee: true }
            })
        } catch (err) {
            console.log(err)
            setModalMessage('Algo deu errado...')
            toggleModal()
        }
    }

    const loadProducer = id => {
        axios.get(`${server}/producer/${id}`).then(res => {
            setProducerDataOrigin(res.data)
            storage.ref('producers').child(`${res.data.profileImg}`).getDownloadURL()
                .then((url) => { setProdImageUrl(url) })
                .catch(() => { })

            if (res.data.farmImg) {
                var urllist = []
                res.data.farmImg.split(',').map(u => storage.ref('producers').child(`${u}`).getDownloadURL()
                    .then((url) => { urllist.push(url) })
                    .catch(() => { }))
                setFarmImageUrl(urllist)
            }else{  
                var urllist = []
                res.data.img.forEach(img =>{
                    if(img.role === "farm"){
                        urllist.push(img.img_url)
                    }
                })
                setFarmImageUrl(urllist)
            }
        })
    }

    const addToCart = () => {
        // setCoffeeDataOrigin({ ...coffeeData, sackRemain: coffeeData.sackRemain - sackQuantity }) // DESCONTA AS SACAS DO FRONT
        cartContext.addToCart(parseInt(coffeeId), sackQuantity)
        setIsInCart(true)
    }

    const updateCoffeeOnCart = () => {
        cartContext.cartList.map(item => {
            if (item.id == coffeeId) {
                // axios.patch(`${server}/coffeeSackRemain/${coffeeId}`, {
                //     sackQuantity: sackQuantity - item.quantity,
                //     sackRemain: coffeeData.sackRemain
                // })
                // setCoffeeDataOrigin({ ...coffeeData, sackRemain: coffeeData.sackRemain - sackQuantity + item.quantity }) // DESCONTA AS SACAS DO FRONT
            }
        })
        cartContext.updateCoffeeOnCart(parseInt(coffeeId), sackQuantity)
        setModalMessage('Carrinho alterado!')
        toggleModal()
    }

    const removeFromCart = () => {
        cartContext.removeFromCart(parseInt(coffeeId))
        setIsInCart(false)
    }

    const [menuOptions] = useState([
        isPt() ? farmlyTexts.menuAboutTitle_pt : isCo() ? farmlyTexts.menuAboutTitle_es : farmlyTexts.menuAboutTitle_en,
        isPt() ? farmlyTexts.menuSensoryTitle_pt : isCo() ? farmlyTexts.menuSensoryTitle_es : farmlyTexts.menuSensoryTitle_en,
        isPt() ? farmlyTexts.menuOriginTitle_pt : isCo() ? farmlyTexts.menuOriginTitle_es : farmlyTexts.menuOriginTitle_en
        // isPt() ? "Avaliações" : "Ratings"

    ])

    const [chartAttributes, setChartAttributes] = useState([{
        Finalização: 6,
        Aroma: 6,
        Acidez: 6,
        Equilibrio: 6,
        Corpo: 6,
        Sabor: 6,
    }])

    useEffect(() => {
        setChartAttributes([{
            Finalização: coffeeData.aftertaste,
            Aroma: coffeeData.aroma,
            Acidez: coffeeData.acidity,
            Equilibrio: coffeeData.balance,
            Corpo: coffeeData.body,
            Sabor: coffeeData.flavour,
        }])


    }, [coffeeData])

    const scoreAttributes = [
        "aftertaste",
        "aroma",
        "acidity",
        "balance",
        "body",
        "flavour",
    ]

    // const fileUploadFarm = async (acceptedFiles) => {
    //     var newlist = [...producerData.farmImg.split(',')]
    //     var urllist = selectedFarmFileURL
    //     if (acceptedFiles.length > 0) {
    //         for (var f in acceptedFiles) {
    //             const trapdoor = crypto.randomBytes(6).toString('HEX');
    //             const fileName = `${trapdoor}_${acceptedFiles[f].name}`;
    //             newlist.push(fileName)
    //             setProducerData({ ...producerData, farmImg: newlist })
    //             await storage.ref(`producers/${fileName}`).put(acceptedFiles[f])
    //             await storage.ref('producers').child(`${fileName}`).getDownloadURL()
    //                 .then((url) => { urllist.push(url); setSelectedFarmFileURL(urllist) })
    //                 .catch(e => console.log(e))

    //         }
    //         const formData = { ...producerData }
    //         formData.farmImg = newlist
    //         sendData(formData)
    //     }
    // }

    const fileUploadCoffee = async (acceptedFiles) => {
        if (coffeeData.imageUrl) {
            var newlist = [...coffeeData.imageUrl.split(',')]
        }
        else {
            var newlist = []
        }
        var urllist = selectedCoffeeFileURL
        if (acceptedFiles.length > 0) {
            for (var f in acceptedFiles) {
                const trapdoor = crypto.randomBytes(6).toString('HEX');
                const fileName = `${trapdoor}_${acceptedFiles[f].name}`;
                newlist.push(fileName)
                setCoffeeData({ ...coffeeData, imageUrl: newlist })
                await storage.ref(`producers/${fileName}`).put(acceptedFiles[f])
                await storage.ref('producers').child(`${fileName}`).getDownloadURL()
                    .then((url) => { urllist.push(url); setSelectedCoffeeFileURL(urllist) })
                    .catch(e => console.log(e))

            }
            const formData = { ...coffeeData }
            formData.imageUrl = newlist
            sendData(formData)
        }
    }

    const onDropRejected = () => {
        setModalMessage("Imagem não pode ser maior do que 2 megabytes.")
        toggleModal()
    }

    const sendData = async (formData) => {
        formData.imageUrl = formData.imageUrl.join(',')
        axios.patch(`${server}/coffees`, formData)
            .then(res => {
                setModalMessage('Dados alterados! Aguarde alguns instantes')
                toggleModal()
                setTimeout(refreshPage, 2000)
            })
            .catch(err => {
                setModalMessage('Algo deu errado...')
                console.log(err)
                toggleModal()
            })
    }

    const sendDataToRemoveImage = async (formData) => {
        axios.patch(`${server}/coffees`, formData)
            .then(res => {
                setModalMessage('Sua imagem foi removida! Aguarde alguns instantes')
                toggleModal()
                setTimeout(refreshPage, 2000)
            })
            .catch(err => {
                setModalMessage('Algo deu errado...')
                console.log(err)
                toggleModal()
            })
    }

    const updateProducerData = async () => {
        const formData = { ...producerData }

        try {
            sendProducerData(formData)
            setProducerDataOrigin(producerData)
            setModalMessage("Dados alterados!")
            toggleModal()

        } catch (err) {
            setModalMessage("Algo deu errado...")
            toggleModal()
            console.log(err)
        }
    }

    const sendProducerData = async (formData) => {
        // formData.farmImg = formData.farmImg.join(',')
        formData.jwt = acceptedCookies() ?
            document.cookie.split(';').filter(item => item.includes('jwt'))[0].split('jwt=')[1]
            : ""
        if (userContext.isAdmin) {
            formData.isProd = true
            formData.id = document.location.pathname.split('/')[3]
        }
        await axios.patch(`${server}/user`, formData)
    }

    const [selectedFileURL, setSelectedFileURL] = useState()
    // lista para multiplos arquivos

    // url temporária local e arquivo na memória
    const fileUploadProfile = async (acceptedFiles) => {
        if (acceptedFiles.length > 1) {
            setModalMessage("Você deve selecionar apenas uma imagem para sua foto de perfil!")
            toggleModal()
        } else {
            if (acceptedFiles.length > 0) {
                const trapdoor = crypto.randomBytes(6).toString('HEX');
                const fileName = `${trapdoor}_${acceptedFiles[0].name}`;

                setProducerData({ ...producerData, profileImg: fileName })

                if (producerDataOrigin.profileImg)
                    storage.ref('producers').child(producerDataOrigin.profileImg)
                        .delete()

                setProducerDataOrigin({ ...producerDataOrigin, profileImg: fileName })

                await storage.ref(`producers/${fileName}`).put(acceptedFiles[0])
                await storage.ref('producers').child(`${fileName}`).getDownloadURL()
                    .then((url) => setSelectedFileURL(url))

                const formData = { ...producerData }
                formData.profileImg = fileName
                sendProducerData(formData)
            }
        }
    }

    const removeProfilePicture = () => {
        setProducerData({ ...producerData, profileImg: null })
        storage.ref('producers').child(producerData.profileImg)
            .delete()

        setSelectedFileURL('')
        const formData = { ...producerData }
        formData.profileImg = null
        sendProducerData(formData)
    }



    return (
        <>
            {showModal && <FarmlyModal id="page-modal" closeModal={toggleModal}>{modalMessage}</FarmlyModal>}
            {fullListPopup &&
                <FarmlyModal closeModal={() => setFullListPopup(false)}>
                    <div className="request-content">
                        <label id="request-title">Your list of samples is full!</label>
                        <p>You have selected 5 samples to request already!</p>
                        <p>You can change your choices by going to the "offerList" page and </p>
                        setting the list of samples you would like to request as you want!
                        <br />
                        <br />
                        <Link id='request-submit' to={{ pathname: '/offerList' }}>
                            offerList
                        </Link>
                    </div>
                </FarmlyModal>
            }
            {alreadyOnList &&
                <FarmlyModal closeModal={() => setAlreadyOnList(false)}>
                    <div className="request-content">
                        <label id="request-title">This sample is already in your list!</label>
                        <p>You can look at other options of samples to request by going </p>
                        to the "offerList" page or looking at all coffees in the "Coffees" page!
                        <br />
                        <br />
                        <div>
                            <Link id='request-submit' to={{ pathname: '/offerList' }}>
                                Offer List
                            </Link>
                            <Link id='request-submit' to={{ pathname: '/coffees' }}>
                                Coffees
                            </Link>
                        </div>
                    </div>
                </FarmlyModal>
            }
            {/* {(addSampleInList || alreadyOnList) &&
                <FarmlyModal closeModal={() => {setAddSampleInList(false); setAlreadyOnList(false)}}>
                    <div className="request-content">
                        <label id="request-title">These samples are no longer available!</label>
                        {false ? //(5 - sampleContext.offerList.length) == 0 ?
                            <Fragment>
                                <p>You have selected 5 samples to request!</p>
                                <p> Go to the "Offer List" page to finish your sample request or </p>
                                to change the samples you have selected!
                            </Fragment>
                            :
                            <Fragment>
                                <p>Stay tuned! Soon more samples will be released! </p>
                            </Fragment>
                            // <Fragment>
                            //     <p>This sample was added to you list of samples! </p>
                            //     <p>You can check this list on the "Offer List" page and </p>
                            //     select other samples you would like to try!
                            // </Fragment>
                        }
                    </div>
                </FarmlyModal>
            } */}
            {addSampleInList &&
                <FarmlyModal closeModal={() => setAddSampleInList(false)}>
                    <div className="request-content">
                        <label id="request-title">A sample of this coffee has been added to your list</label>
                        {(5 - sampleContext.offerList.length) == 0 ?
                            <Fragment>
                                <p>You have selected 5 samples to request!</p>
                                <p> Go to the "Offer List" page to finish your sample request or </p>
                                to change the samples you have selected!
                            </Fragment>
                            :
                            <Fragment>
                                <p>You can check this list on the "Offer List" page and </p>
                                select other samples you would like to try!
                            </Fragment>
                        }
                        <br />
                        <br />
                        <Link id='request-submit' to={{ pathname: '/offerList' }}>
                            Offer List
                        </Link>
                    </div>
                </FarmlyModal>
            }
            {requestPopup && <RequestSample triggerRequest={triggerRequest} coffeeId={coffeeId} requestedAlready={requestedAlready} setRequestedAlready={setRequestedAlready} />}
            <Main>
                {(userContext.isAdmin) && <Link to={{ pathname: `/producer/profile/${producerData.id}`, navProps: { from: 'Perfil' } }}
                    className="coffee-profile-back-to-profile" >
                    {isPt() ? farmlyTexts.coffeeProfileGotoProfile_pt : farmlyTexts.coffeeProfileGotoProfile_en}{producerData.name}</Link>}
                {(!userContext.isAdmin && isOwner) && <Link to={{ pathname: "/profile", state: { coffeeSection: true } }}
                    className="coffee-profile-back-to-profile" >
                    {isPt() ? farmlyTexts.coffeeProfileBacktoProfile_pt : farmlyTexts.coffeeProfileBacktoProfile_en}</Link>}
                <div id="coffee-profile">
                    <div className="coffee-side-menu">
                        <SideMenu sideMenuOptions={menuOptions} selectedOption={selectedOption}
                            setSelectedOption={setSelectedOption} menuWidth='15vw' />
                    </div>
                    <div id="coffee-sections">
                        <About sackQuantity={sackQuantity} increaseQuantity={increaseQuantity} producerData={producerData} farmImageUrl={farmImageUrl} coffeeImageUrl={coffeeImageUrl}
                            decreaseQuantity={decreaseQuantity} setSackQuantity={setSackQuantity} fileUpload={fileUploadCoffee} onDropRejected={onDropRejected}
                            triggerRequest={triggerRequest} coffeeData={coffeeData} coffeeId={coffeeId} sendDataToRemoveImage={sendDataToRemoveImage}
                            isOwner={isOwner} isProducer={isProducer} setCoffeeData={setCoffeeData} handleSave={coffeeId === 'new' ? save : edit} fullListPopup={fullListPopup} setFullListPopup={setFullListPopup}
                            hasEdited={hasEdited} addToCart={addToCart} updateCoffeeOnCart={updateCoffeeOnCart} removeFromCart={removeFromCart} reset={reset}
                            isInCart={isInCart} requestedAlready={requestedAlready} setRequestedAlready={setRequestedAlready} />
                        <Sensory chartAttributes={chartAttributes} isOwner={isOwner} isProducer={isProducer} handleSave={coffeeId === 'new' ? save : edit} setCoffeeDataOrigin={setCoffeeDataOrigin}
                            setCoffeeData={setCoffeeData} scoreAttributes={scoreAttributes}
                            coffeeData={coffeeData} hasEdited={hasEdited} autocomplete={autocomplete} sensoryList={sensoryList}
                            setSensoryList={setSensoryList} reset={reset} setHasEdited={setHasEdited} />
                        {/* <Ratings></Ratings> */}
                        <Origin isOwner={isOwner} producerData={producerData} imageUrl={prodImageUrl} farmImageUrl={farmImageUrl} coffeeData={coffeeData}
                            setCoffeeData={setCoffeeData} regionSubtitle={regionSubtitle} setProducerData={setProducerData} hasEdited={hasEdited}
                            producerDataOrigin={producerDataOrigin} setProducerDataOrigin={setProducerDataOrigin} updateProducerData={updateProducerData}
                            fileUpload={fileUploadProfile} onDropRejected={onDropRejected} removeFile={removeProfilePicture} selectedFileURL={selectedFileURL} setSelectedFileURL={setSelectedFileURL} />
                    </div>
                </div>
            </Main>
        </>
    )
}

export default CoffeeProfile