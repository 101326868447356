import React, { useEffect, useContext, useState } from 'react'
import './Checkout.css'

import Main from '../templates/Main.jsx'
import { server } from '../../common.js'
import axios from 'axios'

import CartContext from '../../context/cart/CartContext'

const Checkout = props => {

    const cartContext = useContext(CartContext)
    const [status, setStatus] = useState('loading')

    useEffect(() => {
        cartContext.updateCart([])
        axios.get(`${server}/paymentStatus/${props.match.params.paymentId}`)
            .then(res => {
                if (res.data.status === "paid") {
                    setStatus('paid')
                } else {
                    setStatus('failed')
                }
            })
    }, [])

    const renderLoading = (
        "Verifying..."
    )

    const renderPaid = (
        "Your purchased has been verified. Thank you!"
    )

    const renderFailed = (
        "Whoops, something went wrong with your payment."
    )

    const renderMessage = () => {
        switch (status) {
            default:
            case 'loading':
                return renderLoading
            case 'paid':
                return renderPaid
            case 'failed':
                return renderFailed
        }
    }

    return (
        <Main>
            <div className="checkout">
                <div className="checkout-message">
                    {renderMessage()}
                </div>
            </div>
        </Main>
    )
}

export default Checkout