import React, { useContext, useState, useEffect } from 'react'

import FarmlyModal from '../templates/FarmlyModal.jsx'
import FarmlyInput from '../templates/FarmlyInput.jsx'
import PricingInfo from '../templates/PricingInfo.jsx'
import CartContext from '../../context/cart/CartContext'
import UserContext from '../../context/user/UserContext'
import { parseCoffeeImage } from 'utils/firebase/index.ts'
import Select from 'react-select'
import { isPt, farmlyTexts, server } from '../../common.js'
import { storage } from '../../firebase'
import { Link } from 'react-router-dom'
import axios from 'axios'
import * as pricings from '../templates/pricing.js'

// import { CoffeeFilterContainer } from 'modules/CoffeeManagement/components/FilterCoffees/styles.js'

const producerImage = require('../../assets/producer.jpg')

const CoffeeItemAdmin = props => {

    const { id, producer, price, quantity, producerFee,
            setQuantity, sacksLeft, brazil, coffee, couponItem, setCouponItemData} = props
    const { farmly_fee, logistics_fee, import_duties, IRPJ_CSLL } = props.paymentData

    const userContext = useContext(UserContext)
    const [showPricingInfo, setShowPricingInfo] = useState(false)
    const [coffeeImgUrl, setCoffeeImageUrl] = useState("")
    const [modalMessage, setModalMessage] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [showCoffeeModal, setShowCoffeeModal] = useState(false)
    const [displayCouponPriceSimulation, setDisplayCouponPriceSimulation] = useState(false)
    const [selectOptions, setSelectOptions] = useState([{value: 0,label: "Reais", c: "Brazil"}, {value: 1, label: "Euro", c: "other"}, {value: 2, label: "Pesos", c: "Colombia"}])
    const [couponSimulationResult, setCouponSimulationResult] = useState({producer: 0.00, logisticsF: 0.00, importD: 0.00, farmlyF: 0.00, total: 0.00})
    const [couponData, setCouponData] = useState({
        currency: 0,
        sack_price: 0.00,
        euro_price: 0.00,
        logistics: 0.00,
        farmly_fee: 0.00,
        transport_fee: 0.00
    })
    
    const deleteFromCart = id => {
        axios.delete(`${server}/deleteCartItem/${id}/${window.location.pathname.split('/')[2]}`)
        setModalMessage("Coffee removed. Wait a few seconds...")
        setShowModal(true)
        setTimeout(function () {
            refreshPage()
        }, 1500)
    }

    function refreshPage() {
        window.location.reload();
    }

    const toggleModal = () => {
        setShowModal(!showModal)
    }

    useEffect(() => {
        const getCoffeeImage = async () => {
            try {
                const images = await (await axios.get(`${server}/coffeeProdImg/${id}`)).data
                if(images.length > 0 && images[0].coffee_image){
                    const coffeeImages = await parseCoffeeImage("producers", images[0].coffee_image)
                    setCoffeeImageUrl(coffeeImages[0])
                } else {
                    setCoffeeImageUrl('/static/media/producer.18e4bf68.jpg')
                }
            } catch(error){
                alert('an error occured')
                console.log(error)
            }
          
        }
            // .then(res => {
            //     const farmImg = res.data.farmImg || ""
            //     //GAMBIARRA PARA O INACIO! TIRAR QUANDO RESOLVER OS CROPS!
            //     const refUrl = props.producerId == 61
            //         ? farmImg.split(',')[((props.coffeeId % 69) + 2) % 4]
            //         : res.data.profileImg
            //     if (refUrl) {
            //         storage.ref('producers').child(refUrl).getDownloadURL()
            //             .then(imgUrl => setCoffeeImageUrl(imgUrl))
            //             .catch(() => { })
            //     }
            // })
            getCoffeeImage()
    },  [])

    const simulateCoffeeCouponCodePriceReduction = (coffee) => {
        const coffeeInfo = [coffee];
        const producers = pricings.toEuroWithDiscount(couponData.sack_price, {euro_price: couponData.euro_price})
        const totalLogisticsFee = pricings.totalLogisticsFeeWithDiscount(coffeeInfo, couponData)
        const totalImportDuties = pricings.totalImportDutiesWithDiscount(coffeeInfo, couponData)
        const totalFarmlyFee = pricings.totalFarmlyFeeWithDiscount(coffeeInfo, couponData)
        const discount = parseFloat(producers + totalLogisticsFee + totalImportDuties + totalFarmlyFee).toFixed(2)
        setCouponSimulationResult({producer: producers.toFixed(2), logisticsF: totalLogisticsFee.toFixed(2), importD: totalImportDuties.toFixed(2),
        farmlyF: totalFarmlyFee.toFixed(2), total: discount})
        setDisplayCouponPriceSimulation(true)
    }


  
    const createCouponItem = (coffee) => {
        const couponItemIndex = couponItem.findIndex(c => c.item_id === coffee.id) 
        if(couponItemIndex === -1 ){
            if(couponItem[0].item_id === 0){
                couponItem[0].item_id = coffee.id
                couponItem[0].quantity = coffee.quantity
                couponItem[0].euro_value = couponData.euro_price
                couponItem[0].item_value = coffee.origin === "Brazil" ? parseFloat(couponData.sack_price) / (couponData.euro_price * 60) : parseFloat(couponData.sack_price) / (couponData.euro_price * 70) 
                couponItem[0].sack_price = couponData.sack_price
                couponItem[0].logistics_fee = couponData.logistics
                couponItem[0].farmly_fee = couponData.farmly_fee
                couponItem[0].transport_fee = couponData.transport_fee
                couponItem[0].name = ""
            }
             else {
                couponItem.push({
                    item_id: coffee.id,
                    quantity: coffee.quantity,
                    euro_value: couponData.euro_price,
                    item_value:  coffee.origin === "Brazil" ? parseFloat(couponData.sack_price) / (couponData.euro_price * 60) : parseFloat(couponData.sack_price) / (couponData.euro_price * 70), 
                    sacks_price: couponData.sack_price,
                    logistics_fee: couponData.logistics,
                    farmly_fee: couponData.farmly_fee,
                    transport_fee: couponData.transport_fee,
                    name: "" 
                })
        }
        alert("Item do cupom adicionado com sucesso!");
        } else {
            couponItem[couponItemIndex].item_id = coffee.id
            couponItem[couponItemIndex].quantity = coffee.quantity
            couponItem[couponItemIndex].euro_value = couponData.euro_price
            couponItem[couponItemIndex].item_value = coffee.origin === "Brazil" ? parseFloat(couponData.sack_price) / (couponData.euro_price * 60) : parseFloat(couponData.sack_price) / (couponData.euro_price * 70)
            couponItem[couponItemIndex].sack_price = couponData.sack_price
            couponItem[couponItemIndex].logistics_fee = couponData.logistics
            couponItem[couponItemIndex].farmly_fee = couponData.farmly_fee
            couponItem[couponItemIndex].transport_fee = couponData.transport_fee
            couponItem[couponItemIndex].name = ""

            alert("Item do cupom alterado com sucesso!");

        }
        setCouponItemData(couponItem)
        
    }
    useEffect(() => {
        const inputs = document.querySelectorAll('.farmly-input');
        inputs.forEach((item, index) => {
            switch(index) 
            {
                case 0:
                    item.value = coffee.sackPrice
                break;
                case 1:
                    item.value = coffee.euro_value
                    break;
                case 2: 
                    item.value = coffee.logistics_fee
                    break;
                case 3:
                    item.value = coffee.farmly_fee
                    break;
            }
            
        })
    }, [showCoffeeModal])
    
    
    return (
        
        <>
        <div className="cart-coffee-item">
            {showModal && <FarmlyModal id="page-modal" closeModal={toggleModal}>{modalMessage}</FarmlyModal>}
            {showPricingInfo &&
                <FarmlyModal closeModal={() => setShowPricingInfo(false)}>
                    {/* <PricingInfo producerFee={producerFee/quantity} logistics_fee={logistics_fee[0].logistics_fee*quantity}
                        import_duties={import_duties[0].import_duties*quantity} farmly_fee={farmly_fee[0].farmly_fee*quantity} brazil={brazil} />  */}
                    <PricingInfo producerFee={producerFee/quantity} logistics_fee={logistics_fee/quantity}
                        import_duties={import_duties/quantity} farmly_fee={farmly_fee/quantity} brazil={brazil} IRPJ_CSLL={IRPJ_CSLL }/> 
                    {/* VERSAO BRASILEIRA: <PricingInfo producerFee={producerFee} logistics_fee={logistics_fee}
                        import_duties={import_duties} farmly_fee={farmly_fee} />  */} 
                </FarmlyModal>}
            <div className="cart-item-remove-container">
                <div className="cart-item-remove" onClick={() => { deleteFromCart(id) }} >X</div>
            </div>
            {coffeeImgUrl &&
            <Link to={`/coffee/${id}`} style={{width: "50%"}}>
                <img src={coffeeImgUrl} className="cart-producer-img" alt="" />
            </Link>}
            <div className="cart-coffee-info-container" style={{width: "50%"}}>
                <label className="cart-column-title cart-column-title-mobile">
                    {isPt() ? farmlyTexts.cartProduct_pt : farmlyTexts.cartProduct_en}:
                </label>
                <label className="cart-item-product">{producer}</label>
            </div>
            <div className="cart-coffee-info-container" style={{width: "50%"}}>
                <label className="cart-column-title cart-column-title-mobile">
                    {isPt() ? farmlyTexts.cartPrice_pt : farmlyTexts.cartPrice_en}:
                </label>
                <label className="cart-item-price">€{price.toFixed(2)}</label>
            </div>
            <div className="cart-coffee-info-container" style={{width: "50%"}}>
                <label className="cart-column-title cart-column-title-mobile">
                    {isPt() ? farmlyTexts.cartQuantity_pt : farmlyTexts.cartQuantity_en}
                </label>
                <div className="cart-item-quantity">
                    {/* <div className="cart-quantity-minus" onClick={decreaseQuantity}>-</div> */}
                    <FarmlyInput value={Math.min(quantity, sacksLeft)} setValue={value => setQuantity(value, id)}
                        styleClass="cart-quantity-display" upperBound={sacksLeft} type="numeric" />
                    {/* <div className="cart-quantity-plus" onClick={increaseQuantity}>+</div> */}
                </div>

            </div>
            <div className="cart-coffee-info-container" style={{width: "50%"}}>
                <label className="cart-column-title cart-column-title-mobile">
                    {isPt() ? farmlyTexts.cartProducer_pt : farmlyTexts.cartProducer_en}:
                </label>
                <label className="cart-item-producer">€{producerFee.toFixed(2)}</label>
                <div className="cart-item-pricing-details" onClick={() => setShowPricingInfo(true)}>
                    i
                </div>
                {userContext.user && userContext.user.id == 0 &&<div className="cart-item-pricing-details" onClick={() => setShowCoffeeModal(true)}>
                    +
                </div>
                }
            </div>
        </div>
      

        {showCoffeeModal && (
        <FarmlyModal closeModal={() => setShowCoffeeModal(false)}>
            <div className="confirm-info-container">
                        <label className="pricing-info-title">Forneça os dados do cupom</label>
                        <div className="pricing-info-item">
                            <label className="pricing-info-label">Moeda:</label>
                            <div style={{width: "57%"}}>
                            <Select
								id="shipping-country"
								placeholder="Moeda"
                                style={{width: "57%"}}
                                defaultValue={selectOptions.filter(c => c.c === coffee.origin)}
                                options={selectOptions}
								onChange={(e) =>
								setCouponData({
							    ...couponData,
								currency: e.value,
								})
								}
								
							></Select>
                            </div>
                        </div>
                        <div className="pricing-info-item">
                        <label className="pricing-info-label">Preço da Saca</label>
                            <FarmlyInput value={couponData.sack_price} setValue={value => setCouponData({ ...couponData, sack_price: parseFloat(value) })}
                                className="pricing-info-value" id="sackPrice" type="number" placeholder="Preço da Saca..." />
                        </div>
                        <div className="pricing-info-item">
                            <label className="pricing-info-label">Preço do Euro:</label>
                            <FarmlyInput value={couponData.euro_price} setValue={value => setCouponData({ ...couponData, euro_price: parseFloat(value) })}
                                className="pricing-info-value" id="euro" type="number" placeholder="euro..." />
                        </div>
                        <div className="pricing-info-item">
                            <label className="pricing-info-label">Logistica:</label>
                            <FarmlyInput value={couponData.logistics} setValue={value => setCouponData({ ...couponData, logistics: parseFloat(value) })}
                                className="pricing-info-value" id="logistics" type="number" placeholder="logistics..." />
                        </div>
                        <div className="pricing-info-item">
                            <label className="pricing-info-label">Taxa da Farmly:</label>
                            <FarmlyInput value={couponData.farmly_fee} setValue={value => setCouponData({ ...couponData, farmly_fee: parseFloat(value) })}
                                className="pricing-info-value" id="farmly_fee" type="number" placeholder="Taxa da Farmly..." />
                        </div>
                        <div className="pricing-info-item">
                            <label className="pricing-info-label">Transporte:</label>
                            <FarmlyInput value={couponData.transport_fee} setValue={value => setCouponData({ ...couponData, transport_fee: parseFloat(value) })}
                                className="pricing-info-value" id="transport_fee" type="number" placeholder="Taxa de transporte em  %" />
                        </div>
                       
                        <br />
                        {displayCouponPriceSimulation &&     
                        <table>
                            <thead>
                             <th>Produtor</th>
                             <th>Logistica</th>
                             <th>import duties</th>
                             <th>Taxa da Farmly</th>
                             <th>Total</th>
                             </thead>
                              <tbody style={{width: '100%'}}>
                               <td>{couponSimulationResult.producer}</td>
                               <td>{couponSimulationResult.logisticsF}</td>
                               <td>{couponSimulationResult.importD}</td>
                               <td>{couponSimulationResult.farmlyF}</td>
                               <td>{couponSimulationResult.total}</td>
                              </tbody>
                        </table>}
                    </div>
                        <div style={{display: 'flex', justifyContent: 'space-evenly', width: '90%'}}>
                            <div className="cart-checkout-button" onClick={() => { simulateCoffeeCouponCodePriceReduction(coffee)}}>Simular</div>
                            <div className="cart-checkout-button" onClick={() => { createCouponItem(coffee)}}>Add item</div>
                        </div>
                     
        </FarmlyModal>
    )}
   
        </>
        
    )


    
    
}

export default CoffeeItemAdmin