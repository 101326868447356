import styled from "styled-components";

export const coffeePicture3 = require('assets/coffee_farm.jpg');

export const Container = styled.div`
    background-color: var(--farmly-seaSalt);
    overflow: hidden;
    height: 151px;
`

export const CardImg = styled.img`
    z-index: -1;
    opacity: 1;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 177px;
    object-fit: cover;
    border-radius: 5px 5px 0 0;
    background-color: var(--farmly-seaSalt);
`