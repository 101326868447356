import React from "react";
import L from 'leaflet'
import { Map, Marker, TileLayer } from "react-leaflet";
import { language } from "../../../common";

import ButtonBase from "@material-ui/core/ButtonBase";

import "../../../styles/profile/components/UserLocation.css";

const homeIcon = L.icon({
    iconUrl: require('../../../assets/home_map_icon.png'),
    iconSize: [40, 40],
    iconAnchor: [17, 46],
});

const UserLocation = (props) => {
    const {
        isOwner,
        userDataOrigin,
        setUserDataOrigin,
        submitUserLocation,
        windowSize,
    } = props;

    const handleMapClick = (event) => {
        // console.log(event.latlng.lat, event.latlng.lng);
        isOwner && setUserDataOrigin({ ...userDataOrigin, latitude: event.latlng.lat, longitude: event.latlng.lng })
    }

    if (!isOwner && !userDataOrigin.latitude) {
        return null;
    }
    return (
        <div className="new-profile user-location">
            {windowSize > 768
                ?
                <h2 className="new-profile user-location-title big-title">{{ pt: 'Localização', es: 'Localización' }[language]}</h2>
                :
                <h3 className="new-profile user-location-title">{{ pt: 'Localização', es: 'Localización' }[language]}</h3>
            }
            {isOwner ?
                (
                    userDataOrigin.isProd === true ?
                        <p>
                            {
                                {
                                    pt: 'Clique no mapa para indicar a localização da fazenda',
                                    es: 'Clic en el mapa para indicar la localización de la granja'
                                }[language]
                            }
                        </p>
                        :
                        <p>
                            {
                                {
                                    pt: 'Clique no mapa para indicar a localização da torrefadora',
                                    es: 'Clic en el mapa para indicar la localización del tostador'
                                }[language]
                            }
                        </p>
                )
                :
                null
            }
            <Map
                zoomControl={false}
                center={
                    userDataOrigin.latitude
                        ? [userDataOrigin.latitude, userDataOrigin.longitude]
                        : [-14.235, -51.9253]
                }
                zoom={7}
                className="new-profile profile-map-container"
                onClick={(e) => handleMapClick(e)}
            >
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                />
                <Marker
                    icon={homeIcon}
                    position={
                        userDataOrigin.latitude
                            ? [
                                userDataOrigin.latitude,
                                userDataOrigin.longitude,
                            ]
                            : [-14.235, -51.9253]
                    }
                />
            </Map>
            {isOwner && (
                <ButtonBase
                    style={{ width: 'fit-content' }}
                    className="new-farmly-button f-button-green"
                    onClick={(e) => {
                        isOwner && submitUserLocation(userDataOrigin);
                    }}
                >
                    {{ pt: 'Confirmar localização', es: 'Confirmar localización', en: 'Confirm location' }[language]}
                </ButtonBase>
            )}
        </div>
    );
};

export default UserLocation;
