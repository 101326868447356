import React, { FunctionComponent } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronLeft,
    faChevronRight
} from '@fortawesome/free-solid-svg-icons';
import Slider from "react-slick";

import { SliderContainer, NextArrowBackGround, PrevArrowBackGround, SlickDots, SamplesContainer } from './style';

interface SamplesCarouselProps {
    renderSamples: () => JSX.Element[]
}

const SamplesCarousel: FunctionComponent<SamplesCarouselProps> = (props) => {

    const SamplePrevArrow = (props: { onClick?: () => void }) => {
        const { onClick } = props;
        return (
            <PrevArrowBackGround>
                <FontAwesomeIcon icon={faChevronLeft} className="prev-arrow" onClick={onClick} />
            </PrevArrowBackGround>
        )
    }

    const SampleNextArrow = (props: { onClick?: () => void }) => {
        const { onClick } = props;
        return (
            <NextArrowBackGround>
                <FontAwesomeIcon icon={faChevronRight} className="next-arrow" onClick={onClick} />
            </NextArrowBackGround>
        )
    }

    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: false,
        autoplaySpeed: 2500,
        pauseOnHover: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        appendDots: (dots: any) => <ul>{dots}</ul>,
        customPaging: () => (
            <SlickDots>
                <button className="ft_slick_dots">
                    <div className="loading" />
                </button>
            </SlickDots>
        ),
        responsive: [
            {
                breakpoint: 1450,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 950,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
        ]
    }

    return (
        <>
            <SliderContainer>
                <SamplesContainer>
                <Slider {...settings}>
                    {props.renderSamples()}
                </Slider>
                </SamplesContainer>
            </SliderContainer>
        </>
    );
}
export default SamplesCarousel;