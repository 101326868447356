import React from "react";
import Slider from "react-slick";
import './NewCarousel.css';
import { useEffect } from 'react';
import CoffeeCard from '../coffees/CoffeeCard'
import zIndex from "@material-ui/core/styles/zIndex";

const NewCarousel = (props) => {
    const coffees = props.coffees

    useEffect(() => { }, [coffees])

    var settings = {
        dots: true,
        adaptiveHeight: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        autoplay: false,
        autoplaySpeed: 2500,
        pauseOnHover: true,
        customPaging: index => (
            <li className="li-slick-dots">
                <button className="ft_slick_dots">
                    <div className="loading" />
                </button>
            </li>
        ),
        responsive: [
            {
                breakpoint: 1030,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: false,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const renderCoffeeCards = () =>
        coffees.map(coffee => {
            return (
                <div className="card">
                    <CoffeeCard producerId={coffee.producerId} key={coffee.id} coffeeId={coffee.id} coffee={coffee}
                        farmName={coffee.farm?coffee.farm:coffee.farmName} score={coffee.score} sensoryNotesEn={coffee.sensory_notes_en}
                        imageUrl={coffee.imageUrl} sensoryNotes={coffee.sensoryNotes} farmCode={coffee.farmCode}
                        variety={coffee.variety} process={coffee.process} />
                </div>
            );
        });

    return (
        <div className="new-carousel-container" >
            {coffees.length !== 0 &&
                <Slider {...settings}>
                    {renderCoffeeCards()}
                    {/* <div className="card">
                        <CoffeeCard producerId={coffees[0].producerId} key={coffees[0].id} coffeeId={coffees[0].id} coffee={coffees[0]}
                            farmName={coffees[0].farm} score={coffees[0].score} sensoryNotesEn={coffees[0].sensory_notes_en}
                            imageUrl={coffees[0].imageUrl} sensoryNotes={coffees[0].sensoryNotes} farmCode={coffees[0].farmCode}
                            variety={coffees[0].variety} process={coffees[0].process} />
                    </div>
                    <div className="card">
                        <CoffeeCard producerId={coffees[1].producerId} key={coffees[1].id} coffeeId={coffees[1].id} coffee={coffees[1]}
                            farmName={coffees[1].farm} score={coffees[1].score} sensoryNotesEn={coffees[1].sensory_notes_en}
                            imageUrl={coffees[1].imageUrl} sensoryNotes={coffees[1].sensoryNotes} farmCode={coffees[1].farmCode}
                            variety={coffees[1].variety} process={coffees[1].process} />
                    </div>
                    <div className="card">
                        <CoffeeCard producerId={coffees[2].producerId} key={coffees[2].id} coffeeId={coffees[2].id} coffee={coffees[2]}
                            farmName={coffees[2].farm} score={coffees[2].score} sensoryNotesEn={coffees[2].sensory_notes_en}
                            imageUrl={coffees[2].imageUrl} sensoryNotes={coffees[2].sensoryNotes} farmCode={coffees[2].farmCode}
                            variety={coffees[2].variety} process={coffees[2].process} />
                    </div>
                    <div className="card">
                        <CoffeeCard producerId={coffees[3].producerId} key={coffees[3].id} coffeeId={coffees[3].id} coffee={coffees[3]}
                            farmName={coffees[3].farm} score={coffees[3].score} sensoryNotesEn={coffees[3].sensory_notes_en}
                            imageUrl={coffees[3].imageUrl} sensoryNotes={coffees[3].sensoryNotes} farmCode={coffees[3].farmCode}
                            variety={coffees[3].variety} process={coffees[3].process} />
                    </div>
                    <div className="card">
                        <CoffeeCard producerId={coffees[4].producerId} key={coffees[4].id} coffeeId={coffees[4].id} coffee={coffees[4]}
                            farmName={coffees[4].farm} score={coffees[4].score} sensoryNotesEn={coffees[4].sensory_notes_en}
                            imageUrl={coffees[4].imageUrl} sensoryNotes={coffees[4].sensoryNotes} farmCode={coffees[4].farmCode}
                            variety={coffees[4].variety} process={coffees[4].process} />
                    </div>
                    <div className="card">
                        <CoffeeCard producerId={coffees[5].producerId} key={coffees[5].id} coffeeId={coffees[5].id} coffee={coffees[5]}
                            farmName={coffees[5].farm} score={coffees[5].score} sensoryNotesEn={coffees[5].sensory_notes_en}
                            imageUrl={coffees[5].imageUrl} sensoryNotes={coffees[5].sensoryNotes} farmCode={coffees[5].farmCode}
                            variety={coffees[5].variety} process={coffees[5].process} />
                    </div>
                    <div className="card">
                        <CoffeeCard producerId={coffees[6].producerId} key={coffees[6].id} coffeeId={coffees[6].id} coffee={coffees[6]}
                            farmName={coffees[6].farm} score={coffees[6].score} sensoryNotesEn={coffees[6].sensory_notes_en}
                            imageUrl={coffees[6].imageUrl} sensoryNotes={coffees[6].sensoryNotes} farmCode={coffees[6].farmCode}
                            variety={coffees[6].variety} process={coffees[6].process} />
                    </div>
                    <div className="card">
                        <CoffeeCard producerId={coffees[7].producerId} key={coffees[7].id} coffeeId={coffees[7].id} coffee={coffees[7]}
                            farmName={coffees[7].farm} score={coffees[7].score} sensoryNotesEn={coffees[7].sensory_notes_en}
                            imageUrl={coffees[7].imageUrl} sensoryNotes={coffees[7].sensoryNotes} farmCode={coffees[7].farmCode}
                            variety={coffees[7].variety} process={coffees[7].process} />
                    </div> */}
                </Slider>
            }
        </div>
    );
}

export default NewCarousel