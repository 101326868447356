import React from 'react'
import './BasicInfo.css'

import { isPt, farmlyTexts, server, isCo, isBr } from '../../common'
import { useEffect } from 'react'
import ReactGA from 'react-ga'
import axios from 'axios'
import { GoogleLogin } from 'react-google-login'
import {Link, useHistory} from 'react-router-dom'

function Message() {
    return(
        <>
        <p>Esqueceu a senha?</p>
        </>
    );
}

const BasicInfo = props => {

    const { userData, setUserData, next, setModalMessage, toggleModal } = props

    const allFilled = (
        userData.name !== '' &&
        userData.email !== '' &&
        userData.password !== '' &&
        userData.confirmPassword !== ''
    )
    const history = useHistory()


    const validate = (email) => {
        const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
    
        return expression.test(String(email).toLowerCase())
    }

    const passwordMatches = userData.password === userData.confirmPassword

    useEffect(() => {
        window.scrollTo(0, 0)
        // if(isBr()) {
        //     history.push({
        //         pathname: '/signupInvite'
        //     })
        //     history.go()
        // }
    }, [])

    const responseGoogle = (response) => {
        setUserData({ ...userData, email: response.profileObj.email, name: response.profileObj.name, password: response.profileObj.googleId })
        next()
    }

    //REPORTS ANALYTICS
    useEffect(() => {
        ReactGA.event({
            category: 'SignUp - Basic Info',
            action: `Visited the SignUp Basic Info Page`
        })
    }, [])
    const clickInputName = () =>{
        if(isPt()) {
            ReactGA.event({
                category: 'SignUp - Basic Info',
                action: `New producer clicked on name input`
            })
        } else {
            ReactGA.event({
                category: 'SignUp - Basic Info',
                action: `New roaster clicked on name input`
            })
        }
    }
    const clickInputEmail = () =>{
        if(isPt()) {
            ReactGA.event({
                category: 'SignUp - Basic Info',
                action: `New producer clicked on email input`
            })
        } else {
            ReactGA.event({
                category: 'SignUp - Basic Info',
                action: `New roaster clicked on email input`
            })
        }
    }
    const clickInputCreatePassword = () =>{
        if(isPt()) {
            ReactGA.event({
                category: 'SignUp - Basic Info',
                action: `New producer clicked on create password input`
            })
        } else {
            ReactGA.event({
                category: 'SignUp - Basic Info',
                action: `New roaster clicked on create password input`
            })
        }
    }
    const clickInputConfirmPassword = () =>{
        if(isPt()) {
            ReactGA.event({
                category: 'SignUp - Basic Info',
                action: `New producer clicked on confirm password input`
            })
        } else {
            ReactGA.event({
                category: 'SignUp - Basic Info',
                action: `New roaster clicked on confirm password input`
            })
        }
    }

    return (
        <div id='basic-info'>
            <div className="signup-title-row">
                <div className="coffee-icon" />
                <label className="signup-title">{isPt() ? farmlyTexts.signupTitle_pt : (isCo() ? `INSCRÍBETE` : farmlyTexts.signupTitle_en)}</label>
            </div>
            <div className="signup-row">
                <label className="signup-field-label">{isPt() ? farmlyTexts.signupName_pt : (isCo() ? 'Nombre' : farmlyTexts.signupName_en)}</label>
                <input type="text" className="signup-field-input" value={userData.name}
                    onChange={e => setUserData({ ...userData, name: e.target.value })}
                    onClick={()=> clickInputName()}
                    placeholder={isPt() ? farmlyTexts.signupName_pt : (isCo() ? 'Nombre' : farmlyTexts.signupName_en)} />
            </div>
            <div className="signup-row">
                <label className="signup-field-label">{isPt() ? farmlyTexts.signupEmail_pt : farmlyTexts.signupEmail_en}</label>
                <input type="email" className="signup-field-input" value={userData.email} required
                    onChange={e => setUserData({ ...userData, email: e.target.value })}
                    onClick={()=> clickInputEmail()}
                    placeholder={isPt() ? farmlyTexts.signupEmail_pt : farmlyTexts.signupEmail_en} />
            </div>
            <div className="signup-row signup-compound-row basic-info-compound-row">
                <div className="basic-info-compound-field basic-info-compound-first">
                    <label className="signup-field-label">{isPt() ? farmlyTexts.signupPasswordLabel_pt : (isCo() ? 'Elija su contraseña' : farmlyTexts.signupPasswordLabel_en)}</label>
                    <input type="password" className="signup-field-input basic-info-input" value={userData.password}
                        onChange={e => setUserData({ ...userData, password: e.target.value })}
                        onClick={()=> clickInputCreatePassword()}
                        placeholder={isPt() ? farmlyTexts.loginPassword_pt : (isCo() ? `Contraseña` : farmlyTexts.loginPassword_en)} />
                </div>
                <div className="basic-info-compound-field">
                    <label className="signup-field-label">{isPt() ? farmlyTexts.signupConfirmPassword_pt : (isCo() ? 'Confirma tu contraseña' : farmlyTexts.signupConfirmPassword_en)}</label>
                    <input type="password" className="signup-field-input basic-info-input" value={userData.confirmPassword}
                        onChange={e => setUserData({ ...userData, confirmPassword: e.target.value })}
                        onClick={()=> clickInputConfirmPassword()}
                        placeholder={isPt() ? farmlyTexts.signupConfirmPassword_pt : (isCo() ? 'Confirma tu contraseña' : farmlyTexts.signupConfirmPassword_en)} />
                </div>
            </div>
            <div className="signup-row-button basic-bottom">
                <div className="signup-basic-button" style={{ marginBottom: '8px'}} onClick={async () => {
                    const nextButton = document.querySelector('.signup-basic-button')
                    nextButton.style.pointerEvents = 'none'
                    nextButton.innerHTML = isPt() ? 'Aguarde...' : (isCo() ? 'Espera...' : 'Wait...')
                    const res = await axios.post(`${server}/userExists`, { email: userData.email })
                    const userExists = res.data.exists

                    if (!allFilled) {
                        const fillMsg = isPt() ? farmlyTexts.signupPleaseFill_pt : (isCo() ? `¡Rellena todos los campos!` :farmlyTexts.signupPleaseFill_en)
                        setModalMessage(fillMsg)
                        toggleModal()
                    }
                    else if (userExists) {
                        const userExistsMsg = isPt() ? 
                            <>
                                <p>Email já cadastrado!</p>
                                <Link to="/login">Deseja fazer Login?</Link>
                                </> : (
                                    isCo() ? <>
                                    <p>¡El email ya está registrado!</p>
                                    <Link to="/login">¿Quieres iniciar sesión?</Link>
                                    </> : 
                                    <>
                                        <p>Email already registered!</p>
                                        <Link to="/login">Do you want to log in?</Link>
                                    </>
                            )
                        setModalMessage(userExistsMsg)
                        toggleModal()
                    }
                    else if (!validate(userData.email)) {
                        const emailInvalidMsg = isPt() ? 'Selecione um email válido!' : (isCo() ? 'Selecciona un email válido!' : 'Select a valid email!')
                        setModalMessage(emailInvalidMsg)
                        toggleModal()
                    }

                    else if (!passwordMatches) {
                        const passwordMsg = isPt() ? 'Senha e confirmação devem ser iguais!' : (isCo() ? 'La contraseña y la confirmación deben ser las mismas!' :'Password and confirmation must match!')
                        setModalMessage(passwordMsg)
                        toggleModal()
                    }
                    else next()
                    nextButton.style.pointerEvents = 'auto'
                    nextButton.innerHTML = isPt() ? farmlyTexts.signupNext_pt : farmlyTexts.signupNext_en
                    if(isPt()) {
                        ReactGA.event({
                            category: 'SignUp',
                            action: `New Producer finished basic info ${userData.email}`
                        })
                    } else {
                        ReactGA.event({
                            category: 'SignUp',
                            action: `New roaster finished basic info ${userData.email}`
                        })
                    }

                }}>
                    {isPt() ? farmlyTexts.signupNext_pt : (isCo() ? 'Siguiente' : farmlyTexts.signupNext_en)}
                </div>
                <label className="signup-field-label">{isPt() ? 'Ou' : (isCo() ? 'O' : 'Or')}</label>
                <div style={{marginTop: '5px'}}>
                    <GoogleLogin
                        clientId="503177513332-b2g42k0864opttrsn7v6v8e2o3imvse5.apps.googleusercontent.com"
                        buttonText={isPt() ? 'Entrar com Google' : (isCo() ? `Registro en Google` : "Log in with Google")}
                        onSuccess={responseGoogle}
                        onFailure={responseGoogle}
                        cookiePolicy={'single_host_origin'}
                    />
                </div>
            </div>
        </div>
    )
}

export default BasicInfo