import React from 'react';
import { 
  LandingContent, Title, Container, Paragraph, 
  LucasImage, SecondParagraph, Span, CuponImage, 
  ImagesBox, Image, ImagesSubBox, BigImage, 
  WppLink, WppContainer, FormContainer, FormTitle, 
  FormBox, FormLabel, FormInput, FormButton 
} from './styles';
import NewNav from '../../components/templates/NewNav';
import NewFooter from '../../components/templates/NewFooter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useLead from './hooks/useLead';

//images
import LucasImageMontage from "../../assets/jpeg/LucasMontageImg.jpeg"
import CuponImg from "../../assets/jpeg/LucasTripCupon.jpeg"
import Dasawe from "../../assets/png/dasawe.png"
import Girafffe from "../../assets/png/girafffe.png"
import OneEleven from "../../assets/png/one eleven.png"
import Split from "../../assets/png/split.png"
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'



const LandingPageLucasTrip: React.FC = () => {

  const lead = useLead()

  return (
      <>
        <NewNav mainTitle={''} isHome={false} noBanner={true} isCuppingLive = {false} />
        <LandingContent>
          <Title>IF YOU ARE READING THIS...</Title>
          <Container>
            <Paragraph>
              You met Lucas, one of FARMly's founders and we heard that you are not only interested in great coffee, 
              but in opportunities, in creating bonds with producers and having a specialized service when it comes 
              to finding your ideal coffee.
            </Paragraph>
            <LucasImage src={LucasImageMontage}/>
          </Container>
          <Container>
            <SecondParagraph>
              Our big mission here is to impact the coffee market and create bonds instead of just trading!
              You need to find amazing, unique coffee and also get to know who produces them. 
              The producers need to be valued and fairly payed. We are here to guarantee both of them. 
            </SecondParagraph>
            <Paragraph>
              Check here some of our friends who trust and do business through our services:
            </Paragraph>
            <ImagesBox>
              <ImagesSubBox>
                <Image src={OneEleven}/>
                <Image src={Split}/>
              </ImagesSubBox>
              <ImagesSubBox>
                <BigImage src={Girafffe}/>
                <BigImage src={Dasawe}/>
              </ImagesSubBox>
            </ImagesBox>
          </Container>
          <Container>
            <Paragraph>
              And we really want YOU to be a part of this movement along with more than <Span>900 producers and 300 roasters!</Span>
              <br />
              That's why we would like to offer you an exclusive opportunity:
            </Paragraph>
            <CuponImage src={CuponImg}/>
          </Container>
          <Container>
            <Paragraph>
              Print this coupon, send it in our whatsapp to guarantee this opportunity in the next shipment 
              and don't forget to fill out the form to be part of this movement
            </Paragraph>
            <WppContainer>
              <WppLink target="_blank" rel="noopener noreferrer" href={`https://api.whatsapp.com/send?phone=+5537991036677`}>
                  <FontAwesomeIcon className="whatsapp-icon-thank-you" icon={faWhatsapp} />
                  <Span>Share on Whatsapp</Span> 
              </WppLink>
            </WppContainer>
          </Container>
          {!lead.registered ?
            <FormContainer>
              <FormTitle>Subscribe Here!</FormTitle>
              <FormBox>
                <FormLabel>Name</FormLabel>
                <FormInput placeholder="name..." onChange={(e:any) => lead.setData(e.target.value, 'Name')}/>
              </FormBox>
              <FormBox>
                <FormLabel>Roastery</FormLabel>
                <FormInput placeholder="roastery..." onChange={(e:any) => lead.setData(e.target.value, 'Roastery')}/>
              </FormBox>
              <FormBox>
                <FormLabel>Email</FormLabel>
                <FormInput placeholder="email..." onChange={(e:any) => lead.setData(e.target.value, 'Email')}/>
              </FormBox>
              <FormBox>
                <FormLabel>Phone</FormLabel>
                <FormInput placeholder="phone..." onChange={(e:any) => lead.setData(e.target.value, 'Phone')}/>
              </FormBox>
              <FormButton onClick={() => lead.sendData()}>Send</FormButton>
              
            </FormContainer>
          :
            <FormTitle>Thanks for your subscription!</FormTitle>
          }
        </LandingContent>
        <NewFooter noMargin={true}/>
      </>
  );
}



export default LandingPageLucasTrip;