import React, { useState, useContext, useEffect } from "react";
import { storage } from "../../firebase";
import crypto from "crypto";
import axios from "axios";

import SettingsIcon from "@material-ui/icons/Settings";
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import EmailIcon from '@material-ui/icons/Email';
import ButtonBase from "@material-ui/core/ButtonBase";

import { language, isBr, server, acceptedCookies } from "../../common";
import { addImg, removeImg } from "../../image";
import { getUserData } from "../profile/user";

import "../../styles/profile/NewProfile.css";
import "../../styles/profile/components/UserAccordion.css";

import Main from "./Main";
import FarmlyModal from "./FarmlyModal";
import UserContext from "../../context/user/UserContext";
import UserImages from "./profileComponents/UserImages";
import UserLocation from "./profileComponents/UserLocation";
import UserFarmlyClub from "./profileComponents/UserFarmlyClub";
import UserSendSample from "./profileComponents/UserSendSample";
import UserCoffees from "./profileComponents/UserCoffees";
import UserFarm from "./profileComponents/UserFarm";
import EditInformations from "../EditInformations/EditInformations";
import EditPassword from "../EditPassword/EditPassword";
import EditEmail from "../EditEmail/EditEmail";

import UserAvatar from "../templates/profileComponents/UserAvatar";
import UserTitle from "../templates/profileComponents/UserTitle";
import UserRequestSample from "./profileComponents/UserRequestSample";
import { LoadingContext } from "../../context/loading/LoadingContext";

const loadAnim = require("../../assets/load.svg");
const NewProfileTemplate = props => {

    const userContext = useContext(UserContext);
    const loadingContext = useContext(LoadingContext);

    const [showUserSendSampleContent, setShowUserSendSampleContent] = useState(false)
    const [userDataOrigin, setUserDataOrigin] = useState({});
    const [isOwner, setIsOwner] = useState(true);
    const [fetchError, setFetchError] = useState(false);
    const [isShowingCoffeeInventory, setIsShowingCoffeeInventory] = useState(true);
    const profileIsProd = document.location.pathname.split("/")[1] === 'producer';
    
    // const [loadingProfileData,setLoadingProfileData] = useState(false);

    const initUserData = async () => {
        loadingContext.setLoading(true);
        try {
            const cookies = acceptedCookies()
                ? document.cookie
                    .split(";")
                    .filter((item) => item.includes("jwt"))[0]
                    .split("jwt=")[1]
                : "";

            const id = acceptedCookies()
                ? document.cookie
                    .split(";")
                    .filter((item) => item.includes("userId"))[0]
                    .split("userId=")[1]
                : "";
            const userType = acceptedCookies()
            ? document.cookie
                .split(";")
                .filter((item) => item.includes("isProd"))[0]
                .split("isProd=")[1]
            : "";
            if (userContext.isAdmin) {
                const id = document.location.pathname.split("/")[3]
                const response = await axios.post(`${server}${document.location.pathname}`, {
                    jwt: cookies,
                    isProd: profileIsProd,
                    id
                })
                response.data.isProd = profileIsProd;
                setUserDataOrigin(response.data);
            } else {
                const response = await getUserData();
                setUserDataOrigin(response.data);
            }

         

        } catch (error) {
            console.log(error);
        } finally {
            loadingContext.setLoading(false);
        }
    }

    useEffect(() => {
        initUserData();
    }, []);

    useEffect(() => {
        console.log("Olha os dados", userDataOrigin)
        if (userDataOrigin && userDataOrigin.id !== undefined) {
            if (userContext.isAdmin) {
                if (profileIsProd) {
                    getCoffeesByProducerId(userDataOrigin.id);
                } else {
                    console.log("Olha os dados", userDataOrigin)
                    getCoffeesByRoasterId(userDataOrigin.id);
                }
            } else {
                (userDataOrigin.isProd === true) ? getCoffeesByProducerId(userDataOrigin.id) : getCoffeesByRoasterId(userDataOrigin.id)
            }
        }
    }, [userDataOrigin])

    const [coffees, setCoffees] = useState([]);

    const getCoffeesByProducerId = async (
        producerId
    ) => {
        const queryString = `${server}/prodCoffees/${producerId}`

        await axios.get(queryString)
            .then(res => {
                setCoffees(res.data)
            }).catch(error => {
                // console.log(error);
                setFetchError(true);
            });
    }

    const getCoffeesByRoasterId = async (
        roasterId
    ) => {
        const queryString = `${server}/requested_from/${roasterId}`

        await axios.get(queryString)
            .then(res => {
                setCoffees(res.data)
            }).catch(error => {
                // console.log(error);
                setFetchError(true);
            });
    }

    const [modalMessage, setModalMessage] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [closeModal, setCloseModal] = useState(false);
    const [windowSize, setWindowSize] = useState({
        w: window.innerWidth,
        h: window.innerHeight,
    });

    useEffect(() => {
        window.addEventListener("resize", reportWindowSize);
        return () => window.removeEventListener("resize", reportWindowSize);
    });

    const [refreshPage, setRefreshPage] = useState(false)

    const refreshModalAction = (refresh = false) => {
        if (refresh) {
            initUserData()
        }
        setShowModal(false);
    }

    const reportWindowSize = () => {
        setWindowSize({ w: window.innerWidth, h: window.innerHeight });
    };

    const activateEditProfileModal = () => {
        setModalMessage(
            userDataOrigin.isProd === true ?
                <EditInformations
                    userDataOrigin={userDataOrigin}
                    submitUserData={submitUserData}
                    type='producers'
                />
                :
                <EditInformations
                    userDataOrigin={userDataOrigin}
                    submitUserData={submitUserData}
                    type='roasters'
                />
        )
        setShowModal(true)
    }

    const activateEditEmailModal = () => {
        setModalMessage(
            <EditEmail
                submitNewEmail={submitNewEmail}
            />)
        setShowModal(true)
    }

    const activateEditPasswordModal = () => {
        setModalMessage(
            <EditPassword
                submitNewPassword={submitNewPassword}
            />)
        setShowModal(true)
    }

    const submitUserData = (data) => {
        delete data.user_id;
        delete data.img;
        delete data.email;
        delete data.created_at;
        delete data.resetPasswordToken;
        delete data.resetTokenExpiration;

        setCloseModal(true);
        setModalMessage(
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "auto",
                }}
            >
                <p>{{ pt: 'Salvando dados...', es: 'Guardando datos...', en: 'Saving data...' }[language]}</p>
                <img
                    src={loadAnim}
                    alt="loading animation"
                    style={{
                        display: "inline",
                        width: "50px",
                        height: "50px",
                        alignSelf: "center",
                    }}
                />
            </div>
        );

        axios.patch(`${server}/updateAllUserData`, { ...data, isProd: userContext.user.isProd, country: language === 'pt' ? "Brazil" : "Colombia" })
            .then((res) => {
                setModalMessage(
                    <p>{{ pt: 'Dados salvos com sucesso!', es: '¡Datos guardados con éxito!', en: 'Data saved successfully!' }[language]}</p>
                );
                setRefreshPage(true);
                // history.go(0);
            })
            .catch((err) => {
                console.log(err);
                setModalMessage(
                    <p>
                        {{ pt: 'Erro ao salvar dados! Tente novamente mais tarde.', es: '¡Error al guardar los datos! Por favor, inténtelo más tarde.', en: 'Error when saving data! Please try again later.' }[language]}
                    </p>
                );
            })
            .finally(() => {
                setCloseModal(false);
            })
    }

    const submitUserLocation = (data) => {
        setShowModal(true);
        submitUserData(data);
    }

    const submitNewPassword = (data) => {
        // console.log(data)
        axios.patch(`${server}/updateAllUserPassword`, { ...data, isProd: userContext.user.isProd, id: userContext.user.id })
            .then(res => {
                setModalMessage(
                    <p>{{ pt: 'Dados salvos com sucesso!', es: '¡Datos guardados con éxito!', en: 'Data saved successfully!' }[language]}</p>
                )
                // refreshModalAction(true)
                setRefreshPage(true);
            })
            .catch(err => {
                console.log(err.response.data);
                if (err.response.data && typeof (err.response.data) === 'object' && err.response.data.status === "auth-failed") {
                    setModalMessage(
                        <div style={{ display: 'flex', flexDirection: "column" }}>
                            <p>{{ pt: 'Senha antiga incorreta! Tente novamente', es: '¡Contraseña antigua incorrecta! Por favor, inténtelo de nuevo', en: 'Old password incorrect! Please try again' }[language]}</p>
                            <ButtonBase className="new-farmly-button" onClick={() => activateEditPasswordModal()}>{{ pt: 'Tentar novamente', es: 'Inténtelo nuevamente', en: 'Try Again' }[language]}</ButtonBase>
                        </div>
                    )
                } else {
                    setModalMessage(
                        <p>{{ pt: 'Problemas na atualização dos dados! Voltando para a versão antiga', es: 'Problemas de actualización de datos. Volviendo a la versión antigua', en: 'Data update problems! Switching back to the old version' }[language]}</p>
                    )
                }
            })
    }

    const submitNewEmail = (data) => {
        const userId = window.location.pathname.split('/')[3];
        axios.patch(`${server}/updateAllUserEmail`, {
            ...data,
            isProd: userContext.user.isProd, //tem que ver se o admin está como produtor ou não em produção
            id: userContext.isAdmin ? userId : userContext.user.id,
            uid: userDataOrigin.bubble_unique_id
        })
            .then(res => {
                axios.post(`${server}/verify`, {
                    jwt: userContext.user.jwt,
                })
                    .then(ver => {
                        setRefreshPage(true);
                        setModalMessage(
                            <p>{{ pt: 'Dados atualizados com sucesso! Verifique o novo email para utilizar a plataforma corretamente', es: '¡Datos actualizados con éxito! Compruebe su nuevo correo electrónico para utilizar la plataforma correctamente', en: 'Data updated successfully! Check your new email to use the platform correctly' }[language]}</p>
                        )
                        setRefreshPage(true);
                    })
                    .catch(err => {
                        console.log(err.response.data);
                    })
            })
            .catch(err => {
                console.log(err.response.data);
                setModalMessage(
                    <p>{{ pt: 'Problemas na atualização dos dados! Voltando para a versão antiga', es: 'Problemas de actualización de datos. Volviendo a la versión antigua', en: 'Data update problems! Switching back to the old version' }[language]}</p>
                )
            })
    }

    const submitSample = (coffeeData) => {
        if (Object.values(coffeeData).some(info => info === null)) {
            setModalMessage(
                <p>
                    {{ pt: 'Coloque todos os dados necessários para enviar a amostra!', es: 'Introduzca todos los datos necesarios para enviar la muestra.', en: 'Enter all the necessary data to send the sample!' }[language]}
                </p>
            );
            setShowModal(true);
        } else {
            setCloseModal(true);
            setModalMessage(
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        margin: "auto",
                    }}
                >
                    <p>{{ pt: 'Cadastrando Café...', es: 'Registrando café...', en: 'Registering Coffee...' }[language]}</p>
                    <img
                        src={loadAnim}
                        alt="loading animation"
                        style={{
                            display: "inline",
                            width: "50px",
                            height: "50px",
                            alignSelf: "center",
                        }}
                    />
                </div>
            );
            setShowModal(true);
            axios.post(`${server}/addCoffee`, { ...coffeeData,  producerId: userDataOrigin.id, isBr: isBr() })
                .then((res) => {
                    setModalMessage(
                        <div className="new-profile user-send-sample-confirmation">
                            <h3>{{ pt: 'Aguardamos a sua amostra!', es: '¡Esperamos recibir su muestra!', en: 'We are waiting for your sample!' }[language]}</h3>
                            {/* <p>{{ pt: 'Esperamos 2kg de sua amostra!', es: '¡Esperamos 2kg de su muestra!', en:'We expect 2kg of your sample!' }[language]}</p> */}
                            <div className="user-send-sample-confirmation-data">
                                <p>{{ pt: 'O código do seu café é:', es: 'El código de su café es:', en: 'The code for your coffee is:' }[language]}</p>
                                <h4>{res.data[0].farmCode}</h4>
                            </div>
                            <div className="user-send-sample-confirmation-data">
                                <p>{{ pt: 'Você registrou o envio para:', es: 'Ha registrado el envío a:', en: 'You registered the shipment to:' }[language]}</p>
                                <h4>{coffeeData.section === 100
                                    ? 'Cabral: (33)99985-2990 Rua Dona Bininha, nº 151 - Bairro Esplanada CEP: 35334-000 São Sebastião do Anta - MG'
                                    :
                                    // coffeeData.section === '200'
                                    //     ? 'Jack: (35) 99925-4566 Rua José Luis Pressato, nº25 - Bairro Urupês CEP: 37063-110 Varginha - MG'
                                        
                                        coffeeData.section === 300
                                            ? 'Salomão: (33) 3331-1000 Rua Oliveira, nº 100 - Bairro Pinheiro CEP: 36902-102  Manhuaçu - MG'
                                            : 'Luz Nelly Umaña: Calle 14 # 19-23 B / Pueblo Nuevo, Trujillo, Valle Cedula, 31785293'
                                }</h4>
                            </div>
                            <div className="user-send-sample-confirmation-data">
                                <p>{{ pt: 'O rótulo é:', es: 'La etiqueta es:', en: 'The label is:' }[language]}</p>
                                <h4>FARMly - {res.data[0].farmCode}</h4>
                            </div>
                            <div className="user-send-sample-confirmation-data">
                                <p>{{ pt: 'Lembre-se de rotular a embalagem com "FARMly - Código do Café"!', es: 'Recuerde etiquetar el paquete con "FARMly - Código de café".', en: 'Remember to label the package with "FARMly - Coffee Code"!' }[language]}</p>
                                <p>{{ pt: 'Rotule corretamente para que possamos identificá-lo!', es: 'Etiquétalo correctamente para que podamos identificarlo.', en: 'Label it correctly so we can identify it!' }[language]}</p>
                            </div>
                            {/* {{pt:'',es:''}[language]} */}
                        </div>
                    )
                    setRefreshPage(true);
                })
                .catch((err) => {
                    console.log(err)
                    setModalMessage(
                        <p>{{ pt: 'Erro no cadastro! Tente novamente mais tarde', es: 'Error de registro. Por favor, inténtelo más tarde', en: 'Registration error! Please try again later' }[language]}</p>
                    )
                })
                .finally(setCloseModal(false))
        }
    }


    const submitUserImg = async (img, role, refresh = true) => {
        // console.log("manda imagem!", img);
        if (img && img.length > 0) {
            // console.log("da para enviar!");
            setCloseModal(true);
            setModalMessage(
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        margin: "auto",
                    }}
                >
                    <p>{{ pt: 'Salvando imagem...', es: 'Guardando la imagen...', en: 'Saving image...' }[language]}</p>
                    <img
                        src={loadAnim}
                        alt="loading animation"
                        style={{
                            display: "inline",
                            width: "50px",
                            height: "50px",
                            alignSelf: "center",
                        }}
                    />
                </div>
            );

            setShowModal(true);
            const trapdoor = crypto.randomBytes(6).toString("HEX");
            const fileName = `${trapdoor}_${img[0].name}`;
            try {
                const result = await storage
                    .ref(`image/${fileName}`)
                    .put(img[0]);
                // console.log("upload completo!", result);
                try {
                    const img_url = await storage
                        .ref("image")
                        .child(`${fileName}`)
                        .getDownloadURL();
                    // console.log(img_url);
                    try {
                        setModalMessage(
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    margin: "auto",
                                }}
                            >
                                <p>{{ pt: 'Atualizando dados...', es: 'Actualizando los datos...', en: 'Updating data...' }[language]}</p>
                                <img
                                    src={loadAnim}
                                    alt="loading animation"
                                    style={{
                                        display: "inline",
                                        width: "50px",
                                        height: "50px",
                                        alignSelf: "center",
                                    }}
                                />
                            </div>
                        );
                        const addResult = await addImg(
                            { main_id: userDataOrigin.user_id },
                            { img_url: img_url, file_name: fileName },
                            role
                        );
                        // console.log("imagem salva com sucesso!", addResult);
                        // console.log(
                        //     "hora de pegar os dados do usuário outra vez!"
                        // );
                        if (refresh) {
                            setModalMessage(
                                <p>
                                    {{ pt: 'Imagem salva com sucesso!', es: '¡Imagen guardada con éxito!', en: 'Image saved successfully!' }[language]}
                                </p>
                            );
                            // refreshModalAction(refresh)
                            setRefreshPage(true);
                        }
                    } catch (error) {
                        console.log("error in saving data", error);
                        storage.ref("image").child(`${fileName}`).delete();
                    }
                } catch (error) {
                    console.log("error in fetching data", error);
                    storage.ref("image").child(`${fileName}`).delete();
                }
            } catch (error) {
                console.log("upload error", error);
            } finally {
                setCloseModal(false);
            }
        }
    };

    const deleteUserImg = async (img, refresh = true) => {
        setCloseModal(true);
        setModalMessage(
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "auto",
                }}
            >
                <p>Deletando imagem...</p>
                <img
                    src={loadAnim}
                    alt="loading animation"
                    style={{
                        display: "inline",
                        width: "50px",
                        height: "50px",
                        alignSelf: "center",
                    }}
                />
            </div>
        );
        setShowModal(true);
        storage
            .ref("image")
            .child(img.file_name)
            .delete()
            .then((deleteResult) => {
                setModalMessage(
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            margin: "auto",
                        }}
                    >
                        <p>{{ pt: 'Deletando imagem...', es: 'Eliminando la imagen...', en: 'Deleting image...' }[language]}</p>
                        <img
                            src={loadAnim}
                            alt="loading animation"
                            style={{
                                display: "inline",
                                width: "50px",
                                height: "50px",
                                alignSelf: "center",
                            }}
                        />
                    </div>
                );
                removeImg(img.img_id)
                    .then((dbDeleteResult) => {
                        if (refresh) {
                            setModalMessage(
                                <p>{{ pt: 'Pronto!', es: '¡Listo para salir!', en: 'Done!' }[language]}</p>
                            );
                            setRefreshPage(true);
                        }
                    })
                    .catch((err) => {
                        console.log(
                            "error in updating data",
                            err
                        );
                    });
            })
            .catch((err) => {
                storage
                .ref("producers")
                .child(img.file_name)
                .delete()
                .then((deleteResult) => {
                    setModalMessage(
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                margin: "auto",
                            }}
                        >
                            <p>{{ pt: 'Deletando imagem...', es: 'Eliminando la imagen...', en: 'Deleting image...' }[language]}</p>
                            <img
                                src={loadAnim}
                                alt="loading animation"
                                style={{
                                    display: "inline",
                                    width: "50px",
                                    height: "50px",
                                    alignSelf: "center",
                                }}
                            />
                        </div>
                    );
                    removeImg(img.img_id)
                        .then((dbDeleteResult) => {
                            if (refresh) {
                                setModalMessage(
                                    <p>{{ pt: 'Pronto!', es: '¡Listo para salir!', en: 'Done!' }[language]}</p>
                                );
                                setRefreshPage(true);
                            }
                        })
                        .catch((err) => {
                            console.log(
                                "error in updating data",
                                err
                            );
                        });
                }).catch((err) => {
                    console.log(
                        "error in deleting data", err
                    );
                })
            })
            .finally(() => {
                setCloseModal(false);
            });
    };

    const updateUserImg = async (oldImg, newImg) => {
        await deleteUserImg(oldImg, false);
        await submitUserImg(newImg, oldImg.role);
    };

    const promptUpdateUserImg = (oldImg, newImg) => {
        setCloseModal(true);
        setModalMessage(
            <div className="update-message-modal">
                {{ pt: 'Tem certeza que quer atualizar a imagem? Sua imagem antiga será apagada!', es: '¿Está seguro de que quiere actualizar la imagen? Tu antigua imagen será eliminada.', en: 'Are you sure you want to update the image? Your old image will be deleted!' }[language]}
                <div className="update-message-modal-button-holder">
                    <button className="new-farmly-button f-button-green" onClick={(e) => updateUserImg(oldImg, newImg)}>
                        {{ pt: 'Sim', es: 'Si', en: 'Yes' }[language]}
                    </button>
                    <button className="new-farmly-button f-button-red" onClick={(e) => { setShowModal(false); document.getElementById('myAvatar').value = null; }}>
                        {{ pt: 'Não', es: 'No', en: 'No' }[language]}
                    </button>
                </div>
            </div>
        );
        setShowModal(true);
    };

    console.log("olha o contexto", userContext)

    return (
        <Main
            titleHeader={userDataOrigin.isProd === true ?
                { pt: "Perfil", en: "Profile", es: "Perfil" }[language]
                :
                { pt: "Torrefador", en: "Roaster", es: "Tostador" }[language]}
        >
            <div className="new-profile user-header">
                <UserAvatar
                    isOwner={isOwner}
                    isAdmin={false}
                    submitUserImg={submitUserImg}
                    imgs={userDataOrigin.img}
                    promptUpdateUserImg={promptUpdateUserImg}
                />
                <UserTitle
                    isOwner={isOwner}
                    windowSize={windowSize}
                    user={userDataOrigin}
                    editProfile={activateEditProfileModal}
                />
            </div>
            {isOwner && userDataOrigin.isProd === true ?
                <UserSendSample
                    submitSample={submitSample}
                    showUserSendSampleContent={showUserSendSampleContent}
                    setShowUserSendSampleContent={setShowUserSendSampleContent}
                    user={userDataOrigin}
                />
                :
                (isOwner && userDataOrigin.isProd === false
                    ?
                    <UserRequestSample /> : null
                )}

            {(isOwner && userDataOrigin.isProd === true) && <UserFarmlyClub
                windowSize={windowSize}
                inviteCode={userDataOrigin.inviteCode}
            />}

            {userDataOrigin.isProd === true && <UserFarm producer={userDataOrigin} />}

            <UserCoffees
                coffees={coffees}
                user={userDataOrigin}
                setShowUserSendSampleContent={setShowUserSendSampleContent}
                windowSize={windowSize}
                fetchError={fetchError}
            />

            <UserImages
                imgs={userDataOrigin.img ? userDataOrigin.img.filter(img => img.role !== "profile") : []}
                isOwner={isOwner}
                isAdmin={false}
                user={userDataOrigin}
                submitUserImg={submitUserImg}
                deleteUserImg={deleteUserImg}
                userInfo={userContext}
            />

            <UserLocation
                isOwner={isOwner}
                windowSize={windowSize}
                userDataOrigin={userDataOrigin}
                setUserDataOrigin={setUserDataOrigin}
                submitUserLocation={submitUserLocation}
            />
            {isOwner && (
                <div className="new-profile profile-actions">
                    <ButtonBase
                        className="new-farmly-button f-button-white"
                        onClick={activateEditProfileModal}
                    >
                        <SettingsIcon />
                        &nbsp;&nbsp;{{ pt: 'Editar Perfil', es: 'Editar el Perfil', en: 'Edit Profile' }[language]}
                    </ButtonBase>
                    <ButtonBase
                        className="new-farmly-button f-button-o-bt-red"
                        onClick={activateEditEmailModal}
                    >
                        <EmailIcon />
                        &nbsp;&nbsp;{{ pt: 'Alterar Email', es: 'Cambiar Email', en: 'Change email' }[language]}
                    </ButtonBase>
                    <ButtonBase
                        className="new-farmly-button f-button-o-bt-red"
                        onClick={activateEditPasswordModal}
                    >
                        <VpnKeyIcon />
                        &nbsp;&nbsp;{{ pt: 'Alterar Senha', es: 'Cambiar contraseña', en: 'Change password' }[language]}
                    </ButtonBase>
                </div>
            )}
            {showModal && (
                <FarmlyModal
                    id="page-modal"
                    closeModal={() => refreshModalAction(refreshPage)}
                    noClose={closeModal}
                >
                    {modalMessage}
                </FarmlyModal>
            )}
        </Main>
    );
};

export default NewProfileTemplate;