import React, { Fragment, useState } from "react";
import Slider from "react-slick";
import { isCo, farmlyTexts, isPt, server } from "../../../common";

import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ButtonBase from '@material-ui/core/ButtonBase';

import "../../../styles/profile/components/UserImages.css";

const loadAnim = require('../../../assets/load.svg');

const CarouselImage = (props) => {
	const [loaded, setLoaded] = useState(false);

	const handleLoad = () => {
		setLoaded(true)
	}

	return (
		<Fragment>
			{
				loaded
					?
					null
					:
					<img src={loadAnim} alt="loading animation" style={{ margin: 'auto' }} />
			}
			<img className="user-carousel-img" src={props.image.img_url} alt={props.image.role} onLoad={handleLoad} />
		</Fragment>
	)
}

const UserImages = (props) => {
	let activeSlide;

	const {
		imgs,
		isOwner,
		user,
		isAdmin,
		userInfo,
		submitUserImg,
		deleteUserImg,
	} = props;
	const settings = {
		dots: true,
		arrows: false,
		adaptiveHeight: true,
		infinite: false,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		initialSlide: imgs ? imgs.length - 1 : 0,
		autoplay: false,
		className: "new-profile profile-caroussel",
		beforeChange: (current, next) => activeSlide = next
	};	
	

	const uploadFile = () => {
		const inputFile = document.getElementById('myFile');
		inputFile.click();
	}

	let currentSlide = imgs ? imgs.length - 1 : 0;
	const deleteImg = () => {
		deleteUserImg(imgs[activeSlide])
	}

	if (!isOwner && imgs.length === 0) {
		return null
	}

	const renderIframes = () => {
        return (
            <div style={{display: "flex", flexDirection:"column", alignItems: "center", width: "100%", height: "100%"}}>
			{userInfo.user.id == 0 ? <span>Samples Producer</span> : <></>}
            {userInfo.user.id == 0 ? <iframe src={`https://farmly-offerlist.bubbleapps.io/version-test/samples_producer_l?fid=${userInfo.user.id}&debug_mode=true`} className="user-iframe"></iframe> :<></>}
			{userInfo.user.id == 0 ? <span>Samples Roaster</span> : <></>}
			{userInfo.user.id == 0 ? <iframe src={`https://farmly-offerlist.bubbleapps.io/version-test/samples_roaster?fid=${userInfo.user.id}&debug_mode=true`} className="user-iframe"></iframe> :<></>}
            </div>
        )
    }

	// <iframe width="560" height="315" src="https://www.youtube.com/embed/rqpriUFsMQQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
	return (
	
		<div className='new-profile user-images'>
			<br></br>
			{/* {renderIframes()} */}
			<div className="new-profile profile-images">
				{
				imgs && imgs.length > 0
						?
						<Slider {...settings} afterChange={(e) => { currentSlide = e }}>
							{
								imgs && imgs.map((image, indx) => {
									return <CarouselImage image={image} key={indx} />
								})
							}
						</Slider>

						:
						<div className='user-images-actions'>
							{/* {renderIframes()} */}
							<ButtonBase className="new-farmly-button f-button-green" onClick={uploadFile}>
								{user.isProd === true ?
									(
										isPt() ? 'Adicionar fotos da sua fazenda'
											:
											(isCo() ? "Agrega fotos de tu granja" : "Add photos of your farm")
									)
									: (
										isPt() ? 'Adicionar fotos da sua torrefadora'
											:
											(isCo() ? "Agrega fotos de tu tostador" : "Add photos of your roastery")
									)
								}
							</ButtonBase>
							</div>
				}
			</div>
			<input type="file" id="myFile" accept="image/*" name="filename" hidden onChange={(e) => { submitUserImg(e.target.files, 'farm') }}></input>
			{((imgs && imgs.length > 0 && isOwner) || (imgs && imgs.length > 0 && isAdmin)) &&
				<div className='user-images-actions'>
					<ButtonBase className="new-farmly-button f-button-green" onClick={uploadFile}>{isPt() ? "+ Adicionar" : (isCo()? "+ Agregar" : "+ Add")}</ButtonBase>
					<ButtonBase className="new-farmly-button f-button-red" onClick={deleteImg}> <DeleteForeverIcon /> &nbsp;&nbsp; {isPt() ? "Deletar" : (isCo() ? "Eliminar" : "Delete")}</ButtonBase>
				</div>
			}
		</div>
	);
};

export default UserImages;
