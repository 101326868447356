import React, { useState, useEffect, useContext } from "react";
import MainCupping from '../templates/MainCupping'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faTimes, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import CoffeeCuppingCard from './CoffeeCuppingCard'
import CuppingMasterCard from './CuppingMasterCard'
import UserContext from '../../context/user/UserContext'
import axios from 'axios'
import { server, isPt } from '../../common'
import { useHistory } from 'react-router-dom'
import './CuppingSelect.css'
import Dropzone from 'react-dropzone'
import { storage } from '../../firebase'
import crypto from 'crypto'
import ReactGA from 'react-ga'

const CuppingSelect = (props) => {
    const [createCupping, setCreateCupping] = useState(false)
    const [createInstantCoffee, setCreateInstantCoffee] = useState(false)
    const [cuppingCoffees, setCuppingCoffees] = useState([])
    const [cuppingMasters, setCuppingMasters] = useState({})
    const [cuppingMasterData, setCuppingMasterData] = useState({})
    const [newCoffeeData, setNewCoffeeData] = useState({})
    const [coffeesVector, setCoffeesVector] = useState([])
    const [coffeesVectorData, setCoffeesVectorData] = useState([])
    const [selectColor, setSelectColor] = useState(0)
    const [selectColorCoffee, setSelectColorCoffee] = useState(0)
    const [secondPartMobile, setSecondPartMobile] = useState(false)
    const [nextId, setNextId] = useState([])
    const userContext = useContext(UserContext)
    const history = useHistory()
    var date = new Date()
    
    useEffect(() => {
        if(!userContext.user) {
            axios.post(`${server}/getAllCuppingCoffees`, {user_id: props.history.location.state.id})
            .then(coffees => {
                setCuppingCoffees(coffees.data)
                let aux = 0
                coffees.data.forEach(item => {
                    if(item.coffee_id > aux) {
                        aux = item.coffee_id
                        setNextId(aux)
                    } 
                })
            })
        }
        else{
           axios.post(`${server}/getAllCuppingCoffees`, {user_id: userContext.user.id})
            .then(coffees => {
                setCuppingCoffees(coffees.data)
                let aux = 0
                coffees.data.forEach(item => {
                    if(item.coffee_id > aux) {
                        aux = item.coffee_id
                        setNextId(aux)
                    } 
                })
            }) 
        }
        
    }, [createInstantCoffee])

    useEffect(() => {
        if(!userContext.user){
            axios.post(`${server}/getAllCuppingMasters`, {user_id: props.history.location.state.id})
            .then(cuppings => {
                setCuppingMasters(cuppings.data)
            })
            axios.post(`${server}/signin`, {
                email: props.history.location.state.email,
                password: props.history.location.state.password
            })
                .then(payload => {
                    userContext.userLogin({
                        isProd: payload.data.isProd,
                        jwt: payload.data.jwt,
                        id: payload.data.id,
                        main_id: payload.data.main_id
                    })    
                })
                .catch(err => {
                    console.log(err);
                })
        }
        else{
            axios.post(`${server}/getAllCuppingMasters`, {user_id: userContext.user.id})
            .then(cuppings => {
                setCuppingMasters(cuppings.data)
                // let aux = 0
                // cuppings.data.forEach(item => {
                //     if(item.cupping_id > aux) { Se interessar saber o ID do prox cupping_master no front
                //         aux = item.coffee_id
                //         setNextId(aux)
                //     } 
                // })
            })
        }
    }, [])

    const renderCuppingCards = () => 
    cuppingMasters.map(cupping => {
        let coffeesVectorImg = []
        let coffeesVector = []
        cuppingCoffees.forEach(coffee => {
            cupping.img.split(',').forEach(coffeeId => {
                if(coffee.coffee_id == coffeeId) {
                    if(coffee.coffee_id == cupping.img.split(',')[0] || coffee.coffee_id == cupping.img.split(',')[1] || coffee.coffee_id == cupping.img.split(',')[2]) coffeesVectorImg.push(coffee)
                coffeesVector.push(coffee)
                }
            })
        })
        return (<CuppingMasterCard cuppingData={cupping} coffeesVectorImg={coffeesVectorImg} coffeesVector={coffeesVector}/>)
    })

    const renderCoffeeCards = () => 
    cuppingCoffees.map(coffee => {
        return (<CoffeeCuppingCard coffeeData={coffee} coffeesVector={coffeesVector} setCoffeesVector={setCoffeesVector} coffeesVectorData={coffeesVectorData} setCoffeesVectorData={setCoffeesVectorData}/>)
    })

    const renderImages = () =>
    coffeesVectorData.map((coffee, index) => {
        return (<img className="cupping-image-unique" src={coffee.img} style={{width: `${100/coffeesVectorData.length}%`, borderTopLeftRadius: index == 0 ? `20px` :`0px`, borderTopRightRadius: index == coffeesVectorData.length-1 ? `20px`: `0px` }}></img>)
    })

    const renderImages3 = () => {
        return ( 
            <>
                <img className="cupping-image-unique" src={coffeesVectorData[0].img} style={{width: `${100/3}%`, borderTopLeftRadius: `20px`, borderTopRightRadius: `0px` }}></img>
                <img className="cupping-image-unique" src={coffeesVectorData[1].img} style={{width: `${100/3}%`, borderTopLeftRadius: `0px`, borderTopRightRadius: '0px'}}></img>
                <img className="cupping-image-unique" src={coffeesVectorData[2].img} style={{width: `${100/3}%`, borderTopLeftRadius: '0px', borderTopRightRadius:`20px`}}></img>
            </>
        )}

    const addCoffee = () => {
        axios.post(`${server}/createCuppingCoffee`, {
            user_id: userContext.user.id,
            name: newCoffeeData.name,
            description: newCoffeeData.description,
            coffee_color: selectColorCoffee,
            img: newCoffeeData.img
        })
        .then(_ => {
            setCreateInstantCoffee(false)
            setNewCoffeeData({})
        })
        .catch(err => console.log(err))
    }

    const fileUpload = async (acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            const trapdoor = crypto.randomBytes(6).toString('HEX')
            const fileName = `${trapdoor}_${acceptedFiles[0].name}`
            await storage.ref(`producers/${fileName}`).put(acceptedFiles[0])
            await storage.ref('producers').child(`${fileName}`).getDownloadURL()
                .then((url) => { setNewCoffeeData({...newCoffeeData, img: url}) })
                .catch(e => console.log(e))

        }
    }

    const onDropRejected = () => {
        // setModalMessage("Imagem não pode ser maior do que 2 megabytes.")
        // toggleModal()
    }

    const startCupping = () => {
        axios.post(`${server}/createCuppingMaster`, {
            user_id: userContext.user.id,
            description: cuppingMasterData.description,
            cupping_color: selectColor,
            date: `${date.getDate()}/${(date.getMonth()+1)}/${date.getFullYear()}`,
            img: coffeesVector.join(',')
        })
        .then(res => {
            coffeesVectorData.forEach((coffee) => {
                let newCupping = {
                    coffee_id: coffee.coffee_id,
                    user_id: coffee.user_id,
                    aroma: '6',
                    flavor: '6',
                    completion: '6',
                    acidity: '6',
                    body: '6',
                    balance: '6',
                    defects: '0',
                    big_defect: '0',
                    overall: '0',
                    score: 76,
                    isFarmly: false,
                    cupping_id: res.data[0]
                }
                axios.post(`${server}/createCupping`, newCupping)
            })
            history.push({
                pathname: '/cupping',
                state: { cuppingCoffees: coffeesVectorData, isFarmly: false, cupping_id: res.data[0] }
            })
        })
        .catch(err => console.log(err))        
    }

    //REPORTS ANALYTICS
    useEffect(() => {
        ReactGA.event({
            category: 'Cupping Select',
            action: `A user visited the Cupping Select Page`
        })
    }, [])

    const clickAddCuppingModal = () =>{
        ReactGA.event({
            category: 'Cupping Select',
            action: 'User clicked on Add Cupping button to open the modal'
        })
    }
    const clickAddCuppingCard = () =>{
        ReactGA.event({
            category: 'Cupping Select',
            action: 'User clicked on Add Cupping card to open the modal'
        })
    }
    const clickRegisterCupping = () =>{
        ReactGA.event({
            category: 'Cupping Select',
            action: 'User clicked on Register Cupping button'
        })
    }

    return(
        <>
        {createInstantCoffee &&
                <div className="black-background-modal-cupping" style={{zIndex:"13"}}>
                    <div className="create-coffee-modal-container-cupping">
                        <div className="coffee-img-and-color-modal-cupping">
                        <div style={{flexDirection: 'column'}}>
                                    {newCoffeeData.img ? 
                                    <img className="coffee-img-cupping-card-preview" src={newCoffeeData.img}></img>
                                    :
                                    <Dropzone onDrop={fileUpload} onDropRejected={onDropRejected} maxSize={2097152}
                                    accept="image/*" multiple>
                                    {({ getRootProps, getInputProps }) => (
                                        <section>
                                            <div className="add-picture-modal-cupping"{...getRootProps()}>
                                                <input {...getInputProps()} accept="image/*" />
                                                <p>{isPt() ? '+ Clique para adicionar uma imagem para o café' : '+ Click to add a picture of the coffee'}</p>
                                            </div>
                                        </section>
                                    )}
                                </Dropzone>}
                                <div className="select-color-modal-cupping" style={selectColorCoffee == 1 ? {backgroundColor: '#b73952'} : selectColorCoffee == 2 ? {backgroundColor: '#f47a54'} : selectColorCoffee==3 ? {backgroundColor: '#bba726'} : selectColorCoffee==4 ? {backgroundColor: 'rgb(27, 98, 94)'} : selectColorCoffee == 5 ? {backgroundColor:'#6dd7f3'} : {backgroundColor:'rgb(27, 98, 94)'}}>
                                    <div className="coffee-name-modal-cupping">{newCoffeeData.name ? newCoffeeData.name : "Coffee Name"}</div>
                                    <div className="description-modal-cupping">
                                    {newCoffeeData.description ? newCoffeeData.description : (isPt() ? "Descrição do café para ajudar a identificar o tipo" : "Description of this cafe to help understand what type it is.")}
                                    </div>
                                </div>
                                <div className="coffee-id-modal-cupping">
                                    ID: {parseInt(nextId, 10) + 1}
                                </div>
                            </div>
                            <div className="colors-container-modal-cupping">
                                    <div className={selectColorCoffee==1 ? "color-red color-selected" : "color-red"} onClick={()=> setSelectColorCoffee(1)}></div>
                                    <div className={selectColorCoffee==2 ? "color-orange color-selected" : "color-orange"}  onClick={()=> setSelectColorCoffee(2)}></div>
                                    <div className={selectColorCoffee==3 ? "color-lime color-selected" : "color-lime"}  onClick={()=> setSelectColorCoffee(3)}></div>
                                    <div className={selectColorCoffee==4 ? "color-dark-green color-selected" : "color-dark-green"} onClick={()=> setSelectColorCoffee(4)}></div>
                                    <div className={selectColorCoffee==5 ? "color-blue color-selected" : "color-blue"}  onClick={()=> setSelectColorCoffee(5)}></div>
                            </div>
                        </div>
                        <div className="coffee-input-info-modal-cupping">
                            <input className="coffee-info-input-modal-cupping" value={newCoffeeData.name} setValue={value => setNewCoffeeData({...newCoffeeData, name: value})} onChange={(event) =>  setNewCoffeeData({...newCoffeeData, name: event.target.value})} placeholder={isPt() ? "Nome do Café" : "Coffee name"}></input>
                            <textarea className="coffee-info-input-modal-cupping" value={newCoffeeData.description} setValue={value => setNewCoffeeData({...newCoffeeData, description: value})} onChange={(event) =>  setNewCoffeeData({...newCoffeeData, description: event.target.value})} rows='3' placeholder={isPt() ? "Descrição" : "Description"}></textarea>
                        </div>
                    </div>
                    <div className="add-or-cancel-create-coffee-container-modal-cupping">
                        <div className="create-coffee-modal-cupping"  onClick={()=>addCoffee()}>{isPt() ? "+ Adicionar Café" : "+ Add Coffee"}</div>
                        <div className="cancel-coffee-modal-cupping" onClick={()=>{setCreateInstantCoffee(false); setNewCoffeeData({})}}>X</div>
                    </div>
                </div>
            }
            {createCupping &&
                <div className="black-background-modal-cupping">
                    <div className="create-coffee-modal-container-cupping">
                    {window.screen.width>768 ?
                    <>
                        <div className="cupping-img-and-color-modal-cupping">
                            <div style={{flexDirection: 'column'}}>
                                <div className="all-pictures-modal-cupping">
                                    {coffeesVectorData.length > 3 ? renderImages3() : renderImages()}
                                    {/* <div className="cupping-name-modal-cupping">{cuppingMasterData.description}</div> */}
                                </div>
                                <div className="date-and-info-modal-cupping" style={selectColor == 1 ? {backgroundColor: '#b73952'} : selectColor == 2 ? {backgroundColor: '#f47a54'} : selectColor==3 ? {backgroundColor: '#bba726'} : selectColor==4 ? {backgroundColor: 'rgb(27, 98, 94)'} : selectColor == 5 ? {backgroundColor:'#6dd7f3'} : {backgroundColor:'rgb(27, 98, 94)'}}>
                                    <div className="description-modal-cupping">
                                        <div className="selected-id-cupping-modal">{coffeesVector.length > 4 ? `${coffeesVector[0]},${coffeesVector[1]},${coffeesVector[2]},${coffeesVector[3]},${coffeesVector[4]}...` : coffeesVector.join(',')}</div>
                                        <div className="date-and-hour-container-cupping-modal">
                                            <div>{date.getDate()}/{date.getMonth()+1}/{date.getFullYear()}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="colors-cupping-container-modal-cupping">
                                    <div className={selectColor==1 ? "color-red color-selected" : "color-red"} onClick={()=> setSelectColor(1)}></div>
                                    <div className={selectColor==2 ? "color-orange color-selected" : "color-orange"}  onClick={()=> setSelectColor(2)}></div>
                                    <div className={selectColor==3 ? "color-lime color-selected" : "color-lime"}  onClick={()=> setSelectColor(3)}></div>
                                    <div className={selectColor==4 ? "color-dark-green color-selected" : "color-dark-green"} onClick={()=> setSelectColor(4)}></div>
                                    <div className={selectColor==5 ? "color-blue color-selected" : "color-blue"}  onClick={()=> setSelectColor(5)}></div>
                                </div>
                                <input className="cupping-info-input-modal-cupping" value={cuppingMasterData.description} setValue={value => setCuppingMasterData({...cuppingMasterData, description: value})} onChange={(event) =>  setCuppingMasterData({...cuppingMasterData, description: event.target.value})} placeholder={isPt() ? "Nome do Cupping" : "Cupping name"}></input>
                                {/* <div className="coffees-selected-container-cupping">
                                    
                                </div> */}
                                <div className="cupping-button-container-cupping-modal">
                                    <div className="create-cupping-modal-cupping" onClick={() => {startCupping(); clickRegisterCupping()}}>{isPt() ? 'Iniciar Cupping' : 'Start Cupping'}</div> {/*START CUPPING. FIX LATER*/}
                                    <div className="cancel-or-back-button-container">
                                        <FontAwesomeIcon className="cancel-cupping-modal-cupping" icon={faArrowLeft} onClick={()=>setCreateCupping(false)}/>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <div className="coffee-select-cupping-modal-cupping">
                            <div className="add-instant-coffee-card-create" onClick={()=>setCreateInstantCoffee(true)}>{isPt() ? '+ Adicionar Café' : '+ Add Coffee'}</div>
                            {cuppingCoffees.length && renderCoffeeCards()}
                            {cuppingCoffees.length == 0 && 
                            <div className="cupping-tutorial-steps-create-coffee">
                                {isPt() ?
                                <> 
                                Você não tem nenhum café!
                                <br/>
                                <span className="cupping-tutorial-steps-create-coffee-span" onClick={()=> {setCreateInstantCoffee(true)}} >Adicione seu café</span> para seguir com sua prova!
                                </> 
                                : 
                                <>
                                You don't have any coffee!
                                <br/>
                                <span className="cupping-tutorial-steps-create-coffee-span" onClick={()=> {setCreateInstantCoffee(true)}} >Add a coffee</span> to follow with your cupping!
                                </>}
                            </div>}
                            {cuppingMasters.length == 0 && 
                                <div className="cupping-tutorial-steps">
                                    {isPt() ?
                                    'Clique nos cafés para incluí-los na prova!'
                                    : 
                                    'Click on the coffees to include them in the cupping!'}
                                </div>
                                }
                        </div>
                        </>
                    :
                    <>
                        <div className="coffee-select-mobile-title">{isPt() ? "Selecione seus cafés!" : 'Select your coffees!'}</div>
                        <div className="coffee-select-cupping-modal-cupping" style={{left: cuppingCoffees.length == 0 && '10%' }}>
                        {cuppingCoffees.length && renderCoffeeCards()}
                        {cuppingCoffees.length == 0 && 
                            <div className="cupping-tutorial-steps-create-coffee-mobile">
                                {isPt() ?
                                <> 
                                Você não tem nenhum café!
                                <br/>
                                <span className="cupping-tutorial-steps-create-coffee-span" onClick={()=> {setCreateInstantCoffee(true)}} >Adicione seu café</span> para seguir com sua prova!
                                </> 
                                : 
                                <>
                                You don't have any coffee!
                                <br/>
                                <span className="cupping-tutorial-steps-create-coffee-span" onClick={()=> {setCreateInstantCoffee(true)}} >Add a coffee</span> to follow with your cupping!
                                </>}
                            </div>}
                        </div>
                        <div className="continue-or-back-button-container">
                            <div className="generate-new-coffee-cupping-modal-cupping" onClick={()=>setCreateInstantCoffee(true)}>{isPt() ? '+ Adicionar Café' : '+ Add Coffee'}</div>
                            <div>
                                <FontAwesomeIcon className="go-back-cupping-modal-cupping" icon={faTimes} onClick={()=>setCreateCupping(false)}/>
                                <FontAwesomeIcon className="continue-cupping-modal-cupping" icon ={faArrowRight} onClick={()=>setSecondPartMobile(true)}/>
                            </div>
                        </div>
                </>
                }
                    </div>
                </div>
            }
            {secondPartMobile &&
            <>
                <div className="black-background-modal-cupping">
                    <div className="create-coffee-modal-container-cupping">
                    <div className="cupping-img-and-color-modal-cupping">
                            <div style={{flexDirection: 'column'}}>
                                <div className="all-pictures-modal-cupping">
                                    {coffeesVectorData.length > 3 ? renderImages3() : renderImages()}
                                    {/* <div className="cupping-name-modal-cupping">{cuppingMasterData.description}</div> */}
                                </div>
                                <div className="date-and-info-modal-cupping" style={selectColor == 1 ? {backgroundColor: '#b73952'} : selectColor == 2 ? {backgroundColor: '#f47a54'} : selectColor==3 ? {backgroundColor: '#bba726'} : selectColor==4 ? {backgroundColor: 'rgb(27, 98, 94)'} : selectColor == 5 ? {backgroundColor:'#6dd7f3'} : {backgroundColor:'rgb(27, 98, 94)'}}>
                                    <div className="description-modal-cupping">
                                        <div className="selected-id-cupping-modal">{coffeesVector.length > 4 ? `${coffeesVector[0]},${coffeesVector[1]},${coffeesVector[2]},${coffeesVector[3]},${coffeesVector[4]}...` : coffeesVector.join(',')}</div>
                                        <div className="date-and-hour-container-cupping-modal">
                                            <div>{date.getDate()}/{date.getMonth()+1}/{date.getFullYear()}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="colors-cupping-container-modal-cupping">
                                    <div className={selectColor==1 ? "color-red color-selected" : "color-red"} onClick={()=> setSelectColor(1)}></div>
                                    <div className={selectColor==2 ? "color-orange color-selected" : "color-orange"}  onClick={()=> setSelectColor(2)}></div>
                                    <div className={selectColor==3 ? "color-lime color-selected" : "color-lime"}  onClick={()=> setSelectColor(3)}></div>
                                    <div className={selectColor==4 ? "color-dark-green color-selected" : "color-dark-green"} onClick={()=> setSelectColor(4)}></div>
                                    <div className={selectColor==5 ? "color-blue color-selected" : "color-blue"}  onClick={()=> setSelectColor(5)}></div>
                                </div>
                                <input className="cupping-info-input-modal-cupping" value={cuppingMasterData.description} setValue={value => setCuppingMasterData({...cuppingMasterData, description: value})} onChange={(event) =>  setCuppingMasterData({...cuppingMasterData, description: event.target.value})} placeholder={isPt() ? "Nome do Cupping" : "Cupping name"}></input>
                                {/* <div className="coffees-selected-container-cupping">
                                    
                                </div> */}
                                <div className="cupping-button-container-cupping-modal">
                                    <div className="create-cupping-modal-cupping" onClick={() => startCupping()}>{isPt() ? 'Iniciar Cupping' : 'Start Cupping'}</div> {/*START CUPPING. FIX LATER*/}
                                    <div className="cancel-or-back-button-container" onClick={()=>setSecondPartMobile(false)}>
                                        <FontAwesomeIcon className="cancel-cupping-modal-cupping" icon={faArrowLeft} />
                                        {/* <FontAwesomeIcon className="cancel-cupping-modal-cupping" icon={faTimes} /> */}
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </>
            }
            <MainCupping noBanner style={{overflowY: 'hidden'}}>
                <br/>
                <br/>
                <br/>
                <br/>
                <div className="cupping-title-container">
                    <label className="cupping-page-title">Cupping</label>
                    <button className="add-coffees-button-create-coffee" onClick={()=> {setCreateCupping(true); clickAddCuppingModal()}}>{isPt() ? '+ Adicionar Cupping' : '+ Add cupping'}</button>
                </div>
                <div className="new-coffees-container-create-coffee" style={{marginLeft: cuppingMasters.length == 0 && '17vw' }}>
                    {cuppingMasters.length > 0 &&
                        <>
                        <div className="add-cupping-card-create coffee-cupping-hover" onClick={()=> {setCreateCupping(true); clickAddCuppingCard()}}>
                            {isPt() ? '+ Adicionar novo Cupping' : '+ Add a new cupping'}
                        </div>
                        {renderCuppingCards()}
                        </>
                        }
                    {cuppingMasters.length == 0 && 
                    <div className="cupping-tutorial-steps">
                        {isPt() ?
                        <> 
                        Você não tem nenhum cupping ainda!
                        <br/>
                        Clique em <span className="cupping-tutorial-steps-span" onClick={()=> {setCreateCupping(true); clickAddCuppingCard()}} >Adicionar Cupping</span> para iniciar sua primeira prova!
                        </> 
                        : 
                        <>
                        You don't have any cupping yet!
                        <br/>
                        Click <span className="cupping-tutorial-steps-span" onClick={()=> {setCreateCupping(true); clickAddCuppingCard()}} >Add Cupping</span> to start!
                        </>}
                    </div>
                    }
                </div>

            </MainCupping>
        </>
    )
}
export default CuppingSelect
