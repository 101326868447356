// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import  firebase from 'firebase/app';

// Add the Firebase services that you want to use
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

var firebaseConfig = {
    apiKey: "AIzaSyDetiOOYzLE7Vtrzj9edJFkNRP0TKd4hc0",
    authDomain: "farmly-web.firebaseapp.com",
    databaseURL: "https://farmly-web.firebaseio.com",
    projectId: "farmly-web",
    storageBucket: "farmly-web.appspot.com",
    messagingSenderId: "196623404328",
    appId: "1:196623404328:web:40131aa594fc0c5922d41d",
    measurementId: "G-JQ8BWP8MEB"
  };

  // Initialize Firebase
firebase.initializeApp(firebaseConfig);
  // firebase.analytics();
const storage = firebase.storage()

export {
    storage, firebase as default
}