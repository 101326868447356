import React, { Fragment, useState, useEffect } from "react";
import SliderCupping from "react-slick";
import { server } from '../../common'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import CuppingIndvResults from './CuppingIndvResults.jsx'
import './Cupping.css'
import FloatingButton from '../templates/FloatingButton'
import MainCupping from '../templates/MainCupping'
import NavCupping from '../templates/NavCupping'
import CuppingIndvDesktopResults from "./CuppingIndvDesktopResults";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faReply, faShare, faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons'
import { RotateRight } from "@material-ui/icons";
import ReactGA from 'react-ga'


const roletaCompleta = require('../../assets/roleta-completa.svg')

const CuppingResults = (props) => {

    const coffeesCupping = props.location.state.cuppingCoffees
    const cupping_id = props.location.state.cupping_id
    const [sensoryNote, setSensoryNote] = useState()
    const [sensoryList, setSensoryList] = useState()
    const [currentCoffee, setCurrentCoffee] = useState(0)
    const [notesModal, setNotesModal] = useState(false)
    const [cuppingDone, setCuppingDone] = useState(false)
    const [checkCuppings, setCheckCuppings] = useState(false)
    const [fillAllSpaces, setFillAllSpaces] = useState(false)
    const [goToResults, setGoToResults] = useState({status: false, coffeeId: null})
    const [auxSensoryList, setAuxSensoryList] = useState(false)
    const history = useHistory()

    const renderCuppingIndvs = () =>
        coffeesCupping.map((coffee, index) => {
            return <CuppingIndvResults coffee={coffee} auxSensoryList={auxSensoryList} cupping_id={cupping_id} goToResults={goToResults}/>
        })

    const renderCuppingIndvsDesktop1 = () =>
        coffeesCupping.map((coffee, index) => {
            return <CuppingIndvDesktopResults coffee={coffee} auxSensoryList={auxSensoryList} cupping_id={cupping_id} goToResults={goToResults}/>
        })

    const setNotesModalFunction = () => {
        axios.post(`${server}/getCuppingCoffee`, { user_id: coffeesCupping[currentCoffee].user_id, coffee_id: coffeesCupping[currentCoffee].coffee_id})
            .then(res => {
                if (res.data.sensoryNotes !== null && res.data.sensoryNotes !== "") setSensoryList(res.data.sensoryNotes.split(','))
                else setSensoryList([])
                setNotesModal(true)
            })
    }

    const addToSensoryList = async () => {
        let aux = [...sensoryList]
        aux.push(sensoryNote)
        await setSensoryList(aux)
    }

    const addSensoryNote = () => {
        axios.post(`${server}/getCuppingCoffee`, { user_id: coffeesCupping[currentCoffee].user_id, coffee_id: coffeesCupping[currentCoffee].coffee_id})
            .then(async res => {
                const reqBody = {
                    ...res.data,
                    sensoryNotes: sensoryList.join(',')
                }
                try {
                    await axios.patch(`${server}/updateCuppingCoffee`, reqBody)
                    setSensoryNote([null])
                    setNotesModal(false)
                } catch (err) {
                    console.log(err)
                } finally {
                    setAuxSensoryList(!auxSensoryList)
                }
            })
        // setAuxSensoryList(!auxSensoryList)
    }

    const renderSensoryCard = () =>
        sensoryList.map((note) => {
            let random = Math.floor(Math.random() * 3)
            return (
                <Fragment>
                    <div className={(random == 0) ? 'sensory-red' : ((random == 1 ? 'sensory-lime' : 'sensory-orange'))}>{note}</div>
                &nbsp;
                    <br />
                </Fragment>
            )
        })

    const info = {
        dots: false,
        infinite: true,
        draggable: true,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
    };
    const settingsCupping = {
        arrows: false,
        dots: true,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        focusOnSelect: true,
        pauseOnHover: true,
        // beforeChange: (current, next) => this.setState({ activeSlide: next }),
        afterChange: current => {
            // scrollDot(current);
            let dot_width = document.getElementsByClassName('slick-active')[document.getElementsByClassName('slick-active').length-1].offsetWidth;
            document.getElementsByClassName('slick-dots')[0].scroll({left: (current)*dot_width, top: 0, behavior: 'smooth'})
            setCurrentCoffee(current)
        },
        customPaging: function (i) {
            return (
                <a className="each-dot">
                    <div>Café:{coffeesCupping[i].coffee_id}</div>
                </a>
            );
        },

    };

    const settingsCuppingDesktop = {
        dots: true,
        infinite: false,
        draggable: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        focusOnSelect: true,
        pauseOnHover: true,
        arrows: true,
        dotsClass: 'slick-dots-desktop',
        // beforeChange: (current, next) => this.setState({ activeSlide: next }),
        afterChange: current => {
            // scrollDot(current);
            setCurrentCoffee(current)
        },
        customPaging: function (i) {
            return (
                <a className="each-dot-desktop" style={{ margin: '2px' }}>
                    Café:{coffeesCupping[i].coffee_id}
                </a>
            );
        },

    };

    var current_rotation = 0;

    const rotateRightWheel = () =>{
        ReactGA.event({
            category: 'Cupping Results',
            action: 'User clicked to rotate to right the flavor wheel'
        })
        current_rotation += 30;
	    document.getElementById("rotate-wheel").style.transform = 'rotate(' + current_rotation + 'deg)';
    }

    const rotateLeftWheel = () =>{
        ReactGA.event({
            category: 'Cupping Results',
            action: 'User clicked to rotate to left the flavor wheel'
        })
        current_rotation -= 30;
	    document.getElementById("rotate-wheel").style.transform = 'rotate(' + current_rotation + 'deg)';
    }

    //REPORTS ANALYTICS
    useEffect(() => {
        ReactGA.event({
            category: 'Cupping Results',
            action: `A user visited the Cupping Results Page`
        })
    }, [])


    return (
        <>
            {notesModal && 
                <div className="black-background-modal-cupping">
                    <button className="notes-button" onClick={()=>{
                        addSensoryNote()
                        setNotesModal(false)}}>
                            <FontAwesomeIcon icon={faArrowLeft}/>
                    </button>
                    <FontAwesomeIcon icon={window.screen.width>500 ? faShare : faArrowUp}  className="rotate-right-wheel-button" onClick={()=>rotateRightWheel()}/>
                    <img src={roletaCompleta} id="rotate-wheel" className="roleta-completa-sensory-notes-modal"></img>
                    <FontAwesomeIcon icon={window.screen.width>500 ? faReply : faArrowDown} className="rotate-left-wheel-button" onClick = {()=>rotateLeftWheel()}/>
                    <div className="add-sensory-notes-div">
                        <div className="sensory-note-input-container">
                            <input className="input-add-sensory-notes" onChange={(event)=>setSensoryNote(event.target.value)} placeholder="sensory note you would like to add"></input>
                            <button className="notes-button-add" onClick={()=>addToSensoryList()}>+</button>
                        </div>
                        <div className='sensory-cards-main'>
                            {renderSensoryCard()}
                        </div>
                    </div>
                </div>
            }

            {window.screen.width > 768 ?
                <MainCupping noBanner style={{overflowY: 'hidden'}}>
                    <div className="cupping-holder">
                        <div style={{ display: 'flex', flexDirection: 'row', overflowY: 'hidden' }}>
                            <label style={{ fontSize: 'xxx-large', fontWeight: '600', paddingLeft: '100px' }}>Cupping</label>
                        </div>
                        <SliderCupping {...settingsCuppingDesktop}>
                            {renderCuppingIndvsDesktop1()}
                        </SliderCupping>
                    </div>
                </MainCupping>
                :
                <MainCupping noBanner>
                    <div className="indv-container" >
                        <div className="cupping-holder">
                            <SliderCupping {...settingsCupping}>
                                {renderCuppingIndvs()}
                            </SliderCupping>
                        </div>
                    </div>
                </MainCupping>
            }
        </>
    );
}

export default CuppingResults