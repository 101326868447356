import React, { useState, useEffect } from "react";
import MainCupping from '../../templates/MainCupping'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMugHot, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import { server } from '../../../common'
import './CoffeeFarmlyCuppingCard.css'
import { storage } from '../../../firebase'
import { useHistory } from 'react-router-dom'


const coffeeImg = require ('../../../assets/producer.jpg')
const coffeeCup = require ('../../../assets/coffeeCup.svg')

const CoffeeFarmlyCuppingCard = (props) => {

    const {coffeeData, category, reload, setReload, taster} = props
    const history = useHistory()
    const selectColor = (coffeeData.id%5) + 1
    const [coffeeImageUrl, setCoffeeImageUrl] = useState()

    useEffect(() => {
        if (coffeeData.imageUrl) {
            storage.ref('producers').child(coffeeData.imageUrl.split(',')[0]).getDownloadURL()
                .then(imgUrl => {
                    setCoffeeImageUrl(imgUrl)
                })
                .catch(() => { })
        }
    }, [])

    const coffeeUpdateStatus = (right) => {
        if(right) {
            axios.patch(`${server}/setSection`, {
                id: coffeeData.id,
                section: coffeeData.section + 1
            })
            .then(_ => setReload(!reload))
        }
        else {
            axios.patch(`${server}/setSection`, {
                id: coffeeData.id,
                section: coffeeData.section - 1
            })
            .then(_ => setReload(!reload))
        }
    }

    const coffeeMoveToCupping = () => {
        axios.patch(`${server}/setSection`, {
            id: coffeeData.id,
            section: taster + 2
        })
        .then(_ => setReload(!reload))
    }

    const goToProfile = () => {
        history.push(`/coffee/${coffeeData.id}`)
    }


    return(
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <div className="coffee-img-and-color-cupping-card coffee-cupping-hover" onClick={() => goToProfile()}>
                <div className="card-container-modal-cupping">
                    <div>
                        <div className="picture-modal-cupping">
                            <img className="coffee-img-cupping-card" src={coffeeImageUrl ? coffeeImageUrl : coffeeImg}></img>
                        </div>
                        <div className="select-color-cupping-card-modal-cupping" style={selectColor == 1 ? {backgroundColor: '#b73952'} : selectColor == 2 ? {backgroundColor: '#f47a54'} : selectColor==3 ? {backgroundColor: '#bba726'} : selectColor==4 ? {backgroundColor: 'rgb(27, 98, 94)'} : selectColor == 5 ? {backgroundColor:'#6dd7f3'} : {backgroundColor:'rgb(27, 98, 94)'}}>
                            <div className="coffee-name-modal-cupping">{coffeeData.producer}</div>
                            <div className="coffee-id-modal-cupping">
                                Code: {coffeeData.farmCode}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cup-coffee-select-buttons">
                {category > 1 ? <FontAwesomeIcon className="select-cup-coffee" icon={faArrowLeft} onClick={() => coffeeUpdateStatus(false)} /> : <></>}
                <FontAwesomeIcon className="select-cup-coffee" icon={faMugHot} onClick={() => coffeeMoveToCupping()} />
                {category < 6 ? <FontAwesomeIcon className="select-cup-coffee" icon={faArrowRight} onClick={() => coffeeUpdateStatus(true)} /> : <></>}
            </div>
        </div>
    )
}
export default CoffeeFarmlyCuppingCard
