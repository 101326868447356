import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 4vw;
`

export const Title = styled.h2`
    font-weight: 500;
    
`

export const TableSelectContainer = styled.div`
    display: flex;
    width: 40vw;
    justify-content: space-evenly;

`

export const TableSelect = styled.div`
    color: #1A5F5C;
    padding: 1vw;
    border-radius: 3vw;
    cursor: pointer;

    :hover {
        background-color: #1A5F5C;
        color: white;
        border-radius: 3vw;
    } 

`
export const TableSelected = styled.div`
    background-color: #1A5F5C;
    color: white;
    padding: 1vw;
    border-radius: 3vw;
    cursor: pointer;

`
