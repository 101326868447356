import React, { FunctionComponent } from 'react';
import {
    TextField
} from '@material-ui/core';
import { CartNameInputContainer } from './style';
import CartModel from 'modules/SampleCart/models/cartModel';

interface TextFieldCartNameProps {
    sampleCart: CartModel,
    setSampleCart: React.Dispatch<React.SetStateAction<any>>,
    setChangesHappen: React.Dispatch<React.SetStateAction<boolean>>
}

const TextFieldCartName: FunctionComponent<TextFieldCartNameProps> = (props) => {
    return (
        <>
            <CartNameInputContainer>
                <TextField
                    label="Nome do carrinho"
                    id="outlined-name"
                    margin="normal"
                    variant="outlined"
                    value={props.sampleCart.cart_name}
                    type="text"
                    onChange={(e) => {
                        props.setSampleCart(
                            {
                                ...props.sampleCart,
                                cart_name: e.target.value
                            }
                        ); props.setChangesHappen(true)
                    }}
                />
            </CartNameInputContainer>
        </>
    );
}
export default TextFieldCartName;