import React, { Fragment, useState, useEffect } from "react";
import MainCupping from '../templates/MainCupping'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isPt } from '../../common'
import { faMugHot } from '@fortawesome/free-solid-svg-icons'

import './CoffeeCuppingCard.css'

const coffeeImg = require ('../../assets/producer.jpg')
const CoffeeCuppingCard = (props) => {

    const {coffeeData, coffeesVector, setCoffeesVector, coffeesVectorData, setCoffeesVectorData} = props
    const selectColor = coffeeData.coffee_color
    const [isSelected, setIsSelected] = useState(false)

    const selectCoffee = () => { 
        setCoffeesVector(coffeesVector.concat(coffeeData.coffee_id))
        setCoffeesVectorData(coffeesVectorData.concat(coffeeData))
        setIsSelected(!isSelected)
    }

    const removeCoffee = () => {
        setCoffeesVector(coffeesVector.filter(item => item !== coffeeData.coffee_id))
        setCoffeesVectorData(coffeesVectorData.filter(item => item.coffee_id !== coffeeData.coffee_id))
        setIsSelected(!isSelected)
    }


    return(
        <>
            <div className="coffee-img-and-color-cupping-card coffee-cupping-hover">
                <div className="card-container-modal-cupping">
                    {isSelected && 
                        <div className="selected-coffee-mug-background" onClick={()=> removeCoffee()}>
                            <FontAwesomeIcon className="mug-black-background" icon={faMugHot}></FontAwesomeIcon>
                            <div className="ready-black-background">{isPt() ? 'Pronto!' : 'Ready!'}</div>
                        </div>}
                    <div className={isSelected && 'selected-coffee-card-cupping-modal'} onClick={()=> selectCoffee()}>
                        <div className="picture-modal-cupping">
                            <img className="coffee-img-cupping-card" src={coffeeData.img ? coffeeData.img : coffeeImg}></img>
                        </div>
                        <div className="select-color-cupping-card-modal-cupping" style={selectColor == 1 ? {backgroundColor: '#b73952'} : selectColor == 2 ? {backgroundColor: '#f47a54'} : selectColor==3 ? {backgroundColor: '#bba726'} : selectColor==4 ? {backgroundColor: 'rgb(27, 98, 94)'} : selectColor == 5 ? {backgroundColor:'#6dd7f3'} : {backgroundColor:'rgb(27, 98, 94)'}}>
                            <div className="coffee-name-modal-cupping">{coffeeData.name}</div>
                            <div className="description-modal-cupping">
                                {coffeeData.description}
                            </div>
                            <div className="coffee-id-modal-cupping">
                                ID: {coffeeData.coffee_id}
                            </div>
                        </div>
                        
                    </div>
                    
                </div>
            </div>
        </>
    )
}
export default CoffeeCuppingCard
