import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'

import Main from '../templates/Main.jsx'
import { isPt, farmlyTexts, server } from '../../common'

const VerifyEmail = props => {

    const [verified, setVerified] = useState(false)
    useEffect(() => {
            axios.post(`${server}${props.location.pathname}`)
                .then(res => {
                    setVerified(true)
                })
                .catch(err => setVerified(false))
    },[])

    return (
        <Main>
            <div style={{margin:"15% auto 85% auto"}}>
            {verified 
            ? 
            <p style={{color: 'var(--farmly-blueStone)', fontWeight: '500'}}> {isPt() ? farmlyTexts.verified_pt : farmlyTexts.verified_en}</p>
            :
            <p style={{color: 'var(--farmly-blueStone)', fontWeight: '500'}}>{ isPt() ? farmlyTexts.notVerified_pt : farmlyTexts.notVerified_en }</p>}
            </div>
        </Main>
    )
}

export default VerifyEmail