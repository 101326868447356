import React from "react";
import Main from "../../components/templates/Main";
import { ShipmentsTable } from "./styles";
import ShipmentLine from "./components/ShipmentsLine"
import useShipments from "./hooks/useShipments";
import IShippment from "./models/ShipmentModel"
import ShipmentsLegends from "./components/ShipmentsLegends";


const ManageShipments: React.FC = () => {

  const shipmentsData = useShipments()

  const renderShipments = () =>
    shipmentsData.shipments.map((shipment: IShippment) => {
      return(
        <ShipmentLine key={shipment.shipping_id} shipment={shipment} />
      )
  })

  return (
  <Main>
      <ShipmentsTable>
          <ShipmentsLegends/>
          {renderShipments()}
      </ShipmentsTable>
  </Main>
  );
};

export default ManageShipments;
