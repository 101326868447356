import styled from 'styled-components';

export const LinkContainer = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
`
export const LinkContainerTypography = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
`
export const LinkContainerSpan = styled.div`
`
export const LinkContainerExpireDate = styled.div`
    margin-bottom: 10px;
`