import React from 'react';

import '../styles/components/Badge.css';
import {language} from '../common.js'


const badgeOptions = {
    default:{
        color:"#FFFFFF",
        text:"Default",
        next:0
    },
    1:{
        color:'#DF2629',
        text:{
            en:"fruity",
            pt:"frutado",
            es:"afrutado"
        },
        next:0
    },
    2:{
        color:'#E6B414',
        text:{
            en:"sour/fermented",
            pt:"azedo/fermentado",
            es:"agrio/fermentado"
        },
        next:0
    },
    3:{
        color:'#1F9538',
        text:{
            en:"greem/vegetative",
            pt:"folhagem/vegetal",
            es:"gremio/vegetal"
        },
        next:0
    },
    4:{
        color:'#15A9BC',
        text:{
            en:"others",
            pt:"outros",
            es:"otros"
        },
        next:0
    },
    5:{
        color:'#CB4829',
        text:{
            en:"roasted",
            pt:"tostado",
            es:"tostado"
        },
        next:0
    },
    6:{
        color:'#AF1F3B',
        text:{
            en:"spices",
            pt:"especiarias",
            es:"especias"
        },
        next:0
    },
    7:{
        color:'#A97B62',
        text:{
            en:"nutty/cocoa",
            pt:"nozes/cacau",
            es:"nuez/cacao"
        },
        next:0
    },
    8:{
        color:'#E45E31',
        text:{
            en:"sweet",
            pt:"adocicado",
            es:"gremio/vegetal"
        },
        next:0
    },
    9:{
        color:'#E31A73',
        text:{
            en:"floral",
            pt:"floral",
            es:"floral"
        },
        next:0
    },
    11:{
        color:'#E04A4D',
        text:{
            en:"berry",
            pt:"baya",
            es:"baga"
        },
        next:1
    },
    12:{
        color:'#CB493F',
        text:{
            en:"dried fruit",
            pt:"frutas secas",
            es:"fruta seca"
        },
        next:1
    },
    13:{
        color:'#F56745',
        text:{
            en:"other fruits",
            pt:"outras frutas",
            es:"otras frutas"
        },
        next:1
    },
    14:{
        color:'#F9A215',
        text:{
            en:"citrus fruit",
            pt:"citrinos",
            es:"cítricos"
        },
        next:1
    },
    21:{
        color:'#E2C503',
        text:{
            en:"sour",
            pt:"azedo",
            es:"agrio"
        },
        next:2
    },
    22:{
        color:'#B19826',
        text:{
            en:"alcohol/fermented",
            pt:"álcool/fermentado",
            es:"alcohol/fermentado"
        },
        next:2
    },
    31:{
        color:'#A2B21A',
        text:{
            en:"olive oil",
            pt:"azeite de oliva",
            es:"aceite de oliva"
        },
        next:3
    },
    32:{
        color:'#6F8A2B',
        text:{
            en:"raw",
            pt:"cru",
            es:"crudo"
        },
        next:3
    },
    33:{
        color:'#34A351',
        text:{
            en:"vegetative",
            pt:"vegetal",
            es:"vegetal"
        },
        next:3
    },
    34:{
        color:'#5C9B7F',
        text:{
            en:"beany",
            pt:"feijão",
            es:"frijol"
        },
        next:3
    },
    41:{
        color:'#9CB2B8',
        text:{
            en:"papery/musty",
            pt:"papel/mofado",
            es:"papel/polvo"
        },
        next:4
    },
    42:{
        color:'#73C0CC',
        text:{
            en:"chemical",
            pt:"químico",
            es:"químico"
        },
        next:4
    },
    51:{
        color:'#CBA560',
        text:{
            en:"pipe tobacco",
            pt:"tabaco para cachimbo",
            es:"tabaco de pipa"
        },
        next:5
    },
    52:{
        color:'#E0BE7A',
        text:{
            en:"tabacco",
            pt:"tabaco",
            es:"tabaco"
        },
        next:5
    },
    53:{
        color:'#C08660',
        text:{
            en:"burnt",
            pt:"queimado",
            es:"quemado"
        },
        next:5
    },
    54:{
        color:'#DEB05A',
        text:{
            en:"cereal",
            pt:"cereais",
            es:"cerales"
        },
        next:5
    },
    61:{
        color:'#7A4551',
        text:{
            en:"pungent",
            pt:"pungente",
            es:"picante"
        },
        next:6
    },
    62:{
        color:'#B34C55',
        text:{
            en:"pepper",
            pt:"pimenta",
            es:"pimienta"
        },
        next:6
    },
    63:{
        color:'#B34C55',
        text:{
            en:"brown spice",
            pt:"especiarias marrons",
            es:"especia marrón"
        },
        next:6
    },
    71:{
        color:'#C98866',
        text:{
            en:"nutty",
            pt:"nozes",
            es:"nuez"
        },
        next:7
    },
    72:{
        color:'#BD7647',
        text:{
            en:"cocoa",
            pt:"cacao",
            es:"cacao"
        },
        next:7
    },
    81:{
        color:'#D65956',
        text:{
            en:"brown sugar",
            pt:"açúcar mascavo",
            es:"azúcar moreno"
        },
        next:8
    },
    82:{
        color:'#FA9A7D',
        text:{
            en:"vanilla",
            pt:"baunilha",
            es:"vainilla"
        },
        next:8
    },
    83:{
        color:'#F67571',
        text:{
            en:"vanillin",
            pt:"baunilha artificial",
            es:"vainilla artificial"
        },
        next:8
    },
    84:{
        color:'#EA5965',
        text:{
            en:"overall sweet",
            pt:"doce em geral",
            es:"dulce en general"
        },
        next:8
    },
    85:{
        color:'#D2525C',
        text:{
            en:"sweet aromatics",
            pt:"doces aromáticos",
            es:"dulces aromáticos"
        },
        next:8
    },
    91:{
        color:'#985D6C',
        text:{
            en:"black tea",
            pt:"chá preto",
            es:"té negro"
        },
        next:9
    },
    92:{
        color:'#E26F9C',
        text:{
            en:"floral",
            pt:"floral",
            es:"floral"
        },
        next:9
    },
    111:{
        color:'#3E0116',
        text:{
            en:"blackberry",
            pt:"amora",
            es:"mora"
        },
        next:11
    },
    112:{
        color:'#E72666',
        text:{
            en:"raspberry",
            pt:"framboesa",
            es:"frambuesa"
        },
        next:11
    },
    113:{
        color:'#6367B1',
        text:{
            en:"blueberry",
            pt:"mirtilo",
            es:"arándanos"
        },
        next:11
    },
    114:{
        color:'#F12A2F',
        text:{
            en:"strawberry",
            pt:"morango",
            es:"fresa"
        },
        next:11
    },
    121:{
        color:'#B63952',
        text:{
            en:"raisin",
            pt:"uva passa",
            es:"pasas de uva"
        },
        next:12
    },
    122:{
        color:'#A6416F',
        text:{
            en:"prune",
            pt:"ameixa seca",
            es:"ciruela pasa"
        },
        next:12
    },
    131:{
        color:'#D17C2C',
        text:{
            en:"coconut",
            pt:"côco",
            es:"coco"
        },
        next:13
    },
    132:{
        color:'#E9314D',
        text:{
            en:"cherry",
            pt:"cereja",
            es:"cereza"
        },
        next:13
    },
    133:{
        color:'#E85452',
        text:{
            en:"pomegranate",
            pt:"romã",
            es:"granada"
        },
        next:13
    },
    134:{
        color:'#F89A0D',
        text:{
            en:"pineapple",
            pt:"abacaxi",
            es:"piña"
        },
        next:13
    },
    135:{
        color:'#ADBA1B',
        text:{
            en:"grape",
            pt:"uva",
            es:"uva"
        },
        next:13
    },
    136:{
        color:'#49BA3F',
        text:{
            en:"apple",
            pt:"maçã",
            es:"manzana"
        },
        next:13
    },
    137:{
        color:'#F78A56',
        text:{
            en:"peach",
            pt:"pêssego",
            es:"melocotón"
        },
        next:13
    },
    138:{
        color:'#BAA627',
        text:{
            en:"pear",
            pt:"pêra",
            es:"pera"
        },
        next:13
    },
    141:{
        color:'#F46250',
        text:{
            en:"grapefruit",
            pt:"toranja",
            es:"pomelo"
        },
        next:14
    },
    142:{
        color:'#E46316',
        text:{
            en:"orange",
            pt:"laranja",
            es:"naranja"
        },
        next:14
    },
    143:{
        color:'#FCE501',
        text:{
            en:"lemon",
            pt:"limão",
            es:"limón"
        },
        next:14
    },
    144:{
        color:'#7CB22D',
        text:{
            en:"lime",
            pt:"lima",
            es:"lima"
        },
        next:14
    },
    211:{
        color:'#9DA810',
        text:{
            en:"sour aromatics",
            pt:"aromáticos azedos",
            es:"aromáticos agrios"
        },
        next:21
    },
    212:{
        color:'#93A76D',
        text:{
            en:"acetic acid",
            pt:"ácido acético",
            es:"ácido acético"
        },
        next:21
    },
    213:{
        color:'#D0B345',
        text:{
            en:"butyric acid",
            pt:"ácido butírico",
            es:"ácido butírico"
        },
        next:21
    },
    214:{
        color:'#8DB73C',
        text:{
            en:"isovaleric acid",
            pt:"ácido isovalérico",
            es:"ácido isovalérico"
        },
        next:21
    },
    215:{
        color:'#F9F001',
        text:{
            en:"citric acid",
            pt:"ácido cítrico",
            es:"ácido cítrico"
        },
        next:21
    },
    216:{
        color:'#C1BB04',
        text:{
            en:"malic acid",
            pt:"ácido málico",
            es:"ácido málico"
        },
        next:21
    },
    221:{
        color:'#8F1A53',
        text:{
            en:"winey",
            pt:"vinho",
            es:"vino"
        },
        next:22
    },
    222:{
        color:'#B43F34',
        text:{
            en:"whiskey",
            pt:"uísqui",
            es:"whisky"
        },
        next:22
    },
    223:{
        color:'#BA9226',
        text:{
            en:"fermented",
            pt:"fermentado",
            es:"fermentado"
        },
        next:22
    },
    224:{
        color:'#8C6434',
        text:{
            en:"overripe",
            pt:"ácido málico",
            es:"super maduro"
        },
        next:22
    },
    331:{
        color:'#A1BD1B',
        text:{
            en:"under-ripe",
            pt:"sub-maduro",
            es:"poco maduro"
        },
        next:33
    },
    332:{
        color:'#5FAB33',
        text:{
            en:"peapod",
            pt:"vagem de ervilha",
            es:"vaina de guisante"
        },
        next:33
    },
    333:{
        color:'#01A64F',
        text:{
            en:"fresh",
            pt:"fresco",
            es:"fresco"
        },
        next:33
    },
    334:{
        color:'#048646',
        text:{
            en:"dark green",
            pt:"verde escuro",
            es:"verde oscuro"
        },
        next:33
    },
    335:{
        color:'#20B544',
        text:{
            en:"vergetative",
            pt:"vegetal",
            es:"vegetal"
        },
        next:33
    },
    336:{
        color:'#A2A822',
        text:{
            en:"hay-like",
            pt:"como feno",
            es:"como el heno"
        },
        next:33
    },
    337:{
        color:'#78C235',
        text:{
            en:"herb-like",
            pt:"herbal",
            es:"hierbas"
        },
        next:33
    },
    411:{
        color:'#8B8C8F',
        text:{
            en:"stale",
            pt:"envelhecido",
            es:"racio"
        },
        next:41
    },
    412:{
        color:'#BDB171',
        text:{
            en:"cardboard",
            pt:"papelão",
            es:"cartón"
        },
        next:41
    },
    413:{
        color:'#FDFDF3',
        text:{
            en:"papery",
            pt:"papel",
            es:"papel"
        },
        next:41
    },
    414:{
        color:'#754F01',
        text:{
            en:"woody/bark",
            pt:"lenhoso/casca",
            es:"woody/corteza"
        },
        next:41
    },
    415:{
        color:'#A2A36C',
        text:{
            en:"woody/pulp",
            pt:"lenhoso/polpa",
            es:"woody/pulpa"
        },
        next:41
    },
    416:{
        color:'#C9B580',
        text:{
            en:"musty/dusty",
            pt:"mofado/pó",
            es:"mohoso/polvoriento"
        },
        next:41
    },
    417:{
        color:'#978941',
        text:{
            en:"musty/earthy",
            pt:"mofado/terroso",
            es:"mohoso/terrenal"
        },
        next:41
    },
    418:{
        color:'#9C967E',
        text:{
            en:"animalic",
            pt:"animal",
            es:"animalico"
        },
        next:41
    },
    419:{
        color:'#CD7B67',
        text:{
            en:"meaty/brothy",
            pt:"carnudo/caldo",
            es:"carnoso/caldo"
        },
        next:41
    },
    4110:{
        color:'#DC6368',
        text:{
            en:"phenolic",
            pt:"fenólico",
            es:"fenólico"
        },
        next:41
    },
    421:{
        color:'#7FA79C',
        text:{
            en:"bitter",
            pt:"amargo",
            es:"amargo"
        },
        next:42
    },
    422:{
        color:'#DDF1FC',
        text:{
            en:"salty",
            pt:"salgado",
            es:"salado"
        },
        next:42
    },
    423:{
        color:'#799AAE',
        text:{
            en:"medicinal",
            pt:"medicinal",
            es:"medicinal"
        },
        next:42
    },
    424:{
        color:'#019EB9',
        text:{
            en:"petroleum",
            pt:"petróleo",
            es:"petróleo"
        },
        next:42
    },
    425:{
        color:'#5D777B',
        text:{
            en:"skunky",
            pt:"odor desagradável",
            es:"olor desagradable"
        },
        next:42
    },
    426:{
        color:'#120A0A',
        text:{
            en:"rubber",
            pt:"borracha",
            es:"goma"
        },
        next:42
    },
    531:{
        color:'#B9A440',
        text:{
            en:"acrid",
            pt:"acre",
            es:"acrídico"
        },
        next:53
    },
    532:{
        color:'#899792',
        text:{
            en:"ashy",
            pt:"cinzas",
            es:"ceniza"
        },
        next:53
    },
    533:{
        color:'#A17435',
        text:{
            en:"smoky",
            pt:"defumado",
            es:"ahumado"
        },
        next:53
    },
    534:{
        color:'#8A480D',
        text:{
            en:"brown, roast",
            pt:"marrom, tostado",
            es:"marrón, tostado"
        },
        next:53
    },
    541:{
        color:'#B78F6C',
        text:{
            en:"grain",
            pt:"grãos",
            es:"grano"
        },
        next:54
    },
    542:{
        color:'#EC9D57',
        text:{
            en:"malt",
            pt:"malte",
            es:"malta"
        },
        next:54
    },
    631:{
        color:'#C88A2A',
        text:{
            en:"anise",
            pt:"anís",
            es:"anís"
        },
        next:63
    },
    632:{
        color:'#8D292A',
        text:{
            en:"nutmeg",
            pt:"nóz moscada",
            es:"nuez moscada"
        },
        next:63
    },
    633:{
        color:'#E67622',
        text:{
            en:"cinnamon",
            pt:"canela",
            es:"canela"
        },
        next:63
    },
    634:{
        color:'#A16C58',
        text:{
            en:"clove",
            pt:"cravo",
            es:"clavo"
        },
        next:63
    },
    711:{
        color:'#D4AD07',
        text:{
            en:"peanuts",
            pt:"amendoim",
            es:"cacahuetes"
        },
        next:71
    },
    712:{
        color:'#9D542F',
        text:{
            en:"hazelnut",
            pt:"avelã",
            es:"avellana"
        },
        next:71
    },
    713:{
        color:'#C89E81',
        text:{
            en:"almond",
            pt:"amêndoa",
            es:"almendra"
        },
        next:71
    },
    721:{
        color:'#6A2A18',
        text:{
            en:"chocolate",
            pt:"chocolate",
            es:"chocolate"
        },
        next:72
    },
    722:{
        color:'#480401',
        text:{
            en:"dark chocolate",
            pt:"chocolate amargo",
            es:"chocolate amargo"
        },
        next:72
    },
    811:{
        color:'#310A0D',
        text:{
            en:"molasses",
            pt:"melaço",
            es:"melaza"
        },
        next:81
    },
    812:{
        color:'#AF331B',
        text:{
            en:"maple syrup",
            pt:"xarope de bordo",
            es:"jarabe de arce"
        },
        next:81
    },
    813:{
        color:'#D88918',
        text:{
            en:"caramelized",
            pt:"caramelizado",
            es:"caramelizado"
        },
        next:81
    },
    814:{
        color:'#DC5B18',
        text:{
            en:"honey",
            pt:"mel",
            es:"miel"
        },
        next:81
    },
    921:{
        color:'#F99E0D',
        text:{
            en:"chamomile",
            pt:"camomila",
            es:"manzanilla"
        },
        next:92
    },
    922:{
        color:'#F15776',
        text:{
            en:"rose",
            pt:"rosa",
            es:"rosa"
        },
        next:92
    },
    923:{
        color:'#F6F1BA',
        text:{
            en:"jasmine",
            pt:"jasmim",
            es:"jasmín"
        },
        next:92
    },
    
}

const hexToRGB = hex => {
    let r = 0, g = 0, b = 0;
    // handling 3 digit hex
    if(hex.length === 4){
       r = "0x" + hex[1] + hex[1];
       g = "0x" + hex[2] + hex[2];
       b = "0x" + hex[3] + hex[3];
       // handling 6 digit hex
    }else if (hex.length === 7){
 
       r = "0x" + hex[1] + hex[2];
       g = "0x" + hex[3] + hex[4];
       b = "0x" + hex[5] + hex[6];
    };
 
    return{
       red: +r,
       green: +g,
       blue: +b
    };
 }

const textContrast = (color) => {
    return (color.red*0.299 + color.green*0.587 + color.blue*0.114) > 186? "#000000" : "#ffffff"
}

const Badge = (props) => {
    // CUSTOM BADGE PROP
    // {
    //      text:"teste"
    //      color:"#FFFFFF",
    //      textColor:"#FFFFFF",
    // }
    if (props.customBadge){
        const textColor = props.customBadge.textColor?props.customBadge.textColor:textContrast(hexToRGB(props.customBadge.color))
        return (
            <span className="badge" style={{backgroundColor:props.customBadge.color, color:textColor}}>{props.customBadge.text}</span>
        );
    }
    const textColor = textContrast(hexToRGB(badgeOptions[props.badge].color));
    return (
        <span className="badge" style={{backgroundColor:badgeOptions[props.badge].color, color:textColor}}>{badgeOptions[props.badge].text[language]}</span>
    );
}

const BadgeTest = () => {
    const badges = Object.keys(badgeOptions);
    return (
        <div className="badge-test">
            {
                badges.map(badge =>{
                    return <Badge badge={badge} key={10000+badge}/>
                })
            }
            <Badge customBadge={{text:"Customizado",color:"#FF0000"}} />
        </div>
    );
}

export {Badge, BadgeTest, badgeOptions};