import styled from "styled-components";

export const Container = styled.div`
    display: flex;
`
export const DescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0 0 0 15px;
`

export const DescriptionItem = styled.p`
    color: #fff;
    margin: 0;
    font-weight: 300;
    font-size: 12px;
`