import React from 'react'
import {
    useHistory
} from "react-router-dom";

import { isPt, isCo } from '../../../common'

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faInstagram } from '@fortawesome/free-brands-svg-icons'

import '../../../styles/profile/components/UserRequestSample.css'

const UserRequestSample = () => {

    const history = useHistory();

    return (
        <div className="new-profile user-accordion-button">
            <div className="user-accordion-action" onClick={() => history.push("/offerlist")}>
                <div className="new-farmly-button f-button-orange">
                    {isPt() ? "Pedir uma amostra" : (isCo() ? "Pedir una muestra" : "Request a sample")}
                </div>
            </div>
            {/* parte do botão do instagram está comentado porque o banco de dados ainda nao foi atualizado ainda para isso */}
            {/* <div className="user-instagram-container">
                <div className="user-instagram-container-icon">
                    <FontAwesomeIcon icon={faInstagram} color="white" className="user-instagram-container-icon-content" />
                </div>
                <div className="user-instagram-container-text">
                    {isPt() ? "Instagram do Torrefador" : (isCo() ? "perfil tostador" : "Roaster instagram")}
                </div>
            </div> */}
        </div>
    )
}

export default UserRequestSample