import styled from "styled-components";

export const LegendsLine = styled.tr`

`;


export const LegendsTitle = styled.th`
    width: 10vw;
    font-size: 1.5vw;
    color:rgb(27,98, 94);
`;
