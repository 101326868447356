import React from "react";
import { language } from "../../../common";
import Main from "../../../components/templates/Main";
import SampleCartSteps from "./components/SampleCartSteps";
import ModalDisplay from "./components/ModalDisplay";
import useSampleCarts from './hooks/useSampleCarts';
import { SampleCartSignIn } from "./style";

const SampleCart = () => {
    const titleHeaderLanguage = {
        pt: "Cadastre-se",
        en: "Signup",
        es: "Registrarse",
    }[language];
    const sampleCartsHook = useSampleCarts();

    return (
        <>
            <Main titleHeader={titleHeaderLanguage}>
                <SampleCartSignIn>
                    <SampleCartSteps
                        step={sampleCartsHook.step}
                        userContext={sampleCartsHook.userContext}
                        arraySamples={sampleCartsHook.arraySamples}
                        renderSamples={sampleCartsHook.renderSamples}
                        setStep={sampleCartsHook.setStep}
                        roasterData={sampleCartsHook.roasterData}
                        setRoasterData={sampleCartsHook.setRoasterData}
                        toggleModal={sampleCartsHook.toggleModal}
                        setModalMessage={sampleCartsHook.setModalMessage}
                        //@ts-ignore
                        submitRequestFromLogin={sampleCartsHook.submitRequestFromLogin}
                        signUpForm={sampleCartsHook.signUpForm}
                        userData={sampleCartsHook.userData}
                        setUserData={sampleCartsHook.setUserData}
                        emailFailure={sampleCartsHook.emailFailure}
                        serverFailure={sampleCartsHook.serverFailure}
                        authFailure={sampleCartsHook.authFailure}
                        //@ts-ignore
                        login={sampleCartsHook.login}
                    />
                    <ModalDisplay
                        showModal={sampleCartsHook.showModal}
                        toggleModal={sampleCartsHook.toggleModal}
                        modalMessage={sampleCartsHook.modalMessage}
                    />
                </SampleCartSignIn>
            </Main>
        </>
    );
};

export default SampleCart;
