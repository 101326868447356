import React, { useContext, Fragment } from 'react'
import { useEffect } from 'react'
import './Nav.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingBasket, faBell } from '@fortawesome/free-solid-svg-icons'

import { Link } from 'react-router-dom'
import { isPt, isBr, isCo, farmlyTexts, acceptedCookies, server } from '../../common'
import UserContext from '../../context/user/UserContext.js'
import CartContext from '../../context/cart/CartContext.js'
import { useLocation, useHistory } from 'react-router-dom'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import FarmlyModal from '../templates/FarmlyModal.jsx'
import { useState } from 'react'
import axios from 'axios'
// import { isClipEffect } from 'html2canvas/dist/types/render/effects'
const headerImage = require('../../assets/header-banner.png')
const homeHeaderImage = require('../../assets/basetopcut.png')
const logoImage = require('../../assets/title-home-small.png')
const logoAlt = require('../../assets/title-home-small.png')

const Nav = props => {

    const userContext = useContext(UserContext)
    const cartContext = useContext(CartContext)
    const location = useLocation()
    const history = useHistory()

    const [timeAlert, setTimeAlert] = useState(false)

    const [notificationList, setNotificationList] = useState()

    useEffect(() => {
        {
            userContext.user &&
                axios.get(`${server}/getAllNotifications/${userContext.user.id}/${userContext.user.isProd}`)
                    .then(async notifications => {
                        await setNotificationList(notifications.data.filter((novas) => novas.read == false))
                    })
                    .catch(err => console.log(err))
        }
    }, [])


    // useEffect(() => {
    //         if (cartContext.timer.minutes === 3 && cartContext.timer.seconds === 0) {
    //             setTimeAlert(true)
    //         }
    //         setTimeout(function () {
    //             if (cartContext.controlTimer.control == true) {
    //                 if (cartContext.controlTimer.zero == true) {
    //                     cartContext.setControlTimer({ control: false, zero: false })
    //                     cartContext.setTimer({ ...cartContext.timer, minutes: 0, seconds: 0 })
    //                 } else {
    //                     cartContext.setControlTimer({ control: false, zero: false })
    //                     cartContext.setTimer({ ...cartContext.timer, minutes: 15, seconds: 0 })
    //                 }

    //             } else {
    //                 if (cartContext.timer.seconds > 0) {
    //                     cartContext.setTimer({ ...cartContext.timer, seconds: (cartContext.timer.seconds - 1) })
    //                 }

    //                 if (cartContext.timer.seconds === 0) {
    //                     if (cartContext.timer.minutes === 0) {
    //                         cartContext.noMoreTime()
    //                         cartContext.setTimer({ ...cartContext.timer, current: false })

    //                     } else {
    //                         cartContext.setTimer({ ...cartContext.timer, seconds: 59, minutes: cartContext.timer.minutes - 1 })
    //                     }
    //                 }
    //             }
    //         }, 1000)
    //     }, [cartContext.timer.seconds, cartContext.timer.minutes])

    //     const plusTime = () => {
    //         cartContext.setControlTimer({ control: true, zero: false })
    //     }

    // const action = (
    //     <Fragment>
    //         <br />
    //         <div className='time-modal'>
    //             <Link to="/coffees">
    //                 <div className="edit-coffee-button" onClick={plusTime} > {isBr() ? 'Continuar comprando' : 'Keep buying'}</div>
    //             </Link>
    //             <Link to="/cart">
    //                 <div className="edit-coffee-button" >{isBr() ? 'Finalizar compra' : 'Confirm purchase'}</div>
    //             </Link>
    //         </div>
    //     </Fragment>
    // )

    // useEffect(() => {
    //         if (cartContext.controlTimer.control == true) {
    //             cartContext.setTimer({ ...cartContext.timer, current: false })
    //         }
    // }, [cartContext.controlTimer])


    const logout = () => {

        if (acceptedCookies()) {
            document.cookie = 'jwt=0; max-age=0'
            document.cookie = 'isProd=0; max-age=0'
            document.cookie = 'userId=0; max-age=0'
            document.cookie = 'cartList=0; max-age=0'
        }
        { (!userContext.user || (userContext.user && !userContext.user.isProd)) && cartContext.clearCart() }
        userContext.userLogout()
        window.location.href = '/'
    }

    const renderLogo = () => (
        <Link to="/">
            <div id="logo-container">
                {!props.smallImage &&
                    <img id='logo' src={logoImage} alt="logo" />
                }
                <img id='logo-alt' src={logoAlt} alt="logo" />
                {props.smallImage &&
                    <div className="small-image">
                        <img id='small-logo' src={logoAlt} alt="logo" />
                    </div>
                }
            </div>
        </Link>
    )

    const burgerClick = () => {
        const burger = document.querySelector("#burger")
        const nav = document.querySelector(".nav")
        const navItems = document.querySelectorAll('.nav-item')

        nav.classList.toggle('nav-active')
        burger.classList.toggle('burger-open')

        navItems.forEach((item, index) => {
            item.style.animation = item.style.animation ? '' : `navLinkFade 0.5s ease forwards ${index / 7 + 0.2}s`
        })
    }

    const renderNavBar = () => (
        <div className="nav-area">
            <nav className="nav">
                <Link className='nav-item' to='/'>
                    {isPt() ? farmlyTexts.navHome_pt : farmlyTexts.navHome_en}
                </Link>
                {/* {!isBr() && !userContext.user &&
                    <Link className='nav-item' to='/start_here'>
                        START HERE
                </Link>} */}
                <Link className='nav-item' to='/coffees'>
                    {(isPt() || isCo()) ? farmlyTexts.navCoffees_pt : farmlyTexts.navCoffees_en}
                </Link>
                {!isPt() &&
                    <Link className='nav-item' to='/offerlist'>
                        OFFERLIST
                    </Link>
                }
                {!isPt() && !!userContext.user &&
                    <Link className='nav-item' to='/createCuppingCoffee'>
                        CUPPING
                    </Link>
                }
                {!isPt() && !userContext.user &&
                    <Link className='nav-item' to='/CuppingLogin'>
                        CUPPING
                    </Link>
                }
                {!userContext.user &&
                    <Link className='nav-item' to='/login'>
                        {isPt() ? farmlyTexts.navLogin_pt : farmlyTexts.navLogin_en}
                    </Link>}
                {!!userContext.user &&
                    <Link className='nav-item' to={{
                        pathname: '/profile',
                        navProps: {
                            from: userContext.user.isProd ? 'Perfil' : 'Profile'
                        }
                    }} >
                        {(isPt() || isCo()) ? farmlyTexts.navProfile_pt : farmlyTexts.navProfile_en}
                    </Link>}
                {!!userContext.user && userContext.user.isProd &&
                    <Link className='nav-item' to={{
                        pathname: '/profiletosample',
                        navProps: {
                            from: 'Enviar Amostra'
                        }
                    }} >
                        {isCo() ? 'ENVIAR MUESTRA' : 'SEND SAMPLE'}
                    </Link>}

                {/* {isPt() && <a className='nav-item' href='https://blog-pt.farmlyclub.com'>
                    {isPt() ? farmlyTexts.navBlog_pt : farmlyTexts.navBlog_en}
                </a>}
                {!isPt() && <a className='nav-item' href='https://blog.farmlyclub.com'>
                    {isPt() ? farmlyTexts.navBlog_pt : farmlyTexts.navBlog_en}
                </a>} */}
                <Link className='nav-item' to='/help'>
                    {isPt() ? farmlyTexts.navHelp_pt : (isCo() ? 'AYUDA' : farmlyTexts.navHelp_en)}
                </Link>
                {/* {!!userContext.user &&
                    <Link to={{
                        pathname: '/profiletonotification',
                        navProps: {
                            from: userContext.user.isProd ? 'Notificações' : 'Notifications'
                        }
                    }}>
                        <div className="cart-container">
                            <FontAwesomeIcon className="cart-icon" style={{ color: "rgb(245, 245, 245)", margin: '2px' }} icon={faBell} />
                            {notificationList &&
                                <Fragment>
                                    {
                                        notificationList.length > 0 &&
                                        <div className='cart-counter'>{notificationList.length}</div>
                                    }
                                </Fragment>
                            }
                        </div>
                    </Link>
                } */}
                {cartContext.cartList && (userContext.user ? !userContext.user.isProd : !userContext.user) &&
                    <Link className='nav-item' to='/cart' alt="link to shopping cart">
                        <div className="cart-container">
                            <FontAwesomeIcon className="cart-icon" icon={faShoppingBasket} />
                            {cartContext.cartList.length > 0 &&
                                <div className='cart-counter'>{cartContext.cartList.length}</div>
                            }
                        </div>
                    </Link>}
                {!!userContext.user && <label id="divider">|</label>}
                {!!userContext.user &&
                    <button className="logout nav-item" onClick={logout}>{isPt() ? farmlyTexts.navLogout_pt : farmlyTexts.navLogout_en}</button>}
            </nav>
            <div id="burger" onClick={burgerClick}>
                <div id="line1"></div>
                <div id="line2"></div>
                <div id="line3"></div>
            </div>
        </div >
    )

    const renderHeaderBanner = () => (
        <div id="header-container">
            <img id="header" src={headerImage} alt="" />
        </div>
    )

    const renderHomeBanner = () => (
        <div id="home-header-container">
            <img id="home-header" src={homeHeaderImage} alt="" />
        </div>
    )

    return (
        <>
            {/* {timeAlert &&
                <FarmlyModal closeModal={() => setTimeAlert(false)}>
                    <label id="request-title">{isBr() ? 'Finalize sua compra!' : 'Confirm purchase'}</label>
                    {isBr() ?
                        <Fragment>
                            <p>As sacas de cafés que estão no seu carrinho ficarão reservadas para você por mais 3 minutos!</p>
                            <p>Finalize sua compra para garantir seus cafés ou continue comprando para garantir a reserva de sacas de café no seu carrinho
                            por mais tempo!</p>
                        </Fragment>
                        :
                        <Fragment>
                            <p>The products in your cart will be reserved for only 3 more minutes!</p>
                            <p>Confirm your purchase to guarantee your coffees or press keep buying to restart the reservation timer!</p>
                        </Fragment>
                    }
                    <div className='time-modal'>
                        <Link to="/coffees">
                            <div className="edit-coffee-button" onClick={plusTime} >{isBr() ? 'Continuar comprando' : 'Keep buying'}</div>
                        </Link>
                        <Link to="/cart">
                            <div className="edit-coffee-button" >{isBr() ? 'Finalizar compra' : 'Confirm purchase'}</div>
                        </Link>
                    </div>
                </FarmlyModal>
            } */}
            <div id="nav-container" style={props.noBanner ? { position: 'fixed', width: '100%' } : {}}>
                {renderLogo()}
                {renderNavBar()}
                {/* {!!userContext.user && !userContext.user.isProd &&
                <Fragment>
                {(cartContext.cartList).length && (cartContext.timer.minutes !== 0 || cartContext.timer.seconds !== 0) ?
                    <Snackbar
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        key={{ vertical: 'bottom' } + { horizontal: 'center' }}
                        open={true}
                    >
                        <SnackbarContent
                            message={
                                isPt() ?
                                    `As sacas de café que estão no seu carrinho estão reservadas para você por ${cartContext.timer.minutes == 0 ? '00' : cartContext.timer.minutes < 10 ? '0' + cartContext.timer.minutes : cartContext.timer.minutes}:${cartContext.timer.seconds == 0 ? '00' : cartContext.timer.seconds < 10 ? '0' + cartContext.timer.seconds : cartContext.timer.seconds}.\n
                                Finalize sua compra ou continue comprando para aumentar o tempo que suas sacas ficarão reservadas!`
                                            :
                                            `The coffees will be reserved in your cart for ${cartContext.timer.minutes == 0 ? '00' : cartContext.timer.minutes < 10 ? '0' + cartContext.timer.minutes : cartContext.timer.minutes}:${cartContext.timer.seconds == 0 ? '00' : cartContext.timer.seconds < 10 ? '0' + cartContext.timer.seconds : cartContext.timer.seconds}.\n
                                Confirm your purchases or press "keep buying" to restart the reservation timer!`
                                    }
                                    action={action}
                                    style={{ width: '100%', justifyContent: 'center' }}
                                />

                            </Snackbar>
                            :
                            <Fragment></Fragment>
                        }
                    </Fragment>
                }
                </Fragment>
                } */}
                {!props.noBanner && (props.isHome ? renderHomeBanner() : renderHeaderBanner())}
            </div>
        </>
    )
}

export default Nav