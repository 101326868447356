import React, { useState, useEffect, useContext, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ReactGA from 'react-ga'
import Select from "react-select";

import './RequestSample.css'
import { isPt, farmlyTexts, server } from '../../common'
// import REditableText from '../profile/roasters/rEditableText'
// import REditableSelect from './rEditableSelect'
import Error from '../templates/Error'
import UserContext from '../../context/user/UserContext.js'
import SampleContext from '../../context/sample/SampleContext.js'

import Countries from "../../assets/countries.json";
import FarmlyInput from '../templates/FarmlyInput'


const countriesElectedForVatEori = [
    "United Kingdom (UK)",
];

const renderOpts = () => {
    const country = [];
    Countries.map((opt) => {
        country.push({
            value: opt,
            label: opt,
        });
    });
    return country;
};

const RequestSample = props => {
    const { closeModal, noClose, samplesArray } = props
    // const { triggerRequest, isAtDisplay} = props
    const [userData, setUserData] = useState({})
    const [error, setError] = useState(false)
    const [largerSample, setLargerSample] = useState(false)
    const [roastOption, setRoastOption] = useState(false)
    const userContext = useContext(UserContext)
    const sampleContext = useContext(SampleContext)
    const defaultUserData = {
        email: '',
        address: '',
        country: '',
        postalCode: '',
        sampleWeight: 100,
        roastery: '',
    }
    const allFilled = (
        userData.name !== '' &&
        userData.email !== '' &&
        userData.address !== '' &&
        userData.country !== '' &&
        userData.postalCode !== '' &&
        userData.roastery !== '' &&
        (!countriesElectedForVatEori.includes(userData.country) || (countriesElectedForVatEori.includes(userData.country) && userData.vat!==''))
    )
    // todo: substituir por users.js getUserData e fazer 
    // pegar tudo do context e colocar o jwt no header
    useEffect(() => {
        if (userContext.user != null) {
            axios.post(`${server}/users`, {
                "isProd": 0,
                "id": userContext.user.id,
                "jwt": userContext.user.jwt
            })
                .then(res => setUserData({ ...res.data, sampleWeight: 100 }))
                .catch(setUserData(defaultUserData))
        }
        else {
            setUserData(defaultUserData)
        }
    }, [])

    const [formPage,setFormPage] = useState(1)

    const toggleLargerSample = () => {
        setLargerSample(!largerSample)
    }

    const handleRoastOption = () =>{
        setRoastOption(!roastOption);
    }

    const requestSubmit = async () => {
        var n = new Date()
        if (!allFilled) {
            if (userData.name == '') { document.getElementById('name').style.borderColor = 'red' }
            if (userData.email == '') { document.getElementById('email').style.borderColor = 'red' }
            if (userData.address == '') { document.getElementById('address').style.borderColor = 'red' }
            if (userData.postalCode == '') { document.getElementById('postal').style.borderColor = 'red' }
            if (userData.country == '') { document.getElementById('country').style.borderColor = 'red' }
            if (userData.roastery == '') { document.getElementById('roastery').style.borderColor = 'red' }
        }
        else {
            samplesArray.forEach(async item => {
                await axios.post(`${server}/request`, {
                    roaster: userData,
                    coffee: item,
                    date: n.getFullYear() + '-' + n.getMonth() + '-' + n.getDate(),
                    roast_option: roastOption,
                })
                    .then(res => {
                        const { answer } = res.data
                        document.querySelector('#request-title').removeAttribute('hidden')
                        document.querySelector('.request-content').style.display = 'none'
                        document.querySelector('#request-title').style.marginTop = '0'
                        if (answer === 'error') {
                            document.querySelector('#request-title').innerHTML = 'Erro!'
                            document.querySelector('#request-title').style.fontSize = 'x-large'
                        } else if (answer === 'success') {
                            document.querySelector('#request-title').innerHTML = isPt() ? farmlyTexts.requestSampleEmailSent_pt : farmlyTexts.requestSampleEmailSent_en
                            document.querySelector('#request-title').removeAttribute('hidden')
                            sampleContext.clearList()
                        }
                        ReactGA.event({
                            category: 'Offerlist',
                            action: 'User finished sample request'
                        })
                    })
                    .catch(err => {
                        document.querySelector('.request-content').style.display = 'none'
                        document.querySelector('#request-title').removeAttribute('hidden')
                        document.querySelector('#request-title').style.marginTop = '0'
                        document.querySelector('#request-title').innerHTML = 'server error'
                        document.querySelector('#request-title').style.fontSize = 'x-large'
                        setError(true)
                    })
            })
        }
    }


    const nextModal = () => {
        document.getElementById('basic-info-field').style.display = 'none'
        document.getElementById('request-text').style.display = "none"
        document.getElementById('weight-field').style.display = "block"
        toggleLargerSample()
    }
    return (
        <div id="sample-request-bg" onClick={!noClose ? event => {
            event.target.id === 'modal-bg' && closeModal()
        }: null }>
            <div id="sample-request-box">
                <div className="request-close-modal-button" onClick={closeModal}>
                    <FontAwesomeIcon className="close-request-modal-icon" icon={faTimes} />
                </div>
                <div className="request-wrap">
                    <label id="request-title" hidden>{isPt() ? farmlyTexts.requestRequestSample_pt : farmlyTexts.requestRequestSample_en} </label>
                    {!userContext.user &&
                        <div className="request-content">
                            <label id="request-text">Log in or register to request samples!</label>
                            <Link id='request-submit' to={{ pathname: '/login', state: window.location.pathname }}>
                                Login
                        </Link>
                        </div>}

                    {userContext.user &&
                        <div className="request-content">
                            <label id="request-text">{isPt() ? farmlyTexts.requestConfirmData_pt : farmlyTexts.requestConfirmData_en}</label>
                            <div id="basic-info-field" className="request-field-container">
                                {formPage === 1 
                                ? 
                                <Fragment>
                                    {isPt() ? farmlyTexts.requestName_pt : farmlyTexts.requestName_en}
                                    <FarmlyInput value={userData.name} setValue={value => {setUserData({ ...userData, name: value }) }} elementId={"name"}  type={"text"} styleClass="request-sample-input" />
                                    Email:
                                    <FarmlyInput value={userData.email} setValue={value => { setUserData({ ...userData, email: value }) }} elementId={"email"}  styleClass="request-sample-input" />
                                    {isPt() ? 'Nome da Torrefadora' : 'Roastery Name'}
                                    <FarmlyInput value={userData.roastery} setValue={value => { setUserData({ ...userData, roastery: value }) }} elementId={"postal"}  styleClass="request-sample-input" />
                                    {isPt() ? farmlyTexts.requestAddress_pt : farmlyTexts.requestAddress_en}
                                    <FarmlyInput value={userData.address} setValue={value => { setUserData({ ...userData, address: value }) }} elementId={"address"}  styleClass="request-sample-input" />
                                    {isPt() ? farmlyTexts.requestCEP_pt : farmlyTexts.requestCEP_en}
                                    <FarmlyInput value={userData.postalCode} setValue={value => { setUserData({ ...userData, postalCode: value }) }} elementId={"postal"}  styleClass="request-sample-input" />
                                    {isPt() ? 'País' : 'Country'}
                                    <Select
                                        className="request-select-input"
                                        classNamePrefix="select"
                                        id="select-country"
                                        styles={{
                                            menuList:(provided, state) => ({
                                                ...provided,
                                                maxHeight:'150px'
                                              }),
                                        }}
                                        controlShouldRenderValue={true}
                                        value={{value:userData.country,label:userData.country}}
                                        placeholder={
                                            (isPt()
                                                ? farmlyTexts.signupCountry_pt
                                                : farmlyTexts.signupCountry_en) + " ..."
                                        }
                                        onChange={(e) => {
                                            setUserData({ ...userData, country: e.value });
                                        }}
                                        options={renderOpts()}
                                    />
                                    <FormControlLabel
                                        control={<Switch checked={roastOption} onChange={handleRoastOption} name="roast_option" inputProps={{ 'aria-label': 'roast option' }}/>}
                                        label={
                                            roastOption
                                            ?
                                            "Roasted"
                                            :
                                            "Not roasted"
                                        }
                                    />
                                </Fragment>
                                :
                                <Fragment>
                                    {countriesElectedForVatEori.includes(userData.country) &&
                                    <Fragment>
                                        VAT:
                                        <FarmlyInput value={userData.vat} setValue={value => { setUserData({ ...userData, vat: value }) }} elementId={"vat"}  styleClass="request-sample-input" />
                                    </Fragment>
                                    }
                                    {isPt() ? 'Site da torrefadora' : 'Roastery Website'}
                                    <FarmlyInput value={userData.website} setValue={value => { setUserData({ ...userData, website: value }) }} elementId={"website"}  styleClass="request-sample-input" />
                                    {isPt() ? 'Torra por mês (kg)' : 'Roast per month (kg)'}
                                    <FarmlyInput value={userData.roast_per_month} setValue={value => { setUserData({ ...userData, roast_per_month: value }) }} type={"numeric"} elementId={"roast_per_month"}  styleClass="request-sample-input" />
                                </Fragment>
                                }
                                
                                {/* <FarmlyInput value={userData.country} setValue={e => { setUserData({ ...userData, country: e.target.value }) }} elementId={"country"}  styleClass="request-sample-input" /> */}
                                {/* <label htmlFor="">{isPt() ? farmlyTexts.requestSampleWeight_pt : 'Is 100g of green samples enough ?'}</label>
                                <Switch
                                    checked={roastOption}
                                    onChange={handleRoastOption}
                                    name="roast_option"
                                    inputProps={{ 'aria-label': 'roast option' }}
                                    // color='#2E7C79'
                                /> */}
                            </div>
                            {
                                formPage === 1 
                                ? 
                                <div id="request-submit" onClick={()=>{setFormPage(2)}}>
                                    {isPt() ? "Próximo" : "Next"}
                                </div>
                                :
                                <div id="request-submit" onClick={requestSubmit}>
                                    {isPt() ? farmlyTexts.requestRequest_pt : farmlyTexts.requestRequest_en}
                                </div>
                            }
                            {!allFilled && <div className="request-alert"> {isPt() ? farmlyTexts.requestFillAll_pt : farmlyTexts.requestFillAll_en} </div>}

                        </div>}
                    {error && <Error setError={setError} />}
                </div>
            </div>
        </div>
    )
}

export default RequestSample

// todo: evitar que uma amostr seja solicitada diversas vezes pela mesma pessoa