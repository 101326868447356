import styled from "styled-components";
import { Link } from 'react-router-dom';

export const BodyContainer = styled(Link)`
    margin: 0 18px;
    z-index: 2;
    color: #fff;
    display: flex;
    flex-direction: column;
    text-decoration: none;
`

export const Title = styled.p`
    font-weight: 400;
    font-size: 20px;
    line-height:30px;
    margin:0;
    color:#fff;
`