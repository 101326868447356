import React, { useState, useEffect, useContext, Fragment } from 'react'
import Avatar from '@material-ui/core/Avatar';
import './CuppingLiveMediumCard.css'
import { farmlyColors, server, isBr, isCo } from '../../../common.js'
import axios from 'axios'
import CartContext from '../../../context/cart/CartContext'
import UserContext from '../../../context/user/UserContext.js'
import {
    toEuro,
    totalCoffeePrice,
    logisticsFee,
    importDuties,
    farmlyFee,
    producersPriceKg
} from '../../templates/pricing.js'
import { storage } from '../../../firebase'

const coffeeImage = require('../../../assets/produtor3.jpg')
const coffeePicture3 = require('../../../assets/coffee_farm.jpg')

const CuppingLiveMediumCard = props => {
    const {coffee, toggleModal, setModalMessage, userData, setAuxSamples, auxSamples } = props
    const [sackQuantity, setSackQuantity] = useState(1)
    const cartContext = useContext(CartContext)
    const userContext = useContext(UserContext)
    const [isInCart, setIsInCart] = useState(false)
    const [paymentData, setPaymentData] = useState({
        farmly_fee: 0,
        logistics_fee: 0,
        import_duties: 0,
        import_duties_adjustment: 0,
        euro_value: 0
    })
    const [totalKgPrice, setTotalKgPrice] = useState("0.00")
    const priceKgEuro = toEuro(totalKgPrice, paymentData, coffee).toFixed(2)
    const newDate = new Date
    const day = ("0" + newDate.getDate().toString()).slice(-2)
    const month = ("0" + (newDate.getMonth() + 1).toString()).slice(-2)
    const year = newDate.getFullYear().toString()
    const dateString = [month, day, year].join('-')
    const [hasReview, setHasReview] = useState(false)
    const [reviews, setReviews] = useState ([])
    const [auxReview, setAuxReview] = useState(false)
    const [reviewInfo, setReviewInfo] = useState({
        coffeeId: coffee.id,
        roasterId: userContext.user ? userContext.user.id : null,
        date: dateString,
        score: 0,
        comment: null,
        isAdmin: false
    })
    const [cardImg, setCardImg] = useState()
    const [farmImg, setFarmImg] = useState()
    const [coffeeImageUrl, setCoffeeImageUrl] = useState()
    const [error, setError] = useState(false)


    useEffect(() => {
        if(cartContext && cartContext.cartList) {
            cartContext.cartList.map(item => {
                if (item.id == coffee.id) {
                    setIsInCart(true)
                }
            })
    }
    }, [])
    useEffect(() => {
        axios.get(`${server}/paymentData`)
            .then(res => setPaymentData(res.data))
    }, [])

    useEffect(() => {
        if (coffee && paymentData) {
            const price = totalCoffeePrice({ ...coffee, quantity: sackQuantity }, paymentData)
            const kgPrice = totalCoffeePrice({ ...coffee, quantity: '1' }, paymentData)
            setTotalKgPrice(isNaN(kgPrice) ? "0.00" : (kgPrice / 60).toFixed(2))
        }
    }, [coffee, paymentData, sackQuantity])
    
    useEffect(() => {
        getReviews()
    }, [auxReview])

    const getReviews = async () =>{
        await axios.get(`${server}/getReview/${coffee.id}`)
            .then(async res=>{
                setReviews(res.data)
            })
    }

    useEffect(() => {
        if (userContext.user) {
            axios.get(`${server}/searchReview/${coffee.id}/${userContext.user.id}`)
                .then(async review => {
                    if (review.data) {
                        setHasReview(true)
                    }
                })
        }
    }, [auxReview])

    const decreaseQuantity = () =>{
        if(sackQuantity>0){
            setSackQuantity(sackQuantity-1)
        }
    }
    const increaseQuantity = () =>{
        setSackQuantity(sackQuantity+1)
    }
    const renderNotes = () =>
    coffee.sensory_notes_en.split(',').map(note=>{
       return(
           <div className={(note.length < 7) ? 'sensory-red' : (((note.length < 10 && note.length > 6) ? 'sensory-lime' : 'sensory-orange'))}>{note}</div>
       )
   })

        const addToCart = () => {
            cartContext.addToCart(parseInt(coffee.id), sackQuantity)
            setIsInCart(true)
            setModalMessage("Coffee Added!")
            toggleModal()
        }
    
        const updateCoffeeOnCart = () => {
            cartContext.cartList.map(item => {
                if (item.id == coffee.id) {
                    axios.patch(`${server}/coffeeSackRemain/${coffee}`, {
                        sackQuantity: sackQuantity - item.quantity,
                        sackRemain: coffee.sackRemain
                    })
                }
            })
            cartContext.updateCoffeeOnCart(parseInt(coffee.id), sackQuantity)
            setModalMessage("Cart updated!")
            toggleModal()
        }

        const renderComments = () =>
        reviews.map(item=>{
            const names = item.roaster.split(" ")
            const initials = names[0][0]
            return(
                <div>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <Avatar style={{backgroundColor: 'red'}}>{initials}</Avatar>
                        <p className="card-live-roaster-name">{item.roaster}</p>
                    </div>
                    <div>{item.comment}</div>
                    <br/>
                </div>
            )
        })

        const sendReview = async () =>{
            console.log(reviewInfo);
            await axios.post(`${server}/createReview`, reviewInfo)
                .then(res=>{
                    setAuxReview(!auxReview)
                    setHasReview(true)
                })
        }
    
        const deleteReview = () => {
            axios.delete(`${server}/deleteReview/${coffee.id}/${userContext.user.id}`)
            setHasReview(false)
            setAuxReview(!auxReview)
        }

        useEffect(() => {
            if (coffee.imageUrl) {
                storage.ref('producers').child(coffee.imageUrl.split(',')[0]).getDownloadURL()
                    .then(imgUrl => setCoffeeImageUrl(imgUrl))
                    .catch(() => { })
            }
        }, [])
    
        useEffect(() => {
            if (coffee.cardImg) {
                storage.ref('producers').child(coffee.cardImg).getDownloadURL()
                    .then(img => setCardImg(img))
                    .catch(() => { })
            }
        }, [])
    
        useEffect(() => {
            axios.get(`${server}/producer/${coffee.producerId}`)
                .then(user => {
                    if (user.data.farmImg) {
                        storage.ref('producers').child(user.data.farmImg.length > 1 ? user.data.farmImg.split(',')[0] : user.data.farmImg).getDownloadURL()
                            .then(imgUrl => setFarmImg(imgUrl))
                            .catch(() => { })
                    }
                })
                .catch()
        }, [])

        const removeSample = () =>{
            console.log(parseInt(userContext.user.id), coffee.id)
            axios.delete(`${server}/deleteRequest/${userContext.user.id}/${coffee.id}`)
            .then(res => {
                setAuxSamples(!auxSamples)
                console.log("rolou");
                console.log(res.data);
                })
                .catch(err => {
                    console.log('server error', err)
                    setError(true)
                })
        }
    return (
        <div className="live-medium-card-big-container">
            <div className="live-medium-card-container">
                <div className="second-container-live-card">
                    <div className="third-container-live-card">
                        <img src={cardImg || coffeeImageUrl || farmImg || coffeePicture3} className="card-medium-live-image"></img>
                        <div className="card-medium-live-code">
                            <div className="card-medium-live-code-container">
                                {coffee.farmCode}
                            </div>
                            <div className="producer-and-coffee-info-card-medium-live">
                                <p className="card-medium-live-producer-name">{coffee.producer}</p>
                                <div className="price-and-score-card-medium-live">
                                    <div className="card-live-small-info">{true ? `€${totalKgPrice}`:`€${priceKgEuro}`}</div> {/*isBr()*/}
                                    <div className="card-live-small-info">{coffee.score}pts</div>
                                </div>
                            </div>
                        </div>
                        <div className="third-container-live-medium-card">
                            <div className="card-medium-live-more-info-container">
                            <div><b>{isBr() ?'Processo:':(isCo() ? 'Proceso:' : 'Process:')}</b></div>
                            <div>{coffee.process}</div>
                            <br/>
                            <div><b>{isBr() ?'Variedade:':(isCo() ? 'Variedad:' : 'Variety:')}</b></div>
                            <div>{coffee.variety}</div>
                            <br/>
                            <div><b>{isBr() ?'Região:':(isCo() ? 'Región:' : 'Region:')}</b></div>
                            <div>{coffee.region}</div>
                            </div>
                            <div className="card-medium-live-sensory-container">
                                {coffee.sensory_notes_en != null &&
                                    renderNotes()
                                }
                            </div>
                        </div>
                        {/* {isBr() ?
                            <Fragment></Fragment>
                        :
                        <div>
                            <div className="card-medium-live-add-button-container">
                                {isInCart ? 
                                    <button className="card-medium-live-add-button" onClick={updateCoffeeOnCart}>Update Cart</button>
                                :
                                    <button className="card-medium-live-add-button" onClick={addToCart}>Add to Card</button>
                                }
                            </div>
                            <div className="card-medium-live-quantity-container">
                                <button className="card-live-button-quantity" onClick={()=> decreaseQuantity()}>-</button>
                                <input className="card-live-input-quantity" value={sackQuantity}></input>
                                <button className="card-live-button-quantity" onClick={()=> increaseQuantity()}>+</button>
                            </div>
                        </div>
                        } */}
                        {(isBr() || isCo()) ?
                        <Fragment></Fragment>
                    :
                        <div className="card-medium-live-add-button-container">
                        { (coffee.farmCode == 'C502') ?
                            <button className="card-medium-live-add-button">Sold Out</button>

                        :
                        <>

                        {isInCart ? 
                            <button className="card-medium-live-add-button" onClick={updateCoffeeOnCart}>Update Cart</button>
                        :
                            <button className="card-medium-live-add-button" onClick={addToCart}>Add to Card</button>
                        }
                       
                        <div className="card-medium-live-quantity-container">
                                    <button className="card-live-button-quantity" onClick={()=> decreaseQuantity()}>-</button>
                                    <input className="card-live-input-quantity" value={sackQuantity}></input>
                                    <button className="card-live-button-quantity" onClick={()=> increaseQuantity()}>+</button>
                                </div>
                                </>
                        }
                    </div>}
                        <br/>
                        {window.screen.width>768 &&
                            <div className="medium-card-remove-sample" onClick={()=>removeSample()}>
                                <u>Remove sample</u>
                            </div>
                        }
                        <br/>

                    </div>
                </div>
            </div>
            {window.screen.width<800 &&
            <div className="third-comment-container-live-card">
                    <div className="card-live-comments-container">
                        {reviews.length ? renderComments() 
                        :
                        (isBr() || isCo()) ? 
                        <div>
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <Avatar style={{backgroundColor: 'red'}}>F</Avatar>
                                <p className="card-live-roaster-name">FARMly</p>
                            </div>
                            <div>{isCo() ? 'Los tostadores dejarán aquí sus comentarios sobre este café.' : 'Os torrefadores deixarão comentários sobre esse café aqui!'}</div>
                            <br/>
                        </div>
                        :
                        <div>
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <Avatar style={{backgroundColor: 'red'}}>F</Avatar>
                                <p className="card-live-roaster-name">FARMly Bot</p>
                            </div>
                            <div>Leave your review of this coffee here!</div>
                            <br/>
                        </div>}
                    </div>
                    {(isBr() || isCo()) ?
                        <Fragment></Fragment>
                    :
                    <div className="card-live-make-a-comment-container">
                        <input className="card-live-comment-input" placeholder="Make a comment about this coffee!" value={reviewInfo.comment} setValue={value => setReviewInfo({ ...reviewInfo, comment: value })} onChange={(event) => setReviewInfo({ ...reviewInfo, comment: event.target.value })}></input>
                        <div className="card-live-comment-buttons-container">
                        {hasReview?
                            <button className="card-live-send-comment-button" style={{width: '115px'}} onClick={()=>deleteReview()}>Delete Review</button>
                        :
                            <button className="card-live-send-comment-button" onClick={()=>sendReview()}>Send</button>
                        }
                        </div>
                    </div>
                    }
                </div>
                }
        </div>
    )
}
export default CuppingLiveMediumCard
