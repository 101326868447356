import React from 'react'
import './PrivacyPolicy.css'

import Main from '../templates/Main.jsx'
import Title from '../templates/Title.jsx'
import { isPt, farmlyTexts } from '../../common.js'
import { cookiesTexts } from './policyTexts.js'

const CookiesPolicy = props => {

    return (
        <Main>
            <div className="privacy-policy">
                <Title title={isPt() ? farmlyTexts.cookiesPolicyTitle_pt : farmlyTexts.cookiesPolicyTitle_en} />
                <label className="policy-title">
                    {isPt() ? "1. O Que São Cookies?" : "1. What Are Cookies?"}
                </label>
                <div className="policy-text">
                    {isPt() ? cookiesTexts.section1_pt : cookiesTexts.section1_en}
                </div>
                <label className="policy-title">
                    {isPt() ? "2. Uso De Cookies" : "2. Use Of Cookies"}
                </label>
                <div className="policy-text">
                    {isPt() ? cookiesTexts.section2_pt : cookiesTexts.section2_en}
                </div>
                <label className="policy-title">
                    {isPt() ? "3. Tipo, Propósito E Como Eles Funcionam" : "3. Type, Purpose And How They Work"}
                </label>
                <div className="policy-text">
                    {isPt() ? cookiesTexts.section3_l1_pt : cookiesTexts.section3_l1_en}
                    <br />
                    <br />
                    {isPt() ? cookiesTexts.section3_l2_pt : cookiesTexts.section3_l2_en}
                    <br />
                    <br />
                    {isPt() ? cookiesTexts.section3_l3_pt : cookiesTexts.section3_l3_en}
                    <br />
                    <br />
                    {isPt() ? cookiesTexts.section3_l4_pt : cookiesTexts.section3_l4_en}
                    <br />
                    <br />
                    {isPt() ? cookiesTexts.section3_l5_pt : cookiesTexts.section3_l5_en}
                    <br />
                    <br />
                    {isPt() ? cookiesTexts.section3_l6_pt : cookiesTexts.section3_l6_en}
                    <br />
                    <br />
                    {isPt() ? cookiesTexts.section3_l7_pt : cookiesTexts.section3_l7_en}
                    <br />
                    <br />
                    {isPt() ? cookiesTexts.section3_l8_pt : cookiesTexts.section3_l8_en}
                </div>
                <label className="policy-title">
                    {isPt() ? "4. Mobilidade Da Política De Cookies" : "4. Cookies Policy Mobility"}
                </label>
                <div className="policy-text">
                    {isPt() ? cookiesTexts.section4_l1_pt : cookiesTexts.section4_l1_en}
                    <br />
                    <br />
                    <label className="policy-bold">Google Chrome:</label>
                    <br />
                    <a href="http://support.google.com/chrome/bin/answer.py?hl=pt&answer=95647" className="policy-link">
                        http://support.google.com/chrome/bin/answer.py?hl=pt&answer=95647
                    </a>
                    <br />
                    <label className="policy-bold">Internet Explorer:</label>
                    <br />
                    <a href="http://windows.microsoft.com/pt-pt/windows7/how-to-manage-cookies-in-internet-explorer-9" className="policy-link">
                        http://windows.microsoft.com/pt-pt/windows7/how-to-manage-cookies-in-internet-explorer-9
                    </a>
                    <br />
                    <label className="policy-bold">Mozilla Firefox:</label>
                    <br />
                    <a href="https://support.mozilla.org/pt-PT/kb/Activar%20e%20Desactivar%20cookies" className="policy-link">
                        https://support.mozilla.org/pt-PT/kb/Activar%20e%20Desactivar%20cookies
                    </a>
                    <br />
                    <label className="policy-bold">Apple Safari:</label>
                    <br />
                    <a href="http://support.apple.com/kb/ph5042" className="policy-link">
                        http://support.apple.com/kb/ph5042
                    </a>
                    <br />
                    <br />
                    {isPt() ? cookiesTexts.section4_l2_pt : cookiesTexts.section4_l2_en}
                    <br />
                    <br />
                    {isPt() ? cookiesTexts.section4_l3_pt : cookiesTexts.section4_l3_en}
                </div>
                <label className="policy-title">
                    {isPt() ? "5. Responsabilidade Da FARMly" : "5. FARMly Liability"}
                </label>
                <div className="policy-text">
                    {isPt() ? cookiesTexts.section5_pt : cookiesTexts.section5_en}
                </div>
                <label className="policy-title">
                    {isPt() ? "6. Direito De Atualização Da FARMly" : "6. FARMly's Right To Update"}
                </label>
                <div className="policy-text">
                    {isPt() ? cookiesTexts.section6_pt : cookiesTexts.section6_en}
                </div>
            </div>
        </Main>
    )
}

export default CookiesPolicy