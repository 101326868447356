import React, { Fragment, useState, useEffect, useContext } from "react";
import SliderCupping from "react-slick";
import { isPt, server, acceptedCookies } from '../../../common'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import CuppingIndv from './FarmlyCuppingIndv.jsx'
import FarmlyModal from '../../templates/FarmlyModal'
import './FarmlyCupping.css'
import FloatingButton from '../../templates/FloatingButton'
import MainFarmlyCupping from './MainFarmlyCupping'
import NavCupping from '../../templates/NavCupping'
import CuppingIndvDesktop from "./FarmlyCuppingIndvDesktop";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faReply, faShare, faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons'
import { RotateRight } from "@material-ui/icons";
import ReactGA from 'react-ga'
import UserContext from '../../../context/user/UserContext'


const roletaCompleta = require('../../../assets/roleta-completa.svg')

const FarmlyCupping = (props) => {

    const [coffeesCupping, setCoffeesCupping] = useState(null)
    const [sensoryNote, setSensoryNote] = useState()
    const [sensoryList, setSensoryList] = useState()
    const [currentCoffee, setCurrentCoffee] = useState(0)
    const [notesModal, setNotesModal] = useState(false)
    const [cuppingDone, setCuppingDone] = useState(false)
    const [checkCuppings, setCheckCuppings] = useState(false)
    const [fillAllSpaces, setFillAllSpaces] = useState(false)
    const [goToResults, setGoToResults] = useState({ status: false, coffeeId: null })
    const [auxSensoryList, setAuxSensoryList] = useState(false)
    const userContext = useContext(UserContext)
    const history = useHistory()
    const userId = acceptedCookies() ?
        document.cookie.split(';').filter(item => item.includes('userId'))[0].split('userId=')[1]
        : 10000
    const taster = (userId == 545 || userId == 577) ? 300 : (userId == 558 ? 400 : (userId == 576 ? 100 : 200))


    useEffect(() => {
        axios.get(`${server}/coffeesBySection/${taster + 2}`)
            .then(async coffees => {
                setCoffeesCupping(coffees.data)
            })
    }, [])


    const renderCuppingIndvs = () =>
        coffeesCupping.map((coffee, index) => {
            return <CuppingIndv coffee={coffee} sensoryList={sensoryList} cuppingDone={cuppingDone} checkCuppings={checkCuppings} setCheckCuppings={setCheckCuppings} updateAll={updateAll} setFillAllSpaces={setFillAllSpaces} goToResults={goToResults} taster={taster} />
        })

    const renderCuppingIndvsDesktop1 = () =>
        coffeesCupping.map((coffee, index) => {
            return <CuppingIndvDesktop coffee={coffee} sensoryList={sensoryList} cuppingDone={cuppingDone} checkCuppings={checkCuppings} setCheckCuppings={setCheckCuppings} updateAll={updateAll} setFillAllSpaces={setFillAllSpaces} goToResults={goToResults} auxSensoryList={auxSensoryList} notesModal={notesModal} taster={taster} />
        })

    const setNotesModalFunction = () => {
        axios.get(`${server}/coffees/?id=${coffeesCupping[currentCoffee].id}`)
            .then(res => {
                if (res.data[0].sensoryNotes !== null && res.data[0].sensoryNotes !== "") setSensoryList(res.data[0].sensoryNotes.split(','))
                else setSensoryList([])
                setNotesModal(true)
            })
    }

    const addToSensoryList = async () => {
        let aux = [...sensoryList]
        aux.push(sensoryNote)
        await setSensoryList(aux)
        setSensoryNote("")
    }

    const addSensoryNote = () => {
        axios.get(`${server}/coffees/?id=${coffeesCupping[currentCoffee].id}`)
            .then(async res => {
                const reqBody = {
                    ...res.data[0],
                    sensoryNotes: sensoryList.join(',')
                }
                try {
                    await axios.patch(`${server}/coffees`, reqBody)
                    setSensoryNote([null])
                    setNotesModal(false)
                } catch (err) {
                    console.log(err)
                }
            })
        setAuxSensoryList(!auxSensoryList)
    }

    const updateAll = () => {
        let aux = 0
        coffeesCupping.forEach((coffee) => {
            if (coffee.user_id !== 2000) {
                history.push('/cuppingSelect')
            }
            aux++
            axios.get(`${server}/coffees/?id=${coffee.id}`)
                .then(res => {
                    const reqBody = {
                        ...res.data[0]
                    }
                    axios.get(`${server}/getMainCupping/${coffee.id}`)
                        .then(res => {
                            reqBody.aroma = res.data[0].aroma = null ? 0 : res.data[0].aroma
                            reqBody.acidity = res.data[0].acidity = null ? 0 : res.data[0].acidity
                            reqBody.aftertaste = res.data[0].completion = null ? 0 : res.data[0].completion
                            reqBody.body = res.data[0].body = null ? 0 : res.data[0].body
                            reqBody.balance = res.data[0].balance = null ? 0 : res.data[0].balance
                            reqBody.flavour = res.data[0].flavor = null ? 0 : res.data[0].flavor
                            reqBody.sampleApprove = "approved"
                            try {
                                axios.patch(`${server}/coffees`, reqBody)
                                if (aux == coffeesCupping.length) {
                                    history.push('/cuppingSelect')
                                }
                            } catch (err) {
                                console.log(err)
                            }
                        })

                })
        })
    }

    const renderSensoryCard = () =>
        sensoryList.map((note) => {
            let random = Math.floor(Math.random() * 3)
            return (
                <Fragment>
                    <div className={(random == 0) ? 'sensory-red' : ((random == 1 ? 'sensory-lime' : 'sensory-orange'))}>{note}</div>
                    &nbsp;
                    <br />
                </Fragment>
            )
        })

    const info = {
        dots: false,
        infinite: true,
        draggable: true,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
    };
    const settingsCupping = {
        arrows: false,
        dots: true,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        focusOnSelect: true,
        pauseOnHover: true,
        // beforeChange: (current, next) => this.setState({ activeSlide: next }),
        afterChange: current => {
            let dot_width = document.getElementsByClassName('slick-active')[document.getElementsByClassName('slick-active').length - 1].offsetWidth;
            document.getElementsByClassName('slick-dots')[0].scroll({ left: (current) * dot_width, top: 0, behavior: 'smooth' })
            setCurrentCoffee(current)
        },
        customPaging: function (i) {
            return (
                <a className="each-dot">
                    <div>{coffeesCupping[i].farmCode}</div>
                </a>
            );
        },

    };

    const settingsCuppingDesktop = {
        dots: true,
        infinite: false,
        draggable: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        focusOnSelect: true,
        pauseOnHover: true,
        arrows: true,
        dotsClass: 'slick-dots-desktop',
        // beforeChange: (current, next) => this.setState({ activeSlide: next }),
        afterChange: current => {
            // scrollDot(current);
            setCurrentCoffee(current)
        },
        customPaging: function (i) {
            return (
                <a className="each-dot-desktop" style={{ margin: '2px' }}>
                    Café:{coffeesCupping[i].farmCode}
                </a>
            );
        },
    };

    var current_rotation = 0;

    const rotateRightWheel = () => {
        current_rotation += 30;
        document.getElementById("rotate-wheel").style.transform = 'rotate(' + current_rotation + 'deg)';
    }

    const rotateLeftWheel = () => {
        current_rotation -= 30;
        document.getElementById("rotate-wheel").style.transform = 'rotate(' + current_rotation + 'deg)';
    }

    //REPORTS ANALYTICS
    useEffect(() => {
        ReactGA.event({
            category: 'Cupping',
            action: `A user visited the Cupping Page`
        })
    }, [])


    return (
        <>
            {fillAllSpaces && <FarmlyModal closeModal={() => setFillAllSpaces(false)} style={{ maxHeight: 300, maxWidth: 400 }}>
                <div className='cupping-modal-wrap'>
                    <label><b>{isPt() ? 'Você não preencheu todos o campos!' : 'No ha rellenado todos los campos.'}</b></label>
                    <br />
                    <label>{isPt() ? 'Alguns campos não foram preenchidos! Você gostaria de continuar mesmo assim ou gostaria de voltar para revisar o cupping?' : 'Algunos campos no se han rellenado. ¿Le gustaría continuar de todas formas o le gustaría volver para revisar las ventosas?'}</label>
                    <br />
                    <br />
                    <div className="buttons-display-cupping-desktop">
                        <button className="go-back-button" onClick={() => setFillAllSpaces(false)}>{isPt() ? 'Voltar' : 'Volver'}</button>
                        <button className="go-foward-button" onClick={() => setGoToResults({ status: true, coffeeId: coffeesCupping[currentCoffee].coffee_id })}>Continuar</button>
                    </div>
                </div>
            </FarmlyModal>
            }
            {notesModal &&
                <div className="black-background-modal-cupping">
                    <button className="notes-button" onClick={() => {
                        addSensoryNote()
                        setNotesModal(false)
                        window.location.reload();
                    }}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </button>
                    <FontAwesomeIcon icon={window.screen.width > 500 ? faShare : faArrowUp} className="rotate-right-wheel-button" onClick={() => rotateRightWheel()} />
                    <img src={roletaCompleta} id="rotate-wheel" className="roleta-completa-sensory-notes-modal"></img>
                    <FontAwesomeIcon icon={window.screen.width > 500 ? faReply : faArrowDown} className="rotate-left-wheel-button" onClick={() => rotateLeftWheel()} />
                    <div className="add-sensory-notes-div">
                        <div className="sensory-note-input-container">
                            <input className="input-add-sensory-notes" value={sensoryNote} onChange={(event) => setSensoryNote(event.target.value)} placeholder="sensory note you would like to add"></input>
                            <button className="notes-button-add" onClick={() => addToSensoryList()}>+</button>
                        </div>
                        <div className='sensory-cards-main'>
                            {renderSensoryCard()}
                        </div>
                    </div>
                </div>
            }

            {window.screen.width > 768 ?
                <MainFarmlyCupping noBanner style={{ overflowY: 'hidden' }}>
                    <div className="cupping-holder">
                        <div style={{ display: 'flex', flexDirection: 'row', overflowY: 'hidden' }}>
                            <label style={{ fontSize: 'xxx-large', fontWeight: '600', paddingLeft: '100px' }}>Cupping</label>
                            <FloatingButton setNotesModalFunction={setNotesModalFunction} />
                        </div>
                        {coffeesCupping && coffeesCupping.length > 0 &&
                            <SliderCupping {...settingsCuppingDesktop}>
                                {renderCuppingIndvsDesktop1()}
                            </SliderCupping>
                        }

                    </div>
                </MainFarmlyCupping>
                :
                <MainFarmlyCupping noBanner style={{ overflowY: 'hidden' }}>
                    <div className="indv-container" >
                        <div className="cupping-holder">
                            <FloatingButton setNotesModalFunction={setNotesModalFunction} />
                            <SliderCupping {...settingsCupping}>
                                {coffeesCupping && renderCuppingIndvs()}
                            </SliderCupping>
                        </div>
                    </div>
                </MainFarmlyCupping>
            }
        </>
    );
}

export default FarmlyCupping 