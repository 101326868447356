import styled from 'styled-components';

export const SignUpContainer = styled.div`
`
export const SignUpCompoundRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    @media (max-width: 650px) {
        flex-direction: column;
    }
`
export const InputInformationData = styled.div`
    margin-left: 7px;
    height: 30px;
    width: 25vw;
    border: 3px solid var(--farmly-elm);
    border-radius: 10px;
    padding-right: 20px; 
    padding-left: 20px;
    border-width: thin;
    margin-top: 5px;
    @media (max-width: 650px) {
        width: 80%;
    }
`
export const SinupFieldInput = styled.div`
    border-radius: 14px;
    border-color: gray !important;
    width: 350px;
    @media (max-width: 400px) {
        width: 285px;
    }
`
export const SignupCompoundButtons = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 15px;
    @media (max-width: 650px) {
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`
export const SignupBackButton = styled.div`
    height: 35px;
    width: 20vw;
    border-radius: 5px;
    color: #ffffff;
    background-color: #C85B56;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 650px) {
        margin-bottom: 5px;    
    }
`
export const SignupNextButton = styled.div`
    height: 35px;
    width: 20vw;
    border-radius: 5px;
    display: flex;
    cursor: pointer;
    color: #ffffff;
    background-color: #1B625E;
    justify-content: center;
    align-items: center;
`
export const SelectItems = styled.div`
    display: flex;
    flex-direction: row;
`