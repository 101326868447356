import React, { useState, useContext } from 'react'
import './Login.css'

import { Link, useHistory } from 'react-router-dom'
import axios from 'axios'
import Main from '../templates/Main.jsx'
import PasswordRecovery from '../passwordRecovery/PasswordRecovery.jsx'
import { server, language, isPt, farmlyTexts, acceptedCookies, isBr, isCo } from '../../common'
import Title from '../templates/Title.jsx'
import UserContext from '../../context/user/UserContext.js'
import CartContext from '../../context/cart/CartContext.js'
import ReactGA from 'react-ga'
import { GoogleLogin } from 'react-google-login'
import FarmlyModal from '../templates/FarmlyModal.jsx'
import Cookies from 'js-cookie'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGoogle } from '@fortawesome/free-brands-svg-icons'
import ReceiveQuery from 'modules/Insterticial/hooks/ReceiveQuery';

const Login = props => {

    const titleHeaderLanguage = {'pt':'Acessar','en':'Login','es':'Acceso'}[language]

    const state = (props.location.state || null)
    const userContext = useContext(UserContext)
    const cartContext = useContext(CartContext)
    const history = useHistory()

    const{
        emailQuery,
        returnUrlQuery
    } = ReceiveQuery()
    
    const [info, setInfo] = useState({
        email: emailQuery? emailQuery : '',
        password: ''
    })
    const [failureSignInGoogle, setModalSignin]= useState(false)
    const [recoveryActive, setRecoveryActive] = useState(false)
    const [emailFailure, setEmailFailure] = useState(false)
    const [authFailure, setAuthFailure] = useState(false)
    const [serverFailure, setServerFailure] = useState(false)
    const [pleaseTypePassword, setPleaseTypePassword] = useState(false)
    const [signupGoogle, setSignupGoogle]=useState(false)
    const toggleRecovery = () => setRecoveryActive(!recoveryActive)
    const analyticsInfo = () => {
        if(isPt()){
            ReactGA.event({
                category: 'New potencial Producer',
                action: 'Producer in potencial started sign up process'
            })
        } else {
            ReactGA.event({
                category: 'New potencial Roaster',
                action: 'Roaster in potencial started sign up process'
            })
        }
    }
    const login = async () => {
        try {   
        setEmailFailure(false)
        setAuthFailure(false)
        const payload = await axios.post(`${server}/signin`, { email: info.email, password: info.password})
       
                const cookie = acceptedCookies() ?
                    document.cookie : ""
                userContext.userLogin({
                    isProd: payload.data.isProd,
                    jwt: payload.data.jwt,
                    id: payload.data.id,
                    main_id: payload.data.main_id,
                    bid: payload.data.bid !==undefined ? payload.data.bid : "0" 
                })            

                if (cookie.includes('cartList') && !cartContext.cookiesCartIsEmpty) {
                    cartContext.setCartToServer(payload.data.jwt)
                } else if (!payload.data.isProd) {
                    cartContext.getCartFromServer(payload.data.id)
                }

           

                ReactGA.event({
                    category: 'Login',
                    action: 'User Logged In'
                })

                if (acceptedCookies()) {
                    Cookies.set('jwt', payload.data.jwt, {expires: payload.data.exp, path: '/'})
                    Cookies.set('isProd', payload.data.isProd, {expires: payload.data.exp, path: '/'})
                    Cookies.set('userId', payload.data.id, {expires: payload.data.exp, path: '/'})
                    Cookies.set('main_id', payload.data.main_id, {expires: payload.data.exp, path: '/'})
                    Cookies.set('bid', payload.data.bid !==undefined ? payload.data.bid : "0" , {expires: payload.data.exp, path: '/'})

                }
                if(emailQuery){
                    window.location.assign(`${returnUrlQuery}`)
                }else{
                    let next = null
                    if (typeof state === "string") next = state
                    history.push({
                        pathname: next || (payload.data.isProd ? '/profile' : '/offerlist'),
                        state: { coffeeSection: true }
                    })
                    history.go()
                }
        } catch(err) {
                const status = err.response ? err.response.data.status : 'server-failure'
                if (status === 'email-not-found') {
                    setEmailFailure(true)
                } else if (status === 'auth-failed' || status === 'incomplete') {
                    setAuthFailure(true)
                } else if (status === 'server-failure') {
                    setServerFailure(true)
                } else {
                    setAuthFailure(true)
                    console.log(err)
                }
            }
    }

    const googleLogin = (email, name, password) => {
        setEmailFailure(false)
        setAuthFailure(false)
        
        if(password === '') {
            setPleaseTypePassword(true)
            return
        }
        axios.post(`${server}/signin`, {
            email: email,
            password: password
        })
            .then(payload => {
                const cookie = acceptedCookies() ?
                    document.cookie : ""
                userContext.userLogin({
                    isProd: payload.data.isProd,
                    jwt: payload.data.jwt,
                    id: payload.data.id,
                    main_id: payload.data.main_id,
                    bid: payload.data.bid !==undefined ? payload.data.bid : "0" 
                })
                if (cookie.includes('cartList') && !cartContext.cookiesCartIsEmpty) {
                    cartContext.setCartToServer(payload.data.jwt)
                } else if (!payload.data.isProd) {
                    cartContext.getCartFromServer(payload.data.id)
                }

                ReactGA.event({
                    category: 'Login',
                    action: 'User Logged In'
                })
                if (acceptedCookies()) {
                    Cookies.set('jwt', payload.data.jwt, {expires: payload.data.exp, path: '/'})
                    Cookies.set('isProd', payload.data.isProd, {expires: payload.data.exp, path: '/'})
                    Cookies.set('userId', payload.data.id, {expires: payload.data.exp, path: '/'})
                    Cookies.set('main_id', payload.data.main_id, {expires: payload.data.exp, path: '/'})
                    Cookies.set('bid', payload.data.bid !==undefined ? payload.data.bid : "0" , {expires: payload.data.exp, path: '/'})
                }
                if(emailQuery){
                    window.location.assign(`${returnUrlQuery}`)
                }else{
                    let next = null
                    if (typeof state === "string") next = state
                    history.push({
                        pathname: next || (payload.data.isProd ? '/profile' : '/coffees'),
                        state: { coffeeSection: true }
                    })
                    history.go()
                }
            }) 
            .catch(err => {
                const status = err.response ? err.response.data.status : 'server-failure'
                if (status === 'email-not-found') {                    
                    setModalSignin(true)
                    console.log('Hey1')
                    if(setSignupGoogle){
                        history.push({
                            pathname: '/signupGoogle',
                            state: {userData : {
                                email: email, 
                                name: name, 
                                password: password,
                                confirmPassword: password
                
                            }}
                        })
                        history.go()
                    }
                } else if (status === 'auth-failed' || status === 'incomplete') {
                    setAuthFailure(true)
                    setModalSignin(true)
                } else if (status === 'server-failure') {
                    setServerFailure(true)
                    
                }
            })
    }

    const responseGoogle = (response) => {
        googleLogin(response.profileObj.email, response.profileObj.name, response.profileObj.googleId,response.profileObj.password)
    }

    const checkUserLoggedIn = () => {
        if (userContext.user){
            history.push('/profile')
        }
    }

    const renderForm = () => {
        checkUserLoggedIn()

        return (
            <>
            <div className="login-container">
                <div className="login-form">
                    <Title title={isPt() || isCo()? farmlyTexts.loginTitle_pt : farmlyTexts.loginTitle_en} showIcon uppercase ml_20 />
                    <div className="login-row top-row">
                        <div className="login-form-group">
                            <label htmlFor='login-email' id='login-field-label' >{isPt() ? farmlyTexts.loginEmail_pt : farmlyTexts.loginEmail_en}</label>
                            <input type="text" className="login-form-control" id="login-email"
                                name='email' onChange={e => setInfo({ ...info, email: e.target.value })}
                                value={info.email} placeholder={isPt() ? farmlyTexts.loginEmail_pt : farmlyTexts.loginEmail_en} />
                            {emailFailure && <label className="login-fail-label">{isPt() ? farmlyTexts.loginEmailNotFound_pt : (isCo() ? `Email no registrado` : farmlyTexts.loginEmailNotFound_en)}</label>}
                            {serverFailure && <label className="login-fail-label">{isPt() ? farmlyTexts.loginServerFailure_pt : (isCo() ? 'Fallo del servidor. Por favor, inténtelo de nuevo en unos minutos, o envíenos un mensaje describiendo el problema!' :farmlyTexts.loginServerFailure_en)}</label>}
                        </div>
                    </div>
                    <div className="login-row">
                        <div className="login-form-group">
                            <div id="password-label-container">
                                <label id='login-field-label' >{isPt() ? farmlyTexts.loginPassword_pt : (isCo() ? `Contraseña` : farmlyTexts.loginPassword_en)}</label>
                            </div>
                            <input type="password" className="login-form-control"
                                onKeyPress={(e)=>{e.key=== 'Enter' && login()}}
                                name='password' onChange={e => setInfo({ ...info, password: e.target.value })}
                                value={info.password} placeholder={isPt() ? farmlyTexts.loginPassword_pt : (isCo() ? `Contraseña` : farmlyTexts.loginPassword_en)} />
                            {authFailure && <label className="login-fail-label">{isPt() ? farmlyTexts.loginInvalidEmailPassword_pt : (isCo() ? `email o contraseña incorrectos.` :farmlyTexts.loginInvalidEmailPassword_en)}</label>}
                            {pleaseTypePassword && <label className="login-fail-label">{isPt() ? 'Digite sua senha para prosseguir.' : (isCo() ? `Introduzca su contraseña para continuar.` : 'Enter your password to proceed.')}</label>}
                            <div id="password-label-container">
                                <label id="password-recovery-label" onClick={toggleRecovery} >
                                    {isPt() ? farmlyTexts.loginForgotPassword_pt : (isCo() ? '¿Olvidó su contraseña?' : farmlyTexts.loginForgotPassword_en)}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="login-row">
                        <div className="login-button-container">
                            <button className="login-button" onClick={login} style={{ marginBottom: '8px'}}>
                                {isPt() || isCo() ? farmlyTexts.loginSignin_pt : farmlyTexts.loginSignin_en}
                            </button>
                        </div>
                        <label className="signup-field-label">{isPt() ? 'Ou' : (isCo() ? 'O' : 'Or')}</label>
                      
                        <div class="login-button-google" style={{marginTop: '3px'}}>
                            <GoogleLogin
                                clientId="503177513332-b2g42k0864opttrsn7v6v8e2o3imvse5.apps.googleusercontent.com"
                                buttonText={isPt() ? 'Entrar com Google' : (isCo() ? `Registro en Google` : "Log in with Google")}
                                onSuccess={responseGoogle}
                                onFailure={responseGoogle}
                                cookiePolicy={'single_host_origin'}
                            />
                        </div>
                    </div>
                    <div className="login-row">
                        <div id="login-new-account">
                            <label id='new-account-label'>
                                {isPt() ? farmlyTexts.loginNewAccount_pt : (isCo() ? `¿No tienes una cuenta? ` : farmlyTexts.loginNewAccount_en)}
                            </label>
                            <Link id='new-account-link' to={{pathname: isBr() ? '/signup' : '/signup', state: {state}}} onClick={analyticsInfo}>
                                {isPt() ? 'Clique aqui' : (isCo() ? 'Inscríbete!' : farmlyTexts.loginSignUp_en)} 
                            </Link>
                            {/* <Link id='new-account-link' to={{pathname: isBr() ? '/signupInvite' : '/signup', state: {state}}} onClick={analyticsInfo}>
                                {isPt() ? 'Clique aqui' : (isCo() ? 'Inscríbete!' : farmlyTexts.loginSignUp_en)} 
                            </Link> */}
                        </div>
                    </div>
                </div>
            </div>
            {failureSignInGoogle && <FarmlyModal closeModal={() => setModalSignin(false)}>
            <div>
                        <label style={{bottom:'2vh'}}>
                        {isPt() ? 'Email não cadastrado. ' : (isCo() ? `Correo electrónico no registrado` : 'Email not registered')}
                            </label>
                        <button className="modalGoogle-button" onClick={()=>setSignupGoogle(true)} style={{ marginTop: '14px'}}>
                        {isPt() ? 'Cadastrar-se com esse email' : (isCo() ? `Inscríbase con este correo electrónico ` : 'Sign up with this email')}
                        </button>
                        <label className="signup-field-label" style={{ marginTop: '14px'}}>{isPt() ? 'Ou' : (isCo() ? 'O' : 'Or')}</label>
                        <button className="modalGoogle-button" onClick={()=>setModalSignin(false)} style={{ marginTop: '14px'}}>
                                {isPt() ? 'Tentar com outro email' : (isCo() ? `Inténtelo con otro correo electrónico ` : 'Try with another email')}
                        </button>
                        
            </div>
            </FarmlyModal> }
            </>
        )
    }

    return (
        <Main titleHeader={titleHeaderLanguage}>
            {renderForm()}
            {recoveryActive && <PasswordRecovery toggleRecovery={toggleRecovery} email={info.email} />}
        </Main>
    )
}

export default Login