import React, { useReducer, useEffect, useState, useContext } from 'react'
import CartContext from './CartContext.js'
import CartReducer from './CartReducer.js'

import {
    ADD_TO_CART,
    REMOVE_FROM_CART,
    LOAD_CART,
    CLEAR_CART,
    PRODUCER_LOGIN,
    UPDATE_CART,
} from '../types.js'

import axios from 'axios'
import { server, acceptedCookies } from '../../common.js'

const CartState = props => {
    const [timer, setTimer] = useState({
        minutes: 0,
        seconds: 0,
        current: false
    })
    const [controlTimer, setControlTimer] = useState({
        control: false,
        zero: false
    })
    const splits = acceptedCookies() ?
        document.cookie.split(';') : []
    const jwt = splits.filter(item => item.includes('jwt'))[0] ?
        splits.filter(item => item.includes('jwt'))[0].split('=')[1] : false
	const isProd = splits.filter((item) => item.includes("isProd"))[0];
    

    const initialState = {
        cartList: []
    }

    const [state, dispatch] = useReducer(CartReducer, initialState)
    const listToString = list => list.reduce((prev, item) => `${item.id}:${item.quantity},${prev}`, "")
    const stringToList = str => str ? str.split(',').filter(el => el)
        .map(el => {
            const splits = el.split(':')
            return { id: parseInt(splits[0]), quantity: parseInt(splits[1]), hour: parseInt(splits[2]), minutes: parseInt(splits[3]) }
        })
        : []

    useEffect(() => {
        if (!window.location.href.includes('checkout')) {
            const cookie = acceptedCookies() ? document.cookie : ""
            if (cookie.includes('userId=') && !isProd) {
                const id = cookie.split(';').filter(el => el.includes('userId='))[0].split('=')[1]
                getCartFromServer(id)
            } else if (cookie.includes('cartList')) {
                const cartString = splits.filter(item => item.includes('cartList'))[0].split('=')[1]
                const list = stringToList(cartString)
                dispatch({ type: LOAD_CART, payload: list })
            }
        }
    }, [])

    const getCartFromServer = id => {
        axios.get(`${server}/shoppingCart/${id}`)
            .then(res => {
                const cartString = res.data.shoppingCart || ""
                const list = stringToList(cartString)
                dispatch({ type: LOAD_CART, payload: list })
            }).catch(err =>{
                console.log(err);
            })
    }

    const setCartToServer = jwt => {
        const cartString = splits.filter(item => item.includes('cartList'))[0].split('=')[1]

        axios.patch(`${server}/user`, {
            shoppingCart: cartString,
            jwt
        })
    }

    const addToCart = (id, quantity) => {
        const list = [...state.cartList]
        var d = new Date()
        if (list.filter(el => el.id === id).length === 0) {
            list.push({
                id,
                quantity,
                hour: d.getHours(),
                minutes: d.getMinutes()
            })
        }
        const cartString = listToString(list)
        if (jwt) {
            axios.patch(`${server}/user`, {
                shoppingCart: cartString,
                jwt
            })
        } else if (acceptedCookies()) {
            document.cookie = `cartList=${cartString}; max-age=${60 * 60 * 24}; path=/`
        }
        if (timer.current == false) {
            setTimer({ ...timer, minutes: 15, seconds: 0, current: true })
        } else {
            setControlTimer({ control: true, zero: false })
        }
        return dispatch({ type: ADD_TO_CART, payload: list })


    }

    const updateCoffeeOnCart = (id, newQuantity) => {
        const list = [...state.cartList]
        list.map((item) => {
            if (item.id == id) {
                item.quantity = newQuantity;
            }
        })
        const cartString = listToString(list)
        if (jwt) {
            axios.patch(`${server}/user`, {
                shoppingCart: cartString,
                jwt
            })
        } else if (acceptedCookies()) {
            document.cookie = `cartList=${cartString}; max-age=${60 * 60 * 24}; path=/`
        }
        if (timer.current == false) {
            setTimer({ ...timer, minutes: 15, seconds: 0, current: true })
        } else {
            setControlTimer({ control: true, zero: false })
        }
        return dispatch({ type: UPDATE_CART, payload: list })
    }

    const removeFromCart = id => {
        const list = state.cartList.filter(item => item.id != id)
        const cartString = listToString(list)
        if (jwt) {
            axios.patch(`${server}/user`, {
                shoppingCart: cartString,
                jwt
            })
        } else if (acceptedCookies()) {
            document.cookie = `cartList=${cartString}; max-age=${60 * 60 * 24}; path=/`
        }
        return dispatch({ type: REMOVE_FROM_CART, payload: list })
    }

    const clearCart = () => dispatch({ type: CLEAR_CART })

    const updateCart = cartList => {
        const cartString = listToString(cartList)
        if (jwt) {
            axios.patch(`${server}/user`, {
                shoppingCart: cartString,
                jwt
            })
        } else if (acceptedCookies()) {
            document.cookie = `cartList=${cartString}; max-age=${60 * 60 * 24}; path=/`
        }
        return dispatch({ type: LOAD_CART, payload: cartList })
    }

    const noMoreTime = () => {
        if (state.cartList) {
            state.cartList.map(item => {
                axios.get(`${server}/coffee/${item.id}`)
                    .then(coffee => {
                        axios.patch(`${server}/coffeeSackRemain/${item.id}`, {
                            sackQuantity: -item.quantity,
                            sackRemain: coffee.data.sackRemain
                        })
                    })
                clearCart()
            })
        }
    }


    const cookiesCartIsEmpty = () => {
        const cartString = splits.filter(item => item.includes('cartList'))[0].split('=')[1]
        return cartString.length < 3
    }

    const producerLogin = () => dispatch({ type: PRODUCER_LOGIN })

    return <CartContext.Provider
        value={{
            addToCart,
            removeFromCart,
            getCartFromServer,
            setCartToServer,
            clearCart,
            updateCart,
            listToString,
            cookiesCartIsEmpty,
            producerLogin,
            updateCoffeeOnCart,
            cartList: state.cartList,
            timer: timer,
            setTimer: setTimer,
            controlTimer: controlTimer,
            setControlTimer: setControlTimer,
            noMoreTime: noMoreTime
        }}
    >
        {props.children}
    </CartContext.Provider>
}

export default CartState