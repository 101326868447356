import React, { FunctionComponent } from 'react'
import { Link } from "react-router-dom";

import { SampleCartItem, CoffeeListContainer, CoffeeListBox } from './style';
import TitleLabel from '../TitleLabel';
import CartModel from 'modules/SampleCart/models/cartModel';
import CoffeeModel from 'modules/SampleCart/models/coffeeModel'
interface CoffeeListProps {
    sampleCart: CartModel,
    removeFromSampleCart: (coffee: CoffeeModel) => void
}
const CoffeeList: FunctionComponent<CoffeeListProps> = (props) => {
    let samples = props.sampleCart.sample_list as any
    const samplesNumber = samples.length
    let selectedSamples = []
    for(let i = 0; i < samplesNumber; i++){
        if(samples[i][0]){
            selectedSamples.push(samples[i][0])
        } else {
            selectedSamples.push(samples[i])

        }
    }
        
    return (
        <CoffeeListContainer>
            <TitleLabel title="Cart" />
            <CoffeeListBox>
                {selectedSamples && selectedSamples.map((coffee: CoffeeModel,  i) => {
                        return (
                            <SampleCartItem>
                                <Link to={`/coffee/${coffee.id}`}>
                                    {coffee.farmCode === null ?
                                        "Coffee ID: " + coffee.id :
                                        "Farmly Code: " + coffee.farmCode}
                                </Link>
                                <button onClick={(e) => { props.removeFromSampleCart(coffee) }}>
                                    X
                                </button>
                            </SampleCartItem>
                        )
                   
                })}
            </CoffeeListBox>
        </CoffeeListContainer>
    );
}
export default CoffeeList;

