import React, { useState } from 'react'

import './Datalist.css'

// uso: <Datalist options={["a", "b", "c"]} 
// userSelection={state} setUserSelection={setState} />

const DataList = (props) => {

    const { componentName, dropdownStyle, listName, buttonStyle, options, userSelection, setUserSelection } = props

    const [selected, setSelected] = useState([])

    const addSelected = () => {

        // spread selection => usa uma copia para atribuição safe
        // evita problemas de renderização no futuro
        const oldList = [...userSelection]
        if (selected !== '' && !(userSelection.indexOf(selected) >= 0)) {
            oldList.push(selected)
            setUserSelection(oldList)
        }
    }

    const renderOpts = options =>
        options.map((opt) => {
            return <option key={opt.name} value={opt.name}>{opt.name}</option>
        })

    return (
        <>
            {/* <input type="text" name={componentName} className={dropdownStyle} list={listName} multiple onChange={(e) => { setSelected(e.target.value) }} />
            <datalist id={listName}>
                {renderOpts(options)}
            </datalist>
            <input type="button" value="+" className={buttonStyle} onClick={() => addSelected()} /> */}

            <div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <input type="text" name={componentName} className={dropdownStyle} list={listName} multiple onChange={(e) => { setSelected(e.target.value) }} />
                    <button type="button" value="+" className={buttonStyle} onClick={() => addSelected()} >+</button>
                </div>
                <datalist id={listName}>
                    {renderOpts(options)}
                </datalist>
            </div>
        </>
    )
}

DataList.defaultProps = {
    buttonStyle: "add-button",
    dropdownStyle: "sensory-datalist",
    componentName: "farmly-list",
    listName: "sensory-list"
}

export default DataList