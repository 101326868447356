import { useState } from 'react';
import backend from '../../../../lib/backend'

const useLead = () => {

    const [roasterData, setRoasterData] = useState({
        name: '',
        from: '',
        email: '',
        phone_number: ''
    });

    const [registered, setRegisterd] = useState(false);

    const setData = (value, category) => {

        switch (category) {

            case 'Name':
            setRoasterData({...roasterData, name: value})
            break;
            case 'Roastery':
            setRoasterData({...roasterData, from: value})
            break;
            case 'Email':
            setRoasterData({...roasterData, email: value})
            break;
            case 'Phone':
            setRoasterData({...roasterData, phone_number: value})
            break;

        }
    }

    const sendData = () => {

        backend.post(`/newRegister` ,{
            ...roasterData
        })
            .then(_ => {
                setRegisterd(true)
            })
            .catch(err => {
                console.log(err);
            })
    }

    return { sendData, setData, registered }
}

export default useLead;