import React, { useEffect, useState } from "react";
import { CoffeeFilterContainer, SelectCoffeeFilter, CoffeeFilterLabel } from "./styles";
import tasterOptions from "utils/TastersData"
import sectionOptions from "utils/SectionData"
import {server} from "../../../../common.js"
import axios from 'axios'
import { CoffeeInfoSelect, DeleteShippingButton } from "../CoffeesLineMain/styles";
import { deleteShipping } from "modules/CoffeeManagement/hooks/useDeleteShipping";

interface QueryParams {
  shipment : number,
  taster : number,
  section : string
}

interface FilterCoffeesProps {
  queryParams : QueryParams,
  setQueryParams : React.Dispatch<React.SetStateAction<QueryParams>>
}

export default function FilterCoffees(props: FilterCoffeesProps) {
  let defaultShipmentValue = [{
    label: "",
    value: 0
  }]
  const { queryParams, setQueryParams } = props;
  const [curDate, setCurDate] = useState([])
  const [shipments,setShipments] = useState([{label: "", value: 0}]);

  useEffect(()=>{
    const getShippingData = async () => {
      try {
        const shipments = await axios.get(`${server}/getShippingData`)
        const sortedShipments = shipments.data.sort((a:any, b:any)=>{return b.shipping_id - a.shipping_id})
        const shipmentOptions = sortedShipments.map((data: any)=>{
          const option = {
            label: data.departure_date?.split("-")[2].split("T")[0]+"/"+data.departure_date?.split("-")[1]+"/"+data.departure_date?.split("-")[0],
            value: data.shipping_id
          }
          return option
      })
      setShipments(shipmentOptions)
      } catch (error) {
        alert('Acontenceu um erro')
        console.error(error)
      }
    }
    
    getShippingData()
  },[]);


  return (
    <>
      {shipments.length > 1 && 
      <CoffeeFilterContainer>
        <CoffeeFilterLabel>Embarque</CoffeeFilterLabel>
        <SelectCoffeeFilter
          defaultValue={shipments[0]}
          options={shipments}
          onChange={(e:any)=> setQueryParams({...queryParams, shipment: e.value})}
        ></SelectCoffeeFilter>
      </CoffeeFilterContainer>
}
      <CoffeeFilterContainer>
      <CoffeeFilterLabel>Provador</CoffeeFilterLabel>
      <SelectCoffeeFilter
        defaultValue={tasterOptions[4]}
        options={tasterOptions}
        onChange={(e:any)=> setQueryParams({...queryParams, taster: e.value})}
      ></SelectCoffeeFilter>
      </CoffeeFilterContainer>

      <CoffeeFilterContainer>
      <CoffeeFilterLabel>Etapa</CoffeeFilterLabel>
      <SelectCoffeeFilter
        defaultValue={sectionOptions[3]}
        options={sectionOptions}
        onChange={(e:any)=> setQueryParams({...queryParams, section: e.value})}
        
      ></SelectCoffeeFilter>
      </CoffeeFilterContainer>
      {/* <CoffeeFilterContainer>
      <CoffeeFilterLabel>Excluir Embarque</CoffeeFilterLabel>
      <DeleteShippingButton type="button" onClick={() => {
        deleteShipping(queryParams.shipment)
        // setCurDate(shipments[shipments.findIndex((c: any) => c.value === queryParams.shipment)])
        // console.log("Olha esssa porra!", curDate)
      }}>Excluir Embarque {curDate}</DeleteShippingButton>
      </CoffeeFilterContainer> */}
    </>
  );
}
