import React, { Fragment } from 'react'
import './Roasters.css'
import CoffeeCard from '../../coffees/CoffeeCard.jsx'
import { isPt, farmlyTexts } from '../../../common'
import { Link } from 'react-router-dom'



const coffeePicture1 = require('../../../assets/produtor1.jpg')

const RoasterFavorites = (props) => {

    const { favorites } = props

    const renderCards = favorites =>
        favorites.map((coffee) => {
            coffee.image = coffeePicture1
            return <CoffeeCard key={coffee.id} coffee={coffee} farmCode={coffee.farmCode}
                farmName={coffee.farmName} score={coffee.score} sensoryNotesEn={coffee.sensory_notes_en}
                producerId={coffee.producerId} sensoryNotes={coffee.sensoryNotes} imageUrl={coffee.imageUrl}
                variety={coffee.variety} process={coffee.process} coffeeId={coffee.id} totalKgPrice={coffee.sackPrice} width='25vw' background={coffee.image} />
        })


    return (
        <div className="roaster-profile-container">
            <div className="favorites-container">
                {favorites.length === 0 &&
                    <div>
                        <div className="empty-page">{isPt() ? farmlyTexts.roasterFavoritesMessage_pt : farmlyTexts.roasterFavoritesMessage_en}</div>
                        <br></br>
                        <Link to='/coffees'>
                            <div className="roaster-profile-button">{isPt() ? farmlyTexts.addFavorites_pt : farmlyTexts.addFavorites_en}</div>
                        </Link>
                    </div>
                }
                {favorites.length > 0 && renderCards(favorites)}
            </div>
        </div>
    )
}

export default RoasterFavorites