import React, { FunctionComponent } from 'react'
import {
    AlreadyHaveAccountDiv, AlreadyHaveAccountLabel, BasicInfoSampleCartContainer,
    CarouselSamples, CarouselWithFewSamples, DisplaySamplesWithoutCarousel,
    EmailProfileLabel,
    LoginButtonAccess, LoginFailLabel, PasswordProfileLabel, ProducerProfileItem,
    ProducerProfileRow, RequestSamplesButton, RequestSamplesContent, ServerFailAuth,
    ServerFailLabel, SignUpNowLabel, SignUpSampleCartButton,
    StepOneFlowContainer, StepZeroFlowContainer,
    StepZeroFlowContainerUser
} from './style';
import { language } from "../../../../../common"
import FarmlyInput from 'components/templates/FarmlyInput';
import SamplesCarousel from '../SamplesCarousel';
import BasicInfoSampleCart from '../BasicInfoSampleCart';
import SampleCartSignUp from '../SampleCartSignUp';
import CartModel from 'modules/SampleCart/models/cartModel';

interface UserDataProps {
    name: string,
    email: string,
    password: string,
    confirmPassword: string,
    postalCode: string,
    address: string,
    country: string,
}
interface RoasterDataProps {
    country: string,
    email: string,
    name: string,
    password: string,
    confirmPassword: string,
    postalCode: string,
    address: string,
    roastery: string,
    vat: string,
    phoneNumber: string,
    sensoryNotes: string,
    coffeeScores: string
}
interface LoginReturnProps {
    then: any;
    status: string,
    jwt: number,
    isProd: boolean,
    id: number,
    main_id: number,
    exp: number
}

interface UserContextProps {
    user: {
        isProd: boolean,
        jwt: number,
        id: number,
        main_id: number
    }
}
interface SampleCartStepsProps {
    step: number,
    userContext: UserContextProps,
    arraySamples: Array<CartModel>,
    roasterData: RoasterDataProps,
    setRoasterData: React.Dispatch<React.SetStateAction<RoasterDataProps>>,
    toggleModal: () => void,
    setModalMessage: React.Dispatch<React.SetStateAction<string>>
    setStep: React.Dispatch<React.SetStateAction<number>>,
    submitRequestFromLogin: (id?: null | number) => void,
    renderSamples: () => JSX.Element[],
    signUpForm: () => void,
    userData: UserDataProps,
    setUserData: React.Dispatch<React.SetStateAction<UserDataProps>>,
    emailFailure: boolean
    serverFailure: boolean
    authFailure: boolean,
    login: () => LoginReturnProps
}

const SampleCartSteps: FunctionComponent<SampleCartStepsProps> = (props) => {
    const stepFlow = () => {
        switch (props.step) {
            case 0:
                return (
                    <StepZeroFlowContainer>
                        {!props.userContext.user && (
                            <StepZeroFlowContainerUser>
                                <CarouselSamples>
                                    {props.arraySamples.length > 3 ? (
                                        <SamplesCarousel
                                            renderSamples={props.renderSamples}
                                        />
                                    ) :
                                        (
                                            props.arraySamples.length === 3 ?
                                                <DisplaySamplesWithoutCarousel>
                                                    {props.renderSamples()}
                                                </DisplaySamplesWithoutCarousel>
                                                :
                                                <CarouselWithFewSamples>
                                                    {props.renderSamples()}
                                                </CarouselWithFewSamples>

                                        )
                                    }
                                </CarouselSamples>
                                <SignUpNowLabel>
                                    Sign Up Now.
                                </SignUpNowLabel>
                                <BasicInfoSampleCartContainer>
                                    <BasicInfoSampleCart
                                        setStep={props.setStep}
                                        roasterData={props.roasterData}
                                        setRoasterData={props.setRoasterData}
                                        toggleModal={props.toggleModal}
                                        setModalMessage={props.setModalMessage}
                                    />
                                </BasicInfoSampleCartContainer>
                                <AlreadyHaveAccountLabel >
                                    <AlreadyHaveAccountDiv>
                                        {
                                            {
                                                pt: "Já possui uma conta? Faça",
                                                en: "Already have an account?",
                                                es: "¿Ya tiene una cuenta?",
                                            }[language]
                                        }
                                    </AlreadyHaveAccountDiv>
                                    <LoginButtonAccess onClick={() => props.setStep(2)}>
                                        {
                                            {
                                                pt: "login",
                                                en: "Sign In",
                                                es: "Inicio de sesión"
                                            }[language]
                                        }
                                    </LoginButtonAccess>
                                </AlreadyHaveAccountLabel>
                            </StepZeroFlowContainerUser>
                        )
                        }
                        {
                            !!props.userContext.user && (
                                <StepZeroFlowContainerUser>
                                    <CarouselSamples>
                                        {props.arraySamples.length > 3 ? (
                                            <SamplesCarousel
                                                renderSamples={props.renderSamples}
                                            />
                                        ) : (
                                            <CarouselWithFewSamples>
                                                {props.renderSamples()}
                                            </CarouselWithFewSamples>
                                        )}
                                    </CarouselSamples>
                                    <RequestSamplesContent>
                                        <RequestSamplesButton
                                            className="signup-basic-button"
                                            onClick={() => props.submitRequestFromLogin()}
                                        >
                                            {
                                                {
                                                    pt: "Peça essas amostras",
                                                    en: "Request these samples",
                                                    es: "Solicite estas muestras",
                                                }[language]
                                            }
                                        </RequestSamplesButton>
                                    </RequestSamplesContent>
                                </StepZeroFlowContainerUser>
                            )
                        }
                    </StepZeroFlowContainer>
                );
            case 1:
                return (
                    <StepOneFlowContainer>
                        <SampleCartSignUp
                            setStep={props.setStep}
                            signUpForm={props.signUpForm}
                            roasterData={props.roasterData}
                            setRoasterData={props.setRoasterData}
                            setModalMessage={props.setModalMessage}
                            toggleModal={props.toggleModal}
                        />
                    </StepOneFlowContainer>
                );
            case 2:
                return (
                    <>
                        <ProducerProfileRow>
                            <ProducerProfileItem >
                                <EmailProfileLabel>
                                    <label htmlFor="">
                                        Email:{" "}
                                    </label>
                                </EmailProfileLabel>
                                <FarmlyInput
                                    value={props.userData.email}
                                    setValue={(value) =>
                                        props.setUserData({ ...props.userData, email: value })
                                    }
                                    styleClass="input-farmly"
                                    elementId="email"
                                    placeholder="email..."
                                />
                            </ProducerProfileItem>
                        </ProducerProfileRow>

                        {props.emailFailure && (
                            <LoginFailLabel>
                                <label> Email not found.</label>
                            </LoginFailLabel>
                        )}
                        {props.serverFailure && (
                            <ServerFailLabel>
                                <label htmlFor="">
                                    Server error. Please, try again in a few moments, or send us a
                                    message describing the problem.
                                </label>
                            </ServerFailLabel>
                        )}
                        <ProducerProfileRow>
                            <ProducerProfileItem>
                                <PasswordProfileLabel>
                                    <label htmlFor="">
                                        Password: {" "}
                                    </label>
                                </PasswordProfileLabel>
                                <FarmlyInput
                                    value={props.userData.password}
                                    type="password"
                                    setValue={(value) =>
                                        props.setUserData({ ...props.userData, password: value })
                                    }
                                    styleClass="input-farmly"
                                    elementId="password"
                                    placeholder="password..."
                                />
                            </ProducerProfileItem>
                        </ProducerProfileRow>
                        {props.authFailure && (
                            <ServerFailAuth>
                                <label htmlFor="">
                                    {" "}
                                    Incorrect email or password.
                                </label>
                            </ServerFailAuth>
                        )}
                        <SignUpSampleCartButton
                            onClick={() => {
                                props.login()
                                    .then((loginResult: LoginReturnProps) => {
                                        props.submitRequestFromLogin(loginResult.id)
                                    })
                                    .catch((err: any) => { })
                            }
                            }
                        >
                            {" "}
                            Login and Request
                        </SignUpSampleCartButton>
                    </>
                );
            default:
        }
    };
    return (
        <>
            {stepFlow()}
        </>
    );
}
export default SampleCartSteps;