const orderByTheDate = (dateOne, dateTwo) => {
    if (dateOne < dateTwo) {
        return 1;
    }
    else if (dateOne > dateTwo) {
        return -1;
    }
    else {
        return 0;
    }
}

export default orderByTheDate;