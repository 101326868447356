import React, { useState, useEffect, useContext, Fragment } from 'react'
import axios from 'axios'
import { server, isPt } from '../../../common'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faTimes } from '@fortawesome/free-solid-svg-icons'
import { useHistory, Link } from 'react-router-dom'
import UserContext from '../../../context/user/UserContext.js'
import Paper from '@material-ui/core/Paper'
import { makeStyles, withStyles, Theme, createStyles } from '@material-ui/core/styles'
import FarmlyInput from '../../templates/FarmlyInput'
import './FarmlyCuppingIndv.css'
import Grid from '@material-ui/core/Grid'
import Volume from '@material-ui/core/Slider'
import Slider from '@material-ui/core/Slider'
// import Slider from "react-slick"
import Divider from '@material-ui/core/Divider'
import RadarChart from '../../templates/RadarChartCuppingDesktop'
import RadarChartResultsCupping from '../../templates/RadarChartResultsCupping'
import FarmlyModal from '../../templates/FarmlyModal'
import ReactGA from 'react-ga'


const CuppingIndvDesktop = props => {

    const { coffee, sensoryList, cuppingDone, checkCuppings, setCheckCuppings, updateAll, setFillAllSpaces, goToResults, notesModal, auxSensoryList, cupping_id, taster, userId } = props
    const userContext = useContext(UserContext)
    const [showResults, setShowResults] = useState(true)
    const [init, setInit] = useState(true)
    const [coffeeData, setCoffeeData] = useState()
    const [cuppingData, setCuppingData] = useState({})
    const [score, setScore] = useState(0)
    const [roast1, setRoast1] = useState(false)
    const [roast2, setRoast2] = useState(false)
    const [roast3, setRoast3] = useState(false)
    const [roast4, setRoast4] = useState(false)
    const [roast5, setRoast5] = useState(false)

    const [defect0, setDefect0] = useState(false)
    const [defect1, setDefect1] = useState(false)
    const [defect2, setDefect2] = useState(false)
    const [defect3, setDefect3] = useState(false)
    const [defect4, setDefect4] = useState(false)
    const [defect5, setDefect5] = useState(false)

    const [bigDefect0, setBigDefect0] = useState(false)
    const [bigDefect1, setBigDefect1] = useState(false)
    const [bigDefect2, setBigDefect2] = useState(false)
    const [bigDefect3, setBigDefect3] = useState(false)
    const [bigDefect4, setBigDefect4] = useState(false)
    const [bigDefect5, setBigDefect5] = useState(false)

    const [sweet1, setSweet1] = useState(false)
    const [sweet2, setSweet2] = useState(false)
    const [sweet3, setSweet3] = useState(false)
    const [sweet4, setSweet4] = useState(false)
    const [sweet5, setSweet5] = useState(false)
    const [sweet, setSweet] = useState(0)

    const [clean1, setClean1] = useState(false)
    const [clean2, setClean2] = useState(false)
    const [clean3, setClean3] = useState(false)
    const [clean4, setClean4] = useState(false)
    const [clean5, setClean5] = useState(false)
    const [cleanCup, setCleanCup] = useState(0)

    const [equal1, setEqual1] = useState(false)
    const [equal2, setEqual2] = useState(false)
    const [equal3, setEqual3] = useState(false)
    const [equal4, setEqual4] = useState(false)
    const [equal5, setEqual5] = useState(false)
    const [equalty, setEqualty] = useState(0)

    const [valueAroma, setValueAroma] = useState()
    const [valueAroma2, setValueAroma2] = useState(0)
    const [valueAroma3, setValueAroma3] = useState(0)
    const [valueFlavor, setValueFlavor] = useState()
    const [valueCompletion, setValueCompletion] = useState()
    const [valueAcidity, setValueAcidity] = useState()
    const [valueAcidity2, setValueAcidity2] = useState(0)
    const [valueBody, setValueBody] = useState()
    const [valueBody2, setValueBody2] = useState(0)
    const [valueBalance, setValueBalance] = useState()
    const [valueOverall, setValueOverall] = useState()

    const [roastWarning, setRoastWarning] = useState(false)
    const [aromaWarning, setAromaWarning] = useState(false)
    const [flavorWarning, setFlavorWarning] = useState(false)
    const [completionWarning, setCompletionWarning] = useState(false)
    const [acidityWarning, setAcidityWarning] = useState(false)
    const [bodyWarning, setBodyWarning] = useState(false)
    const [balanceWarning, setBalanceWarning] = useState(false)
    const [overallWarning, setOverallWarning] = useState(false)
    const [scoreVar, setScoreVar] = useState(false)
    const [updateNotes, setUpdateNotes] = useState(false)

    const [defectLight, setDefectLight] = useState()
    const [defectHeavy, setHeavyDefect] = useState()


    const history = useHistory()

    useEffect(() => {
        if (init) {
            initializeData()
        }
        setShowResults(false)
    }, [])

    useEffect(() => {
        if (goToResults.status) {
            if (goToResults.coffeeId == coffee.coffee_id) {
                handleResult(false)
                setFillAllSpaces(false)
            }
        }
    }, [goToResults])

    useEffect(() => {
        if (cuppingDone) {
            axios.patch(`${server}/updateCupping`, {
                ...cuppingData,
                clean_cup: cleanCup,
                sweetness: sweet,
                uniformity: equalty,
                score: score,
                cupping_done: true
            })
        }
    }, [showResults, cuppingDone])

    useEffect(() => {
        setScore(parseFloat(cuppingData.aroma) + parseFloat(cuppingData.flavor) + parseFloat(cuppingData.completion) + parseFloat(cuppingData.acidity) + parseFloat(cuppingData.body) + parseFloat(cuppingData.balance) + cleanCup + sweet + equalty - parseFloat(cuppingData.defects) - parseFloat(cuppingData.big_defect) + parseFloat(cuppingData.overall) + 30)
    }, [cuppingData, cleanCup, sweet, equalty])

    useEffect(() => {
        axios.post(`${server}/getCuppingCoffee`, { user_id: coffee.user_id, coffee_id: coffee.coffee_id })
            .then(res => {
                if (res.data.sensoryNotes !== null && res.data.sensoryNotes !== "") setCoffeeData(res.data)
                else setCoffeeData({ ...res.data, sensoryNotes: "Sem notas" })
            })
    }, [showResults, auxSensoryList, updateNotes])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }, [showResults])

    const initializeData = () => {
        setCuppingData({
            coffee_id: coffee.id,
            user_id: userId,
            aroma: coffee.aroma ? parseFloat(coffee.aroma) : 0,
            flavor: coffee.flavour ? coffee.flavour : 0,
            completion: coffee.aftertaste ? coffee.aftertaste : 0,
            acidity: coffee.acidity ? coffee.acidity : 0,
            body: coffee.body ? coffee.body : 0,
            balance: coffee.balance ? coffee.balance : 0,
            overall: coffee.overall ? parseInt(coffee.overall) : 60,
            defects: '0',
            big_defect: '0',
            score: coffee.score,
            generalComment: coffee.general_comment,
            isFarmly: true
        })

        setScore(coffee.score)
        setInit(false)
    }

    const handleResult = async (force) => {
        if ((!roastWarning || !aromaWarning || !flavorWarning || !acidityWarning || !bodyWarning || !balanceWarning || !overallWarning || !completionWarning) && force) {
            setFillAllSpaces(true)
        } else {
            axios.patch(`${server}/coffees`, {
                id: coffee.id,
                producerId: coffee.producerId,
                origin: coffee.origin,
                farmCode: coffee.farmCode,
                aroma: cuppingData.aroma,
                acidity: cuppingData.acidity,
                balance: cuppingData.balance,
                flavour: cuppingData.flavor,
                aftertaste: cuppingData.completion,
                overall: cuppingData.overall,
                body: cuppingData.body,
                score: score,
                sampleSentDate: cuppingData.sampleSentDate,
                sampleReceivedDate: cuppingData.sampleReceivedDate,
                sampleTastedDate: cuppingData.sampleTastedDate,
                producer: coffee.producer,
                process: coffee.process,
                variety: cuppingData.variety,
                shipment: coffee.shipment,
                region: coffee.region,
                height: cuppingData.height,
                sackRemain: cuppingData.storage,
                section: taster + 4,
                general_comment: cuppingData.generalComment,
                whatsappNotification: true
                // clean_cup: cleanCup,
                // sweetness: sweet,
                // uniformity: equalty,
                // cupping_done: true
            }).then(_ => {
                setCheckCuppings(!checkCuppings)
                setShowResults(true)
            })
        }
    }

    const handleReturn = async () => {
        setShowResults(false)
        setScoreVar(true)
    }

    const setRoastPoint = (which) => {
        switch (which) {
            case 1:
                setCuppingData({ ...cuppingData, roasting_point: 1 })
                setRoast1(!roast1)
                setRoast2(false)
                setRoast3(false)
                setRoast4(false)
                setRoast5(false)
                break;
            case 2:
                setCuppingData({ ...cuppingData, roasting_point: 2 })
                setRoast2(!roast2)
                setRoast1(false)
                setRoast3(false)
                setRoast4(false)
                setRoast5(false)
                break;
            case 3:
                setCuppingData({ ...cuppingData, roasting_point: 3 })
                setRoast3(!roast3)
                setRoast2(false)
                setRoast1(false)
                setRoast4(false)
                setRoast5(false)
                break;
            case 4:
                setCuppingData({ ...cuppingData, roasting_point: 4 })
                setRoast4(!roast4)
                setRoast2(false)
                setRoast3(false)
                setRoast1(false)
                setRoast5(false)
                break;
            case 5:
                setCuppingData({ ...cuppingData, roasting_point: 5 })
                setRoast5(!roast5)
                setRoast2(false)
                setRoast3(false)
                setRoast4(false)
                setRoast1(false)
                break;

            default:
                break;
        }
    }

    const setDefect = (which) => {
        setDefectLight(which)
        switch (which * 2) {
            case 0:
                setCuppingData({ ...cuppingData, defects: 0 })
                setDefect0(!defect0)
                setDefect2(false)
                setDefect1(false)
                setDefect3(false)
                setDefect4(false)
                setDefect5(false)
                break;
            case 2:
                setCuppingData({ ...cuppingData, defects: 2 })
                setDefect1(!defect1)
                setDefect2(false)
                setDefect0(false)
                setDefect3(false)
                setDefect4(false)
                setDefect5(false)
                break;
            case 4:
                setCuppingData({ ...cuppingData, defects: 4 })
                setDefect2(!defect2)
                setDefect0(false)
                setDefect1(false)
                setDefect3(false)
                setDefect4(false)
                setDefect5(false)
                break;
            case 6:
                setCuppingData({ ...cuppingData, defects: 6 })
                setDefect3(!defect3)
                setDefect0(false)
                setDefect2(false)
                setDefect1(false)
                setDefect4(false)
                setDefect5(false)
                break;
            case 8:
                setCuppingData({ ...cuppingData, defects: 8 })
                setDefect4(!defect4)
                setDefect0(false)
                setDefect2(false)
                setDefect3(false)
                setDefect1(false)
                setDefect5(false)
                break;
            case 10:
                setCuppingData({ ...cuppingData, defects: 10 })
                setDefect5(!defect5)
                setDefect0(false)
                setDefect2(false)
                setDefect3(false)
                setDefect4(false)
                setDefect1(false)
                break;

            default:
                break;
        }
    }


    const setBigDefect = (which) => {
        setHeavyDefect(which)
        switch (which * 4) {
            case 0:
                setCuppingData({ ...cuppingData, big_defect: 0 })
                setBigDefect0(!bigDefect0)
                setBigDefect2(false)
                setBigDefect1(false)
                setBigDefect3(false)
                setBigDefect4(false)
                setBigDefect5(false)
                break;
            case 4:
                setCuppingData({ ...cuppingData, big_defect: 4 })
                setBigDefect1(!bigDefect1)
                setBigDefect2(false)
                setBigDefect0(false)
                setBigDefect3(false)
                setBigDefect4(false)
                setBigDefect5(false)
                break;
            case 8:
                setCuppingData({ ...cuppingData, big_defect: 8 })
                setBigDefect2(!bigDefect2)
                setBigDefect0(false)
                setBigDefect1(false)
                setBigDefect3(false)
                setBigDefect4(false)
                setBigDefect5(false)
                break;
            case 12:
                setCuppingData({ ...cuppingData, big_defect: 12 })
                setBigDefect3(!bigDefect3)
                setBigDefect2(false)
                setBigDefect1(false)
                setBigDefect0(false)
                setBigDefect4(false)
                setBigDefect5(false)
                break;
            case 16:
                setCuppingData({ ...cuppingData, big_defect: 16 })
                setBigDefect4(!bigDefect4)
                setBigDefect2(false)
                setBigDefect1(false)
                setBigDefect3(false)
                setBigDefect0(false)
                setBigDefect5(false)
                break;
            case 20:
                setCuppingData({ ...cuppingData, big_defect: 20 })
                setBigDefect5(!bigDefect5)
                setBigDefect2(false)
                setBigDefect1(false)
                setBigDefect3(false)
                setBigDefect4(false)
                setBigDefect0(false)
                break;

            default:
                break;
        }
    }

    const handleChangeAroma2 = (event, newValue) => {
        setScoreVar(true)
        setValueAroma2(newValue)
    }

    const handleChangeAroma3 = (event, newValue) => {
        setScoreVar(true)
        setValueAroma3(newValue)
    }

    const handleChangeAcidity2 = (event, newValue) => {
        setScoreVar(true)
        setValueAcidity2(newValue)
    }

    const handleChangeBody2 = (event, newValue) => {
        setScoreVar(true)
        setValueBody2(newValue)
    }

    const removeNote = async (item) => {
        let sensoryNotesListToUpdate = coffee.sensoryNotes.split(',')
        sensoryNotesListToUpdate.splice(item, 1)
        try {
            await axios.patch(`${server}/coffees`, {
                id: coffee.id, sensoryNotes: sensoryNotesListToUpdate.join(',')
            })
            setUpdateNotes(!updateNotes)
        } catch (err) {
            console.log(err)
        } finally {
            window.location.reload();
        }
    }

    const renderSensoryCard = () =>
        coffee.sensoryNotes.split(',').map((note) => {
            let random = Math.floor(Math.random() * 3)
            return (
                <Fragment>
                    {note !== "Sem notas" ? <div className={(random == 0) ? 'sensory-red' : ((random == 1 ? 'sensory-lime' : 'sensory-orange'))} style={{ display: 'flex', flexDirection: 'row', width: 'auto' }}>
                        {note}
                        <div onClick={() => { removeNote(note) }} style={{ marginLeft: '8px', marginRight: '3px', cursor: 'pointer' }}>x</div>
                    </div> : note}
                    &nbsp;
                    <br />
                </Fragment>
            )
        })


    const useStyles = makeStyles((theme) => ({
        mainPaper: {
            height: 'auto',
            marginBottom: '-55px',
            borderRadius: '25px',
            width: '100%',
            backgroundColor: 'rgb(27, 98, 94)',
            marginTop: '-10vw',
            paddingTop: '20vw'
        },
        mainPaperDesktop: {
            height: 'auto',
            borderRadius: '25px',
            width: '100%',
            backgroundColor: '#fffff',
        },
        volume: {
            height: 300,
        },
        scorePaper: {
            marginTop: '-45px',
            height: 'auto',
            marginBottom: '5px',
            borderRadius: '25px',
            width: '100%',
            backgroundColor: 'white',
            zIndex: '0',
            paddingBottom: '25px'
        },
        margin: {
            margin: theme.spacing(1),
        },
        rootslider: {
            width: 50 + theme.spacing(3) * 2,
            color: '#000000',
            height: 2,
            padding: '15px 0',
        },
    }))
    const classes = useStyles()

    const acidityMarks = [
        {
            value: 6,
            label: 'Baixa',
        },
        {
            value: 7,
            label: '',
        },
        {
            value: 8,
            label: 'Média',
        },
        {
            value: 9,
            label: '',
        },
        {
            value: 10,
            label: 'Alta',
        },
    ]

    const chartAttributes = [{
        Acidez: cuppingData.acidity,
        Aroma: cuppingData.aroma,
        Corpo: cuppingData.body,
        Equilibrio: cuppingData.balance,
        Finalização: cuppingData.completion,
        Sabor: cuppingData.flavor
    }]

    const thumbShadow = '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

    const CuppingSlider = withStyles({
        thumb: {
            height: 30,
            width: 8,
            borderRadius: 2,
            backgroundColor: '#DC635E',
            boxShadow: thumbShadow,
            marginTop: -14,
            marginLeft: 0,
            '&:focus, &:hover, &$active': {
                boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
                '@media (hover: none)': {
                    boxShadow: thumbShadow,
                },
            },
        },
        active: {},
        track: {
            height: 2,
            backgroundColor: '#000000',
        },
        rail: {
            height: 2,
            opacity: .5,
            backgroundColor: '#000000',
        },
        mark: {
            backgroundColor: '#000000',
            height: 24,
            width: 3,
            marginTop: -10,
        },
        markActive: {
            opacity: 1,
            backgroundColor: '#000000',
        },
    })(Slider);

    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div className='cupping-indv-container-overflow' style={{ height: '100%', width: '60%', overflowY: 'scroll' }} >
                    <div style={{ direction: 'ltr' }}>
                        <div style={{ margin: '2vw', borderRadius: '25px', marginBottom: '0vw' }}>
                            <div className="row roast-container-desktop">
                                <div className="coffee-roast-desktop" style={{ padding: '15px' }}>
                                    <label htmlFor="" className="roast-title-desktop" >{isPt() ? 'Ponto da Torra:' : 'Punto de tueste'}</label>
                                    <div className={cuppingData.roasting_point == '1' ? 'selected-roast-desktop' : ''} onClick={() => {
                                        setCuppingData({ ...cuppingData, roasting_point: '1' })
                                        setRoastWarning(true)
                                    }}>
                                        <div className='cafe-fraco-desktop'></div>
                                    </div>
                                    <div className={cuppingData.roasting_point == '2' ? 'selected-roast-desktop' : ''} onClick={() => {
                                        setCuppingData({ ...cuppingData, roasting_point: '2' })
                                        setRoastWarning(true)
                                    }}>
                                        <div className='cafe-fraco2-desktop'></div>
                                    </div>
                                    <div className={cuppingData.roasting_point == '3' ? 'selected-roast-desktop' : ''} onClick={() => {
                                        setCuppingData({ ...cuppingData, roasting_point: '3' })
                                        setRoastWarning(true)
                                    }}>
                                        <div className='cafe-medio-desktop'></div>
                                    </div>
                                    <div className={cuppingData.roasting_point == '4' ? 'selected-roast-desktop' : ''} onClick={() => {
                                        setCuppingData({ ...cuppingData, roasting_point: '4' })
                                        setRoastWarning(true)
                                    }}>
                                        <div className='cafe-medio2-desktop'></div>
                                    </div>
                                    <div className={cuppingData.roasting_point == '5' ? 'selected-roast-desktop' : ''} onClick={() => {
                                        setCuppingData({ ...cuppingData, roasting_point: '5' })
                                        setRoastWarning(true)
                                    }}>
                                        <div className='cafe-forte-desktop'></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Divider variant="middle" />

                        <div style={{ margin: '2vw', borderRadius: '25px' }}>
                            <div className={classes.rootslider} className="row" style={{ padding: '15px' }}>
                                <div className='cupping-col-contents col'>
                                    <label className="info-title-desktop" style={{ marginRight: '1vw' }}>{isPt() ? 'Aroma:' : 'Aroma:'}</label>
                                    <input id={cuppingData.coffee_id} type="number" className="farmly-input input-cupping-categories" min={6} max={10} step={1} value={cuppingData.aroma} onClick="this.select()" onChange={e => setCuppingData({ ...cuppingData, aroma: e.target.value })} placeholder="" />
                                    {(cuppingData.aroma < 6 || cuppingData.aroma < 10) && <div className='invalid-input-cupping' style={{ color: 'var(--farmly-fuzzy)' }}>{isPt() ? `Nota entre 6 e 10.` : `Grado entre 6 y 10.`}</div>}
                                </div>

                                <div className='cupping-col-contents col'>
                                    <Grid container className="medium-volume-desktop">
                                        <label className='info2-title-desktop' style={{ marginRight: '1vw' }}>{isPt() ? 'Seco:' : 'Seco:'}</label>
                                        <CuppingSlider style={{ marginRight: '1vw' }} value={valueAroma3} onChange={handleChangeAroma3} marks={true} min={6} max={10} valueLabelDisplay="off" orientation="horizontal" aria-labelledby="continuous-slider" step={1} track="normal" />
                                    </Grid>
                                </div>
                                <div className='cupping-col-contents col'>
                                    <Grid container className="medium-volume-desktop">
                                        <label className='info2-title-desktop' style={{ marginRight: '1vw' }}>{isPt() ? 'Crosta:' : 'Corteza:'}</label>
                                        <CuppingSlider value={valueAroma2} onChange={handleChangeAroma2} marks={true} min={6} max={10} valueLabelDisplay="off" orientation="horizontal" aria-labelledby="continuous-slider" step={1} track="normal" />
                                    </Grid>
                                </div>
                            </div>
                        </div>

                        <Divider variant="middle" />

                        <div style={{ margin: '2vw', borderRadius: '25px' }}>
                            <div style={{ padding: '15px' }}>
                                <div className='cupping-col-contents col'>
                                    <label className="info-title-desktop" style={{ marginRight: '1vw' }}>{isPt() ? 'Sabor:' : 'Sabor:'}</label>
                                    <input type="number" className="farmly-input input-cupping-categories" min={6} max={10} step={1} value={cuppingData.flavor} onClick="this.select()" onChange={e => setCuppingData({ ...cuppingData, flavor: e.target.value })} placeholder="" />
                                    {(cuppingData.flavor > 10 || cuppingData.flavor < 6) && <label className='invalid-input-cupping' style={{ color: 'var(--farmly-fuzzy)' }}>{isPt() ? 'Nota entre 6 e 10.' : 'Grado entre 6 y 10.'}</label>}
                                </div>
                                <div className='cupping-col-contents col'>
                                    <label className="info-title-desktop" style={{ marginRight: '1vw', marginLeft: '3vw' }}>{isPt() ? 'Sabor Residual:' : 'Sabor Residual:'}</label>
                                    <input type="number" className="farmly-input input-cupping-categories" min={6} max={10} step={1} value={cuppingData.completion} onClick="this.select()" onChange={e => setCuppingData({ ...cuppingData, completion: e.target.value })} />
                                    {(cuppingData.completion > 10 || cuppingData.completion < 6) && <label className='invalid-input-cupping' style={{ color: 'var(--farmly-fuzzy)' }}>{isPt() ? 'Nota entre 6 e 10.' : 'Grado entre 6 y 10.'}</label>}
                                </div>
                            </div>
                        </div>

                        <Divider variant="middle" />

                        <div style={{ margin: '2vw', borderRadius: '25px' }}>
                            <div style={{ padding: '15px' }}>
                                <div className='cupping-col-contents col'>
                                    <label className="info-title-desktop" style={{ marginRight: '1vw' }}>{isPt() ? 'Acidez:' : 'Acidez:'}</label>
                                    <input type="number" className="farmly-input input-cupping-categories" min={6} max={10} step={1} value={cuppingData.acidity} onClick="this.select()" onChange={e => setCuppingData({ ...cuppingData, acidity: e.target.value })} placeholder="" />
                                    {(cuppingData.acidity > 10 || cuppingData.acidity < 6) && <label className='invalid-input-cupping' style={{ color: 'var(--farmly-fuzzy)' }}>{isPt() ? 'Nota entre 6 e 10.' : 'Grado entre 6 y 10.'}</label>}
                                </div>
                                <div className={classes.rootslider} className='cupping-col-contents col'>
                                    <label className='info2-title-desktop' style={{ marginRight: '2vw', marginLeft: '2vw' }}>{isPt() ? 'Intensidade:' : 'Intensidad:'}</label>
                                    <Grid container spacing={2} className='medium-volume-desktop'>
                                        <CuppingSlider style={{ marginRight: '1vw' }} value={valueAcidity2} onChange={handleChangeAcidity2} marks={acidityMarks} min={6} max={10} valueLabelDisplay="off" orientation="horizontal" aria-labelledby="continuous-slider" step={0.5} />
                                    </Grid>
                                </div>
                            </div>
                        </div>

                        <Divider variant="middle" />

                        <div style={{ margin: '2vw', borderRadius: '25px' }}>
                            <div style={{ padding: '15px' }}>
                                <div className='cupping-col-contents col'>
                                    <label className="info-title-desktop" style={{ marginRight: '1vw' }}>{isPt() ? 'Corpo:' : 'Cuerpo:'}</label>
                                    <input type="number" className="farmly-input input-cupping-categories" min={6} max={10} step={1} value={cuppingData.body} onClick="this.select()" onChange={e => setCuppingData({ ...cuppingData, body: e.target.value })} placeholder="" />
                                    {(cuppingData.body > 10 || cuppingData.body < 6) && <label className='invalid-input-cupping' style={{ color: 'var(--farmly-fuzzy)' }}>{isPt() ? 'Nota entre 6 e 10.' : 'Grado entre 6 y 10.'}</label>}
                                </div>
                                <div className={classes.rootslider} className='cupping-col-contents col'>
                                    <label className='info2-title-desktop' style={{ marginRight: '2vw', marginLeft: '2vw' }}>{isPt() ? 'Intensidade:' : 'Intensidad:'}</label>
                                    <Grid container spacing={2} className='medium-volume-desktop'>
                                        <CuppingSlider value={valueBody2} style={{ marginRight: '1vw' }} onChange={handleChangeBody2} marks={true} min={6} max={10} valueLabelDisplay="off" orientation="horizontal" aria-labelledby="continuous-slider" step={1} />
                                    </Grid>
                                </div>
                            </div>
                        </div>

                        <Divider variant="middle" />
                        <div style={{ margin: '2vw', borderRadius: '25px' }}>
                            <div style={{ padding: '15px' }}>
                                <div className='cupping-col-contents col'>
                                    <label className="info-title-desktop" style={{ marginRight: '1vw' }}>{isPt() ? 'Equilíbrio:' : 'Balance:'}</label>
                                    <input type="number" className="farmly-input input-cupping-categories" min={6} max={10} step={1} value={cuppingData.balance} onClick="this.select()" onChange={e => setCuppingData({ ...cuppingData, balance: e.target.value })} placeholder="" />
                                    {(cuppingData.balance > 10 || cuppingData.balance < 6) && <label className='invalid-input-cupping' style={{ color: 'var(--farmly-fuzzy)' }}>{isPt() ? 'Nota entre 6 e 10.' : 'Grado entre 6 y 10.'}</label>}
                                </div>
                                <div className='cupping-col-contents col'>
                                    <label className="info-title-desktop" style={{ marginRight: '1vw', marginLeft: '3vw' }}>{isPt() ? 'Notal final:' : 'Notal Final:'}</label>
                                    <input type="number" className="farmly-input input-cupping-categories" min={6} max={10} step={1} value={cuppingData.overall} onClick="this.select()" onChange={e => setCuppingData({ ...cuppingData, overall: e.target.value })} placeholder="" />
                                    {(cuppingData.overall < 6  || cuppingData.overall > 10) && <label className='invalid-input-cupping' style={{ color: 'var(--farmly-fuzzy)' }}>{isPt() ? 'Nota entre 6 e 10.' : 'Grado entre 6 y 10.'}</label>}
                                </div>
                            </div>
                        </div>
                        <Divider variant="middle" />
                        <div zIndex="tooltip" style={{ margin: '2vw', borderRadius: '25px', position: 'relative' }}>
                            <div style={{ padding: '15px', textAlign: 'center' }}>
                                <div className="producer-profile-item">
                                    <label className="info-title-desktop">{isPt() ? 'Xícara Limpa:' : 'Taza limpia:'}</label>
                                    <span>{isPt() ? 'Marque a xícara que não for uma xícara limpa' : 'Marca el taza que no es una taza limpia'}</span>
                                </div>
                                <div>
                                    {clean1 && <FontAwesomeIcon icon={faTimes} className="select-cross-desktop" onClick={() => {
                                        clean1 ? setCleanCup(cleanCup + 2) : setCleanCup(cleanCup - 2)
                                        setClean1(!clean1)
                                    }}></FontAwesomeIcon>}
                                    <FontAwesomeIcon className="select-cup-desktop" icon={faCoffee} onClick={() => {
                                        clean1 ? setCleanCup(cleanCup + 2) : setCleanCup(cleanCup - 2)
                                        setClean1(!clean1)
                                    }}></FontAwesomeIcon>
                                    {clean2 && <FontAwesomeIcon icon={faTimes} className="select-cross-desktop" onClick={() => {
                                        clean2 ? setCleanCup(cleanCup + 2) : setCleanCup(cleanCup - 2)
                                        setClean2(!clean2)
                                    }}></FontAwesomeIcon>}
                                    <FontAwesomeIcon className="select-cup-desktop" icon={faCoffee} onClick={() => {
                                        clean2 ? setCleanCup(cleanCup + 2) : setCleanCup(cleanCup - 2)
                                        setClean2(!clean2)
                                    }}></FontAwesomeIcon>
                                    {clean3 && <FontAwesomeIcon icon={faTimes} className="select-cross-desktop" onClick={() => {
                                        clean3 ? setCleanCup(cleanCup + 2) : setCleanCup(cleanCup - 2)
                                        setClean3(!clean3)
                                    }}></FontAwesomeIcon>}
                                    <FontAwesomeIcon className="select-cup-desktop" icon={faCoffee} onClick={() => {
                                        clean3 ? setCleanCup(cleanCup + 2) : setCleanCup(cleanCup - 2)
                                        setClean3(!clean3)
                                    }}></FontAwesomeIcon>
                                    {clean4 && <FontAwesomeIcon icon={faTimes} className="select-cross-desktop" onClick={() => {
                                        clean4 ? setCleanCup(cleanCup + 2) : setCleanCup(cleanCup - 2)
                                        setClean4(!clean4)
                                    }}></FontAwesomeIcon>}
                                    <FontAwesomeIcon className="select-cup-desktop" icon={faCoffee} onClick={() => {
                                        clean4 ? setCleanCup(cleanCup + 2) : setCleanCup(cleanCup - 2)
                                        setClean4(!clean4)
                                    }}></FontAwesomeIcon>
                                    {clean5 && <FontAwesomeIcon icon={faTimes} className="select-cross-desktop" onClick={() => {
                                        clean5 ? setCleanCup(cleanCup + 2) : setCleanCup(cleanCup - 2)
                                        setClean5(!clean5)
                                    }}></FontAwesomeIcon>}
                                    <FontAwesomeIcon className="select-cup-desktop" icon={faCoffee} onClick={() => {
                                        clean5 ? setCleanCup(cleanCup + 2) : setCleanCup(cleanCup - 2)
                                        setClean5(!clean5)
                                    }}></FontAwesomeIcon>
                                </div>
                            </div>
                        </div>

                        <Divider variant="middle" />

                        {/* Doçura */}
                        <div zIndex="tooltip" style={{ margin: '2vw', borderRadius: '25px', position: 'relative' }}>
                            <div style={{ padding: '15px', textAlign: 'center' }}>
                                <div className="producer-profile-item">
                                    <label htmlFor="" className="info-title-desktop">{isPt() ? 'Doçura:' : 'Dulzura:'}</label>
                                    <span>{isPt() ? 'Marque a xícara que não tiver a presença de doçura' : 'Marca la taza que no tiene la presencia de la dulzura'}</span>
                                </div>
                                <div>
                                    {sweet1 && <FontAwesomeIcon icon={faTimes} className="select-cross-desktop" onClick={() => {
                                        sweet1 ? setSweet(sweet + 2) : setSweet(sweet - 2)
                                        setSweet1(!sweet1)
                                    }}></FontAwesomeIcon>}
                                    <FontAwesomeIcon className="select-cup-desktop" icon={faCoffee} onClick={() => {
                                        sweet1 ? setSweet(sweet + 2) : setSweet(sweet - 2)
                                        setSweet1(!sweet1)
                                    }}></FontAwesomeIcon>
                                    {sweet2 && <FontAwesomeIcon icon={faTimes} className="select-cross-desktop" onClick={() => {
                                        sweet2 ? setSweet(sweet + 2) : setSweet(sweet - 2)
                                        setSweet2(!sweet2)
                                    }}></FontAwesomeIcon>}
                                    <FontAwesomeIcon className="select-cup-desktop" icon={faCoffee} onClick={() => {
                                        sweet2 ? setSweet(sweet + 2) : setSweet(sweet - 2)
                                        setSweet2(!sweet2)
                                    }}></FontAwesomeIcon>
                                    {sweet3 && <FontAwesomeIcon icon={faTimes} className="select-cross-desktop" onClick={() => {
                                        sweet3 ? setSweet(sweet + 2) : setSweet(sweet - 2)
                                        setSweet3(!sweet3)
                                    }}></FontAwesomeIcon>}
                                    <FontAwesomeIcon className="select-cup-desktop" icon={faCoffee} onClick={() => {
                                        sweet3 ? setSweet(sweet + 2) : setSweet(sweet - 2)
                                        setSweet3(!sweet3)
                                    }}></FontAwesomeIcon>
                                    {sweet4 && <FontAwesomeIcon icon={faTimes} className="select-cross-desktop" onClick={() => {
                                        sweet4 ? setSweet(sweet + 2) : setSweet(sweet - 2)
                                        setSweet4(!sweet4)
                                    }}></FontAwesomeIcon>}
                                    <FontAwesomeIcon className="select-cup-desktop" icon={faCoffee} onClick={() => {
                                        sweet4 ? setSweet(sweet + 2) : setSweet(sweet - 2)
                                        setSweet4(!sweet4)
                                    }}></FontAwesomeIcon>
                                    {sweet5 && <FontAwesomeIcon icon={faTimes} className="select-cross-desktop" onClick={() => {
                                        sweet5 ? setSweet(sweet + 2) : setSweet(sweet - 2)
                                        setSweet5(!sweet5)
                                    }}></FontAwesomeIcon>}
                                    <FontAwesomeIcon className="select-cup-desktop" icon={faCoffee} onClick={() => {
                                        sweet5 ? setSweet(sweet + 2) : setSweet(sweet - 2)
                                        setSweet5(!sweet5)
                                    }}></FontAwesomeIcon>
                                </div>
                            </div>
                        </div>

                        <Divider variant="middle" />

                        {/* Uniformidade */}
                        <div zIndex="tooltip" style={{ margin: '2vw', borderRadius: '25px', position: 'relative' }}>
                            <div style={{ padding: '15px', textAlign: 'center' }}>
                                <div className="producer-profile-item">
                                    <label htmlFor="" className="info-title-desktop">{isPt() ? 'Uniformidade:' : 'Uniformidad:'}</label>
                                    <span>{isPt() ? 'Marque a xícara que não for uniforme' : 'Marcar el taza que no es uniforme'}</span>
                                </div>
                                <div>
                                    {equal1 && <FontAwesomeIcon icon={faTimes} className="select-cross-desktop" onClick={() => {
                                        equal1 ? setEqualty(equalty + 2) : setEqualty(equalty - 2)
                                        setEqual1(!equal1)
                                    }}></FontAwesomeIcon>}
                                    <FontAwesomeIcon className="select-cup-desktop" icon={faCoffee} onClick={() => {
                                        equal1 ? setEqualty(equalty + 2) : setEqualty(equalty - 2)
                                        setEqual1(!equal1)
                                    }}></FontAwesomeIcon>
                                    {equal2 && <FontAwesomeIcon icon={faTimes} className="select-cross-desktop" onClick={() => {
                                        equal2 ? setEqualty(equalty + 2) : setEqualty(equalty - 2)
                                        setEqual2(!equal2)
                                    }}></FontAwesomeIcon>}
                                    <FontAwesomeIcon className="select-cup-desktop" icon={faCoffee} onClick={() => {
                                        equal2 ? setEqualty(equalty + 2) : setEqualty(equalty - 2)
                                        setEqual2(!equal2)
                                    }}></FontAwesomeIcon>
                                    {equal3 && <FontAwesomeIcon icon={faTimes} className="select-cross-desktop" onClick={() => {
                                        equal3 ? setEqualty(equalty + 2) : setEqualty(equalty - 2)
                                        setEqual3(!equal3)
                                    }}></FontAwesomeIcon>}
                                    <FontAwesomeIcon className="select-cup-desktop" icon={faCoffee} onClick={() => {
                                        equal3 ? setEqualty(equalty + 2) : setEqualty(equalty - 2)
                                        setEqual3(!equal3)
                                    }}></FontAwesomeIcon>
                                    {equal4 && <FontAwesomeIcon icon={faTimes} className="select-cross-desktop" onClick={() => {
                                        equal4 ? setEqualty(equalty + 2) : setEqualty(equalty - 2)
                                        setEqual4(!equal4)
                                    }}></FontAwesomeIcon>}
                                    <FontAwesomeIcon className="select-cup-desktop" icon={faCoffee} onClick={() => {
                                        equal4 ? setEqualty(equalty + 2) : setEqualty(equalty - 2)
                                        setEqual4(!equal4)
                                    }}></FontAwesomeIcon>
                                    {equal5 && <FontAwesomeIcon icon={faTimes} className="select-cross-desktop" onClick={() => {
                                        equal5 ? setEqualty(equalty + 2) : setEqualty(equalty - 2)
                                        setEqual5(!equal5)
                                    }}></FontAwesomeIcon>}
                                    <FontAwesomeIcon className="select-cup-desktop" icon={faCoffee} onClick={() => {
                                        equal5 ? setEqualty(equalty + 2) : setEqualty(equalty - 2)
                                        setEqual5(!equal5)
                                    }}></FontAwesomeIcon>
                                </div>
                            </div>
                        </div>

                        <Divider variant="middle" />

                        {/* Defeitos */}
                        <div style={{ margin: '2vw', borderRadius: '25px', position: 'relative' }}>
                            <div style={{ padding: '15px' }}>
                                <div className='cupping-col-contents col ' style={{ marginRight: '2vw' }}>
                                    <label style={{ marginRight: '1vw' }} className="info-title-desktop">{isPt() ? 'Defeitos leves:' : 'Defectos leves:'}</label>
                                    <input type="number" className="farmly-input input-cupping-categories" min={0} max={5} step={1} value={cuppingData.defects / 2} onClick="this.focus()" onChange={e => setDefect(e.target.value)} placeholder="" />
                                    {(cuppingData.defects / 2 > 5 || cuppingData.defects / 2 < 0) && <label className='invalid-input-cupping' style={{ color: 'var(--farmly-fuzzy)' }}>{isPt() ? 'Nota entre 0 e 5.' : 'Calificación entre 0 y 5.'}</label>}
                                </div>
                                <label className='defect-comment'><b>{isPt() ? 'Leve' : 'Leve'}</b> x2</label>
                                <div className='cupping-col-contents col ' style={{ marginRight: '2vw' }}>
                                    <label style={{ marginRight: '1vw' }} className="info-title-desktop">{isPt() ? 'Defeitos fortes:' : 'Defectos graves:'}</label>
                                    <input type="number" className="farmly-input input-cupping-categories" min={0} max={5} step={1} value={cuppingData.big_defect / 4} onClick="this.focus()" onChange={e => setBigDefect(e.target.value)} placeholder="" />
                                    {(cuppingData.big_defect / 4 > 5 || cuppingData.big_defect / 4 < 0) && <label className='invalid-input-cupping' style={{ color: 'var(--farmly-fuzzy)' }}>{isPt() ? 'Nota entre 0 e 5.' : 'Calificación entre 0 y 5.'}</label>}
                                </div>
                                <label className='defect-comment'><b>{isPt() ? 'Forte' : 'Grave'}</b> x4</label>
                            </div>
                        </div>
                        {/* <div style={{ margin: '2vw', borderRadius: '25px', position: 'relative' , display: 'flex', flexDirection: 'column', alignItems: 'center', height: '20vw', justifyContent: 'space-evenly'}}>
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', width: '100%'}}>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <label style={{fontWeight: '600', paddingBottom: '0.5vw'}}>{isPt() ? 'Data de Envio:' : 'Fecha de envío:'}</label>
                                    <input className="input-cupping-farmly-date" value={cuppingData.sampleSentDate} onChange={e => setCuppingData({ ...cuppingData, sampleSentDate: e.target.value })}></input>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <label style={{fontWeight: '600', paddingBottom: '0.5vw'}}>{isPt() ? 'Data de Recebimento:' : 'Fecha de recepción:'}</label>
                                    <input className="input-cupping-farmly-date" value={cuppingData.sampleReceivedDate} onChange={e => setCuppingData({ ...cuppingData, sampleReceivedDate: e.target.value })}></input>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <label style={{fontWeight: '600', paddingBottom: '0.5vw'}}>{isPt() ? 'Data da Prova:' : 'Fecha de la prueba:'}</label>
                                    <input className="input-cupping-farmly-date" value={cuppingData.sampleTastedDate} onChange={e => setCuppingData({ ...cuppingData, sampleTastedDate: e.target.value })}></input>
                                </div>
                            </div>
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', width: '90%'}}>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <label style={{fontWeight: '500', paddingBottom: '0.1vw'}}>{isPt() ? 'Produtor:' : 'Productor:'}</label>
                                    <input className="input-cupping-farmly-info" value={cuppingData.producer} onChange={e => setCuppingData({ ...cuppingData, producer: e.target.value })}></input>                                   
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <label style={{fontWeight: '500', paddingBottom: '0.1vw'}}>{isPt() ? 'Processo:' : 'Proceso:'}</label>
                                    <input className="input-cupping-farmly-info" value={cuppingData.process} onChange={e => setCuppingData({ ...cuppingData, process: e.target.value })}></input>                                    
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <label style={{fontWeight: '500', paddingBottom: '0.1vw'}}>{isPt() ? 'Cidade:' : 'Ciudad:'}</label>
                                    <input className="input-cupping-farmly-info" value={cuppingData.farmName} onChange={e => setCuppingData({ ...cuppingData, farmName: e.target.value })}></input>                                    
                                </div>
                            </div>
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', width: '90%'}}>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <label style={{fontWeight: '500', paddingBottom: '0.1vw'}}>{isPt() ? 'Variedade:' : 'Variedad:'}</label>
                                    <input className="input-cupping-farmly-info" value={cuppingData.variety} onChange={e => setCuppingData({ ...cuppingData, variety: e.target.value })}></input>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <label style={{fontWeight: '500', paddingBottom: '0.1vw'}}>{isPt() ? 'Região:' : 'Región:'}</label>
                                    <input className="input-cupping-farmly-info" value={cuppingData.region} onChange={e => setCuppingData({ ...cuppingData, region: e.target.value })}></input>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <label style={{fontWeight: '500', paddingBottom: '0.1vw'}}>Peso:</label>
                                    <input className="input-cupping-farmly-info" value={cuppingData.sieve} onChange={e => setCuppingData({ ...cuppingData, sieve: e.target.value })}></input>
                                </div>
                            </div>
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', width: '90%'}}>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <label style={{fontWeight: '500', paddingBottom: '0.1vw'}}>{isPt() ? 'Altitude:' : 'Altitud'}</label>
                                    <input className="input-cupping-farmly-info" value={cuppingData.height} onChange={e => setCuppingData({ ...cuppingData, height: e.target.value })}></input>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <label style={{fontWeight: '500', paddingBottom: '0.1vw'}}>{isPt() ? 'Umidade:' : 'Humedad:'}</label>
                                    <input className="input-cupping-farmly-info" value={cuppingData.batch} onChange={e => setCuppingData({ ...cuppingData, batch: e.target.value })}></input>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <label style={{fontWeight: '500', paddingBottom: '0.1vw'}}>{isPt() ? 'Nº de Sacas:' : 'Nº de Sacas:'}</label>
                                    <input className="input-cupping-farmly-info" value={cuppingData.storage} onChange={e => setCuppingData({ ...cuppingData, storage: e.target.value })}></input>
                                </div>
                            </div>
                        </div> */}
                        <Divider variant="middle" />

                    </div>
                </div>
                <div className={classes.scorePaper} style={{ width: '30%', marginLeft: '60px', marginTop: '-135px' }}>
                    <div className="producer-profile-item" style={{ padding: '1vw', paddingTop: '1vw', textAlign: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', marginBottom: '0px' }}>
                        <label htmlFor="" className="info-title-desktop" >{isPt() ? 'Pontuação:' : 'Puntuación:'}</label>
                        <label style={{ color: 'rgb(230, 179, 143)', fontSize: '40px', fontWeight: 'normal' }}> {parseFloat(score)}pts</label>
                    </div>
                    <div className="producer-profile-item" style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}>
                        <label htmlFor="" className="info2-title-desktop" >{coffee.producer}</label>
                        <label style={{ color: 'rgb(230, 179, 143)', fontSize: '25px', fontWeight: 'normal' }}>Código: {coffee.farmCode}</label>
                    </div>
                    <div>
                        {/* <div style={{ height: '17vw', overflowY: 'scroll' }}> */}
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <RadarChart attributes={chartAttributes} maxHeight={'12vw'} />
                        </div>
                        <br />
                        {coffeeData &&
                            <div className='sensory-cards' style={{ overflowY: 'scroll' }}>
                                {renderSensoryCard()}
                            </div>
                        }
                    </div>
                    <div style={{ borderRadius: '25px', position: 'relative', marginTop: '-20px' }}>
                        <div style={{ padding: '15px', textAlign: 'center' }}>
                            <div className="comment-box" style={{ textAlign: 'center' }}>
                                <label className="info2-title-desktop">{isPt() ? 'Comentário geral:' : 'Comentario general:'}</label>
                                <FarmlyInput textarea styleClass="farmly-input comments-cupping-desktop" value={cuppingData.generalComment || ""} setValue={value => setCuppingData({ ...cuppingData, generalComment: value })}></FarmlyInput>
                            </div>
                        </div>
                    </div>
                    <div style={{ textAlign: '-webkit-center' }}>
                        <div>
                            <button className="score-paper-button-score-cupping" onClick={() => handleResult(true)} style={{ marginBottom: '8px', width: '50%' }}>
                                {isPt() ? 'Salvar' : 'Guardar'}
                            </button>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
}

export default CuppingIndvDesktop