import React from 'react'
import './Title.css'

const Title = props => (
    <div className={`farmly-title ${props.styleClass ? props.styleClass : ""}`}>
        {props.showIcon && <div className="coffee-icon" />}
        <div className="farmly-title-labels" 
            style={{ marginLeft: props.ml_20 ? '20px' : '' }} >
            <label className="farmly-title-label" 
                style={{ textTransform: props.uppercase ? 'uppercase' : '' }} >{props.title}</label>
            {props.subtitle && <label className="farmly-subtitle-label">{props.subtitle}</label>}
        </div>
    </div>
)

export default Title